import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as types from '../types'
import _api from '../../services/api'
import URLS from '../../services/urls'
import * as createDocumentActions from '../actions/createNewDocumentActions'

function* createNewDocumentWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.createNewDocument,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(createDocumentActions.createNewDocumentSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(createDocumentActions.createNewDocumentFailure(e))
  }
}
function* fetchAllDocumentsWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.fetchAllPrivateDocuments,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(createDocumentActions.fetchAllPrivateDocumentsSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(createDocumentActions.fetchAllPrivateDocumentsFailure(e))
  }
}
function* createNewDocumentWatcher() {
  yield takeLatest(types.CREATE_NEW_DOCUMENT_REQUEST, createNewDocumentWorker)
}
function* fetchAllDocumentsWatcher() {
  yield takeLatest(
    types.FETCH_ALL_PRIVATE_DOCUMENT_REQUEST,
    fetchAllDocumentsWorker
  )
}

export default function* createNewDocumentSaga() {
  yield all([createNewDocumentWatcher(), fetchAllDocumentsWatcher()])
}
