import React, {Component} from 'react'
import styles from "./FilterDocuments.module.css";
import commonStyles from '../../../shared/Common.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';
import { getFullDate } from "../../../shared/helper";
import Select from 'react-select';

class FilterDocuments extends Component{
    constructor(props){
        super(props)
        this.state={
            startDate:'',
            endDate:'',
            selectedDocTypes:'',
        }
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })

    }
    handleDocTypesChange = selectedDocTypes => {
        this.setState({ selectedDocTypes });
    };

    handleFilterDocuments = () => {
        const {startDate,endDate,selectedDocTypes} = this.state
        let docTypes=[]
        if(selectedDocTypes && selectedDocTypes.length>0){
             docTypes = selectedDocTypes.map(docType =>docType.value)
        }
        const paramsObj = {
            startDate:startDate,
            endDate:endDate,
            docTypes:docTypes.toString()
        }
        
        this.props.allDocumentsFilterRequest(paramsObj)
    }
 
    render() {
        const {  startDate, endDate, selectedDocTypes } = this.state
        const today = getFullDate();
       
        return (
            <div>
                <CommonModal modalClass={'team_common_modal'} closeHandler={this.props.closeHandler} header={'Filter Documents'}>



                <div className={`row ${styles.row_gap}`}>
                         
                         <div className="col md-4">
                             <p className={commonStyles.common_form_label}>DOCUMENT TYPE</p>
                             <Select
                                 value={selectedDocTypes}
                                 onChange={this.handleDocTypesChange}
                                 options={this.props.docTypes}
                                 isMulti={true}
                             />
                         </div>
                         <div className="col md-4">
                         <p className={commonStyles.common_form_label}>START DATE</p>
                              <input
                                 className={`form-control ${commonStyles.common_form_text_field}`}
                                 type="date"
                                 name="startDate"
                                 value={startDate}
                                 max={today}
                                 onChange={(event) => this.handleUserInput(event)}>
                             </input>                     
                         </div>
                         <div className="col md-4">
                             <p className={commonStyles.common_form_label}>END DATE</p>
                             <input
                                 className={`form-control ${commonStyles.common_form_text_field}`}
                                 type="date"
                                 name="endDate"
                                 value={endDate}
                                 min={startDate}
                                 max={today}
                                 onChange={(event) => this.handleUserInput(event)}>
                             </input>
                         </div>
                     </div>
                <div className={styles.buttons_wrapper}>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                                onClick={this.props.closeHandler}>
                                CANCEL
                                </button>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                                onClick={this.handleFilterDocuments}>
                                APPLY FILTER
                            </button>
                        </div>
                </CommonModal>
            </div>
        )
    }

}
export default FilterDocuments;
