import React, { Component } from "react";
import styles from "./AllDocumentsDetails.module.css";
import AllDocumentsDocInfo from '../../../components/all-documents/all-documents-doc-info/AllDocumentsDocInfo';
import AllDocumentsVersionHistory from '../../../components/all-documents/all-documents-version-history/AllDocumentsVersionHistory'
import { Tab, Tabs } from 'react-bootstrap';
class AllDocumentsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className='thin_custom_scrollbar'>
                <div className={styles.popup_overlay} onClick={() => this.props.onPopupClose()}></div>
                <div className={styles.popup_container}>
                    <div className={`tab_with_bottom_border ${styles.clustered_paragraphs_details_container}`}>
                        <Tabs id="all-documents-details-tab">
                            <Tab eventKey="doc_info" title="Document Info">
                                <AllDocumentsDocInfo history={this.props.history} from={this.props.from} selectedFileData={this.props.selectedFileData}></AllDocumentsDocInfo>
                            </Tab>
                        
                            <Tab eventKey="version_history" title="Version History">
                                <AllDocumentsVersionHistory history={this.props.history} from={this.props.from}></AllDocumentsVersionHistory>
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </div>
        )
    }
}
export default AllDocumentsDetails;
