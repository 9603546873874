import * as types from '../types';

// add comment to response file
export const createFileCommentRequest = (payload) => ({ type: types.CREATE_FILE_COMMENT_REQUEST, payload });
export const createFileCommentSuccess = (payload) => ({ type: types.CREATE_FILE_COMMENT_SUCCESS, payload });
export const createFileCommentFailure = (payload) => ({ type: types.CREATE_FILE_COMMENT_FAILURE, payload });

export const getFileCommentRequest = (payload) => ({ type: types.GET_FILE_COMMENT_REQUEST, payload });
export const getFileCommentSuccess = (payload) => ({ type: types.GET_FILE_COMMENT_SUCCESS, payload });
export const getFileCommentFailure = (payload) => ({ type: types.GET_FILE_COMMENT_FAILURE, payload });

export const deleteFileCommentRequest = (payload) => ({ type: types.DELETE_FILE_COMMENT_REQUEST, payload });
export const deleteFileCommentSuccess = (payload) => ({ type: types.DELETE_FILE_COMMENT_SUCCESS, payload });
export const deleteFileCommentFailure = (payload) => ({ type: types.DELETE_FILE_COMMENT_FAILURE, payload });

export const updateFileCommentRequest = (payload) => ({ type: types.UPDATE_FILE_COMMENT_REQUEST, payload });
export const updateFileCommentSuccess = (payload) => ({ type: types.UPDATE_FILE_COMMENT_SUCCESS, payload });
export const updateFileCommentFailure = (payload) => ({ type: types.UPDATE_FILE_COMMENT_FAILURE, payload });
