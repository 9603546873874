import React, { Component } from 'react';
import styles from './ClusteredParagraphs.module.css';
// import commonStyles from '../../../shared/Common.module.css';
import EditTag from '../edit-tag/EditTag';
import tags from '../../../json-data/editTags.json';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { handleSuccess } from '../../../shared/HandleMessages';
import {
  setUploadedData,
  similarParaId,
  similarParaInit,
} from '../../../store/actions/uploadedDocActions';
const confidenceScoreMap = {
  0: 'LOW',
  1: 'MEDIUM',
  2: 'HIGH',
  3: '',
};
const confidenceColorMap = {
  0: '#FF0016',
  1: '#F19221',
  2: '#13CE66',
  3: '#D3D3D3',
};
class ClusteredParagraphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 100,
      isClickedOnTagEdit: false,
      searchWords: [''],
      editedParaDetails: {},
      editedTags: null,
      openComment: false,
      paraComment: null,
      role: localStorage.getItem('role'),
      isClicked: false,
      id: null,
    };
  }
  onResize = (resizeFactor) => {
    let nextSize = this.state.size + resizeFactor;
    if (nextSize > 150) {
      nextSize = 150;
    } else if (nextSize <= 50) {
      nextSize = 50;
    }
    this.setState({
      size: nextSize,
    });
  };

  onTagEdit = (para) => {
    this.setState({
      isClickedOnTagEdit: true,
      editedParaDetails: para,
    });
  };
  handleTagEdit = () => {
    this.setState({
      isClickedOnTagEdit: false,
    });
  };
  checkIfFilterTags = () => {
    if (Object.keys(this.props.selectedTags).length) {
      for (const property in this.props.selectedTags) {
        if (this.props.selectedTags[property]) {
          return true;
        }
      }
    }
    return false;
  };
  handleTagEditSuccess = (editResponse) => {
    this.props.clearSelectedTags();
    this.setState({ editedTags: editResponse });
    // this.props.setActiveTags(editResponse)

    const clusterData = this.props.uploadedDoc.clusterData;
    let clusteredResult = this.props.uploadedDoc.clusterData.data.result;
    let index = -1;
    clusteredResult.forEach((el, i) => {
      if (el.paraSeqId == editResponse.paraSeqId) {
        index = i;
      }
    });
    if (index !== -1) {
      const obj = clusteredResult[index];
      obj.mainTags = editResponse.mainTags || '';
      clusteredResult[index] = { ...obj };
      clusterData.data.result = [...clusteredResult];
      this.props.setUploadedData(clusterData);
    }
  };

  handleCommentClick(para) {
    this.setState({
      openComment: true,
      paraComment: para.paraId,
    });
  }
  navigateBack = () => {
    let url = this.props.history.location.state?.from;
    if (url) {
      this.props.history.push(url);
    }
  };
  handleSimilarPara = (para) => {
    this.props.similarParaId(para.paraId);
    if (para.paraId === this.state.id) {
      this.setState({ isClicked: !this.state.isClicked, id: null });
      this.props.similarParaInit();
    } else {
      this.setState({ isClicked: true, id: para.paraId });
    }
  };
  handleHoverOff = () => {
    this.props.similarParaInit();
    handleSuccess('Feedback submitted successfully!');
  };
  getAgreeBoxStyle() {
    // if(this.props.uploadedDoc.prepareResponse){
    //   return{marginBottom:'300px'}
    // }
    if (this.props.showTopStrip) {
      return { paddingRight: '120px', paddingLeft: '120px' };
    } else return null;
  }
  getDocRenderWrapperStyle() {
    if (this.props.showTopStrip) {
      return { paddingTop: '120px' };
    } else return null;
  }
  render() {
    // this.props.uploadedDoc.prepareResponse
    var data = [];
    let searchWords = [];
    data.push(this.props.searchItem);
    if (this.props.searchItem.trim()) {
      searchWords = data;
    }
    const {
      isClickedOnTagEdit,
      editedParaDetails,
      openComment,
      paraComment,
      role,
      size,
      isClicked,
      id,
    } = this.state;
    let clusteredResult = this.props.uploadedDoc.clusterData?.data.result;

    if (this.state.editedTags) {
      clusteredResult.map((data) => {
        //will get error if use === (number === string)
        if (data.paraSeqId == this.state.editedTags.paraSeqId) {
          data.mainTags = this.state.editedTags.customMainTag;
          data.subTags[0] = this.state.editedTags.customSubTags[0].name;
          if (this.state.editedTags.customSubTags.length > 1) {
            //   if(data.subTags[1]){
            data.subTags[1] = this.state.editedTags.customSubTags[1].name;
            if (data.subTags[2]) {
              data.subTags[2] = '';
            }
            // }
          } else {
            data.subTags[1] = '';
            data.subTags[2] = '';
          }
          data.confidence = 3;
        }
      });
    }

    const tagSelected = this.checkIfFilterTags();
    return (
      <div className={styles.doc_wrapper}>
        {this.props.showTopStrip && (
          <div className={styles.doc_control_container}>
            <div className={styles.doc_control_section}>
              <div>
                <i
                  onClick={() => this.navigateBack()}
                  className={`fas fa-arrow-left ${styles.icon_style}`}
                ></i>
                {
                  this.props.uploadedDoc.clusterData?.data.fileVersion
                    .orgFileName
                }
              </div>
              {/* <div>
                    <i  className={`fas fa-arrow-left ${styles.icon_style}`}></i>IncomeTax_SCN.pdf
                    </div>  */}
              <div className={styles.resize_icons}>
                <i
                  onClick={() => this.onResize(10)}
                  className={`fas fa-search-plus ${styles.icon_style}`}
                ></i>
                <span>{size}%</span>
                <i
                  onClick={() => this.onResize(-10)}
                  className={`fas fa-search-minus ${styles.icon_style}`}
                ></i>
              </div>
            </div>
          </div>
        )}
        <div
          className={styles.doc_render_wrapper}
          style={this.getDocRenderWrapperStyle()}
        >
          <div className={styles.AgreeLeft}>
            <div className={styles.AgreeBox} style={this.getAgreeBoxStyle()}>
              {clusteredResult.map((para, i) => {
                let datasubtag = [];
                let datasugtag = [];
                if (para.subTags) {
                  // datasubtag = para.subTags.split('|')
                  datasubtag = Object.values(para.subTags);
                }
                if (para.suggestedTags) {
                  datasugtag = para.suggestedTags.split('|');
                }
                if (tagSelected && !this.props.selectedTags[datasubtag[0]]) {
                  return null;
                }
                return (
                  <figcaption key={i}>
                    <article style={{ zoom: this.state.size + '%' }}>
                      {/* { para.paraText &&   <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={searchWords}
                        autoEscape={true}
                        textToHighlight={para.paraText}
                        className="HighlightClass"
                      />} */}

                      {/* { para.htmlText &&
   <div className="text"  dangerouslySetInnerHTML={{ __html: para.htmlText }} />
} */}
                      {para.paraType === 'Paragraph' && para.paraText && (
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchWords}
                          autoEscape={true}
                          textToHighlight={para.paraText}
                          className="HighlightClass"
                        />
                      )}

                      {para.paraType === 'Table' && (
                        <img
                          src={para.paraText}
                          alt=""
                          width={para.table_dimension?.width + '%'}
                        ></img>
                      )}
                    </article>
                    {paraComment !== para.paraId && (
                      <aside
                        className={
                          isClicked && para.paraId === id
                            ? styles.openPopUp
                            : null
                        }
                        onClick={() => this.handleSimilarPara(para)}
                      >
                        <div
                          className={
                            isClicked && para.paraId === id
                              ? styles.AsideBox_click
                              : styles.AsideBox
                          }
                        >
                          <div
                            style={{
                              borderLeft:
                                '5px solid' +
                                confidenceColorMap[para.confidence],
                              borderTop:
                                '1px solid' +
                                confidenceColorMap[para.confidence],
                              borderRight:
                                '1px solid' +
                                confidenceColorMap[para.confidence],
                              borderBottom:
                                '1px solid' +
                                confidenceColorMap[para.confidence],
                            }}
                            className={styles.AsideHead}
                          >
                            <span className={styles.title}>
                              {datasubtag[0]}
                            </span>
                            <div className={styles.confidence_container}>
                              <div
                                style={{
                                  color: confidenceColorMap[para.confidence],
                                }}
                                className={styles.confidence_score}
                              >
                                {para.confidence === 3
                                  ? 'CUSTOM TAG'
                                  : 'CONFIDENCE - ' +
                                    confidenceScoreMap[para.confidence]}
                              </div>
                            </div>
                            <span className={styles.action}>
                              <span>
                                <i
                                  onClick={() => this.onTagEdit(para)}
                                  className="fa fa-times-circle"
                                ></i>
                              </span>
                              <span>
                                <i className="fa fa-check-circle"></i>
                              </span>
                              {role === 'manager' && (
                                <img
                                  onClick={() => this.handleCommentClick(para)}
                                  src={require('../../../assets/add_comment.png')}
                                  alt=""
                                />
                              )}
                            </span>
                          </div>
                          <div className={styles.AsideBody}>
                            {datasubtag.length > 0 && (
                              <div>
                                <span className={styles.subTagTitle}>
                                  SUBTAGS
                                </span>
                                <ul>
                                  {datasubtag.map((data, i) => {
                                    if (i > 0 && data && data !== '') {
                                      return i > 0 && <li key={i}>{data}</li>;
                                    }
                                  })}
                                </ul>
                                <span className={styles.subTagTitle}>
                                  MAINTAG
                                </span>
                                <div className={styles.mainTagStyle}>
                                  {' '}
                                  {para.mainTags}
                                </div>
                              </div>
                            )}
                            {datasugtag.length > 0 && (
                              <div>
                                <h4>Suggested Tags</h4>
                                <ul>
                                  {datasugtag.map((data, i) => {
                                    return <li key={i}>{data}</li>;
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </aside>
                    )}
                    {openComment && paraComment === para.paraId && (
                      <div className={styles.comment_container}>
                        <textarea></textarea>
                      </div>
                    )}
                  </figcaption>
                );
              })}
            </div>
          </div>
        </div>
        {isClickedOnTagEdit && (
          <EditTag
            editedParaDetails={editedParaDetails}
            tags={tags.result}
            closeHandler={this.handleTagEdit}
            onTagEditSuccess={this.handleTagEditSuccess}
          ></EditTag>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uploadedDoc: state.uploadedDoc,
  };
};
const mapDispatchToProps = {
  setUploadedData,
  similarParaId,
  similarParaInit,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusteredParagraphs);
