import React, { Component } from "react";
import styles from "./TeamMemberList.module.css";
import EditMember from "../edit-member/EditMember";
import DeleteMember from "../delete-member/DeleteMember";
// import commonStyles from '../../../shared/Common.module.css';
const ROLES = ["manager", "admin"];

class TeamMemberList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberToEdit: [],
      isEditMemberModalOpen: false,
      isDeleteMemberModalOpen: false,
      memberToDelete: null,
      membersData: [],
      filteredMembersData: [],
      isAscending: 1,
      fieldToSort: "",
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchItem !== this.props.searchItem) {
      this.getFilteredMembers();
    }
  }
  componentDidMount() {
    this.setState({ membersData: [...this.props.members] }, () => {
      this.getFilteredMembers();
    });
  }
  handleDeleteTeamMember(teamMemberId) {
    this.setState({
      isDeleteMemberModalOpen: true,
      memberToDelete: teamMemberId,
    });
    // const paramsObj = {
    //     teamMemberId : teamMemberId,
    //     status:'inactive'
    // }
    // this.props.ondeleteTeamMember(paramsObj)
  }
  handleEditTeamMember(teamMemberId) {
    this.setState({ isEditMemberModalOpen: true });
    const memberToEdit = this.props.members.filter((member) => {
      return member.teamMemberId === teamMemberId;
    });
    this.setState({
      memberToEdit: memberToEdit,
    });
  }
  onEditMemberClose = () => {
    this.setState({ isEditMemberModalOpen: false });
  };
  onDeleteMemberClose = () => {
    this.setState({ isDeleteMemberModalOpen: false });
  };
  getFilteredMembers = () => {
    let searchItems = this.props.searchItem;
    let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    let filteredMembers = this.state.membersData.filter((member) => {
      return member.name.toLowerCase().search(subStr.toLowerCase()) !== -1;
    });
    this.setState({ filteredMembersData: filteredMembers });
  };
  onDeleteTeamMember = () => {
    const paramsObj = {
      teamMemberId: this.state.memberToDelete,
      status: "inactive",
    };
    this.props.ondeleteTeamMember(paramsObj);
  };
  onEditTeamMember = (params) => {
    this.props.onEditTeamMember(params);
  };
  shouldShowIcon() {
    return (
      this.props.name !== "All" && ROLES.includes(localStorage.getItem("role"))
    );
  }
  sortTeamMember = (orderBy) => {
    const filteredMembersData = this.state.filteredMembersData;
    filteredMembersData.sort((a, b) =>
      a[orderBy] > b[orderBy]
        ? this.state.isAscending
        : this.state.isAscending * -1
    );
    this.setState({
      filteredMembersData,
      isAscending: this.state.isAscending * -1,
      fieldToSort: orderBy,
    });
  };
  render() {
    const {
      memberToEdit,
      isEditMemberModalOpen,
      isDeleteMemberModalOpen,
      memberToDelete,
      filteredMembersData,
      fieldToSort,
      isAscending,
    } = this.state;
    return (
      <div className={"thin_custom_scrollbar"}>
        <div className={styles.team_member_list_container}>
          <table className={styles.team_member_list_table}>
            <thead>
              <tr className={styles.team_member_list_row_label}>
                <th>
                  {" "}
                  <label onClick={() => this.sortTeamMember("name")}>
                    TEAM MEMBER
                  </label>
                  {fieldToSort === "name" && isAscending === -1 && (
                    <i class="fas fa-angle-up"></i>
                  )}
                  {fieldToSort === "name" && isAscending === 1 && (
                    <i class="fas fa-angle-down"></i>
                  )}
                </th>
                <th>
                  <label onClick={() => this.sortTeamMember("email")}>
                    EMAIL ADDRESS
                  </label>
                  {fieldToSort === "email" && isAscending === -1 && (
                    <i class="fas fa-angle-up"></i>
                  )}
                  {fieldToSort === "email" && isAscending === 1 && (
                    <i class="fas fa-angle-down"></i>
                  )}
                </th>
                <th>
                  <label onClick={() => this.sortTeamMember("designation")}>
                    DESIGNATION
                  </label>
                  {fieldToSort === "designation" && isAscending === -1 && (
                    <i class="fas fa-angle-up"></i>
                  )}
                  {fieldToSort === "designation" && isAscending === 1 && (
                    <i class="fas fa-angle-down"></i>
                  )}
                </th>
                <th>
                  <label onClick={() => this.sortTeamMember("role")}>
                    ROLE ACCESS
                  </label>
                  {fieldToSort === "role" && isAscending === -1 && (
                    <i class="fas fa-angle-up"></i>
                  )}
                  {fieldToSort === "role" && isAscending === 1 && (
                    <i class="fas fa-angle-down"></i>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredMembersData.map((data, index) => {
                let member = data.name;
                let email = data.email;
                let designation = data.designation;
                let role = data.role;
                let teamMemberId = data.teamMemberId;
                return (
                  <tr
                    className={styles.team_member_list_row_content}
                    key={index}
                  >
                    <td>
                      <img
                        className={`avatar ${styles.team_member_avatar}`}
                        src="https://www.w3schools.com/howto/img_avatar.png"
                        alt="Avatar"
                      ></img>
                      <span className={styles.member}>{member}</span>
                    </td>
                    <td>
                      <span>{email}</span>
                    </td>
                    <td>
                      <span>{designation} </span>
                    </td>
                    <td>
                      <span>{role} </span>
                    </td>
                    {this.shouldShowIcon() && (
                      <td>
                        <i
                          onClick={() =>
                            this.handleEditTeamMember(teamMemberId)
                          }
                          className="fas fa-pen"
                        ></i>
                      </td>
                    )}
                    {this.shouldShowIcon() && (
                      <td>
                        <i
                          onClick={() =>
                            this.handleDeleteTeamMember(teamMemberId)
                          }
                          className="fas fa-times"
                        ></i>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {isEditMemberModalOpen && memberToEdit.length === 1 && (
          <EditMember
            closeHandler={this.onEditMemberClose}
            memberToEdit={memberToEdit}
            onEditTeamMember={this.onEditTeamMember}
          ></EditMember>
        )}
        {isDeleteMemberModalOpen && (
          <DeleteMember
            memberToDelete={memberToDelete}
            closeHandler={this.onDeleteMemberClose}
            onDeleteTeamMember={this.onDeleteTeamMember}
          ></DeleteMember>
        )}
      </div>
    );
  }
}

export default TeamMemberList;
