import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as graphs from '../actions/graphs.Action';

function* allGraphsWorker(params) {
    try {
        const data = yield call(_api, URLS.requestReportAnalysisGraph, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(graphs.allGraphsSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(graphs.allGraphsFailure(e));
    }
}

function* allGraphsWatcher() {
    yield takeLatest(types.ALL_REPORT_ANALYSIS_GRAPH_REQUEST, allGraphsWorker);
}


export default function* allGraphsSaga() {
    yield all([
        allGraphsWatcher(),
        
    ]);
}