import React from 'react'
import styles from './AllFoldersTreeRecentFolders.module.css'
import grey_folder from '../../../assets/grey_folder.svg'

class AllFoldersTreeRecentFolders extends React.Component {
  constructor(props) {
    super(props)
  }
  handleFolderClick = (fileName, referenceId, catalogSourceId) => {
    this.props.history.push(
      {
        pathname: '/selected-folder',
        search: '?source=data-catalog',
      },
      {
        fileName: fileName,
        referenceId: referenceId,
        catalogSourceId: catalogSourceId,
      }
    )
  }
  render() {
    return (
      <React.Fragment>
        <div className={styles.all_folders_tree_folders_container}>
          {this.props.folders.map((data, index) => {
            return (
              <div
                onClick={() =>
                  this.handleFolderClick(
                    data.name,
                    data.referenceId,
                    data.catalogSourceId
                  )
                }
                className={styles.all_folders_tree_folders_card}
              >
                <img
                  className={styles.folder_icon}
                  src={grey_folder}
                  alt="folder Icon"
                ></img>{' '}
                <div className={styles.folder_title}>{data.name}</div>
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default AllFoldersTreeRecentFolders
