import * as types from '../types'
// Workflow management project Actions

export const workflowManagementProjectDetailsRequest = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_DETAILS_REQUEST,
  payload,
})
export const workflowManagementProjectDetailsSuccess = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_DETAILS_SUCCESS,
  payload,
})
export const workflowManagementProjectDetailsFailure = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_DETAILS_FAILURE,
  payload,
})

export const workflowManagementAddMemberRequest = payload => ({
  type: types.WORKFLOW_MANAGEMENT_ADD_MEMBER_REQUEST,
  payload,
})
export const workflowManagementAddMemberSuccess = payload => ({
  type: types.WORKFLOW_MANAGEMENT_ADD_MEMBER_SUCCESS,
  payload,
})
export const workflowManagementAddMemberFailure = payload => ({
  type: types.WORKFLOW_MANAGEMENT_ADD_MEMBER_FAILURE,
  payload,
})

export const workflowManagementRemoveMemberRequest = payload => ({
  type: types.WORKFLOW_MANAGEMENT_REMOVE_MEMBER_REQUEST,
  payload,
})
export const workflowManagementRemoveMemberSuccess = payload => ({
  type: types.WORKFLOW_MANAGEMENT_REMOVE_MEMBER_SUCCESS,
  payload,
})
export const workflowManagementRemoveMemberFailure = payload => ({
  type: types.WORKFLOW_MANAGEMENT_REMOVE_MEMBER_FAILURE,
  payload,
})
