import React from 'react'
import styles from './AllFoldersTableView.module.css'
import arrow_up from '../../../assets/arrow_up.svg'
import grey_folder from '../../../assets/folder_grey_ea.svg'
import grey_document from '../../../assets/grey_document.svg'
import { formatDate } from '../../../shared/formatDate'
import { withRouter } from 'react-router-dom'
import { handleLongFileName } from '../../../shared/helper'
class AllFoldersTableView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredFilesData: this.props.files,
      isAscending: 1,
      sortedFileData: this.props.files,
      filteredFoldersData: this.props.folders,
      sortedFoldersData: this.props.folders,
    }
  }

  componentDidMount() {
    this.setState({ filteredFilesData: this.props.files })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchItems !== this.props.searchItems) {
      this.getFilteredFiles()
      this.getFilteredFolders()
    }
    if (prevProps.files !== this.props.files) {
      this.setState({
        filteredFilesData: this.props.files,
        sortedFileData: this.props.files,
        filteredFoldersData: this.props.folders,
        sortedFoldersData: this.props.folders,
      })
    }
  }

  sortFiles = () => {
    const filteredFilesData = this.state.filteredFilesData
    filteredFilesData.sort((a, b) =>
      a['fileName'].replace(/%20/g, '').toLowerCase() >
      b['fileName'].replace(/%20/g, '').toLowerCase()
        ? this.state.isAscending
        : this.state.isAscending * -1
    )
    this.setState({
      filteredFilesData,
      isAscending: this.state.isAscending * -1,
      sortedData: filteredFilesData,
    })
  }
  getFilteredFiles = () => {
    let subStr = this.props.searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let filteredFiles = this.state.sortedFileData.filter(tag => {
      return (
        tag.fileName
          .replace(/%20/g, '')
          .toLowerCase()
          .search(subStr.toLowerCase()) !== -1
      )
    })
    this.setState({ filteredFilesData: filteredFiles })
  }

  sortFolders = () => {
    const filteredFoldersData = this.state.filteredFoldersData
    filteredFoldersData.sort((a, b) =>
      a['name'].replace(/%20/g, '').toLowerCase() >
      b['name'].replace(/%20/g, '').toLowerCase()
        ? this.state.isAscending
        : this.state.isAscending * -1
    )
    this.setState({
      filteredFoldersData,
      isAscending: this.state.isAscending * -1,
      // sortedData:filteredFilesData
    })
  }

  getFilteredFolders = () => {
    let subStr = this.props.searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let filteredFolders = this.state.sortedFoldersData.filter(tag => {
      return (
        tag.name
          .replace(/%20/g, '')
          .toLowerCase()
          .search(subStr.toLowerCase()) !== -1
      )
    })
    this.setState({ filteredFoldersData: filteredFolders })
  }

  sortFilesAndFolders() {
    this.sortFolders()
    this.sortFiles()
  }

  render() {
    const { isAscending } = this.state
    return (
      <React.Fragment>
        <div>
          <table className={styles.data_catalog_all_folders_table}>
            <thead>
              <tr className={styles.all_folders_row_label}>
                <th>
                  {' '}
                  <span>Name</span>{' '}
                  {
                    <img
                      className={`${styles.arrow_up} ${
                        isAscending == 1 ? '' : styles.arrow_down
                      }`}
                      src={arrow_up}
                      alt="arrow_up"
                      onClick={() => this.sortFilesAndFolders()}
                    ></img>
                  }
                </th>
                <th>
                  <span>Document Type</span>
                </th>
                <th>
                  <span>Modified On</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.filteredFoldersData?.length > 0 &&
                this.state.filteredFoldersData.map((data, index) => {
                  let docName = data.name.replace(/%20/g, '').slice(0, 20)
                  let modified = formatDate(data.modified)
                  let fileType = '-'

                  return (
                    <tr key={index}>
                      <td>
                        <span className={styles.folder_name}>
                          <img
                            className={styles.grey_folder}
                            src={grey_folder}
                            alt="document"
                          ></img>
                          {docName}
                        </span>
                      </td>
                      <td>
                        <span className={styles.all_folder_tree_table_text}>
                          {fileType}{' '}
                        </span>
                      </td>

                      <td>
                        <span className={styles.modified}>{modified.date}</span>
                      </td>
                    </tr>
                  )
                })}

              {this.state.filteredFilesData?.length > 0 &&
                this.state.filteredFilesData.map((data, index) => {
                  console.log(this.props)
                  let docName = data.fileName.replace(/%20/g, '').slice(0, 20)
                  let modified = formatDate(data.modified)
                  let number = data.fileType
                  let confidence = data.confidence
                  return (
                    <tr
                      key={index}
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/document-analytics',
                          search: '?source=data-catalog',
                          state: {
                            fileId: data.fileId,
                            filePath: data.filePath,
                          },
                        })
                      }}
                      className={styles.files_list_file}
                    >
                      <td>
                        <span className={styles.folder_name}>
                          <img
                            className={styles.grey_document}
                            src={grey_document}
                            alt="document"
                          ></img>
                          {/*  to remove file extension */}
                          {/* {docName.substring(0, docName.lastIndexOf('.'))
                            ? docName.substring(0, docName.lastIndexOf('.'))
                            : docName} */}
                          {docName}
                        </span>
                      </td>
                      <td>
                        <span className={styles.all_folder_tree_table_text}>
                          {number}{' '}
                        </span>
                      </td>

                      <td>
                        <span className={styles.modified}>{modified.date}</span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(AllFoldersTableView)
