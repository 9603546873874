import * as types from '../types'

const initialState = {
  isLoading: false,
  dashboardData: null,
  error: null,
  isFileUploading: false,
  documentUploadData: null,
  isFileClustering: false,
  fileClusterData: null,
  isClusteredLoading: false,
  clusteredData: null,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // user dashboard Actions
    case types.DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        dashboardData: null,
      }
    case types.DASHBOARD_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dashboardData: action.payload.result,
      }
    }
    case types.DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }
    case types.DASHBOARD_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        isFileUploading: true,
        documentUploadData: null,
      }
    case types.DASHBOARD_FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        isFileUploading: false,
        documentUploadData: action.payload.result,
      }
    }
    case types.DASHBOARD_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        isFileUploading: false,
        error: action.payload.message || defaultErrorMessage,
      }

    case types.UPLOAD_DOCUMENT_FILE_CLUSTER_REQUEST:
      return {
        ...state,
        isFileClustering: true,
        fileClusterData: null,
      }
    case types.UPLOAD_DOCUMENT_FILE_CLUSTER_SUCCESS: {
      return {
        ...state,
        isFileClustering: false,
        fileClusterData: action.payload.result,
      }
    }
    case types.UPLOAD_DOCUMENT_FILE_CLUSTER_FAILURE:
      return {
        ...state,
        isFileClustering: false,
        error: action.payload.message || defaultErrorMessage,
      }

    case types.UPLOAD_DOCUMENT_CLUSTERED_DATA_REQUEST:
      return {
        ...state,
        isClusteredLoading: true,
        clusteredData: null,
      }
    case types.UPLOAD_DOCUMENT_CLUSTERED_DATA_SUCCESS: {
      return {
        ...state,
        isClusteredLoading: false,
        clusteredData: action.payload.result,
      }
    }
    case types.UPLOAD_DOCUMENT_CLUSTERED_DATA_FAILURE:
      return {
        ...state,
        isClusteredLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }

    default: {
      return state
    }
  }
}
