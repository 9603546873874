import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './Login.module.css';
import commonStyles from '../../../shared/Common.module.css';
import ErrorText from '../../../shared/error-text/ErrorText';
import Captcha from '../../../shared/captcha/Captcha';
import isEmail from 'validator/lib/isEmail';
class Login extends Component {
  constructor(props) {
    localStorage.clear();
    super(props);
    this.state = {
      email: '',
      password: '',
      isFormInvalid: true,
      type: 'password',
      showError: false,
      errors: {
        email: 'Email is not provided',
        password: 'Password is not provided',
      },
      navigate: false,
      isCaptchaChecked: true,
    };
  }

  componentDidMount() {
    // this.isLoggedIn();
    localStorage.clear();
  }

  componentDidUpdate(prevProps) {
    // if(this.state.navigate){
    if (
      prevProps.isLoginLoading &&
      !this.props.isLoginLoading &&
      this.props.user
    ) {
      this.isLoggedIn();
    }
  }

  isLoggedIn = () => {
    if (this.props.user) {
      localStorage.setItem('token', this.props.user.token);
      localStorage.setItem('userProfileData', JSON.stringify(this.props.user));
      localStorage.setItem('role', this.props.user.role);
      if (this.props.user.role === 'admin') {
        this.props.history.push('/admin');
      } else {
        let search = window.location.search;
        const params = new URLSearchParams(search);
        const redirectPath = params.get('redirect_path');
        const fileId = params.get('fileId');
        const utmSource = params.get('utm_source');
        if (redirectPath) {
          this.props.history.push({
            pathname: `/${redirectPath}`,
            state: { fileId, utmSource },
          });
        } else {
          this.props.history.push('/dashboard');
        }
      }
    }
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let errors = this.state.errors;
    let formsInvalid = false;
    switch (name) {
      case 'email':
        errors.email = isEmail(value) ? '' : 'Email is not valid!';
        formsInvalid = !isEmail(value);
        break;
      case 'password':
        errors.password = value.length < 5 ? 'Password is not valid!' : '';
        formsInvalid = errors.password.length > 0 ? true : false;
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
    this.setState({ formsInvalid: formsInvalid });
  };

  goToSignup = () => {
    this.props.history.push('/signup');
  };
  goToForgotPassword = () => {
    this.props.history.push('/forgot-password');
  };
  handleLogin = () => {
    const { email, password, formsInvalid, isCaptchaChecked } = this.state;
    if (formsInvalid || !email || !password || !isCaptchaChecked) {
      this.setState({ showError: true });
    } else {
      this.setState({ showError: false });
      const paramsObj = {
        email: email,
        password: password,
      };
      this.props.onLogin(paramsObj);
    }
    this.setState({ navigate: true });
  };
  showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input',
    });
  };
  verifyCaptcha = (e) => {
    this.setState({ isCaptchaChecked: true });
  };

  render() {
    localStorage.clear();
    const { email, password, errors, showError } = this.state;
    return (
      <React.Fragment>
        <div className={`row ${commonStyles.no_gutter}`}>
          <div className="col-md-12">
            <div className={commonStyles.home_top_container}>
              {/* <div className={commonStyles.home_top_section}>
                <span className={commonStyles.home_top_text}>Don't have an account?</span>
                <button type="button" className={`btn btn-primary-outline ${commonStyles.home_top_action_btn} ${commonStyles.btn_ripple}`}
                onClick={this.goToSignup}>
                  GET STARTED
                </button>
              </div> */}
            </div>
            <div className={styles.login_form_container}>
              <p className={styles.login_main_text}>SIGN IN TO PRECILY AI</p>
              {/* <p>Enter your details below</p> */}
              <div className={commonStyles.home_form_section}>
                <form>
                  <div className={styles.login_label}>EMAIL ADDRESS</div>
                  <div></div>
                  <input
                    className={`form-control  ${commonStyles.common_form_text_field} ${styles.login_form_text_field}`}
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.handleUserInput}
                  ></input>
                  {showError && errors.email && (
                    <ErrorText text={errors.email} />
                  )}
                  <div className={styles.login_form_password_section}>
                    <p className={styles.login_label}>PASSWORD</p>
                    <p
                      className={styles.login_form_password_text}
                      onClick={this.goToForgotPassword}
                    >
                      Forgot your password?
                    </p>
                  </div>
                  <div className={styles.login_form_password_field}>
                    <input
                      className={`form-control ${commonStyles.common_form_text_field} ${styles.login_form_text_field}`}
                      type={this.state.type}
                      name="password"
                      value={password}
                      onChange={this.handleUserInput}
                    ></input>
                    {showError && errors.password && (
                      <ErrorText text={errors.password} />
                    )}
                    <span
                      className={styles.login_form_password_eye}
                      onClick={this.showHide}
                    >
                      <i
                        className={`fa ${
                          this.state.type === 'input'
                            ? 'fa-eye-slash'
                            : 'fa-eye'
                        }`}
                      ></i>
                    </span>
                  </div>
                  <div className={styles.login_form_captcha_container}>
                    <Captcha handleClick={this.verifyCaptcha}></Captcha>
                  </div>
                  <div className={styles.login_form_submit_container}>
                    <button
                      onClick={this.handleLogin}
                      type="button"
                      className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple} ${styles.login_form_button}`}
                      disabled={!this.state.isCaptchaChecked}
                    >
                      SIGN IN
                    </button>
                  </div>
                </form>
              </div>
              {/* {this.props.error && 
                <div>
                  {this.props.error}
                </div>} */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.homepage.userDetails,
    error: state.homepage.error,
    isLoginLoading: state.homepage.isLoginLoading,
  };
};
export default connect(mapStateToProps, null)(Login);
