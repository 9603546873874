import * as types from '../types'

// Catalog Recent Folders Actions

export const catalogRecentFoldersRequest = payload => ({
  type: types.CATALOG_RECENT_FOLDERS_REQUEST,
  payload,
})
export const catalogRecentFoldersSuccess = payload => ({
  type: types.CATALOG_RECENT_FOLDERS_SUCCESS,
  payload,
})
export const catalogRecentFoldersFailure = payload => ({
  type: types.CATALOG_RECENT_FOLDERS_FAILURE,
  payload,
})

// Catalog Recent Documents Actions

export const catalogRecentDocumentsRequest = payload => ({
  type: types.CATALOG_RECENT_DOCUMENTS_REQUEST,
  payload,
})
export const catalogRecentDocumentsSuccess = payload => ({
  type: types.CATALOG_RECENT_DOCUMENTS_SUCCESS,
  payload,
})
export const catalogRecentDocumentsFailure = payload => ({
  type: types.CATALOG_RECENT_DOCUMENTS_FAILURE,
  payload,
})

// Catalog All Folders Actions

export const catalogAllFoldersRequest = payload => ({
  type: types.CATALOG_ALL_FOLDERS_REQUEST,
  payload,
})
export const catalogAllFoldersSuccess = payload => ({
  type: types.CATALOG_ALL_FOLDERS_SUCCESS,
  payload,
})
export const catalogAllFoldersFailure = payload => ({
  type: types.CATALOG_ALL_FOLDERS_FAILURE,
  payload,
})

// Catalog All Source Actions

export const catalogAllSourceRequest = payload => ({
  type: types.CATALOG_ALL_SOURCE_REQUEST,
  payload,
})
export const catalogAllSourceSuccess = payload => ({
  type: types.CATALOG_ALL_SOURCE_SUCCESS,
  payload,
})
export const catalogAllSourceFailure = payload => ({
  type: types.CATALOG_ALL_SOURCE_FAILURE,
  payload,
})

// Catalog Search Term Actions

export const catalogSearchTermRequest = payload => ({
  type: types.CATALOG_SEARCH_TERM_REQUEST,
  payload,
})
export const catalogSearchTermSuccess = payload => ({
  type: types.CATALOG_SEARCH_TERM_SUCCESS,
  payload,
})
export const catalogSearchTermFailure = payload => ({
  type: types.CATALOG_SEARCH_TERM_FAILURE,
  payload,
})

// Catalog Advanced Search Actions
export const catalogAdvancedSearchRequest = payload => ({
  type: types.CATALOG_ADVANCED_SEARCH_REQUEST,
  payload,
})
export const catalogAdvancedSearchSuccess = payload => ({
  type: types.CATALOG_ADVANCED_SEARCH_SUCCESS,
  payload,
})
export const catalogAdvancedSearchFailure = payload => ({
  type: types.CATALOG_ADVANCED_SEARCH_FAILURE,
  payload,
})

export const catalogAdvancedSearchFilterAction = payload => ({
  type: types.CATALOG_ADVANCED_SEARCH_FILTERS,
  payload,
})
