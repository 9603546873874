import React from "react";
// import styles from "./Captcha.module.css";

import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

const Captcha = (props) => {
  return (
    <React.Fragment>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Ldvdq0cAAAAAE5np9fETWd8PqUtrnMCaahfbjNA"
      // onChange={this.handleCaptcha}
      onChange={props.handleClick}
      />
    </React.Fragment>
  );



}


export default Captcha;
