import * as types from '../types';

// Update Profile Actions
export const fileClusterDataRequest = (payload) => ({ type: types.FILE_CLUSTER_DATA_REQUEST, payload });
export const fileClusterDataSuccess = (payload) => ({ type: types.FILE_CLUSTER_DATA_SUCCESS, payload });
export const fileClusterDataFailure = (payload) => ({ type: types.FILE_CLUSTER_DATA_FAILURE, payload });

export const similarDocClusterDataRequest = (payload) => ({ type: types.SIMILAR_DOC_CLUSTER_DATA_REQUEST, payload });
export const similarDocClusterDataSuccess = (payload) => ({ type: types.SIMILAR_DOC_CLUSTER_DATA_SUCCESS, payload });
export const similarDocClusterDataFailure = (payload) => ({ type: types.SIMILAR_DOC_CLUSTER_DATA_FAILURE, payload });
