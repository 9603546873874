import * as types from '../types'

export const fetchAllNotificationsRequest = payload => ({
  type: types.FETCH_ALL_NOTIFICATION_REQUEST,
  payload,
})
export const fetchAllNotificationsSuccess = payload => ({
  type: types.FETCH_ALL_NOTIFICATION_SUCCESS,
  payload,
})
export const fetchAllNotificationsFailure = payload => ({
  type: types.FETCH_ALL_NOTIFICATION_FAILURE,
  payload,
})

export const markAllNotificationsReadRequest = payload => ({
  type: types.MARK_ALL_NOTIFICATIONS_READ_REQUEST,
  payload,
})
export const markAllNotificationsReadSuccess = payload => ({
  type: types.MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
  payload,
})
export const markAllNotificationsReadFailure = payload => ({
  type: types.MARK_ALL_NOTIFICATIONS_READ_FAILURE,
  payload,
})

export const markNotificationReadRequest = payload => ({
  type: types.MARK_NOTFICATION_READ_REQUEST,
  payload,
})
export const markNotificationReadSuccess = payload => ({
  type: types.MARK_NOTFICATION_READ_SUCCESS,
  payload,
})
export const markNotificationReadFailure = payload => ({
  type: types.MARK_NOTFICATION_READ_FAILURE,
  payload,
})

export const removeNotificationRequest = payload => {
  return {
    type: types.REMOVE_NOTIFICATION_REQUEST,
    payload,
  }
}
export const removeNotificationSuccess = payload => ({
  type: types.REMOVE_NOTIFICATION_SUCCESS,
  payload,
})
export const removeNotificationFailure = payload => ({
  type: types.REMOVE_NOTIFICATION_FAILURE,
  payload,
})
