import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      setTimeout(() => {
        window.location.replace("/dashboard");
      }, 3000);
      return <h3>Error occured</h3>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
