import React from "react";
import styles from "./DataCatalogAllFoldersTwo.module.css";
// import Sidebar from '../../shared/sidebar/Sidebar'
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import commonStyles from "../../shared/Common.module.css";
import RecentDocuments from "../../components/data-catalog/recent-documents/RecentDocuments";
import AllFolders from "../../components/data-catalog/all-folders/AllFolders";
import AllFolderList from "../../components/data-catalog-all-folders/all-folder-list/AllFolderList";
import SearchBlackIcon from "../../../src/assets/search_black.svg";
import search_grey_icon from "../../../src/assets/search_grey.svg";

import folders from "../../json-data/allFolders.json";
import recentDocuments from "../../json-data/recentDocuments.json";
import rightCards from "../../json-data/rightCards.json";
import green_document from "../../../src/assets/green_document.svg";
import ArrowUp from "../../../src/assets/arrow-up-grey.svg";
import { connect } from "react-redux";
import * as catalogSelectedFolder from "../../store/actions/catalogSelectedFolderAction";
import { formatDate } from "../../shared/formatDate";

import grey_bg_folder from "../../../src/assets/grey_bg_folder.svg";
import white_bg_folder from "../../../src/assets/white_bg_folder.svg";

import AllFoldersTableView from "../../components/all-folders-tree/all-folders-table-view/AllFoldersTableView";

import "antd/dist/antd.css";
import { Tree } from "antd";

const TreeFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={grey_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
);

const SelectedFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={white_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
);

class DataCatalogAllFoldersTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: [],
      data: [],
      newData: [],
      parentId: "",
      isLoading: false,
      files: [],
      folders: [],
      paths: "",
      searchItems: "",
    };
  }

  componentDidMount() {
    const paramsObj = {
      referenceId: this.props.referenceId,
      catalogSourceId: this.props.catalogSourceId,
      level: 0,
      isRoot: true,
    };
    this.props.catalogAllFoldersTreeData(paramsObj);
    this.props.catalogAllFoldersContent();
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.isSelectedFolderTreeDataLoading &&
      prevProps.isSelectedFolderTreeDataLoading &&
      this.props.catalogRecentFoldersData
    ) {
      let selectedFolder = []
      if (!this.props.isPrivate) {
        selectedFolder = this.props.catalogRecentFoldersData?.allFolders.filter(
          folder => {
            return folder.catalogSourceId === this.props.catalogSourceId
          }
        )
      } else if (this.props.isPrivate)
        selectedFolder = this.props.catalogRecentFoldersData?.privateFolder

      let formattedData = this.makeFolderTree(selectedFolder)
      this.setState({ data: formattedData })
    }

    if (
      !this.props.isSelectedFileTreeDataLoading &&
      prevProps.isSelectedFileTreeDataLoading &&
      this.props.catalogAllFoldersContentData
    ) {
      let formattedData = this.makeFolderTree(
        this.props.catalogAllFoldersContentData.folders
      );
      let rootNode = this.addChildrenToTree(
        this.state.data,
        formattedData,
        this.state.parentId
      );
      this.setState({
        newData: rootNode,
        isLoading: false,
        files: this.props.catalogAllFoldersContentData.files,
        folders: this.props.catalogAllFoldersContentData?.folders,
      });
    }
  }

  makeFolderTree(folders) {
    let folderTreeData = [];
    if (folders && folders.length > 0) {
      folders.map((folder, index) => {
        return folderTreeData.push({
          title: folder.name,
          key: `${folder.referenceId}${Math.random()}`,
          isLeaf: false,
          children: [],
          level: folder.level,
          referenceId: folder.referenceId,
          catalogSourceId: folder.catalogSourceId,
          icon: ({ selected }) =>
            selected ? <SelectedFolderIcon /> : <TreeFolderIcon />,
        });
      });
      return folderTreeData;
    }
  }

  addChildrenToTree(rootNode, newData, parentId) {
    if (rootNode && rootNode.length > 0) {
      rootNode.some(function iter(a) {
        if (a.key === parentId) {
          a.children = newData || [];
          return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
      });
      return rootNode;
    }
  }

  onSelect = (keys, event) => {
    if (this.state.parentId !== event.node.key) {
      this.setState({
        parentId: event.node.key,
        isLoading: true,
        paths: event.node.title,
      });
      const paramObj = {
        referenceId: event.node.referenceId,
        catalogSourceId: event.node.catalogSourceId,
        level: event.node.level,
      };
      this.props.catalogAllFoldersContent(paramObj);
    }
  };

  handleBackNavigation = () => {
    // this.props.history.push('/data-catalog')
    this.props.history.goBack()
  }
  handleUserInput = event => {
    var searchItems = event.target.value
    this.setState({ searchItems: event.target.value })
  }
  handleBackNavigationAllFolders = () => {
    this.props.history.push({
      pathname: '/all-folders-tree',
      search: '?source=data-catalog',
    })
  }

  render() {
    const { DirectoryTree } = Tree;
    const { data, isLoading, parentId, paths } = this.state;
    return (
      <React.Fragment>
        <div className={styles.data_catalog_all_folders_top_section}>
          <div className={styles.left_section}>
            <div
              className={styles.back_icon}
              onClick={this.handleBackNavigation}
            >
              <img src={ArrowUp} alt="Arrow Up" />
            </div>
            <div
              className={styles.folder_path}
              onClick={this.handleBackNavigationAllFolders}
            >
              All Folders /
            </div>
            <div className={styles.folder_name}>
              {this.props.history.location?.state?.fileName.replace(
                /%20/g,
                " "
              )}
            </div>
          </div>

          <div className={styles.right_section}>
            {/* {this.props.selectedFolderData?.metaData && this.props.selectedFolderData?.metaData.map((data, index) => {
                  return (
                    <div className={styles.right_section_card}>
                      <div className={styles.card_content}>
                        <div>
                          <img
                            className={styles.icon}
                            src={search_grey_icon}
                            alt="Search Icon"
                          />
                        </div>
                        <div className={styles.card_text_content}>
                          <div className={styles.name}>{data.name}</div>
                          <div className={styles.card_bottom_section}>
                            <div>{data.count} RESULTS</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })} */}
          </div>
        </div>
        <hr></hr>

        <div className={styles.recent_document_container}>
          <div className={styles.title_search_bar_container}>
            <div className={styles.title_container}>
              {/* <span className={styles.title}>Recent Documents</span>
                  <img src={green_document} alt="document"></img> */}
            </div>
            <div className={styles.search_bar_section}>
              <div className={styles.search_bar_container}>
                <input
                  placeholder="Search This Folder"
                  type="text"
                  className={styles.search_box}
                  onChange={(event) => this.handleUserInput(event)}
                ></input>
                <div className={styles.search_icon}>
                  <img
                    className={styles.icon}
                    src={SearchBlackIcon}
                    alt="Search Icon"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* recent folder start */}
          {/* {this.props.selectedFolderData?.recentFiles && (
                <RecentDocuments
                  recentDocuments={this.props.selectedFolderData.recentFiles}
                  parent={'DataCatalogAllFolder'}
                ></RecentDocuments>
              )} */}

          {/* recent folder end */}
        </div>
        <hr></hr>

        {/* here comes tree view start----------------*/}

        <div className={styles.data_container}>
          <div className={styles.tree_content}>
            <div className="folder_tree_container">
              {isLoading ? (
                <p>Loading....</p>
              ) : (
                <DirectoryTree
                  // onSelect={this.onSelect}
                  onExpand={this.onSelect}
                  defaultExpandedKeys={[parentId]}
                  defaultSelectedKeys={[parentId]}
                  treeData={data}
                />
              )}
            </div>
          </div>

          <div className={styles.table_content}>
            {this.props.catalogRecentFoldersData && this.state.files && (
              <AllFoldersTableView
                foldersData={folders.folders}
                files={this.state.files}
                folders={this.state.folders}
                searchItems={this.state.searchItems}
              ></AllFoldersTableView>
            )}
          </div>
        </div>

        {/* {this.props.selectedFolderData?.allFiles && this.state.allFilesData && (
              <div className={styles.all_folder_list_container}>
                <AllFolderList
                  foldersData={this.state.allFilesData}
                  searchItems={this.state.searchItems}
                  
                ></AllFolderList>
              </div>
            )} */}

        {/* here comes tree view end----------------*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    catalogRecentFoldersData:
      state.catalogSelectedFolder.selectedFolderTreeData,
    isSelectedFolderTreeDataLoading:
      state.catalogSelectedFolder.isSelectedFolderTreeDataLoading,
    isSelectedFileTreeDataLoading:
      state.catalogSelectedFolder.isSelectedFileTreeDataLoading,
    catalogAllFoldersContentData:
      state.catalogSelectedFolder.selectedFileTreeData,
  };
};
const mapDispatchToProps = {
  catalogAllFoldersTreeData:
    catalogSelectedFolder.catalogSelectedFolderTreeRequest,
  catalogAllFoldersContent:
    catalogSelectedFolder.catalogSelectedFileTreeRequest,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataCatalogAllFoldersTwo);
