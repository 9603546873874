import React, { Component } from "react";
import styles from "./InReviewFileParagraphs.module.css";
// import commonStyles from '../../../shared/Common.module.css';
import { connect } from 'react-redux';
import Highlighter from "react-highlight-words";

class InReviewFileParagraphs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 100,
            searchWords: [''],
        }
    }
    onResize = (resizeFactor) => {
        let nextSize = this.state.size + resizeFactor;
        if (nextSize > 150) {
            nextSize = 150
        }
        else if (nextSize <= 50) {
            nextSize = 50
        }
        this.setState({
            size: nextSize
        })
    }
    render() {
        var data = []
        let searchWords = []
        data.push(this.props.searchItem)
        if (this.props.searchItem.trim()) {
                searchWords= data
            
        }
        let clusteredResult = this.props.uploadedDoc.clusterData.data.result
    
        return (
            <div className={styles.doc_wrapper} >
                <div className={styles.doc_control_container}>
                    <div className={styles.doc_control_section}>
                        <div>
                            <i className={`fas fa-arrow-left ${styles.icon_style}`}></i>{this.props.uploadedDoc.clusterData.data.fileVersion.orgFileName}
                        </div>
                        {/* <div>
                    <i  className={`fas fa-arrow-left ${styles.icon_style}`}></i>IncomeTax_SCN.pdf
                    </div>  */}
                        <div>
                            <i onClick={() => this.onResize(10)} className={`fas fa-search-plus ${styles.icon_style}`}></i>
                            <i onClick={() => this.onResize(-10)} className={`fas fa-search-minus ${styles.icon_style}`}></i>
                        </div>
                    </div>
                </div>
                <div className={styles.doc_render_wrapper}>
                    <div className={styles.AgreeLeft}>
                        <div className={styles.AgreeBox} style={{ paddingRight: '120px', paddingLeft: '120px' }}>
                            {
                                clusteredResult.map((para, i) => {
                                   
                                    return (
                                        <figcaption key={i}>
                                            <article style={{ zoom: this.state.size + '%' }} >
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={searchWords}
                                                    autoEscape={true}
                                                    textToHighlight={para.paraText}
                                                    className="HighlightClass"
                                                />
                                            </article>
                                        </figcaption>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        uploadedDoc: state.uploadedDoc
    };
}
export default connect(mapStateToProps, null)(InReviewFileParagraphs);