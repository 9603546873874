import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as clusteredResult from '../actions/clusteredResultAction';

function* mainSubTagsWorker(params) {
    try {
        const data = yield call(_api, URLS.requestMainSubTags, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(clusteredResult.mainSubTagsSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(clusteredResult.mainSubTagsFailure(e));
    }
}

function* addFeedbackWorker(params) {
    try {
        const data = yield call(_api, URLS.requestAddFeedback, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(clusteredResult.addFeedbackSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(clusteredResult.addFeedbackFailure(e));
    }
}

function* mainSubTagsWatcher() {
    yield takeLatest(types.MAIN_SUB_TAGS_REQUEST, mainSubTagsWorker);
}
function* addFeedbackWatcher() {
    yield takeLatest(types.ADD_FEEDBACK_REQUEST, addFeedbackWorker);
}


export default function* clusteredResultSaga() {
    yield all([
        mainSubTagsWatcher(),
        addFeedbackWatcher()

    ]);
}