import React, { Component } from 'react';
import { connect } from 'react-redux';

import OutsideClickHandler from 'react-outside-click-handler';

import * as searchResultsPageActions from './../../../store/actions/catalogSearchResultsAction';

import Filter from '../../data-catalog/modals/filter/Filter';
import Sort from '../../data-catalog/modals/sort/Sort';
import RelatedSearches from '../../data-catalog/related-searches/RelatedSearches';
import Dropdown from '../../data-catalog/dropdown/Dropdown';
import SearchIcon from './../../../assets/search_green.svg';
import FolderOutlineGreen from './../../../assets/folder_outline_green.svg';
import FolderSolidBlue from './../../../assets/folder_solid_blue.svg';
import ArrowUpSolid from './../../../assets/arrow_up_solid.svg';
import ArrowDownSolid from './../../../assets/select_arrow.svg';
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import CommonStyles from './../../../shared/Common.module.css';
import data from '../../../json-data/searchResults.json';
import styles from './RelatedSearchesAndFolders.module.css';

class RelatedSearchesAndFolders extends Component {
  PARENT = 'RelatedSearchesAndFolders';

  /*
   * allDocumentData would be changed based on filters
   * would be carry forwarded to "RelatedSearchesPage"
   */
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
      showSortModal: false,
      alphabeticalSort: false,
      oldestDateSort: false,
      documentData: this.props.documentsData,
      allDocumentData: this.props.filteredDocuments
        ? this.props.filteredDocuments
        : this.props.allDocumentData,
      selectedFilters: this.props.allFilters,
      totalDocumentsCount: this.props.filteredDocuments
        ? this.props.filteredDocuments?.length
        : this.props.allDocumentData?.length,
    };
  }

  recentSearchesRef = React.createRef();
  filterRef = React.createRef();

  toggleFilter = () => {
    const { showFilterModal, showSortModal } = this.state;
    if (showSortModal) {
      this.setState({ showSortModal: false });
    }
    this.setState({
      showFilterModal: !showFilterModal,
    });
  };
  toggleSort = () => {
    const { showSortModal, showFilterModal } = this.state;
    if (showFilterModal) {
      this.setState({ showFilterModal: false });
    }
    this.setState({
      showSortModal: !showSortModal,
    });
  };

  closeFilterAndSort = () => {
    const { showFilterModal, showSortModal } = this.state;
    if (showFilterModal || showSortModal)
      this.setState({
        showFilterModal: false,
        showSortModal: false,
      });
  };

  handleViewAll = () => {
    const allFilters = this.filterRef.current.getCheckboxState();
    this.props.history.push('/related-searches-page', {
      searchTerm: this.props.searchTerm,
      filteredData: this.state.allDocumentData,
      allFilters,
    });
  };

  handleViewAllFolders = () => {
    // this.props.history.push('/all-folders-tree')
    this.props.history.push({
      pathname: '/all-folders-tree',
      search: '?source=data-catalog',
    });
  };

  handleFolderClick = (fileName, referenceId, catalogSourceId) => {
    this.props.history.push('/selected-folder', {
      fileName: fileName,
      referenceId: referenceId,
      catalogSourceId: catalogSourceId,
    });
  };
  alphabeticalSortToggle = () => {
    const { alphabeticalSort } = this.state;
    this.setState(
      {
        alphabeticalSort: !alphabeticalSort,
      },
      () => {
        this.recentSearchesRef.current.sortProjectByAlphabet();
      }
    );
  };

  dateSortToggle = () => {
    const { oldestDateSort } = this.state;
    this.setState(
      {
        oldestDateSort: !oldestDateSort,
      },
      () => {
        this.recentSearchesRef.current.sortProjectByDate();
      }
    );
  };

  handleKeywordClick = (selectedKeyword) => {
    console.log('handleKeywordClick', selectedKeyword);
    this.props.handleKeywordClick(selectedKeyword);
  };

  applyFilters = (allDocumentFilteredData) => {
    this.props.saveFilteredDocuments(allDocumentFilteredData);
    const allFilters = this.filterRef.current.getCheckboxState();
    this.props.saveFilters(allFilters);

    let areFiltersEmpty = true;
    for (const key in allFilters) {
      const value = allFilters[key];
      if (value.length) {
        areFiltersEmpty = false;
        break;
      }
    }

    if (allDocumentFilteredData.length === 0 && areFiltersEmpty) {
      this.setState(
        {
          allDocumentData: this.props.allDocumentData,
          totalDocumentsCount: this.props.allDocumentData.length,
        },
        () => {
          this.closeFilterAndSort();
        }
      );
    } else if (allDocumentFilteredData) {
      this.setState(
        {
          allDocumentData: allDocumentFilteredData,
          totalDocumentsCount: allDocumentFilteredData.length,
        },
        () => {
          this.closeFilterAndSort();
        }
      );
    }
  };

  clearFilters = () => {
    console.info('clearFilters');
    this.setState(
      {
        // documentData: this.props.documentsData,
        allDocumentData: this.props.allDocumentData,
        totalDocumentsCount: this.props.allDocumentData.length,
      },
      () => {
        this.closeFilterAndSort();
        this.props.saveFilteredDocuments(null);
        this.props.saveFilters(null);
      }
    );
  };
  render() {
    const { search_results, folders } = data;
    const {
      showSortModal,
      showFilterModal,
      alphabeticalSort,
      oldestDateSort,
      documentData,
      allDocumentData,
      totalDocumentsCount,
    } = this.state;
    const { filters, keywords } = this.props;
    let { selectedFilters } = this.state;

    selectedFilters = selectedFilters !== null ? selectedFilters : filters;
    // console.info('original filters: ', filters)
    // console.info('allDocuments', allDocumentData)

    return (
      <div className={styles.wrapper}>
        {this.props.documentsData?.length === 0 ? (
          <div className={styles.root}>No Documents Found!!</div>
        ) : (
          <div className={styles.root}>
            {/* Left Part */}
            <div className={styles.left}>
              <div className={styles.heading}>
                {/* Heading */}
                <div className={styles.heading__root}>
                  <div className={styles.heading__heading}>Search Results</div>
                  <div className={styles.heading__icon_div}>
                    <img
                      className={styles.heading__icon}
                      src={SearchIcon}
                      alt={'Search Icon'}
                    />
                  </div>
                </div>

                <OutsideClickHandler
                  onOutsideClick={() => this.closeFilterAndSort()}
                >
                  <div className={styles.dropdown_wrapper}>
                    <div className={styles.filter_wrapper}>
                      <div
                        className={styles.dropdown}
                        onClick={this.toggleFilter}
                      >
                        <Dropdown
                          backgroundColor={'#fafafa'}
                          value0={'Filter'}
                          height={'30px'}
                          fontSize={'18px'}
                          toggled={showFilterModal}
                        />
                      </div>
                      <div
                        style={
                          showFilterModal
                            ? { display: 'block' }
                            : { display: 'none' }
                        }
                      >
                        <Filter
                          filters={filters}
                          allDocumentData={this.props.allDocumentData}
                          applyFilters={(filteredData) =>
                            this.applyFilters(filteredData)
                          }
                          clearFilters={this.clearFilters}
                          allFilters={this.props.allFilters}
                          ref={this.filterRef}
                        />
                      </div>
                    </div>
                    <div className={styles.sort_wrapper}>
                      <div
                        className={styles.dropdown}
                        onClick={this.toggleSort}
                      >
                        <Dropdown
                          backgroundColor={'#fafafa'}
                          value0={'Sort'}
                          height={'30px'}
                          fontSize={'18px'}
                          toggled={showSortModal}
                        />
                      </div>
                      {showSortModal && (
                        <div
                          className={`${CommonStyles.card_layout} ${styles.sort_root}`}
                        >
                          <div className={styles.wrapper1}>
                            <div className={styles.sort_text}>Alphabetical</div>
                            <div className={styles.sort_tag}>A-Z</div>
                            <div
                              className={styles.icon_container}
                              onClick={this.alphabeticalSortToggle}
                            >
                              <IconContext.Provider
                                value={{ color: '#11256d', size: '18px' }}
                              >
                                <div>
                                  {alphabeticalSort ? (
                                    <BiDownArrowAlt />
                                  ) : (
                                    <BiUpArrowAlt />
                                  )}
                                </div>
                              </IconContext.Provider>
                            </div>
                          </div>
                          <div className={styles.wrapper1}>
                            <div className={styles.sort_text}>Date</div>
                            <div className={styles.sort_tag}>Oldest</div>
                            <div
                              className={styles.icon_container}
                              onClick={this.dateSortToggle}
                            >
                              <IconContext.Provider
                                value={{ color: '#11256', size: '18px' }}
                              >
                                <div>
                                  {oldestDateSort ? (
                                    <BiDownArrowAlt />
                                  ) : (
                                    <BiUpArrowAlt />
                                  )}
                                </div>
                              </IconContext.Provider>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>
              <RelatedSearches
                parent={this.PARENT}
                documentsData={allDocumentData}
                searchResults={search_results}
                history={this.props.history}
                ref={this.recentSearchesRef}
              />

              {totalDocumentsCount > 5 && (
                <div
                  onClick={this.handleViewAll}
                  className={styles.page_end_text}
                >
                  View all {totalDocumentsCount} results
                </div>
              )}
            </div>

            {/* Right Part */}
            <div className={styles.right}>
              <div className={styles.right_top}>
                <div className={styles.heading}>
                  {/* Heading */}
                  <div className={styles.heading__root}>
                    <div className={styles.heading__heading}>
                      Related Folders
                    </div>
                    <img src={FolderOutlineGreen} alt="Folder Outline Green" />
                  </div>
                </div>
                <div
                  onClick={this.handleViewAllFolders}
                  className={styles.subheading}
                >
                  View All
                </div>
                <div>
                  {/* Related Folders */}
                  <div className={styles.related_folder__root}>
                    {this.props?.recentFolders?.map((folder) => (
                      <div
                        onClick={() =>
                          this.handleFolderClick(
                            folder.name,
                            folder.referenceId,
                            folder.catalogSourceId
                          )
                        }
                        className={styles.related_folder__folder}
                      >
                        <div className={styles.related_folder__icon}>
                          <img src={FolderSolidBlue} alt="Folder Solid Blue" />
                        </div>
                        <div className={styles.related_folder__name}>
                          {folder.name.replace(/%20/g, ' ')}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.right_bottom}>
                <div className={styles.title}>Related Keywords</div>
                <div className={styles.keyword__root}>
                  {keywords?.map((item, index) => (
                    <div
                      onClick={() => {
                        this.handleKeywordClick(item);
                      }}
                      className={`keyword` + `-${index}`}
                    >
                      {item}&nbsp;&nbsp;&nbsp;
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allFilters: state.catalogSearchResult.catalogSearchResultFiltersData,
    filteredDocuments:
      state.catalogSearchResult.catalogSearchResultFilteredDocuments,
  };
};

const mapDispatchToProps = {
  saveFilteredDocuments:
    searchResultsPageActions.catalogSearchResultFilteredDocumentsAction,
  saveFilters: searchResultsPageActions.catalogSearchResultFilterAction,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(RelatedSearchesAndFolders);
