import * as types from '../types'

const initialState = {
  isLoading: false,
  fileAddedData: null,
  isFolderAddedLoading: false,
  folderAddedData: null,
  isFolderListLoading: false,
  folderListData: null,
  error: null,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // user dashboard Actions
    case types.PRIVATE_FOLDER_ADD_FILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        fileAddedData: null,
      }
    case types.PRIVATE_FOLDER_ADD_FILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        fileAddedData: action.payload.result,
      }
    }
    case types.PRIVATE_FOLDER_ADD_FILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }

    case types.PRIVATE_FOLDER_ADD_FOLDER_REQUEST:
      return {
        ...state,
        isFolderAddedLoading: true,
        folderAddedData: null,
      }
    case types.PRIVATE_FOLDER_ADD_FOLDER_SUCCESS: {
      return {
        ...state,
        isFolderAddedLoading: false,
        folderAddedData: action.payload.result,
      }
    }
    case types.PRIVATE_FOLDER_ADD_FOLDER_FAILURE:
      return {
        ...state,
        isFolderAddedLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }

    case types.PRIVATE_FOLDER_FOLDER_LIST_REQUEST:
      return {
        ...state,
        isFolderListLoading: true,
        folderListData: null,
      }
    case types.PRIVATE_FOLDER_FOLDER_LIST_SUCCESS: {
      return {
        ...state,
        isFolderListLoading: false,
        folderListData: action.payload.result,
      }
    }
    case types.PRIVATE_FOLDER_FOLDER_LIST_FAILURE:
      return {
        ...state,
        isFolderListLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }

    default: {
      return state
    }
  }
}
