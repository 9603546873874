import React, { Component } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { IconContext } from 'react-icons';

import styles from './Dropdown.module.css';

export default class Dropdown extends Component {
  render() {
    const {
      backgroundColor,
      value0,
      fontSize,
      width,
      height,
      borderRadius,
      toggled,
      padding,
      iconSize = '24px',
      textColor,
    } = this.props;
    return (
      <div
        className={styles.dropdown}
        style={{
          backgroundColor,
          width,
          height,
          fontSize,
          borderRadius,
          padding,
        }}
      >
        <div
          className={styles.text}
          style={
            toggled
              ? { color: '#11256d' }
              : { color: textColor ? textColor : '#7a7a7a' }
          }
        >
          {value0}
        </div>
        <div className={styles.icon}>
          <IconContext.Provider
            value={
              toggled
                ? { color: '#11256d', size: `${iconSize}` }
                : { color: '#7a7a7a', size: `${iconSize}` }
            }
          >
            {toggled ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </IconContext.Provider>
        </div>
      </div>
    );
  }
}
