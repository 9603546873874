import React, { Component } from 'react';
import styles from './DisplayDocument.module.css';
// import commonStyles from '../../shared/Common.module.css';
import CommonModal from '../../shared/common-modal/CommonModal';
import Loader from '../../shared/loader/Loader';
import FileViewer from 'react-file-viewer';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class DisplayDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 100,
      pageNumber: 1,
      numPages: null,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  renderPages = () => {
    return Array.from({ length: this.state.numPages }, (el, i) => {
      return <Page loading={''} pageNumber={i + 1} />;
    });
  };

  componentDidUpdate() {}
  onResize = (resizeFactor) => {
    let nextSize = this.state.size + resizeFactor;
    if (nextSize > 150) {
      nextSize = 150;
    } else if (nextSize <= 50) {
      nextSize = 50;
    }
    this.setState({
      size: nextSize,
    });
  };
  renderLoader = () => {
    return <Loader></Loader>;
  };

  render() {
    const { fileName, showFileUrl } = this.props;
    const { size } = this.state;
    const fileType = showFileUrl.split('.').pop();
    return (
      <React.Fragment>
        <CommonModal
          modalClass={'display_document_modal'}
          closeHandler={this.props.closeHandler}
        >
          <div className={styles.top_actions_strip}>
            <div className={styles.actions_strip_section}>
              <div className={styles.file_name}>{fileName}</div>
              <div>
                <div className={styles.resize_icons}>
                  <i
                    onClick={() => this.onResize(10)}
                    className={`fas fa-search-plus ${styles.icon_style}`}
                  ></i>
                  {size !== 100 && <span>{size}%</span>}
                  <i
                    onClick={() => this.onResize(-10)}
                    className={`fas fa-search-minus ${styles.icon_style}`}
                  ></i>
                  <i
                    onClick={this.props.closeHandler}
                    className={`fas fa-times ${styles.cut_icon}`}
                  ></i>
                </div>

                <div
                  style={{ zoom: this.state.size + '%' }}
                  className={styles.doc_render_wrapper}
                ></div>
              </div>
            </div>
          </div>
          <div style={{ zoom: this.state.size + '%' }}>
            {fileType === 'docx' && (
              <FileViewer
                fileType={fileType}
                // filePath={this.props.uploadDoc.docData.data.data.orgFilePath}
                // filePath={"https://cors-anywhere.herokuapp.com/" + fileUrl}
                onError={this.onError}
                filePath={showFileUrl}
                // onError={this.onError}
              />
            )}

            {fileType.toLowerCase() === 'pdf' && (
              <Document
                file={showFileUrl}
                onLoadSuccess={this.onDocumentLoadSuccess}
                loading={this.renderLoader}
              >
                {this.state.numPages && this.renderPages()}
              </Document>
            )}
          </div>
        </CommonModal>
      </React.Fragment>
    );
  }
}

export default DisplayDocument;
