import React from 'react'
import styles from './RecentSearches.module.css'
import search_grey from '../../../assets/search_grey.svg'
import { formatDate } from '../../../shared/formatDate'

class RecentSearches extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onRecentSearchClick = (searchTerm) =>{
    this.props.onRecentSearchClick(searchTerm)
  }

  render() {
    return (
      <React.Fragment>
        {this.props.recentSearches.map(data => {
          const timeAndDate = formatDate(data.modified)
          return (
            <div onClick={()=>{this.onRecentSearchClick(data.searchTerm)}} className={styles.recent_search_container}>
              <div className={styles.recent_search_content}>
                <img
                  className={styles.recent_search_icon}
                  src={search_grey}
                  alt="search"
                ></img>
                <span className={styles.recent_search_text}>
                  {' '}
                  {data.searchTerm}{' '}
                </span>
                <div className={styles.recent_search_date}>
                  {timeAndDate.date}
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }
}

export default RecentSearches
