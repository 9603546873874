import React, { Component } from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { IconContext } from 'react-icons'

import SearchBar from '../search-bar/SearchBar'
import AdvancedSearch from './../modals/advanced-search/AdvancedSearch'
import SourceIcon from './../../../assets/source.svg'
import styles from './SearchBarAndDropDown.module.css'

export default class SearchBarAndDropDown extends Component {
  advancedSearchRef = React.createRef()
  searchBarRef = React.createRef()

  state = {
    showAdvancedSearchPanel: false,
  }

  toggleAdvancedSearch = () => {
    const { showAdvancedSearchPanel } = this.state
    this.setState({
      showAdvancedSearchPanel: !showAdvancedSearchPanel,
    })
  }

  handleAdvancedSearchFilter = filters => {
    this.props.handleAdvancedSearchFilter(filters)
  }

  clearAll = () => {
    this.advancedSearchRef.current.clearAll()
  }
  getSearchTerm = () => {
    return this.searchBarRef.current.getSearchTerm()
  }
  getAdvancedSearchState = () => {
    return this.advancedSearchRef.current.getAdvancedSearchState()
  }

  render() {
    // console.log("jj", this.props.searchKeyword)
    const { showAdvancedSearchPanel } = this.state
    const advancedSearchData = this.props.advancedSearchData
    const {parent} = this.props
    // console.info(advancedSearchData)

    return (
      <div className={styles.root}>
        <div
          className={styles.top_part_wrapper}
          style={
            showAdvancedSearchPanel
              ? { backgroundColor: '#fff', padding: '0' }
              : {}
          }
        >
          <div
            className={styles.top}
            style={
              showAdvancedSearchPanel
                ? { borderRadius: '0px', borderBottom: '1px solid #eaeaea' }
                : {}
            }
          >
            <div
              className={styles.left}
              style={showAdvancedSearchPanel ? { borderRadius: '0px' } : {}}
            >
              <SearchBar
                iconColor={'#2c2c2c'}
                iconSize={'24px'}
                placeholder={this.props.placeholder}
                fontSize={'18px'}
                leftMargin={'10px'}
                handleSearch={searchItems => {
                  this.props.handleSearch(searchItems)
                }}
                searchKeyword={this.props.searchKeyword}
                ref={this.searchBarRef}
              />
            </div>
            <div className={styles.right}>
              <div
                className={styles.advanced_search}
                onClick={this.toggleAdvancedSearch}
                style={showAdvancedSearchPanel ? { borderRadius: '0px' } : {}}
              >
                <div
                  className={styles.advanced_search_text}
                  style={
                    showAdvancedSearchPanel
                      ? { color: '#11256d' }
                      : { color: '#7a7a7a' }
                  }
                >
                  Advanced Search
                </div>
                <div className={styles.advanced_search_icon}>
                  <IconContext.Provider
                    value={
                      showAdvancedSearchPanel
                        ? { color: '#11256d', size: '24px' }
                        : { color: '#7a7a7a', size: '24px' }
                    }
                  >
                    {showAdvancedSearchPanel ? (
                      <div>
                        <IoMdArrowDropup />
                      </div>
                    ) : (
                      <div>
                        <IoMdArrowDropdown />
                      </div>
                    )}
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>

          {showAdvancedSearchPanel && (
            <AdvancedSearch
              ref={this.advancedSearchRef}
              data={advancedSearchData}
              getAdvancedSearchData={this.props.getAdvancedSearchData}
              parent={parent}
              resetDataOnPage={this.props.resetDataOnPage}
              closeAdvancedSearchPanel={this.toggleAdvancedSearch}
            />
          )}
        </div>

        {this.props.showSearchTerm && (
          <div className={styles.bottom_container}>
            <div className={styles.bottom_left}>
              <span className={styles.search_result_title}>
                Search Results on:{' '}
              </span>
              <span className={styles.search_keyword}>
                {this.props.searchKeyword}
              </span>
            </div>
            <div className={styles.bottom_right}>
              <div className={styles.source_text}>SOURCES</div>
              <img
                className={styles.source_icon}
                src={SourceIcon}
                alt="Sources Icon"
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
