import React, { Component } from "react";
import styles from "./CreateTeam.module.css";
import commonStyles from '../../../shared/Common.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal'
import Select from 'react-select';
import { connect } from 'react-redux';
import ErrorText from '../../../shared/error-text/ErrorText';

class CreateTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            selectedVisibility: '',
            selectedMembers: '',
            errors: {
                name:'',
            }
        }
    }

    handleVisibilityChange = selectedVisibility => {
        this.setState({ selectedVisibility });
    };
    handleMembersChange = selectedMembers => {
        this.setState({ selectedMembers });
    };
    handleUserInput = (e) => {

        const name = e.target.name;
        const value = e.target.value;
        let errors = this.state.errors;
        if (value && value !== '') {
            errors[name] = ''
        }
        this.setState({ errors });
        this.setState({ [name]: value })

    }
getSelectedMemberIds = () =>{
    if(this.state.selectedMembers.length>0){
   let ids = this.state.selectedMembers.map(member => {
        return(  member.value)
      })
      return ids
}
}
getErrors = () => {
    const { name} = this.state
    let errors = this.state.errors;
    if (name === '') {
        errors.name = "Name is Required!"
    }
    
    let errorCount=0
    for (const property in this.state.errors) {
        if(this.state.errors[property].length>1){
            errorCount++;
        }
      }
    
    this.setState({ errors: errors})
    return(errorCount>0)
}
    handleCreateTeam = () => {
        const isFormInvalid =  this.getErrors()
        if(!isFormInvalid){
        const { name } = this.state;
        let ids = this.getSelectedMemberIds()       
        const paramsObj = {
            teamId: 'dfu39xo36kdy7rpuh',
            team_name: name,
            visibility: this.state.selectedVisibility.value,
            team_members:ids?.toString()
        }
        this.props.oncreateTeam(paramsObj)
    }

    }
    getVisibilityOptions = () =>{
        const visibilityDropdown = this.props.dropdowns.filter(dropdown => {
            if(dropdown.type==="TEAM_VISIBILITY")
            return dropdown.values
          })
       const visibilityOptions =  visibilityDropdown[0].values.map(item => {

            return ({
                value: item.key,
                label: item.value,
            })
        })
       return visibilityOptions
    }
    getMemberOptions = () =>{
        const memberDropdown = this.props.dropdowns.filter(dropdown => {
            if(dropdown.type==="USERS")
            return dropdown.values
          })
       const memberOptions =  memberDropdown[0].values.map(item => {

            return ({
                value: item.key,
                label: item.value,
            })
        })
       return memberOptions
    }

    render() {
        const { name,selectedVisibility,selectedMembers, errors } = this.state
        let visibilityOptions = []
        let memberOptions = []
        if(this.props.dropdowns && this.props.dropdowns.length){
            visibilityOptions = this.getVisibilityOptions()
            memberOptions = this.getMemberOptions()
       }
        return (
            <div>
                <CommonModal modalClass={'team_common_modal'} closeHandler={this.props.closeHandler} header={'Create Team'}>
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <p className={commonStyles.common_form_label}>ENTER TEAM NAME</p>
                                <input
                                    className={`form-control ${commonStyles.common_form_text_field}`}
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(event) => this.handleUserInput(event)}>
                                </input>
                                {errors.name.length > 0 && <ErrorText text={errors.name} />}
                            </div>
                            <div className="col-md-6">
                                <p className={commonStyles.common_form_label}>CHOOSE VISIBILITY</p>
                                <Select
                                    value={selectedVisibility}
                                    onChange={this.handleVisibilityChange}
                                    options={visibilityOptions}
                                />
                            </div>

                        </div>
                        <div className={`row ${styles.row_gap}`}>
                            <div className="col-md-12">
                                <p className={commonStyles.common_form_label}>ADD MEMBER</p>
                                <Select
                                    value={selectedMembers}
                                    onChange={this.handleMembersChange}
                                    options={memberOptions}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                        <div className={styles.btn_wrapper}>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                                onClick={this.props.closeHandler}>
                                CANCEL
                      </button>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                                onClick={this.handleCreateTeam}>
                                CREATE
                      </button>
                        </div>
                    </form>

                </CommonModal>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dropdowns: state.team.teamList?.data?.dropdown, 
    };
}

export default connect(mapStateToProps, null)(CreateTeam);
