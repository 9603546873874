import * as types from '../types'

const initialState = {
  new: null,
  isLoading: false,
  markAllNotificationLoading: false,
  markNotificationLoading: false,
  markNotificationReadData: null,
  error: null,
  messages: [],
  isNotificationRemoveLoading: false,
  notificationRemoveData: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        messages: [],
      }
    case types.FETCH_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        new: action.payload.new,
        messages: action.payload.result,
        isLoading: false,
        error: null,
      }
    case types.FETCH_ALL_NOTIFICATION_FAILURE:
      return {
        ...state,
        new: null,
        error: action.payload,
      }
    case types.MARK_ALL_NOTIFICATIONS_READ_REQUEST:
      return {
        ...state,
        markAllNotificationLoading: true,
      }
    case types.MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
      return {
        ...state,
        markAllNotificationLoading: false,
        error: null,
      }
    case types.MARK_ALL_NOTIFICATIONS_READ_FAILURE:
      return {
        ...state,
        error: action.payload,
        markAllNotificationLoading: false,
      }
    case types.REMOVE_NOTIFICATION_REQUEST:
      return {
        ...state,
        isNotificationRemoveLoading: true,
      }
    case types.REMOVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isNotificationRemoveLoading: false,
        notificationRemoveData: action.payload.result.data,
      }
    case types.REMOVE_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isNotificationRemoveLoading: false,
      }
    case types.MARK_NOTFICATION_READ_REQUEST:
      return {
        ...state,
        markNotificationLoading: true,
      }
    case types.MARK_NOTFICATION_READ_SUCCESS:
      return {
        ...state,
        markNotificationLoading: false,
        markNotificationReadData: action.payload.result.data,
        error: null,
      }
    case types.MARK_NOTFICATION_READ_FAILURE:
      return {
        ...state,
        markNotificationLoading: false,
        error: action.payload,
      }
    default: {
      return state
    }
  }
}
