import React, { Component } from 'react';

import NavBar from './../workflow-management/NavBar/NavBar';
import Sidebar from './../workflow-management/Sidebar/Sidebar';
import RightBar from './../workflow-management/RightBar/RightBar';

import Chart1 from '../../../src/assets/Chart1.svg';
import Chart2 from '../../../src/assets/Chart2.svg';
import Chart3 from '../../../src/assets/Chart3.svg';
import MasterListWhiteSVG from '../../../src/assets/master_list_icon_white.svg';
import MasterListGreySVG from '../../../src/assets/master_list_icon_grey.svg';
import MasterListSVG from '../../../src/assets/master_list_icon.svg';
import ArrowUp from '../../../src/assets/arrow-up-grey.svg';
import commonStyles from '../../shared/Common.module.css';
import styles from './MasterList.module.css';

export default class MasterList extends Component {
  state = {
    buttonSelected: 0,
  };
  handleBackNavigation = () => {
    this.props.history.goBack();
  };
  buttonChangeHandler = (index) => {
    const { buttonSelected } = this.state;
    this.setState({
      buttonSelected: index,
    });
  };
  render() {
    const { buttonSelected } = this.state;

    return (
      <>
        <NavBar />
        <Sidebar />
        <RightBar />
        <div
          className={`${commonStyles.common_workflow_management_wrapper} ${styles.root}`}
          style={buttonSelected === 2 ? { height: '100vh' } : {}}
        >
          <div className={styles.relative}>
            <div
              className={styles.back_icon}
              onClick={this.handleBackNavigation}
            >
              <img src={ArrowUp} alt="Arrow Up" />
            </div>
          </div>

          <div className={styles.header_wrapper}>
            <div className={styles.header} onClick={this.handleBackNavigation}>
              <div>Data Catalog/</div>

              <div className={styles.heading}>Master List</div>
            </div>
          </div>

          <section className={styles.recent_folders}>
            <div className={styles.recent_folders_heading}>
              <div>Corporate and International Tax</div>
              <img src={MasterListSVG} alt="MasterListSVG" />
            </div>
            <div className={styles.buttons}>
              {[
                'International Taxation',
                'Domestic Tax Laws',
                'Constitutional Validity',
              ].map((text, index) => (
                <div
                  className={styles.button}
                  style={
                    buttonSelected === index
                      ? {
                          backgroundColor: '#11256d',
                          color: '#fff',
                        }
                      : { backgroundColor: '#fff', color: '#000' }
                  }
                  onClick={() => this.buttonChangeHandler(index)}
                >
                  <img
                    src={
                      buttonSelected === index
                        ? MasterListWhiteSVG
                        : MasterListGreySVG
                    }
                    alt="Master List Icon"
                  />
                  <div>{text}</div>
                </div>
              ))}
            </div>

            <div className={styles.source_and_issues}>
              <div>SCOPE</div>
              <div>MAIN ISSUE</div>
              <div>SUB ISSUES</div>
            </div>
          </section>

          <section className={styles.chart_section}>
            {buttonSelected === 0 && <img src={Chart1} alt="Chart" />}
            {buttonSelected === 1 && <img src={Chart2} alt="Chart" />}
            {buttonSelected === 2 && <img src={Chart3} alt="Chart" />}
          </section>
        </div>
      </>
    );
  }
}
