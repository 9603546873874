import * as types from '../types';

// User Dashboard Actions

export const userDashboardRequest = (payload) => ({ type: types.USER_DASHBOARD_REQUEST, payload });
export const userDashboardSuccess = (payload) => ({ type: types.USER_DASHBOARD_SUCCESS, payload });
export const userDashboardFailure = (payload) => ({ type: types.USER_DASHBOARD_FAILURE, payload });

// Advance Search Actions

export const advanceSearchRequest = (payload) => ({ type: types.ADVANCE_SEARCH_REQUEST, payload });
export const advanceSearchSuccess = (payload) => ({ type: types.ADVANCE_SEARCH_SUCCESS, payload });
export const advanceSearchFailure = (payload) => ({ type: types.ADVANCE_SEARCH_FAILURE, payload });

// Recent Search Actions

export const recentSearchRequest = (payload) => ({ type: types.RECENT_SEARCH_REQUEST, payload });
export const recentSearchSuccess = (payload) => ({ type: types.RECENT_SEARCH_SUCCESS, payload });
export const recentSearchFailure = (payload) => ({ type: types.RECENT_SEARCH_FAILURE, payload });

// File Read Actions

export const fileReadRequest = (payload) => ({ type: types.FILE_READ_REQUEST, payload });
export const fileReadSuccess = (payload) => ({ type: types.FILE_READ_SUCCESS, payload });
export const fileReadFailure = (payload) => ({ type: types.FILE_READ_FAILURE, payload });

// Report Analysis Graph Actions

export const reportAnalysisGraphRequest = (payload) => ({ type: types.REPORT_ANALYSIS_GRAPH_REQUEST, payload });
export const reportAnalysisGraphSuccess = (payload) => ({ type: types.REPORT_ANALYSIS_GRAPH_SUCCESS, payload });
export const reportAnalysisGraphFailure = (payload) => ({ type: types.REPORT_ANALYSIS_GRAPH_FAILURE, payload });