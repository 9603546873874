import React from 'react';
import styles from './AddSourceModal.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';
import DriveIcon from '../../../assets/data_source_drive.svg';
import CloudUploadIcon from '../../../assets/cloud_upload.svg';
import GoogleDiveIcon from '../../../assets/google_drive_colored.svg';
import OneDiveIcon from '../../../assets/onedrive_colored_icon.svg';
import DropboxIcon from '../../../assets/dropbox_colored_icon.svg';
import LocalDeviceImage from './../../../assets/local_device.png';
import s3Icon from '../../../assets/aws_s3_icon.svg';
import gcpStorageIcon from '../../../assets/gcp_storage.svg';
import blobStorageIcon from '../../../assets/azure-storage-blob.svg';

class AddSourceModal extends React.Component {
  render() {
    const {
      sourceModalHandler,
      handleGDriveAuth,
      handleOneDriveAuth,
      handleDropboxAuth,
      handleLocalFiles,
    } = this.props;
    return (
      <CommonModal
        modalClass={'data_source_common_modal'}
        closeHandler={sourceModalHandler}
        backdropClassName={'data_source_model_blue_bg'}
        header={
          <div className={`${styles.modal_header_container}`}>
            <h4 className={`${styles.modal_header_heading}`}>
              Choose Repository
            </h4>
            <img
              src={DriveIcon}
              alt="Drive icon"
              className={`${styles.drive_icon}`}
            />
          </div>
        }
      >
        <div className={styles.all_source_container}>
          <div className={styles.single_source_container}>
            <img
              src={CloudUploadIcon}
              alt="data upload"
              className={`${styles.source_icon}`}
            />
            <p className={`${styles.source_name}`}>Upload</p>
          </div>

          <div
            className={styles.single_source_container}
            onClick={handleGDriveAuth}
          >
            <img
              src={GoogleDiveIcon}
              alt="Google Drive"
              className={`${styles.source_icon}`}
            />
            <p className={`${styles.source_name}`}>Google Drive</p>
          </div>

          <div
            className={styles.single_source_container}
            onClick={handleDropboxAuth}
          >
            <img
              src={DropboxIcon}
              alt="Dropbox"
              className={`${styles.source_icon}`}
            />
            <p className={`${styles.source_name}`}>Dropbox</p>
          </div>

          <div
            className={styles.single_source_container}
            onClick={handleOneDriveAuth}
          >
            <img
              src={OneDiveIcon}
              alt="One Drive"
              className={`${styles.source_icon}`}
            />
            <p className={`${styles.source_name}`}>OneDrive</p>
          </div>
          <div className={styles.single_source_container}>
            <img
              src={LocalDeviceImage}
              alt="One Drive"
              className={`${styles.source_icon}`}
            />
            <label
              for="file-upload"
              class="custom-file-upload"
              className={`${styles.source_name}`}
            >
              Device Files
            </label>
            <input
              id="file-upload"
              className={styles.local_files_input}
              directory=""
              webkitdirectory=""
              type="file"
            />
          </div>

          <div className={styles.single_source_container}>
            <img
              src={s3Icon}
              alt="aws s3"
              className={`${styles.source_icon_small}`}
            />
            <p className={`${styles.source_name}`}>AWS S3</p>
          </div>
          <div className={styles.single_source_container}>
            <img
              src={gcpStorageIcon}
              alt="aws s3"
              className={`${styles.source_icon_small}`}
            />
            <p className={`${styles.source_name}`}>GCP Storage</p>
          </div>

          <div className={styles.single_source_container}>
            <img
              src={blobStorageIcon}
              alt="aws s3"
              className={`${styles.source_icon}`}
            />
            <p className={`${styles.source_name}`}>Blob Storage</p>
          </div>
          <div className={styles.single_source_container}>Source</div>
          <div className={styles.single_source_container}>Source</div>
        </div>
      </CommonModal>
    );
  }
}

export default AddSourceModal;
