import React from 'react';
import styles from './DocumentAnalytics.module.css';
import { IconContext } from 'react-icons';
// import { Steps } from 'antd';
import BaloonSVG from './../../../assets/baloon_green.svg';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import selected_three_dot_icon from '../../../assets/selected_three_dot_icon.svg';
import three_dot_icon from '../../../assets/three_dot_icon.svg';
import SearchBar from './../../data-catalog/search-bar/SearchBar';
import moment from 'moment';
// import Tooltip from 'react-bootstrap/Tooltip';
import OutsideClickHandler from 'react-outside-click-handler';

// const { Step } = Steps;

class EditHistory extends React.Component {
  constructor(props) {
    super(props);
    this.editHistoryRef = React.createRef();
    this.editOpenHistoryRef = React.createRef();
  }

  render() {
    const {
      lastEditOpen,
      editHistoryData,
      filerSearchValue,
      filteredSubTags,
      isTagSelected,
      handleLastEdit,
      formatDate,
      selectedAction,
      closeActionPopup,
      openActionPopup,
      handleSearchTags,
      handleTagClick,
      restoreHistory,
      handleHistoryActiveDot,
      historyActiveDot,
      handleScrollBar,
    } = this.props;
    return (
      <div className={styles.right_container_container}>
        {editHistoryData.length > 1 ? (
          <span
            className={styles.right_container_header}
            onClick={handleLastEdit}
          >
            <span>LAST EDIT</span>
            {lastEditOpen ? (
              <IconContext.Provider
                value={{
                  size: 21,
                  color: '#7A7A7A',
                }}
              >
                <IoIosArrowUp />
              </IconContext.Provider>
            ) : (
              <IconContext.Provider
                value={{
                  size: 21,
                  color: '#7A7A7A',
                }}
              >
                <IoIosArrowDown />
              </IconContext.Provider>
            )}
          </span>
        ) : null}

        {lastEditOpen ? (
          <div
            className={styles.right_container_steps_open}
            ref={this.editOpenHistoryRef}
            onScroll={() =>
              handleScrollBar(
                this.editOpenHistoryRef,
                'document_analytics_edit_history_container'
              )
            }
          >
            {editHistoryData &&
              editHistoryData.map((data, index) => (
                <div className={styles.right_container_step}>
                  <div
                    className={styles.steps_dot_and_date_container}
                    onClick={() => {
                      handleHistoryActiveDot(index);
                      restoreHistory(data.fileVersion);
                    }}
                  >
                    <div
                      className={
                        historyActiveDot === index
                          ? styles.steps_left_green_dot
                          : styles.steps_left_green_dot_inactive
                      }
                    />
                    <div
                      className={
                        historyActiveDot === index
                          ? styles.steps_middle_active
                          : null
                      }
                    >
                      {`${formatDate(data.createdAt)} • ${moment(
                        data.createdAt
                      ).format('hh:mm A')}`}
                      {data.fileVersion === 'initial' ? (
                        <div>clustered</div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    {selectedAction !== index + 1 ? (
                      <img
                        className={styles.last_edit_three_dot_icon}
                        onClick={() => openActionPopup(index + 1)}
                        src={three_dot_icon}
                        alt="three_dot_icon"
                      />
                    ) : (
                      <OutsideClickHandler onOutsideClick={closeActionPopup}>
                        <img
                          className={styles.last_edit_three_dot_icon_active}
                          onClick={(e) => openActionPopup(e, index + 1)}
                          src={selected_three_dot_icon}
                          alt="three_dot_icon"
                        />
                        <div
                          className={
                            data.fileVersion === 'initial'
                              ? styles.last_edit_action_popup_initial
                              : styles.last_edit_action_popup
                          }
                        >
                          <div
                            className={styles.last_edit_action_popup_tag}
                            onClick={() => {
                              handleHistoryActiveDot(index);
                              restoreHistory(data.fileVersion);
                            }}
                          >
                            <span
                              className={styles.last_edit_action_popup_text}
                            >
                              Restore this version
                            </span>
                          </div>
                          <div className={styles.last_edit_action_popup_tag}>
                            <span
                              className={styles.last_edit_action_popup_text}
                            >
                              Rename This Version
                            </span>
                          </div>
                          <div className={styles.last_edit_action_popup_tag}>
                            <span
                              className={styles.last_edit_action_popup_text}
                            >
                              Make A Copy
                            </span>
                          </div>
                        </div>
                      </OutsideClickHandler>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <>
            {editHistoryData.length > 1 ? (
              <div className={styles.date_container}>
                <img src={BaloonSVG} alt="Baloon Green" />
                {editHistoryData.length > 0 ? (
                  <span>{`${formatDate(
                    editHistoryData[0].createdAt
                  )} • ${moment(editHistoryData[0].createdAt).format(
                    'hh:mm A'
                  )}`}</span>
                ) : (
                  <div></div>
                )}
              </div>
            ) : null}
          </>
        )}

        {lastEditOpen ? null : (
          <div
            ref={this.editHistoryRef}
            className={
              editHistoryData.length > 0
                ? styles.search_bar_and_chips
                : styles.search_bar_and_chips_height
            }
            onScroll={() =>
              handleScrollBar(this.editHistoryRef, 'edit_history_scroll_active')
            }
          >
            <div className={styles.search_bar_background}>
              <div className={styles.search_bar_wrapper}>
                <SearchBar
                  iconColor={'#2c2c2c'}
                  iconSize={'18px'}
                  fontSize={'15px'}
                  placeholder={'Search Tags'}
                  handleSearch={() => {}}
                  searchKeyword={filerSearchValue}
                  handleSearchChange={(searchItems) => {
                    handleSearchTags(searchItems);
                  }}
                />
              </div>
            </div>
            <div className={styles.chips_container}>
              {filteredSubTags &&
                filteredSubTags.map((subTag) => (
                  <span
                    key={subTag}
                    onClick={() => handleTagClick(subTag)}
                    className={`${
                      isTagSelected.includes(subTag)
                        ? styles.selected_sub_tag
                        : null
                    }`}
                  >
                    {subTag}
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EditHistory;
