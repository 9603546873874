import React, { Component } from 'react'

import styles from './CheckboxListFilter.module.css'

export default class CheckboxListFilter extends Component {
  /* tagSelected: [array of corresponding selected value] */
  state = {
    [this.props.tagSelected]: [],
  }

  componentDidMount() {
    const allFilters = this.props.allFilters
    this.setState({
      ...allFilters,
    })
  }
  /* adding empty array when tag is selected */
  componentDidUpdate(prevProps) {
    if (
      (prevProps.tagSelected !== undefined || prevProps.tagSelected !== null) &&
      !this.state.hasOwnProperty(this.props.tagSelected)
    ) {
      this.setState({
        [this.props.tagSelected]: [],
      })
    }
  }
  resetState = () => {
    Object.keys(this.state).forEach(key => (this.state[key] = []))
    this.setState(this.state)
  }

  onValueChange = (e, value) => {
    const selectedCheckboxes = this.state[this.props.tagSelected]

    // if (selectedCheckboxes ) return

    if (selectedCheckboxes.includes(value) && !e.target.checked) {
      selectedCheckboxes.splice(selectedCheckboxes.indexOf(value), 1)
    } else if (!selectedCheckboxes.includes(value) && e.target.checked) {
      selectedCheckboxes.push(value)
    }
    this.setState({
      [this.props.tagSelected]: selectedCheckboxes,
    })
  }

  getState = () => {
    return this.state
  }
  render() {
    const {
      data,
      tagSelected,
      filteredList,
      showSelected,
      width = 18,
      height = 18,
      margin,
      backgroundColor = '#eaeaea',
    } = this.props

    let listData = []
    if (filteredList) listData = filteredList
    else if (data) listData = data[tagSelected]?.children.sort()

    if (showSelected) {
      listData = this.state[tagSelected]
    }

    return (
      <>
        {listData?.map((item, index) => (
          <div className={styles.checkbox_container} key={index}>
            <div
              className={styles.round}
              style={{
                width,
                height,
              }}
            >
              <input
                type={'checkbox'}
                id={tagSelected + '--' + index}
                onChange={e =>
                  this.onValueChange(e, item)
                } /* value={this.state.selectedValues[index]} */
                name={''}
                value={item}
                checked={this.state[this.props.tagSelected]?.includes(item)}
                backgroundColor
              />
              <label
                for={tagSelected + '--' + index}
                style={{
                  width,
                  height,
                }}
              ></label>
            </div>
            <label
              for={tagSelected + '--' + index}
              className={styles.checkbox_title}
            >
              {item}
            </label>
          </div>
        ))}
      </>
    )
  }
}
