import { call, put, takeLatest, all } from "redux-saga/effects";

import * as types from "../types";
import _api from "../../services/api";
import URLS from "../../services/urls";
import * as support from "../actions/supportActions";

function* allTicketsWorker(params) {
  try {
    const user = { data: { email: params.payload } };
    const data = yield call(_api, URLS.requestAllTickets, user, "POST");
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(support.fetchAllTicketsSuccess(data.result.data));
  } catch (e) {
    console.log(e);
    yield put(support.fetchAllTicketsFailure(e));
  }
}

function* createTicketWorker(params) {
  try {
    const ids = { data:  params.payload};
    const data = yield call(_api, URLS.requestCreateTicket, ids, "POST");
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(support.createTicketSuccess(data));
  } catch (e) {
    yield put(support.createTicketFailure(e));
  }
}

function* updateTicketWorker(params) {
  try {
    const ids = { data: params.payload};
    const data = yield call(_api, URLS.requestTicketUpdate, ids, "POST");
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(support.updateTicketSuccess(data.result.data));
  } catch (e) {
    yield put(support.updateTicketFailure(e));
  }
}

function* deleteTicketWorker(params) {
  try {

    const ids = {data: params.payload}
    const data = yield call(_api, URLS.requestTicketDelete, ids, "POST");
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(support.deleteTicketSuccess(data.result.data));
  } catch (e) {
    yield put(support.deleteTicketFailure(e));
  }
}

function* allTicketsWatcher() {
  yield takeLatest(types.REQUEST_ALL_TICKETS, allTicketsWorker);
}

function* createTicketWatcher() {
  yield takeLatest(types.REQUEST_TICKET_CREATE, createTicketWorker);
}

// function* viewTicketWatcher() {
//   yield takeLatest(types.REQUEST_TICKET_VIEW, viewTicketWorker);
// }

function* updateTicketWatcher() {
  yield takeLatest(types.REQUEST_TICKET_UPDATE, updateTicketWorker);
}


function* deleteTicketWatcher() {
  yield takeLatest(types.REQUEST_TICKET_DELETE, deleteTicketWorker);
}

export default function* notificationSaga() {
  yield all([
    allTicketsWatcher(),
    createTicketWatcher(),
    // viewTicketWatcher(),
    deleteTicketWatcher(),
    updateTicketWatcher()
  ]);
}
