import axios from 'axios';

import { baseUrl } from './constants';
import { createParamsString } from '../shared/helper';
import urls from './urls';

export default async (url, params = {}, method = 'GET') => {
  try {
    let finalUrl = baseUrl + url + (method === 'GET' ? createParamsString(params) : '');
    const configObj = {
      method,
      url: finalUrl,
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    if (method !== 'GET') {
      configObj['data'] = params;
    }
    const response = await axios({ ...configObj });
    if (response.code !== 200) {
    }
    return response.data;
  } catch (e) {
    console.log('Eroro in api : ', e.response);
    return e.response && e.response.data;
  }
}

export const multipleRequest = async (urls, params, methods) => {
  const requests = urls.map((el, i) => {
    let finalUrl = baseUrl + el + (methods[i] === 'GET' ? createParamsString(params[i]) : '');
    const configObj = {
      method:methods[i],
      url: finalUrl,
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token'), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    if (methods[i] !== 'GET') {
      configObj['data'] = params[i];
    }
    return { ...configObj }
  })
  axios.all([...requests]).then(axios.spread((...responses) => {
    return [...responses]
  })).catch(e => {
    console.log('Eroro in api : ', e.response);
  })
}

