import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropbox } from 'dropbox';
import * as Msal from 'msal';
import { Client } from '@microsoft/microsoft-graph-client';
import { gapi } from 'gapi-script';
import * as dataSourceAuth from '../../../store/actions/dataSourceAuthAction';
import * as dataSourceInfo from '../../../store/actions/dataSourceInfoAction';
import AddSourceModal from './AddSourceModal';
import ProgressModal from './ProgressModal';
import FolderViewModal from './FolderViewModal';
import grey_bg_folder from '../../../assets/grey_bg_folder.svg';
import white_bg_folder from '../../../assets/white_bg_folder.svg';
import * as catalogLandingPage from '../../../store/actions/catalogLandingPageActions';
import * as graphData from '../../../store/actions/graphDataAction';
import { v4 as uuidv4 } from 'uuid';

const ALL_FOLDERS_LIMIT = 10;
const RECENT_SEARCH_LIMIT = 5;

const TreeFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={grey_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
);

const SelectedFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={white_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
);

class AddSource extends Component {
  constructor(props) {
    super(props);

    // Dropbox configuration
    const dropboxConfig = {
      clientId: 'i29kkf2150ooqrh',
      clientSecret: 'mxg2b2am1xlwkai',
    };
    this.dbx = new Dropbox(dropboxConfig);
    this.dropboxRedirectUri = this.props.dropboxUri;

    // OneDrive configurations
    const msalConfig = {
      auth: {
        clientId: 'f562f243-657c-45f2-a8ac-973ad8ddd16c',
        authority: 'https://login.microsoftonline.com/common/',
      },
    };
    this.oneDriveRedirectUri = this.props.oneDriveUri;
    this.oneDriveScope = ['user.read', 'files.readwrite'];
    this.msalInstance = new Msal.UserAgentApplication(msalConfig);

    // Google drive configuration
    this.GDriveClientId =
      '444080614464-h45903renspm98k3a9v7hspoedj436v4.apps.googleusercontent.com';
    this.GDriveApiKey = 'AIzaSyCewEtpdxlBDsEP2Zz1Gv_YCbFbtV3Q85E';
    this.GDriveDiscoveryDocs = [
      'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    ];
    this.GDriveScopes = 'https://www.googleapis.com/auth/drive';

    // State
    this.state = {
      tokenInfo: null,
      filesFoldersInfo: [],
      selectedFolders: [],
      connectorResponseId: '',
      sourceName: '',
      uniqueId: '',
      errorMessage: '',
      folderChildren: [],
      isNewDataLoading: false,
      authToken: null,
      isAllSelect: false,
      trackId: new Date().getTime(),
      selectedData: [],
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const name = urlParams.get('name');
    if (name === 'dropbox' && code) {
      this.dbx.auth
        .getAccessTokenFromCode(this.dropboxRedirectUri, code)
        .then(async (token) => {
          let uniqueId = uuidv4();
          this.setState({ uniqueId });
          this.dbx.auth.setRefreshToken(token.result.refresh_token);
          await this.props.dataSourceAuthRequest({
            source: 'dropbox',
            metaData: token.result,
            userId: this.props.userId,
            uniqueId,
          });
          this.getDropboxDataList('');
        })
        .catch((err) => {
          console.log(err);
          this.setState({ errorMessage: 'Can not fetch Dropbox data' });
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isLoading &&
      !this.props.isLoading &&
      this.props.dataSourceAuth
    ) {
      this.setState({ connectorResponseId: this.props.dataSourceAuth });
    }
  }

  progressModalHandler = () => {
    this.setState({ progressModalOpen: !this.state.progressModalOpen });
  };

  submitSelectedFolder = async (folderPath) => {
    try {
      let formattedData = this.state.selectedFolders.map((item) => {
        return {
          id: item.value,
          mimeType: item.mimeType,
          name: folderPath[item.value],
          orgName: item.type === 'file' ? item.name : '',
          sourceId: uuidv4(),
        };
      });
      let trackId = new Date().getTime();
      await this.props.dataSourceInfoRequest({
        userId: this.props.userId,
        data: formattedData,
        sourceName: this.state.sourceName,
        uniqueId: this.state.uniqueId,
        trackId,
      });
      this.setState({
        progressModalOpen: !this.state.progressModalOpen,
        trackId,
      });
      this.props.sourceModalHandler(false);
    } catch (error) {
      console.log(error);
      this.setState({
        errorMessage: 'Can not reach to the server. Please try again.',
      });
    }
  };

  formatGDriveData = (data) => {
    let formattedData = [];

    data.map((item) => {
      return formattedData.push({
        name: item.name,
        type:
          item.mimeType === 'application/vnd.google-apps.folder'
            ? 'folder'
            : 'file',
        mimeType: item.mimeType,
        id: item.id,
        title: item.name,
        key: item.id,
        isLeaf: item.mimeType !== 'application/vnd.google-apps.folder',
        children: [],
        icon:
          item.mimeType === 'application/vnd.google-apps.folder'
            ? ({ selected }) =>
                selected ? <SelectedFolderIcon /> : <TreeFolderIcon />
            : null,
      });
    });

    let folders = formattedData.filter(
      (item) => item.mimeType === 'application/vnd.google-apps.folder'
    );

    let documentsFile = formattedData.filter((item) => {
      // let fileExtension = item.name.split('.');
      if (
        item.mimeType === 'application/vnd.google-apps.document' ||
        item.mimeType === 'application/pdf' ||
        item.mimeType === 'application/msword' ||
        item.mimeType ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        return true;
      } else {
        return false;
      }
    });

    // let otherFiles = formattedData.filter((item) => {
    //   if (
    //     item.mimeType !== 'application/vnd.google-apps.document' &&
    //     item.mimeType !== 'application/pdf' &&
    //     item.mimeType !== 'application/vnd.google-apps.folder' &&
    //     item.mimeType !== 'application/msword' &&
    //     item.mimeType !==
    //       'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    return [
      {
        title: 'Root',
        key: 'Root',
        icon: ({ selected }) =>
          selected ? <SelectedFolderIcon /> : <TreeFolderIcon />,
        children: [...folders, ...documentsFile],
      },
    ];
  };

  formatOneDriveData = (data) => {
    let formattedData = [];

    data.map((item) => {
      return formattedData.push({
        name: item.name,
        type: item.folder ? 'folder' : 'file',
        mimeType: item.folder ? 'folder' : 'file',
        id: item.id,
        title: item.name,
        key: item.id,
        isLeaf: item.folder ? false : true,
        children: [],
        icon: item.folder
          ? ({ selected }) =>
              selected ? <SelectedFolderIcon /> : <TreeFolderIcon />
          : null,
      });
    });

    let folders = formattedData.filter((item) => item.type === 'folder');

    let documentsFile = formattedData.filter((item) => {
      let fileExtension = item.name.split('.');
      if (
        fileExtension[fileExtension.length - 1] === 'pdf' ||
        fileExtension[fileExtension.length - 1] === 'docx' ||
        fileExtension[fileExtension.length - 1] === 'doc'
      ) {
        return true;
      } else {
        return false;
      }
    });

    // let otherFiles = formattedData.filter((item) => {
    //   let fileExtension = item.name.split('.');
    //   if (
    //     fileExtension[fileExtension.length - 1] !== 'pdf' &&
    //     fileExtension[fileExtension.length - 1] !== 'docx' &&
    //     fileExtension[fileExtension.length - 1] !== 'doc' &&
    //     item.type !== 'folder'
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    return [
      {
        title: 'Root',
        key: 'Root',
        icon: ({ selected }) =>
          selected ? <SelectedFolderIcon /> : <TreeFolderIcon />,
        children: [...folders, ...documentsFile],
      },
    ];
  };

  formatDbxData = (data) => {
    let formattedData = [];

    data.map((item) => {
      return formattedData.push({
        name: item.name,
        type: item['.tag'],
        mimeType: item['.tag'],
        id: item['path_display'],
        title: item.name,
        key: item['path_display'],
        isLeaf: item['.tag'] === 'file' ? true : false,
        children: [],
        icon:
          item['.tag'] !== 'file'
            ? ({ selected }) =>
                selected ? <SelectedFolderIcon /> : <TreeFolderIcon />
            : null,
      });
    });

    let folders = formattedData.filter((item) => item.type === 'folder');

    let documentsFile = formattedData.filter((item) => {
      let fileExtension = item.name.split('.');
      if (
        fileExtension[fileExtension.length - 1] === 'pdf' ||
        fileExtension[fileExtension.length - 1] === 'docx' ||
        fileExtension[fileExtension.length - 1] === 'doc'
      ) {
        return true;
      } else {
        return false;
      }
    });

    // let otherFiles = formattedData.filter((item) => {
    //   let fileExtension = item.name.split('.');
    //   if (
    //     fileExtension[fileExtension.length - 1] !== 'pdf' &&
    //     fileExtension[fileExtension.length - 1] !== 'docx' &&
    //     fileExtension[fileExtension.length - 1] !== 'doc' &&
    //     item.type !== 'folder'
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    return [
      {
        title: 'Root',
        key: 'Root',
        icon: ({ selected }) =>
          selected ? <SelectedFolderIcon /> : <TreeFolderIcon />,
        children: [...folders, ...documentsFile],
      },
    ];
  };

  addChildrenToTree(rootNode, newData, parentId) {
    if (parentId === 'Root') {
      return rootNode;
    }
    rootNode.some(function iter(a) {
      if (a.key === parentId) {
        a.children = newData || [];
        return true;
      }
      return Array.isArray(a.children) && a.children.some(iter);
    });
    return rootNode;
  }

  getDropboxDataList = async (path) => {
    try {
      if (path) {
        this.setState({ isNewDataLoading: true });
        let result = await this.dbx.filesListFolder({ path });
        let formattedData = this.formatDbxData(result.result.entries);
        formattedData = formattedData[0].children;
        let filesData = this.state.filesFoldersInfo;
        let insertedData = this.addChildrenToTree(
          filesData,
          formattedData,
          path
        );
        this.setState({
          filesFoldersInfo: insertedData,
          sourceName: 'dropbox',
          isNewDataLoading: false,
          selectedData: formattedData,
        });
      } else {
        let result = await this.dbx.filesListFolder({ path });
        let data = this.formatDbxData(result.result.entries);
        this.setState({
          filesFoldersInfo: data,
          sourceName: 'dropbox',
        });
        this.props.sourceModalHandler(true);
      }
    } catch (error) {
      console.log(error);
      this.setState({
        errorMessage: 'Can not fetch Dropbox data. Please try again.',
      });
    }
  };

  handleDropboxAuth = () => {
    try {
      const authUrl = this.dbx.auth.getAuthenticationUrl(
        this.dropboxRedirectUri,
        null,
        'code',
        'offline',
        null,
        'none',
        false
      );
      window.location.href = authUrl;
    } catch (error) {
      console.log(error);
      this.setState({
        errorMessage: 'Can not sign in with Dropbox. Please try again.',
      });
    }
  };

  handleGDriveAuth = async () => {
    try {
      gapi.load('client:auth2', async () => {
        gapi.client
          .init({
            apiKey: this.GDriveApiKey,
            clientId: this.GDriveClientId,
            discoveryDocs: this.GDriveDiscoveryDocs,
            scope: this.GDriveScopes,
          })
          .then(async () => {
            const googleAuth = gapi.auth2.getAuthInstance();
            const googleUser = await googleAuth.signIn();
            // const refreshToken = await googleUser.grantOfflineAccess();

            // await googleUser.getBasicProfile().getId();
            let uniqueId = uuidv4();
            this.setState({ uniqueId });

            const token = googleUser.getAuthResponse();
            await this.props.dataSourceAuthRequest({
              source: 'gdrive',
              metaData: token,
              userId: this.props.userId,
              uniqueId,
            });
            const response = await gapi.client.drive.files.list({
              q: `'root' in parents and trashed = false`,
            });
            const formattedData = this.formatGDriveData(response.result.files);
            this.setState({
              filesFoldersInfo: formattedData,
              sourceName: 'gdrive',
            });
          })
          .catch((err) =>
            this.setState({
              errorMessage: `Google Error: ${err.details}`,
            })
          );
      });
    } catch (error) {
      console.log(error);
      this.setState({
        errorMessage: 'Can not fetch Google Drive data. Please try again.',
      });
    }
  };

  getGDriveData = async (folderId) => {
    try {
      if (folderId && folderId !== 'Root') {
        gapi.client.load('drive', 'v3', async () => {
          this.setState({ isNewDataLoading: true });
          const response = await gapi.client.drive.files.list({
            q: `'${folderId}' in parents and trashed = false`,
          });
          let formattedData = this.formatGDriveData(response.result.files);
          formattedData = formattedData[0].children;
          let filesData = this.state.filesFoldersInfo;
          let insertedData = this.addChildrenToTree(
            filesData,
            formattedData,
            folderId
          );
          this.setState({
            filesFoldersInfo: insertedData,
            sourceName: 'gdrive',
            isNewDataLoading: false,
            selectedData: formattedData,
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleOneDriveAuth = async () => {
    try {
      var loginRequest = {
        scopes: this.oneDriveScope,
        redirectUri: this.oneDriveRedirectUri,
      };
      await this.msalInstance.loginPopup(loginRequest);
      const token = await this.msalInstance.acquireTokenSilent({
        scopes: this.oneDriveScope,
        redirectUri: this.oneDriveRedirectUri,
      });
      let uniqueId = uuidv4();
      this.setState({ authToken: token, uniqueId });
      // this.setState({ uniqueId: token.uniqueId });
      await this.props.dataSourceAuthRequest({
        source: 'onedrive',
        metaData: token,
        userId: this.props.userId,
        uniqueId,
      });
      const client = Client.init({
        authProvider: (done) => {
          done(null, token.accessToken);
        },
      });
      const data = await client.api('/me/drive/root/children').get();
      const formattedData = this.formatOneDriveData(data.value);
      this.setState({
        filesFoldersInfo: formattedData,
        sourceName: 'onedrive',
      });
    } catch (error) {
      console.log(error);
      this.setState({
        errorMessage: 'Can not fetch One Drive data. Please try again.',
      });
    }
  };

  getOneDriveData = async (folderId) => {
    try {
      if (folderId) {
        const client = Client.init({
          authProvider: (done) => {
            done(null, this.state.authToken.accessToken);
          },
        });
        this.setState({ isNewDataLoading: true });
        const data = await client
          .api(`/drive/items/${folderId}/children`)
          .get();
        let formattedData = this.formatOneDriveData(data.value);
        formattedData = formattedData[0].children;
        let filesData = this.state.filesFoldersInfo;
        let insertedData = this.addChildrenToTree(
          filesData,
          formattedData,
          folderId
        );
        this.setState({
          filesFoldersInfo: insertedData,
          sourceName: 'onedrive',
          isNewDataLoading: false,
          selectedData: formattedData,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleCheckbox = (event, folderInfo) => {
    const value = event.target.checked;
    if (value) {
      const temp = this.state.selectedFolders;
      this.setState({
        selectedFolders: [...temp, folderInfo],
      });
    } else {
      const newVal = this.state.selectedFolders.filter(
        (info) => info.value !== folderInfo.value
      );
      this.setState({ selectedFolders: newVal });
    }
  };

  handleAnotherFolderSelection = () => {
    this.setState({ selectedFolders: [], isAllSelect: false });
  };

  handleModalCancel = () => {
    this.props.catalogRecentFolders({ limit: ALL_FOLDERS_LIMIT });
    this.props.catalogRecentDocuments({ limit: RECENT_SEARCH_LIMIT });
    this.props.catalogAllSource();
    const paramObject = {
      source: 'gdrive',
    };
    this.props.catalogAdvancedSearch(paramObject);
    this.props.graphData({
      userId: this.props.userId,
      graphType: 'DataSource',
    });
    this.props.graphData({
      userId: this.props.userId,
      graphType: 'Issues',
    });
    this.setState({
      filesFoldersInfo: [],
      source: '',
      selectedFolders: [],
      progressModalOpen: false,
      selectedData: [],
    });
    this.props.sourceModalHandler(false);
  };

  handleAllSelect = (event, data) => {
    let checked = event.target.checked;
    if (checked) {
      let temp = [];
      data.map((item) =>
        temp.push({
          type: item.type,
          value: item.id,
          mimeType: item.mimeType,
          name: item.name,
        })
      );
      this.setState({ selectedFolders: temp, isAllSelect: checked });
    } else {
      this.setState({ selectedFolders: [], isAllSelect: checked });
    }
  };

  render() {
    const { open, sourceModalHandler } = this.props;
    const {
      filesFoldersInfo,
      folderChildren,
      progressModalOpen,
      selectedFolders,
      isNewDataLoading,
      sourceName,
      isAllSelect,
      selectedData,
      trackId,
    } = this.state;

    return (
      <div>
        {open && !filesFoldersInfo.length > 0 ? (
          <AddSourceModal
            sourceModalHandler={sourceModalHandler}
            handleGDriveAuth={this.handleGDriveAuth}
            handleOneDriveAuth={this.handleOneDriveAuth}
            handleDropboxAuth={this.handleDropboxAuth}
          />
        ) : null}

        {/* {true ? ( */}
        {open && filesFoldersInfo.length > 0 ? (
          <FolderViewModal
            sourceModalHandler={sourceModalHandler}
            filesFoldersInfo={filesFoldersInfo}
            submitSelectedFolder={this.submitSelectedFolder}
            handleModalCancel={this.handleModalCancel}
            folderChildren={folderChildren}
            handleCheckbox={this.handleCheckbox}
            selectedFolders={selectedFolders}
            getGDriveData={this.getGDriveData}
            isNewDataLoading={isNewDataLoading}
            sourceName={sourceName}
            getDropboxDataList={this.getDropboxDataList}
            getOneDriveData={this.getOneDriveData}
            handleAllSelect={this.handleAllSelect}
            isAllSelect={isAllSelect}
            selectedData={selectedData}
            handleAnotherFolderSelection={this.handleAnotherFolderSelection}
          />
        ) : null}

        {progressModalOpen ? (
          <ProgressModal
            progressModalHandler={this.progressModalHandler}
            handleModalCancel={this.handleModalCancel}
            trackId={trackId}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.homepage.userDetails.userId,
    dataSourceAuth: state.dataSourceAuth,
    isLoading: state.dataSourceAuth.isLoading,
  };
};

const mapDispatchToProps = {
  dataSourceAuthRequest: dataSourceAuth.dataSourceAuthRequest,
  dataSourceInfoRequest: dataSourceInfo.dataSourceInfoRequest,
  catalogRecentFolders: catalogLandingPage.catalogRecentFoldersRequest,
  catalogRecentDocuments: catalogLandingPage.catalogRecentDocumentsRequest,
  catalogAllSource: catalogLandingPage.catalogAllSourceRequest,
  graphData: graphData.graphDataRequest,
  catalogAdvancedSearch: catalogLandingPage.catalogAdvancedSearchRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSource);
