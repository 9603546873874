import * as types from '../types';

const initialState = {
    isLoading: false,
    docData: null,
    error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
    switch (action.type) {
        // Upload Doc Actions
        case types.UPLOAD_DOC_REQUEST:
            return {
                ...state, isLoading: true, docData:null
            };
        case types.UPLOAD_DOC_SUCCESS: {
            return {
                ...state, isLoading: false,
                docData: action.payload.result
            };
        }
        case types.UPLOAD_DOC_FAILURE: return {
            ...state, isLoading: false, error: action.payload.message || defaultErrorMessage
        };
        case types.CLEAR_UPLOAD_DOC_LOADER: return {
            ...state, isLoading: false
        }
        default: {
            return  state ;
        }
    }
}