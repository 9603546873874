import React, { Component } from "react";
import styles from "./EditTag.module.css";
import commonStyles from '../../../shared/Common.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';
import * as clusteredResult from '../../../store/actions/clusteredResultAction';
import { connect } from 'react-redux';
import ErrorText from '../../../shared/error-text/ErrorText';
import {handleSuccess, handleError } from '../../../shared/HandleMessages'
const TEXT_REPLACE_REGEX = /[.*+?^${}()|[\]\\]/g;
class EditTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainTag: '',
            subTag: "",
            secondSubTag: "",
            filteredMainTags: [],
            allSubTags: [],
            isMainTagValid: false,
            filteredSubTags: [],
            isAnotherSubTagRequested: false,
            isEditTagModalOpen:true,
            hasError:false

        }
    }
    componentDidMount() {
        this.props.mainSubTagsRequest()
       
    }
    componentDidUpdate(prevProps){
        if(this.props.feedbackData && prevProps.isFeedbackLoading && !this.props.isFeedbackLoading ){
            this.props.onTagEditSuccess(this.props.feedbackData.data);
            handleSuccess(this.props.feedbackData.message)
            this.props.closeHandler()
        }
        else if( prevProps.isFeedbackLoading &&  !this.props.isFeedbackLoading && this.props.error){
            handleError(this.props.error)
        }
    }

    handleSubTag = (event) => {
        let searchItems = event.target.value
        let subStr = searchItems.replace(TEXT_REPLACE_REGEX, '\\$&');

        let filteredSubTags = this.state.allSubTags.filter(tag => {
            return (tag.toLowerCase().search(
                subStr.toLowerCase()
            ) !== -1);
        });

        this.setState({
            subTag: event.target && event.target.value,
            filteredSubTags: filteredSubTags
        })
    }
    handleSecondSubTag = (event) => {
        let searchItems = event.target.value
        let subStr = searchItems.replace(TEXT_REPLACE_REGEX, '\\$&');
        let filteredSubTags = this.state.allSubTags.filter(tag => {
            return (tag.toLowerCase().search(
                subStr.toLowerCase()
            ) !== -1);
        });

        this.setState({
            secondSubTag: event.target && event.target.value,
            filteredSubTags: filteredSubTags
        })

    }
    handleMainTag = (event) => {
        let searchItems = event.target && event.target.value;
        let subStr = searchItems.replace(TEXT_REPLACE_REGEX, '\\$&');
        let filteredMainTags = this.props.mainSubTags?.data.filter(tag => {
            return (tag.mainTag.toLowerCase().search(
                subStr.toLowerCase()
            ) !== -1);
        });

        if ((filteredMainTags && event.target && event.target.value.includes(filteredMainTags[0] && filteredMainTags[0].mainTag))) {
            this.setState({ isMainTagValid: true, mainTag: event.target && event.target.value, filteredMainTags });
        } else {
            this.setState({ isMainTagValid: false, mainTag: event.target && event.target.value, filteredMainTags, subTag:'' });
        }
    }
    addSelectedMainTag = (tag) => {
        this.setState({ mainTag: tag, isMainTagValid: true }, () => this.getCorrespondingSubTags());
    }
    addSelectedSubTag = ( subTag) => {
        if(!this.state.isAnotherSubTagRequested)
        this.setState({ subTag: subTag })

        if(this.state.isAnotherSubTagRequested){
            this.setState({secondSubTag:subTag})
        }

    }
    onMainTagBlur = () => {
        if (this.state.isMainTagValid) {
            this.getCorrespondingSubTags()
        } else {
            this.setState({ allSubTags: [] });
        }
    }
    getCorrespondingSubTags() {
        const filteredSubTags = this.state.filteredMainTags[0] && this.state.filteredMainTags[0]['subTags'];
        const allSubTags = (filteredSubTags && filteredSubTags.map(el => el.name || '')) || [];
        this.setState({ allSubTags, filteredSubTags: allSubTags });
    }
    addAnotherSubtag() {
        this.setState({ isAnotherSubTagRequested: true })
    }
    removeAnotherSubtag(){
        this.setState({isAnotherSubTagRequested:false,secondSubTag:'' })
    }
    handleFeedback = () =>{
        if(this.state.subTag.length>0){
            
        
        let subTags="";
       
        if(this.state.subTag!==''){
            subTags=this.state.subTag
        }
        if(this.state.secondSubTag!==''){
            subTags=subTags+","+this.state.secondSubTag
        }
        const {fileId,fileVersion,fileVersionId,fileParaId,paraSeqId, version, paraId} = this.props.editedParaDetails
        const paramObj = {
            fileId:fileId,
            fileVersion:fileVersion,
            fileVersionId :fileVersionId,
            fileParaId :fileParaId,
            paraSeqId :paraSeqId.toString(),
            version:version,
            paraId:paraId,
            mainTag :this.state.mainTag,
            subTags:subTags,
        }
        
        this.props.addFeedbackRequest(paramObj)
        this.setState({isEditTagModalOpen:false})
    }
    else{
        this.setState({hasError:true})
    }
    }
    render() {
        const { mainTag, subTag, filteredMainTags, isMainTagValid, filteredSubTags, isAnotherSubTagRequested, secondSubTag, hasError } = this.state
        return (
            <div>
                <CommonModal modalClass={'edit_tag_modal'} header={' '} closeHandler={this.props.closeHandler}>
                  <div className={styles.edit_tag_container}>
                        <div>
                            <div className={styles.title}> Edit Tag</div>
                            <div className="row">
                                <div className={`col-md-6 ${styles.text_field_column}`} >
                                    <p className={commonStyles.common_form_label}>ENTER MAIN TAG</p>
                                    <input
                                        className={`form-control ${commonStyles.common_form_text_field}`}
                                        type="text"
                                        name="mainTag"
                                        value={mainTag}
                                        onChange={(event) => this.handleMainTag(event)}
                                        onBlur={this.onMainTagBlur}
                                    >
                                    </input>
                                    <p className={commonStyles.common_form_label}>ENTER SUB-TAG(S)</p>
                                    <input
                                        className={`form-control ${commonStyles.common_form_text_field}`}
                                        type="text"
                                        name="subTag"
                                        value={subTag}
                                        disabled={!isMainTagValid}
                                        onChange={(event) => this.handleSubTag(event)}>
                                    </input>
                                    {hasError && <ErrorText text="Required!" />}
                                    {isAnotherSubTagRequested && <div><p className={commonStyles.common_form_label}>ENTER SUB-TAGSS(S)</p>
                                        <input
                                            className={`form-control ${commonStyles.common_form_text_field}`}
                                            type="text"
                                            name="secondSubTag"
                                            value={secondSubTag}
                                            onChange={(event) => this.handleSecondSubTag(event)}>
                                        </input> </div>}
                                  {!isAnotherSubTagRequested && isMainTagValid && <div onClick={() => this.addAnotherSubtag()} className={styles.addAnotherSubtag}>Add Another</div>}
                                  {isAnotherSubTagRequested && <div onClick={() => this.removeAnotherSubtag()} className={styles.addAnotherSubtag}>Remove</div>}
                                </div>
                                <div className={`col-md-6 ${styles.suggestion_section}`}>
                                    <p className={commonStyles.common_form_label}>PRECILY SUGGESTIONS</p>
                                    {!isMainTagValid && filteredMainTags?.map((tag) => {
                                        return (
                                            <div key={tag.mainSubTagId} onClick={() => this.addSelectedMainTag(tag.mainTag)} className={styles.tagsStyling}>{tag.mainTag}</div>
                                        )
                                    })}
                                    {isMainTagValid && filteredSubTags.map((tag, index) => {
                                        return (
                                            <div key={index} onClick={() => this.addSelectedSubTag(tag)} className={styles.tagsStyling}>{tag}</div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                        <hr></hr>
                        <div className={styles.feedback_bottom_section}>
                            <div className={styles.feedback_text}>
                                Help us understand you better!
                            </div>
                            <div className={styles.feedback_subtext}>
                                WHY GENERATED MAIN & SUB TAGS WERE NOT RELEVENT TO YOU? (OPTIONAL)
                            </div>
                            <div className={styles.feedback_text_field}>
                                <textarea  rows="3"></textarea >
                            </div>
                        </div>
                        <div className={styles.buttons_wrapper}>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                                onClick={this.props.closeHandler}>
                                CANCEL
                                </button>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                                onClick={this.handleFeedback}>
                                CONFIRM
                            </button>
                        </div>
                    </div>
                </CommonModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mainSubTags: state.clusteredResult.mainSubTags,
        feedbackData: state.clusteredResult.feedbackData,
        isLoading: state.clusteredResult.isLoading,
        isFeedbackLoading:state.clusteredResult.isFeedbackLoading,
        error:state.clusteredResult.error
    };
}
const mapDispatchToProps = {
    mainSubTagsRequest: clusteredResult.mainSubTagsRequest,
    addFeedbackRequest: clusteredResult.addFeedbackRequest,

}
export default connect(mapStateToProps, mapDispatchToProps)(EditTag);