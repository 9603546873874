import React, { Component } from "react";
import styles from "./AddUser.module.css";
import commonStyles from '../../../shared/Common.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';
import { connect } from 'react-redux';
import Select from 'react-select';


const options = [
    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Private' },
    { value: 'protected', label: 'Protected' },
];
class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            designation: '',
            email: '',
            role: '',
            showError: false,
            selectedOption: null,
            errors: {
                email: 'Email is not valid!'
            }

        }
    }
    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };
    handleUserInput = (e) => {

        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })

    }
    componentDidMount() {
   
    }

    handleAddUser = () => {
        // const { name, designation, email, role } = this.state;
        // const paramsObj = {
        //     userId: '765599uhkbasjffe',
        //     new_name: name,
        //     new_email: email,
        //     new_designation: designation,
        //     new_role: role
        // }
        // this.props.onAddTeamMember(paramsObj)
    }
    render() {
        const { name, designation, email,selectedOption } = this.state
        return (
            <div>
                <CommonModal modalClass={'team_common_modal'} closeHandler={this.props.closeHandler} header={'Add a User'}>
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <p className={commonStyles.common_form_label}>ENTER NAME</p>
                                <input
                                    className={`form-control ${commonStyles.common_form_text_field}`}
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(event) => this.handleUserInput(event)}>
                                </input>
                            </div>
                            <div className="col-md-6">
                                <p className={commonStyles.common_form_label}>ENTER DESIGNATION</p>
                                <input
                                    className={`form-control ${commonStyles.common_form_text_field}`}
                                    type="text"
                                    name="designation"
                                    value={designation}
                                    onChange={(event) => this.handleUserInput(event)}>
                                </input>
                            </div>
                        </div>
                        <div className={`row ${styles.row_gap}`}>
                            <div className="col-md-6">
                                <p className={commonStyles.common_form_label}>ENTER ADDRESS</p>
                                <input
                                    className={`form-control ${commonStyles.common_form_text_field}`}
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={(event) => this.handleUserInput(event)}>
                                </input>
                            </div>
                            <div className="col-md-6">
                                <p className={commonStyles.common_form_label}>CHOOSE ROLE</p>
                                <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={options}
                                />
                            </div>
                        </div>
                        <div className={styles.buttons_wrapper}>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                                onClick={this.props.closeHandler}>
                                CANCEL
                                </button>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                                onClick={this.handleAddUser}>
                                SEND INVITE
                            </button>
                        </div>
                    </form>
                </CommonModal>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = {


}
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);