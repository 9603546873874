import React, { Component } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IconContext } from 'react-icons';

import styles from './SearchBar.module.css';

export default class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarPlaceholder: 'Case Laws On Depreciation',
      searchItems: this.props.searchKeyword ? this.props.searchKeyword : '',
    };
  }

  componentDidMount() {
    if (this.props.parent === 'Dashboard') {
      this.queryTickerHandler();
    }
  }

  getSearchTerm = () => {
    return this.state.searchItems;
  };

  handleUserInput = (event) => {
    var searchItems = event.target.value;
    this.setState({ searchItems });
    if (this.props.handleSearchChange)
      this.props.handleSearchChange(event.target.value);
  };
  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.handleSearch(this.state.searchItems);
    }
  };

  /* if parent === 'Dashboard' */
  queryTickerHandler = () => {
    const values = [
      'Case Laws On Depreciation',
      'Permanent Establishment',
      'High Court Judgments On Indirect Transfer',
    ];
    this.queryInterval = setInterval(() => {
      this.setState({
        searchBarPlaceholder: values[Math.round(Math.random() * 3)],
      });
    }, 1000 * 2);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.searchKeyword !== this.props.searchKeyword) {
      this.setState({ searchItems: this.props.searchKeyword });
    }
  }

  render() {
    const {
      iconColor,
      iconSize = '18px',
      fontSize,
      fontColor = '#000000',
      leftMargin,
    } = this.props;
    const { searchItems, searchBarPlaceholder } = this.state;

    return (
      <div
        className={styles.root}
        style={{
          marginLeft: leftMargin,
        }}
      >
        <input
          type="text"
          className={styles.search_box}
          style={{
            fontSize,
            color: `${fontColor}`,
          }}
          value={searchItems}
          placeholder={
            this.props.parent === 'Dashboard'
              ? searchBarPlaceholder
              : this.props.placeholder
          }
          onChange={(event) => this.handleUserInput(event)}
          onKeyPress={this.handleKeyPress}
        ></input>
        <div
          onClick={() => this.props.handleSearch(searchItems)}
          className={styles.search_icon}
        >
          <IconContext.Provider
            value={{ color: `${iconColor}`, size: `${iconSize}` }}
          >
            <div>
              <FiSearch />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    );
  }
}
