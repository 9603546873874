import React from 'react'
import styles from './AllFolders.module.css'
import arrow_up from '../../../assets/arrow_up.svg'
import { formatDate } from '../../../shared/formatDate'
import { handleLongFileName } from '../../../shared/helper'

class AllFolders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredFoldersData: this.props.foldersData,
      privateFolders: this.props.privateFolders,
      precilyData: this.props.precilyData,
      isAscending: 1,
    }
  }
  handleViewAll = () => {
    this.props.history.push({
      pathname: '/all-folders-tree',
      search: '?source=data-catalog'
    })
  }

  handleFolderClick = (fileName, referenceId, catalogSourceId, hasMore, isPrivate) => {
    this.props.history.push({
      pathname: '/selected-folder',
      search: '?source=data-catalog'
    }, {
      fileName: fileName,
      referenceId: referenceId,
      catalogSourceId: catalogSourceId,
      hasMore: hasMore,
      isPrivate
    })
  }

  sortFolders = () => {
    const filteredFoldersData = this.state.filteredFoldersData
    filteredFoldersData.sort((a, b) =>
      a['name'].replace(/%20/g, '').toLowerCase() >
      b['name'].replace(/%20/g, '').toLowerCase()
        ? this.state.isAscending
        : this.state.isAscending * -1
    )
    this.setState({
      filteredFoldersData,
      isAscending: this.state.isAscending * -1,
    })
  }

  render() {
    const { isAscending, privateFolders, precilyData } = this.state

    // console.info('precilyData', precilyData)
    return (
      <React.Fragment>
        <table className={styles.data_catalog_all_folders_table}>
          <thead>
            <tr className={styles.all_folders_row_label}>
              <th>
                {' '}
                <span>Name</span>{' '}
                <img
                  className={`${styles.arrow_up} ${
                    isAscending == 1 ? '' : styles.arrow_down
                  }`}
                  src={arrow_up}
                  alt="arrow_up"
                  onClick={() => this.sortFolders()}
                ></img>
              </th>
              <th>
                <span>Modified On</span>
              </th>
              <th>
                <span>Documents</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {privateFolders?.map((folder, index) => (
              <tr className={styles.fixed_row}>
                <td>
                  <span
                    onClick={() =>
                      this.handleFolderClick(
                        folder.name,
                        folder.referenceId,
                        folder.catalogSourceId,
                        folder.hasMore,
                        folder.isPrivate
                      )
                    }
                    className={styles.folder_name}
                  >
                    {folder.name}
                  </span>
                </td>

                <td>
                  <span className={styles.modified}>
                    {formatDate(folder.modified).date}
                  </span>
                </td>
                <td>
                  <span className={styles.size}>
                    {folder.filesCount !== null &&
                    folder.filesCount !== undefined
                      ? folder.filesCount
                      : 0}
                  </span>
                </td>
              </tr>
            ))}

            <tr>
              <td>
                <span
                  onClick={() =>
                    this.handleFolderClick(
                      precilyData?.name,
                      precilyData?.referenceId,
                      precilyData?.catalogSourceId,
                      precilyData?.hasMore
                    )
                  }
                  className={styles.folder_name}
                >
                  {precilyData?.name}
                </span>
              </td>

              <td>
                <span className={styles.modified}>
                  {formatDate(precilyData?.modified).date}
                </span>
              </td>
              <td>
                <span className={styles.size}>
                  {precilyData?.filesCount !== null &&
                  precilyData?.filesCount !== undefined
                    ? precilyData?.filesCount
                    : 0}
                </span>
              </td>
            </tr>

            {this.state.filteredFoldersData?.map((data, index) => {
              let docName = data.name
              let modified = formatDate(data.modified)
              let filesCount = data.filesCount
              return (
                <tr key={index}>
                  <td>
                    <span
                      onClick={() =>
                        this.handleFolderClick(
                          data.name,
                          data.referenceId,
                          data.catalogSourceId,
                          data.hasMore
                        )
                      }
                      className={styles.folder_name}
                    >
                      {handleLongFileName(docName, 30)}
                    </span>
                  </td>

                  <td>
                    <span className={styles.modified}>{modified.date}</span>
                  </td>
                  <td>
                    <span className={styles.size}>{filesCount} </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {this.props.showMore > 0 && (
          <div onClick={this.handleViewAll} className={styles.view_all_folders}>
            View all {this.props.showMore} folders
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default AllFolders
