import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as types from '../types'
import _api from '../../services/api'
import URLS from '../../services/urls'
import * as privateFolderActions from '../actions/privateFolderAction'

function* addFileWorker(params) {
  try {
    const data = yield call(_api, URLS.requestFileAdd, params.payload, 'POST')
    if (data && data.status !== 200) {
      throw data
    }
    yield put(privateFolderActions.fileAddSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(privateFolderActions.fileAddFailure(e))
  }
}
function* addFileWatcher() {
  yield takeLatest(types.PRIVATE_FOLDER_ADD_FILE_REQUEST, addFileWorker)
}

function* addFolderWorker(params) {
  try {
    const data = yield call(_api, URLS.requestFolderAdd, params.payload, 'POST')
    if (data && data.status !== 200) {
      throw data
    }
    yield put(privateFolderActions.folderAddSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(privateFolderActions.folderAddFailure(e))
  }
}
function* addFolderWatcher() {
  yield takeLatest(types.PRIVATE_FOLDER_ADD_FOLDER_REQUEST, addFolderWorker)
}

function* folderListWorker(params) {
  try {
    const data = yield call(_api, URLS.requestFoldersList, params.payload, 'POST')
    if (data && data.status !== 200) {
      throw data
    }
    yield put(privateFolderActions.foldersListSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(privateFolderActions.foldersListFailure(e))
  }
}
function* folderListWatcher() {
  yield takeLatest(types.PRIVATE_FOLDER_FOLDER_LIST_REQUEST, folderListWorker)
}

export default function* privateFolderSaga() {
  yield all([addFileWatcher(), addFolderWatcher(), folderListWatcher()])
}
