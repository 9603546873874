import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import Split from 'react-split'
import OutsideClickHandler from 'react-outside-click-handler'
import { IconContext } from 'react-icons'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiPencil } from 'react-icons/bi'
import { FiShare2 } from 'react-icons/fi'

import * as d3 from 'd3'
// import { IoIosArrowDown } from 'react-icons/io';
import { connect } from 'react-redux'
import Tooltip from 'react-bootstrap/Tooltip'

// import SearchBar from './../../data-catalog/search-bar/SearchBar';
import BackArrowSVG from './../../assets/arrow-up-grey.svg'
import BlueFileSVG from './../../assets/blue_file_DA.svg'
import greenDotIcon from './../../assets/green_dot.svg'
import copyIcon from '../../assets/copy_icon.svg'
import styles from './CreateDocument.module.css'

import * as createNewDocumentActions from './../../store/actions/createNewDocumentActions'
import * as sidebarAction from './../../store/actions/sidebarAction'
import * as fileClusterData from './../../store/actions/fileClusterDataAction'

import NavBar from './../../screens/workflow-management/NavBar/NavBar'
import Sidebar from './../../screens/workflow-management/Sidebar/Sidebar'
import RightBar from './../../screens/workflow-management/RightBar/RightBar'
import DragDocument from './../../components/workflow-management/DocumentAnalytics/DragDocuments'
import EditTags from './../../components/workflow-management/DocumentAnalytics/EditTags'
import CommonModal from '../../shared/common-modal/CommonModal'
import EditHistory from './../../components/workflow-management/DocumentAnalytics/EditHistory'
import AddComment from './../../components/workflow-management/DocumentAnalytics/AddComment'
import HighlightEditor from './../../components/workflow-management/DocumentAnalytics/HighlightEditor'
import Loader from '../../shared/loader/Loader'
import Share from './../../components/data-catalog/modals/share/Share'
import { triggerToast } from './../../shared/helper'

class CreateDocument extends Component {
  constructor(props) {
    super(props)

    this.reactQuillRef = React.createRef()
    var icons = ReactQuill.Quill.import('ui/icons')
    icons['undo'] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
      </svg>`
    icons['redo'] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
      </svg>`

    this.editor = React.createRef()
    this.ref = React.createRef()
    this.state = {
      showRelatedDocumentSection: false,
      showInfoPanel: false,
      lastEditOpen: false,
      selectedAction: null,
      shareAction: null,
      selectedValues: 'related_document',
      text: '',
      documentName: '',
      fileId: null,
      isPrepareResponse: false,
      numPages: null,
      restoringHistory: false,
      editedHistory: null,
      subTags: [],
      filteredSubTags: [],
      filerSearchValue: '',
      selectedSimilarDoc: 0,
      selectedParaIndex: [],
      isTagSelected: '',
      isSimilarParaModalOpen: false,
      editHistoryData: [],
      fileClusterData: [],
      citationData: [],
      fileComments: null,
      isCommenting: false,
      commentCount: 0,
      commentText: '',
      similarParaSeqId: 0,
      similarParas: null,
      selectedSimilarParaIndex: null,
      similarDocs: null,
      selectedSimilarDocUrl: '',
      isLoading: true,
      issuesPercent: null,
      fileMainData: null,
      isSubmittedForReview: false,
      showShare: false,
      allMainSubTags: null,
      isSimilarParsDataLoading: false,
      similarParaData: null,
      isTagSelectedOnce: false,
      similarDocFileClusterData: [],
      similarDocClusterDataLoading: false,
      similarDocFileClusterSubTags: [],
      similarDocsSelectedParaIndex: [],
      isSimilarDocsTagSelected: '',
      filteredFileComments: null,
      selectedCommentCount: null,
      fileStatus: '',
      restoringInitialVersion: false,
      splitPane2Expanded: false,
      size1: 90,
      size2: 10,
      defaultSize: 90,
    }
  }

  componentDidMount() {
    let state = this.props.location.state

    if (state && state.fileId) {
      this.setState(
        {
          fileId: state.fileId,
        },
        () => {
          this.props.fileClusterDataRequest({
            fileId: state.fileId,
          })
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isFileClusterDataLoading &&
      !this.props.isFileClusterDataLoading &&
      this.props.fileClusterData
    ) {
      const documentName = this.props.fileClusterData.fileName
      const text = this.props.fileClusterData.result[0].htmlText
      this.setState({
        documentName,
        text,
      })
    }
  }

  handleModelChange = model => {
    this.setState({
      text: model,
    })
  }

  handleExpandPane2 = () => {
    this.setState({
      size1: 10,
      size2: 90,
      splitPane2Expanded: true,
    })
  }
  resetSplitPane2 = () => {
    this.setState({
      size1: 90,
      size2: 10,
      splitPane2Expanded: false,
    })
  }

  handleOnDrag = sizes => {
    this.setState({
      size1: sizes[0],
      size2: sizes[1],
    })
  }
  handleDragEnd = sizes => {
    this.setState({
      splitPane2Expanded: true,
    })
  }

  handleUserInput = event => {
    var documentName = event.target.value
    this.setState({ documentName })
  }
  goBackHandler = () => {
    this.props.history.goBack()
  }
  saveDocument = () => {
    const { documentName, text, fileId } = this.state

    if (documentName === '')
      return triggerToast('Filename cannot be empty!', 'error')

    if (text === '') return triggerToast('Text cannot be empty!', 'error')

    this.props.changePrivateDocumentsDisplayedRequest(true)

    const params = {
      fileId,
      fileName: documentName,
      data: text,
    }

    this.props.createNewDocumentRequest(params)
    this.props.newDocumentSaveRequest(true)
    this.props.history.goBack()
  }

  handleShare = () => {
    const { showShare } = this.state
    this.setState({ showShare: !showShare })
  }

  closeSharePanel = () => {
    this.setState({ showShare: false })
  }

  toggleInfoPanel = () => {
    const { showInfoPanel } = this.state
    this.setState({
      showInfoPanel: !showInfoPanel,
    })
  }
  closeInfoPanel = () => {
    this.setState({
      showInfoPanel: false,
    })
  }
  formatDate = date => {
    let months = [
      'JAN',
      'FEB',
      'MARCH',
      'APR',
      'MAY',
      'JUNE',
      'JULY',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ]
    let day = new Date(date).getDate()
    let month = new Date(date).getMonth()
    let year = new Date(date).getFullYear()
    return `${day} ${months[month]} ${year}`
  }

  render() {
    const {
      showInfoPanel,
      lastEditOpen,
      isPrepareResponse,
      filteredSubTags,
      filerSearchValue,
      fileClusterData,
      selectedSimilarDoc,
      similarDocs,
      selectedParaIndex,
      isTagSelected,
      isSimilarParaModalOpen,
      editHistoryData,
      citationData,
      selectedAction,
      restoringHistory,
      fileComments,
      isCommenting,
      commentText,
      selectedSimilarParaIndex,
      selectedSimilarDocUrl,
      isLoading,
      issuesPercent,
      fileMainData,
      isSubmittedForReview,
      allMainSubTags,
      similarDocFileClusterData,
      similarDocClusterDataLoading,
      similarDocFileClusterSubTags,
      similarParaData,
      isSimilarParsDataLoading,
      showShare,
      similarDocsSelectedParaIndex,
      isSimilarDocsTagSelected,
      filteredFileComments,
      selectedCommentCount,
      fileStatus,
      restoringInitialVersion,
      size1,
      size2,
      splitPane2Expanded,
      documentName,
      text,
    } = this.state

    return (
      <div className={styles.root}>
        <NavBar />
        <Sidebar />
        <RightBar />
        <div
          className={`${styles.main_container} create_document_main_container`}
        >
          <div
            className={`${styles.split_pane_wrapper}  create_document_pane_container`}
          >
            <Split
              sizes={[size1, size2]}
              minSize={90}
              expandToMin={false}
              gutterSize={5}
              gutterAlign={'center' | 'start' | 'end'}
              // snapOffset={30}
              dragInterval={1}
              direction="vertical"
              cursor="row-resize"
              onDrag={this.handleOnDrag}
              onDragEnd={this.handleDragEnd}
            >
              <section className={styles.upper_part_root}>
                <div className={styles.header}>
                  <div
                    className={styles.section_header_left_file_info_container}
                  >
                    <div
                      className={styles.back_arrow}
                      onClick={this.goBackHandler}
                    >
                      <img src={BackArrowSVG} alt="Back Arrow" />
                    </div>

                    <img src={BlueFileSVG} alt="Blue File" />

                    <div className={styles.heading_container}>
                      {/* <div className={styles.heading}>../PATH NAME/PATH</div> */}
                      {/* // TODO: input for filename */}
                      {/* <div className={styles.sub_heading}>
                        {this.props.fileClusterData?.fileName &&
                          this.props.fileClusterData?.fileName
                            .replace(/%20/g, ' ')
                            .replace(/%28/g, ' ')
                            .replace(/%29/g, ' ')}
                      </div> */}
                      <input
                        type="text"
                        placeholder="Document Name"
                        value={documentName}
                        onChange={this.handleUserInput}
                      />
                      <IconContext.Provider
                        value={{
                          size: 18,
                        }}
                      >
                        <BiPencil />
                      </IconContext.Provider>
                    </div>
                  </div>

                  {/* {fileStatus ? (
                    <div className={styles.file_status_container}>
                      <div className={styles.file_status_indicator}></div>
                      <p>{fileStatus.toUpperCase()}</p>
                    </div>
                  ) : null} */}

                  <div className={styles.icon_container}>
                    <span
                      onClick={this.handleShare}
                      className={
                        showShare
                          ? `${styles.share_icon} ${styles.share_icon_active}`
                          : styles.share_icon
                      }
                    >
                      {showShare ? (
                        <IconContext.Provider
                          value={{
                            size: 21,
                            color: '#fff',
                          }}
                        >
                          <FiShare2 />
                        </IconContext.Provider>
                      ) : (
                        <IconContext.Provider
                          value={{
                            size: 21,
                            color: '#9a9a9a',
                          }}
                        >
                          <FiShare2 />
                        </IconContext.Provider>
                      )}
                    </span>
                    <OutsideClickHandler onOutsideClick={this.closeSharePanel}>
                      <div>
                        {showShare && (
                          <Share
                            index={5}
                            top={'45px'}
                            left={'-1px'}
                            right={'90px'}
                            fileId={this.props.location.state?.fileId}
                          />
                        )}
                      </div>
                    </OutsideClickHandler>

                    <OutsideClickHandler onOutsideClick={this.closeInfoPanel}>
                      <span
                        className={styles.info_icon}
                        onClick={this.toggleInfoPanel}
                        style={
                          showInfoPanel
                            ? {
                                backgroundColor: '#11256D',
                              }
                            : {}
                        }
                      >
                        <IconContext.Provider
                          value={
                            showInfoPanel
                              ? {
                                  size: 21,
                                  color: '#fff',
                                }
                              : {
                                  size: 21,
                                  color: '#9a9a9a',
                                }
                          }
                        >
                          <AiOutlineInfoCircle />
                        </IconContext.Provider>
                      </span>

                      {showInfoPanel && (
                        <div className={styles.info_panel}>
                          <div>
                            <span>Created On</span>
                            <span>
                              {this.formatDate(
                                this.props.fileClusterData?.created
                              )}
                            </span>
                          </div>
                          <div>
                            <span>Last Edit</span>
                            <span>
                              {this.formatDate(
                                this.props.fileClusterData?.created
                              )}
                            </span>
                          </div>
                          <div>
                            <span>Authors</span>
                            <div className={styles.authors}>
                              <span>
                                {this.props.fileClusterData?.uploadedBy}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </OutsideClickHandler>
                  </div>
                </div>

                <div className={styles.editor_and_middle_part_wrapper}>
                  <section className={styles.middle_part}>
                    <section className={styles.middle_part_left_container}>
                      <div className={styles.left_heading}>
                        ISSUES ADDRESSED
                      </div>

                      <div className={styles.issues_wrapper}>
                        {/* <div className={styles.issues_bar}>
                          <div ref={this.ref} />
                        </div>

                        <div className={styles.issue_container}>
                          {issuesPercent &&
                            issuesPercent.map(issue => {
                              return issue.percent <= 0 ? null : (
                                <div className={styles.issue_details}>
                                  <span
                                    className={styles.issue_color_1}
                                    style={{ backgroundColor: issue.color }}
                                  ></span>
                                  <span className={styles.issue_title}>
                                    {issue.name}
                                  </span>
                                  <span className={styles.issue_percentage}>
                                    {issue.percent}%
                                  </span>
                                </div>
                              )
                            })}
                        </div> */}
                      </div>

                      <div className={styles.citations_container}>
                        <div className={styles.citations_heading}>
                          CITATIONS
                        </div>
                        <div className={styles.citations_list}>
                          {citationData &&
                            citationData.map((data, index) => (
                              <div className={styles.citation_list_container}>
                                <span>{index + 1}</span>
                                <span className={styles.citation_title}>
                                  {data}
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </section>

                    <section
                      className={`${styles.middle_part_middle_container} document_analytics_edit`}
                    >
                      <div style={{ marginRight: '20px', height: '100%' }}>
                        <ReactQuill
                          theme="snow"
                          modules={{
                            toolbar: {
                              container: [
                                ['undo'],
                                ['redo'],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                                [{ indent: '-1' }, { indent: '+1' }],
                                [{ align: [] }],
                              ],
                              handlers: {
                                undo: this.myUndo,
                                redo: this.myRedo,
                              },
                            },
                          }}
                          onChange={e => this.handleModelChange(e)}
                          value={text}
                          ref={el => {
                            this.reactQuillRef = el
                          }}
                        />
                      </div>
                    </section>

                    <section className={styles.middle_part_right_container}>
                      {/* {isSubmittedForReview ||
                      fileStatus === 'in-review' ? null : (
                        <EditHistory
                          lastEditOpen={lastEditOpen}
                          editHistoryData={editHistoryData}
                          filerSearchValue={filerSearchValue}
                          filteredSubTags={filteredSubTags}
                          isTagSelected={isTagSelected}
                          handleLastEdit={this.handleLastEdit}
                          formatDate={this.formatDate}
                          renderDashboardTooltip={this.renderDashboardTooltip}
                          selectedAction={selectedAction}
                          closeActionPopup={this.closeActionPopup}
                          openActionPopup={this.openActionPopup}
                          handleSearchTags={this.handleSearchTags}
                          handleTagClick={this.handleTagClick}
                          restoreHistory={this.restoreHistory}
                        />
                      )} */}

                      {/* {isSubmittedForReview || fileStatus === 'in-review' ? (
                        <AddComment
                          data={
                            filteredFileComments
                              ? filteredFileComments
                              : fileComments
                          }
                          isCommenting={isCommenting}
                          handleCommentCancel={this.handleCommentCancel}
                          commentText={commentText}
                          handleCommentChange={this.handleCommentChange}
                          submitComment={this.submitComment}
                        />
                      ) : null} */}

                      {/* {console.log('fjhfsahdasdakldhdashd', fileMainData)} */}

                      <div className={styles.btn_container}>
                        <div
                          className={styles.submit_review_btn}
                          onClick={this.saveDocument}
                        >
                          SAVE DOCUMENT
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </section>

              <section>
                <DragDocument
                  similarDocs={[]}
                  selectedSimilarDoc={selectedSimilarDoc}
                  handleDocumentChange={this.handleDocumentChange}
                  selectedSimilarDocUrl={selectedSimilarDocUrl}
                  similarDocFileClusterData={similarDocFileClusterData}
                  similarDocClusterDataLoading={similarDocClusterDataLoading}
                  similarDocFileClusterSubTags={similarDocFileClusterSubTags}
                  handleSimilarDocTagClick={this.handleSimilarDocTagClick}
                  similarDocsSelectedParaIndex={similarDocsSelectedParaIndex}
                  isSimilarDocsTagSelected={isSimilarDocsTagSelected}
                  handleExpandPane2={this.handleExpandPane2}
                  resetSplitPane2={this.resetSplitPane2}
                  splitPane2Expanded={splitPane2Expanded}
                  size={size2}
                />
              </section>
            </Split>
          </div>
        </div>

        {/* {isSimilarParaModalOpen ? (
          <CommonModal
            modalClass={'documents_analytics_similar_para_modal'}
            backdropClassName={'documents_analytics_similar_para_model_blue_bg'}
            closeHandler={this.handleSimilarParaModal}
            staticBackdrop={true}
            header={
              <div className={`${styles.similar_para_header_container}`}>
                SIMILAR PARAGRAPHS
              </div>
            }
          >
            <div>
              {!isSimilarParsDataLoading && similarParaData?.length < 1 ? (
                <div>No Similar Para Found</div>
              ) : isSimilarParsDataLoading ? (
                <Loader />
              ) : (
                <div>
                  {this.handleSimilarParaList(similarParaData).map(
                    (para, index) => (
                      <div>
                        {selectedSimilarParaIndex === index ? (
                          <div
                            className={styles.similar_para_single_para_active}
                          >
                            <div
                              className={
                                styles.similar_para_single_para_heading
                              }
                            >
                              <div
                                className={
                                  styles.similar_para_single_para_heading_left
                                }
                              >
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_main
                                  }
                                >
                                  {para.fileName
                                    .replace(/%20/g, ' ')
                                    .replace(/%28/g, ' ')
                                    .replace(/%29/g, ' ')}
                                </div>
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_sub
                                  }
                                >
                                  YEAR {para.meta.year ? para.meta.year : null}
                                </div>
                              </div>
                              <div
                                className={
                                  styles.similar_para_single_para_heading_right
                                }
                              >
                                <img
                                  src={copyIcon}
                                  alt="copy btn"
                                  onClick={() =>
                                    this.copyHandler(para.paraText)
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={styles.similar_para_single_para_body}
                            >
                              {para.paraText}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.similar_para_single_para}>
                            <div
                              className={
                                styles.similar_para_single_para_heading
                              }
                            >
                              <div
                                className={
                                  styles.similar_para_single_para_heading_left
                                }
                              >
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_main
                                  }
                                  onClick={() =>
                                    this.handleSimilarParaClick(index)
                                  }
                                >
                                  {para.fileName
                                    .replace(/%20/g, ' ')
                                    .replace(/%28/g, ' ')
                                    .replace(/%29/g, ' ')}
                                </div>
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_sub
                                  }
                                >
                                  YEAR {para.meta.year ? para.meta.year : null}
                                </div>
                              </div>
                              <div
                                className={
                                  styles.similar_para_single_para_heading_right
                                }
                              >
                                <img
                                  src={copyIcon}
                                  alt="copy btn"
                                  onClick={() =>
                                    this.copyHandler(para.paraText)
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={styles.similar_para_single_para_body}
                            >
                              {`${
                                para.paraText.length > 500
                                  ? `${para.paraText.slice(0, 500)}...`
                                  : para.paraText
                              }`}
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </CommonModal>
        ) : null} */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isFileClusterDataLoading: state.fileClusterData.isLoading,
    fileClusterData: state.fileClusterData.filesStatus,
  }
}
const mapDispatchToProps = {
  createNewDocumentRequest: createNewDocumentActions.createNewDocumentRequest,
  newDocumentSaveRequest: sidebarAction.newDocumentSaveRequest,
  fileClusterDataRequest: fileClusterData.fileClusterDataRequest,
  changePrivateDocumentsDisplayedRequest:
    createNewDocumentActions.changePrivateDocumentsDisplayed,
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateDocument)
