import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as types from '../types'
import _api from '../../services/api'
import URLS from '../../services/urls'
import * as catalogSearchResults from '../actions/catalogSearchResultsAction'

function* catalogSearchResultsDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestCatalogSearchResultData,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(catalogSearchResults.catalogSearchResultDataSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(catalogSearchResults.catalogSearchResultDataFailure(e))
  }
}

function* catalogSearchResultsDataWatcher() {
  yield takeLatest(
    types.CATALOG_SEARCH_RESULT_DATA_REQUEST,
    catalogSearchResultsDataWorker
  )
}

function* catalogSearchRelatedFoldersWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestCatalogSearchRelatedFolders,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(catalogSearchResults.catalogSearchRelatedFoldersSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(catalogSearchResults.catalogSearchRelatedFoldersFailure(e))
  }
}
function* catalogSearchRelatedFoldersWatcher() {
  yield takeLatest(
    types.CATALOG_SEARCH_RELATED_FOLDERS_REQUEST,
    catalogSearchRelatedFoldersWorker
  )
}

export default function* catalogSearchResultsSaga() {
  yield all([
    catalogSearchResultsDataWatcher(),
    catalogSearchRelatedFoldersWatcher(),
  ])
}
