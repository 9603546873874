import  * as types from '../types'

export const fetchAllTicketsRequest = (payload) => ({type: types.REQUEST_ALL_TICKETS, payload})
export const fetchAllTicketsSuccess = (payload) => ({type: types.ALL_TICKETS_SUCCESS, payload})
export const fetchAllTicketsFailure = (payload) => ({type: types.ALL_TICKETS_FAILURE, payload})


export const createTicketRequest = (payload) => ({type: types.REQUEST_TICKET_CREATE, payload})
export const createTicketSuccess = (payload) => ({type: types.TICKET_CREATE_SUCCESS, payload})
export const createTicketFailure = (payload) => ({type: types.TICKET_DELETE_FAILURE, payload})


export const viewTicketRequest = (payload) => ({type: types.REQUEST_TICKET_VIEW, payload})
export const viewTicketSuccess = (payload) => ({type: types.TICKET_VIEW_SUCCESS, payload})
export const viewTicketFailure = (payload) => ({type: types.TICKET_VIEW_FAILURE, payload})

export const updateTicketRequest = (payload) => ({type: types.REQUEST_TICKET_UPDATE, payload})
export const updateTicketSuccess = (payload) => ({type: types.TICKET_UPDATE_SUCCESS, payload})
export const updateTicketFailure = (payload) => ({type: types.TICKET_UPDATE_FAILURE, payload})


export const deleteTicketRequest = (payload) => ({type: types.REQUEST_TICKET_DELETE, payload})
export const deleteTicketSuccess = (payload) => ({type: types.TICKET_DELETE_SUCCESS, payload})
export const deleteTicketFailure = (payload) => ({type: types.TICKET_DELETE_FAILURE, payload})



