import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as navbar from '../actions/navbarActions';

function* quickSummaryWorker(params) {
    try {
        const data = yield call(_api, URLS.requestQuickSummary, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(navbar.quickSummarySuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(navbar.quickSummaryFailure(e));
    }
}

function* quickSummaryWatcher() {
    yield takeLatest(types.QUICK_SUMMARY_REQUEST, quickSummaryWorker);
}


export default function* navbarSaga() {
    yield all([
        quickSummaryWatcher(),


    ]);
}