import React, { Component } from "react";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import styles from "./Graphs.module.css";
import commonStyles from "../../shared/Common.module.css";
import Sidebar from "../../shared/sidebar/Sidebar";
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import { connect } from "react-redux";
import * as graphs from "../../store/actions/graphs.Action";
import Loader from "../../shared/loader/Loader";

class Graphs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const paramsObj = {
      type: "all",
    };
    this.props.allGraphsRequest(paramsObj);
  }

  getGraphData = (graphData) => {
    return {
      labels: graphData.xAxis,
      datasets: [
        {
          label: graphData.title,
          fill: false,
          // lineTension: 0.1,
          // backgroundColor: 'rgba(75,192,192,0.4)','
          borderColor: "#EBEEFE",
          // borderCapStyle: 'butt',
          // borderDash: [],
          // borderDashOffset: 0.0,
          // pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: "#fff",
          // pointBorderWidth: 1,
          pointHoverRadius: 5,
          // pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          // pointHoverBorderColor: 'rgba(220,220,220,1)',
          // pointHoverBorderWidth: 2,
          // pointRadius: 1,
          // pointHitRadius: 10,
          data: graphData.yAxis,
          backgroundColor: graphData.colors,
        },
      ],
    };
  };

  getGraphOptions(graphData) {
    return {
      legend: {
        labels: {
          fontColor: "#144192",
          fontSize: 15,
          fontStyle: "bold",
        },
      },
      scales: {
        xAxes: [
          {
            barThickness: 20,
            scaleLabel: {
              display: true,
              labelString: graphData.xTitle,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: graphData.yTitle,
            },
          },
        ],
      },
    };
  }

  render() {
    let lineData;
    let barData;
    let DoughnutData;
    if (this.props.allGraphsData) {
      lineData = this.getGraphData(this.props.allGraphsData.graphs[0]);
      barData = this.getGraphData(this.props.allGraphsData.graphs[1]);
      DoughnutData = this.getGraphData(this.props.allGraphsData.graphs[2]);
    }
    return (
      <div>
        <NavigationBar
          onHeaderSearchChange={this.onHandleSearchChange}
        ></NavigationBar>
        <Sidebar></Sidebar>
        <div className={commonStyles.commom_wrapper}>
          <div className={commonStyles.commom_title}>Reports and Analytics</div>
          <div className={styles.team_management_content_wrapper}>
            <div className={`card ${commonStyles.card_layout}`}>
              <div className={`card-body ${styles.team_card_body}`}>
                {this.props.allGraphsData ? (
                  <React.Fragment>
                    <div className="row">
                      <div
                        style={{ marginTop: "20px", marginBottom: "30px" }}
                        className="col-md-6"
                      >
                        <Line
                          options={this.getGraphOptions(
                            this.props.allGraphsData.graphs[0]
                          )}
                          data={lineData}
                        />
                      </div>
                      <div style={{ margin: "auto" }} className="col-md-6">
                        <Bar
                          options={this.getGraphOptions(
                            this.props.allGraphsData.graphs[1]
                          )}
                          data={barData}
                        />
                      </div>
                    </div>
                    <div style={{ margin: "auto" }} className="col-md-6">
                      <h2
                        style={{
                          color: "#144192",
                          fontFamily: "Roboto",
                          fontSize: "15px",
                          marginTop: "15px",
                          fontWeight: "600",
                          textAlign: "center"
                        }}
                      >
                        Documents Clustered Based On Issues
                      </h2>
                      <Doughnut data={DoughnutData} />
                    </div>
                  </React.Fragment>
                ) : (
                  <Loader></Loader>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allGraphsData: state.allGraphs?.allGraphs,
    isLoading: state.dashboard.isLoading,
  };
};
const mapDispatchToProps = {
  allGraphsRequest: graphs.allGraphsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Graphs);
