import React, { Component } from 'react'
import { connect } from 'react-redux'

import styles from './Dashboard.module.css'
import commonStyles from '../../shared/Common.module.css'
// import Sidebar from '../../shared/sidebar/Sidebar';
import DocCount from '../../components/dashboard/doc-count/DocCount'
import NavigationBar from '../../shared/navigation-bar/NavigationBar'
import ReportsGraph from '../../components/dashboard/reports-graph/ReportsGraph'
import RecentDoc from '../../components/dashboard/recent-doc/RecentDoc'
import UploadDoc from '../../components/dashboard/upload-doc/UploadDoc'
import * as homepage from '../../store/actions/homepageActions'
import * as dashboard from '../../store/actions/dashboardActions'
import * as uploadDocActions from '../../store/actions/uploadDocActions'
import AdvanceSearch from '../../components/dashboard/advance-search/AdvanceSearch'
import Loader from '../../shared/loader/Loader'
// import { createParamsString } from '../../shared/helper';
import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'
import RightBar from '../../screens/workflow-management/RightBar/RightBar'

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      documenUploadBtnClicked: false,
      isAdvanceSearchOpen: false,
      searchItem: '',
      reportsFilters: {
        teamId: null,
        memberId: null,
      },
      selectedTeam: null,
      selectedMember: null,
      members: null,
    }
  }

  uploadDocument = () => {
    this.setState({
      documenUploadBtnClicked: true,
    })
  }

  onUploadClose = () => {
    this.setState({
      documenUploadBtnClicked: false,
    })
  }
  openadvancedSearch = () => {
    // const paramObj = {
    //   search: "tst"
    // }
    // this.props.advanceSearchRequest(paramObj)
    this.setState({
      isAdvanceSearchOpen: true,
    })
  }
  handlerSearch = data => {
    this.props.advanceSearchRequest(data)
    // if(this.props.advanceSearchdata){
    //   this.setState({
    //     isAdvanceSearchOpen: false,
    //   })
    // }
  }
  onAdvancedSearchClose = () => {
    this.setState({
      isAdvanceSearchOpen: false,
    })
  }
  componentDidMount() {
    this.props.userDashboardRequest()
    this.props.reportAnalysisGraphRequest()
    // this.props.advanceSearchRequest(paramObj)
    // this.props.recentSearchRequest()
    // if (!this.props.isAdvanceSearchFetching && prevProps.isAdvanceSearchFetching) {
    //   const url = '/search-results' + createParamsString({search: 'abcd'});
    //   this.props.history.push(url);
    // }
    this.props.clearUploadDocLoader()
  }

  getFilteredGraphData = (
    selectedTeam,
    members,
    isMemberSelected = false,
    memberId = null
  ) => {
    const reportsFilters = {}
    reportsFilters['teamId'] = isMemberSelected
      ? this.state.reportsFilters.teamId
      : selectedTeam.value
    reportsFilters['memberId'] = isMemberSelected ? memberId : null
    this.setState(
      {
        reportsFilters,
        selectedTeam: isMemberSelected ? this.state.selectedTeam : selectedTeam,
        members: isMemberSelected ? this.state.members : members,
        selectedMember: isMemberSelected ? memberId : null,
      },
      () => {
        this.props.reportAnalysisGraphRequest(this.state.reportsFilters)
      }
    )
  }
  viewAllDocuments() {
    this.props.history.push('/all-documents')
  }
  viewAllGraphs() {
    this.props.history.push('/graphs')
  }
  onHandleSearchChange = searchItem => {
    this.setState({ searchItem })
  }
  render() {
    const { documenUploadBtnClicked, isAdvanceSearchOpen } = this.state
    return (
      <React.Fragment>
        <Sidebar></Sidebar>
        {/* <NavigationBar placeHolder={"Search Documents"} loading={this.props.isLoading} onHeaderSearchChange={this.onHandleSearchChange}  history={this.props.history}></NavigationBar> */}
        <NavBar></NavBar>
        <RightBar></RightBar>
        <div
          className={`${commonStyles.commom_wrapper} ${styles.dashboardRoot}`}
        >
          <div className={styles.dashboard_heading}>
            <div>
              <div className={commonStyles.commom_title}>Dashboard</div>
              <div className={commonStyles.commom_subtitle}>
                Tax Submission Tool
              </div>
            </div>
            <div className={styles.button_container}>
              <div>
                <button
                  className={`${styles.sync_btn} ${styles.dashboard_btn} ${commonStyles.btn_ripple}`}
                  type="button"
                  onClick={this.openadvancedSearch}
                >
                  <i className={`fas fa-search ${styles.btn_icon} `}></i>
                  Advanced Search
                </button>
                <button
                  className={`${styles.upload_doc_btn} ${styles.dashboard_btn} ${commonStyles.btn_ripple}`}
                  type="button"
                  onClick={this.uploadDocument}
                >
                  <i className={`fas fa-plus-circle ${styles.btn_icon}`}></i>
                  Upload Document
                </button>
              </div>
            </div>
          </div>

          {this.props.userDashboard &&
            this.props.userDashboard.data &&
            this.props.userDashboard.data.documents && (
              <div className={styles.document_count_container}>
                {this.props.userDashboard.data.documents.map(
                  (record, index) => {
                    return (
                      <DocCount
                        key={record.title}
                        index={index}
                        docCount={record}
                      ></DocCount>
                    )
                  }
                )}
              </div>
            )}
          <div id={styles.bottom_container}>
            <div
              className={`thin_custom_scrollbar ${styles.reports_recentdoc_container}`}
            >
              <div
                className={`card ${commonStyles.card_layout} ${styles.reports_card}`}
              >
                <div className={styles.dashboard_card_heading_container}>
                  <div className={styles.dashboard_card_heading}>
                    Reports & Analytics
                  </div>
                  <div
                    className={styles.dashboard_card_heading_action}
                    onClick={() => this.viewAllGraphs()}
                  >
                    View All
                  </div>
                </div>
                <hr className={styles.divider}></hr>
                <div
                  className={`card-body ${styles.card_body} ${styles.reports_card_body}`}
                >
                  {this.props.reportAnalysisGraph &&
                  this.props.reportAnalysisGraph.data ? (
                    <ReportsGraph
                      reportsAnalytics={this.props.reportAnalysisGraph.data}
                      getFilteredGraphData={this.getFilteredGraphData}
                      selectedTeam={this.state.selectedTeam}
                      members={this.state.members}
                      selectedMember={this.state.selectedMember}
                      history={this.props.history}
                    ></ReportsGraph>
                  ) : (
                    <Loader></Loader>
                  )}
                </div>
              </div>
              <div
                className={`card ${commonStyles.card_layout} ${styles.recentdoc_card}`}
              >
                <div className={styles.dashboard_card_heading_container}>
                  <div className={styles.dashboard_card_heading}>
                    Recent Documents
                  </div>
                  <div
                    className={styles.dashboard_card_heading_action}
                    onClick={() => this.viewAllDocuments()}
                  >
                    View All
                  </div>
                </div>
                <hr className={styles.divider}></hr>
                <div className={`card-body ${styles.card_body}`}>
                  {this.props.userDashboard &&
                  this.props.userDashboard.data &&
                  this.props.userDashboard.data.recentDocuments ? (
                    <RecentDoc
                      history={this.props.history}
                      recentDocuments={
                        this.props.userDashboard.data.recentDocuments
                      }
                      searchItem={this.state.searchItem}
                    ></RecentDoc>
                  ) : (
                    <Loader></Loader>
                  )}
                </div>
              </div>
            </div>
          </div>
          {documenUploadBtnClicked && (
            <UploadDoc
              history={this.props.history}
              closeHandler={this.onUploadClose}
            />
          )}
          {isAdvanceSearchOpen && (
            <AdvanceSearch
              history={this.props.history}
              handlerSearch={this.handlerSearch}
              closeHandler={this.onAdvancedSearchClose}
            ></AdvanceSearch>
          )}
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    homepage: state.homepage,
    user: state.homepage.userDetails,
    error: state.homepage.error,
    userDashboard: state.dashboard.userDashboard,
    advanceSearchdata: state.dashboard.advanceSearch,
    reportAnalysisGraph: state.dashboard.reportAnalysisGraph,
    isLoading: state.dashboard.isLoading,
  }
}
const mapDispatchToProps = {
  clearHomepageError: homepage.clearHomepageError,
  userDashboardRequest: dashboard.userDashboardRequest,
  advanceSearchRequest: dashboard.advanceSearchRequest,
  recentSearchRequest: dashboard.recentSearchRequest,
  reportAnalysisGraphRequest: dashboard.reportAnalysisGraphRequest,
  clearUploadDocLoader: uploadDocActions.clearUploadDocLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
