import React, { Component } from "react";
import styles from "./Teams.module.css";
import * as teamManagement from '../../store/actions/teamManagementActions';
import Sidebar from '../../shared/sidebar/Sidebar';
import NavigationBar from '../../shared/navigation-bar/NavigationBar';
import commonStyles from '../../shared/Common.module.css';
import AddMember from '../../components/teams/add-member/AddMember';
import CreateTeam from '../../components/teams/create-team/CreateTeam';
import TeamMemberList from '../../components/teams/team-member-list/TeamMemberList';
import { Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux';
import Loader from '../../shared/loader/Loader';
import {handleSuccess, handleError} from '../../shared/HandleMessages';
import Overlay from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip'

const ROLES = ["manager", "admin"]
class Teams extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAddMemberClicked: false,
      isCreateTeamClicked: false,
      searchItem:''
    }
    
  }
  componentDidMount(){
    this.requestTeamList()
    
  }
  componentDidUpdate(prevProps){
    if(prevProps.isaddTeamMemberLoading && !this.props.isaddTeamMemberLoading && this.props.addTeamMember){
      handleSuccess(this.props.addTeamMember.message)
      this.props.teamListRequest()
      this.onAddMemberClose()
    }

    else if(prevProps.isCreateTeamLoading && !this.props.isCreateTeamLoading && this.props.createTeam){
      handleSuccess(this.props.createTeam.message)
      this.props.teamListRequest()
      this.onCreateTeamClose()
    }
    else if(prevProps.isUpdateTeamMemberLoading && !this.props.isUpdateTeamMemberLoading && this.props.updateTeamMember){
      handleSuccess(this.props.updateTeamMember.message)
      this.props.teamListRequest()
    }
    else if(prevProps.isDeleteTeamMemberLoading && !this.props.isDeleteTeamMemberLoading && this.props.deleteTeamMember){
      handleSuccess(this.props.deleteTeamMember.message)
      this.props.teamListRequest()
    }

    else if(prevProps.isaddTeamMemberLoading && !this.props.isaddTeamMemberLoading && !this.props.addTeamMember && this.props.error){
      handleError(this.props.error)
    }
    else if(prevProps.isCreateTeamLoading && !this.props.isCreateTeamLoading && !this.props.createTeam && this.props.error){
      handleError(this.props.error)
    }
    else if(prevProps.isDeleteTeamMemberLoading && !this.props.isDeleteTeamMemberLoading && !this.props.deleteTeamMember && this.props.error){
      handleError(this.props.error)
    }
  }
  requestTeamList = () => {
    this.props.teamListRequest()
  }
  handleAddMember = () => {
    this.setState({
      isAddMemberClicked: true,
    })
  }
  onAddMemberClose = () => {
    this.setState({
      isAddMemberClicked: false,
    })
  }
  handleCreateTeam = () => {
    this.setState({
      isCreateTeamClicked: true,
    })
  }
  onCreateTeamClose = () => {
    this.setState({
      isCreateTeamClicked: false
    })
  }
   renderTooltip = (props) => (
    //  console.log("ooo", props)
    <Tooltip id="button-tooltip" {...props}>
      Add Team
    </Tooltip>
  );
  renderTabList = () => {
    let modifiedTeamListData = [...this.props.teamManagement.teamList.data.teams];
    let allTeamData = []
    modifiedTeamListData.forEach(team => {
      allTeamData = allTeamData.concat(team.members)
    })
    let allTeamDataobj = {
      teamName: 'All',
      count: allTeamData.length,
      members: allTeamData
    }
    modifiedTeamListData.splice(0, 0, allTeamDataobj)
    return (modifiedTeamListData.map(team => {
      return (<Tab key={team.teamName} eventKey={team.teamName} title={team.teamName + " (" + team.count + ")"}>
       {team.members && <TeamMemberList  name={team.teamName} searchItem={this.state.searchItem}  ondeleteTeamMember={this.ondeleteTeamMember} onEditTeamMember={this.onEditTeamMember} members={team.members}></TeamMemberList>}
      </Tab>
      );
    }))
  }
  onHandleSearchChange = (searchItem) => {
    this.setState({searchItem});  
}
ondeleteTeamMember = (paramsObj) =>{
  this.props.deleteTeamMemberRequest(paramsObj)
}
onEditTeamMember = (paramsObj) => {
  this.props.updateTeamMemberRequest(paramsObj)
}
   render() {
    const { isAddMemberClicked, isCreateTeamClicked } = this.state
    return (
      <React.Fragment>
        <NavigationBar onHeaderSearchChange={this.onHandleSearchChange}></NavigationBar>
        <Sidebar></Sidebar>
        <div className={commonStyles.commom_wrapper}>
          <div className={commonStyles.commom_title}>Team Management</div>
          {/* <div className={commonStyles.commom_subtitle}>lorem ipsum dolor sit amet, consectetur adipiscing elit</div> */}
          <div className={styles.team_management_content_wrapper}>
            <div className={`card ${commonStyles.card_layout}`}>
              <div className={`card-body ${styles.team_card_body}`}>
              {this.props.teamManagement?.teamList?.data?.teams ?(  <div className={styles.team_management_tab_container}>
                  <Tabs id="team-tab">
                    {this.props.teamManagement&& this.props.teamManagement.teamList && this.props.teamManagement.teamList.data &&  this.renderTabList()}
                  </Tabs>
                {ROLES.includes(localStorage.getItem('role')) &&  <div className={styles.team_management_icons_container}>
                    <div className={styles.team_management_icons}>
                    <OverlayTrigger
    placement="right"
    delay={{ show: 250, hide: 400 }}
    overlay={this.renderTooltip}
  >
                      <img onClick={this.handleAddMember} src={require('../../assets/person_add.svg')} alt="" />
                      </OverlayTrigger>
                      <img onClick={this.handleCreateTeam} src={require('../../assets/group_add.svg')} alt="" />
                      <img onClick={this.requestTeamList} src={require('../../assets/sync.svg')} alt="" />
                    </div>
                  </div>}
                </div>):<Loader></Loader>}
                <div>
                </div>
              </div>
            </div>
          </div>
          {isAddMemberClicked && <AddMember onAddTeamMember={this.props.addTeamMemberRequest} closeHandler={this.onAddMemberClose}></AddMember>}
          {isCreateTeamClicked && <CreateTeam oncreateTeam={this.props.createTeamRequest} closeHandler={this.onCreateTeamClose}></CreateTeam>}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamManagement: state.team,
    addTeamMember:state.team.addTeamMember,
    createTeam:state.team.createTeam,
    updateTeamMember:state.team.updateTeamMember,
    deleteTeamMember:state.team.deleteTeamMember,
    isLoading:state.team.isLoading,
    isaddTeamMemberLoading:state.team.isaddTeamMemberLoading,
    isCreateTeamLoading:state.team.isCreateTeamLoading,
    isUpdateTeamMemberLoading:state.team.isUpdateTeamMemberLoading,
    isDeleteTeamMemberLoading:state.team.isDeleteTeamMemberLoading,
    error:state.team.error
  };
}

const mapDispatchToProps = {
  teamListRequest: teamManagement.teamListRequest,
  addTeamMemberRequest: teamManagement.addTeamMemberRequest,
  createTeamRequest: teamManagement.createTeamRequest,
  deleteTeamMemberRequest: teamManagement.deleteTeamMemberRequest,
  updateTeamMemberRequest: teamManagement.updateTeamMemberRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(Teams);