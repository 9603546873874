import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { withRouter } from 'react-router-dom'

import { IconContext } from 'react-icons'
import { FiShare2 } from 'react-icons/fi'

import styles from './RecentDocuments.module.css'
import grey_document from '../../../assets/grey_document.svg'
import tag_icon from '../../../assets/tag_icon.svg'
import delete_icon from '../../../assets/delete_icon.svg'
import share from '../../../assets/share.svg'
import cloud_download from '../../../assets/cloud_download.svg'
import three_dot_icon from '../../../assets/three_dot_icon.svg'
import selected_three_dot_icon from '../../../assets/selected_three_dot_icon.svg'
import ShareIcon from '../share-icon/ShareIcon'
import { formatDate } from '../../../shared/formatDate'
import { formatFilename } from './../../../shared/helper'
import Share from './../modals/share/Share'

class RecentDocuments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAction: false,
      shareAction: null,
    }
  }
  openActionPopup = index => {
    this.setState({ selectedAction: index })
  }
  closeActionPopup = () => {
    if (this.state.selectedAction) this.setState({ selectedAction: null })
  }
  openSharePopup = index => {
    // console.info('openSharePopup')
    this.setState({
      shareAction: index,
    })
  }
  closeSharePopup = () => {
    // console.info('closeSharePopup')
    this.setState({
      shareAction: null,
    })
  }

  getConfidence = (confidence, confidenceClass) => {
    if (confidenceClass === 0) {
      return { value: 'LOW', color: '#F50072', width: confidence * 100 }
    } else if (confidenceClass === 1) {
      return { value: 'MEDIUM', color: '#F5DC00', width: confidence * 100 }
    } else if (confidenceClass === 2) {
      return { value: 'HIGH', color: '00CC66', width: confidence * 100 }
    }
  }

  documentHandler = fileIndex => {
    const data = this.props?.recentDocuments
    if (data) {
      const fileId = data[fileIndex].fileId
      const filePath = data[fileIndex].filePath
      this.props.history.push(
        {
          pathname: '/document-analytics',
          search: '?source=data-catalog',
        },
        {
          fileId,
          filePath,
        }
      )
    }
  }

  render() {
    const { parent, recentDocuments } = this.props
    const { shareAction } = this.state

    return (
      <React.Fragment>
        <div className={styles.temp}>
          {this.props.recentDocuments &&
            this.props.recentDocuments.map((data, index) => {
              let folderName,
                fileName,
                modifiedDate,
                timeAndDate = {}
              if (parent === 'DataCatalog' && recentDocuments) {
                fileName = formatFilename(data.fileName, 15, 5, 30)
                folderName = data.folderName
                modifiedDate = data.modifiedDate
                timeAndDate = formatDate(data.modified)
              } else if (parent === 'DataCatalogAllFolder') {
                fileName = formatFilename(data.fileName, 15, 5, 30)
                timeAndDate = formatDate(Date.now())
              } else {
                fileName = formatFilename(
                  data.fileName.replace(/%20/g, ' '),
                  15,
                  5,
                  30
                )

                timeAndDate = formatDate(data.modified)
                timeAndDate.date = timeAndDate.date
                timeAndDate.time = timeAndDate.time
              }
              // fileName = formatFilename(data.fileName, 30, 5, 50)
              if (parent === 'SearchResultsPage') {
                var confidenceValueAndColor = this.getConfidence(
                  data.confidence,
                  data.confidenceClass
                )
              }

              return (
                <div className={styles.recent_doc_card}>
                  <div className={styles.upper_part}>
                    <img
                      className={styles.recent_doc_grey_document}
                      src={grey_document}
                      alt="document"
                    ></img>

                    {parent === 'DataCatalog' ? (
                      <div
                        className={`${styles.recent_doc_card_title}`}
                        style={{
                          height: '30px',
                        }}
                      >
                        <span>{folderName}</span>
                        {/* {parent !== 'DataCatalog' && (
                        <span className={styles.tooltiptext}>
                          {data.filename}
                        </span>
                      )} */}
                      </div>
                    ) : (
                      <div
                        className={`${styles.recent_doc_card_title}`}
                        onClick={() => this.documentHandler(index)}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <span>{fileName}</span>
                        {/* {parent !== 'DataCatalog' && (
                        <span className={styles.tooltiptext}>
                          {data.filename}
                        </span>
                      )} */}
                      </div>
                    )}

                    {parent === 'DataCatalog' ? (
                      <div
                        className={`${styles.recent_doc_card_subtitle}`}
                        onClick={() => this.documentHandler(index)}
                        style={{
                          height: '40px',
                          cursor: 'pointer',
                        }}
                      >
                        <span>{fileName}</span>
                        {/* {parent === 'DataCatalog' && (
                        <span className={styles.tooltiptext}>
                          {data.fileName}
                        </span>
                      )} */}
                      </div>
                    ) : (
                      <div className={`${styles.recent_doc_card_subtitle}`}>
                        <span>{data.fileType}</span>
                        {/* {parent === 'DataCatalog' && (
                        <span className={styles.tooltiptext}>
                          {data.fileName}
                        </span>
                      )} */}
                      </div>
                    )}

                    {parent !== 'SearchResultsPage' && (
                      <div className={styles.confidence_container}>
                        <div
                          className={styles.confidence}
                          style={{ width: '100%' }}
                        ></div>
                      </div>
                    )}

                    {parent === 'SearchResultsPage' && (
                      <div className={styles.confidence_container}>
                        <div
                          className={styles.confidence}
                          // style={{ width: '100%' }}
                          style={{
                            width: confidenceValueAndColor.width + '%',
                            backgroundColor: confidenceValueAndColor.color,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                  <div className={styles.recent_doc_card_bottom_content}>
                    <div className={styles.recent_doc_card_bottom_text}>
                      {timeAndDate.date}
                      <span className={styles.dot}>.</span>
                      {timeAndDate.time}
                    </div>

                    <div className={styles.action_dots}>
                      <img
                        className={styles.recent_doc_three_dot_icon}
                        onClick={
                          this.state.selectedAction === index + 1
                            ? () => this.closeActionPopup()
                            : () => this.openActionPopup(index + 1)
                        }
                        src={
                          this.state.selectedAction === index + 1
                            ? selected_three_dot_icon
                            : three_dot_icon
                        }
                        alt="three_dot_icon"
                      />

                      {this.state.selectedAction === index + 1 && (
                        <OutsideClickHandler
                          onOutsideClick={() => this.closeActionPopup()}
                        >
                          <div className={styles.action_popup}>
                            <div className={styles.action_popup_tag}>
                              <img src={tag_icon} alt="tag_icon"></img>{' '}
                              <span className={styles.action_popup_text}>
                                Tag
                              </span>
                            </div>

                            <OutsideClickHandler
                              onOutsideClick={() => this.closeSharePopup()}
                            >
                              <div
                                className={styles.action_popup_share}
                                style={
                                  shareAction === index
                                    ? {
                                        color: '#fff',
                                        backgroundColor: '#11256D',
                                      }
                                    : {}
                                }
                              >
                                <div
                                  className={styles.share_content_wrapper}
                                  onClick={
                                    shareAction === index
                                      ? () => this.closeSharePopup()
                                      : () => this.openSharePopup(index)
                                  }
                                >
                                  <span className={styles.action_share_icon}>
                                    <IconContext.Provider
                                      value={
                                        shareAction === index
                                          ? { color: '#ffffff', size: 21 }
                                          : { color: '#9A9A9A', size: 21 }
                                      }
                                    >
                                      <FiShare2 />
                                    </IconContext.Provider>
                                  </span>

                                  <span className={styles.action_popup_text}>
                                    Share
                                  </span>
                                </div>

                                {shareAction === index && (
                                  <Share
                                    fileId={data?.fileId}
                                    index={index}
                                    top={'41px'}
                                    left={'185px'}
                                    right={'185px'}
                                  />
                                )}
                              </div>
                            </OutsideClickHandler>

                            <div className={styles.action_popup_row_content}>
                              <img
                                className={styles.action_cloud_download_icon}
                                src={cloud_download}
                                alt="cloud_download"
                              ></img>{' '}
                              <span className={styles.action_popup_text}>
                                Download
                              </span>
                            </div>

                            <div className={styles.action_popup_row_content}>
                              <img src={delete_icon} alt="delete_icon"></img>{' '}
                              <span className={styles.action_popup_text}>
                                Delete
                              </span>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(RecentDocuments)
