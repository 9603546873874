import * as types from '../types'

export const createNewDocumentRequest = payload => ({
  type: types.CREATE_NEW_DOCUMENT_REQUEST,
  payload,
})
export const createNewDocumentSuccess = payload => ({
  type: types.CREATE_FILE_COMMENT_SUCCESS,
  payload,
})
export const createNewDocumentFailure = payload => ({
  type: types.CREATE_NEW_DOCUMENT_FAILURE,
  payload,
})

export const fetchAllPrivateDocumentsRequest = payload => ({
  type: types.FETCH_ALL_PRIVATE_DOCUMENT_REQUEST,
  payload,
})
export const fetchAllPrivateDocumentsSuccess = payload => ({
  type: types.FETCH_ALL_PRIVATE_DOCUMENT_SUCCESS,
  payload,
})
export const fetchAllPrivateDocumentsFailure = payload => ({
  type: types.FETCH_ALL_DATA_SOURCE_FAILURE,
  payload,
})

export const changePrivateDocumentsDisplayed = payload => ({
  type: types.IS_CREATE_NEW_DOCUMENT_DISPLAYED,
  payload,
})
