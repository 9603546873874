import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as dataSourceAuth from '../actions/dataSourceAuthAction';

function* dataSourceAuthWorker(params) {
  try {
    const data = yield call(_api, URLS.dataSourceAuth, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(dataSourceAuth.dataSourceAuthSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(dataSourceAuth.dataSourceAuthFailure(e));
  }
}

function* dataSourceAuthWatcher() {
  yield takeLatest(types.FETCH_ALL_DATA_SOURCE_REQUEST, dataSourceAuthWorker);
}

export default function* dataSourceAuthSaga() {
  yield all([dataSourceAuthWatcher()]);
}
