export default {
  //Common URLs
  requestDropdown: '/common/dropdown',

  //Homepage URLs
  requestLogin: '/user/login',
  requestSignup: '/user/signUp',
  requestEmailExist: '/user/email',
  requestForgotPassword: '/user/forgot/password',

  //Dashboard URLs
  requestUserDashboard: '/user/dashboard',
  requestAdvanceSearch: '/search/advance',
  requestRecentSearch: '/search/recent',
  requestFileRead: '/file/read',
  requestReportAnalysisGraph: '/report/analysis/graph',

  //TeamManagement URLs
  requestTeamList: '/team/list',
  requestAddTeamMember: '/team/member/invite',
  requestCreateTeam: '/team/create',
  requestDeleteTeamMember: '/team/member/status',
  requestUpdateTeamMember: '/team/member/update',

  //Account settings Urls
  requestUpdateProfile: '/user/update/profile',
  requestChangePassword: '/user/change/password',

  //upload doc Urls
  requestUploadDoc: '/file/upload',

  //uploaded doc Urls
  requestClusterData: '/v2/api/get/cluster/data',

  //clustered Result Urls
  requestMainSubTags: '/main/sub/tags',
  requestAddFeedback: '/para/feedback/add',

  //All Documents Urls
  requestListDocuments: '/list/all/documents',
  requestFileJourney: '/file/journey',
  requestDeleteDocument: '/file/delete',
  requestFilterDocuments: '/list/all/documents/filter',

  // Notifications URL
  allNotifications: '/notification/all',
  markSingleNotificationRead: '/notification/read',
  markAllNotificationRead: '/notification/all/read',
  removeNotification: '/notification/remove',

  //report analysis graph
  // requestReportAnalysisGraph:'/report/analysis/graph',

  // Support URL
  requestAllTickets: '/support/ticket/all',
  requestCreateTicket: '/support/ticket/create',
  requestTicket: '/support/ticket/view',
  requestTicketDelete: '/support/ticket/delete',
  requestTicketUpdate: '/support/ticket/update',

  // Similar Para
  requestSimilarParas: '/file/similar/para',

  // Similar docs
  requestSimilarDocs: '/file/similar/docs',

  // File response
  requestFileResponse: '/file/response',
  requestSubmitResponse: '/file/response/submit',

  // Data sources
  dataSourceAuth: '/v2/api/source/auth',
  dataSourceInfo: '/v2/api/source/info',

  //Data catalog
  requestcatalogRecentFolders: '/v2/api/catalog/folders',
  requestCatalogRecentDocuments: '/v2/api/catalog/recent',
  requestCatalogAllFolders: '/v2/api/catalog/all-folders',
  requestCatalogAllSource: '/v2/api/source/all',
  requestCatalogSearchTerm: '/v2/api/catalog/searchTerm',
  requestCatalogAdvancedSearch: '/v2/api/catalog/advanceSearch/params',

  // data-catalog-2
  requestCatalogSearchResultData: '/v2/api/catalog/ds/recent-search',
  requestCatalogSearchRelatedFolders: '/v2/api/catalog/search-related-folders',

  //related-searches-page
  requestCatalogAllSearchResultData: '/v2/api/catalog/all-search-result-data',
  requestCatalogSelectedFolderData: '/v2/api/catalog/folder/files',

  // file status modal
  requestCatalogFileStatus: '/v2/api/catalog/files/status',

  // all folders tree
  requestCatalogAllFoldersTree: '/v2/api/catalog/folders',
  requestCatalogAllFoldersContent: '/v2/api/catalog/folders/data',

  // graph data
  requestGraphData: '/v2/api/catalog/graph',

  //workflow management landing page
  requestWorkflowManagementProject: '/v2/api/project/get',
  requestWorkflowManagementProjectGraph: '/v2/api/project/graph',
  requestWorkflowManagementAddMember: '/v2/api/project/add-member',
  requestWorkflowManagementRemoveMember: '/v2/api/project/remove-member',
  requestWorkflowManagementDropdown: '/v2/api/project/dropdown',
  requestWorkflowManagementCreateProject: '/v2/api/project/create',

  // navbar
  requestQuickSummary: '/v2/api/quick-summary',
  requestDocumentAnalyticsHistory: '/v2/api/document-analytics/get-history',
  requestDocumentAnalyticsCreateHistory:
    '/v2/api/document-analytics/create-history',

  documentAnalyticsClusterData: '/v2/api/get/cluster/data',

  // dashboard-v2
  requestDashboardData: '/v2/api/dashboard',
  requestFileUpload: '/file/upload',

  //  upload document modal
  requestFileCluster: '/file/cluster',
  requestClusteredData: '/v2/api/get/cluster/data',

  //  private folder
  requestFileAdd: '/v2/api/privateFolder/addFile',
  requestFolderAdd: '/v2/api/privateFolder/add',
  requestFoldersList: '/v2/api/privateFolder/list',

  //  document analytics
  createFileComment: '/v2/api/file/create-comment',
  getFileComment: '/v2/api/file/get-comment',
  deleteFileComment: '/v2/api/file/delete-comment',
  updateFileComment: '/v2/api/file/update-comment',
  paraFeedbackAdd: '/para/feedback/add',
  allMainSubTags: '/main/sub/tags',
  similarParaData: '/file/similar/para',
  similarDocData: '/file/similar/docs',
  tagsGraphData: '/v2/api/ds/user/history',
  updateFileStatus: '/v2/file/status',

  //  Private document
  createNewDocument: '/v2/create-document',
  fetchAllPrivateDocuments: '/v2/all-private-documents',

  fileTrack: '/v2/api/file/track',
  fileLink: '/v2/api/file/share',
};
