import React, { Component } from "react";
import styles from "./UploadDoc.module.css";
import commonStyles from "../../../shared/Common.module.css";
import CommonModal from "../../../shared/common-modal/CommonModal";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import * as uploadDocActions from "../../../store/actions/uploadDocActions";
import fileUploadImage from "../../../../src/assets/upload_file_init.png";
import fileUploadingImage from "../../../../src/assets/upload_file_loading.png";
import { toast } from "react-toastify";
import Loader from "../../../shared/loader/Loader";
import { handleSuccess, handleError } from "../../../shared/HandleMessages";
const fileUploadText = "Choose File to Upload";
const fileUploadingText = "We'll be right with you";
const fileUploadSubtext =
  "or Drag and Drop (Acceptable Formats: .doc, .docx, .pdf)";
class UploadDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filedata: "",
      fileName: "",
      uploadingInProcess: false,
    };
  }
  onfileFound = (file) => {
    file.forEach((file) => {
      this.setState(
        {
          fileName: file.name,
          uploadingInProcess: true,
        },
        () => {
          const reader = new FileReader();
          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = () => {
            // this.setState({
            //     filedata: reader.result,
            // }, () => {
            this.onUpload(file);

            // })
          };
          reader.readAsDataURL(file);
        }
      );
    });
  };
  onUpload = async (file) => {
    const fileType = file.path.split(".").pop();
    if (fileType !== "pdf" && fileType !== "doc" && fileType !== "docx") {
      toast.error("file format not supported", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //   this.setState({ uploadingInProcess: false });
    } else {
      // if(this.props.teams){
      // const teamId = this.props.teams[0].teamId
      var formdata = new FormData();
      formdata.append("file", file);
      formdata.append("fileName", file.name);
      // formdata.append('teamId', teamId);
      this.props.uploadDocRequest(formdata);
      // }
      // else{
      //     handleError("Team not present")
      // }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    // if (this.props.uploadDoc.docData && !this.props.isLoading && !this.state.uploadingInProcess) {
    //     this.props.history.push('uploaded-doc', {from:'/dashboard'})
    // }
    // if (!prevProps.isLoading && this.props.isLoading && this.state.uploadingInProcess) {
    //     this.setState({ uploadingInProcess: false });
    // }
    if (
      this.props.uploadDoc.docData &&
      prevProps.isLoading &&
      !this.props.isLoading
    ) {
      handleSuccess(this.props.uploadDoc.docData.message);
      this.props.history.push("uploaded-doc", { from: "/dashboard" });
    }
  }

  render() {
    const { fileName } = this.state;
    return (
      <div>
        <CommonModal
          modalClass={"upload_document_modal"}
          closeHandler={this.props.closeHandler}
          hideCloseButton={this.props.isLoading ? true : null}
          header={" "}
        >
          <div className={styles.upload_document_submit_section}>
            <Dropzone
              onDrop={(acceptedFiles) => this.onfileFound(acceptedFiles)}
              disabled={this.props.isLoading}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div
                    className={`upload_doc_container ${styles.upload_document_container}`}
                  >
                    <img
                      src={
                        this.props.isLoading
                          ? fileUploadingImage
                          : fileUploadImage
                      }
                      alt=""
                    />
                    <p className={styles.upload_document_text}>
                      {this.props.isLoading
                        ? fileUploadingText
                        : fileUploadText}
                    </p>

                    <p className={styles.upload_document_format_text}>
                      {this.props.isLoading
                        ? "Uploading " + fileName
                        : fileUploadSubtext}
                    </p>
                    {this.props.isLoading && <Loader></Loader>}
                  </div>

                  {!this.props.isLoading && (
                    <button
                      type="button"
                      className={`btn btn-primary-outline ${commonStyles.home_top_action_btn} ${styles.upload_button} ${commonStyles.btn_ripple}`}
                    >
                      Browse Files
                    </button>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        </CommonModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadDoc: state.uploadDoc,
    isLoading: state.uploadDoc.isLoading,
    teams: state.homepage.userDetails.teams,
  };
};
const mapDispatchToProps = {
  uploadDocRequest: uploadDocActions.uploadDocRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadDoc);
