import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as homepage from '../actions/homepageActions';

function* loginWorker(params) {
    try {
        const data = yield call(_api, URLS.requestLogin, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(homepage.successLogin(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(homepage.failureLogin(e));
    }
}

function* signupWorker(params) {
    try {
        const data = yield call(_api, URLS.requestSignup, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(homepage.successSignup(data));
    } catch (e) {
        console.error(e);
        yield put(homepage.failureSignup(e));
    }

}
function* emailExistWorker(params) {
    try {
        const data = yield call(_api, URLS.requestEmailExist, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(homepage.emailExistSuccess(data));
    } catch (e) {
        console.error(e);
        yield put(homepage.emailExistFailure(e));
    }

}
function* forgotPasswordWorker(params) {
    try {
        const data = yield call(_api, URLS.requestForgotPassword, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(homepage.forgotPasswordSuccess(data));
    } catch (e) {
        console.error(e);
        yield put(homepage.forgotPasswordFailure(e));
    }

}

function* WsWorker(params) {
    try {
        yield put(homepage.saveConnectionSuccess(params.payload))
    } catch (error) {
        yield put(homepage.errorWhileSavingConnection(error));
    }
}

function* notificationWorker(params) {
    try {
        yield put(homepage.saveNotificationSuccess(params.payload))
    } catch (error) {
        yield put(homepage.saveNotificationFailure(error));
    }
}


function* loginWatcher() {
    yield takeLatest(types.REQUEST_LOGIN, loginWorker);
}

function* signupWatcher() {
    yield takeLatest(types.REQUEST_SIGNUP, signupWorker);
}

function* emailExistWatcher() {
    yield takeLatest(types.EMAIL_EXIST_REQUEST, emailExistWorker);
}
function* forgotPasswordWatcher() {
    yield takeLatest(types.FORGOT_PASSWORD_REQUEST, forgotPasswordWorker);
}

function* WsWatcher() {
    yield takeLatest(types.WS_CONNECTION_REQUEST, WsWorker)
}

function* notificationWatcher() {
    yield takeLatest(types.SAVE_NOTIFICATION_REQUEST, notificationWorker)
}

export default function* homepageSaga() {
    yield all([
        loginWatcher(),
        signupWatcher(),
        emailExistWatcher(),
        forgotPasswordWatcher(),
        WsWatcher(),
        notificationWatcher()
    ]);
}