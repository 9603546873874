import React from 'react';
import moment from 'moment'
import './Message.css';

export const Message = ({message: { user, text, date }, name, isAdmin, isPrivate}) => {
    let isSentByCurrentUser = false;
    const trimmedName = name
    if(user.name === trimmedName) {
        isSentByCurrentUser = true
    }
    if(isPrivate) {
        if(user.name == isAdmin) {
            isSentByCurrentUser = true
        }
    }
    let newDate = new Date(date)
    let formatedDate = moment(newDate).fromNow()
    return (
        isSentByCurrentUser
            ? (
                <div>
                    <div style={{justifyContent: "flex-end"}} className="messageContainer justifyEnd">
                        {/* <p className="sentText pr-10">{trimmedName}</p> */}
                        <div className="messageBox backgroundWhite">
                            <p style={{color: "#2C2C2C"}} className="messageText colorDark">{text}</p>
                        </div>
                    </div>
                    <div className="sentText pr-10">{formatedDate}</div>
                </div>
            )
            : (
                <div>
                    <div className="messageContainer justifyStart">
                        <img className="chat_person_image" src={user.profile} style={{width: "36px", height: "36px"}}/>
                        <div className="messageBox backgroundDark" >
                            <p className="messageText colorWhite">{text}</p>
                        </div>
                        {/* <p className="sentText pl-10">{user.name}</p> */}
                    </div>
                    <div className="sentText pl-10">{formatedDate}</div>
                </div>
            )
    )
};