import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as documentAnalyticsHistory from '../actions/documentAnalyticsHistoryAction';

function* getDocumentAnalyticsHistoryWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestDocumentAnalyticsHistory,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(
      documentAnalyticsHistory.documentAnalyticsHistoryGetSuccess(data)
    );
  } catch (e) {
    console.error('error  : ', e);
    yield put(documentAnalyticsHistory.documentAnalyticsHistoryGetFailure(e));
  }
}

function* getDocumentAnalyticsHistoryWatcher() {
  yield takeLatest(
    types.DOCUMENT_ANALYTICS_HISTORY_GET_REQUEST,
    getDocumentAnalyticsHistoryWorker
  );
}

function* createDocumentAnalyticsHistoryWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestDocumentAnalyticsCreateHistory,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(
      documentAnalyticsHistory.documentAnalyticsHistoryCreateSuccess(data)
    );
  } catch (e) {
    console.error('error  : ', e);
    yield put(
      documentAnalyticsHistory.documentAnalyticsHistoryCreateFailure(e)
    );
  }
}

function* createDocumentAnalyticsHistoryWatcher() {
  yield takeLatest(
    types.DOCUMENT_ANALYTICS_HISTORY_CREATE_REQUEST,
    createDocumentAnalyticsHistoryWorker
  );
}

function* paraFeedbackAddWorker(params) {
  try {
    const data = yield call(_api, URLS.paraFeedbackAdd, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(documentAnalyticsHistory.paraFeedbackAddSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(documentAnalyticsHistory.paraFeedbackAddFailure(e));
  }
}

function* paraFeedbackAddWatcher() {
  yield takeLatest(types.PARA_FEEDBACK_ADD_REQUEST, paraFeedbackAddWorker);
}

function* allMainSubTagsWorker(params) {
  try {
    const data = yield call(_api, URLS.allMainSubTags, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(documentAnalyticsHistory.allMainSubTagsSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(documentAnalyticsHistory.allMainSubTagsFailure(e));
  }
}

function* allMainSubTagsWatcher() {
  yield takeLatest(types.ALL_MAIN_SUB_TAGS_REQUEST, allMainSubTagsWorker);
}

function* similarParaDataWorker(params) {
  try {
    const data = yield call(_api, URLS.similarParaData, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(documentAnalyticsHistory.similarParaDataSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(documentAnalyticsHistory.similarParaDataFailure(e));
  }
}

function* similarParaDataWatcher() {
  yield takeLatest(types.SIMILAR_PARA_DATA_REQUEST, similarParaDataWorker);
}

function* similarDocDataWorker(params) {
  try {
    const data = yield call(_api, URLS.similarDocData, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(documentAnalyticsHistory.similarDocDataSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(documentAnalyticsHistory.similarDocDataFailure(e));
  }
}

function* similarDocDataWatcher() {
  yield takeLatest(types.SIMILAR_DOC_DATA_REQUEST, similarDocDataWorker);
}

function* updateFileStatusWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.updateFileStatus,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(documentAnalyticsHistory.updateFileStatusSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(documentAnalyticsHistory.updateFileStatusFailure(e));
  }
}

function* updateFileStatusWatcher() {
  yield takeLatest(types.UPDATE_FILE_STATUS_REQUEST, updateFileStatusWorker);
}

function* fileTrackWorker(params) {
  try {
    const data = yield call(_api, URLS.fileTrack, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(documentAnalyticsHistory.fileTrackSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(documentAnalyticsHistory.fileTrackFailure(e));
  }
}

function* fileStatusWatcher() {
  yield takeLatest(types.FILE_TRACK_REQUEST, fileTrackWorker);
}

export default function* documentAnalyticsHistoryGetSaga() {
  yield all([
    getDocumentAnalyticsHistoryWatcher(),
    createDocumentAnalyticsHistoryWatcher(),
    paraFeedbackAddWatcher(),
    allMainSubTagsWatcher(),
    similarParaDataWatcher(),
    similarDocDataWatcher(),
    updateFileStatusWatcher(),
    fileStatusWatcher(),
  ]);
}
