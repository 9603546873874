import React, { Component } from "react";
import styles from "./SearchResults.module.css";
import Sidebar from '../../shared/sidebar/Sidebar';
import NavigationBar from '../../shared/navigation-bar/NavigationBar';
import commonStyles from '../../shared/Common.module.css';
import { connect } from 'react-redux';
import AllDocumentsDetails from '../../components/all-documents/all-documents-details/AllDocumentsDetails';
import DisplayDocument from '../../shared/display-document/DisplayDocument'
import * as allDocuments from '../../store/actions/allDocumentsActions';

const FROM="/search-results"
class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            togglePopup: false,
            selectedFileData: {},
            selectedAction: null,
            showFile:false,
            showFileUrl:'',
            fileNameToShow:'',
            searchItem:''
        }
    }

    componentDidMount() {
    }

    showFileDetails = () => {

    }
    onHandleSearchChange = (searchItem) => {
       this.setState({searchItem}) 

    }
    getFilteredDocuments = () => {
        let searchItems = this.state.searchItem
        let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let filteredDocuments = this.props.advanceSearchdata.data.filter(doc => {
        if(doc){
            return  doc?.fileName?.toLowerCase().search(
                subStr.toLowerCase()
            ) !== -1;
        }});
        return filteredDocuments
    }
    onTogglePopup = (selectedFileData) => {
        if (!this.state.togglePopup) {
            this.setState({ selectedFileData: selectedFileData })
            const paramsObj = {
                fileId: selectedFileData.fileId
            }
            this.props.fileJourneyRequest(paramsObj)
        }
        this.setState({ togglePopup: !this.state.togglePopup });
    }
    openActionPopup = (fileId) => {
        this.setState({ selectedAction: fileId })
    }
    closeActionPopup = () => {
        if (this.state.selectedAction) {
            this.setState({ selectedAction: null })
        }
    }
    handleShowFile = (showFileUrl,docName ) =>{
        this.setState({showFile:true,showFileUrl, fileNameToShow:docName })
    }
    handleCloseFile = (showFileUrl ) =>{
        this.setState({showFile:false,showFileUrl:''})
    }

    render() {
        const {togglePopup,selectedFileData, showFile, showFileUrl, fileNameToShow } = this.state
        let filteredDocuments = this.getFilteredDocuments()
        return (
            <React.Fragment>
                <NavigationBar onHeaderSearchChange={this.onHandleSearchChange}></NavigationBar>
                <Sidebar></Sidebar>
                {togglePopup && this.props.fileJourneyData && <AllDocumentsDetails history={this.props.history} from={FROM} selectedFileData={selectedFileData} fileJourneyData={this.props.fileJourneyData} onPopupClose={this.onTogglePopup}></AllDocumentsDetails>}
                <div className={commonStyles.commom_wrapper}>
                    <div className={commonStyles.commom_title}>Search Results</div>
                    {/* <div className={commonStyles.commom_subtitle}>lorem ipsum dolor sit amet, consectetur adipiscing elit</div> */}
                    <div className={styles.search_result_content_wrapper}>
                        <div className={`card ${commonStyles.card_layout}`}>
                            <div className={`card-body ${styles.search_result_card_body}`}>

                           {this.props.advanceSearchdata.data.length>0 ?    ( <div onClick={() => this.closeActionPopup()} className={'thin_custom_scrollbar'}>
                                    <div style={{ height: this.props.height + 'px' }} className={styles.search_result_list_container}>
                                        <table className={styles.search_result_table}>
                                            <thead>
                                                <tr className={styles.search_result_row_label}>
                                                    <th> <label>DOCUMENT NAME</label> </th>
                                                    <th ><label>STATUS</label></th>
                                                    {/* <th ><label>ASSIGNED TO</label></th> */}
                                                    <th ><label>UPLOADED BY</label></th>
                                                    <th ><label>DUE DATE</label></th>
                                                    <th><label>VERSION HISTORY</label></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredDocuments && filteredDocuments.map((data, index) => {
                                                    let docName = data?.orgFileName
                                                    let docStatus = data?.fileStatus
                                                    // let docAssignedTo = data.assignedTo
                                                    let docUploadedBy = data?.clientName
                                                    let dueDate = data?.dueDate
                                                    let versionHistory = data?.version
                                                    let fileId = data?.fileId
                                                    let permission = data?.permission
                                                    let isClustered = data?.isClustered
                                                    let fileVersionId = data?.fileVersionId
                                                    let version = data?.version
                                                    let docType = data?.docType?.toString()
                                                    let filePath = data?.filePath
                                                
                                                    const selectedFileData = {
                                                        docName: docName,
                                                        docStatus: docStatus,
                                                        docUploadedBy: docUploadedBy,
                                                        fileId: fileId,
                                                        permission:permission,
                                                        isClustered:isClustered,
                                                        fileVersionId:fileVersionId,
                                                        version:version,
                                                        docType:docType,
                                                        filePath:filePath
                                                    }

                                                    return (
                                                        <tr className={styles.search_result_list_row_content} key={index}>
                                                            <td>
                                                                <span className={styles.doc_name} onClick={() => this.onTogglePopup(selectedFileData)}>{docName}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className={styles.doc_status}>{docStatus}
                                                                </span>
                                                            </td>
                                                            {/* <td>
                                                                <span>{docAssignedTo} </span>
                                                            </td> */}
                                                            <td>
                                                                <span>{docUploadedBy} </span>
                                                            </td>
                                                            <td>
                                                                <span>{dueDate} </span>
                                                            </td>
                                                            <td>
                                                                <span onClick={() => this.onTogglePopup(selectedFileData)} className={styles.doc_version}><u>{versionHistory} Versions</u> </span>
                                                            </td>
                                                            <td style={{ position: 'relative', cursor:'pointer' }}>
                                            <i onClick={() => this.openActionPopup(fileId)} class="fas fa-ellipsis-v"></i>
                                            {this.state.selectedAction === fileId && <div className={styles.action_popup}>
                                            <p className={styles.actionText} onClick={() => {this.handleShowFile(filePath, docName)}}>Show Original File</p>
                                                </div>}
                                        </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>):(<div className={styles.no_result_text}>No result found</div>)}





                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showFile && <DisplayDocument showFileUrl={showFileUrl} fileName={fileNameToShow} closeHandler={this.handleCloseFile}></DisplayDocument>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        advanceSearchdata: state.dashboard.advanceSearch,
        fileJourneyData: state.allDocuments.fileJourneyData
    };
}

const mapDispatchToProps = {
    fileJourneyRequest: allDocuments.fileJourneyRequest

}
export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);