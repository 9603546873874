import React from 'react';
import './CommonModal.css';
import Modal from 'react-bootstrap/Modal';

const CommonModal = ({
  modalClass,
  header,
  children,
  closeHandler,
  hideCloseButton,
  backdropClassName,
  staticBackdrop,
}) => {
  const onClose = (event) => {
    // console.log("eee", event)
    // if (event.type === 'keydown' && event.keyCode === 27) return
    closeHandler();
  };
  return (
    <div>
      <Modal
        show={true}
        onHide={onClose}
        dialogClassName={modalClass}
        aria-labelledby="my-modal"
        centered
        backdrop={staticBackdrop ? true : 'static'}
        keyboard={false}
        backdropClassName={backdropClassName}
      >
        {header && (
          <Modal.Header closeButton={hideCloseButton ? false : true}>
            <Modal.Title id="my-modal">{header}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </div>
  );
};
export default CommonModal;
