import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as catalogLandingPageActions from './../../../store/actions/catalogLandingPageActions'

import styles from './CheckboxList.module.css'
// import { documents } from './../../../json-data/searchResults.json'

class CheckboxList extends Component {
  /* tagSelected: [array of corresponding selected value] */
  state = {
    [this.props.dataKey]: [],
  }

  componentDidMount() {
    // console.info(
    //   'this.props.catalogAdvancedSearchFilters',
    //   this.props.catalogAdvancedSearchFilters
    // )
    const allFilters = this.props.catalogAdvancedSearchFilters
    this.setState({
      ...allFilters,
    })
  }

  /* insert an empty array when a tag is selected */
  componentDidUpdate(prevProps) {
    if (
      (prevProps.dataKey !== undefined || prevProps.dataKey !== null) &&
      !this.state.hasOwnProperty(this.props.dataKey)
    ) {
      this.setState({
        [this.props.dataKey]: [],
      })
    }
  }

  resetState = () => {
    Object.keys(this.state).forEach(key => (this.state[key] = []))
    this.setState(this.state)
    this.props.dispatchAdvancedSearchFilters(null)
  }

  onValueChange = (e, value) => {
    const selectedCheckboxes = this.state[this.props.dataKey]

    // if (selectedCheckboxes ) return

    if (selectedCheckboxes.includes(value) && !e.target.checked) {
      selectedCheckboxes.splice(selectedCheckboxes.indexOf(value), 1)
    } else if (!selectedCheckboxes.includes(value) && e.target.checked) {
      selectedCheckboxes.push(value)
    }

    this.setState(
      {
        [this.props.dataKey]: selectedCheckboxes,
      },
      () => {
        this.props.dispatchAdvancedSearchFilters(this.state)
      }
    )
  }

  render() {
    const {
      data,
      tagSelected,
      showSelected,
      dataKey,
      width = 18,
      height = 18,
      margin,
      backgroundColor = '#eaeaea',
    } = this.props

    let listData = []
    if (data) listData = data[tagSelected]?.children.sort()

    if (showSelected) {
      listData = this.state[dataKey]
    }

    // console.info('list data', listData)
    // console.info('state: ', this.state)

    return (
      <>
        {listData?.map((item, index) => {
          if (typeof item !== 'string') return
          return (
            <div
              className={styles.checkbox_container}
              key={index}
              // style={{ margin: '5px' }}
            >
              <div
                className={styles.round}
                style={{
                  width,
                  height,
                }}
              >
                <input
                  type={'checkbox'}
                  id={tagSelected + '-' + index}
                  onChange={e =>
                    this.onValueChange(e, item)
                  } /* value={this.state.selectedValues[index]} */
                  name={''}
                  value={item}
                  checked={this.state[this.props.dataKey]?.includes(item)}
                  backgroundColor
                />
                <label
                  for={tagSelected + '-' + index}
                  style={{
                    width,
                    height,
                  }}
                ></label>
              </div>
              <span className={styles.checkbox_title}>{item}</span>
            </div>
          )
        })}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    catalogAdvancedSearchFilters: state.catalogLandingPage.catalogAdvancedSearchFilters,
  }
}
const mapDispatchToProps = {
  dispatchAdvancedSearchFilters:
    catalogLandingPageActions.catalogAdvancedSearchFilterAction,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CheckboxList)
