import * as types from "../types";

const initialState = {
  isLoading: false,
  listDocumentsData: null,
  fileJourneyData: null,
  deleteDocumentData: null,
  isDeleteDocumentLoading: false,
  filterDocumentsData: null,
  isFilterDocumentsLoading: false,
  error: null,
  fileData: null,
  loadingRoute: false,
  openFileJourney: false,
};

const defaultErrorMessage = "Something went wrong";
export default (state = initialState, action) => {
  switch (action.type) {
    // list documents Actions
    case types.LIST_DOCUMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        // listDocumentsData: null,
      };
    case types.LIST_DOCUMENTS_SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        listDocumentsData: action.payload.result,
      };
    }
    case types.LIST_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };

    // file journey Actions
    case types.FILE_JOURNEY_REQUEST:
      return {
        ...state,
        isLoading: true,
        fileJourneyData: null,
      };
    case types.FILE_JOURNEY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        fileJourneyData: action.payload.result,
      };
    }
    case types.FILE_JOURNEY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };
    // delete document Actions
    case types.DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteDocumentData: null,
        isDeleteDocumentLoading: true,
      };
    case types.DELETE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        deleteDocumentData: action.payload.result,
        isDeleteDocumentLoading: false,
      };
    }
    case types.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        isDeleteDocumentLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };
    // filter document Actions
    case types.FILTER_DOCUMENTS_REQUEST:
      return {
        ...state,
        filterDocumentsData: null,
        isFilterDocumentsLoading: true,
      };
    case types.FILTER_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        filterDocumentsData: action.payload.result,
        isFilterDocumentsLoading: false,
      };
    }
    case types.FILTER_DOCUMENTS_FAILURE:
      return {
        ...state,
        isFilterDocumentsLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };
    case types.SAVE_FILEDATA_REQUEST:
      return {
        ...state,
        loadingRoute: true,
      };
    case types.SAVE_FILEDATA_SUCCESS:
      return {
        ...state,
        fileData: action.payload,
        openFileJourney: true,
        loadingRoute: false,
      };
    case types.CLEAR_FILE_ID:
      return {
        ...state,
        fileData: null,
        openFileJourney: false,
      };
    default: {
      return state;
    }
  }
};
