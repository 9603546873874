import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as types from '../types'
import _api from '../../services/api'
import URLS from '../../services/urls'
import * as notification from '../actions/notificationAction'
import * as homepage from '../actions/homepageActions'

function* allNotificationsWorker(params) {
  try {
    const user = { userId: params.payload }
    const data = yield call(_api, URLS.allNotifications, user, 'POST')
    if (data && data.status !== 200) {
      throw data
    }
    yield put(notification.fetchAllNotificationsSuccess(data.result.data))
  } catch (e) {
    yield put(notification.fetchAllNotificationsFailure(e))
  }
}

function* markAllNotificationsReadWorker(params) {
  try {
    const ids = { ids: params.payload }
    const data = yield call(_api, URLS.markAllNotificationRead, ids, 'POST')
    if (data && data.status !== 200) {
      throw data
    }
    yield put(notification.markAllNotificationsReadSuccess(data.result.data))
  } catch (e) {
    yield put(notification.markAllNotificationsReadFailure(e))
  }
}
function* removeNotificationWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.removeNotification,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(notification.removeNotificationSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(notification.removeNotificationFailure(e))
  }
}

function* removeNotificationWatcher() {
  yield takeLatest(types.REMOVE_NOTIFICATION_REQUEST, removeNotificationWorker)
}

function* markNotificationAsReadWorker(params) {
  try {
    const id = { messageId: params.payload }
    const data = yield call(_api, URLS.markSingleNotificationRead, id, 'POST')
    if (data && data.status !== 200) {
      throw data
    }
    yield put(notification.markNotificationReadSuccess(data))
  } catch (e) {
    yield put(notification.markNotificationReadFailure(e))
  }
}

function* allNotificationsWatcher() {
  yield takeLatest(types.FETCH_ALL_NOTIFICATION_REQUEST, allNotificationsWorker)
}

function* markAllNotificationsReadWatcher() {
  yield takeLatest(
    types.MARK_ALL_NOTIFICATIONS_READ_REQUEST,
    markAllNotificationsReadWorker
  )
}

function* markNotificationAsReadWatcher() {
  yield takeLatest(
    types.MARK_NOTFICATION_READ_REQUEST,
    markNotificationAsReadWorker
  )
}

export default function* notificationSaga() {
  yield all([
    allNotificationsWatcher(),
    markAllNotificationsReadWatcher(),
    removeNotificationWatcher(),
    markNotificationAsReadWatcher(),
  ])
}
