import React, { Component } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import styles from "./Loader.module.css"
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true
        }
    }

    componentDidUpdate() {
      
    }

    render() {
        return (
<div className={styles.outer}>
  <div className={styles.middle}>
    <div className={styles.inner}>
    <BeatLoader
         css={override}
          size={25}
          color={"#11256D"}
          loading={this.state.loading}
        />
    </div>
  </div>
        </div>
        );
    }
}



export default Loader;