import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { withRouter } from 'react-router-dom';
import { IconContext } from 'react-icons';
// import { BsCircleFill } from 'react-icons/bs'
import { FiShare2 } from 'react-icons/fi';

import grey_document from '../../../../assets/grey_document.svg';
import tag_icon from '../../../../assets/tag_icon.svg';
// import delete_icon from '../../../../assets/delete_icon.svg'
// import cloud_download from '../../../../assets/cloud_download.svg'
import three_dot_icon from '../../../../assets/three_dot_icon.svg';
// import { documents } from './../../../../json-data/documentAnalytics.json'
import styles from './RecentDocuments.module.css';
// import { formatFilename } from '../../../../shared/helper';

class RecentDocuments extends Component {
  state = {
    selectedAction: null,
  };

  documentHandler = (fileIndex) => {
    const { data } = this.props;
    if (data) {
      const fileId = data[fileIndex].fileId;
      const filePath = data[fileIndex].filePath;
      this.props.history.push(
        {
          pathname: '/document-analytics',
          search: '?source=workflow-management',
        },
        {
          fileId,
          filePath,
        }
      );
    }
  };

  formatFilename = (fileName) => {
    let modifiedName = '';
    let arr = fileName.split('.');
    if (arr[arr.length - 1] === 'pdf') {
      arr.pop();
      let temp = arr.join('.');
      fileName = temp;
    }
    if (fileName.length > 30) {
      modifiedName = fileName.slice(0, 30) + '...';
    } else {
      modifiedName = fileName;
    }
    return modifiedName.replace(/%20/g, '');
  };

  render() {
    return (
      <div className={styles.recent_doc_container}>
        {this.props.data &&
          this.props.data.map((doc, index) => {
            return (
              <div
                className={`${styles.recent_document_card_wrapper}`}
                // onClick={() => this.documentHandler(index)}
              >
                <div
                  className={`${styles.recent_doc_card} ${
                    this.props.selectedSimilarDoc === doc.fileId
                      ? styles.related_document_active_doc
                      : ''
                  }`}
                >
                  <div
                    className={styles.related_doc_click_area}
                    onClick={() => this.props.handleDocumentChange(doc.fileId)}
                  >
                    <div className={styles.recent_doc_card_upper_content}>
                      <img
                        className={styles.recent_doc_grey_document}
                        src={grey_document}
                        alt="document"
                      />
                      <span>HIGH</span>
                    </div>
                    <div className={styles.recent_doc_card_title}>
                      {this.formatFilename(doc.fileName)}
                    </div>
                  </div>

                  <div className={styles.recent_doc_card_bottom_content}>
                    <div className={styles.recent_doc_card_bottom_text}>
                      {doc.metaData.year}
                    </div>

                    <div className={styles.action_dots}>
                      <img
                        className={styles.recent_doc_three_dot_icon}
                        src={three_dot_icon}
                        alt="three_dot_icon"
                      />

                      {this.state.selectedAction === index + 1 && (
                        <OutsideClickHandler onOutsideClick={() => {}}>
                          <div className={styles.action_popup}>
                            <div className={styles.action_popup_tag}>
                              <img src={tag_icon} alt="tag_icon"></img>{' '}
                              <span className={styles.action_popup_text}>
                                Tag
                              </span>
                            </div>

                            <OutsideClickHandler onOutsideClick={() => {}}>
                              <div
                                className={styles.action_popup_share}
                                /*  style={
                                  shareAction === index
                                    ? {
                                        color: '#fff',
                                        backgroundColor: '#11256D',
                                      }
                                    : {}
                                } */
                              >
                                <div className={styles.share_content_wrapper}>
                                  <span className={styles.action_share_icon}>
                                    <IconContext.Provider
                                      value={{ color: '#ffffff', size: 21 }}
                                    >
                                      <FiShare2 />
                                    </IconContext.Provider>
                                  </span>

                                  <span className={styles.action_popup_text}>
                                    Share
                                  </span>
                                </div>

                                {/* {shareAction === index && (
                                  <Share
                                    index={index}
                                    top={'41px'}
                                    left={'185px'}
                                    right={'185px'}
                                  />
                                )} */}
                              </div>
                            </OutsideClickHandler>
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.chips_container}>
                  <span>{doc.fileType}</span>
                  <span>
                    {doc.metaData?.jurisdiction
                      ? doc.metaData.jurisdiction
                      : 'N/A'}
                  </span>
                  {/* <span>{doc.metaData?.year ? doc.metaData.year : 2020}</span> */}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default withRouter(RecentDocuments);
