import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./shared/ErrorBoundary/ErrorBoundary";

import "./index.css";
import App from "./App";
import store, { persistor } from "./store";

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* <ErrorBoundary> */}
        <App />
      {/* </ErrorBoundary> */}
    </PersistGate>
  </Provider>,
  //  </React.StrictMode>,
  document.getElementById("root")
);
