import * as types from '../types';

const initialState = {
  isLoading: false,
  filesStatus: null,
  error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // Update Profile Actions
    case types.CATALOG_FILES_STATUS_REQUEST:
      return {
        ...state,
        filesStatus: null,
        isLoading: true,
      };
    case types.CATALOG_FILES_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        filesStatus: action.payload.result.data,
      };
    }
    case types.CATALOG_FILES_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    default: {
      return state;
    }
  }
};
