import React from 'react'

import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'
import RightBar from '../../screens/workflow-management/RightBar/RightBar'
import DataCatalogAllFoldersOne from '../data-catalog-all-folders-one/DataCatalogAllFoldersOne'
import DataCatalogAllFoldersTwo from '../data-catalog-all-folders-two/DataCatalogAllFoldersTwo'

import styles from './DataCatalogAllFolders.module.css'
import commonStyles from '../../shared/Common.module.css'

class DataCatalogAllFolders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allFilesData: null,
      searchItems: '',
    }
  }

  // componentDidMount() {
  //   const paramsObj = {
  //     referenceId: this.props.history.location?.state?.referenceId,
  //     catalogSourceId: this.props.history.location?.state?.catalogSourceId,
  //     limit: 4,
  //   }
  //   this.props.catalogRecentFolders(paramsObj)
  // }
  // componentDidUpdate(prevProps) {
  //   if (
  //     !this.props.isSelectedFolderDataLoading &&
  //     prevProps.isSelectedFolderDataLoading &&
  //     this.props.selectedFolderData?.allFiles
  //   ) {
  //     this.setState({
  //       allFilesData: this.props.selectedFolderData.allFiles,
  //     })
  //   }
  // }
  // handleBackNavigation = () => {
  //   // this.props.history.push('/data-catalog')
  //   this.props.history.goBack()
  // }
  // handleUserInput = event => {
  //   var searchItems = event.target.value
  //   this.setState({searchItems:event.target.value})
  // }

  render() {
    // console.log(
    //   'this.props.history.location?.state?.hasMore',
    //   this.props.history.location?.state?.hasMore
    // )
    return (
      <React.Fragment>
        <NavBar></NavBar>
        <Sidebar></Sidebar>
        <RightBar></RightBar>
        <div
          className={`${commonStyles.commom_data_catalog_wrapper} ${styles.data_catalog_all_folders_container}`}
        >
          {!this.props.history.location?.state?.hasMore && (
            <DataCatalogAllFoldersOne
              history={this.props.history}
            ></DataCatalogAllFoldersOne>
          )}
          {this.props.history.location?.state?.hasMore && (
            <DataCatalogAllFoldersTwo
              history={this.props.history}
              catalogSourceId={
                this.props.history.location?.state?.catalogSourceId
              }
              referenceId={this.props.history.location?.state?.referenceId}
              isPrivate={this.props.history.location?.state?.isPrivate}
            ></DataCatalogAllFoldersTwo>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default DataCatalogAllFolders
