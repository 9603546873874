import React, { Component } from "react";
import styles from "./ReportsGraph.module.css";
// import commonStyles from '../../shared/Common.module.css';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';

class ReportsGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTeam: null,
            selectedMember: null,
            members: [],
        }
    }

    componentDidMount() {
        // this.setState({ chartData: { ...this.state.chartData, data: this.props.reportsAnalytics.datasets[0].data, labels: this.props.reportsAnalytics.labels } })
    }
    handleTeamChange = selectedTeam => {
        this.setState({ selectedTeam: selectedTeam, members: selectedTeam.members });
        this.props.getFilteredGraphData(selectedTeam, selectedTeam.members)
    };
    handleMemberChange = selectedMember => {
        this.setState({ selectedMember });
        this.props.getFilteredGraphData(null, null, true, selectedMember);

    }
    getChartData = (labels = [], data = []) => {
        return {
            labels,
            datasets: [{
                label: 'Number of Documents',
                data,
                backgroundColor: '#EBEEFE',
                borderWidth: 1,
            }]
        };
    }

    viewReports = () => {
        this.props.history.push('/graphs')
    }
    render() {
        const roleType = localStorage.getItem('role')
        const data = this.props.reportsAnalytics.graphs[0].yAxis;
        const labels = this.props.reportsAnalytics.graphs[0].xAxis;
        const chartData = this.getChartData(labels, data);
        const xLabel = this.props.reportsAnalytics.graphs[0].xTitle
        const yLabel = this.props.reportsAnalytics.graphs[0].yTitle
        const teamOptions = this.props.reportsAnalytics.teamMembers.teams.map(item => {

            return ({
                value: item.teamId,
                label: item.teamName,
                members: item.members
            })
        })
        let memberOptions = {}
        if (this.props.members && this.props.members.length > 0) {
            memberOptions = this.props.members.map(item => {
                return ({
                    value: item.teamMemberId,
                    label: item.name
                })
            })
        }
        return (
            <div className="reports_graph_container" style={{ height: '100%' }}>

            { roleType ==="manager" &&    <div className="row" style={{ marginBottom: '10px' }}>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <Select
                            value={this.props.selectedTeam}
                            onChange={this.handleTeamChange}
                            options={teamOptions}
                            placeholder="Select Team"
                        />
                    </div>
                    <div className="col-md-4">

                        <Select
                            value={this.props.selectedMember}
                            onChange={this.handleMemberChange}
                            options={memberOptions}
                            placeholder="Select Member"
                        />
                    </div>
                </div>}
                <Bar data={chartData}
                    options={{
                        scales: {
                            xAxes: [{
                                barThickness: 50,
                                gridLines: {
                                    display: false
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: xLabel,
                                  }
                            }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (value) { if (value % 1 === 0) { return value; } }
                                },
                                gridLines: {
                                    display: false
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: yLabel,
                                  }
                            }]
                        },
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                    }}
                />
                <div className={styles.graph_action_btn_container}>
                    <div className={styles.graph_action_btn_section}>
                        <div className={styles.graph_bottom_subtitle}><i className="fas fa-chevron-left"></i><span onClick={this.viewReports}>Documents Processed By Date</span></div>
                        <div className={styles.graph_bottom_title}>Documents By Clients</div>
                        <div className={styles.graph_bottom_subtitle}><span onClick={this.viewReports}>Documents Processed By Client Name</span><i className="fas fa-chevron-right"></i> </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ReportsGraph;