import React from "react";
import styles from "./DocCount.module.css";
import commonStyles from '../../../shared/Common.module.css';
import Total_Documents from '../../../../src/assets/Total_Documents.svg'
import Pending_Doc from '../../../../src/assets/Pending_Doc.svg'
import In_Review_Docs from '../../../../src/assets/In_Review_Docs.svg'
import Completed_Docs from '../../../../src/assets/Completed_Docs.svg'

const docCountImgMap = {
    0:Total_Documents,
    1:Pending_Doc,
    2:In_Review_Docs,
    3:Completed_Docs
}

const DocCount = (props) => {
    return (
        <React.Fragment>
            <div className={styles.doc_count_card_container}>
                <div className={`card ${commonStyles.card_layout}`}>
                    <div className={`card-body ${styles.doc_count_card_body}`}>
                        {/* <div className={styles.circle}><i className="fas fa-folder"></i></div> */}
                        <img src={docCountImgMap[props.index]} alt="" />
                        <div style={{display:'inline-block'}}>
                        <div className={styles.doc_count}>{props.docCount.count}</div>
                        <div className={styles.doc_title}>{props.docCount.title} Documents</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default DocCount;