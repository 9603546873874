import * as types from '../types'
// Catalog Search Result Data Actions

export const catalogSearchResultDataRequest = payload => ({
  type: types.CATALOG_SEARCH_RESULT_DATA_REQUEST,
  payload,
})
export const catalogSearchResultDataSuccess = payload => ({
  type: types.CATALOG_SEARCH_RESULT_DATA_SUCCESS,
  payload,
})
export const catalogSearchResultDataFailure = payload => ({
  type: types.CATALOG_SEARCH_RESULT_DATA_FAILURE,
  payload,
})

// Catalog Search Related Folders Actions

export const catalogSearchRelatedFoldersRequest = payload => ({
  type: types.CATALOG_SEARCH_RELATED_FOLDERS_REQUEST,
  payload,
})
export const catalogSearchRelatedFoldersSuccess = payload => ({
  type: types.CATALOG_SEARCH_RELATED_FOLDERS_SUCCESS,
  payload,
})
export const catalogSearchRelatedFoldersFailure = payload => ({
  type: types.CATALOG_SEARCH_RELATED_FOLDERS_FAILURE,
  payload,
})

//  For Filters
export const catalogSearchResultFilterAction = payload => ({
  type: types.CATALOG_SEARCH_FILTERS_DATA,
  payload,
})

//  For Filtered documents
export const catalogSearchResultFilteredDocumentsAction = payload => ({
  type: types.CATALOG_SEARCH_FILTERED_DOCUMENTS,
  payload,
})

//  for saving search term
export const catalogSearchResultSearchTermAction = payload => ({
  type: types.SAVE_CATALOG_SEARCH_TERM,
  payload,
})

//  for saving selected sources
export const catalogSelectedSourcesAction = payload => ({
  type: types.CATALOG_SELECTED_SOURCES,
  payload,
})
