import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as types from '../types'
import _api from '../../services/api'
import URLS from '../../services/urls'
import * as workflowManagementAllProjects from '../actions/workflowManagementAllProjectsAction'

function* workflowManagementProjectDetailsDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestWorkflowManagementProject,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(
      workflowManagementAllProjects.workflowManagementProjectDetailsSuccess(
        data
      )
    )
  } catch (e) {
    console.error('error  : ', e)
    yield put(
      workflowManagementAllProjects.workflowManagementProjectDetailsFailure(e)
    )
  }
}

function* workflowManagementProjectDetailsDataWatcher() {
  yield takeLatest(
    types.WORKFLOW_MANAGEMENT_PROJECT_DETAILS_REQUEST,
    workflowManagementProjectDetailsDataWorker
  )
}

function* workflowManagementAddMemberDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestWorkflowManagementAddMember,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(
      workflowManagementAllProjects.workflowManagementAddMemberSuccess(data)
    )
  } catch (e) {
    console.error('error  : ', e)
    yield put(
      workflowManagementAllProjects.workflowManagementAddMemberFailure(e)
    )
  }
}

function* workflowManagementAddMemberDataWatcher() {
  yield takeLatest(
    types.WORKFLOW_MANAGEMENT_ADD_MEMBER_REQUEST,
    workflowManagementAddMemberDataWorker
  )
}

function* workflowManagementRemoveMemberDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestWorkflowManagementRemoveMember,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(
      workflowManagementAllProjects.workflowManagementRemoveMemberSuccess(data)
    )
  } catch (e) {
    console.error('error  : ', e)
    yield put(
      workflowManagementAllProjects.workflowManagementRemoveMemberFailure(e)
    )
  }
}

function* workflowManagementRemoveMemberDataWatcher() {
  yield takeLatest(
    types.WORKFLOW_MANAGEMENT_REMOVE_MEMBER_REQUEST,
    workflowManagementRemoveMemberDataWorker
  )
}

export default function* workFlowManagementAllProjectsSaga() {
  yield all([
    workflowManagementProjectDetailsDataWatcher(),
    workflowManagementAddMemberDataWatcher(),
    workflowManagementRemoveMemberDataWatcher(),
  ])
}
