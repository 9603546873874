import React, { Component } from "react";
import ClusteredResultDocInfo from "../clustered-result-doc-info/ClusteredResultDocInfo";
import ClusteredResultSimilarPara from "../clustered-result-similar-para/ClusteredResultSimilarPara";
import SimilarDocs from "../similar-docs/SimilarDocs";
import styles from "./ClusteredParagraphsDetails.module.css";
import commonStyles from "../../../shared/Common.module.css";
import { Tab, Tabs } from "react-bootstrap";
import * as uploadActions from "../../../store/actions/uploadedDocActions";
import PortalDemo from "../../../shared/portal";
import {handleSuccess, handleError } from '../../../shared/HandleMessages'
import ResponseMessagePopup from '../response-message-popup/ResponseMessagePopup'
import { connect } from "react-redux";
class ClusteredParagraphsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prepareResponse: false,
      value: "",
      isLoading: false,
      isDisabled:
        this.props.uploadedDoc.clusterData.data.fileVersion.docType === "1"
          ? false
          : true,
      shouldOpenMessagePopup:false
    };
  }
  componentDidMount() {
    this.setState({ value: this.props.uploadedDoc.response });
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.isPrepareResponseLoading &&
      prevProps.isPrepareResponseLoading && this.props.uploadedDoc.prepareResponse
    ) {
      this.setState({
        prepareResponse: this.props.uploadedDoc.prepareResponse,
        value: this.props.uploadedDoc.response,
        isLoading: !this.state.isLoading,
      });
    }
    // if (
    //   !this.props.isSubmitResponseLoading &&
    //   prevProps.isSubmitResponseLoading
    // ) {
    //   this.setState({
    //     isLoading: false,
    //     prepareResponse: false,
        
    //   });
    //   handleSuccess(this.props.message)
    //   this.props.history.push({ pathname: '/submit',
    //   state: {filename: this.props.uploadedDoc.clusterData.data.fileVersion.fileName, status: this.props.uploadedDoc.clusterData.data.fileVersion.status}})
    //   this.props.clearResponse();
    // }
  }

  componentWillUnmount() {
    // this.props.clearResponse();
    // this.props.clearSimilarStore();

    this.props.revertActiveState('doc_info');
    this.props.similarParaInit()
  }

  getUniqueTags = () => {
    let allTags = this.props.uploadedDoc.clusterData.data.result.map((para) => {
      let subTagsArray = Object.values(para.subTags);
      return subTagsArray[0];
    });
    let uniqueTags = [...new Set(allTags)];
    return uniqueTags;
    // if(this.props.tempoTags.length){
    // let allTags = this.props.tempoTags.map(para => {
    //         return(para.mainTags)
    //     })
    //     let uniqueTags = [...new Set(allTags)];
    //     return uniqueTags
    // }
  };
  prepareResponse = () => {
    if(this.props.uploadedDoc.clusterData.data.fileVersion.isResponse){
      this.setState({
        prepareResponse: true,
        value: this.props.uploadedDoc.clusterData.data.fileVersion.fileResponse,
      })
    }
    else{
    this.setState({ isLoading: true });
    const id = this.props.uploadedDoc.clusterData.data.fileVersion.fileId;
    this.props.prepareResponse({ fileId: id });
    // this.setState({
    //   responseData: this.props.uploadedDoc.response,
    //   prepareResponse: this.props.uploadedDoc.prepareResponse,
    //   value: this.props.uploadedDoc.response,
    // });
  }
}

  clearResponse = () => {
    this.setState({ value: "", isLoading: false });
  };

  handlePrepareResponse = () => {
    this.setState({ isLoading: false, prepareResponse: false });
    this.props.clearResponse();
  };
  handleResponseChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmitResponse = () => {
    this.setState({shouldOpenMessagePopup:true, prepareResponse: false,isLoading: false})
  };

  handleSelect = (e) => {
    this.props.revertActiveState(e);
  };
  onMessagePopupClose = () => {
    this.setState({shouldOpenMessagePopup:false})
  }
  onMessageSubmit = () => {
        const id = this.props.uploadedDoc.clusterData.data.fileVersion.fileId;
    this.props.submitResponse({ response: this.state.value, fileId: id });
    // this.props.history.push({ pathname: '/submit',
    // state: {filename: this.props.uploadedDoc.clusterData.data.fileVersion.fileName, status: this.props.uploadedDoc.clusterData.data.fileVersion.status}})
  }
  render() {
    const buttonTitle = this.props.uploadedDoc.clusterData.data.fileVersion.isResponse?"EDIT RESPONSE":"PREPARE RESPNSE"
    
    let uniqueTags = this.getUniqueTags();
    let addditionalProps = {};

    if (this.props.uploadedDoc.mainTab) {
      addditionalProps["activeKey"] = this.props.uploadedDoc.mainTab;
    }

    const { value, isLoading, isDisabled, prepareResponse, shouldOpenMessagePopup } = this.state;
    if(prepareResponse){
      this.props.history.push({ pathname: '/test'})
    }
    return (
      <div className="thin_custom_scrollbar">
        <div
          className={`tab_with_bottom_border ${styles.clustered_paragraphs_details_container}`}
        >
          <Tabs
            id="clustered-paragraphs-details-tab"
            {...addditionalProps}
            onSelect={this.handleSelect}
          >
            <Tab eventKey="doc_info" title="Document Info">
              <ClusteredResultDocInfo
                selectedTags={this.props.selectedTags}
                uniqueTags={uniqueTags}
                handleTagSelection={this.props.handleTagSelection}
              ></ClusteredResultDocInfo>
            </Tab>
            <Tab eventKey="similar_para" title="Similar Paragraphs">
              <ClusteredResultSimilarPara
                data={this.props.uploadedDoc.similarParas}
              ></ClusteredResultSimilarPara>
            </Tab>
            <Tab eventKey="similar_docs" title="Similar Docs">
              <SimilarDocs data={this.props.uploadedDoc.similarDocs} />
            </Tab>
          </Tabs>
        </div>
        {prepareResponse && (


          <PortalDemo
            handlePrepareResponse={this.handlePrepareResponse}
            handleResponseChange={this.handleResponseChange}
            handleSubmitResponse={this.handleSubmitResponse}
            clearResponse={this.clearResponse}
            value={value}
          ></PortalDemo>
        )}

   { this.props.showTopStrip &&    <div className={styles.bottom_container}>
          <div className={styles.bottom_container_section}>
            <button
              type="button"
              className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
              onClick={this.prepareResponse}
              disabled={isDisabled}
            >
              {isLoading && <i className="fas fa-spinner fa-spin"></i>} {buttonTitle}
            </button>
          </div>
        </div>}


        {/* {shouldOpenMessagePopup && (
          <ResponseMessagePopup
            closeHandler={this.onMessagePopupClose}
            onMessageSubmit={this.onMessageSubmit}
          ></ResponseMessagePopup>
        )} */}


      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uploadedDoc: state.uploadedDoc,
    dashboard: state.dashboard,
    isPrepareResponseLoading: state.uploadedDoc.isPrepareResponseLoading,
    isSubmitResponseLoading: state.uploadedDoc.isSubmitResponseLoading,
    message: state.uploadedDoc.message,
  };
};
const mapDispatchToProps = {
  prepareResponse: uploadActions.prepareResponseRequest,
  clearResponse: uploadActions.clearResponse,
  revertActiveState: uploadActions.revertActiveState,
  submitResponse: uploadActions.submitResponseRequest,
  clearSimilarStore: uploadActions.clearSimilarPara,
  similarParaInit: uploadActions.similarParaInit
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClusteredParagraphsDetails);
