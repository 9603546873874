import { combineReducers } from 'redux'

import homepageReducer from './homepageReducer'
import teamManagementReducer from './teamManagementReducer'
import accountSettingsReducer from './accountSettingsReducer'
import uploadDocReducer from './uploadDocReducer'
import uploadedDocReducer from './uploadedDocReducer'
import clusteredResultReducer from './clusteredResultReducer'
import dashboardReducer from './dashboardReducer'
import allDocumentsReducer from './allDocumentsReducer'
import notificationReducer from './notificationReducer'
import graphsReducer from './graphsReducer'
import supportReducer from './supportReducer'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import dataSourceAuthReducer from './dataSourceAuthReducer'
import dataSourceInfoReducer from './dataSourceInfoReducer'
import catalogLandingPageReducer from './catalogLandingPageReducer'
import catalogSearchResultsReducer from './catalogSearchResultsReducer'
import catalogAllSearchResultReducer from './catalogAllSearchResultReducer'
import catalogSelectedFolderReducer from './catalogSelectedFolderReducer'
import filesStatusReducer from './filesStatusReducer'
import graphDataReducer from './graphDataReducer'
import allFoldersTreeReducer from './allFoldersTreeReducer'
import workFlowManagementLandingPageReducer from './workFlowManagementLandingPageReducer'
import workflowManagementAllProjectsReducer from './workflowManagementAllProjectsReducer'
import navbarReducer from './navbarReducer'
import documentAnalyticsHistoryReducer from './documentAnalyticsHistoryReducer'
import fileClusterDataReducer from './fileClusterDataReducer'
import dashboardV2Reducer from './dashboard-v2Reducer'
import privateFolderReducer from './privateFolderReducer'
import fileCommentReducer from './fileCommentReducer'
import sidebarReducer from './sidebarReducer'
import createDocumentReducer from './createDocumentReducer'
import fileLinkReducer from './fileLinkReducer'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'dashboard',
    'uploadedDoc',
    // 'catalogSearchResult',
    'catalogLandingPage',
    'uploadDoc',
    'sidebar',
    'createPrivateDocument',
  ],
}
const dashboardPersistConfig = {
  key: 'dashboard',
  storage,
  blacklist: [
    'isFileReadLoading',
    'isAdvanceSearchFetching',
    'isLoading',
    'userDashboard',
    'reportAnalysisGraph',
    'error',
  ],
}
const uploadedDocPersistConfig = {
  key: 'uploadedDoc',
  storage,
  blacklist: ['isLoading', 'paraId', 'mainTab', 'subTab', ['prepareResponse']],
}
const allDocumentsPersistConfig = {
  key: 'allDocuments',
  storage,
  blacklist: ['listDocumentsData'],
}
const catalogLandingPageConfig = {
  key: 'catalogLandingPage',
  storage,
  blacklist: [
    'iscatalogRecentFoldersLoading',
    'catalogRecentFolders',
    'catalogRecentDocuments',
    'iscatalogRecentDocumentsLoading',
    'catalogAllFolders',
    'isCatalogAllFoldersLoading',
    'catalogAllSource',
    'isCatalogAllSourceLoading',
    'catalogAdvancedSearchFilters',
    'error',
  ],
}
const catalogSearchResultConfig = {
  key: 'catalogSearchResult',
  storage,
  blacklist: [
    'catalogSearchResultData',
    'isCatalogSearchResultDataLoading',
    'catalogSearchResultFiltersData',
    'catalogSearchResultFilteredDocuments',
    'catalogSelectedSources',
    'error',
  ],
}
const catalogAllSearchResultConfig = {
  key: 'catalogAllSearchResult',
  storage,
  blacklist: [
    'catalogAllSearchResultData',
    'isCatalogAllSearchResultDataLoading',
    'error',
  ],
}
const catalogSelectedFolderConfig = {
  key: 'catalogSelectedFolder',
  storage,
  blacklist: [
    'selectedFolderData',
    'isSelectedFolderDataLoading',
    'selectedFolderTreeData',
    'isSelectedFolderTreeDataLoading',
    'selectedFileTreeData',
    'isSelectedFileTreeDataLoading',
    'error',
  ],
}
const graphDataConfig = {
  key: 'landingPageGraphData',
  storage,
  blacklist: ['graphData', 'isLoading', 'error'],
}
const allFoldersTreeConfig = {
  key: 'allFoldersTree',
  storage,
  blacklist: [
    'catalogAllFoldersTreeData',
    'isCatalogAllFoldersTreeDataLoading',
    'error',
  ],
}
const workFlowManagementLandingPageConfig = {
  key: 'workFlowManagementLandingPage',
  storage,
  blacklist: [
    'workflowManagementProjectData',
    'isWorkflowManagementProjectDataLoading',
    'workflowManagementProjectGraphData',
    'isWorkflowManagementProjectGraphDataLoading',
    'workflowManagementDropdownData',
    'isWorkflowManagementDropdownDataLoading',
    'workflowManagementCreateProject',
    'isWorkflowManagementCreateProjectLoading',
    'error',
  ],
}
const workflowManagementAllProjectsConfig = {
  key: 'workflowManagementAllProjects',
  storage,
  blacklist: [
    'workflowManagementProjectDetailsData',
    'isWorkflowManagementProjectDetailsDataLoading',
    'workflowManagementAddMemberData',
    'isWorkflowManagementAddMemberDataLoading',
    'workflowManagementRemoveMemberData',
    'isWorkflowManagementRemoveMemberDataLoading',
    'error',
  ],
}
const navbarConfig = {
  key: 'navbar',
  storage,
  blacklist: ['quickSummary', 'isQuickSummaryLoading', 'error'],
}
const dashboardV2Config = {
  key: 'dashboardV2Page',
  storage,
  blacklist: [
    'fileClusterData',
    'clusteredData',
    'isClusteredLoading',
    'isFileClustering',
    'notifications',
  ],
}
const rootReducer = combineReducers({
  homepage: homepageReducer,
  team: teamManagementReducer,
  accountsSettings: accountSettingsReducer,
  uploadDoc: uploadDocReducer,
  uploadedDoc: persistReducer(uploadedDocPersistConfig, uploadedDocReducer),
  clusteredResult: clusteredResultReducer,
  dashboard: persistReducer(dashboardPersistConfig, dashboardReducer),
  allDocuments: persistReducer(allDocumentsPersistConfig, allDocumentsReducer),
  notifications: notificationReducer,
  allGraphs: graphsReducer,
  support: supportReducer,
  dataSourceAuth: dataSourceAuthReducer,
  dataSourceInfo: dataSourceInfoReducer,
  catalogLandingPage: persistReducer(
    catalogLandingPageConfig,
    catalogLandingPageReducer
  ),
  // catalogSearchResult: persistReducer(
  //   catalogSearchResultConfig,
  //   catalogSearchResultsReducer
  // ),
  catalogSearchResult: catalogSearchResultsReducer,
  catalogAllSearchResult: persistReducer(
    catalogAllSearchResultConfig,
    catalogAllSearchResultReducer
  ),
  catalogSelectedFolder: persistReducer(
    catalogSelectedFolderConfig,
    catalogSelectedFolderReducer
  ),
  filesStatus: filesStatusReducer,
  graphData: persistReducer(graphDataConfig, graphDataReducer),
  allFoldersTree: persistReducer(allFoldersTreeConfig, allFoldersTreeReducer),
  workFlowManagementLandingPage: persistReducer(
    workFlowManagementLandingPageConfig,
    workFlowManagementLandingPageReducer
  ),
  workflowManagementAllProjects: persistReducer(
    workflowManagementAllProjectsConfig,
    workflowManagementAllProjectsReducer
  ),
  navbar: persistReducer(navbarConfig, navbarReducer),
  documentAnalyticsHistory: documentAnalyticsHistoryReducer,
  fileClusterData: fileClusterDataReducer,
  dashboardV2Page: persistReducer(dashboardV2Config, dashboardV2Reducer),
  privateFolderComponent: privateFolderReducer,
  fileComment: fileCommentReducer,
  sidebar: sidebarReducer,
  createPrivateDocument: createDocumentReducer,
  fileLink: fileLinkReducer,
})

export default persistReducer(persistConfig, rootReducer)
