// Login Types
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

// Login Types
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT'
// export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
// export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// Signup Types
export const REQUEST_SIGNUP = 'REQUEST_SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

// EMAIL Exist Types
export const EMAIL_EXIST_REQUEST = 'EMAIL_EXIST_REQUEST'
export const EMAIL_EXIST_SUCCESS = 'EMAIL_EXIST_SUCCESS'
export const EMAIL_EXIST_FAILURE = 'EMAIL_EXIST_FAILURE'

// FORGOT PASSWORD Types
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

// NOTIFICATION types
export const WS_CONNECTION_REQUEST = 'CONNECTION_REQUEST'
export const WS_CONNECTION_SUCCESS = 'CONNECTION_SUCCESS'
export const WS_CONNECTION_FAILURE = 'CONNECTION_FAILURE'

export const SAVE_NOTIFICATION_REQUEST = 'SAVE_NOTIFICATION_REQUEST'
export const SAVE_NOTIFICATION_SUCCESS = 'SAVE_NOTIFICATION_SUCCESS'
export const SAVE_NOTIFICATION_FAILURE = 'SAVE_NOTIFICATION_FAILURE'

export const REMOVE_NOTIFICATION_REQUEST = 'REMOVE_NOTIFICATION_REQUEST'
export const REMOVE_NOTIFICATION_SUCCESS = 'REMOVE_NOTIFICATION_SUCCESS'
export const REMOVE_NOTIFICATION_FAILURE = 'REMOVE_NOTIFICATION_FAILURE'

export const FETCH_ALL_NOTIFICATION_REQUEST = 'FETCH_ALL_NOTIFICATION_REQUEST'
export const FETCH_ALL_NOTIFICATION_SUCCESS = 'FETCH_ALL_NOTIFICATION_SUCCESS'
export const FETCH_ALL_NOTIFICATION_FAILURE = 'FETCH_ALL_NOTIFICATION_FAILURE'

export const MARK_ALL_NOTIFICATIONS_READ_REQUEST =
  'MARK_ALL_NOTIFICATIONS_READ_REQUEST'
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS =
  'MARK_ALL_NOTIFICATIONS_READ_SUCCESS'
export const MARK_ALL_NOTIFICATIONS_READ_FAILURE =
  'MARK_ALL_NOTIFICATIONS_READ_FAILURE'

export const MARK_NOTFICATION_READ_REQUEST = 'MARK_NOTFICATION_READ_REQUEST'
export const MARK_NOTFICATION_READ_SUCCESS = 'MARK_NOTFICATION_READ_SUCCESS'
export const MARK_NOTFICATION_READ_FAILURE = 'MARK_NOTFICATION_READ_FAILURE'
export const CURRENT_PROJECT_ID = 'CURRENT_PROJECT_ID'

export const HOMEPAGE_UNREAD_NOTIFICATION_REQUEST =
  'HOMEPAGE_UNREAD_NOTIFICATION_REQUEST'
export const HOMEPAGE_NEW_NOTIFICATION_ID_REQUEST =
  'HOMEPAGE_NEW_NOTIFICATION_ID_REQUEST'

// CLEAR HOMEPAGE ERROR type
export const CLEAR_HOMEPAGE_ERROR = 'CLEAR_HOMEPAGE_ERROR'

export const UPLOAD_DOCUMENT_MODAL_PARENT = 'UPLOAD_DOCUMENT_MODAL_PARENT'

// TEAM LIST Types
export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST'
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS'
export const TEAM_LIST_FAILURE = 'TEAM_LIST_FAILURE'

// ADD TEAM MEMBER Types
export const ADD_TEAM_MEMBER_REQUEST = 'ADD_TEAM_MEMBER_REQUEST'
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS'
export const ADD_TEAM_MEMBER_FAILURE = 'ADD_TEAM_MEMBER_FAILURE'

// CREATE TEAM Types
export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST'
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS'
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE'

// DELETE TEAM MEMBER Types
export const DELETE_TEAM_MEMBER_REQUEST = 'DELETE_TEAM_MEMBER_REQUEST'
export const DELETE_TEAM_MEMBER_SUCCESS = 'DELETE_TEAM_MEMBER_SUCCESS'
export const DELETE_TEAM_MEMBER_FAILURE = 'DELETE_TEAM_MEMBER_FAILURE'

// TEAM ROLE Types
export const TEAM_ROLE_REQUEST = 'TEAM_ROLE_REQUEST'
export const TEAM_ROLE_SUCCESS = 'TEAM_ROLE_SUCCESS'
export const TEAM_ROLE_FAILURE = 'TEAM_ROLE_FAILURE'

// UPDATE PROFILE Types
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

// CHANGE PASSWORD Types
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

// CLEAR ACCOUNT SETTINGS ERROR type
export const CLEAR_ACCOUNT_SETTINGS_RESPONSE_MESSAGES =
  'CLEAR_ACCOUNT_SETTINGS_RESPONSE_MESSAGES'

// UPLOAD DOC Types
export const UPLOAD_DOC_REQUEST = 'UPLOAD_DOC_REQUEST'
export const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS'
export const UPLOAD_DOC_FAILURE = 'UPLOAD_DOC_FAILURE'
export const CLEAR_UPLOAD_DOC_LOADER = 'CLEAR_UPLOAD_DOC_LOADER'

// UPLOADED DOC Types
export const CLUSTER_DATA_REQUEST = 'CLUSTER_DATA_REQUEST'
export const CLUSTER_DATA_SUCCESS = 'CLUSTER_DATA_SUCCESS'
export const CLUSTER_DATA_FAILURE = 'CLUSTER_DATA_FAILURE'
export const SET_UPLOADED_DATA = 'SET_UPLOADED_DATA'

// SIMILAR PARAS
export const SIMILAR_PARA_REQUEST = 'SIMILAR_PARA_REQUEST'
export const SIMILAR_PARA_SUCCESS = 'SIMILAR_PARA_SUCCESS'
export const SIMILAR_PARA_FAILURE = 'SIMILAR_PARA_FAILURE'
export const SIMILAR_PARA_ID = 'SIMILAR_PARA_ID'
export const SIMILAR_PARA_INIT = 'SIMILAR_PARA_INIT'

// SIMILAR DOCS
export const SIMILAR_DOCS_REQUEST = 'SIMILAR_DOCS_REQUEST'
export const SIMILAR_DOCS_SUCCESS = 'SIMILAR_DOCS_SUCCESS'
export const SIMILAR_DOCS_FAILURE = 'SIMILAR_DOCS_FAILURE'

// PREPARE RESPONSE
export const PREPARE_RESPONSE_REQUEST = 'PREPARE_RESPONSE_REQUEST'
export const PREPARE_RESPONSE_SUCCESS = 'PREPARE_RESPONSE_SUCCESS'
export const PREPARE_RESPONSE_FAILURE = 'PREPARE_RESPONSE_FAILURE'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const REVERT_ACTIVE_STATE = 'REVERT_ACTIVE_STATE'
export const CLEAR_SIMILAR_PARA = 'CLEAR_SIMILAR_PARA'
export const SUBMIT_PREPARE_RESPONSE_REQUEST = 'SUBMIT_PREPARE_RESPONSE_REQUEST'
export const SUBMIT_PREPARE_RESPONSE_SUCCESS = 'SUBMIT_PREPARE_RESPONSE_SUCCESS'
export const SUBMIT_PREPARE_RESPONSE_FAILURE = 'SUBMIT_PREPARE_RESPONSE_FAILURE'

// MAIN SUB TAGS Types
export const MAIN_SUB_TAGS_REQUEST = 'MAIN_SUB_TAGS_REQUEST'
export const MAIN_SUB_TAGS_SUCCESS = 'MAIN_SUB_TAGS_SUCCESS'
export const MAIN_SUB_TAGS_FAILURE = 'MAIN_SUB_TAGS_FAILURE'

// USER DASHBOARD Types
export const USER_DASHBOARD_REQUEST = 'USER_DASHBOARD_REQUEST'
export const USER_DASHBOARD_SUCCESS = 'USER_DASHBOARD_SUCCESS'
export const USER_DASHBOARD_FAILURE = 'USER_DASHBOARD_FAILURE'

// ADVANCE SEARCH Types
export const ADVANCE_SEARCH_REQUEST = 'ADVANCE_SEARCH_REQUEST'
export const ADVANCE_SEARCH_SUCCESS = 'ADVANCE_SEARCH_SUCCESS'
export const ADVANCE_SEARCH_FAILURE = 'ADVANCE_SEARCH_FAILURE'

// RECENT SEARCH Types
export const RECENT_SEARCH_REQUEST = 'RECENT_SEARCH_REQUEST'
export const RECENT_SEARCH_SUCCESS = 'RECENT_SEARCH_SUCCESS'
export const RECENT_SEARCH_FAILURE = 'RECENT_SEARCH_FAILURE'

// LIST DOCUMENTS Types
export const LIST_DOCUMENTS_REQUEST = 'LIST_DOCUMENTS_REQUEST'
export const LIST_DOCUMENTS_SUCCESS = 'LIST_DOCUMENTS_SUCCESS'
export const LIST_DOCUMENTS_FAILURE = 'LIST_DOCUMENTS_FAILURE'

// SAVE fileId
export const SAVE_FILEDATA_REQUEST = 'SAVE_FILEDATA_REQUEST'
export const SAVE_FILEDATA_SUCCESS = 'SAVE_FILEDATA_SUCCESS'

export const CLEAR_FILE_ID = 'CLEAR_FILE_ID'

// FILE JOURNEY Types
export const FILE_JOURNEY_REQUEST = 'FILE_JOURNEY_REQUEST'
export const FILE_JOURNEY_SUCCESS = 'FILE_JOURNEY_SUCCESS'
export const FILE_JOURNEY_FAILURE = 'FILE_JOURNEY_FAILURE'

// ADD FEEDBACK
export const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST'
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS'
export const ADD_FEEDBACK_FAILURE = 'ADD_FEEDBACK_FAILURE'

// FILE READ
export const FILE_READ_REQUEST = 'FILE_READ_REQUEST'
export const FILE_READ_SUCCESS = 'FILE_READ_SUCCESS'
export const FILE_READ_FAILURE = 'FILE_READ_FAILURE'

// REPORT ANALYSIS GRAPH
export const REPORT_ANALYSIS_GRAPH_REQUEST = 'REPORT_ANALYSIS_GRAPH_REQUEST'
export const REPORT_ANALYSIS_GRAPH_SUCCESS = 'REPORT_ANALYSIS_GRAPH_SUCCESS'
export const REPORT_ANALYSIS_GRAPH_FAILURE = 'REPORT_ANALYSIS_GRAPH_FAILURE'

// REPORT ANALYSIS GRAPH
export const ALL_REPORT_ANALYSIS_GRAPH_REQUEST =
  'ALL_REPORT_ANALYSIS_GRAPH_REQUEST'
export const ALL_REPORT_ANALYSIS_GRAPH_SUCCESS =
  'ALL_REPORT_ANALYSIS_GRAPH_SUCCESS'
export const ALL_REPORT_ANALYSIS_GRAPH_FAILURE =
  'ALL_REPORT_ANALYSIS_GRAPH_FAILURE'

//UPDATE TEAM MEMBER
export const UPDATE_TEAM_MEMBER_REQUEST = 'UPDATE_TEAM_MEMBER_REQUEST'
export const UPDATE_TEAM_MEMBER_SUCCESS = 'UPDATE_TEAM_MEMBER_SUCCESS'
export const UPDATE_TEAM_MEMBER_FAILURE = 'UPDATE_TEAM_MEMBER_FAILURE'

//DELETE DOCUMENT
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE'

// SUPPORT
export const REQUEST_ALL_TICKETS = 'REQUEST_ALL_TICKETS'
export const ALL_TICKETS_SUCCESS = 'ALL_TICKETS_SUCCESS'
export const ALL_TICKETS_FAILURE = 'ALL_TICKETS_FAILURE'

export const REQUEST_TICKET_CREATE = 'REQUEST_TICKET_CREATE'
export const TICKET_CREATE_SUCCESS = 'TICKET_CREATE_SUCCESS'
export const TICKET_CREATE_FAILURE = 'TICKET_CREATE_FAILURE'

export const REQUEST_TICKET_VIEW = 'REQUEST_TICKET_VIEW'
export const TICKET_VIEW_SUCCESS = 'TICKET_VIEW_SUCCESS'
export const TICKET_VIEW_FAILURE = 'TICKET_VIEW_FAILURE'

export const REQUEST_TICKET_DELETE = 'REQUEST_TICKET_DELETE'
export const TICKET_DELETE_SUCCESS = 'TICKET_DELETE_SUCCESS'
export const TICKET_DELETE_FAILURE = 'TICKET_DELETE_FAILURE'

export const REQUEST_TICKET_UPDATE = 'REQUEST_TICKET_UPDATE'
export const TICKET_UPDATE_SUCCESS = 'TICKET_UPDATE_SUCCESS'
export const TICKET_UPDATE_FAILURE = 'TICKET_UPDATE_FAILURE'

// ADMIN
export const FETCH_ALL_USERS_REQUEST = 'FETCH_ALL_USERS_REQUEST'
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS'
export const FETCH_ALL_USERS_FAILURE = 'FETCH_ALL_USERS_FAILURE'

// REMOVE USER FROM ADMIN PANEL
export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE'
export const FILTER_DOCUMENTS_REQUEST = 'FILTER_DOCUMENTS_REQUEST'
export const FILTER_DOCUMENTS_SUCCESS = 'FILTER_DOCUMENTS_SUCCESS'
export const FILTER_DOCUMENTS_FAILURE = 'FILTER_DOCUMENTS_FAILURE'

// Data source
export const FETCH_ALL_DATA_SOURCE_REQUEST = 'FETCH_ALL_DATA_SOURCE_REQUEST'
export const FETCH_ALL_DATA_SOURCE_SUCCESS = 'FETCH_ALL_DATA_SOURCE_SUCCESS'
export const FETCH_ALL_DATA_SOURCE_FAILURE = 'FETCH_ALL_DATA_SOURCE_FAILURE'

export const FETCH_ALL_SOURCE_INFO_REQUEST = 'FETCH_ALL_SOURCE_INFO_REQUEST'
export const FETCH_ALL_SOURCE_INFO_SUCCESS = 'FETCH_ALL_SOURCE_INFO_SUCCESS'
export const FETCH_ALL_SOURCE_INFO_FAILURE = 'FETCH_ALL_SOURCE_INFO_FAILURE'

// Catalog Landing Page

export const CATALOG_RECENT_FOLDERS_REQUEST = 'CATALOG_RECENT_FOLDERS_REQUEST'
export const CATALOG_RECENT_FOLDERS_SUCCESS = 'CATALOG_RECENT_FOLDERS_SUCCESS'
export const CATALOG_RECENT_FOLDERS_FAILURE = 'CATALOG_RECENT_FOLDERS_FAILURE'

export const CATALOG_RECENT_DOCUMENTS_REQUEST =
  'CATALOG_RECENT_DOCUMENTS_REQUEST'
export const CATALOG_RECENT_DOCUMENTS_SUCCESS =
  'CATALOG_RECENT_DOCUMENTS_SUCCESS'
export const CATALOG_RECENT_DOCUMENTS_FAILURE =
  'CATALOG_RECENT_DOCUMENTS_FAILURE'

export const CATALOG_ALL_FOLDERS_REQUEST = 'CATALOG_ALL_FOLDERS_REQUEST'
export const CATALOG_ALL_FOLDERS_SUCCESS = 'CATALOG_ALL_FOLDERS_SUCCESS'
export const CATALOG_ALL_FOLDERS_FAILURE = 'CATALOG_ALL_FOLDERS_FAILURE'

export const CATALOG_ALL_SOURCE_REQUEST = 'CATALOG_ALL_SOURCE_REQUEST'
export const CATALOG_ALL_SOURCE_SUCCESS = 'CATALOG_ALL_SOURCE_SUCCESS'
export const CATALOG_ALL_SOURCE_FAILURE = 'CATALOG_ALL_SOURCE_FAILURE'

export const CATALOG_SEARCH_TERM_REQUEST = 'CATALOG_SEARCH_TERM_REQUEST'
export const CATALOG_SEARCH_TERM_SUCCESS = 'CATALOG_SEARCH_TERM_SUCCESS'
export const CATALOG_SEARCH_TERM_FAILURE = 'CATALOG_SEARCH_TERM_FAILURE'

export const CATALOG_ADVANCED_SEARCH_REQUEST = 'CATALOG_ADVANCED_SEARCH_REQUEST'
export const CATALOG_ADVANCED_SEARCH_SUCCESS = 'CATALOG_ADVANCED_SEARCH_SUCCESS'
export const CATALOG_ADVANCED_SEARCH_FAILURE = 'CATALOG_ADVANCED_SEARCH_FAILURE'

export const CATALOG_ADVANCED_SEARCH_FILTERS = 'CATALOG_ADVANCED_SEARCH_FILTERS'

//Search Result Page
export const CATALOG_SEARCH_RESULT_DATA_REQUEST =
  'CATALOG_SEARCH_RESULT_DATA_REQUEST'
export const CATALOG_SEARCH_RESULT_DATA_SUCCESS =
  'CATALOG_SEARCH_RESULT_DATA_SUCCESS'
export const CATALOG_SEARCH_RESULT_DATA_FAILURE =
  'CATALOG_SEARCH_RESULT_DATA_FAILURE'

export const CATALOG_SEARCH_RELATED_FOLDERS_REQUEST =
  'CATALOG_SEARCH_RELATED_FOLDERS_REQUEST'
export const CATALOG_SEARCH_RELATED_FOLDERS_SUCCESS =
  'CATALOG_SEARCH_RELATED_FOLDERS_SUCCESS'
export const CATALOG_SEARCH_RELATED_FOLDERS_FAILURE =
  'CATALOG_SEARCH_RELATED_FOLDERS_FAILURE'

export const CATALOG_SEARCH_FILTERS_DATA = 'CATALOG_SEARCH_FILTERS_DATA'
export const CATALOG_SEARCH_FILTERED_DOCUMENTS =
  'CATALOG_SEARCH_FILTERED_DOCUMENTS'
export const SAVE_CATALOG_SEARCH_TERM = 'SAVE_CATALOG_SEARCH_TERM'
export const CATALOG_SELECTED_SOURCES = 'CATALOG_SELECTED_SOURCES'

// related-searches-page
export const CATALOG_ALL_SEARCH_RESULT_DATA_REQUEST =
  'CATALOG_ALL_SEARCH_RESULT_DATA_REQUEST'
export const CATALOG_ALL_SEARCH_RESULT_DATA_SUCCESS =
  'CATALOG_ALL_SEARCH_RESULT_DATA_SUCCESS'
export const CATALOG_ALL_SEARCH_RESULT_DATA_FAILURE =
  'CATALOG_ALL_SEARCH_RESULT_DATA_FAILURE'

// selected-folder
export const CATALOG_SELECTED_FOLDER_DATA_REQUEST =
  'CATALOG_SELECTED_FOLDER_DATA_REQUEST'
export const CATALOG_SELECTED_FOLDER_DATA_SUCCESS =
  'CATALOG_SELECTED_FOLDER_DATA_SUCCESS'
export const CATALOG_SELECTED_FOLDER_DATA_FAILURE =
  'CATALOG_SELECTED_FOLDER_DATA_FAILURE'

export const CATALOG_SELECTED_FOLDER_TREE_DATA_REQUEST =
  'CATALOG_SELECTED_FOLDER_TREE_DATA_REQUEST'
export const CATALOG_SELECTED_FOLDER_TREE_DATA_SUCCESS =
  'CATALOG_SELECTED_FOLDER_TREE_DATA_SUCCESS'
export const CATALOG_SELECTED_FOLDER_TREE_DATA_FAILURE =
  'CATALOG_SELECTED_FOLDER_TREE_DATA_FAILURE'

export const CATALOG_SELECTED_FILE_TREE_DATA_REQUEST =
  'CATALOG_SELECTED_FILE_TREE_DATA_REQUEST'
export const CATALOG_SELECTED_FILE_TREE_DATA_SUCCESS =
  'CATALOG_SELECTED_FILE_TREE_DATA_SUCCESS'
export const CATALOG_SELECTED_FILE_TREE_DATA_FAILURE =
  'CATALOG_SELECTED_FILE_TREE_DATA_FAILURE'

// files status modal
export const CATALOG_FILES_STATUS_REQUEST = 'CATALOG_FILES_STATUS_REQUEST'
export const CATALOG_FILES_STATUS_SUCCESS = 'CATALOG_FILES_STATUS_SUCCESS'
export const CATALOG_FILES_STATUS_FAILURE = 'CATALOG_FILES_STATUS_FAILURE'

export const FETCH_GRAPH_DATA_REQUEST = 'FETCH_GRAPH_DATA_REQUEST'
export const FETCH_GRAPH_DATA_SUCCESS = 'FETCH_GRAPH_DATA_SUCCESS'
export const FETCH_GRAPH_DATA_FAILURE = 'FETCH_GRAPH_DATA_FAILURE'
// All Folders Tree
export const CATALOG_ALL_FOLDERS_TREE_DATA_REQUEST =
  'CATALOG_ALL_FOLDERS_TREE_DATA_REQUEST'
export const CATALOG_ALL_FOLDERS_TREE_DATA_SUCCESS =
  'CATALOG_ALL_FOLDERS_TREE_DATA_SUCCESS'
export const CATALOG_ALL_FOLDERS_TREE_DATA_FAILURE =
  'CATALOG_ALL_FOLDERS_TREE_DATA_FAILURE'

export const CATALOG_ALL_FOLDERS_CONTENT_REQUEST =
  'CATALOG_ALL_FOLDERS_CONTENT_REQUEST'
export const CATALOG_ALL_FOLDERS_CONTENT_SUCCESS =
  'CATALOG_ALL_FOLDERS_CONTENT_SUCCESS'
export const CATALOG_ALL_FOLDERS_CONTENT_FAILURE =
  'CATALOG_ALL_FOLDERS_CONTENT_FAILURE'

//Workflow Management Landing Page Types

export const WORKFLOW_MANAGEMENT_PROJECT_REQUEST =
  'WORKFLOW_MANAGEMENT_PROJECT_REQUEST'
export const WORKFLOW_MANAGEMENT_PROJECT_SUCCESS =
  'WORKFLOW_MANAGEMENT_PROJECT_SUCCESS'
export const WORKFLOW_MANAGEMENT_PROJECT_FAILURE =
  'WORKFLOW_MANAGEMENT_PROJECT_FAILURE'

export const WORKFLOW_MANAGEMENT_PROJECT_GRAPH_REQUEST =
  'WORKFLOW_MANAGEMENT_PROJECT_GRAPH_REQUEST'
export const WORKFLOW_MANAGEMENT_PROJECT_GRAPH_SUCCESS =
  'WORKFLOW_MANAGEMENT_PROJECT_GRAPH_SUCCESS'
export const WORKFLOW_MANAGEMENT_PROJECT_GRAPH_FAILURE =
  'WORKFLOW_MANAGEMENT_PROJECT_GRAPH_FAILURE'

export const WORKFLOW_MANAGEMENT_PROJECT_DETAILS_REQUEST =
  'WORKFLOW_MANAGEMENT_PROJECT_DETAILS_REQUEST'
export const WORKFLOW_MANAGEMENT_PROJECT_DETAILS_SUCCESS =
  'WORKFLOW_MANAGEMENT_PROJECT_DETAILS_SUCCESS'
export const WORKFLOW_MANAGEMENT_PROJECT_DETAILS_FAILURE =
  'WORKFLOW_MANAGEMENT_PROJECT_DETAILS_FAILURE'

export const WORKFLOW_MANAGEMENT_ADD_MEMBER_REQUEST =
  'WORKFLOW_MANAGEMENT_ADD_MEMBER_REQUEST'
export const WORKFLOW_MANAGEMENT_ADD_MEMBER_SUCCESS =
  'WORKFLOW_MANAGEMENT_ADD_MEMBER_SUCCESS'
export const WORKFLOW_MANAGEMENT_ADD_MEMBER_FAILURE =
  'WORKFLOW_MANAGEMENT_ADD_MEMBER_FAILURE'

export const WORKFLOW_MANAGEMENT_REMOVE_MEMBER_REQUEST =
  'WORKFLOW_MANAGEMENT_REMOVE_MEMBER_REQUEST'
export const WORKFLOW_MANAGEMENT_REMOVE_MEMBER_SUCCESS =
  'WORKFLOW_MANAGEMENT_REMOVE_MEMBER_SUCCESS'
export const WORKFLOW_MANAGEMENT_REMOVE_MEMBER_FAILURE =
  'WORKFLOW_MANAGEMENT_REMOVE_MEMBER_FAILURE'

export const WORKFLOW_MANAGEMENT_DROPDOWN_REQUEST =
  'WORKFLOW_MANAGEMENT_DROPDOWN_REQUEST'
export const WORKFLOW_MANAGEMENT_DROPDOWN_SUCCESS =
  'WORKFLOW_MANAGEMENT_DROPDOWN_SUCCESS'
export const WORKFLOW_MANAGEMENT_DROPDOWN_FAILURE =
  'WORKFLOW_MANAGEMENT_DROPDOWN_FAILURE'

export const WORKFLOW_MANAGEMENT_CREATE_PROJECT_REQUEST =
  'WORKFLOW_MANAGEMENT_CREATE_PROJECT_REQUEST'
export const WORKFLOW_MANAGEMENT_CREATE_PROJECT_SUCCESS =
  'WORKFLOW_MANAGEMENT_CREATE_PROJECT_SUCCESS'
export const WORKFLOW_MANAGEMENT_CREATE_PROJECT_FAILURE =
  'WORKFLOW_MANAGEMENT_CREATE_PROJECT_FAILURE'

export const WORKFLOW_MANAGEMENT_SAVE_FILTERS =
  'WORKFLOW_MANAGEMENT_SAVE_FILTERS'
export const WORKFLOW_MANAGEMENT_FILTERED_DOCUMENTS =
  'WORKFLOW_MANAGEMENT_FILTERED_DOCUMENTS'

export const START_TIMER_REQUEST = 'START_TIMER_REQUEST'
export const START_TIMER_SUCCESS = 'START_TIMER_SUCCESS'
export const START_TIMER_FAILURE = 'START_TIMER_FAILURE'
export const NOTIFY_TIMER_SERVER = 'NOTIFY_TIMER_SERVER'
export const STOP_TIMER_REQUEST = 'STOP_TIMER_REQUEST'
export const RESTART_TIMER_REQUEST = 'RESTART_TIMER_REQUEST'

export const DOCUMENT_ANALYTICS_HISTORY_GET_REQUEST =
  'DOCUMENT_ANALYTICS_HISTORY_GET_REQUEST'
export const DOCUMENT_ANALYTICS_HISTORY_GET_SUCCESS =
  'DOCUMENT_ANALYTICS_HISTORY_GET_SUCCESS'
export const DOCUMENT_ANALYTICS_HISTORY_GET_FAILURE =
  'DOCUMENT_ANALYTICS_HISTORY_GET_FAILURE'

export const DOCUMENT_ANALYTICS_HISTORY_CREATE_REQUEST =
  'DOCUMENT_ANALYTICS_HISTORY_CREATE_REQUEST'
export const DOCUMENT_ANALYTICS_HISTORY_CREATE_SUCCESS =
  'DOCUMENT_ANALYTICS_HISTORY_CREATE_SUCCESS'
export const DOCUMENT_ANALYTICS_HISTORY_CREATE_FAILURE =
  'DOCUMENT_ANALYTICS_HISTORY_CREATE_FAILURE'
export const UPDATE_PATHNAME = 'UPDATE_PATHNAME'
export const INCREMENT_TIMER = 'INCREMENT_TIMER'
export const RESET_TIMER = 'RESET_TIMER'
export const STOP_TIMER = 'STOP_TIMER'

// Navbar Types

export const QUICK_SUMMARY_REQUEST = 'QUICK_SUMMARY_REQUEST'
export const QUICK_SUMMARY_SUCCESS = 'QUICK_SUMMARY_SUCCESS'
export const QUICK_SUMMARY_FAILURE = 'QUICK_SUMMARY_FAILURE'

// File Clustering Types
export const FILE_CLUSTER_DATA_REQUEST = 'FILE_CLUSTER_DATA_REQUEST'
export const FILE_CLUSTER_DATA_SUCCESS = 'FILE_CLUSTER_DATA_SUCCESS'
export const FILE_CLUSTER_DATA_FAILURE = 'FILE_CLUSTER_DATA_FAILURE'

// Dashboard Types
export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST'
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS'
export const DASHBOARD_DATA_FAILURE = 'DASHBOARD_DATA_FAILURE'
export const DASHBOARD_FILE_UPLOAD_REQUEST = 'DASHBOARD_FILE_UPLOAD_REQUEST'
export const DASHBOARD_FILE_UPLOAD_SUCCESS = 'DASHBOARD_FILE_UPLOAD_SUCCESS'
export const DASHBOARD_FILE_UPLOAD_FAILURE = 'DASHBOARD_FILE_UPLOAD_FAILURE'

// Upload Document Modal Types
export const UPLOAD_DOCUMENT_FILE_CLUSTER_REQUEST =
  'UPLOAD_DOCUMENT_FILE_CLUSTER_REQUEST'
export const UPLOAD_DOCUMENT_FILE_CLUSTER_SUCCESS =
  'UPLOAD_DOCUMENT_FILE_CLUSTER_SUCCESS'
export const UPLOAD_DOCUMENT_FILE_CLUSTER_FAILURE =
  'UPLOAD_DOCUMENT_FILE_CLUSTER_FAILURE'
export const UPLOAD_DOCUMENT_CLUSTERED_DATA_REQUEST =
  'UPLOAD_DOCUMENT_CLUSTERED_DATA_REQUEST'
export const UPLOAD_DOCUMENT_CLUSTERED_DATA_SUCCESS =
  'UPLOAD_DOCUMENT_CLUSTERED_DATA_SUCCESS'
export const UPLOAD_DOCUMENT_CLUSTERED_DATA_FAILURE =
  'UPLOAD_DOCUMENT_CLUSTERED_DATA_FAILURE'

// Private Folder
export const PRIVATE_FOLDER_ADD_FILE_REQUEST = 'PRIVATE_FOLDER_ADD_FILE_REQUEST'
export const PRIVATE_FOLDER_ADD_FILE_SUCCESS = 'PRIVATE_FOLDER_ADD_FILE_SUCCESS'
export const PRIVATE_FOLDER_ADD_FILE_FAILURE = 'PRIVATE_FOLDER_ADD_FILE_FAILURE'
export const PRIVATE_FOLDER_ADD_FOLDER_REQUEST =
  'PRIVATE_FOLDER_ADD_FOLDER_REQUEST'
export const PRIVATE_FOLDER_ADD_FOLDER_SUCCESS =
  'PRIVATE_FOLDER_ADD_FOLDER_SUCCESS'
export const PRIVATE_FOLDER_ADD_FOLDER_FAILURE =
  'PRIVATE_FOLDER_ADD_FOLDER_FAILURE'
export const PRIVATE_FOLDER_FOLDER_LIST_REQUEST =
  'PRIVATE_FOLDER_FOLDER_LIST_REQUEST'
export const PRIVATE_FOLDER_FOLDER_LIST_SUCCESS =
  'PRIVATE_FOLDER_FOLDER_LIST_SUCCESS'
export const PRIVATE_FOLDER_FOLDER_LIST_FAILURE =
  'PRIVATE_FOLDER_FOLDER_LIST_FAILURE'

export const FILE_CLUSTER_REQUEST = 'FILE_CLUSTER_REQUEST'
export const FILE_CLUSTER_SUCCESS = 'FILE_CLUSTER_SUCCESS'
export const FILE_CLUSTER_FAILURE = 'FILE_CLUSTER_FAILURE'
export const CLUSTERED_DATA_REQUEST = 'CLUSTERED_DATA_REQUEST'
export const CLUSTERED_DATA_SUCCESS = 'CLUSTERED_DATA_SUCCESS'
export const CLUSTERED_DATA_FAILURE = 'CLUSTERED_DATA_FAILURE'

// file response comment
export const CREATE_FILE_COMMENT_REQUEST = 'CREATE_FILE_COMMENT_REQUEST'
export const CREATE_FILE_COMMENT_SUCCESS = 'CREATE_FILE_COMMENT_SUCCESS'
export const CREATE_FILE_COMMENT_FAILURE = 'CREATE_FILE_COMMENT_FAILURE'

export const GET_FILE_COMMENT_REQUEST = 'GET_FILE_COMMENT_REQUEST'
export const GET_FILE_COMMENT_SUCCESS = 'GET_FILE_COMMENT_SUCCESS'
export const GET_FILE_COMMENT_FAILURE = 'GET_FILE_COMMENT_FAILURE'

export const DELETE_FILE_COMMENT_REQUEST = 'DELETE_FILE_COMMENT_REQUEST'
export const DELETE_FILE_COMMENT_SUCCESS = 'DELETE_FILE_COMMENT_SUCCESS'
export const DELETE_FILE_COMMENT_FAILURE = 'DELETE_FILE_COMMENT_FAILURE'

export const UPDATE_FILE_COMMENT_REQUEST = 'UPDATE_FILE_COMMENT_REQUEST'
export const UPDATE_FILE_COMMENT_SUCCESS = 'UPDATE_FILE_COMMENT_SUCCESS'
export const UPDATE_FILE_COMMENT_FAILURE = 'UPDATE_FILE_COMMENT_FAILURE'

// Para Feedback Add

export const PARA_FEEDBACK_ADD_REQUEST = 'PARA_FEEDBACK_ADD_REQUEST'
export const PARA_FEEDBACK_ADD_SUCCESS = 'PARA_FEEDBACK_ADD_SUCCESS'
export const PARA_FEEDBACK_ADD_FAILURE = 'PARA_FEEDBACK_ADD_FAILURE'

export const ALL_MAIN_SUB_TAGS_REQUEST = 'ALL_MAIN_SUB_TAGS_REQUEST'
export const ALL_MAIN_SUB_TAGS_SUCCESS = 'ALL_MAIN_SUB_TAGS_SUCCESS'
export const ALL_MAIN_SUB_TAGS_FAILURE = 'ALL_MAIN_SUB_TAGS_FAILURE'

export const SIMILAR_PARA_DATA_REQUEST = 'SIMILAR_PARA_DATA_REQUEST'
export const SIMILAR_PARA_DATA_SUCCESS = 'SIMILAR_PARA_DATA_SUCCESS'
export const SIMILAR_PARA_DATA_FAILURE = 'SIMILAR_PARA_DATA_FAILURE'

export const SIMILAR_DOC_DATA_REQUEST = 'SIMILAR_DOC_DATA_REQUEST'
export const SIMILAR_DOC_DATA_SUCCESS = 'SIMILAR_DOC_DATA_SUCCESS'
export const SIMILAR_DOC_DATA_FAILURE = 'SIMILAR_DOC_DATA_FAILURE'

// file cluster data for similar docs
export const SIMILAR_DOC_CLUSTER_DATA_REQUEST =
  'SIMILAR_DOC_CLUSTER_DATA_REQUEST'
export const SIMILAR_DOC_CLUSTER_DATA_SUCCESS =
  'SIMILAR_DOC_CLUSTER_DATA_SUCCESS'
export const SIMILAR_DOC_CLUSTER_DATA_FAILURE =
  'SIMILAR_DOC_CLUSTER_DATA_FAILURE'

// get Data Catalog second graph data
export const TAGS_GRAPH_DATA_REQUEST = 'TAGS_GRAPH_DATA_REQUEST'
export const TAGS_GRAPH_DATA_SUCCESS = 'TAGS_GRAPH_DATA_SUCCESS'
export const TAGS_GRAPH_DATA_FAILURE = 'TAGS_GRAPH_DATA_FAILURE'

// Update file status
export const UPDATE_FILE_STATUS_REQUEST = 'UPDATE_FILE_STATUS_REQUEST'
export const UPDATE_FILE_STATUS_SUCCESS = 'UPDATE_FILE_STATUS_SUCCESS'
export const UPDATE_FILE_STATUS_FAILURE = 'UPDATE_FILE_STATUS_FAILURE'

//  Sidebar
export const NEW_DOCUMENT_SAVE_REQUEST = 'NEW_DOCUMENT_SAVE_REQUEST'

//  Private Document
export const CREATE_NEW_DOCUMENT_REQUEST = 'CREATE_NEW_DOCUMENT_REQUEST'
export const CREATE_NEW_DOCUMENT_SUCCESS = 'CREATE_NEW_DOCUMENT_SUCCESS'
export const CREATE_NEW_DOCUMENT_FAILURE = 'CREATE_NEW_DOCUMENT_FAILURE'

export const IS_CREATE_NEW_DOCUMENT_DISPLAYED = 'IS_CREATE_NEW_DOCUMENT_DISPLAYED'


export const FETCH_ALL_PRIVATE_DOCUMENT_REQUEST =
  'FETCH_ALL_PRIVATE_DOCUMENT_REQUEST'
export const FETCH_ALL_PRIVATE_DOCUMENT_SUCCESS =
  'FETCH_ALL_PRIVATE_DOCUMENT_SUCCESS'
export const FETCH_ALL_PRIVATE_DOCUMENT_FAILURE =
  'FETCH_ALL_PRIVATE_DOCUMENT_FAILURE'
// file tracking
export const FILE_TRACK_REQUEST = 'FILE_TRACK_REQUEST'
export const FILE_TRACK_SUCCESS = 'FILE_TRACK_SUCCESS'
export const FILE_TRACK_FAILURE = 'FILE_TRACK_FAILURE'

// file link
export const FILE_LINK_REQUEST = 'FILE_LINK_REQUEST'
export const FILE_LINK_SUCCESS = 'FILE_LINK_SUCCESS'
export const FILE_LINK_FAILURE = 'FILE_LINK_FAILURE'
