import React, { Component } from "react";
import  NavigationBar from '../../shared/navigation-bar/NavigationBar'
import RenderUploadedDoc from '../../components/uploaded-doc/render-uploaded-doc/RenderUploadedDoc'
import styles from "./UploadedDoc.module.css";
import { connect } from 'react-redux';
// import commonStyles from '../../shared/Common.module.css';
import UploadedDocPreview from '../../components/uploaded-doc/uploaded-doc-preview/UploadedDocPreview'



class UploadedDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }
   
    render() {
        return (
            <div >
                <NavigationBar loading={this.props.isLoading}></NavigationBar>

                <div className={styles.uploaded_doc_content_wrapper}>
                    <div className={styles.uploaded_doc_section}>
                        <RenderUploadedDoc history={this.props.history}></RenderUploadedDoc>
                    </div>
                    <div className={styles.doc_preview_section}>
                        <UploadedDocPreview history={this.props.history}></UploadedDocPreview>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    
      isLoading: state.uploadedDoc.isLoading
    };
  }
  export default connect(mapStateToProps, null)(UploadedDoc);