import * as types from '../types';

// Update Profile Actions
export const updateProfileRequest = (payload) => ({ type: types.UPDATE_PROFILE_REQUEST, payload });
export const updateProfileSuccess = (payload) => ({ type: types.UPDATE_PROFILE_SUCCESS, payload });
export const updateProfileFailure = (payload) => ({ type: types.UPDATE_PROFILE_FAILURE, payload });

// Change Password Actions
export const changePasswordRequest = (payload) => ({ type: types.CHANGE_PASSWORD_REQUEST, payload });
export const changePasswordSuccess = (payload) => ({ type: types.CHANGE_PASSWORD_SUCCESS, payload });
export const changePasswordFailure = (payload) => ({ type: types.CHANGE_PASSWORD_FAILURE, payload });

// clear accountSettings error
export const clearaccountSettingsResponseMessages = () => ({ type: types.CLEAR_ACCOUNT_SETTINGS_RESPONSE_MESSAGES});
