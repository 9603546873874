import React from 'react';
import styles from './DocumentAnalytics.module.css';
import { IconContext } from 'react-icons';
import { IoIosArrowDown } from 'react-icons/io';
import CheckIcon from '../../../assets/check_icon_grey.svg';
import selected_three_dot_icon from '../../../assets/selected_three_dot_icon.svg';
import three_dot_icon from '../../../assets/three_dot_icon.svg';
import { connect } from 'react-redux';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';

class AddComment extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.state = {
      selectedCommentId: '',
      isMenuOpen: false,
      isEditing: '',
    };
  }

  handleMenuClick = (id) => {
    this.setState({ selectedCommentId: id });
  };

  handleShowMenu = () => {
    this.setState({ selectedCommentId: '' });
  };

  handleCommentEditing = (id) => {
    this.setState({ isEditing: id, selectedCommentId: '' });
  };

  handleSubmitUpdate = (id) => {
    this.setState({ isEditing: '' });
    this.props.submitUpdateComment(id);
  };

  handleCancelComment = () => {
    this.setState({ selectedCommentId: '' });
    this.props.handleCommentCancel();
  };

  render() {
    const {
      data,
      isCommenting,
      commentText,
      handleCommentChange,
      submitComment,
      handleCommentDelete,
      handleUpdateCommentChange,
      commentUpdateText,
      handleScrollBar,
    } = this.props;

    const { selectedCommentId, isEditing } = this.state;
    let time = moment(new Date()).fromNow();

    return (
      <div className={styles.right_container_container}>
        <span
          className={styles.right_container_header}
          // onClick={handleLastEdit}
        >
          <span>COMMENTS</span>
          <IconContext.Provider
            value={{
              size: 21,
              color: '#7A7A7A',
            }}
          >
            <IoIosArrowDown />
          </IconContext.Provider>
        </span>
        <div
          className={styles.all_file_comments_container}
          ref={this.ref}
          onScroll={() =>
            handleScrollBar(this.ref, 'document_analytics_comment_container')
          }
        >
          {isCommenting ? (
            <div className={styles.single_comment_container}>
              <div className={styles.comment_left_number_container}>
                <div>{data.length + 1}</div>
              </div>

              <div className={styles.comment_right_content_container}>
                <div className={styles.comment_card_heading_container}>
                  <div className={styles.comment_card_heading_wrapper}>
                    <p className={styles.comment_card_head_heading}>
                      {this.props.userName}
                    </p>
                    <p className={styles.comment_card_head_time}>{time}</p>
                  </div>
                  {selectedCommentId !== 'new' ? (
                    <img
                      src={three_dot_icon}
                      alt="check"
                      onClick={() => this.handleMenuClick('new')}
                      className={styles.comment_menu}
                    />
                  ) : (
                    <OutsideClickHandler onOutsideClick={this.handleShowMenu}>
                      <img
                        src={selected_three_dot_icon}
                        alt="check"
                        onClick={this.handleShowMenu}
                        className={styles.selected_comment_menu}
                      />
                      <div className={styles.file_comment_action_popup_discard}>
                        <div
                          className={styles.last_edit_action_popup_tag}
                          onClick={() => this.handleCancelComment()}
                        >
                          <span className={styles.last_edit_action_popup_text}>
                            Discard Comment
                          </span>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
                <div className={styles.comment_text}>
                  <input
                    placeholder="Add Comment"
                    value={commentText}
                    onChange={(e) => handleCommentChange(e)}
                    onKeyPress={(e) =>
                      e.key === 'Enter' ? submitComment() : null
                    }
                    className={styles.new_message_input_field}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {/* <div className={styles.new_comment_container}>
              <textarea
                className={styles.new_comment_input}
                value={commentText}
                onChange={(e) => handleCommentChange(e)}
              />
              <div
                className={styles.new_comment_send_btn}
                onClick={() => submitComment()}
              >
                SEND
              </div>
              <div
                className={styles.new_comment_cancel_btn}
                onClick={() => handleCommentCancel()}
              >
                CANCEL
              </div>
            </div> */}

          {data &&
            data.map((comment) => (
              <div className={styles.single_comment_container}>
                <div className={styles.comment_left_number_container}>
                  <div>{comment.commentCount + 1}</div>
                </div>

                <div className={styles.comment_right_content_container}>
                  <div className={styles.comment_card_heading_container}>
                    <div className={styles.comment_card_heading_wrapper}>
                      <p className={styles.comment_card_head_heading}>
                        {comment.commentedBy}
                      </p>
                      <p className={styles.comment_card_head_time}>
                        {moment(comment.createdAt).fromNow()}
                      </p>
                    </div>
                    {selectedCommentId !== comment.id ? (
                      <img
                        src={three_dot_icon}
                        alt="check"
                        onClick={() => this.handleMenuClick(comment.id)}
                        className={styles.comment_menu}
                      />
                    ) : (
                      <OutsideClickHandler onOutsideClick={this.handleShowMenu}>
                        <img
                          src={selected_three_dot_icon}
                          alt="check"
                          onClick={this.handleShowMenu}
                          className={styles.selected_comment_menu}
                        />
                        <div className={styles.file_comment_action_popup}>
                          <div
                            className={styles.last_edit_action_popup_tag}
                            onClick={() =>
                              this.handleCommentEditing(comment.id)
                            }
                          >
                            <span
                              className={styles.last_edit_action_popup_text}
                            >
                              Edit
                            </span>
                          </div>
                          <div
                            className={styles.last_edit_action_popup_tag}
                            onClick={() => handleCommentDelete(comment.id)}
                          >
                            <span
                              className={styles.last_edit_action_popup_text}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </OutsideClickHandler>
                    )}
                  </div>
                  <div className={styles.comment_text}>{comment.comment}</div>
                  <div className={styles.comment_text}>
                    {isEditing === comment.id ? (
                      <input
                        placeholder="Add Comment"
                        value={commentUpdateText}
                        onChange={(e) => handleUpdateCommentChange(e)}
                        onKeyPress={(e) =>
                          e.key === 'Enter'
                            ? this.handleSubmitUpdate(comment.id)
                            : null
                        }
                        className={styles.new_message_input_field}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userName: state.homepage.userDetails.name,
  };
};

export default connect(mapStateToProps, null)(AddComment);
