import React from 'react';
import 'antd/dist/antd.css';
import { Tree } from 'antd';
import styles from './AddSourceModal.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';
import commonStyles from '../../../shared/Common.module.css';
import SearchBlackIcon from './../../../assets/search_black.svg';
import ArrowLeftIcon from './../../../assets/arrow_left_minimal.svg';
import ArrowUpIcon from './../../../assets/arrow_up.svg';
import FolderGreyIcon from './../../../assets/grey_folder.svg';
import DocumentBlueIcon from './../../../assets/blue_document.svg';
import grey_bg_folder from '../../../assets/grey_bg_folder.svg';
import white_bg_folder from '../../../assets/white_bg_folder.svg';

const TreeFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={grey_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
);

const SelectedFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={white_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
);

class AddSourceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewSelectionOnly: false,
      filteredFilesFoldersInfo: this.props.filesFoldersInfo,
      alreadyFetched: ['Root'],
      folderChildren: [],
      sortedFolderChildren: this.props.selectedData,
      isNameSorting: false,
      folderPath: {},
      folderId: '',
      defaultExpended: [],
      isEdit: false,
      currentSelectedFolder: 'Root',
    };
    this.sourceMap = {
      gdrive: 'Google Drive',
      dropbox: 'Dropbox',
      onedrive: 'Onedrive',
    };
  }

  componentDidMount() {
    this.setState({
      viewSelectionOnly: false,
      filteredFilesFoldersInfo: this.props.filesFoldersInfo,
      alreadyFetched: ['Root'],
      folderChildren: [],
      sortedFolderChildren: this.props.selectedData,
      isNameSorting: false,
      folderPath: {},
      // defaultExpended: ['Root'],
      isEdit: false,
    });
  }

  componentWillReceiveProps(props) {
    const { selectedData } = this.props;
    // console.log(props.selectedData);
    if (props.selectedData !== selectedData) {
      this.setState({
        sortedFolderChildren: props.selectedData,
        folderChildren: props.selectedData,
      });
    }
  }

  handleSelectionOnly = (event) => {
    this.setState({ viewSelectionOnly: event.target.checked });
  };

  onSelect = (keys, event) => {
    // console.log(event);
    if (this.state.currentSelectedFolder !== event.node.title) {
      this.props.handleAnotherFolderSelection();
    }
    this.setState({ currentSelectedFolder: event.node.title });
    if (event.node.children.length > 0) {
      this.setState({
        folderChildren: event.node.children,
        sortedFolderChildren: event.node.children,
      });
    }
  };

  onExpand = (keys, event) => {
    if (
      this.state.defaultExpended.length > 0 &&
      this.state.defaultExpended.includes(event.node.key)
    ) {
      let temp = [...this.state.defaultExpended];
      let filtered = temp.filter((item) => item !== event.node.key);
      this.setState({ defaultExpended: filtered });
    } else {
      this.setState({
        defaultExpended: [...this.state.defaultExpended, event.node.key],
      });
    }
    if (
      !this.state.alreadyFetched.includes(event.node.key) &&
      event.node.type === 'folder'
    ) {
      if (this.props.sourceName === 'gdrive') {
        this.props.getGDriveData(event.node.key);
        this.setState({
          alreadyFetched: [...this.state.alreadyFetched, event.node.key],
        });
      } else if (this.props.sourceName === 'dropbox') {
        this.props.getDropboxDataList(event.node.key);
        this.setState({
          alreadyFetched: [...this.state.alreadyFetched, event.node.key],
        });
      } else if (this.props.sourceName === 'onedrive') {
        this.props.getOneDriveData(event.node.key);
        this.setState({
          alreadyFetched: [...this.state.alreadyFetched, event.node.key],
        });
      } else {
        return;
      }
    }
  };

  handleFolderSearch = (event) => {
    let inputValue = event.target.value;
    const filterData = this.props.filesFoldersInfo[0].children.filter((obj) =>
      obj.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    this.setState({
      filteredFilesFoldersInfo: [
        {
          title: 'Root',
          key: 'Root',
          children: filterData,
          icon: ({ selected }) =>
            selected ? <SelectedFolderIcon /> : <TreeFolderIcon />,
        },
      ],
    });
  };

  handleNameSort = (event) => {
    let checked = event.target.checked;
    this.setState({ isNameSorting: checked });
    if (checked) {
      let arrayToSort = [...this.state.sortedFolderChildren];
      arrayToSort.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      this.setState({ sortedFolderChildren: arrayToSort });
    } else {
      this.setState({ sortedFolderChildren: this.state.folderChildren });
    }
  };

  cancelAll = () => {
    this.props.handleModalCancel();
    this.setState({
      filteredFilesFoldersInfo: [],
      alreadyFetched: ['Root'],
      folderChildren: [],
      sortedFolderChildren: [],
    });
  };

  handleEditClick = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  handleFolderNameChange = (event, folderId) => {
    this.setState({
      folderPath: { ...this.state.folderPath, [folderId]: event.target.value },
    });
  };

  render() {
    const { DirectoryTree } = Tree;
    const {
      submitSelectedFolder,
      handleAllSelect,
      handleCheckbox,
      selectedFolders,
      isNewDataLoading,
      sourceName,
      isAllSelect,
    } = this.props;
    const {
      viewSelectionOnly,
      filteredFilesFoldersInfo,
      alreadyFetched,
      isNameSorting,
      sortedFolderChildren,
      folderPath,
      defaultExpended,
      isEdit,
      folderId,
      currentSelectedFolder,
    } = this.state;
    return (
      <CommonModal
        modalClass={'source_file_folder_modal'}
        backdropClassName={'data_source_model_blue_bg'}
        closeHandler={this.cancelAll}
        header={
          <div className={`${styles.folder_modal_header_container}`}>
            <h4 className={`${styles.modal_header_heading}`}>
              {this.sourceMap[sourceName]}
            </h4>
            <div className={styles.header_search}>
              <input
                placeholder="Search"
                type="text"
                className={styles.search_box}
                onChange={(e) => this.handleFolderSearch(e)}
              ></input>
              <div className={styles.search_icon}>
                <img
                  className={styles.icon}
                  src={SearchBlackIcon}
                  alt="Search Icon"
                />
              </div>
            </div>
          </div>
        }
      >
        <div className={styles.all_source_container}>
          <hr className={`${styles.horizontal_line}`} />
          <div className={styles.folder_view_modal_section_container}>
            <div className={styles.folder_modal_section_wrapper}>
              <div className={styles.progress_modal_section_heading_container}>
                <p className={styles.progress_modal_section_heading_left}>
                  Index
                </p>
                <img src={ArrowLeftIcon} alt="left arrow" />
              </div>
              <hr />
              <div className={`${styles.folder_view_right_container}`}>
                {isNewDataLoading ? (
                  <p>Loading...</p>
                ) : (
                  <DirectoryTree
                    defaultExpandedKeys={defaultExpended}
                    defaultSelectedKeys={[
                      alreadyFetched[alreadyFetched.length - 1],
                    ]}
                    onSelect={this.onSelect}
                    onExpand={this.onExpand}
                    treeData={filteredFilesFoldersInfo}
                  />
                )}
              </div>
            </div>

            <div className={styles.folder_modal_section_wrapper}>
              <div className={styles.source_modal_section_heading_container}>
                {isEdit ? (
                  <input
                    placeholder="Please give your folder a name"
                    className={styles.edit_name_search_box}
                    value={folderPath[folderId]}
                    onChange={(e) => this.handleFolderNameChange(e, folderId)}
                  />
                ) : (
                  <p className={styles.progress_modal_section_heading_left}>
                    {folderPath[folderId]
                      ? folderPath[folderId]
                      : 'Please give your folder a name'}
                  </p>
                )}
                <i
                  className={`fas fa-pen ${styles.edit_name_icon}`}
                  onClick={this.handleEditClick}
                ></i>
              </div>
              <hr />

              <div className={`${styles.folder_view_right_container}`}>
                <div className={styles.files_section_heading_container}>
                  <div className={styles.round}>
                    <input
                      type="checkbox"
                      id={`checkbox-name-sort`}
                      checked={isAllSelect}
                      onChange={(event) => {
                        if (event.target.checked) {
                          let obj = {};
                          sortedFolderChildren.forEach((item) => {
                            if (item.type === 'folder') {
                              obj[item.id] = item.name;
                            } else {
                              obj[item.id] = currentSelectedFolder;
                            }
                          });
                          this.setState({ folderPath: obj });
                        } else {
                          this.setState({ folderPath: {} });
                        }
                        return handleAllSelect(event, sortedFolderChildren);
                      }}
                    />
                    <label htmlFor={`checkbox-name-sort`}></label>
                  </div>
                  <p className={styles.folder_view_heading}>Name</p>
                  <img
                    src={ArrowUpIcon}
                    alt="grid icon"
                    onClick={() =>
                      this.handleNameSort({
                        target: { checked: !isNameSorting },
                      })
                    }
                    className={styles.sort_name_arrow}
                  />
                </div>
                <hr />

                {viewSelectionOnly
                  ? selectedFolders.map((item, i) => (
                      <div key={i}>
                        <div className={styles.files_section_heading_container}>
                          <div className={styles.round}>
                            <input
                              type="checkbox"
                              id={`checkbox-${i}-custom`}
                              checked={true}
                              onChange={(event) =>
                                handleCheckbox(event, {
                                  type: item.type,
                                  value: item.value,
                                  mimeType: item.mimeType,
                                  name: item.name,
                                })
                              }
                            />
                            <label htmlFor={`checkbox-${i}-custom`}></label>
                          </div>
                          <img
                            src={
                              item.type === 'folder'
                                ? FolderGreyIcon
                                : DocumentBlueIcon
                            }
                            alt="icon"
                            className={`${styles.folder_view_right_icon}`}
                          />
                          <p className={styles.folder_view_right_name}>
                            {item.name}
                          </p>
                        </div>
                        <hr />
                      </div>
                    ))
                  : sortedFolderChildren.map((item, i) => (
                      <div key={i}>
                        <div className={styles.files_section_heading_container}>
                          <div className={styles.round}>
                            <input
                              type="checkbox"
                              id={`checkbox-${i}-custom`}
                              checked={selectedFolders.find(
                                (obj) => obj.value === item.id
                              )}
                              onChange={(event) => {
                                let id = item.id;
                                let temp = folderPath[id] ? folderPath[id] : id;
                                let obj = {
                                  [temp]:
                                    item.type === 'folder'
                                      ? item.name
                                      : currentSelectedFolder,
                                };
                                if (event.target.checked) {
                                  this.setState({
                                    folderPath: { ...folderPath, ...obj },
                                    folderId: id,
                                  });
                                } else {
                                  let obj = {};
                                  Object.keys(folderPath).forEach((key) => {
                                    if (key !== item.id) {
                                      obj[key] = folderPath[key];
                                    }
                                  });
                                  this.setState({ folderPath: obj });
                                }
                                return handleCheckbox(event, {
                                  type: item.type,
                                  value: item.id,
                                  mimeType: item.mimeType,
                                  name: item.name,
                                });
                              }}
                            />
                            <label htmlFor={`checkbox-${i}-custom`}></label>
                          </div>
                          <img
                            src={
                              item.type === 'folder'
                                ? FolderGreyIcon
                                : DocumentBlueIcon
                            }
                            alt="icon"
                            className={`${
                              item.type === 'folder'
                                ? styles.folder_view_right_icon
                                : styles.folder_view_right_icon_file
                            }`}
                          />
                          <p className={styles.folder_view_right_name}>
                            {item.name}
                          </p>
                        </div>
                        <hr />
                      </div>
                    ))}
              </div>
            </div>
          </div>

          <div className={`${styles.folder_view_modal_bottom_container}`}>
            <div className={`${styles.toggle_btn_container}`}>
              <label class={styles.switch}>
                <input
                  type="checkbox"
                  checked={viewSelectionOnly}
                  onChange={(e) => this.handleSelectionOnly(e)}
                />
                <span class={`${styles.slider} ${styles.round}`}></span>
              </label>
              <p className={`${styles.bottom_text_selection_only}`}>
                VIEW SELECTION
              </p>
            </div>
            <p className={`${styles.bottom_text}`}>
              {selectedFolders.length} Files Selected
            </p>
            <div>
              <button
                onClick={() =>
                  handleAllSelect(
                    { target: { checked: false } },
                    sortedFolderChildren
                  )
                }
                className={`${styles.folder_view_cancel_all_btn} btn btn-primary-outline ${commonStyles.btn_ripple}`}
              >
                CLEAR ALL
              </button>
              <button
                onClick={() => submitSelectedFolder(folderPath)}
                disabled={selectedFolders.length < 1}
                className={`${styles.cancel_all_btn} btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
              >
                DONE
              </button>
            </div>
          </div>
        </div>
      </CommonModal>
    );
  }
}

export default AddSourceModal;
