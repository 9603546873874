import React, { Component } from "react";
import styles from "./AllDocumentsList.module.css";
// import commonStyles from '../../../shared/Common.module.css';
import DeleteDocument from "../delete-document/DeleteDocument";
import DisplayDocument from '../../../shared/display-document/DisplayDocument'
import { filter } from "lodash";

class AllDocumentsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAction: null,
            documentsData: [],
            filteredDocumentsData: [],
            isAscending: 1,
            fieldToSort: '',
            isDeleteMemberModalOpen:false,
            documentToDelete:"",
            showFile:false,
            showFileUrl:'',
            fileNameToShow:'',
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchItem !== this.props.searchItem) {
            this.getFilteredDocuments()
        }
    }
    componentDidMount() {
        // dont change this condition otherwise on applying filter, count will be updated but not the documents
        if(this.props.documents){
        this.setState({ membersData: [...this.props.documents] }, () => {
            this.getFilteredDocuments()
        })

    }
}


    getFilteredDocuments = () => {
        let searchItems = this.props.searchItem
        let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        let filteredDocuments = this.props.documents?.filter(document => {
            return document.orgFileName.toLowerCase().search(
                subStr.toLowerCase()
            ) !== -1;
        });
        this.setState({ filteredDocumentsData: filteredDocuments })
    }
    openActionPopup = (fileId) => {
        this.setState({ selectedAction: fileId })
    }
    closeActionPopup = () => {
        if (this.state.selectedAction) {
            this.setState({ selectedAction: null })
        }
    }
    handleDelete = (fileId) => {
        this.setState({isDeleteMemberModalOpen:true,
            documentToDelete:fileId
        })
    }
    onDeleteDocumentClose = () => {
        this.setState({isDeleteMemberModalOpen:false})
    }

    onDeleteDocument = () =>{
        const paramsObj = {
            fileId:this.state.documentToDelete
        }
        this.props.onDeleteDocument(paramsObj)
    }
    handleShowFile = (showFileUrl,docName ) =>{
        this.setState({showFile:true,showFileUrl, fileNameToShow:docName })
    }
    handleCloseFile = (showFileUrl ) =>{
        this.setState({showFile:false,showFileUrl:''})
    }
    sortDocuments = (orderBy) => {
        const filteredDocumentsData = this.state.filteredDocumentsData;
        filteredDocumentsData.sort((a, b) => (a[orderBy] > b[orderBy]) ? this.state.isAscending : this.state.isAscending * -1)
        this.setState({
            filteredDocumentsData,
            isAscending: (this.state.isAscending * -1),
            fieldToSort: orderBy
        })
    }
    render() {
        const { filteredDocumentsData, fieldToSort, isAscending, isDeleteMemberModalOpen,documentToDelete, showFile, showFileUrl, fileNameToShow } = this.state
        return (
            <div onClick={() => this.closeActionPopup()} className={'thin_custom_scrollbar'}>
                <div className={styles.all_documents_list_container}>
                    <table className={styles.all_documents_list_table}>
                        <thead>
                            <tr className={styles.all_documents_list_row_label}>
                                <th> <label onClick={() => this.sortDocuments("orgFileName")}>DOCUMENT NAME</label>
                                    {fieldToSort === "orgFileName" && isAscending === -1 && <i class="fas fa-angle-up"></i>}
                                    {fieldToSort === "orgFileName" && isAscending === 1 && <i class="fas fa-angle-down"></i>}
                                </th>
                                <th ><label onClick={() => this.sortDocuments("fileStatus")}>STATUS</label>
                                    {fieldToSort === "fileStatus" && isAscending === -1 && <i class="fas fa-angle-up"></i>}
                                    {fieldToSort === "fileStatus" && isAscending === 1 && <i class="fas fa-angle-down"></i>}
                                </th>
                                <th><label>ASSIGNED TO</label></th>
                                <th><label onClick={() => this.sortDocuments("uploadedBy")}>UPLOADED BY</label>
                                    {fieldToSort === "uploadedBy" && isAscending === -1 && <i class="fas fa-angle-up"></i>}
                                    {fieldToSort === "uploadedBy" && isAscending === 1 && <i class="fas fa-angle-down"></i>}
                                </th>
                                <th><label>DUE DATE</label></th>
                                <th><label>VERSION HISTORY</label></th>
                            </tr>
                        </thead>
                        <tbody>

                            {filteredDocumentsData?.map((data, index) => {
                                let docName = data.orgFileName
                                let docStatus = data.fileStatus
                                let docAssignedTo = data.assignedTo
                                let docUploadedBy = data.uploadedBy
                                let fileId = data.fileId
                                let isClustered = data.isClustered
                                let version = data.version
                                let fileVersionId = data.fileVersionId
                                let minor = data?.minor?.toString()
                                let docType = data?.docType?.toString()
                                let dueDate = data.dueDate
                                let filePath = data.filePath
                                let permission = data.permission
                                const selectedFileData = {
                                    docName: docName,
                                    docStatus: docStatus,
                                    docUploadedBy: docUploadedBy,
                                    fileId: fileId,
                                    isClustered: isClustered,
                                    version: version,
                                    fileVersionId: fileVersionId,
                                    minor: minor,
                                    docType: docType,
                                    dueDate:dueDate,
                                    permission:permission,
                                }

                                return (
                                    <tr className={styles.all_documents_list_row_content} key={index}>
                                        <td onClick={() => this.props.openPopup(selectedFileData)}>
                                            <span className={styles.doc_name}>{docName}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={styles.doc_status}>{docStatus}
                                            </span>
                                        </td>
                                        <td>
                                            <span>{docAssignedTo} </span>
                                        </td>
                                        <td>
                                            <span>{docUploadedBy} </span>
                                        </td>
                                        <td>
                                            <span>{dueDate} </span>
                                        </td>
                                        <td>
                                            <span onClick={() => this.props.openPopup(selectedFileData)} className={styles.doc_version}><u>{version} Versions</u> </span>
                                        </td>
                                        <td style={{ position: 'relative' }}>
                                            <i onClick={() => this.openActionPopup(fileId)} class="fas fa-ellipsis-v"></i>
                                            {this.state.selectedAction === fileId && <div className={styles.action_popup}>
                                            <p className={styles.actionText} onClick={() => {this.handleShowFile(filePath, docName)}}>Show Original File</p>
                                                <p className={styles.actionText} onClick={() => {this.handleDelete(fileId)}}>Delete</p>
                                                </div>}
                                        </td>
                                    </tr>

                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {isDeleteMemberModalOpen && <DeleteDocument documentToDelete={documentToDelete} closeHandler={this.onDeleteDocumentClose} onDeleteDocument={this.onDeleteDocument} ></DeleteDocument>}
            {showFile && <DisplayDocument showFileUrl={showFileUrl} fileName={fileNameToShow} closeHandler={this.handleCloseFile}></DisplayDocument>}
            </div>
        )
    }
}
export default AllDocumentsList;