import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as types from '../types'
import _api from '../../services/api'
import URLS from '../../services/urls'
import * as dashboard from '../actions/dashboard-v2Action'

function* dashboardDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestDashboardData,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(dashboard.dashboardDataSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(dashboard.dashboardDataFailure(e))
  }
}

function* dashboardFileUploadWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestFileUpload,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(dashboard.dashboardFileUploadSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(dashboard.dashboardFileUploadFailure(e))
  }
}

function* fileClusterWorker(params) {
  console.log("fileClusterWorker was called")
  try {
    const data = yield call(
      _api,
      URLS.requestFileCluster,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      console.log("error in file cluster",data)
      throw data
      
    }
    yield put(dashboard.fileClusterSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(dashboard.fileClusterFailure(e))
  }
}

function* clusteredDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestClusteredData,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(dashboard.clusteredDataSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(dashboard.clusteredDataFailure(e))
  }
}

function* dashboardDataWatcher() {
  yield takeLatest(types.DASHBOARD_DATA_REQUEST, dashboardDataWorker)
}
function* dashboardFileUploadWatcher() {
  yield takeLatest(
    types.DASHBOARD_FILE_UPLOAD_REQUEST,
    dashboardFileUploadWorker
  )
}
function* fileClusterWatcher() {
  yield takeLatest(types.UPLOAD_DOCUMENT_FILE_CLUSTER_REQUEST, fileClusterWorker)
}
function* clusteredDataWatcher() {
  yield takeLatest(types.UPLOAD_DOCUMENT_CLUSTERED_DATA_REQUEST, clusteredDataWorker)
}

export default function* dashboardV2Saga() {
  yield all([
    dashboardDataWatcher(),
    dashboardFileUploadWatcher(),
    fileClusterWatcher(),
    clusteredDataWatcher(),
  ])
}
