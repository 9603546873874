import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as privateFolderActions from './../../../../store/actions/privateFolderAction'
import * as dashboardV2Page from '../../../../store/actions/dashboard-v2Action'

import CheckboxList from '../../checkbox-list/CheckboxList'
import FilterCheckboxList from '../../filter-checkbox-list/FilterCheckboxList'
import SearchBar from '../../search-bar/SearchBar'

import { handleSuccess, handleError } from './../../../../shared/HandleMessages'
import { members_list } from './../../../../json-data/searchResults.json'
import CommonStyles from './../../../../shared/Common.module.css'
import styles from './PrivateFolder.module.css'
import { AiTwotoneCompass } from 'react-icons/ai'
import { BsPencil } from 'react-icons/bs'
import { IconContext } from 'react-icons'

class PrivateFolder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      privateFoldersList: null,
      showSelected: false,
      selectedFolderName: null,
      selectedFolderDetails: null,
      fileId: this.props.fileId,
      folderName: '',
      rootFolderDetails: this.props.dashboardData.data?.rootFolder?.[0],
    }
  }

  componentDidMount() {
    this.props.foldersListRequest()
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.fileId && this.props.fileId) {
      this.setState({
        fileId: this.props.fileId,
      })
    }
    if (
      prevProps.isFolderListLoading &&
      !this.props.isFolderListLoading &&
      this.props.folderListData
    ) {
      this.setState({
        privateFoldersList: this.props.folderListData?.data,
      })
    }
    if (
      prevProps.isFolderAddedLoading &&
      !this.props.isFolderAddedLoading &&
      this.props.privateFolderData
    ) {
      // this.setState({
      //   privateFoldersList: this.props.privateFolderData?.data?.allFolders,
      // })
      handleSuccess('Folder created successfully!')
      this.props.closePanel()
    }
  }

  handleSearchChange = searchItems => {
    this.getPrivateFoldersList(searchItems)
  }
  getPrivateFoldersList = searchItems => {
    // if (searchItems === '')
    // this.setState({
    //   privateFoldersList:
    // })
    let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let privateFoldersList = this.props.folderListData?.data?.filter(folder => {
      return folder.name.toLowerCase().search(subStr.toLowerCase()) !== -1
    })

    this.setState({ privateFoldersList })
  }
  toggleShowSelected = e => {
    this.setState({
      showSelected: e.target.checked,
    })
  }

  onValueChange = (e, folder) => {
    let folderName = null
    let folderDetails = null
    if (e.target.checked) {
      folderName = folder.name
      folderDetails = folder
    }

    this.setState({
      selectedFolderName: folderName,
      selectedFolderDetails: folderDetails,
    })
  }

  addFileToFolderHandler = () => {
    const { folderName } = this.state

    if (folderName !== '') {
      const {
        catalogSourceId,
        referenceId,
        level,
      } = this.state.rootFolderDetails
      const { folderName } = this.state

      const params = {
        fileId: this.state.fileId,
        catalogSourceId,
        level: level.toString(),
        folderName,
        referenceId,
      }
      // console.info('params: ', params)
      this.props.folderAddRequest(params)
    } else {
      if (!this.state.selectedFolderDetails) return

      const {
        catalogSourceId,
        referenceId,
        level,
        name,
      } = this.state.selectedFolderDetails

      const params = {
        fileId: this.state.fileId,
        catalogSourceId,
        level: level.toString(),
        folderName: name,
        referenceId,
      }
      this.props.fileAddRequest(params)
      handleSuccess('File added to folder!')
      this.props.closePanel()
    }
  }

  addFolder = e => {
    if (e.key === 'Enter') {
      const {
        catalogSourceId,
        referenceId,
        level,
      } = this.state.rootFolderDetails
      const { folderName } = this.state

      const params = {
        // TODO: uncomment when fixed from backend
        fileId: this.state.fileId,
        catalogSourceId,
        level: level.toString(),
        folderName,
        referenceId,
      }
      this.props.folderAddRequest(params)
    }
  }

  folderNameChange = e => {
    this.setState({
      folderName: e.target.value,
    })
  }
  render() {
    const {
      searchItems,
      privateFoldersList,
      showSelected,
      selectedFolderName,
      folderName,
    } = this.state

    let listData = privateFoldersList
    if (showSelected && selectedFolderName) {
      let tempArray = privateFoldersList.filter(
        folder => folder.name === selectedFolderName
      )
      // console.info('temp array: ', tempArray)
      listData = tempArray
    }

    return (
      <div className={`${CommonStyles.card_layout} ${styles.root}`}>
        <div className={styles.search_bar_container}>
          <div className={styles.search_bar_background}>
            <div className={styles.search_bar_wrapper}>
              <SearchBar
                iconColor={'#2c2c2c'}
                iconSize={'16px'}
                fontSize={'12px'}
                placeholder={'Search'}
                handleSearch={() => {}}
                handleSearchChange={searchItems => {
                  this.handleSearchChange(searchItems)
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.members_list}>
          <>
            {listData?.map((folder, index) => (
              <div className={styles.checkbox_container} key={index}>
                <div
                  className={styles.round}
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`${folder.name}-${index}-${index}`}
                    onChange={e => this.onValueChange(e, folder)}
                    name={`private-folder-radio-group`}
                    value={folder.name}
                    checked={selectedFolderName === folder.name}
                  />
                  <label
                    for={`${folder.name}-${index}-${index}`}
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                </div>
                <span className={styles.checkbox_title}>{folder.name}</span>
              </div>
            ))}
          </>
        </div>

        <div className={styles.input_container}>
          <input
            type="text"
            placeholder="Name your folder"
            onChange={this.folderNameChange}
            onKeyDown={this.addFolder}
            maxLength="40"
          />
          {/* <IconContext.Provider
            value={{
              size: 21,
              color: '#9a9a9a',
            }}
          >
            <BsPencil />
          </IconContext.Provider> */}
        </div>

        <div className={styles.button_container}>
          <div className={styles.toggle_btn}>
            <div className={styles.switch_container}>
              <label class={styles.switch}>
                <input type="checkbox" onChange={this.toggleShowSelected} />
                <span class={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
            <div className={styles.bottom_text}>View Only</div>
          </div>
          <div className={styles.add_btn} onClick={this.addFileToFolderHandler}>
            ADD
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  // console.info('privateFolderData', state.privateFolderComponent)
  return {
    dashboardData: state.dashboardV2Page.dashboardData,
    privateFolderData: state.privateFolderComponent.folderAddedData,
    isFolderAddedLoading: state.privateFolderComponent.isFolderAddedLoading,
    isFolderListLoading: state.privateFolderComponent.isFolderListLoading,
    folderListData: state.privateFolderComponent.folderListData,
  }
}

const mapDispatchToProps = {
  fileAddRequest: privateFolderActions.fileAddRequest,
  folderAddRequest: privateFolderActions.folderAddRequest,
  foldersListRequest: privateFolderActions.foldersListRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateFolder)
