import * as types from '../types';

// List Documents Actions

export const listDocumentsRequest = (payload) => ({ type: types.LIST_DOCUMENTS_REQUEST, payload });
export const listDocumentsSuccess = (payload) => ({ type: types.LIST_DOCUMENTS_SUCCESS, payload });
export const listDocumentsFailure = (payload) => ({ type: types.LIST_DOCUMENTS_FAILURE, payload });

// File Journey Actions
export const fileJourneyRequest = (payload) => ({ type: types.FILE_JOURNEY_REQUEST, payload });
export const fileJourneySuccess = (payload) => ({ type: types.FILE_JOURNEY_SUCCESS, payload });
export const fileJourneyFailure = (payload) => ({ type: types.FILE_JOURNEY_FAILURE, payload });

// DElete Document Actions
export const deleteDocumentRequest = (payload) => ({ type: types.DELETE_DOCUMENT_REQUEST, payload });
export const deleteDocumentSuccess = (payload) => ({ type: types.DELETE_DOCUMENT_SUCCESS, payload });
export const deleteDocumentFailure = (payload) => ({ type: types.DELETE_DOCUMENT_FAILURE, payload });

export const saveFileDataRequest = (payload) => ({type: types.SAVE_FILEDATA_REQUEST, payload})
export const saveFileDataSuccess = (payload) => ({type: types.SAVE_FILEDATA_SUCCESS, payload})
export const clearFileId = () => ({type: types.CLEAR_FILE_ID})
// Filter Document Actions
export const filterDocumentsRequest = (payload) => ({ type: types.FILTER_DOCUMENTS_REQUEST, payload });
export const filterDocumentsSuccess = (payload) => ({ type: types.FILTER_DOCUMENTS_SUCCESS, payload });
export const filterDocumentsFailure = (payload) => ({ type: types.FILTER_DOCUMENTS_FAILURE, payload });
