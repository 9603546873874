import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import OutsideClickHandler from 'react-outside-click-handler';

import Dropdown from '../dropdown/Dropdown';
import RelatedSearches from './../related-searches/RelatedSearches';
// import Sidebar from '../../../shared/sidebar/Sidebar'
import NavigationBar from '../../../shared/navigation-bar/NavigationBar';
import commonStyles from '../../../shared/Common.module.css';
import ArrowBlueRight from './../../../assets/arrow_blue_right.svg';
import ArrowGreyRight from './../../../assets/arrow_grey_right.svg';
import ArrowGreyLeft from './../../../assets/arrow_grey_left.svg';
import ArrowBlueLeft from './../../../assets/arrow_blue_left.svg';
import ArrowUp from './../../../assets/arrow-up-grey.svg';
import styles from './RelatedSearchesPage.module.css';
import data from './../../../json-data/searchResults.json';
import Filter from '../modals/filter/Filter';
import Sort from '../modals/sort/Sort';
import * as searchResultsPageActions from '../../../store/actions/catalogSearchResultsAction';
import { connect } from 'react-redux';

import Sidebar from '../../../screens/workflow-management/Sidebar/Sidebar';
import NavBar from '../../../screens/workflow-management/NavBar/NavBar';
import RightBar from '../../../screens/workflow-management/RightBar/RightBar';

class RelatedSearchesPage extends Component {
  PER_PAGE = 10;
  recentDocumentsData = [];

  filterRef = React.createRef();
  state = {
    showFilterModal: false,
    showSortModal: false,
    recentDocumentPaginationData: [],
    totalNumberOfPages: 1,
    isAlphabetAscending: 1,
    isDateAscending: 1,
    currentPage: 1,
  };

  /*
   * "allDocumentData" are filtered documents from previous page
   */
  componentDidMount() {
    document.body.scrollTop = 0;

    const allDocumentData = this.props.history.location?.state?.filteredData;
    // console.info('allDocumentData', allDocumentData)

    //  for React Pagination
    this.PER_PAGE = allDocumentData?.length > 10 ? 10 : allDocumentData?.length;

    this.recentDocumentsData = allDocumentData;
    const totalNumberOfPages = this.recentDocumentsData?.length / this.PER_PAGE;
    this.setState(
      {
        totalNumberOfPages,
      },
      () => {
        this.handlePaginationClick();
      }
    );
  }
  toggleFilter = () => {
    const { showFilterModal, showSortModal } = this.state;
    if (showSortModal) {
      this.setState({
        showSortModal: false,
      });
    }
    this.setState({
      showFilterModal: !showFilterModal,
    });
  };
  toggleSort = () => {
    const { showSortModal, showFilterModal } = this.state;
    if (showFilterModal) {
      this.setState({
        showFilterModal: false,
      });
    }
    this.setState({
      showSortModal: !showSortModal,
    });
  };
  handleBackNavigation = () => {
    this.props.history.goBack();
  };
  // set this.recentDocumentsData and then call this for explicit call
  handlePaginationClick = (data) => {
    const currentPage = data === undefined ? 0 : data.selected;
    const offset = this.PER_PAGE * currentPage;
    const recentDocumentPaginationData = this.recentDocumentsData.slice(
      offset,
      offset + this.PER_PAGE
    );
    const totalNumberOfPages = Math.ceil(
      this.recentDocumentsData?.length / this.PER_PAGE
    );
    this.setState({
      recentDocumentPaginationData,
      totalNumberOfPages,
      currentPage: currentPage + 1,
    });
  };
  sortByAlbhabet = () => {
    this.sortProjectByAlphabet();
  };
  sortProjectByAlphabet = () => {
    const recentDocumentPaginationData = this.state
      .recentDocumentPaginationData;
    recentDocumentPaginationData.sort((a, b) =>
      a['fileName'].toLowerCase() > b['fileName'].toLowerCase()
        ? this.state.isAlphabetAscending
        : this.state.isAlphabetAscending * -1
    );
    this.setState({
      recentDocumentPaginationData,
      isAlphabetAscending: this.state.isAlphabetAscending * -1,
    });
  };
  sortByDate = () => {
    console.log('hii');
    this.sortProjectByDate();
  };
  sortProjectByDate = () => {
    const { isDateAscending, recentDocumentPaginationData } = this.state;
    if (isDateAscending === 1) {
      recentDocumentPaginationData.sort(function (a, b) {
        return new Date(b['modified']) - new Date(a['modified']);
      });
      this.setState({
        recentDocumentPaginationData,
        isDateAscending: -1,
      });
    } else if (isDateAscending === -1) {
      recentDocumentPaginationData.sort(function (a, b) {
        return new Date(a['modified']) - new Date(b['modified']);
      });
      this.setState({
        recentDocumentPaginationData,
        isDateAscending: 1,
      });
    }
  };
  applyFilters = (filteredData) => {
    if (filteredData.length) {
      this.recentDocumentsData = filteredData;
      this.handlePaginationClick();
      this.closeFilterAndSort();
      this.props.saveFilteredDocuments(filteredData);
      const allFilters = this.filterRef.current.getCheckboxState();
      this.props.saveFilters(allFilters);
    } else {
      this.clearFilters();
    }
  };
  clearFilters = () => {
    this.recentDocumentsData = this.props.catalogSearchResultData?.allDocuments;
    this.handlePaginationClick();
    this.closeFilterAndSort();
    this.props.saveFilteredDocuments(null);
    this.props.saveFilters(null);
  };
  closeFilterAndSort = () => {
    const { showFilterModal, showSortModal } = this.state;
    if (showFilterModal || showSortModal)
      this.setState({
        showFilterModal: false,
        showSortModal: false,
      });
  };
  getPreviousLabel = () => {
    const { currentPage, totalNumberOfPages } = this.state;

    let image = null;
    if (currentPage === 1 && totalNumberOfPages === 1) {
      image = <img src={ArrowGreyLeft} alt="Arrow Left" />;
    } else if (currentPage === 1 && totalNumberOfPages > currentPage) {
      image = <img src={ArrowGreyLeft} alt="Arrow Left" />;
    } else {
      image = <img src={ArrowBlueLeft} alt="Arrow Left" />;
    }
    return image;
  };
  getNextLabel = () => {
    const { currentPage, totalNumberOfPages } = this.state;
    let image = null;
    if (currentPage !== 1 && totalNumberOfPages === currentPage) {
      image = <img src={ArrowGreyRight} alt="Arrow Right" />;
    } else if (currentPage === 1 && totalNumberOfPages === 1) {
      image = <img src={ArrowGreyRight} alt="Arrow Right" />;
    } else {
      image = <img src={ArrowBlueRight} alt="Arrow Right" />;
    }
    return image;
  };
  render() {
    // console.log('catalogSearchResultData', this.props.catalogSearchResultData)

    const {
      showSortModal,
      showFilterModal,
      totalNumberOfPages,
      recentDocumentPaginationData,
      currentPage,
    } = this.state;
    const { search_results } = data;

    const allFilters = this.props.history.location?.state?.allFilters;

    // console.info('totalNumberOfPages', totalNumberOfPages)

    // console.info('state: ', this.state)
    return (
      <>
        <Sidebar />
        <NavBar></NavBar>
        <RightBar></RightBar>
        <div
          className={`${commonStyles.commom_data_catalog_wrapper} ${styles.root}`}
        >
          <div className={styles.relative}>
            <div className={styles.back_icon}>
              <img
                onClick={this.handleBackNavigation}
                src={ArrowUp}
                alt="Arrow Up"
              />
            </div>
          </div>

          <div className={styles.right}>
            <div
              className={styles.title_container}
              onClick={this.handleBackNavigation}
            >
              <div className={styles.heading}>Search Results on</div>
              <div className={styles.search_title}>
                {this.props.history.location?.state?.searchTerm}
              </div>
            </div>

            <OutsideClickHandler onOutsideClick={this.closeFilterAndSort}>
              <div className={styles.dropdown_wrapper}>
                <div className={styles.filter_wrapper}>
                  <div className={styles.dropdown} onClick={this.toggleFilter}>
                    <Dropdown
                      backgroundColor={'#fafafa'}
                      value0={'Filter'}
                      height={'30px'}
                      fontSize={'18px'}
                      toggled={showFilterModal}
                    />
                  </div>

                  <div
                    style={
                      showFilterModal
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                  >
                    {/*
                     * "allDocumentData" original documents for subjection to filters
                     */}
                    <Filter
                      filters={this.props.catalogSearchResultData?.filters}
                      allDocumentData={
                        this.props.catalogSearchResultData?.allDocuments
                      }
                      applyFilters={(filteredData) =>
                        this.applyFilters(filteredData)
                      }
                      clearFilters={this.clearFilters}
                      allFilters={allFilters}
                      ref={this.filterRef}
                    />
                  </div>
                </div>
                <div className={styles.sort_wrapper}>
                  <div className={styles.dropdown} onClick={this.toggleSort}>
                    <Dropdown
                      backgroundColor={'#fafafa'}
                      value0={'Sort'}
                      height={'30px'}
                      fontSize={'18px'}
                      toggled={showSortModal}
                    />
                  </div>

                  <div
                    style={
                      showSortModal ? { display: 'block' } : { display: 'none' }
                    }
                  >
                    <Sort
                      sortByAlbhabet={this.sortByAlbhabet}
                      sortByDate={this.sortByDate}
                    />
                  </div>
                </div>
              </div>
            </OutsideClickHandler>
            <div>
              <RelatedSearches
                history={this.props.history}
                documentsData={recentDocumentPaginationData}
              />
            </div>
            <div className={`react-pagination ${styles.react_pagination}`}>
              <ReactPaginate
                previousLabel={this.getPreviousLabel()}
                nextLabel={this.getNextLabel()}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={totalNumberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={totalNumberOfPages}
                forcePage={currentPage - 1}
                onPageChange={this.handlePaginationClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    catalogSearchResultData: state.catalogSearchResult.catalogSearchResultData,
  };
};
const mapDispatchToProps = {
  saveFilteredDocuments:
    searchResultsPageActions.catalogSearchResultFilteredDocumentsAction,
  saveFilters: searchResultsPageActions.catalogSearchResultFilterAction,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatedSearchesPage);
