import React, { Component } from "react";
import styles from "./AllDocumentsVersionHistory.module.css";
import { connect } from 'react-redux';
import * as uploadedDoc from '../../../store/actions/uploadedDocActions';
import * as dashboard from '../../../store/actions/dashboardActions';
import { createParamsString } from '../../../shared/helper';
// import Loader from '../../../shared/loader/Loader';
class AllDocumentsVersionHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClusteredDataRequested: false,
            uploadingInProcess: false

        }
    }

    componentDidUpdate(){
        if (this.props.uploadedDoc.clusterData && this.state.isClusteredDataRequested && !this.props.isUploadedDocLoading) {
            this.props.history.push('/clustered-result', {from:'all-documents'})

        }

        if (this.props.dashboard.fileRead && !this.props.isDashboardLoading && this.state.uploadingInProcess) {
            const url = '/uploaded-doc' + createParamsString({origin: 'fileRead'});
            this.props.history.push(url, {from:'all-documents'})
        }
    }
    handleOpenDocument = (docInfo) => {
        if(docInfo.isClustered){
        const paramsObj={
            fileId: docInfo.fileId,
            version:docInfo.major,
            minor:docInfo.minor.toString(),
            fileVersionId:docInfo.fileVersionId,
            docType:docInfo.docType.toString(),
        }
        this.props.clusterDataRequest(paramsObj, {fileId: paramsObj.fileId}, {fileId: paramsObj.fileId})
        this.setState({ isClusteredDataRequested: true })
    }
    else{
        const paramsObj={
            fileId: docInfo.fileId,
            version: docInfo.major
        }
        this.props.fileReadRequest(paramsObj)
        this.setState({ uploadingInProcess: true })
    }
    }
    render() {
        return (
            <div className='thin_custom_scrollbar'>
                {/* {(this.props.isDashboardLoading || this.props.isUploadedDocLoading) &&  <Loader></Loader> } */}
                <div className={styles.version_history_container}>
                    {this.props.fileVersionData.map((history, i) => {
                        return (
                            <div key={i} className={styles.version_history_section}>
                                <div className={styles.version}>
                                    Version {history.fileVersion}
                                    <div>
                                        <div className={styles.submitted_by}>
                                           { history.uploadedBy}
                                        </div>
                                        <div onClick={() =>this.handleOpenDocument(history)} className={styles.open_doc}>
                                            <u> Open Document </u>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                        <div className={styles.date}>{history.dueDate}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        fileVersionData: state.allDocuments.fileJourneyData.data.versions,
        uploadDoc: state.uploadDoc,
        uploadedDoc: state.uploadedDoc,
        isUploadedDocLoading: state.uploadedDoc.isLoading,
        dashboard:state.dashboard,
        isDashboardLoading: state.dashboard.isFileReadLoading,

    };
}
const mapDispatchToProps = {
    clusterDataRequest: uploadedDoc.clusterDataRequest,
    fileReadRequest: dashboard.fileReadRequest,

}

export default connect(mapStateToProps, mapDispatchToProps)(AllDocumentsVersionHistory);