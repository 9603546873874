import React, { Component } from "react";
import FileViewer from 'react-file-viewer';
import styles from "./RenderUploadedDoc.module.css";
import { connect } from 'react-redux';
// import commonStyles from '../../../shared/Common.module.css';

class RenderUploadedDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 100,
        }
    }
    onResize = (resizeFactor) => {
        let nextSize = this.state.size + resizeFactor;
        if (nextSize > 150) {
            nextSize = 150
        }
        else if (nextSize <= 50) {
            nextSize = 50
        }
        this.setState({
            size: nextSize
        })
    }

    navigateBack = () =>{
       let  url = this.props.history.location.state?.from
        if(url){
            this.props.history.push(url)
        }
        else{
            this.props.history.push('/dashboard')
        }
    }

    render() {
        let data = window.location.href.includes("origin=fileRead")? this.props.dashboard?.fileRead?.data:this.props.uploadDoc.docData.data
        const {size} = this.state
        const path = data.result.filePath;
        const fileName = data.result.orgFileName;
        const n = path.lastIndexOf('.');
        let fileType = path.substring(n + 1);
        return (
            <div className={styles.doc_wrapper} >
                <div className={styles.doc_control_container}>
                    <div className={styles.doc_control_section}>
                        <div>
                            <i onClick={this.navigateBack} className={`fas fa-arrow-left ${styles.icon_style}`}></i>{fileName}
                    </div>
                        {/* <div>
                    <i  className={`fas fa-arrow-left ${styles.icon_style}`}></i>IncomeTax_SCN.pdf
                    </div>  */}
                        <div className={styles.resize_icons}>
                            <i onClick={() => this.onResize(10)} className={`fas fa-search-plus ${styles.icon_style}`}></i>
                             <span>{size}%</span>
                            <i onClick={() => this.onResize(-10)} className={`fas fa-search-minus ${styles.icon_style}`}></i>
               
                        </div>
                    </div>
                </div>
                <div style={{ zoom: this.state.size + '%' }} className={`document_render_container ${styles.doc_render_wrapper}`}>
                    <FileViewer
                        fileType={fileType}
                        filePath={path}
                        onError={this.onError} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        uploadDoc: state.uploadDoc,
        dashboard:state.dashboard,
    };
}


export default connect(mapStateToProps, null)(RenderUploadedDoc);





