import React from 'react'
import { connect } from 'react-redux'

import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'
import RightBar from '../../screens/workflow-management/RightBar/RightBar'
import AllFolderList from '../../components/data-catalog-all-folders/all-folder-list/AllFolderList'

import recentDocuments from '../../json-data/recentDocuments.json'
import SearchBlackIcon from '../../../src/assets/search_black.svg'
import ArrowUp from '../../../src/assets/arrow-up-grey.svg'

import * as catalogSelectedFolder from '../../store/actions/catalogSelectedFolderAction'
import * as createNewDocumentActions from './../../store/actions/createNewDocumentActions'
import * as sidebarAction from './../../store/actions/sidebarAction'

import styles from './DocumentList.module.css'
import commonStyles from '../../shared/Common.module.css'
import { formatDate } from '../../shared/formatDate'

class DocumentList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allFilesData: null,
      searchItems: '',
    }
  }

  componentDidMount() {
    this.props.fetchAllPrivateDocumentsRequest()
    this.props.newDocumentSaveRequest(false)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isFetchAllDocumentsLoading &&
      !this.props.isFetchAllDocumentsLoading &&
      this.props.fetchAllDocumentsData
    ) {
      this.setState({
        allFilesData: this.props.fetchAllDocumentsData,
      })
    }
  }

  handleBackNavigationGoBack = () => {
    this.props.history.goBack()
  }
  handleUserInput = event => {
    this.setState({ searchItems: event.target.value })
  }

  render() {
    const { allFilesData, searchItems } = this.state

    return (
      <React.Fragment>
        <NavBar></NavBar>
        <Sidebar></Sidebar>
        <RightBar></RightBar>

        <div
          className={`${commonStyles.commom_data_catalog_wrapper} ${styles.data_catalog_all_folders_container}`}
        >
          <div className={styles.data_catalog_all_folders_top_section}>
            <div className={styles.left_section}>
              <div
                className={styles.back_icon}
                onClick={this.handleBackNavigationGoBack}
              >
                <img src={ArrowUp} alt="Arrow Up" />
              </div>
              <div
                className={styles.folder_path}
                onClick={this.handleBackNavigationGoBack}
              >
                Documents
              </div>
              {/*  <div className={styles.folder_name}>
              {this.props.history.location?.state?.fileName.replace(
                /%20/g,
                ' '
              )}
            </div> */}
            </div>

            {/* <div className={styles.right_section}>
            {this.props.selectedFolderData?.metaData &&
              this.props.selectedFolderData?.metaData.map((data, index) => {
                return (
                  <div className={styles.right_section_card}>
                    <div className={styles.card_content}>
                      <div>
                        <img
                          className={styles.icon}
                          src={search_grey_icon}
                          alt="Search Icon"
                        />
                      </div>
                      <div className={styles.card_text_content}>
                        <div className={styles.name}>{data.name}</div>
                        <div className={styles.card_bottom_section}>
                          <div>{data.count} RESULTS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div> */}
          </div>
          <hr></hr>

          <div className={styles.recent_document_container}>
            <div className={styles.title_search_bar_container}>
              {/* <div className={styles.title_container}>
              <span className={styles.title}>Recent Documents</span>
              <img src={green_document} alt="document"></img>
            </div> */}
              <div className={styles.search_bar_section}>
                <div className={styles.search_bar_container}>
                  <input
                    placeholder="Search Document"
                    type="text"
                    className={styles.search_box}
                    onChange={event => this.handleUserInput(event)}
                  ></input>
                  <div className={styles.search_icon}>
                    <img
                      className={styles.icon}
                      src={SearchBlackIcon}
                      alt="Search Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* 
          {this.props.selectedFolderData?.recentFiles && (
            <RecentDocuments
              recentDocuments={this.props.selectedFolderData.recentFiles}
              parent={'DataCatalogAllFolder'}
            ></RecentDocuments>
          )} */}
            {/* 
          {(!this.props.selectedFolderData?.recentFiles ||
            !this.props.selectedFolderData?.recentFiles) && (
            <div>No Documents Found</div>
          )} */}
          </div>
          <hr></hr>

          <div className={styles.all_folder_list_container}>
            {allFilesData ? (
              <AllFolderList
                foldersData={allFilesData}
                searchItems={searchItems}
                parent="DocumentList"
              />
            ) : null}
          </div>

          {/* {this.props.selectedFolderData?.allFiles && this.state.allFilesData && (
          <div className={styles.all_folder_list_container}>
            <AllFolderList
              foldersData={this.state.allFilesData}
              searchItems={this.state.searchItems}
            />
          </div>
        )} */}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedFolderData: state.catalogSelectedFolder.selectedFolderData,
    isSelectedFolderDataLoading:
      state.catalogSelectedFolder.isSelectedFolderDataLoading,
    isCreateDocumentLoading:
      state.createPrivateDocument.isCreateDocumentLoading,
    createNewDocumentData: state.createPrivateDocument.createNewDocumentData,
    isFetchAllDocumentsLoading:
      state.createPrivateDocument.isFetchAllDocumentsLoading,
    fetchAllDocumentsData: state.createPrivateDocument.fetchAllDocumentsData,
  }
}
const mapDispatchToProps = {
  catalogRecentFolders: catalogSelectedFolder.catalogSelectedFolderRequest,
  fetchAllPrivateDocumentsRequest:
    createNewDocumentActions.fetchAllPrivateDocumentsRequest,
    newDocumentSaveRequest: sidebarAction.newDocumentSaveRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentList)
