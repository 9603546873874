import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';

HC_more(Highcharts);

class DataSourceGraph extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { firstGraphData } = this.props;
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          // constructorType={'chart'}
          options={{
            chart: {
              type: 'packedbubble',
              height: '360px',
              style: {
                fontFamily: 'Roboto, sans-serif',
              },
            },
            title: {
              text: '',
            },
            tooltip: {
              useHTML: true,
              pointFormat: '<b>{point.name}:</b> {point.value}',
            },
            plotOptions: {
              packedbubble: {
                minSize: '60%',
                maxSize: '180%',
                zMin: 0,
                zMax: 1000,
                layoutAlgorithm: {
                  gravitationalConstant: 0.05,
                  splitSeries: true,
                  seriesInteraction: false,
                  // dragBetweenSeries: false,
                  // parentNodeLimit: true,
                  enableSimulation: true,
                  parentNodeOptions: {
                    bubblePadding: 20,
                  },
                },
                dataLabels: {
                  enabled: true,
                  format: '{point.name}',
                  filter: {
                    property: 'y',
                    operator: '>',
                    value: 250,
                  },
                  style: {
                    color: 'black',
                    textOutline: 'none',
                    fontWeight: 'normal',
                  },
                },
              },
            },
            series: firstGraphData.series,
          }}
          containerProps={{ style: { height: '358px' } }}
        />
      </div>
    );
  }
}

export default DataSourceGraph;
