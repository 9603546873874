import * as types from '../types';

const initialState = {
  isLoading: false,
  createdLink: null,
  error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // create comment Actions
    case types.FILE_LINK_REQUEST:
      return {
        ...state,
        isLoading: true,
        createdLink: null,
      };
    case types.FILE_LINK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        createdLink: action.payload.result,
      };
    }
    case types.FILE_LINK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };

    default: {
      return state;
    }
  }
};
