import React, { Component } from 'react'
import styles from './Sources.module.css'
import PlusCircleWhiteIcon from './../../../assets/plus-circle_white.svg'
import data from './../../../json-data/searchResults.json'
import CommonStyles from './../../../shared/Common.module.css'
import SearchBar from './../../data-catalog/search-bar/SearchBar'
// import CommonStyles from './../../../shared/Common.module.css'
// import styles from './Sources.module.css'
import CheckboxList from './../../data-catalog/checkbox-list/CheckboxList'
import RadioGroupSources from './../../data-catalog/radio-group-sources/RadioGroupSources'
import AddSourceModal from './../../data-catalog/add-source-modal/index'

class Sources extends Component {
  radioGroupSourcesRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      dropboxUri:
        'https://precilyclient.azurewebsites.net/data-catalog-2?name=dropbox',
      oneDriveUri: 'https://precilyclient.azurewebsites.net/data-catalog-2',
      filteredCheckboxListData: [{ name: 'All Sources' }, ...this.props?.data],
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data) {
      const { filteredCheckboxListData } = this.state
      this.setState({
        filteredCheckboxListData: [
          ...filteredCheckboxListData,
          ...this.props.data,
        ],
      })
    }
  }

  sourceModalHandler = val => {
    this.setState({ open: val })
  }

  handleSearchChange = searchItems => {
    this.setState({ searchItems }, () => {
      this.getFilteredMembersList(searchItems)
    })
  }
  getFilteredMembersList = searchItems => {
    const { filteredCheckboxListData } = this.state

    let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let filteredData
    if (subStr === '')
      this.setState({
        filteredCheckboxListData: [
          { name: 'All Sources' },
          ...this.props?.data,
        ],
      })
    else {
      filteredData = filteredCheckboxListData.filter(member => {
        return (
          member.name
            .toLowerCase()
            .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            .search(subStr.toLowerCase()) !== -1
        )
      })
      this.setState({ filteredCheckboxListData: filteredData })
    }
  }

  getSelectedSources = () => {
    return this.radioGroupSourcesRef.current.getSelectedSources()
  }
  render() {
    const {
      open,
      dropboxUri,
      oneDriveUri,
      filteredCheckboxListData,
    } = this.state
    const totalData = filteredCheckboxListData.length

    // console.info('this.props.data: ', this.props?.data)
    // console.info('filteredCheckboxListData: ', filteredCheckboxListData)

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.headings}>
            <div className={styles.heading}>Showing:</div>
            <div className={styles.subheading}>
              {totalData === 0 ? 0 : totalData - 1} Sources
            </div>
          </div>
          <div className={styles.add_data_source_section}>
            <div className={styles.add_data_source_text}>Add Data Source</div>
            <div className={styles.plus_circle_icon_container}>
              <img
                className={styles.plus_circle_icon}
                src={PlusCircleWhiteIcon}
                alt="Plus Circle White Icon"
                onClick={() => this.sourceModalHandler(true)}
              />
            </div>
          </div>
        </div>

        <div className={`${CommonStyles.card_layout} ${styles.sources_box}`}>
          <div className={styles.search_bar_container}>
            <div className={styles.search_bar_background}>
              <div className={styles.search_bar_wrapper}>
                <SearchBar
                  iconColor={'#C4C4C4'}
                  iconSize={'14px'}
                  fontSize={'12px'}
                  placeholder={'Search Source'}
                  handleSearchChange={searchItems => {
                    this.handleSearchChange(searchItems)
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.sources_container}>
            <RadioGroupSources
              ref={this.radioGroupSourcesRef}
              data={filteredCheckboxListData}
              searchTerm={this.props.searchTerm}
            />
          </div>
        </div>
        <AddSourceModal
          open={open}
          dropboxUri={dropboxUri}
          oneDriveUri={oneDriveUri}
          sourceModalHandler={this.sourceModalHandler}
        />
      </div>
    )
  }
}

export default Sources
