import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AppRoute from './routes/routes'
import 'react-quill/dist/quill.snow.css'
import ResponseMessageHandler from './shared/response-message-handler/ResponseMessageHandler'
import { triggerToast } from './shared/helper'
import NavBar from './screens/workflow-management/NavBar/NavBar'

const App = () => {
  //  to show animation when '/dashboard' leaves after coming back from '/create-document'x
  const isCreateDocumentDisplayed = useSelector(
    state => state.createPrivateDocument.isCreateDocumentDisplayed
  )

  const triggerErrorToast = (error, clearError) => {
    let message = ''
    if (error.data && error.data.length) {
      error.data.forEach(el => {
        message += el && el.message
      })
      message = message || 'Something went wrong'
      triggerToast(message, 'error', clearError)
    }
  }
  const triggerSuccessToast = (message, clearError) => {
    triggerToast(message, 'success', clearError)
  }

  return (
    <React.Fragment>
      <AppRoute
        triggerErrorToast={triggerErrorToast}
        triggerSuccessToast={triggerSuccessToast}
        isCreateDocumentDisplayed={isCreateDocumentDisplayed}
      />
      <ResponseMessageHandler />
    </React.Fragment>
  )
}

export default App
