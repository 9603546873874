import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as uploadDoc from '../actions/uploadDocActions';

function* uploadDocWorker(params) {
    try {
        const data = yield call(_api, URLS.requestUploadDoc, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(uploadDoc.uploadDocSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(uploadDoc.uploadDocFailure(e));
    }
}

function* uploadDocWatcher() {
    yield takeLatest(types.UPLOAD_DOC_REQUEST, uploadDocWorker);
}


export default function* uploadDocSaga() {
    yield all([
        uploadDocWatcher(),


    ]);
}