import * as types from '../types'

const initialState = {
  isLoading: false,
  userDetails: null,
  error: null,
  successMessage: null,
  isSignUpSuccess: false,
  isLoginLoading: false,
  notifications: [],
  newNotificationId: null,
  areNotificationsUnread: false,
  running: false,
  currentTimeSec: 0,
  currentTimeMin: 0,
  currentTimeHours: 0,
  time: 0,
  pathname: null,
  currentProjectId: "",
  uploadDocumentModalParent: null
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // Login Actions
    case types.REQUEST_LOGIN:
      return {
        ...state,
        isLoading: true,
        userDetails: null,
        successMessage: null,
        isLoginLoading: true,
      }
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userDetails: action.payload.result.data,
        successMessage: action.payload.result.message,
        isLoginLoading: false,
      }
    }
    case types.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
        isLoginLoading: false,
      }

    // Signup Actions
    case types.REQUEST_SIGNUP:
      return {
        ...state,
        isLoading: true,
      }
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSignUpSuccess: true,
        userDetails: action.payload.result,
      }
    case types.SIGNUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Logout Actions
    case types.REQUEST_LOGOUT:
      return {
        ...state,
        ...initialState,
      }

    //EmailExist Actions
    case types.EMAIL_EXIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: action.payload.result,
      }

    case types.EMAIL_EXIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }
    //ForgotPassword Actions
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: action.payload.result.message,
      }
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }
    // Clear Error
    case types.CLEAR_HOMEPAGE_ERROR:
      return {
        ...state,
        error: null,
        successMessage: null,
      }
    case types.SAVE_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.SAVE_NOTIFICATION_SUCCESS: {
      state.notifications.unshift(action.payload[0])
      return {
        ...state,
        notifications: state.notifications,
      }
    }
    // case types.REMOVE_NOTIFICATION_REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //   }
    // case types.REMOVE_NOTIFICATION_SUCCESS:
    //   let latest = state.notifications.filter(notif => {
    //     return notif.messageId !== action.payload
    //   })
    //   return {
    //     ...state,
    //     notifications: latest,
    //     error: null,
    //   }
    // case types.REMOVE_NOTIFICATION_FAILURE:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    case types.MARK_NOTFICATION_READ_SUCCESS: {
      return {
        ...state,
      }
    }
    case types.INCREMENT_TIMER: {
      state.time = state.time + 1
      if (state.time) {
        state.currentTimeSec = state.currentTimeSec + 1
        state.time = 0
      }
      if (state.currentTimeSec >= 60) {
        state.currentTimeMin = state.currentTimeMin + 1
        state.currentTimeSec = 0
      }
      if (state.currentTimeMin >= 60) {
        state.currentTimeHours = state.currentTimeHours + 1
        state.currentTimeMin = 0
      }
      return {
        ...state,
        currentTimeSec: state.currentTimeSec,
        currentTimeMin: state.currentTimeMin,
        currentTimeHours: state.currentTimeHours,
        running: true,
      }
    }
    case types.STOP_TIMER: {
      return {
        ...state,
        running: false,
        currentTimeHours: 0,
        currentTimeMin: 0,
        currentTimeSec: 0,
      }
    }
    case types.RESET_TIMER: {
      return {
        ...state,
        currentTimeHours: 0,
        currentTimeMin: 0,
        currentTimeSec: 0,
        running: false,
      }
    }
    case types.CURRENT_PROJECT_ID: {
      return {
        ...state,
        currentProjectId: action.payload
      }
    }
    case types.UPDATE_PATHNAME: {
      return {
        ...state,
        pathname: action.payload,
      }
    }
    case types.HOMEPAGE_UNREAD_NOTIFICATION_REQUEST: {
      return {
        ...state,
        areNotificationsUnread: action.payload,
      }
    }
    case types.HOMEPAGE_NEW_NOTIFICATION_ID_REQUEST: {
      return {
        ...state,
        newNotificationId: action.payload,
      }
    }
    case types.UPLOAD_DOCUMENT_MODAL_PARENT: {
      return {
        ...state,
        uploadDocumentModalParent: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
