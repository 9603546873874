import React from 'react';
import styles from './StatsGraph.module.css';
import * as d3 from 'd3';
class StatsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  formatIssueName = (name) => {
    // let wordArr = name.split(' ');
    // let tempStr = '';
    // wordArr.forEach((word) => {
    //   tempStr += word;
    //   tempStr += `\n`;
    // });
    let tempStr = '';
    if (name.length > 15) {
      tempStr = name.slice(0, 15);
      tempStr += ' ...';
      return tempStr;
    } else {
      return name;
    }
  };

  componentDidMount() {
    let width = 250;
    let height = 170;
    let margin = 0;

    if (this.props.title === 'TIME SPENT') {
      let data = this.props.data;
      if (!data || data.length < 1) return;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      var radius = Math.min(width, height) / 1.5 - margin;

      let svg = d3
        .select(this.ref.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', 'translate(' + width / 2 + ',' + height / 2.5 + ')');

      let categories = [];
      let total = 0;
      data &&
        data.forEach((obj) => {
          categories.push(obj.key);
          total += obj.value;
        });
      let color = d3.scaleOrdinal().domain(categories).range(d3.schemeGreys[6]);

      // Compute the position of each group on the pie:
      let pie = d3
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      var data_ready = pie(data);

      // The arc generator
      var arc = d3
        .arc()
        .innerRadius(radius * 0.5) // This is the size of the donut hole
        .outerRadius(radius * 0.6);

      // Another arc that won't be drawn. Just for labels positioning
      d3.arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll('allSlices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('id', function (d) {
          return `arc_part_${d.data.id}`;
        })
        .attr('fill', function (d, i) {
          return color(d.data.value);
        })
        .attr('stroke', 'white')
        .style('stroke-width', '2px')
        .style('opacity', 0.7)
        .style('cursor', 'pointer')
        .on('mouseover', (d, data) => {
          // let arc = document.getElementById(`arc_part_${data.data.id}`);
          // arc.style.fill = '#1BA94C';

          svg.selectAll('text').remove();
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1.3em')
            .text(`${data.data.key[0].toUpperCase()}${data.data.key.slice(1)}`)
            .style('font-size', '12px')
            .style('color', '#C4C4C4');

          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1em')
            .text(
              `${
                data.data.value % 1 === 0
                  ? data.data.value
                  : data.data.value.toFixed(2)
              } Hrs.`
            )
            .style('font-size', '20px')
            .style('color', '#C4C4C4');
        })
        .on('mouseout', (d, obj) => {
          // let arc = document.getElementById(`arc_part_${obj.data.id}`);
          // arc.style.fill = color(obj.data.value);

          svg.selectAll('text').remove();
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1.3em')
            .text('Total')
            .style('font-size', '12px')
            .style('color', '#C4C4C4');
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1em')
            .text(`${total % 1 === 0 ? total : total.toFixed(2)} Hrs.`)
            .style('font-size', '20px')
            .style('color', '#C4C4C4');
        });

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '-1.3em')
        .text('Total')
        .style('font-size', '12px')
        .style('color', '#C4C4C4');

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '1em')
        .text(`${total % 1 === 0 ? total : total.toFixed(2)} Hrs.`)
        .style('font-size', '20px')
        .style('color', '#C4C4C4');
    } else {
      const data = this.props.data;
      let total = 0;
      let totalCount = 0;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      let radius = Math.min(width, height) / 1.5 - margin;

      // append the svg object to the div called 'my_dataviz'
      var svg = d3
        .select(this.ref.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', 'translate(' + width / 2 + ',' + height / 2.5 + ')');

      let categories = [];
      data.forEach((obj) => {
        categories.push(obj.key);
        if (this.props.title === 'TIME SPENT') {
          if (obj.key === 'total') {
            total = obj.value;
          }
        } else if (this.props.title === 'ISSUES') {
          total = data.length;
          totalCount += obj.value;
        } else {
          total += obj.value;
        }
      });

      // set the color scale
      let color;
      if (this.props.title === 'BILLING') {
        color = d3.scaleOrdinal().domain(categories).range(d3.schemeReds[3]);
      } else if (this.props.title === 'WORKFLOW') {
        color = d3.scaleOrdinal().domain(categories).range(d3.schemeBlues[3]);
      } else {
        color = d3.scaleOrdinal().domain(categories).range(d3.schemeOranges[3]);
      }

      // Compute the position of each group on the pie:
      let pie = d3
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value;
        });
      let data_ready = pie(data);

      // The arc generator
      let arc = d3
        .arc()
        .innerRadius(radius * 0.5) // This is the size of the donut hole
        .outerRadius(radius * 0.6);

      // Another arc that won't be drawn. Just for labels positioning
      d3.arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      let arcPart = svg
        .selectAll('allSlices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', function (d) {
          return color(d.data.key);
        })
        .attr('stroke', 'white')
        .style('stroke-width', '2px')
        .style('opacity', 0.7)
        .style('cursor', 'pointer');

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '-1.3em')
        .text('Total')
        .style('font-size', '12px')
        .style('color', '#C4C4C4');

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '1em')
        .text(
          `${total % 1 === 0 ? total : total.toFixed(2)} ${
            this.props.title === 'ISSUES'
              ? 'Issues'
              : this.props.title === 'WORKFLOW'
              ? total === 1
                ? 'Project'
                : 'Projects'
              : 'Hrs.'
          }`
        )
        .style('font-size', '20px')
        .style('color', '#C4C4C4');

      arcPart
        .on('mouseover', (d, data) => {
          svg.selectAll('text').remove();
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1.3em')
            .html(this.formatIssueName(data.data.key))
            .style('font-size', '12px')
            .style('color', '#C4C4C4');

          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1em')
            .text(
              `${
                this.props.title === 'ISSUES'
                  ? ((data.data.value / totalCount) * 100) % 1 === 0
                    ? (data.data.value / totalCount) * 100
                    : ((data.data.value / totalCount) * 100).toFixed(2)
                  : data.data.value % 1 === 0
                  ? data.data.value
                  : data.data.value.toFixed(2)
              } ${
                this.props.title === 'ISSUES'
                  ? '%'
                  : this.props.title === 'WORKFLOW'
                  ? data.data.value === 1
                    ? 'Project'
                    : 'Projects'
                  : 'Hrs.'
              }`
            )
            .style('font-size', '20px')
            .style('color', '#C4C4C4');
        })
        .on('mouseout', (d) => {
          svg.selectAll('text').remove();
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1.3em')
            .text('Total')
            .style('font-size', '12px')
            .style('color', '#C4C4C4');
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1em')
            .text(
              `${total % 1 === 0 ? total : total.toFixed(2)} ${
                this.props.title === 'ISSUES'
                  ? 'Issues'
                  : this.props.title === 'WORKFLOW'
                  ? total === 1
                    ? 'Project'
                    : 'Projects'
                  : 'Hrs.'
              }`
            )
            .style('font-size', '20px')
            .style('color', '#C4C4C4');
        });
    }
  }
  render() {
    return (
      <div>
        <div ref={this.ref}></div>
        <div className={styles.graph_bottom_text}>{this.props.title}</div>
      </div>
    );
  }
}

export default StatsGraph;
