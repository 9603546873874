import React, { Component } from "react";
import styles from "./EditMember.module.css";
import commonStyles from "../../../shared/Common.module.css";
import CommonModal from "../../../shared/common-modal/CommonModal";
import { connect } from "react-redux";
import Select from "react-select";

class EditMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.memberToEdit[0].role,
    };
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleEditMember = () => {
    const paramsObj = {
      teamMemberId: this.props.memberToEdit[0].teamMemberId,
      role: this.state.selectedOption.value,
    };
    this.props.onEditTeamMember(paramsObj);
  };
  getRoleOptions = () => {
    const roleDropdown = this.props.dropdowns.filter((dropdown) => {
      if (dropdown.type === "TEAM_MEMBER_ROLE") return dropdown.values;
    });
    const roleOptions = roleDropdown[0].values.map((item) => {
      return {
        value: item.key,
        label: item.value,
      };
    });
    return roleOptions;
  };
  render() {
    const { selectedOption } = this.state;
    const { name, designation, email } = this.props.memberToEdit[0];
    let roleOptions = [];
    if (this.props.dropdowns && this.props.dropdowns.length) {
      roleOptions = this.getRoleOptions();
    }
    return (
      <div>
        <CommonModal
          modalClass={"team_common_modal"}
          closeHandler={this.props.closeHandler}
          header={"Edit Member"}
        >
          <form>
            <div className="row">
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>NAME</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="name"
                  value={name}
                  disabled
                ></input>
              </div>
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>DESIGNATION</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="designation"
                  value={designation}
                  disabled
                ></input>
              </div>
            </div>
            <div className={`row ${styles.row_gap}`}>
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>ADDRESS</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="email"
                  value={email}
                  disabled
                ></input>
              </div>
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>CHOOSE ROLE</p>
                <Select
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={roleOptions}
                />
              </div>
            </div>
            <div className={styles.buttons_wrapper}>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                onClick={this.props.closeHandler}
              >
                CANCEL
              </button>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                onClick={this.handleEditMember}
              >
                SAVE
              </button>
            </div>
          </form>
        </CommonModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dropdowns: state.team.teamList?.data?.dropdown,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(EditMember);
