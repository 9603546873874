import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as dashboard from '../actions/dashboardActions';

function* userDashboardWorker(params) {
    try {
        const data = yield call(_api, URLS.requestUserDashboard, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(dashboard.userDashboardSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(dashboard.userDashboardFailure(e));
    }
}

function* userDashboardWatcher() {
    yield takeLatest(types.USER_DASHBOARD_REQUEST, userDashboardWorker);
}

function* advanceSearchWorker(params) {
    try {
        const data = yield call(_api, URLS.requestAdvanceSearch, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(dashboard.advanceSearchSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(dashboard.advanceSearchFailure(e));
    }
}

function* advanceSearchWatcher() {
    yield takeLatest(types.ADVANCE_SEARCH_REQUEST, advanceSearchWorker);
}

function* recentSearchWorker(params) {
    try {
        const data = yield call(_api, URLS.requestRecentSearch, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(dashboard.recentSearchSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(dashboard.recentSearchFailure(e));
    }
}

function* recentSearchWatcher() {
    yield takeLatest(types.RECENT_SEARCH_REQUEST, recentSearchWorker);
}

function* fileReadWorker(params) {
    try {
        const data = yield call(_api, URLS.requestFileRead, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(dashboard.fileReadSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(dashboard.fileReadFailure(e));
    }
}
function* fileReadWatcher() {
    yield takeLatest(types.FILE_READ_REQUEST, fileReadWorker);
}




function* reportAnalysisGraphWorker(params) {
    try {
        const data = yield call(_api, URLS.requestReportAnalysisGraph, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(dashboard.reportAnalysisGraphSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(dashboard.reportAnalysisGraphFailure(e));
    }
}
function* reportAnalysisGraphWatcher() {
    yield takeLatest(types.REPORT_ANALYSIS_GRAPH_REQUEST, reportAnalysisGraphWorker);
}


export default function* dashboardSaga() {
    yield all([
        userDashboardWatcher(),
        advanceSearchWatcher(),
        recentSearchWatcher(),
        fileReadWatcher(),
        reportAnalysisGraphWatcher(),
    ]);
}