import React, { Component } from 'react';
import styles from './UploadedDocPreview.module.css';
import commonStyles from '../../../shared/Common.module.css';
import * as uploadedDoc from '../../../store/actions/uploadedDocActions';
import { getFullDate } from '../../../shared/helper';
import Select from 'react-select';
import { connect } from 'react-redux';
import ErrorText from '../../../shared/error-text/ErrorText';
import { WithContext as ReactTags } from 'react-tag-input';
import { handleSuccess, handleError } from '../../../shared/HandleMessages';
import { toast } from 'react-toastify';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class UploadedDocPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentName: window.location.href.includes('origin=fileRead')
        ? this.props.dashboard?.fileRead?.data?.result?.orgFileName
        : this.props.uploadDoc.docData.data.result.orgFileName,
      clientName: '',
      dueDate: '',
      selectedOption: null,
      selectedPermission: null,
      message: '',
      go: false,
      formsInvalid: true,
      date: new Date(),
      tags: [],
      showError: false,
      docType: '',
      isLoading: false,
      fileId: '',
      isClusteredButtonClicked: false,
      origin: window.location.href.includes('origin=fileRead')
        ? 'fileRead'
        : null,
      errors: {
        documentName: '',
        clientName: '',
        dueDate: '',
        selectedPermission: '',
        selectedOption: '',
        docType: '',
      },
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    let url = window.location.pathname;
    let querry = window.location?.search;
    let fullUrl = url + querry;
    if (!prevProps.isUploadedDocLoading && this.props.isUploadedDocLoading) {
      this.setState({ isLoading: true });
    } else if (
      this.props.uploadedDoc.clusterData &&
      prevProps.isUploadedDocLoading &&
      !this.props.isUploadedDocLoading
    ) {
      // this.props.similarParaRequest({fileId:this.state.fileId})
      // this.props.similarDocsRequest({fileId:this.state.fileId})

      // this.setState({ isLoading: false })
      handleSuccess(this.props.uploadedDoc.clusterData.message);
      this.props.history.push('/clustered-result', { from: fullUrl });
    } else if (
      !this.props.uploadedDoc.clusterData &&
      prevProps.isUploadedDocLoading &&
      !this.props.isUploadedDocLoading &&
      this.props.error
    ) {
      toast.error(this.props.error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({ isLoading: false });
    }
  }
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }
  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    this.setState({ tags: newTags });
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    let errors = this.state.errors;
    errors.selectedOption = '';
    this.setState({ errors });
  };
  handlePermissionChange = (selectedPermission) => {
    if (selectedPermission.value === 'private') {
      handleSuccess('Private Documents Will not be Accessible to Other Teams!');
    } else if (selectedPermission.value === 'public') {
      handleSuccess('Public Document Can Be Accessed by Other Teams!');
    }
    this.setState({ selectedPermission });
    let errors = this.state.errors;
    errors.selectedPermission = '';
    this.setState({ errors });
  };
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let errors = this.state.errors;
    if (value && value !== '') {
      errors[name] = '';
    }
    this.setState({ errors });
    this.setState({ [name]: value });
  };
  getErrors = () => {
    const {
      documentName,
      clientName,
      docType,
      dueDate,
      selectedOption,
      selectedPermission,
    } = this.state;
    let errors = this.state.errors;
    // if (documentName === '') {
    //     errors.documentName = "Document name is Required!"
    // }
    // if (clientName === '') {
    //     errors.clientName = "Client name is Required!"
    // }
    // if (dueDate === '') {
    //     errors.dueDate = "Due date is Required!"
    // }
    if (docType === '') {
      errors.docType = 'Document type is Required';
    }
    // if (!selectedPermission) {
    //     errors.selectedPermission = "Permission is Required!"
    // }
    // if (!selectedOption) {
    //     errors.selectedOption = "Assigned to is Required!"
    // }
    this.setState({ errors: errors });
    let errorCount = 0;
    for (const property in this.state.errors) {
      if (this.state.errors[property].length > 1) {
        errorCount++;
      }
    }
    this.setState({ errors: errors });
    return errorCount > 0;
  };
  onCancel() {
    let url = this.props.history.location.state?.from;
    if (url) {
      this.props.history.push(url);
    } else {
      this.props.history.push('/dashboard');
    }

    // this.props.history.push('/dashboard')
  }
  onSubmit() {
    const isFormInvalid = this.getErrors();
    const data = this.state.origin
      ? this.props.dashboard.fileRead.data.result
      : this.props.uploadDoc.docData.data.result;
    if (!isFormInvalid) {
      const paramsObj = {
        fileName: this.state.documentName,
        fileId: data.fileId,
        fileVersion: data.version,
        fileVersionId: data.fileVersionId,
        filePath: data.filePath,
        version: data.version,
        minor: this.state.origin ? data.minor.toString() : '0',
        docType: this.state.docType ? this.state.docType.toString() : 1,
        client: this.state.clientName,
        dueDate: this.state.dueDate,
        permission: this.state.selectedPermission?.value,
        assignedTo: this.state.selectedOption?.value,
      };
      this.setState({ fileId: data.fileId });
      this.props.clusterDataRequest(
        paramsObj,
        { fileId: data.fileId },
        { fileId: data.fileId }
      );
      const similarParaDocsRequest = {
        fileId: data.fileId,
      };

      // this.props.similarParaRequest(similarParaDocsRequest)
      // this.props.similarDocsRequest(similarParaDocsRequest)
      this.setState({ isClusteredButtonClicked: true });
      this.setState({ go: true });
    }
  }
  onChange = (date) => this.setState({ date });

  render() {
    const data = this.state.origin
      ? this.props.dashboard &&
        this.props.dashboard.fileRead &&
        this.props.dashboard.fileRead.data
      : this.props.uploadDoc &&
        this.props.uploadDoc.docData &&
        this.props.uploadDoc.docData.data;
    const {
      documentName,
      clientName,
      dueDate,
      selectedOption,
      tags,
      message,
      selectedPermission,
      errors,
      isLoading,
    } = this.state;
    const today = getFullDate();
    const permissionOptions =
      data &&
      data.visibility &&
      data.visibility.map((item) => {
        return {
          value: item.key,
          label: item.value,
        };
      });
    const assignedToOptions =
      data &&
      data.teamMembers &&
      data.teamMembers.map((item) => {
        return {
          value: item.userId,
          label: item.name,
        };
      });
    return (
      <div className="thin_custom_scrollbar">
        <div className={styles.doc_preview_container}>
          <p className={styles.doc_preview_title}>Document Preview</p>
          <div className={styles.doc_preview_form_container}>
            <form>
              <p className={commonStyles.common_form_label}>DOCUMENT NAME</p>
              <input
                className={`form-control ${commonStyles.common_form_text_field} ${styles.doc_preview_text_field}`}
                type="text"
                name="documentName"
                value={documentName}
                onChange={(event) => this.handleUserInput(event)}
              ></input>
              {errors.documentName.length > 0 && (
                <ErrorText text={errors.documentName} />
              )}
              <p className={commonStyles.common_form_label}>CLIENT NAME</p>
              <input
                className={`form-control ${commonStyles.common_form_text_field} ${styles.doc_preview_text_field}`}
                type="text"
                name="clientName"
                value={clientName}
                onChange={(event) => this.handleUserInput(event)}
              ></input>
              {errors.clientName.length > 0 && (
                <ErrorText text={errors.clientName} />
              )}
              <div className={styles.doc_preview_two_column_fields}>
                <div className={styles.doc_preview_two_column_fields_width}>
                  <p className={commonStyles.common_form_label}>DUE DATE</p>
                  <input
                    className={`form-control ${commonStyles.common_form_text_field} ${styles.small_font} ${styles.calander}`}
                    type="date"
                    name="dueDate"
                    value={dueDate}
                    min={today}
                    onChange={(event) => this.handleUserInput(event)}
                  ></input>
                  {errors.dueDate.length > 0 && (
                    <ErrorText text={errors.dueDate} />
                  )}
                </div>
                <div className={styles.doc_preview_two_column_fields_width}>
                  <p className={commonStyles.common_form_label}>PERMISSIONS</p>
                  <Select
                    name="permission"
                    value={selectedPermission}
                    onChange={this.handlePermissionChange}
                    options={permissionOptions}
                  />
                  {errors.selectedPermission.length > 0 && (
                    <ErrorText text={errors.selectedPermission} />
                  )}
                </div>
              </div>
              <p className={commonStyles.common_form_label}>DOCUMENT TYPE</p>
              <div className={styles.doc_type_radios}>
                {data &&
                  data.documentType &&
                  data.documentType.map((type, i) => {
                    return (
                      <div key={type.key}>
                        <input
                          type="radio"
                          value={type.key}
                          id={type.key}
                          onChange={(event) => this.handleUserInput(event)}
                          name="docType"
                        />{' '}
                        <label htmlFor={type.key}>{type.value}</label>
                      </div>
                    );
                  })}
                {errors.docType.length > 0 && (
                  <ErrorText text={errors.docType} />
                )}
              </div>
              <p className={commonStyles.common_form_label}>ASSIGNED TO</p>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={assignedToOptions}
                placeholder="Assigned to"
              />
              {errors.selectedOption.length > 0 && (
                <ErrorText text={errors.selectedOption} />
              )}
              <p className={commonStyles.common_form_label}>
                ADD TAGS (OPTIONAL)
              </p>
              <ReactTags
                tags={tags}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                handleDrag={this.handleDrag}
                delimiters={delimiters}
                classNames={{
                  tags: 'tagsClass',
                  tagInput: 'tagInputClass',
                  tagInputField: 'tagInputFieldClass',
                  selected: 'selectedClass',
                  tag: 'tagClass',
                  remove: 'removeClass',
                  suggestions: 'suggestionsClass',
                  activeSuggestion: 'activeSuggestionClass',
                }}
              />
              <p className={commonStyles.common_form_label}>
                MESSAGE (OPTIONAL)
              </p>
              <input
                className={`form-control ${commonStyles.common_form_text_field} ${styles.doc_preview_last_field_bottom_spacing}`}
                type="text"
                name="message"
                value={message}
                placeholder="Enter message here"
                onChange={(event) => this.handleUserInput(event)}
              ></input>
            </form>
          </div>
        </div>
        <div className={styles.doc_prevew_form_submit_container}>
          <div className={styles.doc_prevew_form_submit_section}>
            <button
              type="button"
              onClick={() => this.onCancel()}
              className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
            >
              CANCEL
            </button>
            <button
              type="button"
              onClick={() => this.onSubmit()}
              disabled={isLoading}
              className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
            >
              {isLoading && <i className="fas fa-spinner fa-spin"></i>}
              CLUSTER
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uploadDoc: state.uploadDoc,
    uploadedDoc: state.uploadedDoc,
    isUploadedDocLoading: state.uploadedDoc.isLoading,
    error: state.uploadedDoc.error,
    dashboard: state.dashboard,
    isSimilarDocsLoading: state.uploadedDoc.isSimilarDocsLoading,
    isSimilarParasLoading: state.uploadedDoc.isSimilarParasLoading,
    similarParasData: state.uploadedDoc.similarParas?.byTag,
    similarDocsData: state.uploadedDoc.similarDocs,
  };
};
const mapDispatchToProps = {
  clusterDataRequest: uploadedDoc.clusterDataRequest,
  similarParaRequest: uploadedDoc.similarParaRequest,
  similarDocsRequest: uploadedDoc.similarDocsRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadedDocPreview);
