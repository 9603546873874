import React, { Component } from 'react'
import { IoIosArrowForward, IoMdArrowDropup } from 'react-icons/io'
import { IconContext } from 'react-icons'
import _ from 'lodash'

import CheckboxListFilter from './../../../checkbox-list-filter/CheckboxListFilter'
import SearchBar from './search-bar/SearchBar'
import {
  documents,
  filter_list,
} from './../../../../json-data/searchResults.json'
import CommonStyles from './../../../../shared/Common.module.css'
import styles from './Filter.module.css'
import CheckboxList from '../../checkbox-list/CheckboxList'

export default class Filter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tagSelected: 0,
      searchTerm: null,
      filteredList: null,
      showSelected: false,
      areFiltersSelected: this.props.allFilters ? true : false,
    }

    this.checkboxListRef = React.createRef()
  }

  changeTagSelected = index => {
    this.setState({
      tagSelected: index,
      filteredList: null,
    })
  }

  handleSearchChange = searchItems => {
    this.setState({ searchItems }, () => {
      this.getFilteredList(searchItems)
    })
  }
  getFilteredList = searchItems => {
    // console.info('search term', searchItems === null)
    const { filters } = this.props
    const { tagSelected } = this.state
    // console.info('filters', filters, 'tagSelected', tagSelected)

    if (searchItems === '') {
      this.setState({ filteredList: null })
    } else {
      let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      let filteredList = filters[tagSelected]?.children.filter(member => {
        return member.toLowerCase().search(subStr.toLowerCase()) !== -1
      })
      this.setState({ filteredList })
    }
  }

  toggleShowSelected = e => {
    // console.info(e.target.checked)
    this.setState({
      showSelected: e.target.checked,
    })
  }

  clearAll = () => {
    this.checkboxListRef.current.resetState()
    this.props.clearFilters()
    this.setState({
      areFiltersSelected: false,
    })
  }

  /* getting filters from "CheckboxListFilter" and applying filter on provided data */
  applyFilters = () => {
    /*
     * saving filters to store as to get filters when back to DC2 page from "RelatedSearchesPage"
     */
    // this.checkboxListRef.current.saveFiltersToStore()

    const selectedFilters = this.checkboxListRef.current.getState()
    // console.info('selectedFilters: ', selectedFilters)

    const allDocumentData = this.props.allDocumentData

    if (!allDocumentData) return

    let filteredAllDocumentValues = []

    for (let [key, filters] of Object.entries(selectedFilters)) {
      // console.info('key', key, 'filters', filters)

      if (key === 0 + '') {
        if (filters.length)
          filters.forEach(element => {
            allDocumentData.forEach(document => {
              if (document.filterValues.fileType === element) {
                filteredAllDocumentValues.push(document)
              }
            })
          })
      }

      if (key === 1 + '') {
        if (filters.length)
          filters.forEach(element => {
            allDocumentData.forEach(document => {
              if (document.filterValues.clientName === element) {
                filteredAllDocumentValues.push(document)
              }
            })
          })
      }

      if (key === 2 + '') {
        if (filters.length)
          filters.forEach(element => {
            allDocumentData.forEach(document => {
              document.filterValues.issue.forEach(issue => {
                if (issue === element) {
                  filteredAllDocumentValues.push(document)
                }
              })
            })
          })
      }

      if (key === 3 + '') {
        if (filters.length)
          filters.forEach(element => {
            allDocumentData.forEach(document => {
              document.filterValues.sections.forEach(section => {
                if (section === element) {
                  filteredAllDocumentValues.push(document)
                }
              })
            })
          })
      }

      if (key === 4 + '') {
        if (filters.length)
          filters.forEach(element => {
            allDocumentData.forEach(document => {
              if (document.filterValues.court === element) {
                filteredAllDocumentValues.push(document)
              }
            })
          })
      }

      if (key === 5 + '') {
        if (filters.length)
          filters.forEach(element => {
            allDocumentData.forEach(document => {
              if (document.filterValues.jurisdiction === element) {
                filteredAllDocumentValues.push(document)
              }
            })
          })
      }
    }

    const uniqueAllDocumentFilteredValues = _.uniqBy(
      filteredAllDocumentValues,
      'fileId'
    )
    // console.info('filteredDocumentValues: ', filteredDocumentValues)
    // console.info('uniqueAllDocumentFilteredValues', uniqueAllDocumentFilteredValues)

    this.props.applyFilters(uniqueAllDocumentFilteredValues)
  }
  // areFiltersSelected = areFiltersSelected => {
  //   this.setState({
  //     areFiltersSelected,
  //   })
  // }

  /* contains all the filters */
  getCheckboxState = () => {
    return this.checkboxListRef.current.getState()
  }
  render() {
    const {
      tagSelected,
      searchTerm,
      filteredList,
      showSelected,
      areFiltersSelected,
    } = this.state
    const { filters } = this.props

    const allFilters = this.props.allFilters

    // console.info('allFilters: ', allFilters)

    return (
      <div className={`${CommonStyles.card_layout} ${styles.root}`}>
        {/* Top */}
        <div className={styles.top}>
          <div className={styles.search_bar_container}>
            <div className={styles.search_bar_background}>
              <div className={styles.search_bar_wrapper}>
                <SearchBar
                  iconColor={'#C4C4C4'}
                  iconSize={'14px'}
                  fontSize={'12px'}
                  fontColor={'#000'}
                  placeholder={'Search'}
                  handleSearchChange={searchItems => {
                    this.handleSearchChange(searchItems)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.filters}>
          <div className={styles.bottom}>
            {/* Bottom Left */}

            <div className={styles.bottom_left}>
              {/* Bottom Left Top */}
              <div className={styles.bottom_left_top}>
                {filters?.map(({ title }, index) => (
                  <div
                    className={styles.type_container}
                    style={
                      index === tagSelected
                        ? {
                            backgroundColor: '#11256d',
                            color: '#ffffff',
                          }
                        : { backgroundColor: '#ffffff', color: '#9A9A9A' }
                    }
                    onClick={e => this.changeTagSelected(index)}
                  >
                    <div className={styles.tag}>{title}</div>
                    <div className={styles.icon_container}>
                      <IconContext.Provider
                        value={
                          index === tagSelected
                            ? { color: '#ffffff', size: '21px' }
                            : { color: '#9A9A9A', size: '21px' }
                        }
                      >
                        <div>
                          <IoIosArrowForward />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Bottom Right  */}
            <div className={styles.bottom_right}>
              <CheckboxListFilter
                data={filters}
                tagSelected={tagSelected}
                filteredList={filteredList}
                searchTerm={searchTerm}
                showSelected={showSelected}
                allFilters={allFilters}
                width={'12px'}
                height={'12px'}
                ref={this.checkboxListRef}
              />
            </div>
          </div>
        </div>

        <div className={styles.filter_options}>
          <div className={styles.switch_wrapper}>
            <div className={styles.switch_container}>
              <label class={styles.switch}>
                <input type="checkbox" onChange={this.toggleShowSelected} />
                <span class={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
            <div
              className={styles.bottom_text}
              style={showSelected ? { color: '#11256d' } : {}}
            >
              SELECTION
            </div>
          </div>
          <div className={styles.button_wrapper}>
            <div
              className={styles.clear_all_btn}
              onClick={this.clearAll}
              style={
                areFiltersSelected
                  ? { backgroundColor: '#11256d', color: '#fff' }
                  : {}
              }
            >
              CLEAR ALL
            </div>
            <div
              className={styles.advanced_search_btn}
              onClick={this.applyFilters}
            >
              APPLY
            </div>
          </div>
        </div>
      </div>
    )
  }
}
