import React, { Component } from "react";
import styles from "./TicketList.module.css";
import DeleteTicket from "./DeleteTicket";
import UpdateTicket from "./update-ticket/UpdateTicket";

class TicketList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAction: null,
      filteredTicketsData: [],
      isAscending: 1,
      fieldToSort: "",
      isCloseTicketModalOpen: false,
      isUpdateTicketModalOpen: false,
      ticketToUpdate: "",
      ticketToClose: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchItem !== this.props.searchItem) {
      this.getFilteredDocuments();
    }
  }
  componentDidMount() {
    this.setState({ membersData: [...this.props.documents] }, () => {
      this.getFilteredDocuments();
    });
  }

  getFilteredDocuments = () => {
    let searchItems = this.props.searchItem;
    let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    let filteredDocuments = this.props.documents.filter((document) => {
      return document.subject.toLowerCase().search(subStr.toLowerCase()) !== -1;
    });
    this.setState({ filteredTicketsData: filteredDocuments });
  };
  openActionPopup = (fileId) => {
    this.setState({ selectedAction: fileId });
  };
  closeActionPopup = () => {
    if (this.state.selectedAction) {
      this.setState({ selectedAction: null });
    }
  };
  handleDelete = (id) => {
    this.setState({ isCloseTicketModalOpen: true, ticketToClose: id });
  };

  onDeleteDocumentClose = () => {
    this.setState({ isCloseTicketModalOpen: false });
  };

  handleUpdateTicket = (id) => {
    const ticketToEdit = this.props.documents.filter((ticket) => {
      return ticket.id === id;
    });
    this.setState({
      isUpdateTicketModalOpen: true,
      ticketToUpdate: ticketToEdit,
    });
  };

  onCloseTicket = () => {
    const paramsObj = {
      id: this.state.ticketToClose,
    };
    this.props.onCloseTicket(paramsObj);
    this.setState({ isCloseTicketModalOpen: false });
  };

  onCloseTicketClose = () => {
    this.setState({ isCloseTicketModalOpen: false });
  };
  onCloseUpdateTicket = () => {
    this.setState({ isUpdateTicketModalOpen: false });
  };
  render() {
    const {
      filteredTicketsData,
      isCloseTicketModalOpen,
      isUpdateTicketModalOpen,
      memberToDelete,
    } = this.state;
    return (
      <div
        onClick={() => this.closeActionPopup()}
        className={"thin_custom_scrollbar"}
      >
        <div className={styles.all_documents_list_container}>
          <table className={styles.all_documents_list_table}>
            <thead>
              <tr className={styles.all_documents_list_row_label}>
                <th>
                  {" "}
                  <label>TICKET ID#</label>
                </th>
                <th>
                  <label>SUBJECT</label>
                </th>
                <th>
                  <label>STATUS</label>
                </th>
                <th>
                  <label>DUE DATE</label>
                </th>
                <th>
                  <label>PRIORITY</label>
                </th>
                <th>
                  <label>ASSIGNED TO</label>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredTicketsData.map((data, index) => {
                let date = new Date(data.due_by)
                const formattedDate = date.toLocaleDateString('en-GB', {
                  day: 'numeric', month: 'short', year: 'numeric'
                }).replace(/ /g, '-');
                let id = data.id;
                let subject = data.subject;
                let status = data.status;
                let description_text = data.description_text;
                let due_by = formattedDate;
                let priority = data.priority;
                let priorityColor = data.priorityCode;
                let responder_id = data.responder_id
                  ? data.responder_id
                  : "Precily Inc";

                const selectedFileData = {
                  id: id,
                  subject: subject,
                  status: status,
                  description: description_text,
                  due: due_by,
                  priority: priority,
                  assigned: responder_id || null,
                };

                return (
                  <tr
                    className={styles.all_documents_list_row_content}
                    key={index}
                  >
                    <td>
                      <span className={styles.ticket_name}>{id}</span>
                    </td>
                    <td style={{ width: "300px" }}>
                      <span className={styles.ticket_name}>{subject}</span>
                    </td>
                    <td>
                      <span className={status === "Closed" ? styles.ticket_status_closed :styles.ticket_status}>{status}</span>
                    </td>
                    <td>
                      <span className={styles.ticket_name}>{due_by}</span>
                    </td>
                    <td>
                      <span
                        className={styles.doc_priority}
                        style={{
                          backgroundColor: "#f5fafa",
                          color: priorityColor,
                        }}
                      >
                        {priority}
                      </span>
                    </td>
                    <td>
                      <span className={styles.ticket_name}>{responder_id}</span>
                    </td>
                    <td style={{ position: "relative" }}>
                      {status !== "Closed" ? (
                        <i
                          onClick={() => this.openActionPopup(id)}
                          className="fas fa-ellipsis-v"
                        ></i>
                      ) : (
                        <i
                          onClick={() => this.openActionPopup(id)}
                          className="fas fa-ellipsis-v"
                          style={{ color: "#e8e8e8", cursor: "default" }}
                        ></i>
                      )}

                      {this.state.selectedAction === id && status !== "Closed" && (
                        <div className={styles.action_popup}>
                          <p
                            onClick={() => {
                              this.handleUpdateTicket(id);
                            }}
                            className={styles.actionText}
                          >
                            Update
                          </p>
                          <p
                            onClick={() => {
                              this.handleDelete(id);
                            }}
                            className={styles.actionText}
                          >
                            Close
                          </p>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {isCloseTicketModalOpen && (
          <DeleteTicket
            memberToDelete={memberToDelete}
            closeHandler={this.onCloseTicketClose}
            onCloseTicket={this.onCloseTicket}
          ></DeleteTicket>
        )}
        {isUpdateTicketModalOpen && (
          <UpdateTicket
            ticket={this.state.ticketToUpdate}
            closeHandler={this.onCloseUpdateTicket}
            onCloseTicket={this.onCloseTicket}
          ></UpdateTicket>
        )}
      </div>
    );
  }
}
export default TicketList;
