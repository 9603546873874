import React, { Component } from "react";
import styles from "./AllDocuments.module.css";
import Loader from "../../shared/loader/Loader";
// import Sidebar from "../../shared/sidebar/Sidebar";
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import commonStyles from "../../shared/Common.module.css";
import FilterDocuments from "../../components/all-documents/filter-documents/FilterDocuments";
import AllDocumentsList from "../../components/all-documents/all-documents-list/AllDocumentsList";
import UploadDoc from "../../components/dashboard/upload-doc/UploadDoc";
import AllDocumentsDetails from "../../components/all-documents/all-documents-details/AllDocumentsDetails";
import * as allDocuments from "../../store/actions/allDocumentsActions";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { handleSuccess } from "../../shared/HandleMessages";

import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'
import RightBar from '../../screens/workflow-management/RightBar/RightBar'
const FROM = "/all-documents";
class AllDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documenUploadBtnClicked: false,
      togglePopup: false,
      selectedFileData: {},
      loading: false,
      searchItem: "",
      filterBtnClicked: false,
      AllDocumentData: [],
      isFilterActive:false,
    };
  }
  componentDidMount() {
    this.props.listDocumentsRequest();
    if (this.props.openFileJourney) {
      this.props.fileJourneyRequest({ fileId: this.props.fileData?.fileId });
    }
  }
  componentWillUnmount() {
    this.props.clearFileId();
  }
  onTogglePopup = (selectedFileData) => {
    if (!this.state.togglePopup) {
      this.setState({ selectedFileData: selectedFileData });
      const paramsObj = {
        fileId: selectedFileData.fileId,
      };
      this.props.fileJourneyRequest(paramsObj);
    }
    this.setState({ togglePopup: !this.state.togglePopup });
  };
  uploadDocument = () => {
    this.setState({
      documenUploadBtnClicked: true,
    });
  };
  onUploadClose = () => {
    this.setState({
      documenUploadBtnClicked: false,
    });
  };
  onDeleteDocument = (param) => {
    this.props.deleteDocumentRequest(param);
  };
  renderTabList = (AllDocumentData) => {
    if (AllDocumentData.length > 0) {
      return AllDocumentData.map((doc) => {
        let docStatus = doc.title;
        let docCount = doc.count;
        return (
          <Tab
            key={docStatus}
            eventKey={docStatus}
            title={docStatus + " (" + docCount + ")"}
          >
            <AllDocumentsList
              documents={doc.documents}
              onDeleteDocument={this.onDeleteDocument}
              searchItem={this.state.searchItem}
              openPopup={this.onTogglePopup}
            ></AllDocumentsList>
          </Tab>
        );
      });
    }
  };
  renderDocCount = (AllDocumentData) => {
    return AllDocumentData.map((data) => {
      return (
        <div key={data.title} className={styles.doc_status_row}>
          <div className={styles.circle}>{data.count} </div>
          <br></br>
          <div className={styles.doc_status}>
            {data.title.toLowerCase() === "all" ? "All Docs" : data.title}
          </div>
        </div>
      );
    });
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.isDeleteDocumentLoading &&
      !this.props.isDeleteDocumentLoading &&
      this.props.deleteDocumentData
    ) {
      handleSuccess(this.props.deleteDocumentData.message);
      this.props.listDocumentsRequest();
    }
    if (
      prevProps.isListDocumentsLoading &&
      !this.props.isListDocumentsLoading &&
      this.props.listDocumentsData?.data
    ) {
      this.setState(
        {
          AllDocumentData: [...this.props.listDocumentsData.data.finalResult],
          filterBtnClicked: false,
        },
      );
      // if(this.props.listDocumentsData?.data?.isFilter){
        this.setState({isFilterActive:this.props.listDocumentsData.data?.isFilter})
      // }
    }
    if (
      prevProps.isListDocumentsLoading &&
      !this.props.isListDocumentsLoading
    ) {
      if (this.props.openFileJourney) {
        this.setState({
          togglePopup: true,
          selectedFileData: this.props.fileData,
        });
      }
    }
  }
  onHandleSearchChange = (searchItem) => {
    this.setState({ searchItem });
  };
  openFilterPopup = () => {
    this.setState({ filterBtnClicked: true });
  };
  onFilterClose = () => {
    this.setState({ filterBtnClicked: false });
  };
  getDocTypes = () => {
    // const tagOptions = this.props.listDocumentsData?.data?.docType.map((item) => {
    //   return {
    //     value: item.key,
    //     label: item.value,
    //   };
    // });
    const tagOptions = [
      { value: 1, label: "Show Cause Notice (SCN)" },
      { value: 2, label: "Submission/ Legal Opinions" },
      { value: 3, label: "Case Laws" },
      { value: 4, label: "News Alert" },
    ];
    return tagOptions;
  };
  allDocumentsFilterRequest = (paramsObj) => {
    this.props.listDocumentsRequest(paramsObj);
  };
  clearFilter = () => {
    this.props.listDocumentsRequest();
  }
  render() {
    const {
      selectedFileData,
      documenUploadBtnClicked,
      togglePopup,
      filterBtnClicked,
      AllDocumentData,
      isFilterActive
    } = this.state;
    // let AllDocumentData = []
    let docTypes = [];
    if (this.props.listDocumentsData && !this.props.isListDocumentsLoading) {
      // AllDocumentData = [...this.props.listDocumentsData.data.finalResult];
      docTypes = this.getDocTypes();
    }

    return (
      <React.Fragment>
        {/* <NavigationBar
          loading={this.props.isListDocumentsLoading}
          onHeaderSearchChange={this.onHandleSearchChange}
        ></NavigationBar> */}
        <NavBar></NavBar>
        <Sidebar></Sidebar>
        <RightBar></RightBar>
        {togglePopup && (
          <div className={styles.popup_overlay}>
            <div className={styles.popup_container}>
              {this.props.fileJourneyData && (
                <AllDocumentsDetails
                  history={this.props.history}
                  from={FROM}
                  selectedFileData={selectedFileData}
                  fileJourneyData={this.props.fileJourneyData}
                  onPopupClose={this.onTogglePopup}
                ></AllDocumentsDetails>
              )}
            </div>
          </div>
        )}
        <div className={`${commonStyles.commom_wrapper} ${styles.allDocumentsRoot}`}>
          <div className={styles.heading_row}>
            <div>
              <div className={commonStyles.commom_title}>All Documents</div>
            </div>
            <div>
              {AllDocumentData.length > 0 &&
                this.renderDocCount(AllDocumentData)}
            </div>
          </div>
          <div className={styles.all_documents_content_wrapper}>
            <div className={`card ${commonStyles.card_layout}`}>
              <div className={`card-body ${styles.all_documents_card_body}`}>
                {/* !this.props.isListDocumentsLoading this condition is required otherwise rendering will take place before the new response and old records will be shown */}
                {AllDocumentData.length > 0 &&
                !this.props.isListDocumentsLoading ? (
                  <div className={styles.all_documents_tab_container}>
                    <Tabs id="team-tab">
                      {this.renderTabList(AllDocumentData)}
                    </Tabs>
                    <div className={styles.all_documents_btn_container}>
                     
                    {!isFilterActive &&  <i
                        onClick={this.openFilterPopup}
                        className={`fas fa-filter ${styles.filter_icon}`}
                      ></i>}
                       {isFilterActive && 
                      //   <i
                      //   onClick={this.clearFilter}
                      //   className={`fas fa-user-slash ${styles.filter_icon}`}
                      // ></i>
                      <img style={{width:'16px',marginRight:'16px', cursor:'pointer'}} onClick={this.clearFilter} src={require('../../assets/filter.png')} alt="" />
                      
                      }
                    {/* {isFilterActive &&   <span className={styles.filter_active}></span>} */}
                      <button
                        className={`${styles.upload_doc_btn} ${styles.all_document_btn} ${commonStyles.btn_ripple}`}
                        type="button"
                        onClick={this.uploadDocument}
                      >
                        <i
                          className={`fas fa-plus-circle ${styles.btn_icon}`}
                        ></i>
                        Upload Document
                      </button>
                    </div>
                  </div>
                ) : (
                  <Loader></Loader>
                )}
                <div></div>
              </div>
            </div>
          </div>
        </div>
        {documenUploadBtnClicked && (
          <UploadDoc
            history={this.props.history}
            closeHandler={this.onUploadClose}
            from={FROM}
          ></UploadDoc>
        )}
        {filterBtnClicked && (
          <FilterDocuments
            history={this.props.history}
            allDocumentsFilterRequest={this.allDocumentsFilterRequest}
            docTypes={docTypes}
            closeHandler={this.onFilterClose}
          ></FilterDocuments>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    listDocumentsData: state.allDocuments.listDocumentsData,
    fileJourneyData: state.allDocuments.fileJourneyData,
    isListDocumentsLoading: state.allDocuments.isLoading,
    isDeleteDocumentLoading: state.allDocuments.isDeleteDocumentLoading,
    deleteDocumentData: state.allDocuments.deleteDocumentData,
    openFileJourney: state.allDocuments.openFileJourney,
    fileData: state.allDocuments.fileData,
  };
};
const mapDispatchToProps = {
  listDocumentsRequest: allDocuments.listDocumentsRequest,
  fileJourneyRequest: allDocuments.fileJourneyRequest,
  deleteDocumentRequest: allDocuments.deleteDocumentRequest,
  clearFileId: allDocuments.clearFileId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AllDocuments);
