import React from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReactPaginate from 'react-paginate';
import styles from './DataCatalog.module.css';
import commonStyles from '../../shared/Common.module.css';
import RecentFolders from '../../components/data-catalog/recent-folders/RecentFolders';
import RecentDocuments from '../../components/data-catalog/recent-documents/RecentDocuments';
import AllFolders from '../../components/data-catalog/all-folders/AllFolders';
import SearchBarAndDropDown from '../../components/data-catalog/search_bar_and_dropdown/SearchBarAndDropDown';
import RecentSearches from '../../components/data-catalog/recent-searches/RecentSearches';

import green_document from '../../../src/assets/green_document.svg';
import green_search from '../../../src/assets/green_search.svg';
import green_folder from '../../../src/assets/green_folder.svg';
import plus_circle from '../../../src/assets/plus_circle.svg';

import green_folder_small from '../../../src/assets/green_folder_small.svg';
import green_query from '../../../src/assets/green_query.svg';
import MasterListSVG from '../../../src/assets/master_list_icon.svg';
import * as catalogLandingPage from '../../store/actions/catalogLandingPageActions';
import * as graphData from '../../store/actions/graphDataAction';
import { connect } from 'react-redux';

import connector_dark_blue from '../../../src/assets/connector.svg';
import AddSourceModal from '../../components/data-catalog/add-source-modal';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';
import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar';
import NavBar from '../../screens/workflow-management/NavBar/NavBar';
import RightBar from '../../screens/workflow-management/RightBar/RightBar';

import * as workFlowManagementLandingPage from '../../store/actions/workFlowManagementLandingPageActions';
import * as catalogSearchResults from '../../store/actions/catalogSearchResultsAction';
import NewProjectModal from '../../components/all-projects/modals/new-project/NewProjectModal';
import DataSourceGraph from './DataSourceGraph';
// import * as catalogSearchResultsAction from '../../store/actions/catalogSearchResultsAction';
// import Sidebar from '../../shared/sidebar/Sidebar'
// import NavigationBar from '../../shared/navigation-bar/NavigationBar';
// import recentSearch from '../../json-data/recentSearches.json';
// import folders from '../../json-data/allFolders.json';
// import recentFolders from '../../json-data/recentFolders.json';
// import recentDocuments from '../../json-data/recentDocuments.json';
// import connector from '../../../src/assets/connector.svg';
// import green_folder_small from '../../../src/assets/green_folder_small'
// import BarChart from '../../components/data-catalog/graphs/BarChart';
// import green_folder_small from '../../../src/assets/green_folder_small'
// import Loader from '../../shared/loader/Loader';
// import { formatDate } from '../../shared/formatDate';
// import * as dashboardV2Page from '../../store/actions/dashboard-v2Action';
// import { list } from './../../json-data/advancedSearch.json';
// import EmailModal from '../dashboard-v2/modal/EmailModal';
import { handleSuccess, handleError } from '../../shared/HandleMessages';
import HC_more from 'highcharts/highcharts-more';
// import { act } from 'react-dom/test-utils';
// import HC_exporting from 'highcharts/modules/exporting';
// import HC_access from 'highcharts/modules/accessibility';
// HC_access(Highcharts);
// HC_exporting(Highcharts);
HC_more(Highcharts);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ALL_FOLDERS_LIMIT = 10;
const RECENT_SEARCH_LIMIT = 5;

class DataCatalog extends React.Component {
  searchBarAndDropdownRef = React.createRef();
  PER_PAGE = 6;
  recentDocumentsData = [];

  state = {
    recentDocumentPaginationData: [],
    totalNumberOfPages: 1,
    searchTerm: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      activeTab: 'DataSource',
      graphData: null,
      prevGraphData: null,
      firstGraphData: null,
      dropboxUri:
        'https://precilyclient.azurewebsites.net/data-catalog?name=dropbox',
      oneDriveUri: 'https://precilyclient.azurewebsites.net/data-catalog',
      showNewProjectModal: false,
    };
  }

  handleSearch = (searchItems) => {
    this.setState(
      {
        searchTerm: searchItems,
      },
      () => {
        this.props.catalogSearchTerm({ searchTerm: searchItems });
        const params = {
          query: searchItems,
          source: '',
          filters: {},
        };
        this.props.catalogSearchResultRequest(params);
        this.props.saveFilteredDocuments(null);
        this.props.saveFilters(null);
        this.props.saveSearchTerm(searchItems);

        this.props.history.push('/data-catalog-2', {
          searchItems: searchItems,
        });
      }
    );
  };
  handleViewAll = () => {
    this.props.history.push({
      pathname: '/all-folders-tree',
      search: '?source=data-catalog',
    });
  };
  componentDidMount() {
    this.props.saveFilters(null);
    this.props.saveFilteredDocuments(null);
    this.props.saveFiltersWorkflowPage(null);
    this.props.saveFilteredDocumentsWorkflowPage(null);
    this.props.saveSelectedSources(null);
    this.props.dispatchAdvancedSearchFilters(null);

    this.props.workflowManagementDropdown({ adminId: this.props.userId });
    this.props.catalogRecentFolders({ limit: ALL_FOLDERS_LIMIT });
    this.props.catalogRecentDocuments({ limit: RECENT_SEARCH_LIMIT });
    this.props.catalogAllSource();
    this.props.workflowManagementProject({ createdBy: this.props.userId });
    const paramObject = {};
    this.props.catalogAdvancedSearch(paramObject);
    this.props.tagsGraphData();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.iscatalogRecentDocumentsLoading &&
      prevProps.iscatalogRecentDocumentsLoading &&
      this.props.recentDocumentsData
    ) {
      //  For ReactPaginate, calc page range
      this.recentDocumentsData = this.props.recentDocumentsData.recentDocuments;
      const totalNumberOfPages =
        this.recentDocumentsData.length / this.PER_PAGE;
      this.setState(
        {
          totalNumberOfPages,
        },
        () => {
          this.handlePaginationClick();
        }
      );
    }
    if (!this.props.isLoading && prevProps.isLoading && this.props.graphInfo) {
      if (this.props.graphInfo.graphData?.graph.graphType === 'DataSource') {
        // console.log(this.props.graphInfo.graphData?.graph);
        // let tempObj = {};
        let outerColor = ['#fb8500', '#EE6123', '#FA003F', '#1F271B'];
        let tempArr = [];
        let data = this.props.graphInfo.graphData?.graph?.series;
        data &&
          data.forEach((obj, i) => {
            let temp = {};
            temp.name = obj.name;
            temp.data = obj.data;
            // if (temp.name === 'SCN') {
            //   temp.color = '#F50072';
            // }
            temp.color = outerColor[i];
            // temp.marker = {
            //   fillOpacity: 0.9,
            // };
            tempArr.push(temp);
          });
        this.setState({
          firstGraphData: {
            series: tempArr,
            graphType: this.props.graphInfo.graphData?.graph.graphType,
          },
        });
      }
    }

    if (
      prevProps.isTagsGraphDataLoading &&
      !this.props.isTagsGraphDataLoading &&
      this.props.tagsGraphInfo
    ) {
      let temp = this.props.tagsGraphInfo;
      let formattedData = [];
      let tempObj = {};
      let outerColor = [
        '#F50072',
        '#F59F00',
        '#F5DC00',
        '#72a4c2',
        '#68b04d',
        '#585ed6',
      ];
      Object.keys(temp).forEach((issue) => {
        Object.keys(temp[issue]).forEach((type) => {
          if (tempObj[type]) {
            tempObj[type].data.push({ name: issue, value: temp[issue][type] });
          } else {
            tempObj[type] = {
              name: type,
              data: [{ name: issue, value: temp[issue][type] }],
            };
          }
        });
      });
      Object.keys(tempObj).forEach((type, i) => {
        formattedData.push({
          name: type,
          data: tempObj[type].data,
          color: outerColor[i],
          marker: {
            fillOpacity: 1,
          },
        });
      });

      // let temp = this.props.tagsGraphInfo;
      // let formattedData = [];
      // Object.keys(temp).forEach((issue) => {
      //   let data = [];
      //   Object.keys(temp[issue]).forEach((type) => {
      //     data.push({ name: type, value: temp[issue][type] });
      //   });
      //   formattedData.push({ name: issue, data });
      // });
      this.setState({
        secondGraphData: { graphType: 'Issues', series: formattedData },
      });
    }

    if (
      !this.props.isCatalogSearchResultDataLoading &&
      this.props.catalogSearchResultData
    ) {
      // console.info('searchResultData', this.props.catalogSearchResultData)
    }

    if (
      prevProps.isWorkflowManagementCreateProjectLoading &&
      !this.props.isWorkflowManagementCreateProjectLoading &&
      this.props.workflowManagementCreateProject
    ) {
      handleSuccess('Project has been created successfully!');
      this.setState({ showNewProjectModal: false });
      this.props.workflowManagementDropdown({ adminId: this.props.userId });
      this.props.catalogRecentFolders({ limit: ALL_FOLDERS_LIMIT });
      this.props.catalogRecentDocuments({ limit: RECENT_SEARCH_LIMIT });
      this.props.catalogAllSource();
      this.props.workflowManagementProject({ createdBy: this.props.userId });
      const paramObject = {};
      this.props.catalogAdvancedSearch(paramObject);
      this.props.graphData({
        userId: this.props.userId,
        graphType: 'DataSource',
      });
    }
  }

  sourceModalHandler = (val) => {
    this.setState({ open: val });
  };

  // For ReactPaginate, calc data to be displayed
  handlePaginationClick = (data) => {
    const currentPage = data === undefined ? 0 : data.selected;
    const offset = this.PER_PAGE * currentPage;
    const recentDocumentPaginationData = this.recentDocumentsData.slice(
      offset,
      offset + this.PER_PAGE
    );
    this.setState({
      recentDocumentPaginationData,
    });
  };

  handleActiveTab = (tab) => {
    this.setState({ activeTab: tab });
    if (tab === 'Issues' && !this.state.firstGraphData) {
      this.props.graphData({
        userId: this.props.userId,
        graphType: 'DataSource',
      });
    }
  };

  onRecentSearchClick = (searchTerms) => {
    this.handleSearch(searchTerms);
  };

  toggleNewProjectModal = (value) => {
    this.setState({
      showNewProjectModal: value,
    });
  };

  createProject = (paramsObj) => {
    this.props.workflowManagementCreateProjectRequest(paramsObj);
  };

  getAdvancedSearchData = () => {
    // TODO: to be refactored
    // this.props.catalogSearchTerm({ searchTerm: searchItems })

    const advancedSearchFilters = this.props.catalogAdvancedSearchFilters;

    let advancedSearchFiltersRefined = {};
    if (advancedSearchFilters) {
      // console.info('advancedsearch filters', advancedSearchFilters)
      for (const [key, value] of Object.entries(advancedSearchFilters)) {
        if (value !== null && value.length !== 0) {
          const obj = {
            [key]: value,
          };
          advancedSearchFiltersRefined = {
            ...advancedSearchFiltersRefined,
            ...obj,
          };
        }
      }
    }

    // this.clearAll()
    // this.toggleAdvancedSearch()
    const { startDate, endDate } = this.getAdvancedSearchState();
    const date = [startDate, endDate];

    const params = {
      type:
        Object.keys(advancedSearchFiltersRefined).length === 0 &&
        advancedSearchFiltersRefined.constructor === Object &&
        startDate === null &&
        endDate === null
          ? 'NLQ'
          : 'ADAI',
      query: this.getSearchTerm() ? this.getSearchTerm() : '',
      source: '',
      filters:
        startDate !== null && endDate !== null
          ? { ...advancedSearchFiltersRefined, year: date }
          : advancedSearchFiltersRefined,
    };
    this.props.catalogSearchResultRequest(params);
    this.props.history.push('/data-catalog-2', {
      searchItems: this.getSearchTerm() ? this.getSearchTerm() : '',
    });
  };

  clearAll = () => {
    // console.info(
    //   'dispatchAdvancedSearchFilters',
    //   this.props.dispatchAdvancedSearchFilters()
    // )
    this.searchBarAndDropdownRef.current.clearAll();
    // this.props.dispatchAdvancedSearchFilters(null)
  };
  toggleAdvancedSearch = () => {
    this.searchBarAndDropdownRef.current.toggleAdvancedSearch();
  };
  goToWorkflowPage = () => {
    this.props.history.push('/workflow-management');
  };
  goToMasterListScreen = () => {
    this.props.history.push('/master-list');
  };
  getSearchTerm = () => {
    return this.searchBarAndDropdownRef.current.getSearchTerm();
  };
  getAdvancedSearchState = () => {
    return this.searchBarAndDropdownRef.current.getAdvancedSearchState();
  };

  render() {
    const {
      open,
      activeTab,
      recentDocumentPaginationData,
      dropboxUri,
      oneDriveUri,
      totalNumberOfPages,
      showNewProjectModal,
      firstGraphData,
      secondGraphData,
    } = this.state;

    // console.info(
    //   'this.props.isCatalogSearchResultDataLoading',
    //   this.props.isCatalogSearchResultDataLoading,
    //   'this.props.catalogSearchResultData',
    //   this.props.catalogSearchResultData
    // )
    const advancedSearchDataObject = this.props.catalogAdvancedSearchData;
    let advancedSearchData;
    if (advancedSearchDataObject)
      advancedSearchData = advancedSearchDataObject.filters;
    const totalSources = this.props.catalogRecentFoldersData?.count;

    return (
      <React.Fragment>
        {/* <NavigationBar hideSearchBar={true}></NavigationBar> */}
        <NavBar></NavBar>
        <Sidebar></Sidebar>
        <RightBar></RightBar>
        <div
          className={`${commonStyles.commom_data_catalog_wrapper} ${styles.data_catalog_container}`}
        >
          <div className={styles.top_container}>
            <div className={styles.top_container_cards_section}>
              <div className={styles.data_catalog_heading}>
                <div className={styles.data_catalog_title}>DATA CATALOG</div>
                <div className="heading_container">
                  <div className={styles.data_catalog_subtitle}>
                    Search for any term,
                  </div>
                  <div className={styles.data_catalog_subtitle}>
                    phrase or query
                  </div>
                </div>
              </div>

              <div className={styles.card_wrapper}>
                <div className={styles.new_project_card}>
                  <div className={styles.folder_icon_container}>
                    <img src={green_folder} alt="folder"></img>
                  </div>

                  <div className={styles.top_container_card_info}>
                    <div className={styles.top_container_title}>
                      Working on:
                    </div>

                    <div
                      className={styles.top_container_count}
                      onClick={this.goToWorkflowPage}
                    >
                      {this.props.workflowManagementProjectData === null ||
                      undefined
                        ? 0
                        : this.props.workflowManagementProjectData?.length}
                      {' Projects'}
                    </div>
                    <div
                      className={styles.text_plus_icon_container}
                      onClick={() => this.toggleNewProjectModal(true)}
                    >
                      <div className={styles.top_container_action}>
                        Create New Project
                      </div>
                      <div className={styles.plus_icon}>
                        <img src={plus_circle} alt="plus_circle"></img>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.new_project_card}>
                  <div className={styles.connector_icon_container}>
                    <img style={{width:"100%"}} src={connector_dark_blue} alt="folder"></img>
                  </div>

                  <div className={styles.top_container_card_info}>
                    <div className={styles.top_container_title}>
                      Searching within:
                    </div>
                    <div className={styles.top_container_count}>
                      {totalSources ? totalSources : 0} Sources
                    </div>
                    <div
                      className={styles.text_plus_icon_container}
                      onClick={() => this.sourceModalHandler(true)}
                    >
                      <div className={styles.top_container_action}>
                        Add Data Source
                      </div>
                      <div className={styles.plus_icon}>
                        <img
                          src={plus_circle}
                          alt="plus_circle"
                          className={styles.add_source_modal_btn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.search_bar_and_masterlist}>
            <div className={styles.search_bar_container}>
              <SearchBarAndDropDown
                ref={this.searchBarAndDropdownRef}
                handleSearch={(searchItems) => {
                  this.handleSearch(searchItems);
                }}
                placeholder="E.G. Income Tax 2020"
                showSearchTerm={false}
                advancedSearchData={advancedSearchData}
                getAdvancedSearchData={this.getAdvancedSearchData}
              />
            </div>
            <div
              className={styles.master_list}
              onClick={this.goToMasterListScreen}
            >
              <img src={MasterListSVG} alt="MasterListSVG" />
              <div>View Master List</div>
            </div>
          </div>

          <div className={`thin_custom_scrollbar ${styles.card_container}`}>
            <div className={styles.left_card}>
              <div className={styles.recent_folders_heading}>
                <div className={styles.title}>
                  Recent Queries{' '}
                  <img
                    className={styles.heading_icon}
                    src={green_query}
                    alt="folder"
                  />
                </div>
                <div
                  className={`data_catalog_tabs ${styles.data_catalog_tabs}`}
                >
                  <Tabs
                    defaultActiveKey="DataSource"
                    transition={false}
                    activeKey={activeTab}
                    onSelect={(k) => this.handleActiveTab(k)}
                  >
                    <Tab
                      eventKey="DataSource"
                      title={<div className={styles.tab_title}></div>}
                    ></Tab>
                    <Tab
                      eventKey="Issues"
                      title={<div className={styles.tab_title}></div>}
                    ></Tab>
                    {/* <Tab
                      eventKey="home3"
                      title={<div className={styles.tab_title}></div>}
                    ></Tab> */}
                  </Tabs>
                </div>
              </div>

              <div className={`${styles.recent_queries_card}`}>
                <div
                  className={`${styles.card_body} ${styles.recent_queries_card_body}`}
                >
                  {this.props.isLoading ? (
                    <BeatLoader
                      css={override}
                      size={25}
                      color={'#11256D'}
                      loading={true}
                    />
                  ) : activeTab === 'Issues' && firstGraphData ? (
                    <DataSourceGraph firstGraphData={firstGraphData} />
                  ) : null}

                  {this.props.isTagsGraphDataLoading ? (
                    <BeatLoader
                      css={override}
                      size={25}
                      color={'#11256D'}
                      loading={true}
                    />
                  ) : activeTab === 'DataSource' &&
                    secondGraphData &&
                    !this.props.isTagsGraphDataLoading ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={'chart'}
                      options={{
                        chart: {
                          type: 'packedbubble',
                          height: '370px',
                          style: {
                            fontFamily: 'Roboto, sans-serif',
                          },
                        },
                        title: {
                          text: '',
                        },
                        tooltip: {
                          useHTML: true,
                          pointFormat: '<b>{point.name}:</b> {point.value}',
                        },
                        plotOptions: {
                          packedbubble: {
                            minSize: '80%',
                            maxSize: '200%',
                            zMin: 0,
                            zMax: 1000,
                            layoutAlgorithm: {
                              splitSeries: false,
                              gravitationalConstant: 0.02,
                              bubblePadding: 20,
                              // enableSimulation: false,
                            },
                            dataLabels: {
                              enabled: true,
                              formatter: function () {
                                return this.point.name.length > 13
                                  ? this.point.name
                                      .slice(0, 13)
                                      .split(' ')
                                      .join('<br/>') + '...'
                                  : this.point.name;
                              },
                              style: {
                                color: 'black',
                                textOutline: 'none',
                                fontWeight: 'normal',
                              },
                            },
                          },
                        },
                        series: secondGraphData.series,
                      }}
                      containerProps={{ style: { height: '358px' } }}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <div className={styles.right_card}>
              <div className={styles.recent_folders_heading}>
                <div className={styles.title}>
                  Recent Folders
                  <img
                    className={styles.heading_icon}
                    src={green_folder_small}
                    alt="folder"
                  />
                </div>
                <div
                  onClick={this.handleViewAll}
                  className={styles.recent_folders_view_all}
                >
                  View All
                </div>
              </div>
              <div className={styles.recent_folders_card}>
                {this.props.catalogRecentFoldersData && (
                  <RecentFolders
                    history={this.props.history}
                    recentFolders={
                      this.props.catalogRecentFoldersData.recentFolders
                    }
                  ></RecentFolders>
                )}
              </div>
            </div>
          </div>

          <div className={styles.recent_documents_container}>
            <div className={styles.recent_documents_header_container}>
              <div className={styles.recent_documents_title}>
                Recent Documents
              </div>
              <div className={styles.icon_container}>
                <img
                  className={styles.recent_document_icon}
                  src={green_document}
                  alt="document"
                />
              </div>

              {recentDocumentPaginationData &&
                recentDocumentPaginationData.length > 0 && (
                  <div
                    className={`recent-documents-react-pagination ${styles.react_pagination}`}
                  >
                    <ReactPaginate
                      previousLabel={''}
                      nextLabel={''}
                      breakLabel={''}
                      breakClassName={'break-me'}
                      pageCount={totalNumberOfPages}
                      marginPagesDisplayed={4}
                      pageRangeDisplayed={totalNumberOfPages}
                      onPageChange={this.handlePaginationClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  </div>
                )}
            </div>
            <div className={styles.recent_document_section}>
              {this.props.recentDocumentsData?.recentDocuments && (
                <RecentDocuments
                  recentDocuments={recentDocumentPaginationData}
                  parent={'DataCatalog'}
                ></RecentDocuments>
              )}
            </div>
          </div>
          <div className={styles.all_folder_and_searches_container}>
            <div className={styles.all_folders_container}>
              <div
                className={`${styles.title} ${styles.all_folders_title_container} `}
              >
                All Folders{' '}
                <img
                  className={styles.all_folder_title_icon}
                  src={green_folder_small}
                  alt="folder"
                />
              </div>
              {this.props.iscatalogRecentFoldersLoading && (
                <BeatLoader
                  css={override}
                  size={25}
                  color={'#11256D'}
                  loading={true}
                />
              )}
              {this.props.catalogRecentFoldersData && (
                <AllFolders
                  history={this.props.history}
                  foldersData={this.props.catalogRecentFoldersData.allFolders}
                  privateFolders={
                    this.props.catalogRecentFoldersData.privateFolder
                  }
                  precilyData={this.props.catalogRecentFoldersData?.precilyData}
                  showMore={
                    this.props.catalogRecentFoldersData.count -
                      ALL_FOLDERS_LIMIT >
                    0
                      ? this.props.catalogRecentFoldersData.count
                      : 0
                  }
                ></AllFolders>
              )}
            </div>

            <div className={styles.recent_searches_container}>
              <div
                className={`${styles.title} ${styles.recent_searches_title_container} `}
              >
                Recent Searches{' '}
                <img
                  className={styles.recent_search_title_icon}
                  src={green_search}
                  alt="search"
                ></img>
              </div>
              {/* <div className={styles.recent_search_text}>
                Depreciation releated search result is here. try different
                keywords. You can search for the documents from Advanced Search option.
              </div> */}
              {this.props.recentDocumentsData?.recentSearches && (
                <div className={styles.recent_search_container}>
                  <RecentSearches
                    recentSearches={
                      this.props.recentDocumentsData.recentSearches
                    }
                    onRecentSearchClick={this.onRecentSearchClick}
                  ></RecentSearches>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddSourceModal
          open={open}
          dropboxUri={dropboxUri}
          oneDriveUri={oneDriveUri}
          sourceModalHandler={this.sourceModalHandler}
        />
        {showNewProjectModal && this.props.workflowManagementDropdownData && (
          <NewProjectModal
            newProjectModalHandler={() => this.toggleNewProjectModal(false)}
            dropDownData={this.props.workflowManagementDropdownData}
            userId={this.props.userId}
            createProject={this.createProject}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.homepage.userDetails.userId,
    graphInfo: state.graphData,
    isLoading: state.graphData.isLoading,
    tagsGraphInfo: state.graphData.tagsGraphData,
    isTagsGraphDataLoading: state.graphData.isTagsGraphDataLoading,
    catalogRecentFoldersData: state.catalogLandingPage.catalogRecentFolders,
    iscatalogRecentFoldersLoading:
      state.catalogLandingPage.iscatalogRecentFoldersLoading,
    recentDocumentsData: state.catalogLandingPage.catalogRecentDocuments,
    iscatalogRecentDocumentsLoading:
      state.catalogLandingPage.iscatalogRecentDocumentsLoading,
    catalogAdvancedSearchData: state.catalogLandingPage.catalogAdvancedSearch,
    catalogAllSourceData: state.catalogLandingPage.catalogAllSource,
    catalogAdvancedSearchFilters:
      state.catalogLandingPage.catalogAdvancedSearchFilters,
    catalogSearchResultData: state.catalogSearchResult.catalogSearchResultData,
    isCatalogSearchResultDataLoading:
      state.catalogSearchResult.isCatalogSearchResultDataLoading,
    workflowManagementProjectData:
      state.workFlowManagementLandingPage.workflowManagementProjectData,
    workflowManagementDropdownData:
      state.workFlowManagementLandingPage.workflowManagementDropdownData,
    workflowManagementCreateProject:
      state.workFlowManagementLandingPage.workflowManagementCreateProject,
    isWorkflowManagementCreateProjectLoading:
      state.workFlowManagementLandingPage
        .isWorkflowManagementCreateProjectLoading,
  };
};

const mapDispatchToProps = {
  catalogRecentFolders: catalogLandingPage.catalogRecentFoldersRequest,
  catalogRecentDocuments: catalogLandingPage.catalogRecentDocumentsRequest,
  catalogSearchResultRequest:
    catalogSearchResults.catalogSearchResultDataRequest,
  catalogAllFolders: catalogLandingPage.catalogAllFoldersRequest,
  catalogAllSource: catalogLandingPage.catalogAllSourceRequest,
  graphData: graphData.graphDataRequest,
  catalogSearchTerm: catalogLandingPage.catalogSearchTermRequest,
  catalogAdvancedSearch: catalogLandingPage.catalogAdvancedSearchRequest,
  dispatchAdvancedSearchFilters:
    catalogLandingPage.catalogAdvancedSearchFilterAction,
  saveFilteredDocuments:
    catalogSearchResults.catalogSearchResultFilteredDocumentsAction,
  saveFilters: catalogSearchResults.catalogSearchResultFilterAction,

  workflowManagementProject:
    workFlowManagementLandingPage.workflowManagementProjectRequest,
  workflowManagementDropdown:
    workFlowManagementLandingPage.workflowManagementDropdownRequest,
  workflowManagementCreateProjectRequest:
    workFlowManagementLandingPage.workflowManagementCreateProjectRequest,
  saveSearchTerm: catalogSearchResults.catalogSearchResultSearchTermAction,
  tagsGraphData: graphData.tagsGraphDataRequest,
  saveFiltersWorkflowPage:
    workFlowManagementLandingPage.workflowManagementSaveFilters,
  saveFilteredDocumentsWorkflowPage:
    workFlowManagementLandingPage.workflowManagementSaveFilteredDocuments,
  saveSelectedSources: catalogSearchResults.catalogSelectedSourcesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataCatalog);
