import React, { Component } from "react";
import styles from "./Support.module.css";
import Loader from "../../shared/loader/Loader";
import Sidebar from "../../shared/sidebar/Sidebar";
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import commonStyles from "../../shared/Common.module.css";
import TicketList from "../../components/support/TicketList";
import * as supportActions from "../../store/actions/supportActions";
import CreateTicket from "../../components/support/create-ticket/CreateTicket";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import {handleSuccess, handleError} from '../../shared/HandleMessages'

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createTicket: false,
      togglePopup: false,
      loading: false,
      searchItem: "",
      tickets: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.props.listTicketsRequest(this.props.user.email);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.onUploadClose();
      if (this.props.listTickets?.tickets?.length > 0) {
        this.setState({
          tickets: this.props.listTickets.tickets,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    }

    if (prevProps.isTicketCreated && !this.props.isTicketCreated) {
      this.onUploadClose()
      handleSuccess(this.props.message)
      this.props.listTicketsRequest(this.props.user.email);
      this.setState({ loading: true });
    }

    if (prevProps.isTicketClosed && !this.props.isTicketClosed) {
      this.onUploadClose();
      this.props.listTicketsRequest(this.props.user.email);
      this.setState({ loading: true });
    }

    if (prevProps.isTicketUpdated && !this.props.isTicketUpdated) {
      this.onUploadClose();
      this.props.listTicketsRequest(this.props.user.email);
      this.setState({ loading: true });
    }
  }

  uploadDocument = () => {
    this.setState({
      createTicketbtn: true,
    });
  };
  onUploadClose = () => {
    this.setState({
      createTicketbtn: false,
    });
  };
  onCloseTicket = (param) => {
    this.props.closeTicketRequest(param);
  };

  renderTabList = () => {
    const { tickets } = this.state;
    return tickets.map((doc) => {
      let docStatus = doc.title;
      let docCount = doc.count;
      return (
        <Tab
          key={docStatus}
          eventKey={docStatus}
          title={docStatus + " (" + docCount + ")"}
        >
          <TicketList
            documents={doc.tickets}
            onCloseTicket={this.onCloseTicket}
            searchItem={this.state.searchItem}
          ></TicketList>
        </Tab>
      );
    });
  };

  onHandleSearchChange = (searchItem) => {
    this.setState({ searchItem });
  };

  render() {
    const { createTicketbtn, loading, tickets } = this.state;
    let allDocumentData = [];
    tickets.forEach((document) => {
      allDocumentData = allDocumentData.concat(document.subject);
    });

    // TODO: attach file not working
    return (
      <React.Fragment>
        <NavigationBar
          loading={this.props.isListDocumentsLoading}
          onHeaderSearchChange={this.onHandleSearchChange}
        ></NavigationBar>
        <Sidebar></Sidebar>
        <div className={commonStyles.commom_wrapper}>
          <div className={styles.heading_row}>
            <div>
              <div className={commonStyles.commom_title}>Support Tickets</div>
            </div>
          </div>
          <div className={styles.all_documents_content_wrapper}>
            <div className={`card ${commonStyles.card_layout}`}>
              <div className={`card-body ${styles.all_documents_card_body}`}>
                {loading ? (
                  <Loader />
                ) : tickets && tickets.length > 0 ? (
                  <div className={styles.all_documents_tab_container}>
                    <Tabs id="team-tab">{this.renderTabList()}</Tabs>
                    <div className={styles.all_documents_btn_container}>
                      <button
                        className={`${styles.upload_doc_btn} ${styles.all_document_btn} ${commonStyles.btn_ripple}`}
                        type="button"
                        onClick={this.uploadDocument}
                      >
                        <i
                          className={`fas fa-plus-circle ${styles.btn_icon}`}
                        ></i>
                        Create Ticket
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className={styles.createTicket}>
                    <p>No ticket created yet!</p>
                    <button
                      className={`${styles.upload_doc_btn_center} ${commonStyles.btn_ripple}`}
                      type="button"
                      onClick={this.uploadDocument}
                    >
                      <i
                        className={`fas fa-plus-circle ${styles.btn_icon}`}
                      ></i>
                      Create Ticket
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {createTicketbtn && (
          <CreateTicket
            history={this.props.history}
            closeHandler={this.onUploadClose}
          ></CreateTicket>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.homepage.userDetails,
    listTickets: state.support.tickets,
    isLoading: state.support.isLoading,
    isTicketClosed: state.support.isTicketClosed,
    isTicketCreated: state.support.isTicketCreated,
    isTicketUpdated: state.support.isTicketUpdated,
    message: state.support.message
  };
};
const mapDispatchToProps = {
  listTicketsRequest: supportActions.fetchAllTicketsRequest,
  closeTicketRequest: supportActions.deleteTicketRequest,
  createTicketRequest: supportActions.createTicketRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(Support);
