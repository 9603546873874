import * as types from "../types";

const initialState = {
  isLoading: false,
  clusterData: null,
  error: null,
  paraId: null,
  similarParas: {
    byTag: null,
    bySectionNum: null,
    byFactualData: null,
  },
  isSimilarParasLoading: null,
  isSimilarDocsLoading: null,
  similarDocs: null,
  response: null,
  prepareResponse: false,
  isPrepareResponseLoading: null,
  isSubmitResponseLoading: null,
  message: null,
};

const defaultErrorMessage = "Something went wrong";

export default (state = initialState, action) => {
  switch (action.type) {
    // clusterData Actions
    case types.CLUSTER_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        clusterData: null,
        error: null,
      };
    case types.CLUSTER_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        clusterData: action.payload.clusteredData.result,
        similarParas: action.payload.similarParas.result,
        similarDocs: action.payload.similarDocs.result,
      };
    }
    // error: action.payload.message
    case types.CLUSTER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result?.message || defaultErrorMessage,
      };
    case types.SET_UPLOADED_DATA:
      return {
        ...state,
        clusterData: action.payload,
      };
    case types.SIMILAR_PARA_REQUEST:
      debugger
      return {
        ...state,
        isSimilarParasLoading: true,
        isLoading: true,
        similarParas: {
          byTags: null,
          bySectionNum: null,
          byFactualData: null,
        },
      };
    case types.SIMILAR_PARA_SUCCESS:
      debugger
      return {
        ...state,
        isSimilarParasLoading: false,
        similarParas: {
          byTag: action.payload.result.data.byTag,
          bySectionNum: action.payload.result.data.bySectionNum,
          byFactualData: action.payload.result.data.byFactualData,
        },
        error: null,
      };
    case types.SIMILAR_PARA_FAILURE:
      debugger
      return {
        ...state,
        isSimilarParasLoading: false,
        similarParas: {
          byTag: null,
          bySectionNum: null,
          byFactualData: null,
        },
        error: action.payload.result.message || defaultErrorMessage,
      };
    case types.SIMILAR_PARA_ID:
      return {
        ...state,
        paraId: action.payload,
        mainTab: "similar_para",
        subTab: "doc_info",
      };
    case types.REVERT_ACTIVE_STATE:
      return {
        ...state,
        mainTab: action.payload,
        subTab: null,
      };
    case types.SIMILAR_PARA_INIT:
      return {
        ...state,
        mainTab: null,
        paraId: null,
      };
    case types.CLEAR_SIMILAR_PARA:
      debugger
      return {
        ...state,
        mainTab: null,
        paraId: null,
        similarParas: {
          byTag: null,
          bySectionNum: null,
          byFactualData: null,
        },
      };
    case types.SIMILAR_DOCS_REQUEST: {
      return {
        ...state,
        isSimilarDocsLoading: true,
        isLoading: true,
        similarDocs: null,
      };
    }
    case types.SIMILAR_DOCS_SUCCESS: {
      return {
        ...state,
        isSimilarDocsLoading: false,
        similarDocs: action.payload.result.data,
      };
    }
    case types.SIMILAR_PARA_FAILURE:
      return {
        ...state,
        isSimilarDocsLoading: false,
        similarDocs: null,
        error: action.payload.result.message || defaultErrorMessage,
      };
    case types.PREPARE_RESPONSE_REQUEST:
      return {
        ...state,
        isPrepareResponseLoading: true,
        response: null,
      };
    case types.PREPARE_RESPONSE_SUCCESS:
      return {
        ...state,
        isPrepareResponseLoading: false,
        response: action.payload.result.data.response,
        prepareResponse: true,
        error: null,
      };
    case types.PREPARE_RESPONSE_FAILURE:
      return {
        ...state,
        error: null,
        isPrepareResponseLoading: false,
        prepareResponse: false,
      };
    case types.CLEAR_RESPONSE:
      return {
        ...state,
        prepareResponse: false,
        error: null,
        response:null,
      }; 
    
      case types.SUBMIT_PREPARE_RESPONSE_REQUEST:
      return {
        ...state,
        isSubmitResponseLoading: true,
      };
    case types.SUBMIT_PREPARE_RESPONSE_SUCCESS:
      return {
        ...state,
        message: action.payload.result.message,
        isSubmitResponseLoading: false,
      };
    case types.SUBMIT_PREPARE_RESPONSE_FAILURE:
      return {
        ...state,
        error: action.payload.result.message || defaultErrorMessage,
      };
    default: {
      return state;
    }
  }
};
