import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as fileLink from '../actions/fileLinkAction';

function* fileLinkWorker(params) {
  try {
    const data = yield call(_api, URLS.fileLink, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(fileLink.fileLinkSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(fileLink.fileLinkFailure(e));
  }
}

function* fileLinkWatcher() {
  yield takeLatest(types.FILE_LINK_REQUEST, fileLinkWorker);
}

export default function* fileLinkSaga() {
  yield all([fileLinkWatcher()]);
}
