import * as types from '../types';

const initialState = {
    isLoading: false,
    mainSubTags: null,
    error: null,
    feedbackData:null,
    isFeedbackLoading:null
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
    switch (action.type) {
        // mainSubTags Actions
        case types.MAIN_SUB_TAGS_REQUEST:
            return {
                ...state, isLoading: true, mainSubTags:null
            };
        case types.MAIN_SUB_TAGS_SUCCESS: {
            return {
                ...state, isLoading: false,
                mainSubTags: action.payload.result
            };
        }
        case types.MAIN_SUB_TAGS_FAILURE: return {
            ...state, isLoading: false, error: action.payload.message || defaultErrorMessage
        };

                // addFeesdback Actions
                case types.ADD_FEEDBACK_REQUEST:
                    return {
                        ...state,
                         isLoading: true,
                         isFeedbackLoading:true,
                          feedbackData:null
                    };
                case types.ADD_FEEDBACK_SUCCESS: {
                    return {
                        ...state,
                         isLoading: false,
                         isFeedbackLoading:false,
                        feedbackData: action.payload.result
                    };
                }
                case types.ADD_FEEDBACK_FAILURE: return {
                    ...state,
                     isLoading: false,
                     isFeedbackLoading:false,
                      error: action.payload.message || defaultErrorMessage
                };
        default: {
            return  state ;
        }
    }
}