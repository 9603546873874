import * as types from '../types'

const initialState = {
  catalogRecentFolders: null,
  catalogRecentDocuments: null,
  catalogAllFolders: null,
  catalogAllSource: null,
  catalogSearchTerm: null,
  catalogAdvancedSearch: null,
  iscatalogRecentFoldersLoading: false,
  iscatalogRecentDocumentsLoading: false,
  isCatalogAllFoldersLoading: false,
  isCatalogAllSourceLoading: false,
  isCatalogSearchTermLoading: false,
  isCatalogAdvancedSearchLoading: false,
  catalogAdvancedSearchFilters: null,
  error: null,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // Catalog Recent Folders Actions
    case types.CATALOG_RECENT_FOLDERS_REQUEST:
      return {
        ...state,
        catalogRecentFolders: null,
        iscatalogRecentFoldersLoading: true,
      }
    case types.CATALOG_RECENT_FOLDERS_SUCCESS: {
      return {
        ...state,
        iscatalogRecentFoldersLoading: false,
        catalogRecentFolders: action.payload.result.data,
      }
    }
    case types.CATALOG_RECENT_FOLDERS_FAILURE:
      return {
        ...state,
        iscatalogRecentFoldersLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Catalog Recent Documents Actions
    case types.CATALOG_RECENT_DOCUMENTS_REQUEST:
      return {
        ...state,
        catalogRecentDocuments: null,
        iscatalogRecentDocumentsLoading: true,
      }
    case types.CATALOG_RECENT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        iscatalogRecentDocumentsLoading: false,
        catalogRecentDocuments: action.payload.result.data,
      }
    }
    case types.CATALOG_RECENT_DOCUMENTS_FAILURE:
      return {
        ...state,
        iscatalogRecentDocumentsLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Catalog All Folders Actions
    case types.CATALOG_ALL_FOLDERS_REQUEST:
      return {
        ...state,
        catalogAllFolders: null,
        isCatalogAllFoldersLoading: true,
      }
    case types.CATALOG_ALL_FOLDERS_SUCCESS: {
      return {
        ...state,
        isCatalogAllFoldersLoading: false,
        catalogAllFolders: action.payload.result.data,
      }
    }
    case types.CATALOG_ALL_FOLDERS_FAILURE:
      return {
        ...state,
        isCatalogAllFoldersLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Catalog All Source Actions
    case types.CATALOG_ALL_SOURCE_REQUEST:
      return {
        ...state,
        catalogAllSource: null,
        isCatalogAllSourceLoading: true,
      }
    case types.CATALOG_ALL_SOURCE_SUCCESS: {
      return {
        ...state,
        isCatalogAllSourceLoading: false,
        catalogAllSource: action.payload.result.data,
      }
    }
    case types.CATALOG_ALL_SOURCE_FAILURE:
      return {
        ...state,
        isCatalogAllSourceLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Catalog Advanced Search Actions
    case types.CATALOG_ADVANCED_SEARCH_REQUEST:
      return {
        ...state,
        catalogAdvancedSearch: null,
        isCatalogAdvancedSearchLoading: true,
      }
    case types.CATALOG_ADVANCED_SEARCH_SUCCESS: {
      return {
        ...state,
        isCatalogAdvancedSearchLoading: false,
        catalogAdvancedSearch: action.payload.result.data,
      }
    }
    case types.CATALOG_ADVANCED_SEARCH_FAILURE:
      return {
        ...state,
        isCatalogAdvancedSearchLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }
    // Catalog Search Term Actions
    case types.CATALOG_SEARCH_TERM_REQUEST:
      return {
        ...state,
        isCatalogSearchTermLoading: true,
      }
    case types.CATALOG_SEARCH_TERM_SUCCESS: {
      return {
        ...state,
        isCatalogSearchTermLoading: false,
      }
    }
    case types.CATALOG_SEARCH_TERM_FAILURE:
      return {
        ...state,
        isCatalogSearchTermLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    //  for saving advanced search filters
    case types.CATALOG_ADVANCED_SEARCH_FILTERS:
      return {
        ...state,
        catalogAdvancedSearchFilters: action.payload,
      }
    default: {
      return state
    }
  }
}
