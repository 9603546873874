import React, { Component } from "react";
import styles from "./ResponseMessagePopup.module.css";
import commonStyles from '../../../shared/Common.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';



class ResponseMessagePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
   

    handleMessageSubmit = () =>{
        this.props.onMessageSubmit()
    }
 
    render() {
        return (
            <div>
                <CommonModal modalClass={'team_common_modal'} closeHandler={this.props.closeHandler} header={'Write Message (Optional)'}>
                <p className={commonStyles.common_form_label}>DESCRIBE ISSUE</p>
                <div className={styles.response_message}>
                                <textarea  rows="5"></textarea >
                            </div>
                <div className={styles.buttons_wrapper}>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                                onClick={this.props.closeHandler}>
                                CANCEL
                                </button>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                                onClick={this.handleMessageSubmit}>
                                SUBMIT
                            </button>
                        </div>
                </CommonModal>
            </div>
        )
    }
}


export default ResponseMessagePopup;