import React from 'react'
import styles from './DataCatalogAllFoldersOne.module.css'
// import Sidebar from '../../shared/sidebar/Sidebar'
import NavigationBar from '../../shared/navigation-bar/NavigationBar'
import commonStyles from '../../shared/Common.module.css'
import RecentDocuments from '../../components/data-catalog/recent-documents/RecentDocuments'
import AllFolders from '../../components/data-catalog/all-folders/AllFolders'
import AllFolderList from '../../components/data-catalog-all-folders/all-folder-list/AllFolderList'
import SearchBlackIcon from '../../../src/assets/search_black.svg'
import search_grey_icon from '../../../src/assets/search_grey.svg'

import folders from '../../json-data/allFolders.json'
import recentDocuments from '../../json-data/recentDocuments.json'
import rightCards from '../../json-data/rightCards.json'
import green_document from '../../../src/assets/green_document.svg'
import ArrowUp from '../../../src/assets/arrow-up-grey.svg'
import { connect } from 'react-redux'
import * as catalogSelectedFolder from '../../store/actions/catalogSelectedFolderAction'
import { formatDate } from '../../shared/formatDate'

class DataCatalogAllFoldersOne extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allFilesData: null,
      searchItems: '',
    }
  }

  componentDidMount() {
    const paramsObj = {
      referenceId: this.props.history.location?.state?.referenceId,
      catalogSourceId: this.props.history.location?.state?.catalogSourceId,
      limit: 10,
    }
    this.props.catalogRecentFolders(paramsObj)
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.isSelectedFolderDataLoading &&
      prevProps.isSelectedFolderDataLoading &&
      this.props.selectedFolderData?.allFiles
    ) {
      this.setState({
        allFilesData: this.props.selectedFolderData.allFiles,
      })
    }
  }
  handleBackNavigationAllFolders = () => {
    // this.props.history.push('/data-catalog')
    this.props.history.push({
      pathname: '/all-folders-tree',
      search: '?source=data-catalog'
    })
  }
  handleBackNavigationGoBack = () => {
    // this.props.history.push('/data-catalog')
    this.props.history.goBack()
  }
  handleUserInput = event => {
    var searchItems = event.target.value
    this.setState({ searchItems: event.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.data_catalog_all_folders_top_section}>
          <div className={styles.left_section}>
            <div
              className={styles.back_icon}
              onClick={this.handleBackNavigationGoBack}
            >
              <img src={ArrowUp} alt="Arrow Up" />
            </div>
            <div
              className={styles.folder_path}
              onClick={this.handleBackNavigationAllFolders}
            >
              All Folders /
            </div>
            <div className={styles.folder_name}>
              {this.props.history.location?.state?.fileName.replace(
                /%20/g,
                ' '
              )}
            </div>
          </div>

          <div className={styles.right_section}>
            {this.props.selectedFolderData?.metaData &&
              this.props.selectedFolderData?.metaData.map((data, index) => {
                return (
                  <div className={styles.right_section_card}>
                    <div className={styles.card_content}>
                      <div>
                        <img
                          className={styles.icon}
                          src={search_grey_icon}
                          alt="Search Icon"
                        />
                      </div>
                      <div className={styles.card_text_content}>
                        <div className={styles.name}>{data.name}</div>
                        <div className={styles.card_bottom_section}>
                          <div>{data.count} RESULTS</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <hr></hr>

        <div className={styles.recent_document_container}>
          <div className={styles.title_search_bar_container}>
            <div className={styles.title_container}>
              <span className={styles.title}>Recent Documents</span>
              <img src={green_document} alt="document"></img>
            </div>
            <div className={styles.search_bar_section}>
              <div className={styles.search_bar_container}>
                <input
                  placeholder="Search This Folder"
                  type="text"
                  className={styles.search_box}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                <div className={styles.search_icon}>
                  <img
                    className={styles.icon}
                    src={SearchBlackIcon}
                    alt="Search Icon"
                  />
                </div>
              </div>
            </div>
          </div>
          {this.props.selectedFolderData?.recentFiles && (
            <RecentDocuments
              recentDocuments={this.props.selectedFolderData.recentFiles}
              parent={'DataCatalogAllFolder'}
            ></RecentDocuments>
          )}
          {(!this.props.selectedFolderData?.recentFiles ||
            !this.props.selectedFolderData?.recentFiles) && (
            <div>No Documents Found</div>
          )}
        </div>
        <hr></hr>
        {this.props.selectedFolderData?.allFiles && this.state.allFilesData && (
          <div className={styles.all_folder_list_container}>
            <AllFolderList
              foldersData={this.state.allFilesData}
              searchItems={this.state.searchItems}
            ></AllFolderList>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedFolderData: state.catalogSelectedFolder.selectedFolderData, //allFiles    recentFiles
    isSelectedFolderDataLoading:
      state.catalogSelectedFolder.isSelectedFolderDataLoading,
  }
}
const mapDispatchToProps = {
  catalogRecentFolders: catalogSelectedFolder.catalogSelectedFolderRequest,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataCatalogAllFoldersOne)
