import React from "react";
import styles from "./Reports.module.css";
// import Sidebar from '../../shared/sidebar/Sidebar';
import NavigationBar from '../../shared/navigation-bar/NavigationBar'
import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'

const Reports = () => {
  return (
    <React.Fragment>
        {/* <NavigationBar></NavigationBar> */}
        <NavBar></NavBar>
        <Sidebar></Sidebar>
      <h1 className={styles.wrapper}>Reports</h1>
    </React.Fragment>
  );
}

export default Reports;