import React, { Component } from 'react';
import { IconContext } from 'react-icons';
// import { IoIosArrowDown } from 'react-icons/io';
import NavBar from './../../../screens/workflow-management/NavBar/NavBar';
import Sidebar from './../../../screens/workflow-management/Sidebar/Sidebar';
import RightBar from './../../../screens/workflow-management/RightBar/RightBar';
// import SearchBar from './../../data-catalog/search-bar/SearchBar';
import BackArrowSVG from './../../../assets/arrow-up-grey.svg';
import BlueFileSVG from './../../../assets/blue_file_DA.svg';
// import BaloonSVG from './../../../assets/baloon_green.svg';
import greenDotIcon from './../../../assets/green_dot.svg';
import styles from './DocumentAnalytics.module.css';
// import { citationsList } from './../../../json-data/documentAnalytics.json';
// import { Steps } from 'antd';
// import three_dot_icon from '../../../assets/three_dot_icon.svg';
// import selected_three_dot_icon from '../../../assets/selected_three_dot_icon.svg';
import OutsideClickHandler from 'react-outside-click-handler';
// import SplitPane, { Pane } from 'react-split-pane';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FiShare2 } from 'react-icons/fi';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as uploadedDoc from '../../../store/actions/uploadedDocActions';
import * as documentAnalyticsHistory from '../../../store/actions/documentAnalyticsHistoryAction';
import * as fileClusterData from '../../../store/actions/fileClusterDataAction';
import { connect } from 'react-redux';
import DragDocument from './DragDocuments';
// import FroalaEditor from 'react-froala-wysiwyg';
import EditTags from './EditTags';
import CommonModal from '../../../shared/common-modal/CommonModal';
import copyIcon from '../../../assets/copy_icon.svg';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import highchartsTimeline from 'highcharts/modules/timeline';
import * as fileComment from '../../../store/actions/fileCommentAction';
import EditHistory from './EditHistory';
import AddComment from './AddComment';
import Loader from '../../../shared/loader/Loader';
import * as d3 from 'd3';
import Share from './../../data-catalog/modals/share/Share';
import ReactQuill from 'react-quill';
import HighlightEditor from './HighlightEditor';
import Split from 'react-split';
import moment from 'moment';

// highchartsTimeline(Highcharts);

// const { Step } = Steps;
class DocumentAnalytics extends Component {
  constructor(props) {
    super(props);

    this.middlePartRef = React.createRef();
    this.reactQuillRef = React.createRef();
    this.citationsRef = React.createRef();
    this.issueAddressedRef = React.createRef();
    this.centerRef = React.createRef();
    var icons = ReactQuill.Quill.import('ui/icons');
    icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
    icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

    this.editor = React.createRef();
    this.ref = React.createRef();
    this.state = {
      showRelatedDocumentSection: false,
      showInfoPanel: false,
      lastEditOpen: false,
      selectedAction: null,
      shareAction: null,
      selectedValues: 'related_document',
      text: '',
      isPrepareResponse: false,
      numPages: null,
      restoringHistory: false,
      editedHistory: null,
      subTags: [],
      filteredSubTags: [],
      filerSearchValue: '',
      selectedSimilarDoc: 0,
      selectedParaIndex: [],
      isTagSelected: '',
      isSimilarParaModalOpen: false,
      editHistoryData: [],
      fileClusterData: [],
      citationData: [],
      fileComments: null,
      isCommenting: false,
      commentCount: 0,
      commentText: '',
      similarParaSeqId: 0,
      similarParas: null,
      selectedSimilarParaIndex: null,
      similarDocs: null,
      selectedSimilarDocUrl: '',
      isLoading: true,
      issuesPercent: null,
      fileMainData: null,
      isSubmittedForReview: false,
      showShare: false,
      allMainSubTags: null,
      isSimilarParsDataLoading: false,
      similarParaData: null,
      isTagSelectedOnce: false,
      similarDocFileClusterData: [],
      similarDocClusterDataLoading: false,
      similarDocFileClusterSubTags: [],
      similarDocsSelectedParaIndex: [],
      isSimilarDocsTagSelected: '',
      filteredFileComments: null,
      selectedCommentCount: null,
      fileStatus: '',
      restoringInitialVersion: false,
      splitPane2Expanded: false,
      size1: 90,
      size2: 10,
      defaultSize: 90,
      historyActiveDot: 0,
      previousTarget: null,
      commentUpdateText: '',
    };
  }

  componentDidMount() {
    // this.setState({ isLoading: true });
    let lState = this.props.location.state;
    console.log("location state",this.props.location.state.fileId);
    // this.props.clusterDataRequest(
    //   {
    //     filePath: state?.filePath,
    //     // fileVersionId: 'c5w36hki73pvc4',
    //     // fileVersion: 1,
    //     fileId: state?.fileId,
    //     // version: 1,
    //     // docType: 3,
    //   },
    //   { fileId: state?.fileId },
    //   { fileId: state?.fileId }
    // );
    this.props.fileClusterDataRequest({
      fileId: lState?.fileId,
      filePath: lState?.filePath,
    });
    this.props.getFileCommentRequest({ fileId: lState?.fileId });
    this.props.allMainSubTagsRequest();
    this.props.similarDocDataRequest({ fileId: lState?.fileId });
    if (lState?.utmSource) {
      this.props.fileTrackRequest({
        utm_source: lState?.utmSource,
        fileId: lState?.fileId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps, this.props);
    let state = this.props.location.state;
    if (
      prevProps.isDocumentAnalyticsHistoryLoading &&
      !this.props.isDocumentAnalyticsHistoryLoading &&
      this.props.documentAnalyticsHistory &&
      !this.state.restoringHistory
    ) {
      let historySate = this.state.editHistoryData;
      let data = this.props.documentAnalyticsHistory;
      if (historySate.length) {
        data.push(historySate[historySate.length - 1]);
        this.setState({
          editHistoryData: data,
        });
        if (this.props.documentAnalyticsHistory[0]?.responseData) {
          this.setState({
            text: this.props.documentAnalyticsHistory[0]?.responseData,
          });
        }
      }
    }

    if (
      prevProps.isDocumentAnalyticsHistoryLoading &&
      !this.props.isDocumentAnalyticsHistoryLoading &&
      this.props.documentAnalyticsHistory &&
      this.state.restoringHistory
    ) {
      if (!Array.isArray(this.props.documentAnalyticsHistory)) {
        this.setState({
          text: this.props.documentAnalyticsHistory.responseData,
        });
      }
      this.setState({
        editedHistory: this.props.documentAnalyticsHistory,
      });
    }

    if (
      prevProps.isFileCommentsLoading &&
      !this.props.isFileCommentsLoading &&
      this.props.fileComments
    ) {
      this.setState({
        fileComments: this.props.fileComments.data,
      });
    }

    if (
      prevProps.isFileClusterDataLoading &&
      !this.props.isFileClusterDataLoading &&
      this.props.fileClusterData
    ) {
      let subTags = [];
      let text = '';
      let sortedData =
        this.props.fileClusterData &&
        this.props.fileClusterData.result.sort((a, b) => {
          return a.paraSeqId - b.paraSeqId;
        });
      sortedData &&
        sortedData.forEach((result) => {
          if (result.paraHtml) {
            text += result.paraHtml;
            text += '<br/>';
            text += '<br/>';
          } else {
            text += result.paraText;
            text += '<br/>';
            text += '<br/>';
          }
          let keys = result.subTags ? Object.keys(result.subTags) : [];

          // let filteredFeedback = [];
          // let tempFeedbackObj = {}
          // if(result.feedback) {
          //   result.feedback.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          //   result.feedback.forEach(feedback => {
          //     if(!tempFeedbackObj[feedback.orgTag]) {
          //       filteredFeedback.push(feedback);
          //       tempFeedbackObj[feedback.orgTag] = true
          //     }
          //   })
          // }

          keys.forEach((key) => {
            let inserted = false;
            result.feedback &&
              result.feedback.forEach((feedback) => {
                if (feedback.orgTag === result.subTags[key]) {
                  subTags.push(feedback.customTag);
                  inserted = true;
                }
              });
            if (!inserted) {
              subTags.push(result.subTags[key]);
            }
          });
        });
      subTags = [...new Set(subTags)];
      this.generateGraph(sortedData, subTags);
      if (!this.state.text) {
        this.setState({ text });
      }

      let newEditHistoryData = [...this.state.editHistoryData];
      newEditHistoryData.push({
        // catalogId: 'jksjdsadsad',
        createdAt: this.props.fileClusterData?.created,
        editedBy: this.props.fileClusterData?.uploadedBy,
        fileId: this.props.fileClusterData?.fileId,
        fileVersion: 'initial',
        // referenceId: 'hkdjshdkhsda',
        // responseData: 'abx',
        updatedAt: this.props.fileClusterData?.created,
      });

      this.setState({
        fileClusterData: sortedData,
        subTags: subTags,
        filteredSubTags: subTags,
        citationData: this.props.fileClusterData.citation,
        similarParas: this.props.fileClusterData.similarParaBySections,
        // similarDocs: this.props.fileClusterData.similarDocs,
        // selectedSimilarDoc: this.props.fileClusterData.similarDocs[0]?.fileId,
        // selectedSimilarDocUrl: this.props.fileClusterData.similarDocs[0]
        // ?.filePath,
        isLoading: false,
        fileMainData: this.props.fileClusterData,
        // text,
        fileStatus: this.props.fileClusterData?.status,
        editHistoryData: newEditHistoryData,
      });

      this.props.documentAnalyticsGetRequest({
        fileId: this.props.fileClusterData?.fileId,
      });
    }

    if (
      prevProps.createFileCommentsLoading &&
      !this.props.createFileCommentsLoading &&
      this.props.createFileComment
    ) {
      let state = this.props.location.state;
      this.props.getFileCommentRequest({ fileId: state?.fileId });
    }

    if (
      prevProps.isAllMainSubTagsLoading &&
      !this.props.isAllMainSubTagsLoading &&
      this.props.allMainSubTags
    ) {
      this.setState({ allMainSubTags: this.props.allMainSubTags });
    }

    if (
      prevProps.isParaFeedbackAddLoading &&
      !this.props.isParaFeedbackAddLoading &&
      this.props.paraFeedbackAdd
    ) {
      this.props.fileClusterDataRequest({
        fileId: state?.fileId,
        filePath: state?.filePath,
      });
    }

    if (
      prevProps.isSimilarParaDataLoading &&
      !this.props.isSimilarParaDataLoading &&
      this.props.similarParaData
    ) {
      // console.log(this.props.similarParaData);
      this.setState({
        similarParaData: this.props.similarParaData?.similarBySections,
        isSimilarParsDataLoading: false,
      });
    }

    if (
      prevProps.isSimilarDocDataLoading &&
      !this.props.isSimilarDocDataLoading &&
      this.props.similarDocData
    ) {
      this.setState({
        selectedSimilarDoc: this.props.similarDocData[0]?.fileId,
        selectedSimilarDocUrl: this.props.similarDocData[0]?.filePath,
        similarDocs: this.props.similarDocData,
        similarDocClusterDataLoading: true,
      });
      this.props.similarDocFileClusterDataRequest({
        fileId: this.props.similarDocData[0]?.fileId,
      });
    }

    if (
      prevProps.isSimilarDocFileClusterDataLoading &&
      !this.props.isSimilarDocFileClusterDataLoading &&
      !this.props.similarDocFileClusterData
    ) {
      this.setState({
        similarDocClusterDataLoading: false,
      });
    }

    if (
      prevProps.isSimilarDocFileClusterDataLoading &&
      !this.props.isSimilarDocFileClusterDataLoading &&
      this.props.similarDocFileClusterData
    ) {
      let subTags = [];
      let sortedData =
        this.props.similarDocFileClusterData &&
        this.props.similarDocFileClusterData.result.sort((a, b) => {
          return a.paraSeqId - b.paraSeqId;
        });

      sortedData &&
        sortedData.forEach((result) => {
          let keys = Object.keys(result.subTags);
          keys.forEach((key) => {
            let inserted = false;
            result.feedback &&
              result.feedback.forEach((feedback) => {
                if (feedback.orgTag === result.subTags[key]) {
                  subTags.push(feedback.customTag);
                  inserted = true;
                }
              });
            if (!inserted) {
              subTags.push(result.subTags[key]);
            }
          });
        });
      subTags = [...new Set(subTags)];

      this.setState({
        similarDocFileClusterData: sortedData,
        similarDocClusterDataLoading: false,
        similarDocFileClusterSubTags: subTags,
      });
    }

    if (
      prevProps.isDocumentAnalyticsHistoryCreateDataLoading &&
      !this.props.isDocumentAnalyticsHistoryCreateDataLoading &&
      this.props.documentAnalyticsHistoryCreateData
    ) {
      this.props.documentAnalyticsGetRequest({
        fileId: this.state.fileClusterData[0].fileId,
      });
    }

    if (
      prevProps.deleteFileCommentLoading &&
      !this.props.deleteFileCommentLoading &&
      this.props.deletedFileComment
    ) {
      let state = this.props.location.state;
      this.props.getFileCommentRequest({ fileId: state?.fileId });
    }

    if (
      prevProps.updateFileCommentLoading &&
      !this.props.updateFileCommentLoading &&
      this.props.updatedFileComment
    ) {
      let state = this.props.location.state;
      this.props.getFileCommentRequest({ fileId: state?.fileId });
    }
  }

  myUndo = () => {
    let myEditor = this.reactQuillRef.getEditor();
    return myEditor.history.undo();
  };

  myRedo = () => {
    let myEditor = this.reactQuillRef.getEditor();
    return myEditor.history.redo();
  };

  handleLastEdit = () => {
    this.setState({ lastEditOpen: !this.state.lastEditOpen });
  };

  openActionPopup = (index) => {
    if (this.state.selectedAction === index) {
      this.setState({ selectedAction: null });
    } else {
      this.setState({ selectedAction: index });
    }
  };

  closeActionPopup = () => {
    if (this.state.selectedAction) {
      this.setState({ selectedAction: null });
    }
  };

  handleHistoryActiveDot = (index) => {
    this.setState({ historyActiveDot: index });
  };

  toggleRelatedDocumentSection = () => {
    const { showRelatedDocumentSection } = this.state;
    this.setState({
      showRelatedDocumentSection: !showRelatedDocumentSection,
    });
  };

  toggleInfoPanel = () => {
    const { showInfoPanel } = this.state;
    this.setState({
      showInfoPanel: !showInfoPanel,
    });
  };
  closeInfoPanel = () => {
    this.setState({
      showInfoPanel: false,
    });
  };

  handleSelectChange = (event) => {
    this.setState({ selectedValues: event.target.value });
  };

  handleEditorChange = (value) => {
    this.setState({ text: value });
  };

  handlePrepareResponse = () => {
    if (this.state.restoringHistory) {
      if (this.state.isPrepareResponse) {
        let docData = this.state.fileClusterData;
        this.setState({ isPrepareResponse: false });
        this.props.documentAnalyticsCreateRequest({
          fileId: docData[0].fileId,
          referenceId: 'hkdjshdkhsda',
          catalogId: 'jksjdsadsad',
          responseData: this.state.text,
        });
        // this.props.documentAnalyticsGetRequest({
        //   fileId: docData[0].fileId,
        // });
      } else {
        this.setState({ isPrepareResponse: true });
      }
    } else {
      if (this.state.isPrepareResponse && this.state.fileClusterData) {
        let docData = this.state.fileClusterData;
        this.setState({ isPrepareResponse: false });
        this.props.documentAnalyticsCreateRequest({
          fileId: docData[0].fileId,
          referenceId: 'hkdjshdkhsda',
          catalogId: 'jksjdsadsad',
          responseData: this.state.text,
        });
        // this.props.documentAnalyticsGetRequest({
        //   fileId: docData[0].fileId,
        // });
      } else {
        let text = '';
        if (
          this.state.text?.length > 0 &&
          !this.state.restoringInitialVersion
        ) {
          this.setState({ isPrepareResponse: true });
        } else {
          this.state.fileClusterData &&
            this.state.fileClusterData.forEach((result) => {
              if (result.paraHtml) {
                text += result.paraHtml;
                text += '<br/>';
                text += '<br/>';
              } else {
                text += result.paraText;
                text += '<br/>';
                text += '<br/>';
              }
            });
          this.setState({ isPrepareResponse: true, text });
        }
      }
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handleSearchTags = (search) => {
    if (!search) {
      this.setState({ filteredSubTags: this.state.subTags });
    } else {
      let filtered = this.state.filteredSubTags.filter((subTag) => {
        return subTag.toLowerCase().includes(search);
      });
      this.setState({ filteredSubTags: filtered });
    }
  };

  handleModelChange = (model) => {
    this.setState({
      text: model,
    });
    clearTimeout(this.state.timeOut);
    let docData = this.state.fileClusterData;
    const timeOut = setTimeout(() => {
      this.props.documentAnalyticsCreateRequest({
        fileId: docData[0].fileId,
        referenceId: 'hkdjshdkhsda',
        catalogId: 'jksjdsadsad',
        responseData: model,
        editedBy: this.props.userName,
      });
    }, 600000);
    this.setState({ timeOut });
  };

  handleDocumentChange = (fileId) => {
    // let docs = this.state.similarDocs;
    // let url = '';
    // docs.forEach((doc) => {
    //   if (doc.fileId === index) {
    //     url = doc.filePath;
    //   }
    // });
    this.setState({
      selectedSimilarDoc: fileId,
      similarDocClusterDataLoading: true,
    });
    this.props.similarDocFileClusterDataRequest({
      fileId: fileId,
    });
  };

  handleParaClick = (index) => {
    let arr = [...this.state.selectedParaIndex, index];
    this.setState({ selectedParaIndex: arr, isTagSelected: '' });
  };

  closeParaPopup = () => {
    if (this.state.selectedParaIndex.length > 0) {
      this.setState({ selectedParaIndex: [], isTagSelected: '' });
    }
  };

  handleTagClick = (subTag) => {
    let docs = this.state.fileClusterData;
    // let selectedTag = this.state.selectedParaIndex;
    for (let i = 0; i < docs.length; i++) {
      let keys = Object.keys(docs[i].subTags);
      let feedbackArr = [];
      // let oldFeedbackArr = [];
      docs[i].feedback &&
        docs[i].feedback.forEach((feedback) => {
          feedbackArr.push(feedback.customTag);
        });
      for (let j = 0; j < keys.length; j++) {
        let skip = false;
        docs[i].feedback &&
          docs[i].feedback.forEach((feedback) => {
            if (
              feedback.orgTag === docs[i].subTags[keys[j]] &&
              subTag !== feedback.customTag
            ) {
              skip = true;
            }
          });

        if (
          (subTag === docs[i].subTags[keys[j]] ||
            feedbackArr.includes(subTag)) &&
          !skip
        ) {
          // this.setState({ selectedParaIndex: i, isTagSelected: subTag });
          if (
            this.state.selectedParaIndex.includes(i) &&
            this.state.isTagSelected.includes(subTag)
          ) {
            this.setState((prevState) => {
              let tempArr = [];
              prevState.isTagSelected.forEach((tag) => {
                keys.forEach((key) => {
                  if (tag === docs[i].subTags[key]) {
                    tempArr.push(tag);
                  }
                });
              });
              let filteredTag = prevState.selectedParaIndex;
              if (tempArr.length <= 1) {
                // console.log(i);
                filteredTag = prevState.selectedParaIndex.filter((tag) => {
                  return tag !== i;
                });
              }
              let filteredSelectedTags = prevState.isTagSelected.filter(
                (tag) => tag !== subTag
              );
              // console.log(filteredSelectedTags);
              if (filteredSelectedTags.length === 0) {
                return {
                  selectedParaIndex: [],
                  isTagSelected: [],
                };
              } else {
                return {
                  selectedParaIndex: filteredTag,
                  isTagSelected: filteredSelectedTags,
                };
              }
            });
          } else {
            this.setState((prevState) => {
              let tags = [...new Set([...prevState.selectedParaIndex, i])];

              let el = document.getElementById(
                `analytics-scroll-to-${tags[0]}`
              );
              if (el) {
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                });
              }

              // let tags = [...prevState.selectedParaIndex, i];
              let selectedTags = prevState.isTagSelected;
              if (prevState.isTagSelected.includes(subTag)) {
                return {
                  selectedParaIndex: tags,
                  isTagSelected: [...selectedTags],
                };
              } else {
                return {
                  selectedParaIndex: tags,
                  isTagSelected: [...selectedTags, subTag],
                };
              }
            });
          }
          // break;
        }
      }
    }
  };

  handleSimilarDocTagClick = (subTag) => {
    let docs = this.state.similarDocFileClusterData;
    for (let i = 0; i < docs.length; i++) {
      let keys = Object.keys(docs[i].subTags);
      for (let j = 0; j < keys.length; j++) {
        if (subTag === docs[i].subTags[keys[j]]) {
          if (
            this.state.similarDocsSelectedParaIndex.includes(i) &&
            this.state.isSimilarDocsTagSelected.includes(subTag)
          ) {
            this.setState((prevState) => {
              let tempArr = [];
              prevState.isSimilarDocsTagSelected.forEach((tag) => {
                keys.forEach((key) => {
                  if (tag === docs[i].subTags[key]) {
                    tempArr.push(tag);
                  }
                });
              });
              let filteredTag = prevState.similarDocsSelectedParaIndex;
              if (tempArr.length <= 1) {
                // console.log(i);
                filteredTag = prevState.similarDocsSelectedParaIndex.filter(
                  (tag) => {
                    return tag !== i;
                  }
                );
              }
              let filteredSelectedTags = prevState.isSimilarDocsTagSelected.filter(
                (tag) => tag !== subTag
              );
              // console.log(filteredSelectedTags);
              if (filteredSelectedTags.length === 0) {
                return {
                  similarDocsSelectedParaIndex: [],
                  isSimilarDocsTagSelected: [],
                };
              } else {
                return {
                  similarDocsSelectedParaIndex: filteredTag,
                  isSimilarDocsTagSelected: filteredSelectedTags,
                };
              }
            });
          } else {
            this.setState((prevState) => {
              let tags = [
                ...new Set([...prevState.similarDocsSelectedParaIndex, i]),
              ];

              let el = document.getElementById(
                `analytics-similar-docs-scroll-to-${tags[0]}`
              );
              if (el) {
                el.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                });
              }

              // let tags = [...prevState.selectedParaIndex, i];
              let selectedTags = prevState.isSimilarDocsTagSelected;
              if (prevState.isSimilarDocsTagSelected.includes(subTag)) {
                return {
                  similarDocsSelectedParaIndex: tags,
                  isSimilarDocsTagSelected: [...selectedTags],
                };
              } else {
                return {
                  similarDocsSelectedParaIndex: tags,
                  isSimilarDocsTagSelected: [...selectedTags, subTag],
                };
              }
            });
          }
        }
      }
    }
  };

  handleSimilarParaModal = (paraSeqId, paraText, subTags) => {
    this.setState({
      isSimilarParaModalOpen: !this.state.isSimilarParaModalOpen,
      similarParaSeqId: paraSeqId,
      isSimilarParsDataLoading: true,
      similarParaData: null,
    });
    if (subTags) {
      this.props.similarParaDataRequest({ paraText, subTags });
    } else {
      this.setState({ selectedParaIndex: [], selectedSimilarParaIndex: null });
    }
  };

  copyHandler = (text) => {
    navigator.clipboard.writeText(text);
  };

  formatDate = (date) => {
    let months = [
      'JAN',
      'FEB',
      'MARCH',
      'APR',
      'MAY',
      'JUNE',
      'JULY',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];
    let day = new Date(date).getDate();
    let month = new Date(date).getMonth();
    let year = new Date(date).getFullYear();
    return `${day} ${months[month]} ${year}`;
  };

  goBackHandler = () => {
    this.props.history.goBack();
  };

  restoreHistory = (fileVersion) => {
    if (fileVersion === 'initial') {
      this.setState({
        restoringInitialVersion: true,
        selectedAction: null,
        restoringHistory: false,
      });
    } else {
      this.setState({ restoringHistory: true, selectedAction: null });
      this.props.documentAnalyticsGetRequest({
        fileVersion,
      });
    }
  };

  handleSelectedText = (startIndex, endIndex) => {
    if (
      this.state.isSubmittedForReview ||
      this.state.fileStatus === 'in-review'
    ) {
      // console.log(startIndex, endIndex);
      let commentCount = this.state.fileComments?.length
        ? this.state.fileComments.length
        : 0;
      this.setState({
        isCommenting: true,
        commentCount,
        commentStartIndex: startIndex,
        commentEndIndex: endIndex,
      });
    }
  };

  handleCommentCancel = () => {
    this.setState({
      isCommenting: false,
      commentCount: null,
      commentStartIndex: null,
      commentEndIndex: null,
    });

    // let element = document.querySelector("[data-id='new-comment']");
    // element.style.removeProperty('background-color');
  };

  submitComment = () => {
    const {
      commentCount,
      commentStartIndex,
      commentEndIndex,
      fileClusterData,
      commentText,
    } = this.state;
    this.props.createFileCommentRequest({
      fileId: fileClusterData[0].fileId,
      commentCount,
      startIndex: commentStartIndex,
      endIndex: commentEndIndex,
      comment: commentText,
    });
    this.setState({
      isCommenting: false,
      commentText: '',
      selectedParaIndex: [],
    });
  };

  submitUpdateComment = (id) => {
    let text = this.state.commentUpdateText;
    this.props.updateFileCommentRequest({ id, comment: text });
  };

  handleCommentChange = (event) => {
    this.setState({ commentText: event.target.value });
  };

  handleUpdateCommentChange = (event) => {
    this.setState({ commentUpdateText: event.target.value });
  };

  handleSimilarParaClick = (index) => {
    this.setState({ selectedSimilarParaIndex: index });
  };

  handleSimilarParaList = (paraData) => {
    // console.log(paraData);
    // let arr = [];
    // paraData &&
    //   paraData.forEach((data) => {
    //     // if (data.paraSeqId === paraSeqId) {
    //       arr.push(data);
    //     // }
    //   });
    paraData.sort((a, b) => a.rank - b.rank);
    return paraData;
  };

  generateGraph = (fileClusterData, subTags) => {
    // let colors = [];
    // var letters = '0123456789ABCDEF';
    // for (let index = 0; index < 100; index++) {
    //   let color = '#';
    //   for (var i = 0; i < 6; i++) {
    //     color += letters[Math.floor(Math.random() * 16)];
    //   }
    //   colors.push(color);
    // }
    // let generateRandomColor = (val, name) => {
    //   let total = val + name.length;
    //   let randomColor = '';
    //   if (total < 100) {
    //     randomColor = colors[total];
    //   } else {
    //     randomColor = colors[total - 100];
    //   }
    //   return randomColor;
    // };

    let obj = {};
    let dataArr = [];
    fileClusterData.forEach((file) => {
      let keys = file.subTags ? Object.keys(file.subTags) : [];
      if (keys.length) {
        if (file.subTags[keys[0]] !== 'No Tag') {
          if (obj[file.subTags[keys[0]]]) {
            obj[file.subTags[keys[0]]] = obj[file.subTags[keys[0]]] + 1;
          } else {
            obj[file.subTags[keys[0]]] = 1;
          }
        }
      }

      /**
       * ! Deprecated (Uses all tags to plot graph)
       */

      // keys.forEach((key) => {
      //   if (file.subTags[key] !== 'No Tag') {
      //     if (obj[file.subTags[key]]) {
      //       obj[file.subTags[key]] = obj[file.subTags[key]] + 1;
      //     } else {
      //       obj[file.subTags[key]] = 1;
      //     }
      //   }
      // });
    });
    let tags = Object.keys(obj);
    tags.forEach((tag) => {
      dataArr.push({ key: tag, value: obj[tag] });
    });

    let total = 0;
    let data = [];
    dataArr.forEach((tag, i) => {
      data.push(tag.value);
      total += tag.value;
    });
    dataArr.sort(function (a, b) {
      return a.value - b.value;
    });

    // var color = d3.scaleOrdinal().domain(data).range(d3.schemeCategory10);
    let color = (i) => {
      let colorArr = [
        '#0588BB',
        '#9CCA61',
        '#1B2438',
        '#C3C2BD',
        '#474C5C',
        '#F74C51',
        '#9CC95F',
        '#474C5C',
        '#F3C25C',
        '#BD167A',
        '#01A2E1',
        '#B6EB72',
        '#3F527B',
        '#EDEBE3',
        '#002087',
        '#F00E15',
        '#B7E777',
        '#6275B4',
        '#E7BE6A',
        '#133752',
        '#16BCFC',
        '#C2FA7A',
        '#6783C0',
        '#C2BDA5',
        '#082CA0',
        '#BC0F14',
        '#92F908',
        '#99A9DD',
        '#9F8141',
        '#731275',
        '#86D3F1',
        '#CBEAA4',
        '#7E9EE4',
        '#FFEC90',
        '#0B38C8',
        '#8C0D11',
        '#72BE0B',
        '#6E88DD',
        '#B48E3F',
        '#93088D',
        '#429ABC',
        '#82DA12',
        '#80A7FF',
        '#BBAE70',
        '#0D3EDC',
        '#FF9093',
        '#547A21',
        '#192753',
        '#FADDA2',
        '#E739C0',
        '#37768E',
        '#689D23',
        '#A7BEF0',
        '#D6C679',
        '#2955E2',
        '#FCBEC0',
        '#93C253',
        '#0A1536',
        '#BFA56E',
        '#B871B1',
        '#309CC7',
        '#4F7918',
        '#142447',
        '#FFFFFF',
        '#4870F1',
        '#62090B',
        '#B7D194',
        '#7882A4',
        '#936609',
        '#C00781',
      ];
      if (i >= colorArr.length) {
        let num = Math.floor(Math.random() * colorArr.length);
        return colorArr[num];
      }
      return colorArr[i];
    };

    let finalArr = [];
    dataArr.forEach((data, i) => {
      // let percent = Math.floor((data.value / total) * 100);
      let percent = (data.value / total) * 100;
      percent = percent % 1 === 0 ? percent : percent.toFixed(2);
      finalArr.push({
        name: data.key,
        color: color(dataArr.length - 1 - i),
        percent,
      });
    });
    finalArr.sort((a, b) => b.percent - a.percent);
    this.setState({ issuesPercent: finalArr });

    var height = 20;
    var perc_so_far = 0;

    let tooltipDiv = d3
      .select(this.ref.current)
      .append('div')
      .attr('class', 'issues_addressed_tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('color', '#fff')
      .style('background', '#C4C4C4')
      .style('border-radius', '4px');

    var total_time = d3.sum(data);
    d3.select(this.ref.current).selectAll('svg').remove();
    var chart = d3
      .select(this.ref.current)
      .append('svg')
      .attr('width', '20px')
      .attr('height', '100%');

    var bar = chart.selectAll('g').data(dataArr.reverse()).enter().append('g');
    bar
      .append('rect')
      .attr('width', height)
      .attr('y', function (d) {
        var prev_perc = perc_so_far;
        var this_perc = 100 * (d.value / total_time);
        perc_so_far = perc_so_far + this_perc;
        return prev_perc + '%';
      })
      .attr('height', function (d) {
        return (d.value / total_time) * 100 + '%';
      })
      .attr('fill', function (d, i) {
        // console.log(i);
        return color(i);
      });

    bar.on('mouseover', (d, data) => {
      // console.log(d);
      tooltipDiv.transition().duration(200).style('opacity', 1);
      tooltipDiv
        .html(`${data.key} : ${((data.value / total) * 100).toFixed(2)}%`)
        .style('left', d.pageX - 60 + 'px')
        .style('top', d.pageY - 220 + 'px')
        .style('padding', '9px 11px')
        .append('span')
        .attr('class', 'issues_addressed_tooltip_arrow');
    });

    bar.on('mouseout', (d) => {
      tooltipDiv.transition().duration(500).style('opacity', 0);
    });
  };

  handleSubmitReview = () => {
    this.setState({ isSubmittedForReview: true, fileStatus: 'in-review' });
    this.props.updateFileStatusRequest({
      status: '1',
      fileId: this.props.location.state?.fileId,
    });
  };

  handleShare = () => {
    const { showShare } = this.state;
    this.setState({ showShare: !showShare });
  };

  closeSharePanel = () => {
    this.setState({ showShare: false });
  };

  handleAddFeedback = (
    fileId,
    paraId,
    paraSeqId,
    orgSubTag,
    subTags,
    mainTag
  ) => {
    this.props.paraFeedbackAddRequest({
      fileId,
      paraId,
      paraSeqId,
      subTags,
      mainTag,
      orgTag: orgSubTag,
    });
  };

  handleCommentFilter = (target, commentCount) => {
    if (this.state.selectedCommentCount === commentCount) {
      this.setState({
        filteredFileComments: null,
        selectedCommentCount: null,
      });
      target.style.background = '#d1eedb';
    } else {
      let previousTarget = this.state.previousTarget;
      if (previousTarget) {
        previousTarget.style.background = '#d1eedb';
      }
      let data = this.state.fileComments;
      let filteredData = data.filter(
        (obj) => obj.commentCount === commentCount
      );
      this.setState({
        filteredFileComments: filteredData,
        selectedCommentCount: commentCount,
        previousTarget: target,
      });
      target.style.background = '#1ba94c';
    }
  };

  handleExpandPane2 = () => {
    this.setState({
      size1: 10,
      size2: 90,
      splitPane2Expanded: true,
    });
  };
  resetSplitPane2 = () => {
    this.setState({
      size1: 90,
      size2: 10,
      splitPane2Expanded: false,
    });
  };

  handleOnDrag = (sizes) => {
    this.setState({
      size1: sizes[0],
      size2: sizes[1],
    });
  };
  handleDragEnd = (sizes) => {
    this.setState({
      splitPane2Expanded: true,
    });
  };

  delayedExec = (after, fn) => {
    var timer;
    return () => {
      timer && clearTimeout(timer);
      timer = setTimeout(fn, after);
    };
  };

  scrollStopper = (ref, name) => {
    this.delayedExec(1000, () => {
      if (ref && ref.current) {
        ref.current.classList.remove(name);
      }
    })();
  };

  handleScrollBar = (ref, name) => {
    if (ref.current.classList.contains(name)) {
      this.scrollStopper(ref, name);
    } else {
      ref.current.classList.add(name);
    }
  };

  handleCommentDelete = (id) => {
    this.props.deleteFileCommentRequest({ id });
  };

  render() {
    const {
      showInfoPanel,
      lastEditOpen,
      text,
      isPrepareResponse,
      filteredSubTags,
      filerSearchValue,
      fileClusterData,
      selectedSimilarDoc,
      similarDocs,
      selectedParaIndex,
      isTagSelected,
      isSimilarParaModalOpen,
      editHistoryData,
      citationData,
      selectedAction,
      restoringHistory,
      fileComments,
      isCommenting,
      commentText,
      selectedSimilarParaIndex,
      selectedSimilarDocUrl,
      isLoading,
      issuesPercent,
      fileMainData,
      isSubmittedForReview,
      allMainSubTags,
      similarDocFileClusterData,
      similarDocClusterDataLoading,
      similarDocFileClusterSubTags,
      similarParaData,
      isSimilarParsDataLoading,
      showShare,
      similarDocsSelectedParaIndex,
      isSimilarDocsTagSelected,
      filteredFileComments,
      selectedCommentCount,
      fileStatus,
      restoringInitialVersion,
      size1,
      size2,
      splitPane2Expanded,
      historyActiveDot,
      commentUpdateText,
    } = this.state;

    // console.info('size1: ', size1, 'size2: ', size2)
    let fileNameToShow = this.props.fileClusterData?.fileName
      .replace(/%20/g, ' ')
      .replace(/%28/g, ' ')
      .replace(/%29/g, ' ');

    // console.log("fileNameToShow", fileNameToShow)

    if (fileNameToShow?.includes('pdf_')) {
      fileNameToShow = fileNameToShow.substr(
        0,
        this.props.fileClusterData?.fileName.lastIndexOf('pdf_')
      );
    }
    if (fileNameToShow?.includes('pdfx_')) {
      fileNameToShow = fileNameToShow.substr(
        0,
        this.props.fileClusterData?.fileName.lastIndexOf('pdfx_')
      );
    }

    return (
      <div className={styles.root}>
        <NavBar />
        <Sidebar />
        <RightBar />
        {/* {isLoading ? (
          <Loader />
        ) : ( */}
        <div
          className={`${styles.main_container} document_analytics_main_container`}
        >
          <div
            className={`${styles.split_pane_wrapper} document_analytics_pane_container`}
          >
            {/* <SplitPane split="horizontal" defaultSize="90%"> */}
            {/* <Pane> */}
            <Split
              sizes={[size1, size2]}
              minSize={90}
              expandToMin={false}
              gutterSize={5}
              gutterAlign={'center' | 'start' | 'end'}
              // snapOffset={30}
              dragInterval={1}
              direction="vertical"
              cursor="row-resize"
              onDrag={this.handleOnDrag}
              onDragEnd={this.handleDragEnd}
            >
              <section className={styles.upper_part_root}>
                <div className={styles.header}>
                  <div
                    className={styles.section_header_left_file_info_container}
                  >
                    <div
                      className={styles.back_arrow}
                      onClick={this.goBackHandler}
                    >
                      <img src={BackArrowSVG} alt="Back Arrow" />
                    </div>
                    <img src={BlueFileSVG} alt="Blue File" />
                    <div className={styles.heading_container}>
                      {/* <div className={styles.heading}>../PATH NAME/PATH</div> */}
                      <div className={styles.sub_heading}>
                        {this.props.fileClusterData?.fileName && fileNameToShow}
                      </div>
                    </div>
                  </div>

                  {fileStatus ? (
                    <div
                      className={
                        fileStatus === 'in-review'
                          ? styles.file_status_container_green
                          : styles.file_status_container
                      }
                    >
                      <div
                        className={
                          fileStatus === 'in-review'
                            ? styles.file_status_indicator_green
                            : styles.file_status_indicator
                        }
                      ></div>
                      <p>{fileStatus.toUpperCase()}</p>
                    </div>
                  ) : null}

                  <div className={styles.icon_container}>
                    <OutsideClickHandler onOutsideClick={this.closeSharePanel}>
                      <span
                        onClick={this.handleShare}
                        className={
                          showShare
                            ? `${styles.share_icon} ${styles.share_icon_active}`
                            : styles.share_icon
                        }
                      >
                        {showShare ? (
                          <IconContext.Provider
                            value={{
                              size: 21,
                              color: '#fff',
                            }}
                          >
                            <FiShare2 />
                          </IconContext.Provider>
                        ) : (
                          <IconContext.Provider
                            value={{
                              size: 21,
                              color: '#9a9a9a',
                            }}
                          >
                            <FiShare2 />
                          </IconContext.Provider>
                        )}
                      </span>
                      <div>
                        {showShare && (
                          <Share
                            index={5}
                            top={'45px'}
                            left={'-1px'}
                            right={'90px'}
                            fileId={this.props.location.state?.fileId}
                          />
                        )}
                      </div>
                    </OutsideClickHandler>

                    <OutsideClickHandler onOutsideClick={this.closeInfoPanel}>
                      <span
                        className={styles.info_icon}
                        onClick={this.toggleInfoPanel}
                        style={
                          showInfoPanel
                            ? {
                                backgroundColor: '#11256D',
                              }
                            : {}
                        }
                      >
                        <IconContext.Provider
                          value={
                            showInfoPanel
                              ? {
                                  size: 21,
                                  color: '#fff',
                                }
                              : {
                                  size: 21,
                                  color: '#9a9a9a',
                                }
                          }
                        >
                          <AiOutlineInfoCircle />
                        </IconContext.Provider>
                      </span>
                      {showInfoPanel && (
                        <div className={styles.info_panel}>
                          <div>
                            <span>Created On</span>
                            <span>
                              {this.formatDate(
                                this.props.fileClusterData?.created
                              )}
                            </span>
                          </div>
                          <div>
                            <span>Last Edit</span>
                            <span>
                              {this.formatDate(
                                editHistoryData[0]?.updatedAt
                                  ? editHistoryData[0].updatedAt
                                  : this.props.fileClusterData?.created
                              )}
                            </span>
                          </div>
                          <div>
                            <span>Authors</span>
                            <div className={styles.authors}>
                              <span>
                                {this.props.fileClusterData?.uploadedBy}
                              </span>
                              {/* <span>Marvin McKinney</span> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </OutsideClickHandler>
                  </div>
                </div>

                <div
                  className={styles.editor_and_middle_part_wrapper}
                  ref={this.middlePartRef}
                  onScroll={() =>
                    this.handleScrollBar(
                      this.middlePartRef,
                      'document_analytics_middle_part_scrolling'
                    )
                  }
                >
                  <section className={styles.middle_part}>
                    <section className={styles.middle_part_left_container}>
                      <div className={styles.left_heading}>
                        ISSUES ADDRESSED
                      </div>

                      <div className={styles.issues_wrapper}>
                        <div className={styles.issues_bar}>
                          <div ref={this.ref} />
                        </div>

                        <div
                          className={styles.issue_container}
                          ref={this.issueAddressedRef}
                          onScroll={() =>
                            this.handleScrollBar(
                              this.issueAddressedRef,
                              'document_analytics_issue_address_scrolling'
                            )
                          }
                        >
                          {issuesPercent &&
                            issuesPercent.map((issue) => {
                              return issue.percent <= 0 ? null : (
                                <div className={styles.issue_details}>
                                  <span
                                    className={styles.issue_color_1}
                                    style={{ backgroundColor: issue.color }}
                                  ></span>
                                  <span className={styles.issue_title}>
                                    {issue.name}
                                  </span>
                                  <span className={styles.issue_percentage}>
                                    {issue.percent}%
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <div className={styles.citations_container}>
                        <div className={styles.citations_heading}>
                          CITATIONS
                        </div>
                        <div
                          className={styles.citations_list}
                          ref={this.citationsRef}
                          onScroll={() =>
                            this.handleScrollBar(
                              this.citationsRef,
                              'document_analytics_citations_scrolling'
                            )
                          }
                        >
                          {citationData &&
                            citationData.map((data, index) => (
                              <div className={styles.citation_list_container}>
                                <span>{index + 1}</span>
                                <span className={styles.citation_title}>
                                  {data}
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </section>
                    <section
                      className={`${styles.middle_part_middle_container} document_analytics_edit`}
                    >
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <div className={styles.middle_part_middle_wrapper}>
                          {isPrepareResponse &&
                          fileClusterData &&
                          !isSubmittedForReview ? (
                            <div className={styles.editor_container}>
                              <ReactQuill
                                theme="snow"
                                modules={{
                                  toolbar: {
                                    container: [
                                      ['undo'],
                                      ['redo'],
                                      ['bold', 'italic', 'underline', 'strike'],
                                      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                                      [{ indent: '-1' }, { indent: '+1' }],
                                      [{ align: [] }],
                                    ],
                                    handlers: {
                                      undo: this.myUndo,
                                      redo: this.myRedo,
                                    },
                                  },
                                }}
                                onChange={(e) => this.handleModelChange(e)}
                                value={text}
                                ref={(el) => {
                                  this.reactQuillRef = el;
                                }}
                              />
                            </div>
                          ) : (
                            <div
                              className={
                                fileStatus === 'in-review'
                                  ? styles.document_container_review
                                  : styles.document_container
                              }
                              ref={this.centerRef}
                              onScroll={() =>
                                this.handleScrollBar(
                                  this.centerRef,
                                  'document_analytics_center_docs'
                                )
                              }
                            >
                              {editHistoryData &&
                              !isSubmittedForReview &&
                              editHistoryData.length > 1 &&
                              fileStatus !== 'in-review' ? (
                                <div
                                  className={
                                    styles.restore_secondary_div_container
                                  }
                                >
                                  <img src={BackArrowSVG} alt="back arrow" />
                                  <div
                                    className={
                                      styles.restore_secondary_div_info
                                    }
                                  >
                                    <img
                                      src={greenDotIcon}
                                      alt="green dot"
                                      className={
                                        styles.middle_restore_green_dot
                                      }
                                    />
                                    <p
                                      className={
                                        styles.restore_secondary_div_time
                                      }
                                    >
                                      {`${this.formatDate(
                                        editHistoryData[0].createdAt
                                      )} • ${moment(
                                        editHistoryData[0].createdAt
                                      ).format('hh:mm A')}`}
                                    </p>
                                    <p
                                      className={
                                        styles.restore_secondary_div_name
                                      }
                                    >
                                      {editHistoryData[0].editedBy}
                                    </p>
                                  </div>
                                  <div
                                    className={
                                      styles.restore_previous_version_btn
                                    }
                                    onClick={() => {
                                      this.handleHistoryActiveDot(0);
                                      this.restoreHistory(
                                        editHistoryData[0].fileVersion
                                      );
                                    }}
                                  >
                                    RESTORE
                                  </div>
                                </div>
                              ) : null}

                              {isSubmittedForReview ||
                              fileStatus === 'in-review' ? (
                                <HighlightEditor
                                  data={text}
                                  selectedCommentCount={selectedCommentCount}
                                  fileComments={fileComments}
                                  handleCommentFilter={this.handleCommentFilter}
                                  handleSelectedText={this.handleSelectedText}
                                  isCommenting={isCommenting}
                                />
                              ) : (!restoringInitialVersion &&
                                  editHistoryData?.length > 1) ||
                                restoringHistory ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: text,
                                  }}
                                  className={styles.text_html_container}
                                ></div>
                              ) : (
                                <div style={{ width: '100%' }}>
                                  {/* {!isSubmittedForReview ? ( */}
                                  {fileStatus !== 'in-review' &&
                                  !isSubmittedForReview ? (
                                    <EditTags
                                      data={fileClusterData}
                                      isTagSelected={isTagSelected}
                                      handleParaClick={this.handleParaClick}
                                      closeActionPopup={this.closeParaPopup}
                                      selectedParaIndex={selectedParaIndex}
                                      handleSimilarParaModal={
                                        this.handleSimilarParaModal
                                      }
                                      fileComments={fileComments}
                                      handleSelectedText={
                                        this.handleSelectedText
                                      }
                                      isCommenting={isCommenting}
                                      fileType={fileMainData?.fileType}
                                      isSubmittedForReview={
                                        isSubmittedForReview
                                      }
                                      allMainSubTags={allMainSubTags}
                                      handleAddFeedback={this.handleAddFeedback}
                                      isSimilarParaModalOpen={
                                        isSimilarParaModalOpen
                                      }
                                    />
                                  ) : null}
                                </div>
                              )}

                              {/* <EditTags
                              data={fileClusterData}
                              isTagSelected={isTagSelected}
                              handleParaClick={this.handleParaClick}
                              closeActionPopup={this.closeParaPopup}
                              selectedParaIndex={selectedParaIndex}
                              handleSimilarParaModal={
                                this.handleSimilarParaModal
                              }
                            /> */}
                            </div>
                          )}
                        </div>
                      )}
                    </section>

                    <section className={styles.middle_part_right_container}>
                      {isSubmittedForReview ||
                      fileStatus === 'in-review' ? null : (
                        <EditHistory
                          lastEditOpen={lastEditOpen}
                          editHistoryData={editHistoryData}
                          filerSearchValue={filerSearchValue}
                          filteredSubTags={filteredSubTags}
                          isTagSelected={isTagSelected}
                          handleLastEdit={this.handleLastEdit}
                          formatDate={this.formatDate}
                          selectedAction={selectedAction}
                          closeActionPopup={this.closeActionPopup}
                          openActionPopup={this.openActionPopup}
                          handleSearchTags={this.handleSearchTags}
                          handleHistoryActiveDot={this.handleHistoryActiveDot}
                          historyActiveDot={historyActiveDot}
                          handleTagClick={this.handleTagClick}
                          restoreHistory={this.restoreHistory}
                          handleScrollBar={this.handleScrollBar}
                        />
                      )}

                      {isSubmittedForReview || fileStatus === 'in-review' ? (
                        <AddComment
                          data={
                            filteredFileComments
                              ? filteredFileComments
                              : fileComments
                          }
                          isCommenting={isCommenting}
                          handleCommentCancel={this.handleCommentCancel}
                          commentText={commentText}
                          commentUpdateText={commentUpdateText}
                          handleCommentChange={this.handleCommentChange}
                          handleUpdateCommentChange={
                            this.handleUpdateCommentChange
                          }
                          submitComment={this.submitComment}
                          submitUpdateComment={this.submitUpdateComment}
                          handleCommentDelete={this.handleCommentDelete}
                          handleScrollBar={this.handleScrollBar}
                        />
                      ) : null}

                      {/* {console.log('fjhfsahdasdakldhdashd', fileMainData)} */}

                      <div className={styles.btn_container}>
                        <div
                          onClick={() =>
                            fileMainData?.fileType === 'Notice' &&
                            !isSubmittedForReview &&
                            fileStatus !== 'in-review'
                              ? this.handlePrepareResponse()
                              : null
                          }
                          className={
                            fileMainData?.fileType === 'Notice' &&
                            !isSubmittedForReview &&
                            fileStatus !== 'in-review'
                              ? styles.prepare_response_btn
                              : styles.prepare_response_btn_disabled
                          }
                        >
                          {editHistoryData?.length > 1
                            ? isPrepareResponse
                              ? 'SAVE'
                              : 'EDIT RESPONSE'
                            : isPrepareResponse
                            ? 'SAVE'
                            : 'PREPARE RESPONSE'}
                        </div>
                        <div
                          className={
                            fileMainData?.fileType === 'Notice' &&
                            fileStatus !== 'in-review' &&
                            !isPrepareResponse
                              ? styles.submit_review_btn
                              : styles.submit_review_btn_disabled
                          }
                          onClick={() =>
                            fileMainData?.fileType === 'Notice' &&
                            (fileStatus !== 'in-review' || !isPrepareResponse)
                              ? this.handleSubmitReview()
                              : null
                          }
                        >
                          SUBMIT FOR REVIEW
                        </div>
                      </div>
                    </section>
                  </section>
                </div>
              </section>
              {/* </Pane> */}
              <section>
                {similarDocs && (
                  <DragDocument
                    similarDocs={similarDocs}
                    selectedSimilarDoc={selectedSimilarDoc}
                    handleDocumentChange={this.handleDocumentChange}
                    selectedSimilarDocUrl={selectedSimilarDocUrl}
                    similarDocFileClusterData={similarDocFileClusterData}
                    similarDocClusterDataLoading={similarDocClusterDataLoading}
                    similarDocFileClusterSubTags={similarDocFileClusterSubTags}
                    handleSimilarDocTagClick={this.handleSimilarDocTagClick}
                    similarDocsSelectedParaIndex={similarDocsSelectedParaIndex}
                    isSimilarDocsTagSelected={isSimilarDocsTagSelected}
                    handleExpandPane2={this.handleExpandPane2}
                    resetSplitPane2={this.resetSplitPane2}
                    splitPane2Expanded={splitPane2Expanded}
                    size={size2}
                  />
                )}
              </section>
            </Split>
            {/* </SplitPane> */}
          </div>
        </div>
        {/* )} */}

        {isSimilarParaModalOpen ? (
          <CommonModal
            modalClass={'documents_analytics_similar_para_modal'}
            backdropClassName={'documents_analytics_similar_para_model_blue_bg'}
            closeHandler={this.handleSimilarParaModal}
            staticBackdrop={true}
            header={
              <div className={`${styles.similar_para_header_container}`}>
                SIMILAR PARAGRAPHS
              </div>
            }
          >
            <div>
              {!isSimilarParsDataLoading && similarParaData?.length < 1 ? (
                <div>No Similar Para Found</div>
              ) : isSimilarParsDataLoading ? (
                <Loader />
              ) : (
                <div>
                  {this.handleSimilarParaList(similarParaData).map(
                    (para, index) => (
                      <div>
                        {selectedSimilarParaIndex === index ? (
                          <div
                            className={styles.similar_para_single_para_active}
                          >
                            <div
                              className={
                                styles.similar_para_single_para_heading
                              }
                            >
                              <div
                                className={
                                  styles.similar_para_single_para_heading_left
                                }
                              >
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_main
                                  }
                                >
                                  {para.fileName
                                    .replace(/%20/g, ' ')
                                    .replace(/%28/g, ' ')
                                    .replace(/%29/g, ' ')}
                                </div>
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_sub
                                  }
                                >
                                  YEAR {para.meta.year ? para.meta.year : null}
                                </div>
                              </div>
                              <div
                                className={
                                  styles.similar_para_single_para_heading_right
                                }
                              >
                                <img
                                  src={copyIcon}
                                  alt="copy btn"
                                  onClick={() =>
                                    this.copyHandler(para.paraText)
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={styles.similar_para_single_para_body}
                            >
                              {para.paraText}
                            </div>
                          </div>
                        ) : (
                          <div className={styles.similar_para_single_para}>
                            <div
                              className={
                                styles.similar_para_single_para_heading
                              }
                            >
                              <div
                                className={
                                  styles.similar_para_single_para_heading_left
                                }
                              >
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_main
                                  }
                                  onClick={() =>
                                    this.handleSimilarParaClick(index)
                                  }
                                >
                                  {para.fileName
                                    .replace(/%20/g, ' ')
                                    .replace(/%28/g, ' ')
                                    .replace(/%29/g, ' ')}
                                </div>
                                <div
                                  className={
                                    styles.similar_para_single_para_heading_left_sub
                                  }
                                >
                                  YEAR {para.meta.year ? para.meta.year : null}
                                </div>
                              </div>
                              <div
                                className={
                                  styles.similar_para_single_para_heading_right
                                }
                              >
                                <img
                                  src={copyIcon}
                                  alt="copy btn"
                                  onClick={() =>
                                    this.copyHandler(para.paraText)
                                  }
                                />
                              </div>
                            </div>
                            <div
                              className={styles.similar_para_single_para_body}
                            >
                              {`${
                                para.paraText.length > 500
                                  ? `${para.paraText.slice(0, 500)}...`
                                  : para.paraText
                              }`}
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </CommonModal>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    // uploadDoc: state.uploadDoc,
    // uploadedDoc: state.uploadedDoc,
    // isUploadedDocLoading: state.uploadedDoc.isLoading,
    // error: state.uploadedDoc.error,
    userName: state.homepage.userDetails.name,
    dashboard: state.dashboard,
    isSimilarDocsLoading: state.uploadedDoc.isSimilarDocsLoading,
    isSimilarParasLoading: state.uploadedDoc.isSimilarParasLoading,
    similarParasData: state.uploadedDoc.similarParas?.byTag,
    similarDocsData: state.uploadedDoc.similarDocs,
    documentAnalyticsHistory:
      state.documentAnalyticsHistory.documentAnalyticsHistoryGetData,
    isDocumentAnalyticsHistoryLoading:
      state.documentAnalyticsHistory.isDocumentAnalyticsHistoryGetDataLoading,
    documentAnalyticsHistoryCreateData:
      state.documentAnalyticsHistory.documentAnalyticsHistoryCreateData,
    isDocumentAnalyticsHistoryCreateDataLoading:
      state.documentAnalyticsHistory
        .isDocumentAnalyticsHistoryCreateDataLoading,
    isFileClusterDataLoading: state.fileClusterData.isLoading,
    fileClusterData: state.fileClusterData.filesStatus,
    isFileCommentsLoading: state.fileComment.isCommentsLoading,
    fileComments: state.fileComment.commentsData,
    createFileCommentsLoading: state.fileComment.isLoading,
    createFileComment: state.fileComment.createdComment,
    deleteFileCommentLoading: state.fileComment.isDeleteCommentLoading,
    deletedFileComment: state.fileComment.deletedComment,
    updateFileCommentLoading: state.fileComment.isUpdateCommentLoading,
    updatedFileComment: state.fileComment.updatedComment,
    paraFeedbackAdd: state.documentAnalyticsHistory.paraFeedbackAdd,
    isParaFeedbackAddLoading:
      state.documentAnalyticsHistory.isParaFeedbackAddLoading,
    allMainSubTags: state.documentAnalyticsHistory.allMainSubTags,
    isAllMainSubTagsLoading:
      state.documentAnalyticsHistory.isAllMainSubTgsLoading,
    similarParaData: state.documentAnalyticsHistory.similarParaData,
    isSimilarParaDataLoading:
      state.documentAnalyticsHistory.isSimilarParaDataLoading,
    similarDocData: state.documentAnalyticsHistory.similarDocData,
    isSimilarDocDataLoading:
      state.documentAnalyticsHistory.isSimilarDocDataLoading,
    isSimilarDocFileClusterDataLoading:
      state.fileClusterData.isSimilarDocFileClusterLoading,
    similarDocFileClusterData: state.fileClusterData.similarDocFileClusterData,
  };
};
const mapDispatchToProps = {
  clusterDataRequest: uploadedDoc.clusterDataRequest,
  similarParaRequest: uploadedDoc.similarParaRequest,
  similarDocsRequest: uploadedDoc.similarDocsRequest,
  documentAnalyticsGetRequest:
    documentAnalyticsHistory.documentAnalyticsHistoryGetRequest,
  documentAnalyticsCreateRequest:
    documentAnalyticsHistory.documentAnalyticsHistoryCreateRequest,
  fileTrackRequest: documentAnalyticsHistory.fileTrackRequest,
  updateFileStatusRequest: documentAnalyticsHistory.updateFileStatusRequest,
  fileClusterDataRequest: fileClusterData.fileClusterDataRequest,
  getFileCommentRequest: fileComment.getFileCommentRequest,
  createFileCommentRequest: fileComment.createFileCommentRequest,
  deleteFileCommentRequest: fileComment.deleteFileCommentRequest,
  updateFileCommentRequest: fileComment.updateFileCommentRequest,
  paraFeedbackAddRequest: documentAnalyticsHistory.paraFeedbackAddRequest,
  allMainSubTagsRequest: documentAnalyticsHistory.allMainSubTagsRequest,
  similarParaDataRequest: documentAnalyticsHistory.similarParaDataRequest,
  similarDocDataRequest: documentAnalyticsHistory.similarDocDataRequest,
  similarDocFileClusterDataRequest:
    fileClusterData.similarDocClusterDataRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAnalytics);
