import React, {Component} from 'react'
import styles from "./DeleteDocument.module.css";
import commonStyles from '../../../shared/Common.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';

class DeleteDocument extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }

    handleDeleteDocument = () =>{
        this.props.onDeleteDocument()
    }
 
    render() {
        return (
            <div>
                <CommonModal modalClass={'team_common_modal'} closeHandler={this.props.closeHandler} header={'Delete Document'}>
                    <p>Are you sure you want to delete this document?</p>
                <div className={styles.buttons_wrapper}>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                                onClick={this.props.closeHandler}>
                                CANCEL
                                </button>
                            <button
                                type="button"
                                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                                onClick={this.handleDeleteDocument}>
                                DELETE
                            </button>
                        </div>
                </CommonModal>
            </div>
        )
    }

}
export default DeleteDocument;
