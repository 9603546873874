import React, { Component } from "react";
import styles from "./UsersList.module.css";
// import EditMember from '../edit-member/EditMember'
// import commonStyles from '../../../shared/Common.module.css';

class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {memberToEdit:[]}
    }
    handleDeleteUser(userId) {
        const paramsObj = {
            userId: userId,
            isEditMemberModalOpen:false
        }
        this.props.ondeleteUser(paramsObj)
    }
    handleEditMember(userId){
        this.setState({isEditMemberModalOpen:true})
    const memberToEdit = this.props.members.filter(member => {
          return (member.userId === userId)
        })
        this.setState({
            memberToEdit:memberToEdit
        })
        
    }
    onEditMemberClose = () => {
this.setState({isEditMemberModalOpen:false})
    }
    render() {
        // const {memberToEdit, isEditMemberModalOpen} = this.state
        return (
            <div className={'thin_custom_scrollbar'}>
                <div className={styles.users_list_container}>
                    <table className={styles.users_list_table}>
                        <thead>
                            <tr className={styles.users_list_row_label}>
                                <th> <label>USER</label> </th>
                                <th ><label>EMAIL ADDRESS</label></th>
                                <th><label>DESIGNATION</label></th>
                                <th><label>ROLE ACCESS</label></th>
                            </tr>
                        </thead>
                        <tbody>

                            {this.props.members.map((data, index) => {
                                let member = data.name
                                let email = data.email
                                let designation = data.designation
                                let role = data.role
                                let userId = data.userId
                                return (
                                    <tr className={styles.users_list_row_content} key={index}>
                                        <td>
                                            <img className={`avatar ${styles.users_avatar}`} src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar"></img>
                                            <span className={styles.member}>{member}
                                            </span>
                                        </td>
                                        <td>
                                            <span>{email}
                                            </span>
                                        </td>
                                        <td>
                                            <span>{designation} </span>
                                        </td>
                                        <td>
                                            <span>{role} </span>
                                        </td>
                                        <td>
                                            <i onClick={() => this.handleEditMember(userId)} className="fas fa-pen"></i>
                                        </td>
                                        <td>
                                            <i onClick={() => this.handleDeleteUser(userId)} className="fas fa-times"></i>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* {isEditMemberModalOpen && memberToEdit.length===1 && <EditMember closeHandler={this.onEditMemberClose} memberToEdit={memberToEdit}></EditMember>} */}
            </div>
        )
    }
}
export default UsersList;