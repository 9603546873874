import * as types from '../types';

// Main Sub Tag Actions

export const mainSubTagsRequest = (payload) => ({ type: types.MAIN_SUB_TAGS_REQUEST, payload });
export const mainSubTagsSuccess = (payload) => ({ type: types.MAIN_SUB_TAGS_SUCCESS, payload });
export const mainSubTagsFailure = (payload) => ({ type: types.MAIN_SUB_TAGS_FAILURE, payload });

// Add Feedback Actions

export const addFeedbackRequest = (payload) => ({ type: types.ADD_FEEDBACK_REQUEST, payload });
export const addFeedbackSuccess = (payload) => ({ type: types.ADD_FEEDBACK_SUCCESS, payload });
export const addFeedbackFailure = (payload) => ({ type: types.ADD_FEEDBACK_FAILURE, payload });