import * as types from '../types'

const initialState = {
  isCreateDocumentLoading: false,
  createNewDocumentData: null,
  isFetchAllDocumentsLoading: false,
  fetchAllDocumentsData: null,
  isCreateDocumentDisplayed: false,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // Create New Document
    case types.CREATE_NEW_DOCUMENT_REQUEST:
      return {
        ...state,
        isCreateDocumentLoading: true,
      }
    case types.CREATE_NEW_DOCUMENT_SUCCESS: {
      return {
        ...state,
        isCreateDocumentLoading: false,
        createNewDocumentData: action.payload.result.data,
      }
    }
    case types.CREATE_NEW_DOCUMENT_FAILURE:
      return {
        ...state,
        isCreateDocumentLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Fetch All Documents
    case types.FETCH_ALL_PRIVATE_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetchAllDocumentsLoading: true,
      }
    case types.FETCH_ALL_PRIVATE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        isFetchAllDocumentsLoading: false,
        fetchAllDocumentsData: action.payload.result.data,
      }
    }
    case types.FETCH_ALL_DATA_SOURCE_FAILURE:
      return {
        ...state,
        isFetchAllDocumentsLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }
    case types.IS_CREATE_NEW_DOCUMENT_DISPLAYED:
      return {
        ...state,
        isCreateDocumentDisplayed: action.payload,
      }
    default: {
      return state
    }
  }
}
