import React, { Component } from "react";
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import styles from "./ClusteredResult.module.css";
import ClusteredParagraphs from "../../components/clustered-result/clustered-paragraphs/ClusteredParagraphs";
import ClusteredParagraphsDetails from "../../components/clustered-result/clustered-paragraphs-details/ClusteredParagraphsDetails";
import Sidebar from "../../shared/sidebar/Sidebar";
import * as uploadedDoc from "../../store/actions/uploadedDocActions";
import { connect } from "react-redux";

class ClusteredResult extends Component {
  constructor(props) {
    super(props);
    this.temp = [];
    this.state = {
      selectedTags: {},
      searchItem: "",
      stateTempo: [],
      activeTags: [],
      openSection: false,
    };
  }

  componentDidMount() {
    this.props.similarDocs(
      this.props.uploadedDoc.clusterData.data.fileVersion.fileId
    );
  }
  handleTagSelection = (tag) => {
    const selectedTags = { ...this.state.selectedTags };
    selectedTags[tag] = Object.keys(selectedTags).includes(tag)
      ? !selectedTags[tag]
      : true;
    this.setState({ selectedTags });
  };
  clearSelectedTags = () => {
    this.setState({ selectedTags: {} });
  };
  onHandleSearchChange = (searchItem) => {
    this.setState({ searchItem });
  };
  getClusteredData() {
    const data = this.props.uploadDoc.docData.data.result;
    const paramsObj = {
      fileId: data.fileId,
      fileVersion: data.version,
      fileVersionId: data.fileVersionId,
      filePath: data.filePath,
      version: data.version,
    };
    this.props.clusterDataRequest(paramsObj);
  }
  tempo = (tempoo) => {
    this.setState({ stateTempo: tempoo }, () => {});
  };
  setActiveTags = (activeTags) => {
    this.setState({ activeTags });
  };
  componentDidMount() {
    if (
      this.props.uploadDoc.docData &&
      this.props.uploadDoc.docData.data &&
      this.props.uploadDoc.docData.data.result
    ) {
    }
    // this.getClusteredData()
  }
  render() {
    const prepareResponse = this.props.uploadedDoc.prepareResponse;
    return (
      <div>
        <Sidebar></Sidebar>
        <NavigationBar
          onHeaderSearchChange={this.onHandleSearchChange}
          history={this.props.history}
        ></NavigationBar>

        <div className={styles.uploaded_doc_content_wrapper}>
          <div className={styles.uploaded_doc_section}>
            <ClusteredParagraphs
              history={this.props.history}
              setActiveTags={this.setActiveTags}
              selectedTags={this.state.selectedTags}
              searchItem={this.state.searchItem}
              clearSelectedTags={this.clearSelectedTags}
              renderSimilarPara={this.renderSimilarPara}
              showTopStrip={true}
            ></ClusteredParagraphs>
          </div>
          <div className={styles.doc_preview_section}
            
          >
            <ClusteredParagraphsDetails
              activeTags={this.state.activeTags}
              selectedTags={this.state.selectedTags}
              handleTagSelection={this.handleTagSelection}
              history={this.props.history}
              showTopStrip={true}
            ></ClusteredParagraphsDetails>
          </div>
        </div>
      </div>
    );
  }
  // this.getClusteredData()
}
// render() {
//   return (
//     <div>
//       <Sidebar></Sidebar>
//       <NavigationBar
//         onHeaderSearchChange={this.onHandleSearchChange}
//       ></NavigationBar>
//         <div className={styles.uploaded_doc_content_wrapper}>
//           <div className={styles.uploaded_doc_section}>
//             <ClusteredParagraphs
//               history={this.props.history}
//               selectedTags={this.state.selectedTags}
//               searchItem={this.state.searchItem}
//             ></ClusteredParagraphs>
//           </div>
//           <div className={styles.doc_preview_section}>
//             <ClusteredParagraphsDetails
//               selectedTags={this.state.selectedTags}
//               handleTagSelection={this.handleTagSelection}
//             ></ClusteredParagraphsDetails>
//           </div>
//         </div>
//     </div>
//   );
// }
// }

const mapStateToProps = (state) => {
  return {
    uploadDoc: state.uploadDoc,
    uploadedDoc: state.uploadedDoc,
  };
};
const mapDispatchToProps = {
  clusterDataRequest: uploadedDoc.clusterDataRequest,
  similarDocs: uploadedDoc.similarDocsRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClusteredResult);
