import React, { Component } from 'react'
import { DatePicker } from 'antd'
import 'antd/dist/antd.css'
import { IoIosArrowForward } from 'react-icons/io'
import { IconContext } from 'react-icons'
import { connect } from 'react-redux'

import * as catalogLandingPageActions from './../../../../store/actions/catalogLandingPageActions'

import CheckboxList from '../../checkbox-list/CheckboxList'
import styles from './AdvancedSearch.module.css'

class AdvancedSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showStartCalendar: false,
      showEndCalendar: false,
      tagSelected: 0,
      showSelected: false,
      key: this.props.data?.[0].key,
      areFiltersSelected: false,
      endDate: null,
      startDate: null,
    }

    this.checkboxListRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.catalogAdvancedSearchFilters !==
      this.props.catalogAdvancedSearchFilters
    ) {
      this.setState({
        areFiltersSelected:
          this.props.catalogAdvancedSearchFilters === null ? false : true,
      })
    }
  }

  toggleStartCalendar = () => {
    const { showStartCalendar } = this.state
    this.setState({
      showStartCalendar: !showStartCalendar,
    })
  }
  toggleEndCalendar = () => {
    const { showEndCalendar } = this.state
    this.setState({
      showEndCalendar: !showEndCalendar,
    })
  }
  changeTagSelected = index => {
    this.setState({
      tagSelected: index,
      key: this.props.data?.[index].key,
    })
  }
  clearAll = () => {
    const parent = this.props.parent
    if (parent === 'SearchResultsPage') {
      this.props.resetDataOnPage()
      this.checkboxListRef.current.resetState()
      this.props.closeAdvancedSearchPanel()
    } else {
      this.checkboxListRef.current.resetState()
    }
  }
  toggleShowSelected = e => {
    this.setState({
      showSelected: e.target.checked,
    })
  }

  startDateChange = (date, dateString) => {
    let year = new Date(dateString).getFullYear()
    this.setState({
      startDate: year,
    })
  }
  endDateChange = (date, dateString) => {
    let year = new Date(dateString).getFullYear()
    this.setState({
      endDate: year,
    })
  }

  getAdvancedSearchState = () => {
    return this.state
  }

  render() {
    const {
      showStartCalendar,
      showEndCalendar,
      tagSelected,
      key,
      showSelected,
      areFiltersSelected,
    } = this.state
    const { data } = this.props

    return (
      <div className={styles.root}>
        {/* Left Part */}
        <div className={styles.left}>
          <div className={styles.document_type}>
            <div className={styles.top}>
              {data?.map(({ title, key }, index) => (
                <div
                  className={`${styles.type_container}`}
                  style={
                    index === tagSelected
                      ? {
                          backgroundColor: '#11256d',
                          color: '#ffffff',
                        }
                      : { backgroundColor: '#ffffff', color: '#9A9A9A' }
                  }
                  onClick={e => this.changeTagSelected(index)}
                >
                  <div className={styles.tag}>{title}</div>
                  <div className={styles.icon_container}>
                    <IconContext.Provider
                      value={
                        index === tagSelected
                          ? { color: '#ffffff', size: '21px' }
                          : { color: '#9A9A9A', size: '21px' }
                      }
                    >
                      <div>
                        <IoIosArrowForward />
                      </div>
                    </IconContext.Provider>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.bottom}>
              <div className={styles.switch_container}>
                <label className={styles.switch}>
                  <input type="checkbox" onChange={this.toggleShowSelected} />
                  <span className={`${styles.slider} ${styles.round}`}></span>
                </label>
              </div>
              <div className={styles.bottom_text}>SELECTION</div>
            </div>
          </div>

          <div className={styles.documents_list}>
            <div className={styles.checkbox_container}>
              <CheckboxList
                ref={this.checkboxListRef}
                data={data}
                tagSelected={tagSelected}
                dataKey={key}
                width={'12px'}
                height={'12px'}
                showSelected={showSelected}
              />
            </div>
            <div className={styles.alphabets_list}>
              <div className={styles.alphabets}>A</div>
              <div className={styles.alphabets}>B</div>
              <div className={styles.alphabets}>C</div>
              <div className={styles.alphabets}>D</div>
              <div className={styles.alphabets}>E</div>
              <div className={styles.alphabets}>F</div>
              <div className={styles.alphabets}>I</div>
              <div className={styles.alphabets}>J</div>
              <div className={styles.alphabets}>K</div>
              <div className={styles.alphabets}>L</div>
              <div className={styles.alphabets}>M</div>
              <div className={styles.alphabets}>N</div>
              <div className={styles.alphabets}>O</div>
              <div className={styles.alphabets}>P</div>
              <div className={styles.alphabets}>Q</div>
              <div className={styles.alphabets}>R</div>
              <div className={styles.alphabets}>S</div>
              <div className={styles.alphabets}>T</div>
              <div className={styles.alphabets}>U</div>
              <div className={styles.alphabets}>V</div>
              <div className={styles.alphabets}>W</div>
              <div className={styles.alphabets}>X</div>
              <div className={styles.alphabets}>Y</div>
              <div className={styles.alphabets}>Z</div>
            </div>
          </div>
        </div>

        {/* Right Part */}
        <div className={styles.right}>
          <div className={styles.right_top}>
            <div className={styles.start_date}>
              <div className={styles.date_text}>Start Date</div>

              <div className={styles.calendar_container}>
                {/* <input
                  type="date"
                  name="startDate"
                  // onChange={}
                  // onClick={this.toggleStartCalendar}
                  onFocus={this.toggleStartCalendar}
                  onBlur={this.toggleStartCalendar}
                  style={
                    showStartCalendar
                      ? {
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundPositionX: '90%',
                          backgroundPositionY: '50%',
                          backgroundImage: `url(${ArrowBlueSolid})`,
                        }
                      : {
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                          backgroundPositionX: '90%',
                          backgroundPositionY: '50%',
                          backgroundImage: `url(${ArrowBottomSolid})`,
                        }
                  }
                /> */}
                <DatePicker
                  bordered={false}
                  size="small"
                  onChange={this.startDateChange}
                  style={{
                    padding: '5px 10px',
                    width: '100%',
                  }}
                />
              </div>
            </div>

            <div className={styles.end_date}>
              <div className={styles.date_text}>End Date</div>
              <div className={styles.calendar_container}>
                <DatePicker
                  bordered={false}
                  size="small"
                  onChange={this.endDateChange}
                  style={{
                    padding: '5px 10px',
                    width: '100%',
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.right_bottom}>
            <div
              className={styles.clear_all_btn}
              onClick={this.clearAll}
              style={
                areFiltersSelected
                  ? { backgroundColor: '#11256d', color: '#fff' }
                  : {}
              }
            >
              CLEAR ALL
            </div>
            <div
              className={styles.advanced_search_btn}
              onClick={this.props.getAdvancedSearchData}
            >
              ADVANCED SEARCH
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    catalogAdvancedSearchFilters:
      state.catalogLandingPage.catalogAdvancedSearchFilters,
  }
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(AdvancedSearch)
