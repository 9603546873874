import React, { Component } from 'react'

import { IconContext } from 'react-icons'
import CommonStyles from './../../../../shared/Common.module.css'
import styles from './Sort.module.css'
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'

export default class Sort extends Component {
  state = {
    showAlphabet: false,
    sortByDate: false,
  }
  sortByAlbhabet = () => {
    const { showAlphabet } = this.state
    if (this.props.sortByAlbhabet) {
      this.setState({ showAlphabet: !showAlphabet }, () => {
        this.props.sortByAlbhabet()
      })
    }
  }
  sortByDate = () => {
    const { sortByDate } = this.state

    if (this.props.sortByDate) {
      this.setState({ sortByDate: !sortByDate }, () => {
        this.props.sortByDate()
      })
    }
  }

  render() {
    const { showAlphabet, sortByDate } = this.state
    return (
      <div className={`${CommonStyles.card_layout} ${styles.root}`}>
        <div className={styles.wrapper}>
          <div className={styles.text}>Alphabetical</div>
          <div className={styles.icon_wrapper} onClick={this.sortByAlbhabet}>
            <div className={styles.tag}>A-Z</div>
            <div className={styles.icon_container}>
              <IconContext.Provider value={{ color: '#11256d', size: '18px' }}>
                <div>
                  {showAlphabet ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                </div>
              </IconContext.Provider>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.text}>Date</div>
          <div className={styles.icon_wrapper} onClick={this.sortByDate}>
            <div className={styles.tag}>Order</div>
            <div className={styles.icon_container}>
              <IconContext.Provider value={{ color: '#11256d', size: '18px' }}>
                <div>{sortByDate ? <BiUpArrowAlt /> : <BiDownArrowAlt />}</div>
              </IconContext.Provider>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
