import React from 'react'
import styles from './HomeLeftSection.module.css'
import PrecilyIconSVG from './../../../assets/sign_in_image.svg'

const HomeLeftSection = () => {
  return (
    <React.Fragment>
      {/* <div className={styles.Sidebar}>
        <div className={styles.imgContainer}>
      <div style={{textAlign:'center'}}>
        <div style={{width:'300px',height:'100px',margin:'auto'}}>
<div className={styles.text}>PRECILY AI</div>
<div className={styles.subText}>ADVANCED DATA INSIGHTS, PRECISELY</div>
        </div>
      </div>
      <img src={require('../../../assets/precily_img.png')} alt="" />
      <img className={styles.login_logo} src={require('../../../assets/login_logo.png')} alt="" />
      </div>
      </div> */}

      <div>
        <div className={styles.left_part}>
          <img src={PrecilyIconSVG} alt="Precily Icon" />
          <div>Research Smarter. Faster. Better.</div>
          <div>
            Artificial Intelligence based solution that allows tax and
            legal teams to integrate transparency, efficiency and 
            productivity into workflows.
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HomeLeftSection
