import React, { Component } from "react";
import styles from "./Help.module.css";
import Sidebar from "../../shared/sidebar/Sidebar";
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import commonStyles from "../../shared/Common.module.css";
import India from "../../assets/india.svg";
import USA from "../../assets/usa.svg";

import { Tab, Tabs } from "react-bootstrap";
import FAQ from "./FAQ";

class Help extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <NavigationBar
          loading={this.props.isListDocumentsLoading}
          onHeaderSearchChange={this.onHandleSearchChange}
        ></NavigationBar>
        <Sidebar></Sidebar>
        <div className={commonStyles.commom_wrapper}>
          <div className={styles.heading_row}>
            <div>
              <div className={commonStyles.commom_title}>Help & Contact</div>
            </div>
          </div>
          <div className={styles.all_documents_content_wrapper}>
            <div className={`card ${commonStyles.card_layout}`}>
              <div className={`card-body ${styles.all_documents_card_body}`}>
                <Tabs id="team-tab">
                  <Tab
                    key="How_to_Use"
                    eventKey="How_to_Use"
                    title="How to Use"
                  >
                    <div className={styles.player}>
                      <iframe
                        width="409"
                        height="216"
                        src="https://www.youtube.com/"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <p className={styles.heading}>
                        Step-by-Step Tutorial Video
                      </p>
                      <p className={styles.info}>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet.
                      </p>
                    </div>
                  </Tab>
                  <Tab key="FAQs" eventKey="FAQs" title="FAQs">
                    <FAQ />
                  </Tab>
                  <Tab
                    key="Contact_Us"
                    eventKey="Contact_Us"
                    title="Contact Us"
                  >
                    <div className={`row ${styles.address_block}`}>
                      <ul>
                        <li className={styles.address_space}>
                          <p className={styles.flag_img}>
                            <img src={USA} alt="" className={styles.logo_img} />
                          </p>
                          <h2 className={styles.country_name}>USA</h2>
                          <span class="address">
                            <p className={styles.address}>
                              Stanford Research Park,
                              <br /> 3260, Hillview Avenue, Palo Alto, <br />
                              CA - 94304
                            </p>
                          </span>
                        </li>
                        <li>
                          <span className={styles.flag_img}>
                            <img
                              src={India}
                              alt=""
                              className={styles.logo_img}
                            />
                          </span>
                          <h2 className={styles.country_name}>India</h2>

                          <span class="address">
                            <h4 className={styles.address}>Delhi Office</h4>
                            <p className={styles.address}>
                              501, Incubation Centre - IIIT-D,
                              <br /> Okhla Industrial Estate, Phase III,
                              <br /> New Delhi - 110020
                            </p>
                            <h4 className={styles.address}>Bangalore Office</h4>
                            <p className={styles.address}>
                              52/53, 7th Main Road, BCMC Park Road,
                              <br /> Bhagegowda Layout, Raghuvanahalli,
                              <br /> Karnataka, 560062
                            </p>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Help;
