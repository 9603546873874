import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as WorkflowAllProjectActions from './../../../../store/actions/workflowManagementAllProjectsAction';

// import CheckboxList from '../../checkbox-list/CheckboxList';
// import FilterCheckboxList from '../../filter-checkbox-list/FilterCheckboxList';
// import SearchBar from '../../search-bar/SearchBar';
// import { members_list } from './../../../../json-data/searchResults.json';
import CommonStyles from './../../../../shared/Common.module.css';
import styles from './ShareLink.module.css';
// import { FiSearch } from 'react-icons/fi';
// import { IconContext } from 'react-icons';

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredMembersList: this.props.workflowManagementDropdownData?.users,
      showSelected: false,
      allUsers: this.props.workflowManagementDropdownData?.users,
      projectId: this.props.projectId,
    };

    this.filterCheckboxListRef = React.createRef();
  }

  handleSearchChange = (searchItems) => {
    this.setState({ searchItems });
    this.getFilteredMembersList(searchItems);
  };
  getFilteredMembersList = (searchItems) => {
    let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let filteredMembersList = this.props.workflowManagementDropdownData?.users.filter(
      (member) => {
        return member.value.toLowerCase().search(subStr.toLowerCase()) !== -1;
      }
    );
    this.setState({ filteredMembersList });
  };

  toggleShowSelected = (e) => {
    this.setState({
      showSelected: e.target.checked,
    });
  };

  addMember = () => {
    const { selectedUserDetails } = this.getFilterCheckboxListState();

    let newMembers = [];
    _.forOwn(selectedUserDetails, ({ key, value }, _) => {
      newMembers.push({
        id: key,
        name: value,
        permission: '',
      });
    });

    const params = {
      projectId: this.state.projectId,
      newMembers,
    };
    this.props.requestAddMember(params);
    this.props.closeAddMemberPanel();
  };

  getFilterCheckboxListState = () => {
    return this.filterCheckboxListRef.current.getState();
  };

  render() {
    const { searchItems } = this.state;

    const { fontSize, fontColor = '#000000', leftMargin } = this.props;

    return (
      <div className={`${CommonStyles.card_layout} ${styles.root}`}>
        <div className={styles.search_bar_container}>
          <div className={styles.search_bar_background}>
            <div className={styles.search_bar_wrapper}>
              <div
                className={styles.root_search}
                style={{
                  marginLeft: leftMargin,
                }}
              >
                <input
                  type="text"
                  className={styles.search_box}
                  style={{
                    fontSize,
                    color: `${fontColor}`,
                  }}
                  value={searchItems}
                  placeholder={this.props.createdFileLink}
                  disabled={true}
                  // onChange={(event) => this.handleUserInput(event)}
                  // onKeyPress={this.handleKeyPress}
                ></input>
                <div
                  onClick={() =>
                    this.props.handleCopy(this.props.createdFileLink)
                  }
                  className={styles.search_icon}
                >
                  COPY
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workflowManagementDropdownData:
      state.workFlowManagementLandingPage.workflowManagementDropdownData,
  };
};
const mapDispatchToProps = {
  requestAddMember:
    WorkflowAllProjectActions.workflowManagementAddMemberRequest,
  requestRemoveMember:
    WorkflowAllProjectActions.workflowManagementRemoveMemberRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
