import * as types from '../types'

const initialState = {
  catalogSearchResultData: null,
  catalogSearchRelatedFolders: null,
  isCatalogSearchResultDataLoading: false,
  isCatalogSearchRelatedFoldersLoading: false,
  catalogSearchResultFiltersData: null,
  catalogSearchResultFilteredDocuments: null,
  catalogSearchTerm: null,
  catalogSelectedSources: null,
  error: null,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // Catalog Search Result Data Actions
    case types.CATALOG_SEARCH_RESULT_DATA_REQUEST:
      return {
        ...state,
        catalogSearchResultData: null,
        isCatalogSearchResultDataLoading: true,
      }
    case types.CATALOG_SEARCH_RESULT_DATA_SUCCESS: {
      return {
        ...state,
        isCatalogSearchResultDataLoading: false,
        catalogSearchResultData: action.payload.result.data,
      }
    }
    case types.CATALOG_SEARCH_RESULT_DATA_FAILURE:
      return {
        ...state,
        isCatalogSearchResultDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Catalog Search Related Folders Actions
    case types.CATALOG_SEARCH_RELATED_FOLDERS_REQUEST:
      return {
        ...state,
        catalogSearchRelatedFolders: null,
        isCatalogSearchRelatedFoldersLoading: true,
      }
    case types.CATALOG_SEARCH_RELATED_FOLDERS_SUCCESS: {
      return {
        ...state,
        isCatalogSearchRelatedFoldersLoading: false,
        catalogSearchRelatedFolders: action.payload.result.data,
      }
    }
    case types.CATALOG_SEARCH_RELATED_FOLDERS_FAILURE:
      return {
        ...state,
        isCatalogSearchRelatedFoldersLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    //  for saving filters data
    case types.CATALOG_SEARCH_FILTERS_DATA:
      return {
        ...state,
        catalogSearchResultFiltersData: action.payload,
      }

    //  for saving filtered documents
    case types.CATALOG_SEARCH_FILTERED_DOCUMENTS:
      return {
        ...state,
        catalogSearchResultFilteredDocuments: action.payload,
      }
    // for saving search term
    case types.SAVE_CATALOG_SEARCH_TERM:
      return {
        ...state,
        catalogSearchTerm: action.payload,
      }

      // for saving sources
    case types.CATALOG_SELECTED_SOURCES:
      return {
        ...state,
        catalogSelectedSources: action.payload,
      }

    default: {
      return state
    }
  }
}
