import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as fileComment from '../actions/fileCommentAction';

function* createCommentWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.createFileComment,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(fileComment.createFileCommentSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(fileComment.createFileCommentFailure(e));
  }
}

function* createCommentWatcher() {
  yield takeLatest(types.CREATE_FILE_COMMENT_REQUEST, createCommentWorker);
}

function* getFileCommentWorker(params) {
  try {
    const data = yield call(_api, URLS.getFileComment, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(fileComment.getFileCommentSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(fileComment.getFileCommentFailure(e));
  }
}

function* getFileCommentWatcher() {
  yield takeLatest(types.GET_FILE_COMMENT_REQUEST, getFileCommentWorker);
}

function* deleteFileCommentWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.deleteFileComment,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(fileComment.deleteFileCommentSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(fileComment.deleteFileCommentFailure(e));
  }
}

function* deleteFileCommentWatcher() {
  yield takeLatest(types.DELETE_FILE_COMMENT_REQUEST, deleteFileCommentWorker);
}

function* updateFileCommentWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.updateFileComment,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(fileComment.updateFileCommentSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(fileComment.updateFileCommentFailure(e));
  }
}

function* updateFileCommentWatcher() {
  yield takeLatest(types.UPDATE_FILE_COMMENT_REQUEST, updateFileCommentWorker);
}

export default function* fileCommentSaga() {
  yield all([
    createCommentWatcher(),
    getFileCommentWatcher(),
    deleteFileCommentWatcher(),
    updateFileCommentWatcher()
  ]);
}
