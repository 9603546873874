import React, { Component } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import styles from './EditProfile.module.css'
import commonStyles from '../../../shared/Common.module.css'
import ErrorText from '../../../shared/error-text/ErrorText'
import Modal from '../../../shared/common-modal/CommonModal'
class EditProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      company: '',
      designation: '',
      profileImage: 'https://www.w3schools.com/howto/img_avatar.png',
      showError: false,
      formsInvalid: true,
      showModal: false,
      selectImage: null,
      croppedImageUrl: null,
      base64Image: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 16 / 9,
      },
      errors: {
        name: '',
        designation: '',
      },
    }
  }
  handleUserInput = e => {
    const name = e.target.name
    const value = e.target.value
    let errors = this.state.errors
    let formsInvalid = false
    switch (name) {
      case 'name':
        errors.name =
          value.length < 5 ? 'Full Name must be 5 characters long!' : ''
        formsInvalid = errors.name.length > 0 ? true : false
        break
      case 'designation':
        errors.designation = value.length < 1 ? 'Designation is Required!' : ''
        formsInvalid = errors.designation.length > 0 ? true : false
        break
      default:
        break
    }
    this.setState({ errors, [name]: value })
    this.setState({ formsInvalid: formsInvalid })
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0]) // read file as data url
      reader.onload = event => {
        this.setState({ showModal: true, selectImage: event.target.result })
      }
    }
  }

  componentDidMount() {
    let userProfileData = localStorage.getItem('userProfileData')
    if (userProfileData) {
      let name = JSON.parse(userProfileData).name
      let email = JSON.parse(userProfileData).email
      let company = JSON.parse(userProfileData).company
      let designation = JSON.parse(userProfileData).designation
      this.setState({
        name: name,
        email: email,
        company: company,
        designation: designation,
      })
    }
  }

  handleEditProfile = () => {
    const {
      formsInvalid,
      name,
      email,
      designation,
      profileImage,
      croppedImageUrl,
      base64Image,
    } = this.state
    if (formsInvalid || !name || !email || !designation) {
      this.setState({ showError: true })
    } else {
      this.setState({ showError: false })
      const paramsObj = {
        name: name,
        email: email,
        designation: designation,
        profile: base64Image,
      }
      this.props.onUpdateProfile(paramsObj)
    }
  }

  onImageLoaded = image => {
    this.imageRef = image
  }

  handleModalClose = () => {
    this.setState({ showModal: false })
  }
  onCropComplete = crop => {
    this.makeClientCrop(crop)
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop })
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const base64Image = canvas.toDataURL('image/jpeg')
    this.setState({ base64Image })

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty')
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  handleRemoveImage = () => {
    this.setState({
      profileImage: 'https://www.w3schools.com/howto/img_avatar.png',
    })
    this.handleModal()
  }
  handleSaveImage = () => {
    this.setState({ profileImage: this.state.croppedImageUrl })
    this.handleModal()
  }

  handleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  render() {
    const {
      name,
      email,
      company,
      designation,
      showError,
      errors,
      selectImage,
      showModal,
      crop,
      profileImage,
    } = this.state
    return (
      <div className={`row ${commonStyles.no_gutter}`}>
        <div className={`col-md-3 ${styles.profile_pic_section}`}>
          <div>
            <label className={styles.profile_pic_label}>
              <img
                className={styles.profile_pic}
                src={profileImage}
                alt=""
              ></img>
            </label>
            <br />
            <input
              className={styles.file_input}
              id="fileInput"
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={e => this.onSelectFile(e)}
            ></input>
            <div>
              <label htmlFor="fileInput">
                <div className={styles.picture_change_text}>Change Picture</div>
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className={styles.edit_profile_form_section}>
            <form>
              <p className={commonStyles.common_form_label}>NAME</p>
              <input
                className={`form-control ${commonStyles.common_form_text_field}`}
                type="text"
                name="name"
                value={name}
                onChange={this.handleUserInput}
              ></input>
              {showError && errors.name && <ErrorText text={errors.name} />}
              <p className={commonStyles.common_form_label}>EMAIL ADDRESS</p>
              <input
                className={`form-control ${commonStyles.common_form_text_field}`}
                disabled
                type="text"
                name="email"
                value={email}
                onChange={this.handleUserInput}
              ></input>
              <p className={commonStyles.common_form_label}>COMPANY</p>
              <input
                className={`form-control ${commonStyles.common_form_text_field}`}
                disabled
                type="text"
                name="company"
                value={company}
                onChange={this.handleUserInput}
              ></input>
              <p className={commonStyles.common_form_label}>YOUR DESIGNATION</p>
              <input
                className={`form-control ${commonStyles.common_form_text_field}`}
                type="text"
                name="designation"
                value={designation}
                onChange={this.handleUserInput}
              ></input>
              {showError && errors.designation && (
                <ErrorText text={errors.designation} />
              )}
              <div className={styles.edit_profile_submit_container}>
                <button
                  onClick={this.handleEditProfile}
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>

        {showModal ? (
          <Modal
            modalClass={'team_common_modal'}
            closeHandler={this.handleModalClose}
            header="Change Picture"
          >
            <div className={`row ${commonStyles.no_gutter}`}>
              <div className={`col-md-12 ${styles.picture_selection}`}>
                <ReactCrop
                  src={selectImage}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              </div>
            </div>
            <div className={styles.buttons_wrapper}>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                onClick={this.handleModal}
              >
                DISCARD
              </button>
              <button
                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                onClick={this.handleRemoveImage}
              >
                <i className="fa fa-trash"></i> Remove Picture
              </button>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                onClick={this.handleSaveImage}
              >
                SAVE CHANGES
              </button>
            </div>
          </Modal>
        ) : null}
      </div>
    )
  }
}
export default EditProfile
