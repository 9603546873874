import { toast } from 'react-toastify'

export const createParamsString = params => {
  let url = ''
  for (const param in params) {
    if (params[param] === 0 || params[param]) {
      url += `&${param}=${params[param]}`
    }
  }
  return '?' + url.substring(1, url.length)
}

export const triggerToast = (message, toastType, onCloseHandler) => {
  const configObj = {
    onClose: onCloseHandler,
  }
  switch (toastType) {
    case 'success':
      toast.success(message, configObj)
      break
    case 'error':
      toast.error(message, configObj)
      break
    default:
      console.log('error')
  }
}

export const getFullDate = (date = new Date()) => {
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  return year + '-' + month + '-' + day
}

export const formatFilename = (
  fileName,
  startCount,
  endCount,
  splitCondition = 20
) => {
  if(fileName){
  let modifiedFilename = fileName.replace(/%20/g, '')
  let lastDotPosition = modifiedFilename.lastIndexOf('.')
  if (lastDotPosition !== -1)
    modifiedFilename = modifiedFilename.substr(0, lastDotPosition)

  const formattedFilename =
    modifiedFilename.length > splitCondition
      ? modifiedFilename.substring(0, startCount) +
        '     ...     ' +
        modifiedFilename.substring(modifiedFilename.length - endCount)
      : modifiedFilename
  return formattedFilename
  }
}

export const handleLongFileName = (fileName, limit) => {
 
  let modifiedFilename = fileName.replace(/%20/g, '')
  let lastDotPosition = modifiedFilename.lastIndexOf('.')
  if (lastDotPosition !== -1)
    modifiedFilename = modifiedFilename.substr(0, lastDotPosition)
  if (modifiedFilename.length > limit) {
    return modifiedFilename.slice(0, limit) + '...'
  } else return modifiedFilename
}
