import React, { Component } from "react";
import styles from "./ForgotPassword.module.css";
import commonStyles from '../../../shared/Common.module.css';
import ErrorText from '../../../shared/error-text/ErrorText';
import isEmail from 'validator/lib/isEmail';

const ERROR_MESSAGE='Email is not valid!'
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isEmailValid:false,
      showError:false
    }
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(isEmail(value)){
      this.setState({isEmailValid:true})
    }
    this.setState({ [name]: value });
  }
  goToSignup = () => {
    this.props.history.push('/signup')
  }
  handleResetPassword = () => {
    this.setState({showError:true})
    const { email, isEmailValid } = this.state;
    if(isEmailValid){
    const paramsObj = {
      email: email

    }
    this.props.onforgotPassword(paramsObj)
  }
}

  render() {
    const { email, showError, isEmailValid } = this.state;
    return (
      <div>
        <div className={`row ${commonStyles.no_gutter}`}>
          <div className="col-md-12">
            <div className={commonStyles.home_top_container}>
              <div className={commonStyles.home_top_section}>
                <span className={commonStyles.home_top_text}>  Don't have an account?</span>
                <button
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.home_top_action_btn} ${commonStyles.btn_ripple}`}
                  onClick={this.goToSignup}>GET STARTED
                </button>
              </div>
            </div>
            <div className={styles.fp_form_container}>
              <p className={styles.fp_main_text}>Forgot your password</p>
              <p>We'll send a reset link on your registered Email Id</p>
              <div className={commonStyles.home_form_section}>
                <form>
                  <p className={commonStyles.common_form_label}>REGISTERED EMAIL ADDRESS</p>
                  <input
                    className={`form-control ${commonStyles.common_form_text_field}`}
                    type="text"
                    name="email"
                    value={email}
                    onChange={this.handleUserInput}>
                  </input>
                  {showError && !isEmailValid && <ErrorText text={ERROR_MESSAGE} />}
                  <div className={styles.fp_submit_container}>
                    <button
                      type="button"
                      className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                      onClick={this.handleResetPassword}
                    >RESET PASSWORD
                      </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ForgotPassword;