import React, { Component } from 'react'
import styles from './AddMember.module.css'
import commonStyles from '../../../shared/Common.module.css'
import CommonModal from '../../../shared/common-modal/CommonModal'
// import * as teamManagement from '../../../store/actions/teamManagementActions';
import { connect } from 'react-redux'
import Select from 'react-select'
import ErrorText from '../../../shared/error-text/ErrorText'
// import isEmail from 'validator/lib/isEmail';

class AddMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      designation: '',
      email: '',
      showError: false,
      selectedRole: null,
      errors: {
        name: '',
        designation: '',
        email: '',
        selectedRole: '',
      },
    }
  }
  handleChange = selectedRole => {
    this.setState({ selectedRole })
    let errors = this.state.errors
    errors.selectedRole = ''
    this.setState({ errors })
  }
  handleUserInput = e => {
    // const name = e.target.name;
    // const value = e.target.value;
    // this.setState({ [name]: value })
    const name = e.target.name
    const value = e.target.value
    let errors = this.state.errors
    if (value && value !== '') {
      errors[name] = ''
    }
    this.setState({ errors })
    this.setState({ [name]: value })
  }
  componentDidMount() {
    // const paramsObj = {
    //     type: "TEAM_MEMBER_ROLE",
    // }
    // this.props.teamRoleRequest(paramsObj)
  }
  componentDidUpdate() {}
  getErrors = () => {
    const { name, designation, email, selectedRole } = this.state
    let errors = this.state.errors
    if (name === '') {
      errors.name = 'Name is Required!'
    }
    if (!selectedRole) {
      errors.selectedRole = 'Role is Required!'
    }
    if (email === '') {
      errors.email = 'Email is Required!'
    }
    if (designation === '') {
      errors.designation = 'Designation is Required!'
    }

    let errorCount = 0
    for (const property in this.state.errors) {
      if (this.state.errors[property].length > 1) {
        errorCount++
      }
    }

    this.setState({ errors: errors })
    return errorCount > 0
  }

  handleAddTeamMember = () => {
    const isFormInvalid = this.getErrors()
    if (!isFormInvalid) {
      const { name, designation, email, selectedRole } = this.state
      const paramsObj = {
        teamId: 'dfu39xo36kdy7rpuh',
        new_name: name,
        new_email: email,
        new_designation: designation,
        new_role: selectedRole.value,
      }
      this.props.onAddTeamMember(paramsObj)
    }
  }
  getRoleOptions = () => {
    const roleDropdown = this.props.dropdowns.filter(dropdown => {
      if (dropdown.type === 'TEAM_MEMBER_ROLE') return dropdown.values
    })
    const roleOptions = roleDropdown[0].values.map(item => {
      return {
        value: item.key,
        label: item.value,
      }
    })
    return roleOptions
  }
  render() {
    const { name, designation, email, selectedRole, errors } = this.state
    let roleOptions = []
    if (this.props.dropdowns && this.props.dropdowns.length) {
      roleOptions = this.getRoleOptions()
    }
    return (
      <div>
        <CommonModal
          modalClass={'team_common_modal'}
          closeHandler={this.props.closeHandler}
          header={'Add a Member'}
        >
          <form>
            <div className="row">
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>ENTER NAME</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="name"
                  value={name}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                {errors.name.length > 0 && <ErrorText text={errors.name} />}
              </div>
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>
                  ENTER DESIGNATION
                </p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                {errors.designation.length > 0 && (
                  <ErrorText text={errors.designation} />
                )}
              </div>
            </div>
            <div className={`row ${styles.row_gap}`}>
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>ENTER ADDRESS</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="email"
                  value={email}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                {errors.email.length > 0 && <ErrorText text={errors.email} />}
              </div>
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>CHOOSE ROLE</p>
                <Select
                  value={selectedRole}
                  onChange={this.handleChange}
                  options={roleOptions}
                />
                {errors.selectedRole.length > 0 && (
                  <ErrorText text={errors.role} />
                )}
              </div>
            </div>
            <div className={styles.buttons_wrapper}>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                onClick={this.props.closeHandler}
              >
                CANCEL
              </button>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                onClick={this.handleAddTeamMember}
              >
                SEND INVITE
              </button>
            </div>
          </form>
        </CommonModal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // teamRole:state.team.teamRole,
    dropdowns: state.team.teamList?.data?.dropdown,
  }
}

const mapDispatchToProps = {
  // teamRoleRequest: teamManagement.teamRoleRequest,
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMember)
