import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import {multipleRequest} from '../../services/api';
import URLS from '../../services/urls';
import * as uploadedDoc from '../actions/uploadedDocActions';



function* clusterDataWorker(params) {
    try {
        const data = yield call(_api, URLS.requestClusterData, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        const data2 = yield all([
            call(_api, URLS.requestSimilarParas, params.payload2, 'POST'),
            call(_api, URLS.requestSimilarDocs, params.payload3, 'POST')
        ])
       const finalData = {clusteredData:data, similarParas:data2[0], similarDocs:data2[1]}
        yield put(uploadedDoc.clusterDataSuccess(finalData));
    } catch (e) {
        console.error('error  : ', e);
        yield put(uploadedDoc.clusterDataFailure(e));
    }
}

function* similarParaWorker (params) {
    try {
        const data = yield call(_api, URLS.requestSimilarParas, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }

        yield put(uploadedDoc.similarParaSuccess(data));
    } catch (e) {
        yield put(uploadedDoc.similarParaFailure(e));
    }
}


function* similarDocsWorker (params) {
    try {
        const data = yield call(_api, URLS.requestSimilarDocs, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(uploadedDoc.similarDocsSuccess(data));
    } catch (e) {
        yield put(uploadedDoc.similarDocsFailure(e));
    }
}

function* responseWorker (params) {
    try {
        const data = yield call(_api, URLS.requestFileResponse, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(uploadedDoc.prepareResponseSuccess(data));
    } catch (e) {
        yield put(uploadedDoc.prepareResponseFailure(e));
    }
}

function* submitResponseWorker (params) {
    try {
        const data = yield call(_api, URLS.requestSubmitResponse, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(uploadedDoc.submitResponseSuccess(data));
    } catch (e) {
        yield put(uploadedDoc.submitResponseFailure(e));
    }
}


function* clusterDataWatcher() {
    yield takeLatest(types.CLUSTER_DATA_REQUEST, clusterDataWorker);
}

function* similarParaWatcher() {
    yield takeLatest(types.SIMILAR_PARA_REQUEST, similarParaWorker)
}

function* similarDocsWatcher() {
    yield takeLatest(types.SIMILAR_DOCS_REQUEST, similarDocsWorker)
}

function* prepareResponseWatcher() {
    yield takeLatest(types.PREPARE_RESPONSE_REQUEST, responseWorker)
}

function* submitResponseWatcher() {
    yield takeLatest(types.SUBMIT_PREPARE_RESPONSE_REQUEST, submitResponseWorker)
}

export default function* uploadedDocSaga() {
    yield all([
        clusterDataWatcher(),
        similarParaWatcher(),
        similarDocsWatcher(),
        prepareResponseWatcher(),
        submitResponseWatcher()
    ]);
}