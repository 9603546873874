import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import styles from './DataInstance.module.css';
import PulseWhite from './../../../assets/pulse_white.svg';
import PulseGrey from './../../../assets/wave-grey-icon.svg';
import PulseGreen from './../../../assets/pulse.svg';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_fill from 'highcharts/modules/pattern-fill';
import HC_3d from 'highcharts/highcharts-3d';
HC_fill(Highcharts);
HC_3d(Highcharts);

export default class DataInstance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'home1',
      firstGraphData: null,
      secondGraphData: null,
      thirdGraphData: null,
    };
  }

  getColorPattern = (i) => {
    var colors = Highcharts.getOptions().colors,
      patternColors = [colors[2], colors[0], colors[3], colors[1], colors[4]],
      patterns = [
        'M 0 0 L 5 5 M 4.5 -0.5 L 5.5 0.5 M -0.5 4.5 L 0.5 5.5',
        'M 0 5 L 5 0 M -0.5 0.5 L 0.5 -0.5 M 4.5 5.5 L 5.5 4.5',
        'M 1.5 0 L 1.5 5 M 4 0 L 4 5',
        'M 0 1.5 L 5 1.5 M 0 4 L 5 4',
        'M 0 1.5 L 2.5 1.5 L 2.5 0 M 2.5 5 L 2.5 3.5 L 5 3.5',
      ];

    return {
      pattern: {
        path: patterns[i],
        color: patternColors[i],
        width: 5,
        height: 5,
      },
    };
  };

  handleActiveTab = (tab) => {
    this.setState({ activeTab: tab });
    if (tab === 'home1') {
      this.formatGraphData(tab, this.props.data?.documents);
    } else {
      this.formatGraphData(tab, this.props.data?.graphData);
    }
  };

  componentDidMount() {
    this.formatGraphData(this.state.activeTab, this.props.data?.graphData);
  }

  formatGraphData = (graphType, data) => {
    if (graphType === 'home1' && !this.state.firstGraphData) {
      let yearsData = data?.yearData;
      let formattedData = [];
      let documentType = [];
      let years = yearsData && Object.keys(yearsData);
      years &&
        years.forEach((key) => {
          Object.keys(yearsData[key]).forEach((type) => {
            if (!documentType.includes(type)) {
              documentType.push(type);
            }
          });
        });
      documentType.forEach((type) => {
        let arr = [];
        years.forEach((year) => {
          arr.push(yearsData[year][type]);
        });
        formattedData.push({ name: type, data: arr });
      });
      this.setState({
        firstGraphData: { categories: years, series: formattedData },
      });
    } else if (graphType === 'home2' && !this.state.secondGraphData) {
      let litigations = data?.litigation;
      let formattedData = [];
      litigations &&
        Object.keys(litigations).forEach((litigation, i) => {
          if (litigations[litigation] !== 0) {
            let obj = {};
            obj.name = litigation;
            obj.y = litigations[litigation];
            obj.color = this.getColorPattern(i);
            formattedData.push(obj);
          }
        });

      this.setState({
        secondGraphData: formattedData,
      });
    } else if (graphType === 'home3' && !this.state.thirdGraphData) {
      let courtData = data?.courtData;
      let formattedData = [];
      courtData &&
        Object.keys(courtData).forEach((key) => {
          // let temp = {
          //   name: key,
          //   y: courtData[key],
          // };
          if (courtData[key]) {
            formattedData.push([key, courtData[key]]);
          }
        });
      this.setState({
        thirdGraphData: formattedData,
      });
    } else {
      return [];
    }
  };

  pieColors = (() => {
    var colors = [],
      base = '#37cc6a',
      i;

    for (i = 0; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten((i - 1) / 6)
          .get()
      );
    }
    return colors;
  })();

  countInstance = (data, type) => {
    let total = 0;
    if (data && Object.keys(data).length > 0) {
      if (type === 'yearData') {
        Object.keys(data).forEach((year) => {
          Object.keys(data[year]).forEach((issue) => {
            total += data[year][issue];
          });
        });
      }
      if (type === 'litigation') {
        Object.keys(data).forEach((year) => {
          total += data[year];
        });
      }
    }
    return total;
  };

  render() {
    const {
      activeTab,
      firstGraphData,
      secondGraphData,
      thirdGraphData,
    } = this.state;
    return (
      <div className={`data_instance_root ${styles.data_instance_root}`}>
        <div className={styles.heading_container}>
          <div className={styles.heading}>Data Instances</div>
          <img src={PulseGreen} alt="Pulse" />
        </div>
        <div className={styles.tabs_container}>
          <Tabs
            defaultActiveKey="home1"
            transition={false}
            id="noanim-tab-example"
            onSelect={(k) => this.handleActiveTab(k)}
          >
            <Tab
              tabClassName={styles.tab_root}
              eventKey="home1"
              title={
                <div className={styles.tab_title_root}>
                  <div className={styles.text_container}>
                    <div className={styles.tab_title}>Year Analysis</div>
                    <div className={styles.tab_subtitle}>
                      {this.countInstance(
                        this.props.data?.graphData?.yearData,
                        'yearData'
                      )}{' '}
                      Instances
                    </div>
                  </div>
                  <div className={styles.icon_container}>
                    <img
                      src={activeTab === 'home1' ? PulseWhite : PulseGrey}
                      alt="pulse"
                    />
                  </div>
                </div>
              }
            ></Tab>
            <Tab
              eventKey="home2"
              title={
                <div className={styles.tab_title_root}>
                  <div className={styles.text_container}>
                    <div className={styles.tab_title}>Litigation Analysis</div>
                    {/* <div className={styles.tab_subtitle}>
                      {this.countInstance(
                        this.props.data?.graphData?.litigation,
                        'litigation'
                      )}{' '}
                      Instances
                    </div> */}
                  </div>
                  <div className={styles.icon_container}>
                    <img
                      src={activeTab === 'home2' ? PulseWhite : PulseGrey}
                      alt="pulse"
                    />
                  </div>
                </div>
              }
            ></Tab>
            <Tab
              eventKey="home3"
              title={
                <div className={styles.tab_title_root}>
                  <div className={styles.text_container}>
                    <div className={styles.tab_title}>Court Analysis</div>
                    {/* <div className={styles.tab_subtitle}>1300 Instances</div> */}
                  </div>
                  <div className={styles.icon_container}>
                    <img
                      src={activeTab === 'home3' ? PulseWhite : PulseGrey}
                      alt="pulse"
                    />
                  </div>
                </div>
              }
            ></Tab>
          </Tabs>
        </div>
        {/* <hr /> */}
        {/* <div className={styles.filters}>
          <div className={styles.set_1}>
            <div className={styles.dropdown}>
              <Dropdown
                backgroundColor={'#ffffff'}
                value0={'Type'}
                fontSize={'.66em'}
                borderRadius={'4px'}
                width={'156px'}
              />
            </div>

            <div className={styles.dropdown}>
              <Dropdown
                backgroundColor={'#ffffff'}
                value0={'Filter'}
                fontSize={'.66em'}
                borderRadius={'4px'}
                width={'156px'}
              />
            </div>
          </div>
          <div className={styles.set_2}>
            <div className={styles.dropdown}>
              <Dropdown
                backgroundColor={'#ffffff'}
                value0={'X-Axis'}
                fontSize={'.66em'}
                borderRadius={'4px'}
                width={'156px'}
              />
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                backgroundColor={'#ffffff'}
                value0={'Y-Axis'}
                fontSize={'.66em'}
                borderRadius={'4px'}
                width={'156px'}
              />
            </div>
          </div>
        </div> */}
        {/* <hr /> */}

        <div style={{ width: '100%' }}>
          {activeTab === 'home1' && firstGraphData ? (
            <div>
              {firstGraphData && this.props.data?.graphData ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    colors: [
                      '#0e5326',
                      '#178c40',
                      '#1eb854',
                      '#D0EDDA',
                      '#EAEAEA',
                    ].reverse(),
                    chart: {
                      type: 'column',
                      style: {
                        fontFamily: 'Roboto, sans-serif',
                      },
                    },
                    title: {
                      text: '',
                    },
                    tickLength: 0,
                    xAxis: {
                      categories: firstGraphData.categories,
                      title: {
                        text: 'YEAR',
                        margin: 20,
                        style: {
                          color: '#2c2c2c',
                          fontSize: '12px',
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                        },
                      },
                      labels: {
                        style: {
                          fontSize: '12px',
                          color: '#9a9a9a',
                        },
                      },
                    },
                    yAxis: {
                      lineWidth: 1,
                      min: 1,
                      gridLineColor: '#ffffff',
                      title: {
                        text: 'NUMBER OF DOCUMENT',
                        margin: 20,
                        style: {
                          color: '#2c2c2c',
                          fontSize: '12px',
                          fontFamily: 'Roboto',
                          fontWeight: '500',
                        },
                      },
                      labels: {
                        style: {
                          fontSize: '12px',
                          color: '#9a9a9a',
                        },
                      },
                      stackLabels: {
                        enabled: false,
                        style: {
                          fontWeight: 'bold',
                          color:
                            (Highcharts.defaultOptions.title.style &&
                              Highcharts.defaultOptions.title.style.color) ||
                            'gray',
                        },
                      },
                    },
                    legend: {
                      align: 'right',
                      x: 0,
                      verticalAlign: 'top',
                      y: -10,
                      floating: true,
                      backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor ||
                        'white',
                      borderColor: '#CCC',
                      borderWidth: 0,
                      shadow: false,
                      itemStyle: {
                        fontSize: '12px',
                        fontFamily: 'Roboto, sans-serif',
                        color: '#9a9a9a',
                        fontWeight: 400,
                      },
                    },
                    tooltip: {
                      headerFormat: '<b>{point.x}</b><br/>',
                      pointFormat:
                        '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
                    },
                    plotOptions: {
                      series: {
                        dataLabels: {
                          enabled: true,
                          formatter: function () {
                            if (this.value !== 0) {
                              return this.value;
                            } else {
                              return null;
                            }
                          },
                        },
                      },
                      column: {
                        stacking: 'normal',
                        dataLabels: {
                          enabled: false,
                        },
                      },
                    },
                    series: firstGraphData.series,
                  }}
                  containerProps={{ style: { height: '412px' } }}
                />
              ) : (
                <div className={styles.no_result_text}>
                  <p>No data found</p>
                </div>
              )}
            </div>
          ) : null}

          {activeTab === 'home2' && secondGraphData ? (
            <div>
              {secondGraphData &&
              secondGraphData.length > 0 &&
              this.countInstance(
                this.props.data?.graphData?.litigation,
                'litigation'
              ) ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'pie',
                      style: {
                        fontFamily: 'Roboto, sans-serif',
                      },
                    },
                    title: {
                      text: 'Litigation Analysis',
                    },
                    tooltip: {
                      valueSuffix: '%',
                      borderColor: '#8ae',
                      pointFormat:
                        '{series.name}: <b>{point.percentage:.1f}%</b>',
                    },

                    plotOptions: {
                      series: {
                        dataLabels: {
                          enabled: true,
                          connectorColor: '#777',
                          format:
                            '<b>{point.name}</b>: {point.percentage:.1f} %',
                        },
                        // point: {
                        //   events: {
                        //     click: function () {
                        //       window.location.href = this.website;
                        //     },
                        //   },
                        // },
                        cursor: 'pointer',
                        borderWidth: 3,
                      },
                    },

                    series: [
                      {
                        name: 'Litigation',
                        data: secondGraphData,
                        dataLabels: {
                          style: {
                            fontSize: 12,
                          },
                        },
                      },
                    ],
                  }}
                  containerProps={{ style: { height: '412px' } }}
                />
              ) : (
                <div className={styles.no_result_text}>
                  <p>No data found</p>
                </div>
              )}
            </div>
          ) : null}

          {activeTab === 'home3' ? (
            <div>
              {thirdGraphData && thirdGraphData.length > 0 ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: 'pie',
                      options3d: {
                        enabled: true,
                        alpha: 45,
                      },
                      style: {
                        fontFamily: 'Roboto, sans-serif',
                      },
                    },
                    title: {
                      text: 'Court wise analysis',
                    },
                    tooltip: {
                      pointFormat: '<b>{point.percentage:.1f}%</b>',
                    },
                    plotOptions: {
                      pie: {
                        innerSize: 100,
                        depth: 45,
                      },
                    },
                    series: [
                      {
                        name: 'Delivered amount',
                        data: this.state.thirdGraphData,
                        dataLabels: {
                          style: {
                            fontSize: 12,
                          },
                        },
                      },
                    ],
                  }}
                  containerProps={{ style: { height: '412px' } }}
                />
              ) : (
                <div className={styles.no_result_text}>
                  <p>No data found</p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
