import * as types from '../types'

const initialState = {
  isLoading: false,
  userDashboard: null,
  advanceSearch: null,
  isAdvanceSearchFetching: false,
  recentSearch: null,
  error: null,
  fileRead: null,
  reportAnalysisGraph: null,
  isFileReadLoading: null,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // user dashboard Actions
    case types.USER_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
        userDashboard: null,
      }
    case types.USER_DASHBOARD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userDashboard: action.payload.result,
      }
    }
    case types.USER_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }

    // advance search Actions
    case types.ADVANCE_SEARCH_REQUEST:
      return {
        ...state,
        isAdvanceSearchFetching: true,
        advanceSearch: null,
        error: null,
      }
    case types.ADVANCE_SEARCH_SUCCESS: {
      return {
        ...state,
        isAdvanceSearchFetching: false,
        advanceSearch: action.payload.result,
      }
    }
    case types.ADVANCE_SEARCH_FAILURE:
      return {
        ...state,
        isAdvanceSearchFetching: false,
        error: action.payload?.message || defaultErrorMessage,
      }

    // recent search Actions
    case types.RECENT_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case types.RECENT_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        recentSearch: action.payload.result,
      }
    }
    case types.RECENT_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }

    // file read Actions
    case types.FILE_READ_REQUEST:
      return {
        ...state,
        fileRead: null,
        isFileReadLoading: true,
      }
    case types.FILE_READ_SUCCESS: {
      return {
        ...state,
        fileRead: action.payload.result,
        isFileReadLoading: false,
      }
    }
    case types.FILE_READ_FAILURE:
      return {
        ...state,
        isFileReadLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }
    // report analysis graph Actions
    case types.REPORT_ANALYSIS_GRAPH_REQUEST:
      return {
        ...state,
        isLoading: true,
        reportAnalysisGraph: null,
      }
    case types.REPORT_ANALYSIS_GRAPH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reportAnalysisGraph: action.payload.result,
      }
    }
    case types.REPORT_ANALYSIS_GRAPH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      }
    default: {
      return state
    }
  }
}
