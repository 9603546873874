import React, { Component } from "react";
import styles from "./CreateTicket.module.css";
import Dropzone from "react-dropzone";
import commonStyles from "../../../shared/Common.module.css";
import CommonModal from "../../../shared/common-modal/CommonModal";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import ErrorText from "../../../shared/error-text/ErrorText";
import * as supportActions from "../../../store/actions/supportActions";

class CreateTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      showError: false,
      selectedPriority: null,
      filedata: "",
      fileName: "",
      uploadingInProcess: false,
      errors: {
        name: "",
        selectedPriority: "",
        description: "",
      },
    };
  }
  handleChange = (selectedPriority) => {
    this.setState({ selectedPriority });
    let errors = this.state.errors;
    errors.selectedPriority = "";
    this.setState({ errors });
  };
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let errors = this.state.errors;
    if (value && value !== "") {
      errors[name] = "";
    }
    this.setState({ errors });
    this.setState({ [name]: value });
  };
  getErrors = () => {
    const { name, description, selectedPriority } = this.state;
    let errors = this.state.errors;
    if (name === "") {
      errors.name = "Subject is Required!";
    }
    if (!selectedPriority) {
      errors.selectedPriority = "Priority is Required!";
    }
    if (!description) {
      errors.description = "Description is Required!";
    }

    let errorCount = 0;
    for (const property in this.state.errors) {
      if (this.state.errors[property].length > 1) {
        errorCount++;
      }
    }

    this.setState({ errors: errors });
    return errorCount > 0;
  };

  onfileFound = (file) => {
    file.forEach((file) => {
      this.setState(
        {
          fileName: file.name,
          uploadingInProcess: true,
        },
        () => {
          const reader = new FileReader();
          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = () => {
            this.setState(
              {
                filedata: reader,
              },
              () => {
                this.onUpload(file);
              }
            );
          };
          reader.readAsDataURL(file);
        }
      );
    });
  };
  onUpload = async (file) => {
    const fileType = file.path.split(".").pop();
    if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
      toast.error("file format not supported", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleCreateTicket = () => {
    const isValid = this.getErrors();
    if (!isValid) {
      const { name, description, selectedPriority, filedata } = this.state;
      const email = this.props.userDetails.email;
      const paramObj = {
        subject: name,
        description,
        status: 2,
        email,
        priority: selectedPriority.value,
        filedata: filedata.result
      };
      this.props.createTicket(paramObj);
    }
  };
  getCategoryOptions = () => {
    const roleDropdown = [
      { key: 1, value: "Low" },
      { key: 2, value: "Medium" },
      { key: 3, value: "High" },
      { key: 4, value: "Urgent" },
    ];
    const roleOptions = roleDropdown.map((item) => {
      return {
        value: item.key,
        label: item.value,
      };
    });
    return roleOptions;
  };
  render() {
    const { name, selectedPriority, description, errors } = this.state;
    let roleOptions = [];
    roleOptions = this.getCategoryOptions();
    return (
      <div>
        <CommonModal
          modalClass={"team_common_modal"}
          closeHandler={this.props.closeHandler}
          header={"Create Support Ticket"}
        >
          <form>
            <div className="row">
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>ENTER SUBJECT</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="name"
                  value={name}
                  onChange={(event) => this.handleUserInput(event)}
                ></input>
                {errors.name.length > 0 && <ErrorText text={errors.name} />}
              </div>
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>
                  CHOOSE PRIORITY
                </p>
                <Select
                  value={selectedPriority}
                  onChange={this.handleChange}
                  options={roleOptions}
                />
                {errors.selectedPriority.length > 0 && (
                  <ErrorText text={errors.selectedPriority} />
                )}
              </div>
            </div>
            <div className={`row ${styles.row_gap}`}>
              <p
                className={commonStyles.common_form_label}
                style={{ marginLeft: 15 }}
              >
                DESCRIBE ISSUE
              </p>
              <textarea
                className={`form-control ${styles.textarea}`}
                name="description"
                value={description}
                onChange={(event) => this.handleUserInput(event)}
              ></textarea>
              {errors.name.length > 0 && <ErrorText text={errors.name} />}
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>
                  ATTACH SCREENSHOT
                </p>
                <Dropzone
                  onDrop={(acceptedFiles) => this.onfileFound(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      {!this.props.isLoading && (
                        <>
                          <button
                            type="button"
                            className={`btn btn-primary-outline ${commonStyles.home_top_action_btn} ${commonStyles.btn_ripple}`}
                          >
                            Browse Files
                          </button>
                          <label>{this.state.fileName}</label>
                        </>
                      )}
                    </div>
                  )}
                </Dropzone>
                {/* <label
                  for="file"
                  className={`btn btn-primary-outline ${commonStyles.home_top_action_btn} ${commonStyles.btn_ripple}`}
                >
                  Choose a file
                </label>
                <input
                  type="file"
                  name="file"
                  onChange={(e) => this.onfileFound(e)}
                  style={{ display: "none" }}
                /> */}
              </div>
            </div>
            <div className={styles.buttons_wrapper}>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                onClick={this.props.closeHandler}
              >
                CANCEL
              </button>
              <button
                type="button"
                className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                onClick={this.handleCreateTicket}
              >
                CREATE
              </button>
            </div>
          </form>
        </CommonModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.homepage.userDetails,
  };
};

const mapDispatchToProps = {
  createTicket: supportActions.createTicketRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateTicket);
