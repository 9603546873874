import React from 'react'
import styles from './AllFoldersTree.module.css'
// import Sidebar from '../../shared/sidebar/Sidebar';
import NavigationBar from '../../shared/navigation-bar/NavigationBar'
import commonStyles from '../../shared/Common.module.css'
import AllFoldersTreeRecentFolders from '../../components/all-folders-tree/all-folders-tree-recent-folders/AllFoldersTreeRecentFolders'
import AllFolderList from '../../components/data-catalog-all-folders/all-folder-list/AllFolderList'
import AllFoldersTableView from '../../components/all-folders-tree/all-folders-table-view/AllFoldersTableView'
import allFoldersTreeRecent from '../../json-data/allFoldersTreeRecent.json'
import folders from '../../json-data/allFolders.json'

import 'antd/dist/antd.css'
import { Tree } from 'antd'

import SearchBlackIcon from '../../../src/assets/search_black.svg'
import search_grey_icon from '../../../src/assets/search_grey.svg'

import recentDocuments from '../../json-data/recentDocuments.json'
import rightCards from '../../json-data/rightCards.json'
import green_folder from '../../../src/assets/green_folder_small.svg'
import ArrowUp from '../../../src/assets/arrow-up-grey.svg'
import greater_than_icon from '../../../src/assets/greater_than_icon.svg'
import grey_bg_folder from '../../../src/assets/grey_bg_folder.svg'
import white_bg_folder from '../../../src/assets/white_bg_folder.svg'

import * as allFoldersTree from '../../store/actions/allFoldersTreeActions'
import { connect } from 'react-redux'

import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'
import RightBar from '../../screens/workflow-management/RightBar/RightBar'

const TreeFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={grey_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
)

const SelectedFolderIcon = () => (
  <img
    style={{ width: 20, padding: 1 }} // some custom style to look good
    src={white_bg_folder} // use your imported .png or .jpg file instead
    alt="Custom Icon"
  />
)

class AllFoldersTree extends React.Component {
  constructor(props) {
    super(props)
    // this.path=''
    this.state = {
      initialData: [],
      data: [],
      newData: [],
      parentId: '',
      isLoading: false,
      files: [],
      folders: [],
      paths: '',
      searchItems: '',
    }
  }
  componentDidMount() {
    const paramsObj = {
      referenceId: 'A1D8FC0FC7391AEB!117',
      catalogSourceId: '00000000-0000-0000-a1d8-fc0fc7391aeb',
      level: 0,
      isRoot: true,
    }
    this.props.catalogAllFoldersTreeData(paramsObj)
    this.props.catalogAllFoldersContent()
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.isCatalogAllFoldersTreeDataLoading &&
      prevProps.isCatalogAllFoldersTreeDataLoading &&
      this.props.catalogRecentFoldersData
    ) {
      const treeData = [
        ...this.props.catalogRecentFoldersData?.privateFolder,
        ...this.props.catalogRecentFoldersData?.allFolders,
      ]
      let formattedData = this.makeFolderTree(treeData)
      this.setState({ data: formattedData })
    }

    if (
      !this.props.IsCatalogAllFoldersContentLoading &&
      prevProps.IsCatalogAllFoldersContentLoading &&
      this.props.catalogAllFoldersContentData
    ) {
      let formattedData = this.makeFolderTree(
        this.props.catalogAllFoldersContentData.folders
      )
      let rootNode = this.addChildrenToTree(
        this.state.data,
        formattedData,
        this.state.parentId
      )
      this.setState({
        newData: rootNode,
        isLoading: false,
        files: this.props.catalogAllFoldersContentData.files,
        folders: this.props.catalogAllFoldersContentData?.folders,
      })
    }
  }

  makeFolderTree(folders) {
    let folderTreeData = []
    if (folders && folders.length > 0) {
      folders.map((folder, index) => {
        return folderTreeData.push({
          title: folder.name,
          key: `${folder.referenceId}${Math.random()}`,
          isLeaf: false,
          children: [],
          level: folder.level,
          referenceId: folder.referenceId,
          catalogSourceId: folder.catalogSourceId,
          icon: ({ selected }) =>
            selected ? <SelectedFolderIcon /> : <TreeFolderIcon />,
        })
      })
      return folderTreeData
    }
  }

  addChildrenToTree(rootNode, newData, parentId) {
    if (rootNode && rootNode.length > 0) {
      rootNode.some(function iter(a) {
        if (a.key === parentId) {
          a.children = newData || []
          return true
        }
        return Array.isArray(a.children) && a.children.some(iter)
      })
      return rootNode
    }
  }

  onSelect = (keys, event) => {
    if (this.state.parentId !== event.node.key) {
      this.setState({
        parentId: event.node.key,
        isLoading: true,
        paths: event.node.title,
      })
      const paramObj = {
        referenceId: event.node.referenceId,
        catalogSourceId: event.node.catalogSourceId,
        level: event.node.level,
      }
      this.props.catalogAllFoldersContent(paramObj)
    }
  }

  handleBackNavigation = () => {
    // this.props.history.push('/data-catalog');
    this.props.history.push('/data-catalog')
  }

  handleUserInput = event => {
    var searchItems = event.target.value
    this.setState({ searchItems: event.target.value })

    // const searchItems = event.target.value;
    // let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // let filteredDocuments = this.props.catalogAllFoldersContentData?.files.filter(
    //   (tag) => {
    //     return (
    //       tag.fileName
    //         .replace(/%20/g, '')
    //         .toLowerCase()
    //         .search(subStr.toLowerCase()) !== -1
    //     );
    //   }
    // );
    // this.setState({ files: filteredDocuments });
  }

  render() {
    const { DirectoryTree } = Tree
    const { data, isLoading, parentId, paths } = this.state

    // console.info(
    //   'catalogRecentFoldersData: ',
    //   this.props.catalogRecentFoldersData
    // )
    return (
      <React.Fragment>
        {/* <NavigationBar hideSearchBar={true}></NavigationBar> */}
        <NavBar></NavBar>
        <Sidebar></Sidebar>
        <RightBar></RightBar>
        <div
          className={`${commonStyles.commom_data_catalog_wrapper} ${styles.all_folders_tree_container}`}
        >
          <div className={styles.all_folders_tree_top_section}>
            <div
              className={styles.left_section}
              onClick={this.handleBackNavigation}
            >
              <div className={styles.back_icon}>
                <img src={ArrowUp} alt="Arrow Up" />
              </div>
              <div className={styles.folder_path}>Data Catalog /</div>
              <div className={styles.folder_name}>All Folders</div>
            </div>
          </div>
          <hr></hr>

          <div className={styles.recent_document_container}>
            <div className={styles.title_search_bar_container}>
              <div className={styles.title}>
                Recent Folders{' '}
                <img
                  className={styles.folder_icon}
                  src={green_folder}
                  alt="document"
                ></img>
              </div>

              <div className={styles.search_bar_section}>
                <div className={styles.search_bar_container}>
                  <input
                    placeholder="Search All Folder"
                    type="text"
                    className={styles.search_box}
                    onChange={e => this.handleUserInput(e)}
                  ></input>
                  <div className={styles.search_icon}>
                    <img
                      className={styles.icon}
                      src={SearchBlackIcon}
                      alt="Search Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.props.catalogRecentFoldersData?.recentFolders && (
            <div className={styles.all_folders_tree_recent_folders_container}>
              <AllFoldersTreeRecentFolders
                folders={this.props.catalogRecentFoldersData?.recentFolders}
                history={this.props.history}
              ></AllFoldersTreeRecentFolders>
            </div>
          )}

          <div className={styles.tree_folders_container}>
            <div className={styles.tree_view_section}>
              <div className={styles.tree_view_title_container}>
                <div className={styles.tree_view_title}>Index</div>
                <div>
                  {' '}
                  <img src={greater_than_icon} alt="angle" />
                </div>
              </div>

              <div className="folder_tree_container">
                {isLoading ? (
                  <p>Loading....</p>
                ) : (
                  <DirectoryTree
                    // onSelect={this.onSelect}
                    onExpand={this.onSelect}
                    defaultExpandedKeys={[parentId]}
                    defaultSelectedKeys={[parentId]}
                    treeData={data}
                  />
                )}
              </div>
            </div>

            <div className={styles.table_view_section}>
              <div className={styles.tree_view_title}>
                All Folders / .. / {paths.replace(/%20/g, ' ')}
              </div>

              {this.props.catalogRecentFoldersData && this.state.files && (
                <AllFoldersTableView
                  foldersData={folders.folders}
                  files={this.state.files}
                  folders={this.state.folders}
                  searchItems={this.state.searchItems}
                ></AllFoldersTableView>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    catalogRecentFoldersData: state.allFoldersTree.catalogAllFoldersTreeData,
    isCatalogAllFoldersTreeDataLoading:
      state.allFoldersTree.isCatalogAllFoldersTreeDataLoading,
    IsCatalogAllFoldersContentLoading:
      state.allFoldersTree.IsCatalogAllFoldersContentLoading,
    catalogAllFoldersContentData:
      state.allFoldersTree.catalogAllFoldersContentData,
  }
}
const mapDispatchToProps = {
  catalogAllFoldersTreeData: allFoldersTree.catalogAllFoldersTreeDataRequest,
  catalogAllFoldersContent: allFoldersTree.catalogAllFoldersContentRequest,
}
export default connect(mapStateToProps, mapDispatchToProps)(AllFoldersTree)
