import React from "react";
import styles from "./Submit.module.css";

const Submit = (props) => {
  const { filename, status } = props.location.state;
  setTimeout(() => {
      props.history.push("/all-documents")
  },5000)
  return (
    <div className={styles.screen}>
      <div className={styles.success_section}>
        <img
          src={require("../../assets/tick.png")}
          alt=""
          style={{ width: "83px", marginRight: "15px" }}
        />
        <p className={styles.success_heading}>Success!</p>
      </div>
      <div
        style={{
          display: "block",
          textAlign: "center",
          marginLeft: "200px",
          color: "#373B53",
        }}
      >
        <p>
          Your response to <strong>{filename}</strong> has been submitted.
        </p>
      </div>
      <div
        style={{
          display: "block",
          textAlign: "center",
          marginLeft: "191px",
          color: "#373B53",
        }}
      >
        <p>
          This document is now in <span className={styles.file}>completed</span>{" "}
          phase
        </p>
      </div>
      <div style={{textAlign: "center", marginLeft: "15px"}}>
        <button
          className={`btn btn-primary-outline ${styles.btn}`}
          style={{ marginTop: "10px" }}
          onClick={() => props.history.push("/all-documents")}
        >
          GO TO ALL DOCUMENTS
        </button>
      </div>
    </div>
  );
};

export default Submit;
