import * as types from '../types';
// Catalog All folders tree Result Data Actions

export const catalogAllFoldersTreeDataRequest = (payload) => ({ type: types.CATALOG_ALL_FOLDERS_TREE_DATA_REQUEST, payload });
export const catalogAllFoldersTreeDataSuccess = (payload) => ({ type: types.CATALOG_ALL_FOLDERS_TREE_DATA_SUCCESS, payload });
export const catalogAllFoldersTreeDataFailure = (payload) => ({ type: types.CATALOG_ALL_FOLDERS_TREE_DATA_FAILURE, payload });

// All folders content
export const catalogAllFoldersContentRequest = (payload) => ({ type: types.CATALOG_ALL_FOLDERS_CONTENT_REQUEST, payload });
export const catalogAllFoldersContentSuccess = (payload) => ({ type: types.CATALOG_ALL_FOLDERS_CONTENT_SUCCESS, payload });
export const catalogAllFoldersContentFailure = (payload) => ({ type: types.CATALOG_ALL_FOLDERS_CONTENT_FAILURE, payload });