import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as catalogSelectedFolder from '../actions/catalogSelectedFolderAction';

function* catalogSelectedFolderDataWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCatalogSelectedFolderData, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(catalogSelectedFolder.catalogSelectedFolderSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(catalogSelectedFolder.catalogSelectedFolderFailure(e));
    }
}


function* catalogSelectedFolderDataWatcher() {
    yield takeLatest(types.CATALOG_SELECTED_FOLDER_DATA_REQUEST, catalogSelectedFolderDataWorker);
}



function* catalogSelectedFolderTreeDataWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCatalogAllFoldersTree, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(catalogSelectedFolder.catalogSelectedFolderTreeSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(catalogSelectedFolder.catalogSelectedFolderTreeFailure(e));
    }
}


function* catalogSelectedFolderTreeDataWatcher() {
    yield takeLatest(types.CATALOG_SELECTED_FOLDER_TREE_DATA_REQUEST, catalogSelectedFolderTreeDataWorker);
}


function* catalogSelectedFileTreeDataWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCatalogAllFoldersContent, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(catalogSelectedFolder.catalogSelectedFileTreeSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(catalogSelectedFolder.catalogSelectedFileTreeFailure(e));
    }
}


function* catalogSelectedFileTreeDataWatcher() {
    yield takeLatest(types.CATALOG_SELECTED_FILE_TREE_DATA_REQUEST, catalogSelectedFileTreeDataWorker);
}


export default function* catalogSelectedFolderSaga() {
    yield all([
        catalogSelectedFolderDataWatcher(),
        catalogSelectedFolderTreeDataWatcher(),
        catalogSelectedFileTreeDataWatcher()
        
    ]);
}