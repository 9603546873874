import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as dataSourceInfo from '../actions/dataSourceInfoAction';

function* dataSourceInfoWorker(params) {
  try {
    const data = yield call(_api, URLS.dataSourceInfo, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(dataSourceInfo.dataSourceInfoSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(dataSourceInfo.dataSourceInfoFailure(e));
  }
}

function* dataSourceInfoWatcher() {
  yield takeLatest(types.FETCH_ALL_SOURCE_INFO_REQUEST, dataSourceInfoWorker);
}

export default function* dataSourceInfoSaga() {
  yield all([dataSourceInfoWatcher()]);
}
