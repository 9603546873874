// import React, { Component } from "react";
// import styles from "./SimilarDocs.module.css";
// import Modal from "../../../shared/common-modal/CommonModal";
// import { connect } from "react-redux";

// class SimilarDocs extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: [],
//       currentPage: 1,
//       todosPerPage: 1,
//       isFileModalOpen: false,
//       tagData: null,
//       fileUrl: null,
//       showAll: false,
//       size: 100,
//     };
//   }
//   componentDidMount() {
//     this.setState({ data: this.props.data });
//   }

//   btnPrevClick = () => {
//     if (this.state.currentPage > 1) {
//       let listid = this.state.currentPage - 1;
//       this.setState({ currentPage: listid });
//     }
//   };
//   btnNextClick = () => {
//     if (this.state.data.length > this.state.currentPage) {
//       let listid = this.state.currentPage + 1;
//       this.setState({ currentPage: listid });
//     }
//   };
//   openFile = (path) => {
//     this.setState({
//       isFileModalOpen: true,
//       fileUrl: path,
//     });
//   };

//   onDisplayDocumentClose = () => {
//     this.setState({
//       isFileModalOpen: false,
//     });
//   };

//   showMore = () => {
//     this.setState({ showAll: !this.state.showAll });
//   };

//   onResize = (resizeFactor) => {
//     let nextSize = this.state.size + resizeFactor;
//     if (nextSize > 150) {
//       nextSize = 150
//     }
//     else if (nextSize <= 50) {
//       nextSize = 50
//     }
//     this.setState({
//       size: nextSize
//     })
//   }

//   render() {
//     const { isFileModalOpen, data, size } = this.state;
//     let listAnswers;
//     if (Array.isArray(data) && data.length > 0) {
//       listAnswers = data.map((elem, index) => {
//         return (
//           <div key={index} className={styles.similar_para_container}>
//             <div className={styles.two_column_row}>
//               <div className={styles.file_name}>
//                 {elem.fileName.split(".").slice(0, -1).join(".")}
//               </div>
//               <div
//                 onClick={() => this.openFile(elem.filePath)}
//                 className={styles.file_action}
//               >
//                 Open File
//               </div>
//             </div>
//             {isFileModalOpen && (
//               <Modal
//                 closeHandler={this.onDisplayDocumentClose}
//                 header={elem.fileName}
//                 modalClass={"display_similar_doc_modal"}
//               >




// <div className={styles.top_actions_strip}>
//             <div className={styles.actions_strip_section}>
//               <div className={styles.file_title}>{elem.fileName}</div>
//               <div>

//                 <div className={styles.resize_icons}>
//                   <i onClick={() => this.onResize(10)} className={`fas fa-search-plus ${styles.icon_style}`}></i>
//                   {size !== 100 && <span>{size}%</span>}
//                   <i onClick={() => this.onResize(-10)} className={`fas fa-search-minus ${styles.icon_style}`}></i>
//                   <i
//                   onClick={this.props.closeHandler}
//                   className={`fas fa-times ${styles.cut_icon}`}
//                 ></i>
//                 </div>

//                 <div style={{ zoom: this.state.size + '%' }} className={styles.doc_render_wrapper}></div>

//               </div>

//             </div>
//           </div>















//                 <div style={{ padding: "50px", backgroundColor: "#fff" }}>
//                   {elem.fileText}
//                 </div>
//               </Modal>
//             )}
//           </div>
//         );
//       });
//     } else {
//       listAnswers = null;
//     }

//     return (
//       <div>
//         <div className={styles.similar_para_container}>
//           <span className={styles.title}>
//             SIMILAR PARAGRAPHS FROM HISTORICAL DATA
//           </span>
//         </div>
//         <div>{listAnswers}</div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     uploadedDoc: state.uploadedDoc.similarDocs,
//   };
// };
// export default connect(mapStateToProps, null)(SimilarDocs);


import React, { Component } from "react";
import styles from "./SimilarDocs.module.css";
// import DisplayDocument from "../../../shared/display-document/DisplayDocument";
import Modal from "../../../shared/common-modal/CommonModal";
import { connect } from "react-redux";
class SimilarDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      todosPerPage: 1,
      isFileModalOpen: false,
      tagData: null,
      fileUrl: null,
      showAll: false,
      size: 100,
    };
  }
  componentDidMount() {
    this.setState({ data: this.props.data.data });
  }
  btnPrevClick = () => {
    if (this.state.currentPage > 1) {
      let listid = this.state.currentPage - 1;
      this.setState({ currentPage: listid });
    }
  };
  btnNextClick = () => {
    if (this.state.data.length > this.state.currentPage) {
      let listid = this.state.currentPage + 1;
      this.setState({ currentPage: listid });
    }
  };
  openFile = (elem) => {
    this.setState({
      isFileModalOpen: true,
      text: elem.fileText,
      fileName: elem.fileName,
    });
  };
  onDisplayDocumentClose = () => {
    this.setState({
      isFileModalOpen: false,
    });
  };
  showMore = () => {
    this.setState({ showAll: !this.state.showAll });
  };
    onResize = (resizeFactor) => {
    let nextSize = this.state.size + resizeFactor;
    if (nextSize > 150) {
      nextSize = 150
    }
    else if (nextSize <= 50) {
      nextSize = 50
    }
    this.setState({
      size: nextSize
    })
  }
  render() {
    
    const { isFileModalOpen, data, size } = this.state;
    let listAnswers;
    if (Array.isArray(data) && data.length > 0) {
      listAnswers = data.map((elem, index) => {
        return (
          <div key={index} className={styles.similar_para_container}>
            <div className={styles.two_column_row}>
              <div className={styles.file_name}>
                {elem.fileName.split(".").slice(0, -1).join(".")}
              </div>
              <div
                onClick={() => this.openFile(elem)}
                className={styles.file_action}
              >
                Open File
              </div>
            </div>
          </div>
        );
      });
    } else {
      listAnswers = <p style={{textAlign: "center", fontSize: "12px", marginTop: "31px"}}>No Matching Similar Document!</p>
    }
    return (
      <div>
        <div className={styles.similar_para_container}>
          <span className={styles.title}>
            SIMILAR DOCUMENTS FROM HISTORICAL DATA
          </span>
        </div>
        <div>{listAnswers}</div>
        {isFileModalOpen && (
          <Modal
            closeHandler={this.onDisplayDocumentClose}
            modalClass={"display_similar_doc_modal"}
          >




 <div className={styles.top_actions_strip}>
             <div className={styles.actions_strip_section}>
              <div className={styles.file_title}>{this.state.fileName}</div>
              <div>

                 <div className={styles.resize_icons}>
                   <i onClick={() => this.onResize(10)} className={`fas fa-search-plus ${styles.icon_style}`}></i>
                   {size !== 100 && <span>{size}%</span>}
                  <i onClick={() => this.onResize(-10)} className={`fas fa-search-minus ${styles.icon_style}`}></i>
                   <i
                  onClick={this.onDisplayDocumentClose}
                  className={`fas fa-times ${styles.cut_icon}`}
                ></i>
                </div>

                <div style={{ zoom: this.state.size + '%' }} className={styles.doc_render_wrapper}></div>

              </div>

            </div>
          </div>
<div style={{ zoom: this.state.size + '%' }}>
            <div style={{ padding: "50px", backgroundColor: "#fff", width:'50%', margin:'auto' }}>
              {this.state.text}
            </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uploadedDoc: state.uploadedDoc.similarDocs,
  };
};
export default connect(mapStateToProps, null)(SimilarDocs);
