import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as catalogAllSearchResults from '../actions/catalogAllSearchResultAction';

function* catalogAllSearchResultsDataWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCatalogAllSearchResultData, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(catalogAllSearchResults.catalogAllSearchResultDataSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(catalogAllSearchResults.catalogAllSearchResultDataFailure(e));
    }
}


function* catalogAllSearchResultsDataWatcher() {
    yield takeLatest(types.CATALOG_ALL_SEARCH_RESULT_DATA_REQUEST, catalogAllSearchResultsDataWorker);
}


export default function* catalogSearchResultsSaga() {
    yield all([
        catalogAllSearchResultsDataWatcher(),
    ]);
}