import React, { Component } from "react";
import styles from "./Signup.module.css";
import commonStyles from '../../../shared/Common.module.css';
import { connect } from 'react-redux';
import ErrorText from '../../../shared/error-text/ErrorText';
import Captcha from '../../../shared/captcha/Captcha'
import SignupSuccess from '../signup-success/SignupSuccess'
import isEmail from 'validator/lib/isEmail';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      company: "",
      designation: "",
      password: '',
      termsCheckbox:'',
      formsInvalid: true,
      showError: false,
      isCaptchaChecked: true,
      errors: {
        name: 'Name is Required!',
        email: 'Email is not valid!',
        mobile: 'Mobile must be 10 digit long!',
        company: 'Company Name is Required!',
        designation: 'Designation is Required!',
        password: 'Password must be 8 characters long!',
        emailExist: 'Email already exists',
        termsCheckbox:"Please accept Precily's Terms of Service and Privacy Policy",
        showEmailExistError: false,
      },
    }
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let errors = this.state.errors;
    let formsInvalid = false
    switch (name) {
      case 'name':
        errors.name =
          value.length < 5
            ? 'Full Name must be 5 characters long!'
            : '';
        formsInvalid = errors.name.length > 0 ? true : false
        break;
      case 'email':
        errors.email =
        isEmail(value)
            ? ''
            : 'Email is not valid!';
        formsInvalid = !isEmail(value)
        break;
      case 'password':
        errors.password =
          value.length < 5
            ? 'Password must be 5 characters long!'
            : '';
        formsInvalid = errors.password.length > 0 ? true : false
        break;
      case 'company':
        errors.company =
          value.length < 3
            ? 'Company Name is Required!'
            : '';
        formsInvalid = errors.company.length > 0 ? true : false
        break;
      case 'designation':
        errors.designation =
          value.length < 3
            ? 'Designation is Required!'
            : '';
            case 'termsCheckbox':
        errors.termsCheckbox =
        !e.target.checked
            ? "Please accept Precily's Terms of Service and Privacy Policy"
            : '';
        formsInvalid = errors.termsCheckbox.length > 0 ? true : false
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
    this.setState({ formsInvalid: formsInvalid })
  }
  handleFormSubmit = () => {
    const {  name, email, company, designation, password, formsInvalid, isCaptchaChecked } = this.state
    if (formsInvalid || !name || !email ||!company ||!designation ||!password || !isCaptchaChecked) {
      this.setState({ showError:true }) 
    }else{
      this.setState({ showError:false })
      const paramsObj = {
        name: name,
        email: email,
        company: company,
        designation: designation,
        password: password
      };
      this.props.onSignup(paramsObj);
    }
  }

  verifyCaptcha = () => {
    this.setState({isCaptchaChecked: true})
  }

  goToLogin = () => {
    this.props.history.push('/')
  }
  emailExist = () => {
    
    const {email} = this.state
   if (isEmail(email)){
    const paramsObj = {
      email:email
    }
    this.props.onEmailExist(paramsObj)
  }
}
componentDidMount(){
 
}
  render() {
    const {  name, email, company, designation, password, showError,errors  } = this.state
    const {isSignUpSuccess} = this.props
    return (
      <React.Fragment>
        <div className={`row ${commonStyles.no_gutter}`}>
          <div className="col-md-12">
            <div className={commonStyles.home_top_container}>
              <div className={commonStyles.home_top_section}>
                <span className={commonStyles.home_top_text}> Already have an account?</span>
                <button
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.home_top_action_btn} ${commonStyles.btn_ripple}`}
                  onClick={this.goToLogin}
                >SIGN IN</button>
              </div>
            </div>
            {!isSignUpSuccess && <div className={styles.signup_form_container}>
              <p className={styles.signup_main_text}>Welcome to Precily AI</p>
              <p className={styles.signup_sub_text}>Enter details to create your account</p>
              <div className={commonStyles.home_form_section}>
                <form>
                  <p className={commonStyles.common_form_label}>NAME</p>
                  <input
                    className={`form-control ${commonStyles.common_form_text_field}`}
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => this.handleUserInput(event)}>
                  </input>
                  {showError && errors.name && <ErrorText text={errors.name} />}
                  <p className={commonStyles.common_form_label}>EMAIL ADDRESS</p>
                  <input
                    className={`form-control ${commonStyles.common_form_text_field}`}
                    type="text"
                    name="email"
                    value={email}
                    onBlur={() => this.emailExist()}
                    onChange={(event) => this.handleUserInput(event)}>
                  </input>
                  {showError && errors.email && <ErrorText text={errors.email} />}
                  <p className={commonStyles.common_form_label}>COMPANY</p>
                  <input
                    className={`form-control ${commonStyles.common_form_text_field}`}
                    type="text"
                    name="company"
                    value={company}
                    onChange={(event) => this.handleUserInput(event)}>
                  </input>
                  {showError && errors.company && <ErrorText text={errors.company} />}
                  <p className={commonStyles.common_form_label}>DESIGNATION</p>
                  <input
                    className={`form-control ${commonStyles.common_form_text_field}`}
                    type="text"
                    name="designation"
                    value={designation}
                    onChange={(event) => this.handleUserInput(event)}>
                  </input>
                  {showError && errors.designation && <ErrorText text={errors.designation} />}
                  <p className={commonStyles.common_form_label}>PASSWORD</p>
                  <input
                    className={`form-control ${commonStyles.common_form_text_field}`}
                    type="password"
                    name="password"
                    value={password}
                    onChange={(event) => this.handleUserInput(event)}>
                  </input>
                  {showError && errors.password && <ErrorText text={errors.password} />}
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="termsCheckbox"
                      className={`form-check-input ${styles.signup_checkbox}`}
                      id="tnc"
                      onChange={(event) => this.handleUserInput(event)}>
                    </input>
                    <label
                      className={styles.singup_tnc}
                      htmlFor="tnc">I agree to <a href="https://precily.com/Privacy.html" target="_blank"> Precily's Terms</a>  of Service and Privacy Policy</label>
                  </div>
                  {showError && errors.termsCheckbox && <ErrorText text={errors.termsCheckbox} />}
                  <div className={styles.signup_form_captcha_container}>
                    <Captcha handleClick={this.verifyCaptcha}></Captcha>
                  </div>
                  <div className={styles.signup_form_submit_container}>
                    <button
                      type="button"
                      className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                      onClick={this.handleFormSubmit}
                      disabled={!this.state.isCaptchaChecked}
                      >
                      SIGN UP
                      </button>
                  </div>
                </form>
              </div>
            </div>}
          </div>
        </div>
        {isSignUpSuccess && <SignupSuccess></SignupSuccess>}
      </React.Fragment>
    )
  }

}
const mapStateToProps = (state) => {
  return { user: state.homepage.userDetails, error: state.homepage.error };
}
export default connect(mapStateToProps, null)(Signup);