import React, { Component } from "react";
import styles from "./UsersDetails.module.css";
import Sidebar from '../../shared/sidebar/Sidebar';
import NavigationBar from '../../shared/navigation-bar/NavigationBar';
import commonStyles from '../../shared/Common.module.css';
import UsersList from '../../components/users-details/users-list/UsersList';
import AddUser from '../../components/users-details/add-user/AddUser'

import { Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux';
import usersListData from "../../json-data/userListData";
class UsersDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddUserClicked: false,
        }

    }
    componentDidMount() {
    }
    handleAddUser = () => {
        this.setState({
            isAddUserClicked: true,
        })
    }
    onAddUserClose = () => {
        this.setState({
            isAddUserClicked: false,
        })
    }

    renderTabList = () => {
        let modifiedUsersListData = [...usersListData.users];
        let allUsersListData = []
        modifiedUsersListData.forEach(user => {
            allUsersListData = allUsersListData.concat(user.members)
        })
        let userListDataobj = {
            userName: 'All',
            count: allUsersListData.length,
            members: allUsersListData
        }
        modifiedUsersListData.splice(0, 0, userListDataobj)
        return (modifiedUsersListData.map(user => {
            return (<Tab key={user.userName} eventKey={user.userName} title={user.userName + " (" + user.count + ")"}>
                <UsersList ondeleteUser={this.props.deleteTeamMemberRequest} members={user.members}></UsersList>
            </Tab>
            );
        }))
    }
    render() {
        const { isAddUserClicked } = this.state
        return (
            <React.Fragment>
                <NavigationBar></NavigationBar>
                <Sidebar></Sidebar>
                <div className={commonStyles.commom_wrapper}>
                    <div className={commonStyles.commom_title}>Users Details</div>
                    {/* <div className={commonStyles.commom_subtitle}>lorem ipsum dolor sit amet, consectetur adipiscing elit</div> */}
                    <div className={styles.users_details_content_wrapper}>
                        <div className={`card ${commonStyles.card_layout}`}>
                            <div className={`card-body ${styles.users_card_body}`}>
                                <div className={styles.users_details_tab_container}>
                                    <Tabs id="users-tab">
                                        {this.renderTabList()}
                                    </Tabs>
                                    <div className={styles.users_details_icons_container}>
                                        <div className={styles.users_details_icons}>
                                            <img onClick={this.handleAddUser} src={require('../../assets/person_add.svg')} alt="" />
                                            <img src={require('../../assets/search.svg')} alt="" />
                                            <img src={require('../../assets/sync.svg')} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isAddUserClicked && <AddUser closeHandler={this.onAddUserClose}></AddUser>}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(UsersDetails);