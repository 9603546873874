import React, { Component } from "react";
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import styles from "./ClusteredResultAfterResponsePrepared.module.css";
import ClusteredParagraphs from "../../components/clustered-result/clustered-paragraphs/ClusteredParagraphs";
import ClusteredParagraphsDetails from "../../components/clustered-result/clustered-paragraphs-details/ClusteredParagraphsDetails";
import Sidebar from "../../shared/sidebar/Sidebar";
import * as uploadedDoc from "../../store/actions/uploadedDocActions";
import { connect } from "react-redux";
import commonStyles from '../../shared/Common.module.css';
import  ResponseMessagePopup from '../../components/clustered-result/response-message-popup/ResponseMessagePopup';
import * as uploadActions from "../../store/actions/uploadedDocActions";
import {handleSuccess, handleError } from '../../shared/HandleMessages'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';

import FroalaEditorComponent from 'react-froala-wysiwyg';



class ClusteredResultAfterResponsePrepared extends Component {
    constructor(props) {
        super(props);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.temp = [];
        let cont = "<p>" +this.props.uploadedDoc.response + "</p>"
        this.state = {
            selectedTags: {},
            searchItem: "",
            stateTempo: [],
            activeTags: [],
            searchItem: "",
            model: this.props.uploadedDoc.clusterData.data.fileVersion.isResponse?this.props.uploadedDoc.clusterData.data.fileVersion.fileResponse: this.props.uploadedDoc.response,
            isResponseButtonClicked: false,
            originalResponse:this.props.uploadedDoc.clusterData.data.fileVersion.isResponse?this.props.uploadedDoc.clusterData.data.fileVersion.fileResponse: this.props.uploadedDoc.response,
            responseContent:this.props.uploadedDoc.clusterData.data.fileVersion.isResponse?this.props.uploadedDoc.clusterData.data.fileVersion.fileResponse: this.props.uploadedDoc.response,
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(cont)
                )
            ),
        };
    }
    handleModelChange =(model) =>{
        this.setState({
          model: model
        });
      }

    onEditorStateChange = (editorState) => {

        this.setState({
          editorState,
        });
      };

    componentDidMount() {
        this.props.similarDocs(
            this.props.uploadedDoc.clusterData.data.fileVersion.fileId
        );
    }

    componentDidUpdate(prevProps){
        if (
            !this.props.isSubmitResponseLoading &&
            prevProps.isSubmitResponseLoading
          ) {
            handleSuccess(this.props.message)
            this.props.history.push({ pathname: '/submit',
            state: {filename: this.props.uploadedDoc.clusterData.data.fileVersion.fileName, status: this.props.uploadedDoc.clusterData.data.fileVersion.status}})
            // this.props.clearResponse();
          }
    }
    handleTagSelection = (tag) => {
        const selectedTags = { ...this.state.selectedTags };
        selectedTags[tag] = Object.keys(selectedTags).includes(tag)
            ? !selectedTags[tag]
            : true;
        this.setState({ selectedTags });
    };
    clearSelectedTags = () => {
        this.setState({ selectedTags: {} });
    };
    onHandleSearchChange = (searchItem) => {
        this.setState({ searchItem });
    };
    getClusteredData() {
        const data = this.props.uploadDoc.docData.data.result;
        const paramsObj = {
            fileId: data.fileId,
            fileVersion: data.version,
            fileVersionId: data.fileVersionId,
            filePath: data.filePath,
            version: data.version,
        };
        this.props.clusterDataRequest(paramsObj);
    }
    tempo = (tempoo) => {
        this.setState({ stateTempo: tempoo }, () => { });
    };
    setActiveTags = (activeTags) => {
        this.setState({ activeTags });
    };
    handleLogoClick() {
        this.props.history.push('/dashboard');
    }
    handleResponseBtnClick = () =>{
        this.setState({isResponseButtonClicked:true})
    }
    handleClearResponse = () => {
        this.setState({responseContent:null})
        this.props.clearResponse()
    }
    handleGenerateAgain = () => {
        this.handleResponseBtnClick()
    }
    onMessagePopupClose = () => {
        this.setState({isResponseButtonClicked:false})
    }
    onMessageSubmit = () => {
        const id = this.props.uploadedDoc.clusterData.data.fileVersion.fileId;
    this.props.submitResponse({ response: this.state.model, fileId: id });
  }
  onHandleSearchChange = (searchItem) => {
    this.setState({ searchItem });
  };
  
    componentDidMount() {
        if (
            this.props.uploadDoc.docData &&
            this.props.uploadDoc.docData.data &&
            this.props.uploadDoc.docData.data.result
        ) {
        }
    }


    render() {
        const {isResponseButtonClicked, responseContent, editorState} = this.state
        const prepareResponse = this.props.uploadedDoc.prepareResponse;
        const fileName = this.props.uploadedDoc.clusterData.data.fileVersion.fileName
        
        return (
            <div>
                <Sidebar></Sidebar>
                <NavigationBar onHeaderSearchChange={this.onHandleSearchChange}></NavigationBar>
                <div style={{ fontSize: '24px', marginTop: '80px', marginBottom: '20px', fontWeight: 'bold', color: '#11256D', marginLeft: '94px' }}>Prepare Response - {fileName}</div>
                <div className="row" style={{ marginLeft: '80px', marginRight: '0px' }}>
                    <div className="col-md-6" >

                        <div style={{ border: '1px solid #BDC3C7', borderRadius: '4px', marginBottom: '20px', }}>
                            <div className={styles.response_field_strip}>
                                {fileName}
                            </div>
                            <div style={{ height: '400px', overflow: 'scroll', background: '#ffffff' }}>
                                <ClusteredParagraphs
                                    history={this.props.history}
                                    setActiveTags={this.setActiveTags}
                                    selectedTags={this.state.selectedTags}
                                    searchItem={this.state.searchItem}
                                    clearSelectedTags={this.clearSelectedTags}
                                    renderSimilarPara={this.renderSimilarPara}
                                    showTopStrip={false}
                                ></ClusteredParagraphs>
                            </div>
                        </div>

                        <div style={{ height: '400px', overflow: 'scroll', marginBottom: '20px', border: '1px solid #BDC3C7', borderRadius: '4px', background: '#ffffff' }}>
                            <ClusteredParagraphsDetails
                                activeTags={this.state.activeTags}
                                selectedTags={this.state.selectedTags}
                                handleTagSelection={this.handleTagSelection}
                                history={this.props.history}
                                showTopStrip={false}
                            ></ClusteredParagraphsDetails>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div style={{ border: '1px solid #BDC3C7', borderRadius: '4px', marginBottom: '20px', }}>
                            <div className={styles.response_field_strip}>
                                Response Field
             </div>
                            <div style={{ height: '650px', overflow: 'scroll', background: '#ffffff' }}>
                                <div>
        {/* <div id="editor" contentEditable="true" className={styles.response_content} >{responseContent}</div> */}

                {/* <div className={styles.action_links}>
                    <span>Keep</span>
                    <span onClick={this.handleClearResponse}>Remove</span>
                    <span onClick={this.handleGenerateAgain}>Generate Again</span>
                </div> */}
                
                 {/* <Editor
  editorState={editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={this.onEditorStateChange} 
/> */}
<FroalaEditorComponent
			  model={this.state.model}
              onModelChange={this.handleModelChange}
           />


                                </div>
                            </div>
                        </div>
                        
<div style={{textAlign:'center'}}>
<button
type="button"
onClick={this.handleResponseBtnClick}
className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple} ${styles.response_save}`}>SUBMIT RESPONSE FOR REVIEW</button>

<button
type="button"
onClick={this.handleClearResponse}
className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple} ${styles.response_clear}`}>CLEAR RESPONSE</button>

</div>
                    </div>
                </div>
{isResponseButtonClicked && <ResponseMessagePopup  closeHandler={this.onMessagePopupClose}
            onMessageSubmit={this.onMessageSubmit}></ResponseMessagePopup>}

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        uploadDoc: state.uploadDoc,
        uploadedDoc: state.uploadedDoc,
        isSubmitResponseLoading: state.uploadedDoc.isSubmitResponseLoading,
        message: state.uploadedDoc.message,
    };
};
const mapDispatchToProps = {
    clusterDataRequest: uploadedDoc.clusterDataRequest,
    similarDocs: uploadedDoc.similarDocsRequest,
    submitResponse: uploadActions.submitResponseRequest,
    clearResponse: uploadActions.clearResponse,
};
export default connect(mapStateToProps, mapDispatchToProps)(ClusteredResultAfterResponsePrepared);
