import * as types from '../types';

const initialState = {
  isQuickSummaryLoading: false,
  quickSummary: null,
  error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // Quick Summary Actions
    case types.QUICK_SUMMARY_REQUEST:
      return {
        ...state,
        isQuickSummaryLoading: true,
      };
    case types.QUICK_SUMMARY_SUCCESS: {
      return {
        ...state,
        isQuickSummaryLoading: false,
        quickSummary: action.payload.result.data,
      };
    }
    case types.QUICK_SUMMARY_FAILURE:
      return {
        ...state,
        isQuickSummaryLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    default: {
      return state;
    }
  }
};
