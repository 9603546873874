import React, { Component } from 'react';

import ShareIcon from './../share-icon/ShareIcon';
import PulseSVG from './../../../assets/pulse.svg';
import SourceSVG from './../../../assets/source.svg';
import styles from './RelatedSearches.module.css';
import { formatDate } from '../../../shared/formatDate';

import Highlighter from 'react-highlight-words';

export default class RelatedSearches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredProjectsData: this.props.documentsData,
      // filteredProjectsDataForCompare:this.props.documentsData,
      isAlphabetAscending: 1,
      isDateAscending: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentsData !== this.props.documentsData) {
      this.setState({
        filteredProjectsData: this.props.documentsData,
        filteredProjectsDataForCompare: this.props.documentsData,
      });
    }
  }
  getConfidence = (confidence, confidenceClass) => {
    if (confidenceClass === 0) {
      return { value: 'LOW', color: '#F50072', width: confidence * 100 };
    } else if (confidenceClass === 1) {
      return { value: 'MEDIUM', color: '#F5DC00', width: confidence * 100 };
    } else if (confidenceClass === 2) {
      return { value: 'HIGH', color: '00CC66', width: confidence * 100 };
    }
  };
  handleFileNameClick = (fileId, filePath) => {
    this.props.history.push(
      {
        pathname: '/document-analytics',
        search: '?source=data-catalog',
      },
      {
        fileId: fileId,
        filePath: filePath,
      }
    );
  };

  sortProjectByAlphabet = () => {
    const filteredProjectsData = this.state.filteredProjectsData;
    filteredProjectsData.sort((a, b) =>
      a['folderName'].toLowerCase() > b['folderName'].toLowerCase()
        ? this.state.isAlphabetAscending
        : this.state.isAlphabetAscending * -1
    );

    this.setState({
      filteredProjectsData,
      isAlphabetAscending: this.state.isAlphabetAscending * -1,
    });
  };

  sortProjectByDate = () => {
    const { isDateAscending, filteredProjectsData } = this.state;
    if (isDateAscending === 1) {
      filteredProjectsData.sort(function (a, b) {
        return new Date(b['modified']) - new Date(a['modified']);
      });
      this.setState({
        filteredProjectsData,
        isDateAscending: -1,
      });
    } else if (isDateAscending === -1) {
      filteredProjectsData.sort(function (a, b) {
        return new Date(a['modified']) - new Date(b['modified']);
      });
      this.setState({
        filteredProjectsData,
        isDateAscending: 1,
      });
    }
  };

  getHtmlText = (str, arr) => {
    var sortedArray = arr.sort(function (a, b) {
      return a[0] - b[0];
    });

    var merged = [].concat.apply([], sortedArray);
    var flatArray = merged.map(function (value) {
      return value - 1;
    });

    let first = 1;
    let arrIndex = 0;
    var finalString = '';
    for (let index in str) {
      finalString = finalString + str.charAt(index);
      if (index == flatArray[arrIndex]) {
        if (first === 1) {
          finalString = finalString + '<mark>';
        } else {
          finalString = finalString + '</mark>';
        }
        first = first * -1;
        arrIndex++;
      }
    }

    return finalString;
  };

  render() {
    const { filteredProjectsData } = this.state;
    const { parent } = this.props;
    // console.info('filteredProjectsData', filteredProjectsData)

    return (
      <div className={styles.related_searches__root}>
        <div>
          {filteredProjectsData?.map(
            (
              {
                folderName,
                fileName,
                text,
                modified,
                results,
                sources,
                confidence,
                fileId,
                filePath,
                confidenceClass,
                higlight,
                metaData,
              },
              index
            ) => {
              const dateAndTime = formatDate(modified);
              const confidenceValueAndColor = this.getConfidence(
                confidence,
                confidenceClass
              );
              // let arrayOfTextToHighlight = ''
              // if (text) {
              //   arrayOfTextToHighlight = this.getHtmlText(
              //     text.replace(/%20/g, ' '),
              //     higlight
              //   )
              // }

              let year = 2010;
              let issuesCount = 10;
              if (metaData !== null && metaData !== undefined) {
                year = metaData.year;
                issuesCount = metaData.issues.length;
              }

              if (parent === 'RelatedSearchesAndFolders' && index >= 5) return;
              return (
                <div className={styles.related_searches__wrapper}>
                  <div className={styles.related_searches__container}>
                    <div className={styles.related_searches__left_container}>
                      <div className={styles.body__root}>
                        <div className={styles.body__heading}>
                          {folderName.replace(/%20/g, ' ')}
                        </div>
                        <div
                          onClick={() => {
                            this.handleFileNameClick(fileId, filePath);
                          }}
                          className={styles.body__subheading}
                        >
                          {fileName
                            .replace(/%20/g, ' ')
                            .replace(/%28/g, ' ')
                            .replace(/%29/g, ' ')}
                        </div>
                        <div className={styles.body__description}>
                          {/* {text.replace(/%20/g, ' ')} */}
                          {/* <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={arrayOfTextToHighlight}
    autoEscape={true}
    textToHighlight={text.replace(/%20/g, ' ')}
  /> */}
                          {text && (
                            // <div
                            //   dangerouslySetInnerHTML={{
                            //     __html: arrayOfTextToHighlight,
                            //   }}
                            // ></div>
                            <div>{text.replace(/%20/g, ' ')}</div>
                          )}
                        </div>
                        <div className={styles.body__date}>
                          {dateAndTime.date}.{dateAndTime.time}
                        </div>
                      </div>

                      {/* </div> */}
                    </div>

                    <div className={styles.related_searches__right_container}>
                      <div className={styles.related_searches__share_link}>
                        <ShareIcon
                          provideCtrl={(ctrl) => (this.controlShareIcon = ctrl)}
                          fileId={fileId}
                        />
                      </div>

                      <div className={styles.graphic_container__root}>
                        <div className={styles.graphic_container__confidence}>
                          <div className={styles.confidence_value_container}>
                            Confidence:
                            <div className={styles.confidence_value}>
                              {confidenceValueAndColor.value}
                            </div>
                          </div>

                          <div className={styles.confidence_container}>
                            <div
                              className={styles.confidence}
                              style={{
                                width: confidenceValueAndColor.width + '%',
                                backgroundColor: confidenceValueAndColor.color,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div
                          className={
                            styles.graphic_container__results_and_sources
                          }
                        >
                          <div className={styles.graphic_container__results}>
                            <div className={styles.graphic__root}>
                              <div className={styles.graphic__icon_container}>
                                <img
                                  className={styles.graphic__icon}
                                  src={PulseSVG}
                                  alt="pulse"
                                />
                              </div>
                              <div>
                                <div className={styles.graphic__title}>
                                  Year
                                </div>
                                <div className={styles.graphic__count}>
                                  {year}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.graphic_container__sources}>
                            <div className={styles.graphic__root}>
                              <div className={styles.graphic__icon_container}>
                                <img
                                  className={styles.graphic__icon}
                                  src={SourceSVG}
                                  alt="source"
                                />
                              </div>
                              <div>
                                <div className={styles.graphic__title}>
                                  Issues
                                </div>
                                <div className={styles.graphic__count}>
                                  {issuesCount}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                // </div>
              );
            }
          )}
        </div>
      </div>
    );
  }
}
