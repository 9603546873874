import * as types from '../types'

const initialState = {
  isNewDocumentSaved: false,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    case types.NEW_DOCUMENT_SAVE_REQUEST:
      return {
        ...state,
        isNewDocumentSaved: action.payload,
      }
    default: {
      return state
    }
  }
}
