import * as types from '../types';

// Team List Actions
export const teamListRequest = (payload) => ({ type: types.TEAM_LIST_REQUEST, payload });
export const teamListSuccess = (payload) => ({ type: types.TEAM_LIST_SUCCESS, payload });
export const teamListFailure = (payload) => ({ type: types.TEAM_LIST_FAILURE, payload });

// Add Team Member Types
export const addTeamMemberRequest = (payload) => ({ type: types.ADD_TEAM_MEMBER_REQUEST, payload });
export const addTeamMemberSuccess = (payload) => ({ type: types.ADD_TEAM_MEMBER_SUCCESS, payload });
export const addTeamMemberFailure = (payload) => ({ type: types.ADD_TEAM_MEMBER_FAILURE, payload });

// Create Team Types
export const createTeamRequest = (payload) => ({ type: types.CREATE_TEAM_REQUEST, payload });
export const createTeamSuccess = (payload) => ({ type: types.CREATE_TEAM_SUCCESS, payload });
export const createTeamFailure = (payload) => ({ type: types.CREATE_TEAM_FAILURE, payload });

// Delete Team Member Types
export const deleteTeamMemberRequest = (payload) => ({ type: types.DELETE_TEAM_MEMBER_REQUEST, payload });
export const deleteTeamMemberSuccess = (payload) => ({ type: types.DELETE_TEAM_MEMBER_SUCCESS, payload });
export const deleteTeamMemberFailure = (payload) => ({ type: types.DELETE_TEAM_MEMBER_FAILURE, payload });

// TEAM ROLE Types
export const teamRoleRequest = (payload) => ({ type: types.TEAM_ROLE_REQUEST, payload });
export const teamRoleSuccess = (payload) => ({ type: types.TEAM_ROLE_SUCCESS, payload });
export const teamRoleFailure = (payload) => ({ type: types.TEAM_ROLE_FAILURE, payload });

//Update Team Member
export const updateTeamMemberRequest = (payload) => ({type: types.UPDATE_TEAM_MEMBER_REQUEST, payload})
export const updateTeamMemberSuccess = (payload) => ({type:types.UPDATE_TEAM_MEMBER_SUCCESS, payload})
export const updateTeamMemberFailure = (payload) => ({type:types.UPDATE_TEAM_MEMBER_FAILURE, payload})