import React, { Component } from "react";
import styles from "./SectionNum.module.css";
import Modal from "../../../shared/common-modal/CommonModal";
import { connect } from "react-redux";

const MAX_LENGTH = 250;
let listAnswers = null,
  singleResult = null,
  filterResult = null;
class SectionNum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      todosPerPage: 1,
      isFileModalOpen: false,
      tagData: null,
      fileUrl: null,
      showAll: false,
      size: 100,
    };
  }

  componentDidMount() {
    let mergeArr = [].concat.apply([], this.props.data);
    this.setState({ data: mergeArr });
  }

  btnPrevClick = () => {
    if (this.state.currentPage > 1) {
      let listid = this.state.currentPage - 1;
      this.setState({ currentPage: listid });
    }
  };
  btnNextClick = () => {
    if (this.props.uploadedDoc.paraId) {
      if (filterResult.length > this.state.currentPage) {
        let listid = this.state.currentPage + 1;
        this.setState({ currentPage: listid });
      }
    } else {
      if (this.state.data.length > this.state.currentPage) {
        let listid = this.state.currentPage + 1;
        this.setState({ currentPage: listid });
      }
    }
  };
  openFile = (elem) => {
    this.setState({
      isFileModalOpen: true,
      text: elem.fileText,
      fileName: elem.fileName,
    });
  };
  onDisplayDocumentClose = () => {
    this.setState({
      isFileModalOpen: false,
    });
  };
  showMore = () => {
    this.setState({ showAll: !this.state.showAll });
  };
  onResize = (resizeFactor) => {
    let nextSize = this.state.size + resizeFactor;
    if (nextSize > 150) {
      nextSize = 150;
    } else if (nextSize <= 50) {
      nextSize = 50;
    }
    this.setState({
      size: nextSize,
    });
  };

  render() {
    const {
      currentPage,
      todosPerPage,
      isFileModalOpen,
      data,
      size,
    } = this.state;
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    if (Array.isArray(data) && data.length > 0) {
      filterResult = data.filter((doc) => {
        return doc.paraId === this.props.uploadedDoc.paraId;
      });
    } else {
      singleResult = (
        <p style={{ textAlign: "center", fontSize: "12px", marginTop: "31px" }}>
          No Matching Similar Para!
        </p>
      );
    }
    let renderPrevBtn = (
      <div className={styles.btnPrev} onClick={this.btnPrevClick}>
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </div>
    );
    let renderNextBtn = (
      <div className={styles.btnNext} onClick={this.btnNextClick}>
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </div>
    );
    if (filterResult !== null && filterResult.length > 0) {
      const currentTodos = filterResult.slice(
        indexOfFirstTodo,
        indexOfLastTodo
      );
      singleResult = currentTodos.map((single, index) => {
        return (
          <div className={styles.similar_para_container}>
            <span className={styles.title}>
              SIMILAR PARAGRAPHS FROM HISTORICAL DATA
            </span>
            <div className={styles.two_column_row}>
              <div className={styles.file_name}>{single.fileName}</div>
              <div
                onClick={() => this.openFile(single)}
                className={styles.file_action}
              >
                Open File
              </div>
            </div>

            <div className={styles.page_content}>
              <div className={styles.page_para}>
                {this.state.showAll
                  ? single.text
                  : single.text.substring(0, MAX_LENGTH)}
                <span onClick={this.showMore} className={styles.showmore}>
                  {this.state.showAll ? "show less" : "show more"}
                </span>
              </div>
              <div className={styles.feedback_container}>
                <i className="fas fa-thumbs-up"></i>
                <i className="fas fa-thumbs-down"></i>
                <span>Was this helpful?</span>
              </div>
            </div>
            <div className={styles.navigation_container}>
              <div>{renderPrevBtn}</div>
              <div className={styles.page_count}>
                Page {single.sequence} of{" "}
                {filterResult && filterResult.length ? filterResult.length : 0}
              </div>
              <div> {renderNextBtn}</div>
            </div>
            {/* {isFileModalOpen && (
              <DisplayDocument
                closeHandler={this.onDisplayDocumentClose}
                showFileUrl={this.state.fileUrl}
              ></DisplayDocument>
            )} */}
          </div>
        );
      });
    } else {
      singleResult = (
        <p style={{ textAlign: "center", fontSize: "12px", marginTop: "31px" }}>
          No Matching Similar Para!
        </p>
      );
    }

    // if (Array.isArray(data) && data.length > 0) {
    //   const currentTodos = data.slice(indexOfFirstTodo, indexOfLastTodo);
    //   listAnswers = currentTodos.map((elem, index) => {
    //     return (
    //       <div key={index} className={styles.similar_para_container}>
    //         <span className={styles.title}>
    //           SIMILAR PARAGRAPHS FROM HISTORICAL DATA
    //         </span>
    //         <div className={styles.two_column_row}>
    //           <div className={styles.file_name}>{elem.fileTitle}</div>
    //           {/* <div
    //             onClick={() => this.openFile(elem.filePath)}
    //             className={styles.file_action}
    //           >
    //             Open File
    //           </div> */}
    //         </div>

    //         <div className={styles.page_content}>
    //           <div className={styles.page_para}>
    //             {this.state.showAll
    //               ? elem.text
    //               : elem.text.substring(0, MAX_LENGTH)}
    //             <span onClick={this.showMore} className={styles.showmore}>
    //               {this.state.showAll ? "show less" : "show more"}
    //             </span>
    //           </div>
    //           {/* <div className={styles.page_confidence_score}>{elem.score}</div> */}
    //           <div className={styles.feedback_container}>
    //             <i className="fas fa-thumbs-up"></i>
    //             <i className="fas fa-thumbs-down"></i>
    //             <span>Was this helpful?</span>
    //           </div>
    //         </div>
    //         {this.props.uploadedDoc.paraId ? null : (
    //           <>
    //             <div className={styles.navigation_container}>
    //               <div>{renderPrevBtn}</div>
    //               <div className={styles.page_count}>
    //                 Page {this.state.currentPage} of{" "}
    //                 {data && data.length ? data.length : 0}
    //               </div>
    //               <div> {renderNextBtn}</div>
    //             </div>
    //           </>
    //         )}
    //         {isFileModalOpen && (
    //           <DisplayDocument
    //             closeHandler={this.onDisplayDocumentClose}
    //             showFileUrl={this.state.fileUrl}
    //           ></DisplayDocument>
    //         )}
    //       </div>
    //     );
    //   });
    // }

    return (
      <div>
        <div>{this.props.uploadedDoc.paraId ? singleResult : null}</div>
        {isFileModalOpen && (
          <Modal
            closeHandler={this.onDisplayDocumentClose}
            modalClass={"display_similar_doc_modal"}
          >
            <div className={styles.top_actions_strip}>
              <div className={styles.actions_strip_section}>
                <div className={styles.file_title}>{this.state.fileName}</div>
                <div>
                  <div className={styles.resize_icons}>
                    <i
                      onClick={() => this.onResize(10)}
                      className={`fas fa-search-plus ${styles.icon_style}`}
                    ></i>
                    {size !== 100 && <span>{size}%</span>}
                    <i
                      onClick={() => this.onResize(-10)}
                      className={`fas fa-search-minus ${styles.icon_style}`}
                    ></i>
                    <i
                      onClick={this.onDisplayDocumentClose}
                      className={`fas fa-times ${styles.cut_icon}`}
                    ></i>
                  </div>

                  <div
                    style={{ zoom: this.state.size + "%" }}
                    className={styles.doc_render_wrapper}
                  ></div>
                </div>
              </div>
            </div>
            <div style={{ zoom: this.state.size + "%" }}>
              <div
                style={{
                  padding: "50px",
                  backgroundColor: "#fff",
                  width: "50%",
                  margin: "auto",
                }}
              >
                {this.state.text}
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadedDoc: state.uploadedDoc,
  };
};
export default connect(mapStateToProps, null)(SectionNum);
