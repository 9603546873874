import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as allDocuments from '../actions/allDocumentsActions';

function* listDocumentsWorker(params) {
    try {
        const data = yield call(_api, URLS.requestListDocuments, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(allDocuments.listDocumentsSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(allDocuments.listDocumentsFailure(e));
    }
}

function* listDocumentsWatcher() {
    yield takeLatest(types.LIST_DOCUMENTS_REQUEST, listDocumentsWorker);
}


function* fileJourneyWorker(params) {
    try {
        const data = yield call(_api, URLS.requestFileJourney, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(allDocuments.fileJourneySuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(allDocuments.fileJourneyFailure(e));
    }
}

function* saveFileDataWorker(params) {
    try {
        const data = params.payload
        yield put(allDocuments.saveFileDataSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
    }
}

function* saveFileDataWatcher() {
    yield takeLatest(types.SAVE_FILEDATA_REQUEST, saveFileDataWorker)
}
function* fileJourneyWatcher() {
    yield takeLatest(types.FILE_JOURNEY_REQUEST, fileJourneyWorker);
}

function* deleteDocumentsWorker(params) {
    try {
        const data = yield call(_api, URLS.requestDeleteDocument, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(allDocuments.deleteDocumentSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(allDocuments.deleteDocumentFailure(e));
    }
}
function* deleteDocumentWatcher() {
    yield takeLatest(types.DELETE_DOCUMENT_REQUEST, deleteDocumentsWorker);
}

export default function* allDocumentsSaga() {
    yield all([
        listDocumentsWatcher(),
        fileJourneyWatcher(),
        deleteDocumentWatcher(),
        saveFileDataWatcher()
    ]);
}