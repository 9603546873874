import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import Share from './../modals/share/Share';
import { FiShare2 } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import styles from './ShareIcon.module.css';

export default class ShareIcon extends Component {
  state = {
    showShareOptionsPanel: false,
  };

  toggleShareOptions = () => {
    const { showShareOptionsPanel } = this.state;
    this.setState({
      showShareOptionsPanel: !showShareOptionsPanel,
    });
  };

  closeShareOptionsPanel = () => {
    this.setState({
      showShareOptionsPanel: false,
    });
  };

  render() {
    const { showShareOptionsPanel } = this.state;

    const {
      iconSize = 24,
      iconContainerSize = '36px',
      index,
      top,
      left,
      right,
      fileId,
    } = this.props;

    return (
      <>
        <OutsideClickHandler
          onOutsideClick={() => this.closeShareOptionsPanel()}
        >
          <IconContext.Provider
            value={
              showShareOptionsPanel
                ? { color: '#ffffff', size: iconSize }
                : { color: '#9A9A9A', size: iconSize }
            }
          >
            <div
              className={styles.icon}
              onClick={this.toggleShareOptions}
              style={
                showShareOptionsPanel
                  ? {
                      backgroundColor: '#11256D',
                      borderRadius: 18,
                      width: `${iconContainerSize}`,
                      height: `${iconContainerSize}`,
                    }
                  : {
                      width: `${iconContainerSize}`,
                      height: `${iconContainerSize}`,
                    }
              }
            >
              <FiShare2 />
            </div>
          </IconContext.Provider>

          {showShareOptionsPanel && (
            <Share
              index={index}
              top={top}
              left={left}
              right={right}
              fileId={fileId}
            />
          )}
        </OutsideClickHandler>
      </>
    );
  }
}
