import * as types from '../types'

const initialState = {
  workflowManagementProjectData: null,
  workflowManagementProjectGraphData: null,
  workflowManagementDropdownData: null,
  workflowManagementCreateProject: null,
  isWorkflowManagementProjectDataLoading: false,
  isWorkflowManagementProjectGraphDataLoading: false,
  isWorkflowManagementDropdownDataLoading: false,
  isWorkflowManagementCreateProjectLoading: false,
  filters: null,
  filteredDocuments: null,
  error: null,
}

const defaultErrorMessage = 'Something went wrong'

export default (state = initialState, action) => {
  switch (action.type) {
    // Workflow Management Project Data Actions
    case types.WORKFLOW_MANAGEMENT_PROJECT_REQUEST:
      return {
        ...state,
        workflowManagementProjectData: null,
        isWorkflowManagementProjectDataLoading: true,
      }
    case types.WORKFLOW_MANAGEMENT_PROJECT_SUCCESS: {
      return {
        ...state,
        isWorkflowManagementProjectDataLoading: false,
        workflowManagementProjectData: action.payload.result.data,
      }
    }
    case types.WORKFLOW_MANAGEMENT_PROJECT_FAILURE:
      return {
        ...state,
        isWorkflowManagementProjectDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Workflow Management Project Graph Data Actions
    case types.WORKFLOW_MANAGEMENT_PROJECT_GRAPH_REQUEST:
      return {
        ...state,
        workflowManagementProjectGraphData: null,
        isWorkflowManagementProjectGraphDataLoading: true,
      }
    case types.WORKFLOW_MANAGEMENT_PROJECT_GRAPH_SUCCESS: {
      return {
        ...state,
        isWorkflowManagementProjectGraphDataLoading: false,
        workflowManagementProjectGraphData: action.payload.result.data,
      }
    }
    case types.WORKFLOW_MANAGEMENT_PROJECT_GRAPH_FAILURE:
      return {
        ...state,
        isWorkflowManagementProjectGraphDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }

    // Workflow Management Dropdown Data Actions
    case types.WORKFLOW_MANAGEMENT_DROPDOWN_REQUEST:
      return {
        ...state,
        workflowManagementDropdownData: null,
        isWorkflowManagementDropdownDataLoading: true,
      }
    case types.WORKFLOW_MANAGEMENT_DROPDOWN_SUCCESS: {
      return {
        ...state,
        isWorkflowManagementDropdownDataLoading: false,
        workflowManagementDropdownData: action.payload.result.data,
      }
    }
    case types.WORKFLOW_MANAGEMENT_DROPDOWN_FAILURE:
      return {
        ...state,
        isWorkflowManagementDropdownDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }
    case types.WORKFLOW_MANAGEMENT_CREATE_PROJECT_REQUEST:
      return {
        ...state,
        workflowManagementCreateProject: null,
        isWorkflowManagementCreateProjectLoading: true,
      }
    case types.WORKFLOW_MANAGEMENT_CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        isWorkflowManagementCreateProjectLoading: false,
        workflowManagementCreateProject: action.payload.result.data,
      }
    }
    case types.WORKFLOW_MANAGEMENT_CREATE_PROJECT_FAILURE:
      return {
        ...state,
        isWorkflowManagementCreateProjectLoading: false,
        error: action.payload.result || defaultErrorMessage,
      }
    case types.WORKFLOW_MANAGEMENT_SAVE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    case types.WORKFLOW_MANAGEMENT_FILTERED_DOCUMENTS:
      return {
        ...state,
        filteredDocuments: action.payload,
      }

    default: {
      return state
    }
  }
}
