import React, { Component } from "react";
import styles from "./InReviewFileView.module.css";
import NavigationBar from '../../shared/navigation-bar/NavigationBar'
import Sidebar from '../../shared/sidebar/Sidebar';
import InReviewFileParagraphs from '../../components/in-review-file-view/in-review-file-paragraphs/InReviewFileParagraphs'
// import InReviewFileDetails from '../../components/in-review-file-view/in-review-file-details/InReviewFileDetails'

class InReviewFilesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchItem: '',
        }
    }

    onHandleSearchChange = (searchItem) => {
    }

  render() {
    return (
      <React.Fragment>
        <Sidebar></Sidebar>
        <NavigationBar onHeaderSearchChange={this.onHandleSearchChange}></NavigationBar>

        <div className={styles.inreview_files_content_wrapper}>
                    <div className={styles.inreview_para_section}>
                        <InReviewFileParagraphs   searchItem={this.state.searchItem}></InReviewFileParagraphs>
                    </div>
                    <div className={styles.inreview_file_details_section}>
                        {/* <InReviewFileDetails ></InReviewFileDetails> */}
                    </div>
                </div>
      </React.Fragment>
    );
  }
}

export default InReviewFilesView;