import * as types from '../types';
// Catalog Selected Folder Actions

export const catalogSelectedFolderRequest = (payload) => ({ type: types.CATALOG_SELECTED_FOLDER_DATA_REQUEST, payload });
export const catalogSelectedFolderSuccess = (payload) => ({ type: types.CATALOG_SELECTED_FOLDER_DATA_SUCCESS, payload });
export const catalogSelectedFolderFailure = (payload) => ({ type: types.CATALOG_SELECTED_FOLDER_DATA_FAILURE, payload });

export const catalogSelectedFolderTreeRequest = (payload) => ({ type: types.CATALOG_SELECTED_FOLDER_TREE_DATA_REQUEST, payload });
export const catalogSelectedFolderTreeSuccess = (payload) => ({ type: types.CATALOG_SELECTED_FOLDER_TREE_DATA_SUCCESS, payload });
export const catalogSelectedFolderTreeFailure = (payload) => ({ type: types.CATALOG_SELECTED_FOLDER_TREE_DATA_FAILURE, payload });

export const catalogSelectedFileTreeRequest = (payload) => ({ type: types.CATALOG_SELECTED_FILE_TREE_DATA_REQUEST, payload });
export const catalogSelectedFileTreeSuccess = (payload) => ({ type: types.CATALOG_SELECTED_FILE_TREE_DATA_SUCCESS, payload });
export const catalogSelectedFileTreeFailure = (payload) => ({ type: types.CATALOG_SELECTED_FILE_TREE_DATA_FAILURE, payload });