import * as types from '../types';

const initialState = {
    isLoading: false,
    profileDetails: null,
    error: null,
    successMessage: null
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
    switch (action.type) {
        // Update Profile Actions
        case types.UPDATE_PROFILE_REQUEST: return {
            ...state, isLoading: true
        };
        case types.UPDATE_PROFILE_SUCCESS: {
            return {
                ...state, isLoading: false,
                profileDetails: action.payload.result.data,
                successMessage: action.payload.result.message
            };
        }
        case types.UPDATE_PROFILE_FAILURE: return {
            ...state, isLoading: false, error: action.payload.result || defaultErrorMessage
        };

            // Change Password Actions
            case types.CHANGE_PASSWORD_REQUEST: return {
                ...state, isLoading: true
            };
            case types.CHANGE_PASSWORD_SUCCESS: {
                return {
                    ...state, isLoading: false,
                    // profileDetails: action.payload.result.data,
                    successMessage: action.payload.result.message
                };
            }
            case types.CHANGE_PASSWORD_FAILURE: return {
                ...state, isLoading: false, error: action.payload.result || defaultErrorMessage
            };

        // Clear Error
        case types.CLEAR_ACCOUNT_SETTINGS_RESPONSE_MESSAGES: return {
            ...state, error: null, successMessage: null
        }
        default: {
            return  state ;
        }
    }
}