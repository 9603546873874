import React, { Component } from 'react'
import { BsCircleFill } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import { NavLink } from 'react-router-dom'
import { GrCatalog } from 'react-icons/gr'
import { TiFlowSwitch } from 'react-icons/ti'
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { connect } from 'react-redux'

import DashboardBlackSVG from './../../../assets/dashboard_black_icon.svg'
import DashboardWhiteSVG from './../../../assets/dashboard_white_icon.svg'
import DataCatalogBlackSVG from './../../../assets/data_catalog_icon_black.svg'
import DataCatalogWhiteSVG from './../../../assets/data_catalog_icon_white.svg'
import WorkflowBlackSVG from './../../../assets/workflow_icon_black.svg'
import WorkflowWhiteSVG from './../../../assets/workflow_icon_white.svg'
import ReportBlackSVG from './../../../assets/report_icon_black.svg'
import ReportWhiteSVG from './../../../assets/report_icon_white.svg'
import DocumentGreySVG from './../../../assets/document_grey_icon.svg'
import FolderGreenSVG from './../../../assets/folder_green.svg'

import styles from './Sidebar.module.css'

const renderDashboardTooltip = props => {
  return (
    <Tooltip className="my-tooltip" id="renderDashboardTooltip" {...props}>
      Dashboard
    </Tooltip>
  )
}

const renderDocumentTooltip = props => {
  return (
    <Tooltip id="renderDocumentTooltip" {...props}>
      Document
    </Tooltip>
  )
}

const renderCatalogTooltip = props => {
  return (
    <Tooltip id="renderCatalogTooltip" {...props}>
      Data Catalog
    </Tooltip>
  )
}
const renderWorkflowTooltip = props => {
  return (
    <Tooltip id="renderWorkflowTooltip" {...props}>
      Workflow Management
    </Tooltip>
  )
}

const renderReportsTooltip = props => {
  return (
    <Tooltip id="renderReportsTooltip" {...props}>
      Support
    </Tooltip>
  )
}

const renderDocumentsTooltip = props => {
  return (
    <Tooltip id="renderDocumentsTooltip" {...props}>
      Documents
    </Tooltip>
  )
}
class Sidebar extends Component {
  count = 0
  baseURL = 'https://precilyclient.azurewebsites.net'
  dataCatalogStack = [
    '/data-catalog',
    '/data-catalog-2',
    '/related-searches-page',
    '/master-list',
  ]

  state = {
    indexOfComponentSelected: 0,
    selectedUrl: '',
  }

  handleClick = url => {
    this.setState({ selectedUrl: url })
  }

  entering = e => {
    e.children[0].style.borderTopColor = '#1BA94C'
    e.children[1].style.backgroundColor = '#1BA94C'
    e.children[0].style.color = '#fff'
  }

  render() {
    const { indexOfComponentSelected, selectedUrl } = this.state
    const url = window.location.pathname
    const search = window.location.search
    const { isNewDocumentSaved } = this.props

    return (
      <div className={styles.root}>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderDashboardTooltip}
          // onEntering={this.entering}

          // popperConfig = {{text:"Dashboard"}}
        >
          <div
            style={{
              backgroundColor:
                url === '/dashboard' || search.includes('dashboard')
                  ? '#1BA94C'
                  : '#eaeaea',
            }}
            onClick={() => {
              this.handleClick('/dashboard')
            }}
            className={styles.circle_fill_icon}
          >
            <NavLink
              to={'/dashboard'}
              // activeStyle={{
              //   backgroundColor: '#1BA94C',
              // }}
            >
              {/* <i className={`fab fa-microsoft ${styles.icon_style}`}></i> */}
              {url === '/dashboard' || search.includes('dashboard') ? (
                <img src={DashboardWhiteSVG} alt="Dashboard Icon" />
              ) : (
                <img src={DashboardBlackSVG} alt="Dashboard Icon" />
              )}
            </NavLink>
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderCatalogTooltip}
          onEntering={this.entering}
        >
          <div
            style={{
              backgroundColor:
                this.dataCatalogStack.includes(url) ||
                search.includes('data-catalog')
                  ? '#1BA94C'
                  : '#eaeaea',
            }}
            className={styles.circle_fill_icon}
          >
            <NavLink
              to={'/data-catalog'}
              // activeStyle={{
              //   backgroundColor: '#1BA94C',
              // }}
            >
              {/* <i className={`fas fa-folder ${styles.icon_style}`}></i> */}
              {this.dataCatalogStack.includes(url) ||
              search.includes('data-catalog') ? (
                <img src={DataCatalogWhiteSVG} alt="Dashboard Icon" />
              ) : (
                <img src={DataCatalogBlackSVG} alt="Dashboard Icon" />
              )}
            </NavLink>
          </div>
        </OverlayTrigger>

        {/* <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderWorkflowTooltip}
          onEntering={this.entering}
        >
          <div
            style={{
              backgroundColor:
                url === '/workflow-management' ||
                search.includes('workflow-management')
                  ? '#1BA94C'
                  : '#eaeaea',
            }}
            // className={styles.circle_fill_icon}
            className={` ${styles.circle_fill_icon} `}
          >
            <NavLink to={'/workflow-management'}>
              {url === '/workflow-management' ||
              search.includes('workflow-management') ? (
                <img src={WorkflowWhiteSVG} alt="Dashboard Icon" />
              ) : (
                <img src={WorkflowBlackSVG} alt="Dashboard Icon" />
              )}
            </NavLink>
          </div>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderReportsTooltip}
          onEntering={this.entering}
        >
          <div
            style={{
              backgroundColor:
                url === '/support-page' || search.includes('support-page')
                  ? '#1BA94C'
                  : '#eaeaea',
            }}
            className={`${styles.circle_fill_icon} ${styles.last_main_nav_icon}`}
          >
            <NavLink to={'/support-page'}>
              {url === '/support-page' || search.includes('support-page') ? (
                <img src={ReportWhiteSVG} alt="Dashboard Icon" />
              ) : (
                <img src={ReportBlackSVG} alt="Dashboard Icon" />
              )}
            </NavLink>
          </div>
        </OverlayTrigger> */}

        <div className={styles.divider}></div>

        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderDocumentsTooltip}
          onEntering={this.entering}
        >
          <div
            // style={{
            //   backgroundColor:
            //     url === '/support-page' || search.includes('support-page')
            //       ? '#1BA94C'
            //       : '#eaeaea',
            // }}
            className={styles.document_icon}
          >
            <NavLink to={'/document-list'}>
              {isNewDocumentSaved ? (
                <div className={styles.document_div}>
                  <img
                    className={styles.document_icon}
                    src={DocumentGreySVG}
                    alt="DocumentGreySVG Icon"
                  />
                  <span className={styles.document_badge}></span>
                </div>
              ) : url === '/document-list'  || search.includes('document-list') ? (
                <img src={FolderGreenSVG} alt="FolderGreenSVG Icon" />
              ) : (
                <img src={DocumentGreySVG} alt="DocumentGreySVG Icon" />
              )}
            </NavLink>
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isNewDocumentSaved: state.sidebar.isNewDocumentSaved,
  }
}
export default connect(mapStateToProps, null)(Sidebar)
