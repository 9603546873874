import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'
import BeatLoader from 'react-spinners/BeatLoader'
import _ from 'lodash'

import styles from './SearchResultsPage.module.css'
import commonStyles from '../../shared/Common.module.css'
// import Sidebar from '../../shared/sidebar/Sidebar'
import SearchBarAndDropDown from '../../components/data-catalog/search_bar_and_dropdown/SearchBarAndDropDown'
import Sources from '../../components/search-results-page/sources/Sources'
import RelatedSearchesAndFolders from '../../components/search-results-page/related-searches-and-folders/RelatedSearchesAndFolders'
import DataInstance from '../../components/data-catalog/data-instance/DataInstance'
import recentDocuments from '../../json-data/recentDocuments.json'
import RecentDocuments from '../../components/data-catalog/recent-documents/RecentDocuments'
import GreenDocument from './../../assets/green_document.svg'
// import SearchBox from '../search_bar_and_dropdown/SearchBarAndDropDown'
import * as catalogSearchResults from '../../store/actions/catalogSearchResultsAction'
import * as catalogLandingPage from '../../store/actions/catalogLandingPageActions'
import { connect } from 'react-redux'

import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'
import RightBar from '../../screens/workflow-management/RightBar/RightBar'
import { css } from '@emotion/core'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`
class SearchResultsPage extends Component {
  ALL_FOLDERS_LIMIT = 10
  PER_PAGE = 6
  recentDocumentsData = []
  searchBarAndDropdownRef = React.createRef()
  sourcesRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      recentDocumentPaginationData: [],
      totalNumberOfPages: 1,
      searchTerm: this.props.catalogSearchTerm
        ? this.props.catalogSearchTerm
        : this.props.history.location?.state?.searchItems,
      sourcesData: null,
    }
  }

  /*
   * request for data when not already present
   */
  componentDidMount() {
    this.props.catalogRecentFolders({ limit: this.ALL_FOLDERS_LIMIT })
    const paramObject = {}
    this.props.catalogAdvancedSearch(paramObject)

    // if (this.props.catalogSearchResultData) return
    // const paramsObj = {
    //   query: this.props.history.location?.state?.searchItems,
    //   source: '',
    //   filters: {},
    // }

    // this.props.catalogSearchResultRequest(paramsObj)
    // this.props.catalogSearchRelatedFolders()
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isCatalogSearchResultDataLoading &&
      prevProps.isCatalogSearchResultDataLoading &&
      this.props.catalogSearchResultData
    ) {
      //  For ReactPaginate, calc page range
      this.recentDocumentsData = this.props.catalogSearchResultData?.recentDocuments
      const totalNumberOfPages = this.recentDocumentsData.length / this.PER_PAGE
      this.setState(
        {
          totalNumberOfPages,
        },
        () => {
          this.handlePaginationClick()
        }
      )
    }
    if (!this.props.isLoading && prevProps.isLoading && this.props.graphInfo) {
      this.setState({ graphData: this.props.graphInfo.graphData?.graph })
    }

    if (
      prevProps.isCatalogRecentFoldersLoading &&
      !this.props.iscatalogRecentFoldersLoading &&
      this.props.catalogRecentFoldersData
    ) {
      let catalogAllSource = []
      catalogAllSource[0] = this.props.catalogRecentFoldersData?.privateFolder[0]
      catalogAllSource[1] = this.props.catalogRecentFoldersData?.precilyData
      catalogAllSource = [
        ...catalogAllSource,
        ...this.props.catalogRecentFoldersData?.allFolders,
      ]
      // console.info('catalogAllSource', catalogAllSource)
      this.setState({
        sourcesData: catalogAllSource,
      })
    }
  }
  getAdvancedSearchData = () => {
    const advancedSearchFilters = this.props.catalogAdvancedSearchFilters

    let advancedSearchFiltersRefined = {}

    if (advancedSearchFilters) {
      for (const [key, value] of Object.entries(advancedSearchFilters)) {
        if (value !== null && value.length !== 0) {
          const obj = {
            [key]: value,
          }
          advancedSearchFiltersRefined = {
            ...advancedSearchFiltersRefined,
            ...obj,
          }
        }
      }
    }
    const { startDate, endDate } = this.getAdvancedSearchState()
    const date = [startDate, endDate]

    const params = {
      type:
        _.isEmpty(advancedSearchFiltersRefined) &&
        startDate === null &&
        endDate === null
          ? 'NLQ'
          : 'ADAI',
      query: this.getSearchTerm(),
      source: this.getSelectedSources(),
      filters:
        startDate !== null && endDate !== null
          ? { ...advancedSearchFiltersRefined, year: date }
          : advancedSearchFiltersRefined,
    }

    this.props.catalogSearchTermRequest({ searchTerm: this.getSearchTerm()})
    this.props.catalogSearchResultRequest(params)
    this.clearAll()
  }

  handlePaginationClick = data => {
    const currentPage = data === undefined ? 0 : data.selected
    const offset = this.PER_PAGE * currentPage
    const recentDocumentPaginationData = this.recentDocumentsData.slice(
      offset,
      offset + this.PER_PAGE
    )
    this.setState({
      recentDocumentPaginationData,
    })
  }

  handleSearch = searchItems => {
    this.setState({ searchTerm: searchItems })
    const paramsObj = {
      query: searchItems,
      source: '',
      filters: {
        // fileType: ['SCN'],
      },
    }
    this.props.catalogSearchTermRequest({ searchTerm: searchItems })
    this.props.catalogSearchResultRequest(paramsObj)
    this.props.saveSearchTermRequest(searchItems)
    // this.props.catalogSearchRelatedFolders()
  }

  handleKeywordClick = keyword => {
    this.setState({ searchTerm: keyword })
    const paramsObj = {
      query: keyword,
      source: '',
      filters: {
        // fileType: ['SCN'],
      },
    }
    this.props.catalogSearchTermRequest({ searchTerm: keyword })
    this.props.catalogSearchResultRequest(paramsObj)
  }

  clearAll = () => {
    // this.searchBarAndDropdownRef.current.clearAll()
    this.searchBarAndDropdownRef.current.toggleAdvancedSearch()
  }
  getSelectedSources = () => {
    return this.sourcesRef.current.getSelectedSources()
  }

  getAdvancedSearchState = () => {
    return this.searchBarAndDropdownRef.current.getAdvancedSearchState()
  }
  resetDataOnPage = () => {
    const params = {
      query: this.state.searchTerm,
      source: '',
      filters: {},
    }
    this.props.catalogSearchResultRequest(params)
  }
  getSearchTerm = () => {
    const searchTerm = this.searchBarAndDropdownRef.current.getSearchTerm()
    // this.setState({ searchTerm })
    return searchTerm
  }

  render() {
    const {
      recentDocumentPaginationData,
      totalNumberOfPages,
      searchTerm,
      sourcesData,
    } = this.state
    const advancedSearchDataObject = this.props.catalogAdvancedSearchData
    let advancedSearchData
    if (advancedSearchDataObject)
      advancedSearchData = advancedSearchDataObject.filters

    // console.info('sthis.props.catalogSearchTerm', this.props.catalogSearchTerm)

    return (
      <>
        <Sidebar />
        <NavBar loading={this.props.isCatalogSearchResultDataLoading}></NavBar>
        <RightBar></RightBar>
        <div
          className={`${commonStyles.commom_wrapper} ${styles.root}`}
          // onClick={this.closeModal}
        >
          <div className={styles.search_box_container}>
            <SearchBarAndDropDown
              ref={this.searchBarAndDropdownRef}
              showSearchTerm={true}
              advancedSearchData={advancedSearchData}
              searchKeyword={searchTerm}
              handleSearch={searchItems => {
                this.handleSearch(searchItems)
              }}
              getAdvancedSearchData={this.getAdvancedSearchData}
              parent="SearchResultsPage"
              resetDataOnPage={this.resetDataOnPage}
            />
          </div>
          <div className={styles.data_instances_container}>
            <div className={styles.left}>
              {this.props.isCatalogSearchResultDataLoading ? (
                <BeatLoader
                  css={override}
                  size={25}
                  color={'#11256D'}
                  loading={true}
                />
              ) : (
                <DataInstance data={this.props.catalogSearchResultData} />
              )}

              {/* Graph would go here */}
            </div>
            <div className={styles.right}>
              <Sources
                data={sourcesData ? sourcesData : []}
                searchTerm={searchTerm}
                ref={this.sourcesRef}
              />
            </div>
          </div>
          <hr />

          <div className={styles.recent_documents_container}>
            {this.props.catalogSearchResultData?.recentDocuments?.length >
              0 && (
              <div className={styles.recent_documents_header_container}>
                <div className={styles.recent_documents_title}>
                  Recent Documents
                </div>
                <div className={styles.icon_container}>
                  <img src={GreenDocument} alt="document" />
                </div>
                <div
                  className={`recent-documents-react-pagination ${styles.react_pagination}`}
                >
                  {recentDocumentPaginationData && (
                    <ReactPaginate
                      previousLabel={''}
                      nextLabel={''}
                      breakLabel={''}
                      breakClassName={'break-me'}
                      pageCount={totalNumberOfPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={totalNumberOfPages}
                      onPageChange={this.handlePaginationClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  )}
                </div>
              </div>
            )}
            {(!this.props.catalogSearchResultData ||
              this.props.catalogSearchResultData?.recentDocuments?.length <
                1) && <div>No Result Found...</div>}
            <div className={styles.recent_document_card}>
              <RecentDocuments
                // recentDocuments={recentDocumentPaginationData}
                recentDocuments={
                  this.props.catalogSearchResultData?.recentDocuments
                }
                parent={'SearchResultsPage'}
              />
            </div>
          </div>
          <div>
            {this.props.catalogSearchResultData && (
              <RelatedSearchesAndFolders
                filters={this.props.catalogSearchResultData?.filters}
                documentsData={this.props.catalogSearchResultData?.documents}
                allDocumentData={
                  this.props.catalogSearchResultData?.allDocuments
                }
                recentFolders={
                  this.props.catalogSearchResultData?.recentFolders
                }
                keywords={this.props.catalogSearchResultData?.keywords}
                showMore={this.props.catalogSearchResultData?.showMore}
                history={this.props.history}
                // totalDocumentsCount={
                //   this.props.catalogSearchResultData?.allDocuments?.length
                // }
                searchTerm={searchTerm}
                handleKeywordClick={this.handleKeywordClick}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    catalogAdvancedSearchData: state.catalogLandingPage.catalogAdvancedSearch,
    catalogAllSource: state.catalogLandingPage.catalogAllSource,
    catalogSearchResultData: state.catalogSearchResult.catalogSearchResultData,
    isCatalogSearchResultDataLoading:
      state.catalogSearchResult.isCatalogSearchResultDataLoading,
    catalogAdvancedSearchFilters:
      state.catalogLandingPage.catalogAdvancedSearchFilters,
    isCatalogRecentFoldersLoading:
      state.catalogLandingPage.iscatalogRecentFoldersLoading,
    catalogRecentFoldersData: state.catalogLandingPage.catalogRecentFolders,
    catalogSearchTerm: state.catalogSearchResult.catalogSearchTerm,
  }
}
const mapDispatchToProps = {
  catalogAllSource: catalogLandingPage.catalogAllSourceRequest,
  catalogRecentFolders: catalogLandingPage.catalogRecentFoldersRequest,
  catalogSearchResultRequest:
    catalogSearchResults.catalogSearchResultDataRequest,
  catalogSearchRelatedFolders:
    catalogSearchResults.catalogSearchRelatedFoldersRequest,
  catalogSearchTermRequest: catalogLandingPage.catalogSearchTermRequest,
  catalogAdvancedSearch: catalogLandingPage.catalogAdvancedSearchRequest,
  saveAdvancedSearchFilters:
    catalogLandingPage.catalogAdvancedSearchFilterAction,
  saveSearchTermRequest:
    catalogSearchResults.catalogSearchResultSearchTermAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage)
