import * as types from '../types';

const initialState = {
    isLoading: false,
    allGraphs: null,
    error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
    switch (action.type) {
        // Update Profile Actions
        case types.ALL_REPORT_ANALYSIS_GRAPH_REQUEST: return {
            ...state, allGraphs:null, isLoading: true
        };
        case types.ALL_REPORT_ANALYSIS_GRAPH_SUCCESS: {
            return {
                ...state, isLoading: false,
                allGraphs: action.payload.result.data,
                
            };
        }
        case types.ALL_REPORT_ANALYSIS_GRAPH_FAILURE: return {
            ...state, isLoading: false, error: action.payload.result || defaultErrorMessage
        };

        default: {
            return  state ;
        }
    }
}