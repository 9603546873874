import React, { Component } from "react";
import styles from "./ClusteredResultSimilarPara.module.css";
// import commonStyles from '../../../shared/Common.module.css';
import { Tab, Tabs } from "react-bootstrap";
import SimilarParaContent from "../similar-para-content/SimilarParaContent";
import SectionNum from "../section-num/SectionNum";
// import FactualData from "../factual-data/FactualData"

class ClusteredResultSimilarPara extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="thin_custom_scrollbar">
        <div
          className={`tab_with_bottom_border ${styles.clustered_paragraphs_details_container}`}
        >
          <Tabs id="clustered-paragraphs-details-tab">
            {this.props.data?.data?.byTag && (
              <Tab
                eventKey="doc_info"
                title="By Tags"
                // disabled={
                //   this.props.uploadDoc.similarParas.byTag.length > 0
                //     ? false
                //     : true
                // }
              >
                <SimilarParaContent
                  data={this.props.data.data.byTag}
                />
              </Tab>
            )}

            {this.props.data?.data?.bySectionNum && (
              <Tab
                eventKey="similar_para"
                title="By Section Num"
                // disabled={
                //   this.props.uploadDoc.similarParas.bySectionNum.length > 0
                //     ? false
                //     : true
                // }
              >
                <SectionNum
                  data={this.props.data.data.bySectionNum}
                />
              </Tab>
            )}
            {/* <Tab eventKey="similar_docs" title="By Factual Data">
              <FactualData />
            </Tab> */}
          </Tabs>
        </div>
      </div>
    );
  }
}
export default ClusteredResultSimilarPara;
