import React, { Component } from 'react'
import { IconContext } from 'react-icons'
import { IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io'
import OutsideClickHandler from 'react-outside-click-handler'

import SearchBar from './../../../components/data-catalog/search-bar/SearchBar'
import CommonModal from '../../../shared/common-modal/CommonModal'

import ClipSVG from './../../../assets/clip_green.svg'
import { emails } from './../../../json-data/emailModal.json'
import styles from './EmailModal.module.css'

export default class EmailModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPanelIndex: null,
    }
  }

  showPanelHandler = index => {
    this.setState({
      showPanelIndex: index,
    })
  }

  closePanelHandler = () => {
    this.setState({
      showPanelIndex: null,
    })
  }

  render() {
    const { showPanelIndex } = this.state
    const { emailModalHandler } = this.props

    return (
      <CommonModal
        modalClass={'email_common_modal'}
        closeHandler={emailModalHandler}
        backdropClassName={'email_common_modal_blue_bg'}
        header={
          <div className={`${styles.modal_header_container}`}>
            <h4 className={`${styles.modal_header_heading}`}>Emails</h4>
            <div className={styles.search_bar_container}>
              <SearchBar
                iconColor={'#2c2c2c'}
                iconSize={'24px'}
                placeholder={'Search'}
                fontSize={'18px'}
                handleSearch={() => {}}
              />
            </div>
          </div>
        }
      >
        <div className={styles.root}>
          {emails.map(({ email, filters }, index) => (
            <div className={styles.email_container}>
              <div className={`email_modal_checkbox ${styles.checkbox}`}>
                <label class={styles.container}>
                  <input type="checkbox" />
                  <span class={styles.checkmark}></span>
                </label>
              </div>
              <div className={styles.sender_details}>
                <div className={styles.label}>SENDER</div>
                <div>someone@email.com</div>
              </div>
              <div className={styles.subject_section}>
                <div className={styles.label}>SUBJECT</div>
                <div className={styles.title}>
                  Laborum laborum laborum proident officia occaecat ullamco
                  adipisicing.
                </div>
                <div className={styles.attachments}>
                  <div className={styles.attachment_1}>
                    <img src={ClipSVG} alt="Clip Icon" />
                    <span>Oci asdas asd</span>
                  </div>
                  <div className={styles.attachment_2}>+2</div>
                </div>
              </div>
              <div className={styles.date_section}>
                <div className={styles.label}>DATE</div>
                <div>24 NOV 2020</div>
              </div>
              <div className={styles.dropdown_container}>
                <div
                  className={styles.dropdown}
                  onClick={() => this.showPanelHandler(index)}
                >
                  <div>Wwew asdsad asdasdsad</div>
                  <IconContext.Provider
                    value={{
                      size: 21,
                      color: '#2c2c2c',
                    }}
                  >
                    <IoMdArrowDropdown />
                  </IconContext.Provider>
                </div>

                {showPanelIndex === index && (
                  <OutsideClickHandler onOutsideClick={this.closePanelHandler}>
                    <div className={styles.panel}>
                      <div className={styles.search_bar}>
                        <SearchBar
                          iconColor="#9a9a9a"
                          iconSize="24px"
                          placeholder="Search"
                        />
                      </div>
                      <div className={styles.filter_container}>
                        {filters.map((filter, index) => (
                          <div className={styles.filter}>{filter}</div>
                        ))}
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            </div>
          ))}
        </div>
      </CommonModal>
    )
  }
}
