import moment from 'moment'
export const formatDate = dateField => {
  const monthMap = {
    1: 'JAN',
    2: 'FEB',
    3: 'MARCH',
    4: 'APRIL',
    5: 'MAY',
    6: 'JUNE',
    7: 'JULY',
    8: 'AUG',
    9: 'SEPT',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
  }
  // if (!dateField) dateField = Date.now()

  const date = new Date(dateField)
  const year = date.getFullYear()
  const month = (1 + date.getMonth())
  const day = date.getDate().toString().padStart(2, '0')
  const time = moment.utc(dateField).format('HH:mm A')
  return {
    date: day + ' ' + monthMap[month] + ' ' + year,
    time: time,
  }
}
