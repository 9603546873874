import React, { Component } from 'react';

import { AiOutlineLink } from 'react-icons/ai';
import { FiUserPlus } from 'react-icons/fi';
import { AiOutlineFolder } from 'react-icons/ai';
import { IconContext } from 'react-icons';

import AddMember from './../add-member/AddMember';
import PrivateFolder from './../private-folder/PrivateFolder';
import ShareLink from './../shareLink/shareLink';
import CommonStyles from './../../../../shared/Common.module.css';
import styles from './Share.module.css';
import { connect } from 'react-redux';
import * as fileLink from '../../../../store/actions/fileLinkAction';
import { triggerToast } from '../../../../shared/helper';

class Share extends Component {
  state = {
    showAddMemberPanel: false,
    showPrivateFolderPanel: false,
    showSharePanel: false,
    createdFileLink: '',
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isFileLinkLoading &&
      !this.props.isFileLinkLoading &&
      this.props.createdFileLink?.data?.url
    ) {
      this.setState({ createdFileLink: this.props.createdFileLink?.data?.url });
    }
  }

  toggleAddMemberPanel = () => {
    const { showAddMemberPanel, showPrivateFolderPanel } = this.state;
    this.setState({
      showAddMemberPanel: !showAddMemberPanel,
      showPrivateFolderPanel: false,
    });
  };

  closeAddMemberPanel = () => {
    this.setState({
      showAddMemberPanel: false,
    });
  };

  togglePrivateFolderPanel = () => {
    const { showPrivateFolderPanel, showAddMemberPanel } = this.state;
    this.setState({
      showPrivateFolderPanel: !showPrivateFolderPanel,
      showAddMemberPanel: false,
    });
  };

  closePrivateFolderPanel = () => {
    this.setState({
      showPrivateFolderPanel: false,
    });
  };

  toggleSharePanel = () => {
    const { showSharePanel } = this.state;
    this.setState({
      showSharePanel: !showSharePanel,
      // showAddMemberPanel: false,
    });
    this.props.fileLinkRequest({ fileId: this.props.fileId });
  };

  handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    triggerToast('Copied link to clipboard', 'success');
    const { showSharePanel } = this.state;
    this.setState({
      showSharePanel: !showSharePanel,
      showAddMemberPanel: false,
    });
  };

  render() {
    const {
      showAddMemberPanel,
      showPrivateFolderPanel,
      showSharePanel,
      createdFileLink,
    } = this.state;
    const { fileId, index, top, left, right } = this.props;

    return (
      <div
        className={`${CommonStyles.card_layout} ${styles.root}`}
        style={
          index === 0 || index === 1
            ? {
                left: `${left}`,
                top,
                right: 'unset',
              }
            : {
                right: `${right}`,
                top,
              }
        }
      >
        {showAddMemberPanel && index !== 0 && index !== 1 && (
          <div className={styles.add_member_wrapper}>
            <AddMember closeAddMemberPanel={this.closeAddMemberPanel} />
          </div>
        )}
        {showPrivateFolderPanel && index !== 0 && index !== 1 && (
          <div className={styles.private_folder_wrapper}>
            <PrivateFolder
              fileId={fileId}
              closePanel={this.closePrivateFolderPanel}
            />
          </div>
        )}
        {showSharePanel && index !== 0 && index !== 1 && (
          <div className={styles.add_member_wrapper}>
            <ShareLink
              createdFileLink={createdFileLink}
              handleCopy={this.handleCopy}
            />
          </div>
        )}

        <div className={`${CommonStyles.card_layout} ${styles.share_wrapper}`}>
          <div
            className={styles.email_container}
            onClick={this.toggleSharePanel}
          >
            <div className={styles.icon_container}>
              <IconContext.Provider value={{ color: '#9a9a9a', size: '24px' }}>
                <div>
                  <AiOutlineLink />
                </div>
              </IconContext.Provider>
            </div>
            <div className={styles.text}>Link</div>
          </div>

          <div
            className={styles.add_member_wrapper}
            style={
              showAddMemberPanel
                ? {
                    backgroundColor: '#11256D',
                  }
                : {}
            }
          >
            <div
              className={styles.add_member_container}
              onClick={this.toggleAddMemberPanel}
            >
              <div className={styles.icon_container}>
                <IconContext.Provider
                  value={
                    showAddMemberPanel
                      ? { color: '#fff', size: '24px' }
                      : { color: '#9a9a9a', size: '24px' }
                  }
                >
                  <div>
                    <FiUserPlus />
                  </div>
                </IconContext.Provider>
              </div>
              <div
                className={styles.text}
                style={
                  showAddMemberPanel
                    ? {
                        color: '#fff',
                      }
                    : {}
                }
              >
                Add Member
              </div>
            </div>
          </div>

          <div
            className={styles.private_folder_wrapper}
            style={
              showPrivateFolderPanel
                ? {
                    backgroundColor: '#11256D',
                  }
                : {}
            }
          >
            <div
              className={styles.add_member_container}
              onClick={this.togglePrivateFolderPanel}
            >
              <div className={styles.icon_container}>
                <IconContext.Provider
                  value={
                    showPrivateFolderPanel
                      ? { color: '#fff', size: '24px' }
                      : { color: '#9a9a9a', size: '24px' }
                  }
                >
                  <div>
                    <AiOutlineFolder />
                  </div>
                </IconContext.Provider>
              </div>
              <div
                className={styles.text}
                style={
                  showPrivateFolderPanel
                    ? {
                        color: '#fff',
                      }
                    : {}
                }
              >
                My Folder
              </div>
            </div>
          </div>
        </div>

        {showSharePanel && (index === 0 || index === 1) && (
          <div className={styles.add_member_wrapper}>
            <ShareLink
              createdFileLink={createdFileLink}
              handleCopy={this.handleCopy}
            />
          </div>
        )}
        {showAddMemberPanel && (index === 0 || index === 1) && (
          <div className={styles.add_member_wrapper}>
            <AddMember closeAddMemberPanel={this.closeAddMemberPanel} />
          </div>
        )}
        {showPrivateFolderPanel && (index === 0 || index === 1) && (
          <div className={styles.private_folder_wrapper}>
            <PrivateFolder
              fileId={fileId}
              closePanel={this.closePrivateFolderPanel}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createdFileLink: state.fileLink.createdLink,
    isFileLinkLoading: state.fileLink.isLoading,
  };
};

const mapDispatchToProps = {
  fileLinkRequest: fileLink.fileLinkRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
