import React, { Component } from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { IconContext } from 'react-icons'

import styles from './CommonDropdown.module.css'

export default class CommonDropdown extends Component {
  onDropdownChange = e => {
    this.props.selectedDropdown(e.target.name, e.target.value)
  }
  render() {
    const {
      backgroundColor,
      value0,
      fontSize,
      width,
      height,
      borderRadius,
      toggled,
      padding,
      name,
      dropdownList,
      iconSize = '24px',
      textColor,
    } = this.props

    // console.info(this.props.dropdownList)

    // let arr =[...this.props.dropdownList]
    // let newArray=[...this.props.dropdownList]
    // console.log("hg", arr, this.props.index)
    // if(this.props.index){
    //  newArray = arr.splice(this.props.index,arr.length-1)
    // }
    return (
      <div
      // className={styles.dropdown}
      // style={{
      //   width,

      // }}
      >
        <select
          style={{
            backgroundColor,
            width,
            height,
            fontSize,
            borderRadius,
            padding,
            color: '#c4c4c4'
          }}
          name={name}
          className={styles.common_dropdown_style}
          onChange={this.onDropdownChange}
          defaultValue={0}
        >
          <option  value={0} disabled style={{ color: '#c4c4c4' }}>
            Choose Type
          </option>
          {this.props.dropdownList?.map(dropdown => {
            return (
              <option
                key={dropdown.key}
                value={dropdown.key}
                style={{
                  color: '#000',
                }}
              >
                {dropdown.value}
              </option>
            )
          })}
        </select>
        {/* <div
          className={styles.text}
          style={
            toggled
              ? { color: '#11256d' }
              : { color: textColor ? textColor : '#7a7a7a' }
          }
        >
          {value0}
        </div> */}
        {/* <div className={styles.icon}>
          <IconContext.Provider
            value={
              toggled
                ? { color: '#11256d', size: `${iconSize}` }
                : { color: '#7a7a7a', size: `${iconSize}` }
            }
          >
            {toggled ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </IconContext.Provider>
        </div> */}
      </div>
    )
  }
}
