import React, { Component } from 'react'
import { IoIosArrowForward, IoMdArrowDropup } from 'react-icons/io'
import { IconContext } from 'react-icons'
import _ from 'lodash'
import { connect } from 'react-redux'

import CheckboxListFilter from './../../../checkbox-list-filter/CheckboxListFilter'
import SearchBar from './../../../data-catalog/search-bar/SearchBar'
import CommonStyles from './../../../../shared/Common.module.css'
import styles from './Filter.module.css'
import CheckboxList from '../../../data-catalog/checkbox-list/CheckboxList'

class Filter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tagSelected: 0,
      searchTerm: null,
      filteredList: null,
      showSelected: false,
      areFiltersSelected: false,
    }

    this.checkboxListRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    //  TODO: won't work on refresh
    if (prevProps.allFilters !== this.props.allFilters) {
      if (this.props.allFilters)
        this.setState({
          areFiltersSelected: true,
        })
      else
        this.setState({
          areFiltersSelected: false,
        })
    }
  }

  changeTagSelected = index => {
    this.setState({
      tagSelected: index,
      filteredList: null,
    })
  }

  handleSearchChange = searchItems => {
    this.setState({ searchItems }, () => {
      this.getFilteredList(searchItems)
    })
  }
  getFilteredList = searchItems => {
    // console.info('search term', searchItems === null)
    const { filters } = this.props
    const { tagSelected } = this.state
    // console.info('filters', filters, 'tagSelected', tagSelected)

    if (searchItems === '') {
      this.setState({ filteredList: null })
    } else {
      let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      let filteredList = filters[tagSelected]?.children.filter(member => {
        return member.toLowerCase().search(subStr.toLowerCase()) !== -1
      })
      this.setState({ filteredList })
    }
  }

  toggleShowSelected = e => {
    // console.info(e.target.checked)
    this.setState({
      showSelected: e.target.checked,
    })
  }

  clearAll = () => {
    this.checkboxListRef.current.resetState()
    this.props.clearFilters()
    // this.setState({
    //   areFiltersSelected: false,
    // })
  }

  applyFilters = () => {
    const selectedFilters = this.checkboxListRef.current.getState()
    const documentData = this.props.documentsData

    console.info('documentData: ', documentData)

    if (documentData === null || documentData === undefined) return

    let filteredValues = []

    for (let [key, filters] of Object.entries(selectedFilters)) {
      // console.info('key', key, 'filters', filters)

      if (key === 0 + '') {
        if (filters.length)
          filters.forEach(element => {
            documentData.forEach(document => {
              if (
                Date.now() > new Date(document.endDate).getTime() &&
                element === 'Closed'
              ) {
                filteredValues.push(document)
              } else if (
                Date.now() < new Date(document.startDate).getTime() &&
                element === 'Others'
              )
                filteredValues.push(document)
              else if (
                Date.now() > new Date(document.startDate).getTime() &&
                Date.now() < new Date(document.endDate).getTime() &&
                element === 'Open'
              )
                filteredValues.push(document)
            })
          })
      }

      if (key === 1 + '') {
        if (filters.length)
          filters.forEach(element => {
            documentData.forEach(document => {
              if (document.type.toLowerCase() === element.toLowerCase())
                filteredValues.push(document)
            })
          })
      }

      if (key === 2 + '') {
        if (filters.length)
          filters.forEach(element => {
            documentData.forEach(document => {
              if (
                document.billingDetails.type.toLowerCase() ===
                element.toLowerCase()
              )
                filteredValues.push(document)
              else if (
                document.billingDetails.type.toLowerCase() !== 'hourly' &&
                document.billingDetails.type.toLowerCase() !== 'fixed' &&
                element.toLowerCase() === 'other'
              ) {
                filteredValues.push(document)
              }
            })
          })
      }
    }

    const uniqueFilteredValues = _.uniqBy(filteredValues, 'id')

    // console.info('filteredValues: ', filteredValues)
    console.info('uniqueFilteredValues', uniqueFilteredValues)

    this.props.applyFilters(uniqueFilteredValues)
  }

  getCheckboxListState = () => {
    return this.checkboxListRef.current.getState()
  }

  render() {
    const {
      tagSelected,
      searchTerm,
      filteredList,
      showSelected,
      areFiltersSelected,
    } = this.state
    const { filters, allFilters } = this.props

    return (
      <div className={`${CommonStyles.card_layout} ${styles.root}`}>
        {/* Top */}
        <div className={styles.top}>
          <div className={styles.search_bar_container}>
            <div className={styles.search_bar_background}>
              <div className={styles.search_bar_wrapper}>
                <SearchBar
                  iconColor={'#C4C4C4'}
                  iconSize={'14px'}
                  fontSize={'12px'}
                  fontColor={'#000'}
                  placeholder={'Search'}
                  handleSearchChange={searchItems => {
                    this.handleSearchChange(searchItems)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.filters}>
          <div className={styles.bottom}>
            {/* Bottom Left */}

            <div className={styles.bottom_left}>
              {/* Bottom Left Top */}
              <div className={styles.bottom_left_top}>
                {filters?.map(({ title }, index) => (
                  <div
                    className={styles.type_container}
                    style={
                      index === tagSelected
                        ? {
                            backgroundColor: '#11256d',
                            color: '#ffffff',
                          }
                        : { backgroundColor: '#ffffff', color: '#9A9A9A' }
                    }
                    onClick={e => this.changeTagSelected(index)}
                  >
                    <div className={styles.tag}>{title}</div>
                    <div className={styles.icon_container}>
                      <IconContext.Provider
                        value={
                          index === tagSelected
                            ? { color: '#ffffff', size: '21px' }
                            : { color: '#9A9A9A', size: '21px' }
                        }
                      >
                        <div>
                          <IoIosArrowForward />
                        </div>
                      </IconContext.Provider>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Bottom Right  */}
            <div className={styles.bottom_right}>
              <CheckboxListFilter
                data={filters}
                tagSelected={tagSelected}
                filteredList={filteredList}
                searchTerm={searchTerm}
                showSelected={showSelected}
                allFilters={allFilters}
                width={'12px'}
                height={'12px'}
                ref={this.checkboxListRef}
              />
            </div>
          </div>
        </div>

        <div className={styles.filter_options}>
          <div className={styles.switch_wrapper}>
            <div className={styles.switch_container}>
              <label class={styles.switch}>
                <input type="checkbox" onChange={this.toggleShowSelected} />
                <span class={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
            <div
              className={styles.bottom_text}
              style={showSelected ? { color: '#11256d' } : {}}
            >
              SELECTION
            </div>
          </div>
          <div className={styles.button_wrapper}>
            <div
              className={styles.clear_all_btn}
              onClick={this.clearAll}
              style={
                areFiltersSelected
                  ? { backgroundColor: '#11256d', color: '#fff' }
                  : {}
              }
            >
              CLEAR ALL
            </div>
            <div
              className={styles.advanced_search_btn}
              onClick={this.applyFilters}
            >
              APPLY
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    allFilters: state.workFlowManagementLandingPage.filters,
  }
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(Filter)
