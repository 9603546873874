import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as workFlowManagementLandingPage from '../actions/workFlowManagementLandingPageActions';

function* workflowManagementProjectDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestWorkflowManagementProject,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(
      workFlowManagementLandingPage.workflowManagementProjectSuccess(data)
    );
  } catch (e) {
    console.error('error  : ', e);
    yield put(
      workFlowManagementLandingPage.workflowManagementProjectFailure(e)
    );
  }
}

function* workflowManagementProjectDataWatcher() {
  yield takeLatest(
    types.WORKFLOW_MANAGEMENT_PROJECT_REQUEST,
    workflowManagementProjectDataWorker
  );
}

function* workflowManagementProjectGraphDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestWorkflowManagementProjectGraph,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(
      workFlowManagementLandingPage.workflowManagementProjectGraphSuccess(data)
    );
  } catch (e) {
    console.error('error  : ', e);
    yield put(
      workFlowManagementLandingPage.workflowManagementProjectGraphFailure(e)
    );
  }
}

function* workflowManagementProjectGraphDataWatcher() {
  yield takeLatest(
    types.WORKFLOW_MANAGEMENT_PROJECT_GRAPH_REQUEST,
    workflowManagementProjectGraphDataWorker
  );
}

function* workflowManagementDropdownDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestWorkflowManagementDropdown,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(
      workFlowManagementLandingPage.workflowManagementDropdownSuccess(data)
    );
  } catch (e) {
    console.error('error  : ', e);
    yield put(
      workFlowManagementLandingPage.workflowManagementDropdownFailure(e)
    );
  }
}

function* workflowManagementDropdownDataWatcher() {
  yield takeLatest(
    types.WORKFLOW_MANAGEMENT_DROPDOWN_REQUEST,
    workflowManagementDropdownDataWorker
  );
}

function* workflowManagementCreateProjectWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestWorkflowManagementCreateProject,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(
      workFlowManagementLandingPage.workflowManagementCreateProjectSuccess(data)
    );
  } catch (e) {
    console.error('error  : ', e);
    yield put(
      workFlowManagementLandingPage.workflowManagementCreateProjectFailure(e)
    );
  }
}

function* workflowManagementCreateProjectWatcher() {
  yield takeLatest(
    types.WORKFLOW_MANAGEMENT_CREATE_PROJECT_REQUEST,
    workflowManagementCreateProjectWorker
  );
}

export default function* workFlowManagementLandingPageSaga() {
  yield all([
    workflowManagementProjectDataWatcher(),
    workflowManagementProjectGraphDataWatcher(),
    workflowManagementDropdownDataWatcher(),
    workflowManagementCreateProjectWatcher(),
  ]);
}
