import * as types from '../types';

// get Document analytics history
export const documentAnalyticsHistoryGetRequest = (payload) => ({ type: types.DOCUMENT_ANALYTICS_HISTORY_GET_REQUEST, payload });
export const documentAnalyticsHistoryGetSuccess = (payload) => ({ type: types.DOCUMENT_ANALYTICS_HISTORY_GET_SUCCESS, payload });
export const documentAnalyticsHistoryGetFailure = (payload) => ({ type: types.DOCUMENT_ANALYTICS_HISTORY_GET_FAILURE, payload });

// Create Document analytics history
export const documentAnalyticsHistoryCreateRequest = (payload) => ({ type: types.DOCUMENT_ANALYTICS_HISTORY_CREATE_REQUEST, payload });
export const documentAnalyticsHistoryCreateSuccess = (payload) => ({ type: types.DOCUMENT_ANALYTICS_HISTORY_CREATE_SUCCESS, payload });
export const documentAnalyticsHistoryCreateFailure = (payload) => ({ type: types.DOCUMENT_ANALYTICS_HISTORY_CREATE_FAILURE, payload });

// Para Feedback Add
export const paraFeedbackAddRequest = (payload) => ({ type: types.PARA_FEEDBACK_ADD_REQUEST, payload });
export const paraFeedbackAddSuccess = (payload) => ({ type: types.PARA_FEEDBACK_ADD_SUCCESS, payload });
export const paraFeedbackAddFailure = (payload) => ({ type: types.PARA_FEEDBACK_ADD_FAILURE, payload });

// Para Feedback get all main and subtags
export const allMainSubTagsRequest = (payload) => ({ type: types.ALL_MAIN_SUB_TAGS_REQUEST, payload });
export const allMainSubTagsSuccess = (payload) => ({ type: types.ALL_MAIN_SUB_TAGS_SUCCESS, payload });
export const allMainSubTagsFailure = (payload) => ({ type: types.ALL_MAIN_SUB_TAGS_FAILURE, payload });

export const similarParaDataRequest = (payload) => ({ type: types.SIMILAR_PARA_DATA_REQUEST, payload });
export const similarParaDataSuccess = (payload) => ({ type: types.SIMILAR_PARA_DATA_SUCCESS, payload });
export const similarParaDataFailure = (payload) => ({ type: types.SIMILAR_PARA_DATA_FAILURE, payload });

export const similarDocDataRequest = (payload) => ({ type: types.SIMILAR_DOC_DATA_REQUEST, payload });
export const similarDocDataSuccess = (payload) => ({ type: types.SIMILAR_DOC_DATA_SUCCESS, payload });
export const similarDocDataFailure = (payload) => ({ type: types.SIMILAR_DOC_DATA_FAILURE, payload });

// Update file status
export const updateFileStatusRequest = (payload) => ({ type: types.UPDATE_FILE_STATUS_REQUEST, payload });
export const updateFileStatusSuccess = (payload) => ({ type: types.UPDATE_FILE_STATUS_SUCCESS, payload });
export const updateFileStatusFailure = (payload) => ({ type: types.UPDATE_FILE_STATUS_FAILURE, payload });

// file track
export const fileTrackRequest = (payload) => ({ type: types.FILE_TRACK_REQUEST, payload });
export const fileTrackSuccess = (payload) => ({ type: types.FILE_TRACK_SUCCESS, payload });
export const fileTrackFailure = (payload) => ({ type: types.FILE_TRACK_FAILURE, payload });
