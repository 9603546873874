import React, { Component } from "react";
import styles from "./UpdateTicket.module.css";
import commonStyles from "../../../shared/Common.module.css";
import CommonModal from "../../../shared/common-modal/CommonModal";
import { connect } from "react-redux";
import Select from "react-select";
import * as supportActions from "../../../store/actions/supportActions";
import CommonDropdown from '../../../shared/common-dropdown/CommonDropdown'

class UpdateTicket extends Component {
  constructor(props) {
    console.log('pp', props)
    super(props);
    this.state = {
      selectedOption: this.props.ticket[0].priority,
      description_text: this.props.ticket[0].description_text,
      subject: this.props.ticket[0].subject,
      isLoading: false
    };
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let errors = this.state.errors;
    if (value && value !== "") {
      errors[name] = "";
    }
    this.setState({ errors });
    this.setState({ [name]: value });
  };

  getPriorityOptions = () => {
    const roleDropdown = [
      { key: "Low", value: 1 },
      { key: "Medium", value: 2 },
      { key: "High", value: 3 },
      { key: "Urgent", value: 4 },
    ];
    const roleOptions = roleDropdown.map((item) => {
      return {
        value: item.key,
        label: item.value,
      };
    });
    return roleOptions;
  };
  handleEditMember = () => {
    const paramsObj = {
      subject: this.state.subject,
      email: this.props.user.email,
    };
    if (this.state.selectedOption.label === "High") {
      paramsObj.priority = 3;
    } else if (this.state.selectedOption.label === "Low") {
      paramsObj.priority = 1;
    } else if (this.state.selectedOption.label === "Medium") {
      paramsObj.priority = 2;
    } else {
      paramsObj.priority = 4;
    }
    if (this.props.ticket[0].status === "Open") {
      paramsObj.status = 2;
    } else if (this.props.ticket[0].status === "Pending") {
      paramsObj.status = 1;
    } else if (this.props.ticket[0].status === "Resolved") {
      paramsObj.status = 4;
    } else {
      paramsObj.status = 5;
    }
    this.props.updateTicket({ id: this.props.ticket[0].id, paramsObj });
    this.setState({isLoading: true})
  };
  selectedDropdown = (name, value) => {
    this.setState({ [name]: value })
  }
  render() {
    console.log("pp", this.props)
    const { selectedOption } = this.state;
    const { subject, description_text } = this.state;
    let roleDropdown = [];
    roleDropdown = this.getPriorityOptions();
    return (
      <div>
        <CommonModal
          modalClass={"delete_ticket_modal"}
          closeHandler={this.props.closeHandler}
          header={
            <div className={styles.header}>
            <div>Edit Support Ticket</div>
            </div>
          }
        >
          <form>
            <div className="row">
              <div className="col-md-6">


              <div className={styles.client_name}>
              <div className={styles.label}>SUBJECT</div>
              <input
                type="text"
                name="subject"
                value={subject}
                onChange={(e) => this.setState({ subject: e.target.value })}
              />
            </div>



                {/* <p className={commonStyles.common_form_label}>SUBJECT</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="subject"
                  value={subject}
                  onChange={(e) => this.setState({ subject: e.target.value })}
                ></input> */}
              </div>
              <div className="col-md-6">

                {/* <p className={commonStyles.common_form_label}>PRIORITY</p> */}
                <div className={styles.label}>PRIORITY</div>
                {/* <Select
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={roleDropdown}
                /> */}

<CommonDropdown
                backgroundColor="#fafafa"
                value0="Priority"
                fontSize="18px"
                width="100%"
                height="51px"
                borderRadius="4px"
                iconSize="24px"
                padding="13px 24px"
                textColor="#000"
                name="selectedOption"
                dropdownList={roleDropdown}
                selectedDropdown={this.selectedDropdown}
              />

              </div>
            </div>
            <div className={`row ${styles.row_gap}`}>
              {/* <p className={commonStyles.common_form_label} style={{marginLeft: 15}}>DESCRIPTION</p> */}
              <div style={{marginLeft: 15}} className={styles.label}>DESCRIPTION</div>
              <textarea
                className={`form-control ${styles.textarea}`}
                type="text"
                name="description_text"
                value={description_text}
                rows="3"
                disabled
              ></textarea>
            </div>
            <div className={styles.buttons_wrapper}>
              <button
                type="button"
                className={styles.create_project}
                style={{color:'#11256d', backgroundColor: '#ffffff'}}
                // className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                onClick={this.props.closeHandler}
              >
                CANCEL
              </button>
              <button
                type="button"
                className={styles.create_project}
                // className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                onClick={this.handleEditMember}
              >
                SAVE
              </button>
            </div>
          </form>
        </CommonModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.homepage.userDetails,
  };
};

const mapDispatchToProps = {
  listTicketsRequest: supportActions.fetchAllTicketsRequest,
  updateTicket: supportActions.updateTicketRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateTicket);
