import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { withRouter } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { BsCircleFill } from 'react-icons/bs'
import { FiShare2 } from 'react-icons/fi'
import moment from 'moment'

import selected_three_dot_icon from '../../../assets/selected_three_dot_icon.svg'
import tag_icon from '../../../assets/tag_icon.svg'
import delete_icon from '../../../assets/delete_icon.svg'
import share from '../../../assets/share.svg'
import green_file from '../../../assets/green_file.svg'
import GreyFileSVG from '../../../assets/file_grey.svg'
import cloud_download from '../../../assets/cloud_download.svg'
import three_dot_icon from '../../../assets/three_dot_icon.svg'

import Share from './../../../components/data-catalog/modals/share/Share'
import { formatDate } from '../../../shared/formatDate'
import { formatFilename } from '../../../shared/helper'
import { documents } from '../../../json-data/recentDocuments.json'
import styles from './RecentDocuments.module.css'

class RecentDocuments extends Component {
  state = {
    selectedAction: null,
    shareAction: null,
  }
  openActionPopup = index => {
    this.setState({ selectedAction: index })
  }
  closeActionPopup = () => {
    if (this.state.selectedAction) this.setState({ selectedAction: null })
  }
  openSharePopup = index => {
    console.info('openSharePopup')
    this.setState({
      shareAction: index,
    })
  }
  closeSharePopup = () => {
    console.info('closeSharePopup')
    this.setState({
      shareAction: null,
    })
  }

  documentHandler = fileIndex => {
    const { data } = this.props
    if (data) {
      const fileId = data[fileIndex].fileId
      const filePath = data[fileIndex].filePath
      this.props.history.push({
        pathname: '/document-analytics',
        search: '?source=dashboard'
      }, {
        fileId,
        filePath,
      })
    }
  }

  render() {
    const { selectedAction, shareAction } = this.state
    const { data } = this.props

    // console.info('data: ', data)

    return (
      <React.Fragment>
        <div className={styles.recent_doc_container}>
          {data &&
            data.map(
              (
                {
                  fileId,
                  fileName,
                  fileType,
                  court = 'Others',
                  year = '2005',
                  jurisdiction = 'Delhi',
                  modified,
                },
                index
              ) => {
                if (index > 4) return
                const { date, time } = formatDate(modified)
                return (
                  <div className={styles.recent_document_card_wrapper}>
                    <div className={styles.recent_doc_card}>
                      <div className={styles.recent_doc_card_upper_content}>
                        <img
                          className={styles.recent_doc_grey_document}
                          src={GreyFileSVG}
                          alt="document"
                        />
                        <div
                          className={`${styles.recent_doc_card_title}`}
                          onClick={() => this.documentHandler(index)}
                        >
                          {formatFilename(fileName, 20, 5, 30)}
                          {/* {formatFilename(fileName, 20, 5, 30) !==
                            fileName && (
                            <span className={styles.tooltiptext}>
                              {fileName}
                            </span>
                          )} */}
                        </div>
                      </div>

                      <div className={styles.recent_doc_card_bottom_content}>
                        <div className={styles.recent_doc_card_bottom_text}>
                          {date}
                          <span className={styles.dot}>.</span>
                          {time}
                        </div>

                        <div className={styles.action_dots}>
                          <img
                            className={styles.recent_doc_three_dot_icon}
                            onClick={
                              this.state.selectedAction === index + 1
                                ? () => this.closeActionPopup()
                                : () => this.openActionPopup(index + 1)
                            }
                            src={
                              this.state.selectedAction === index + 1
                                ? selected_three_dot_icon
                                : three_dot_icon
                            }
                            alt="three_dot_icon"
                          />

                          {this.state.selectedAction === index + 1 && (
                            <OutsideClickHandler
                              onOutsideClick={() => this.closeActionPopup()}
                            >
                              <div className={styles.action_popup}>
                                <div className={styles.action_popup_tag}>
                                  <img src={tag_icon} alt="tag_icon"></img>{' '}
                                  <span className={styles.action_popup_text}>
                                    Tag
                                  </span>
                                </div>

                                <OutsideClickHandler
                                  onOutsideClick={() => this.closeSharePopup()}
                                >
                                  <div
                                    className={styles.action_popup_share}
                                    style={
                                      shareAction === index
                                        ? {
                                            color: '#fff',
                                            backgroundColor: '#11256D',
                                          }
                                        : {}
                                    }
                                  >
                                    <div
                                      className={styles.share_content_wrapper}
                                      onClick={
                                        shareAction === index
                                          ? () => this.closeSharePopup()
                                          : () => this.openSharePopup(index)
                                      }
                                    >
                                      <span
                                        className={styles.action_share_icon}
                                      >
                                        <IconContext.Provider
                                          value={
                                            shareAction === index
                                              ? { color: '#ffffff', size: 21 }
                                              : { color: '#9A9A9A', size: 21 }
                                          }
                                        >
                                          <FiShare2 />
                                        </IconContext.Provider>
                                      </span>

                                      <span
                                        className={styles.action_popup_text}
                                      >
                                        Share
                                      </span>
                                    </div>

                                    <div
                                      style={
                                        shareAction === index
                                          ? { display: 'block' }
                                          : { display: 'none' }
                                      }
                                    >
                                      <Share
                                        fileId={fileId}
                                        index={index}
                                        top={'41px'}
                                        left={'185px'}
                                        right={'185px'}
                                      />
                                    </div>
                                  </div>
                                </OutsideClickHandler>

                                <div
                                  className={styles.action_popup_row_content}
                                >
                                  <img
                                    className={
                                      styles.action_cloud_download_icon
                                    }
                                    src={cloud_download}
                                    alt="cloud_download"
                                  ></img>
                                  <span className={styles.action_popup_text}>
                                    Download
                                  </span>
                                </div>

                                <div
                                  className={styles.action_popup_row_content}
                                >
                                  <img
                                    src={delete_icon}
                                    alt="delete_icon"
                                  ></img>
                                  <span className={styles.action_popup_text}>
                                    Delete
                                  </span>
                                </div>
                              </div>
                            </OutsideClickHandler>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.chips_container}>
                      {court && <span>{court}</span>}
                      {jurisdiction && <span>{jurisdiction}</span>}
                      {year && <span>{year}</span>}
                    </div>
                  </div>
                )
              }
            )}
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(RecentDocuments)
