import React, { Component } from "react";
import styles from "./RecentDoc.module.css";
import { connect } from 'react-redux';
import * as uploadedDoc from '../../../store/actions/uploadedDocActions';
import * as dashboard from '../../../store/actions/dashboardActions';
import { createParamsString } from '../../../shared/helper';
import Highlighter from "react-highlight-words";
import {handleError} from '../../../shared/HandleMessages'
import Loader from '../../../shared/loader/Loader';
class RecentDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClusteredDataRequested: false,
            uploadingInProcess: false,
            showLoader:false,
            isDocClicked:false,
            // fileId:'',
        }
    }
    getFilteredDocuments = () =>{
        let searchItems = this.props.searchItem
        let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let filteredDocuments = this.props.recentDocuments.filter(tag => {
            return tag.fileName.toLowerCase().search(
                subStr.toLowerCase()
            ) !== -1;
        });
        return filteredDocuments
    }
    handleDocClick = (docInfo) => {
        this.setState({isDocClicked:true})
        if(docInfo.isClustered){
        const paramsObj={
            fileId: docInfo.fileId,
            version:docInfo.version,
            fileVersionId:docInfo.fileVersionId,
            minor:docInfo.minor.toString(),
            docType:docInfo.docType.toString()
        }
        const similarParaObj = {
            fileId: docInfo.fileId
        }
        this.props.clusterDataRequest(paramsObj, {fileId:docInfo.fileId}, {fileId:docInfo.fileId})
        //     fileId: docInfo.fileId
        // this.setState({fileId:docInfo.fileId})
        // this.props.similarParaRequest(similarParaObj)
        // this.props.similarDocsRequest(similarParaObj)
        this.setState({ isClusteredDataRequested: true })
    }
    else{
        const paramsObj={
            fileId: docInfo.fileId,
            version: docInfo.version
        }
        this.props.fileReadRequest(paramsObj)
        // this.setState({ uploadingInProcess: true })
    }
    }
    // componentDidUpdate(prevProps, prevState){
    //     if (this.props.uploadedDoc.clusterData && this.state.isClusteredDataRequested && !this.props.isUploadedDocLoading) {
    //         this.props.history.push('/clustered-result', {from:'dashboard'})

    //     }
    //     if (this.props.dashboard?.fileRead?.data && prevProps.isFileReadLoading && !this.props.isFileReadLoading ) {
    //         const url = '/uploaded-doc' + createParamsString({origin: 'fileRead'});
    //         this.props.history.push(url, {from:'/dashboard'})
    //     }
    // }
    componentDidUpdate(prevProps, prevState){
        if (this.props.uploadedDoc.clusterData && prevProps.isUploadedDocLoading && !this.props.isUploadedDocLoading) {
            this.props.history.push('/clustered-result', {from:'dashboard'})

        }
       else if (this.props.dashboard?.fileRead?.data && prevProps.isFileReadLoading && !this.props.isFileReadLoading ) {
            const url = '/uploaded-doc' + createParamsString({origin: 'fileRead'});
            this.props.history.push(url, {from:'/dashboard'})
        }

        else if(prevProps.isUploadedDocLoading && !this.props.isUploadedDocLoading  && this.props.UploadedDocError){
            handleError(this.props.UploadedDocError)
        }
        else if(prevProps.isDashboardLoading && !this.props.isDashboardLoading  && this.props.dashboardError){
            handleError(this.props.dashboardError)
        }

        else if((this.props.isUploadedDocLoading && !prevProps.isUploadedDocLoading) || (this.props.isFileReadLoading && !prevProps.isFileReadLoading)){
            this.setState({showLoader:true})
        }
    }
    render() {    
        const {showLoader, isDocClicked} = this.state
        var data = []
        let searchWords = []
        data.push(this.props.searchItem)
        if (this.props.searchItem.trim()) {
            searchWords = data
        }    
        let filteredDocuments = this.getFilteredDocuments()
        return (
            <div className='thin_custom_scrollbar'>
              {showLoader && isDocClicked && <Loader></Loader>}
                <div style={{ height: '100%' }} className={styles.recent_doc_list_container}>
                    <table className={styles.recent_doc_table}>
                        <thead>
                            <tr className={styles.recent_doc_row_label}>
                                <th> <label>DOCUMENT NAME</label> </th>
                                <th ><label>STATUS</label></th>
                                <th><label>DUE DATE</label></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDocuments.map((data, index) => {
                                let Docname = data.fileName
                                let status = data.status
                                let dueDate = data.dueDate
                                return (
                                    <tr key={index}>
                                        <td className={styles.doc_name_row} onClick={() => this.handleDocClick(data)}>
                                            
                                            <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={searchWords}
                                                    autoEscape={true}
                                                    textToHighlight={Docname}
                                                    className="HighlightClass"
                                                />
                                        </td>
                                        <td>
                                            <span className={styles.doc_status}>{status}
                                            </span>
                                        </td>
                                        <td>
                                            <span>{dueDate} </span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        uploadedDoc: state.uploadedDoc,
        isUploadedDocLoading: state.uploadedDoc.isLoading,
        UploadedDocError: state.uploadedDoc.error,
        dashboard:state.dashboard,
        isDashboardLoading: state.dashboard.isLoading,
        isFileReadLoading: state.dashboard.isFileReadLoading,
        dashboardError: state.dashboard.error
    };
}
const mapDispatchToProps = {
    clusterDataRequest: uploadedDoc.clusterDataRequest,
    similarParaRequest: uploadedDoc.similarParaRequest,
    similarDocsRequest: uploadedDoc.similarDocsRequest,
    fileReadRequest: dashboard.fileReadRequest,

}
export default connect(mapStateToProps, mapDispatchToProps)(RecentDoc);
// export default RecentDoc;