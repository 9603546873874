import * as types from '../types';

const initialState = {
  catalogAllSearchResultData: null,
  isCatalogAllSearchResultDataLoading:false,
  error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // Catalog Search Result Data Actions
    case types.CATALOG_ALL_SEARCH_RESULT_DATA_REQUEST:
      return {
        ...state,
        catalogAllSearchResultData: null,
        isCatalogAllSearchResultDataLoading: true,
      };
    case types.CATALOG_ALL_SEARCH_RESULT_DATA_SUCCESS: {
      return {
        ...state,
        isCatalogAllSearchResultDataLoading: false,
        catalogAllSearchResultData: action.payload.result.data,
      };
    }
    case types.CATALOG_ALL_SEARCH_RESULT_DATA_FAILURE:
      return {
        ...state,
        isCatalogAllSearchResultDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };


    default: {
      return state;
    }
  }
};
