import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as accountSettings from '../actions/accountSettingsActions';

function* updateProfileWorker(params) {
    try {
        const data = yield call(_api, URLS.requestUpdateProfile, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(accountSettings.updateProfileSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(accountSettings.updateProfileFailure(e));
    }
}
function* changePasswordWorker(params) {
    try {
        const data = yield call(_api, URLS.requestChangePassword, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(accountSettings.changePasswordSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(accountSettings.changePasswordFailure(e));
    }
}
function* updateProfileWatcher() {
    yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfileWorker);
}
function* changePasswordWatcher() {
    yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePasswordWorker);
}

export default function* accountSettingsSaga() {
    yield all([
        updateProfileWatcher(),
        changePasswordWatcher(),
    ]);
}