import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { IconContext } from 'react-icons'
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io'
import OutsideClickHandler from 'react-outside-click-handler'
import BeatLoader from 'react-spinners/BeatLoader'
import { css } from '@emotion/core'
import { Document, Page } from 'react-pdf'

import * as dashboard_V2_actions from './../../../store/actions/dashboard-v2Action'

import SearchBar from './../../../components/data-catalog/search-bar/SearchBar'
import CommonDropdown from './../../../shared/common-dropdown/CommonDropdown'
import CommonModal from '../../../shared/common-modal/CommonModal'

import ClipSVG from './../../../assets/clip_green.svg'
import { emails } from './../../../json-data/emailModal.json'
import styles from './UploadDocumentModal.module.css'
import { style } from 'd3'

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  margin: 0 auto;
  border-color: red;
  height: 300px;
  z-index: 5;
`

class UploadDocumentModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageNumber: 1,
      numPages: null,
      fileNameUpdated: this.props.data?.result?.fileName,
      // TODO: change it back to 1
      documentType: 3,
      filePath: this.props.data?.result?.filePath,
      fileId: this.props.data?.result?.fileId,
    }
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.data === null && this.props.data) {
    //   this.setState({
    //     filePath: this.props.data?.result?.filePath,
    //     fileId: this.props.data?.result?.fileId,
    //     fileNameUpdated: this.props.data?.result?.fileName,
    //   })
    // }

    if (prevProps.fileClusterData === null && this.props.fileClusterData) {
      const { fileId, filePath } = this.state
      this.props.clusteredDataRequest({
        fileId,
        filePath,
      })
    }

    if (prevProps.clusteredData === null && this.props.clusteredData) {
      const { fileId, filePath } = this.state
      this.props.history.push(
        {
          pathname: '/document-analytics',
          search: '?source=dashboard',
        },
        { fileId, filePath }
      )
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages,
      pageNumber: 1,
    })
  }

  changePage = offset => {
    const { pageNumber } = this.state
    this.setState({
      pageNumber: pageNumber + offset,
    })
  }

  previousPage = () => {
    const { pageNumber } = this.state
    if (!(pageNumber <= 1)) this.changePage(-1)
  }

  nextPage = () => {
    const { pageNumber, numPages } = this.state
    if (!(pageNumber >= numPages)) this.changePage(1)
  }
  handleDropdownChange = (name, value) => {
    this.setState({
      documentType: value,
    })
  }

  filleNameChangeHandler = e => {
    this.setState({
      fileNameUpdated: e.target.value,
    })
  }

  clusterHandler = () => {
    const { fileNameUpdated, documentType, filePath, fileId } = this.state
    const params = {
      fileId,
      filePath,
      fileName: fileNameUpdated,
      docType: documentType.toString(),
    }
    this.props.fileClusterRequest(params)
  }

  render() {
    const { pageNumber, numPages, fileNameUpdated } = this.state
    const {
      uploadDocumentModalHandler,
      data,
      isClusteredLoading,
      isFileClustering,
      clusteredData,
    } = this.props
    let fileName = '',
      filePath = null,
      documentType = null

    if (data) {
      fileName = data?.result?.fileName
      filePath = data?.result?.filePath
      documentType = data?.documentType
    }

    // console.info('state: ', this.state)

    return (
      <CommonModal
        modalClass={'upload_document_common_modal'}
        closeHandler={uploadDocumentModalHandler}
        backdropClassName={'upload_document_common_modal_blue_bg'}
        header={
          <div className={`${styles.modal_header_container}`}>
            <h4 className={`${styles.modal_header_heading}`}>
              Upload Documents
            </h4>
          </div>
        }
      >
        <div className={styles.root}>
          {(isClusteredLoading || isFileClustering || data === null) && (
            <BeatLoader
              css={override}
              size={25}
              color={'#11256D'}
              loading={true}
            />
          )}
          <div className={styles.upper_part}>
            <div className={styles.left}>
              {data && (
                <Document
                  className={'upload-document-modal-pdf'}
                  file={filePath}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
              )}
            </div>

            <div className={styles.right}>
              <div className={styles.container}>
                <div className={styles.label}>DOCUMENT TITLE</div>
                <div className={styles.input_wrapper}>
                  <input
                    type="text"
                    placeholder={fileName}
                    value={fileNameUpdated}
                    onChange={this.filleNameChangeHandler}
                  />
                </div>
              </div>
              <div className={styles.container}>
                <div className={styles.label}>DOCUMENT TYPE</div>
                <CommonDropdown
                  backgroundColor="#fafafa"
                  value0="Assign Role"
                  fontSize="18px"
                  width="100%"
                  height="51px"
                  borderRadius="4px"
                  padding="10px 15px"
                  iconSize="24px"
                  textColor="#000"
                  name="taskName"
                  dropdownList={documentType}
                  // index={idx}
                  selectedDropdown={this.handleDropdownChange}
                />
              </div>
            </div>
          </div>

          <div className={styles.bottom_part}>
            <div className={styles.pdf_controls}>
              <div onClick={this.previousPage}>
                <IconContext.Provider
                  value={
                    pageNumber <= 1
                      ? {
                          size: 21,
                          color: '#eaeaea',
                        }
                      : {
                          size: 21,
                          color: '#11256d',
                        }
                  }
                >
                  <IoMdArrowDropleft />
                </IconContext.Provider>
              </div>
              <p>
                {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
              </p>
              <div onClick={this.nextPage}>
                <IconContext.Provider
                  value={
                    pageNumber >= numPages
                      ? {
                          size: 21,
                          color: '#eaeaea',
                        }
                      : {
                          size: 21,
                          color: '#11256d',
                        }
                  }
                >
                  <IoMdArrowDropright />
                </IconContext.Provider>
              </div>
            </div>
            <button
              type="button"
              className={styles.cluster_btn}
              onClick={this.clusterHandler}
              style={
                isClusteredLoading || isFileClustering
                  ? {
                      opacity: '.5',
                      cursor: 'none',
                    }
                  : {}
              }
              disabled={isClusteredLoading || isFileClustering ? true : false}
            >
              CLUSTER
            </button>
          </div>
        </div>
      </CommonModal>
    )
  }
}

const mapStateToProps = state => {
  // console.info('state dashboardV2Data: ', state.dashboardV2Page)
  return {
    isFileClustering: state.dashboardV2Page.isFileClustering,
    fileClusterData: state.dashboardV2Page.fileClusterData,
    isClusteredLoading: state.dashboardV2Page.isClusteredLoading,
    clusteredData: state.dashboardV2Page.clusteredData,
  }
}

const mapDispatchToProps = {
  fileClusterRequest: dashboard_V2_actions.fileClusterRequest,
  clusteredDataRequest: dashboard_V2_actions.clusteredDataRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UploadDocumentModal))
