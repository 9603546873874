import * as types from '../types'

// Login Actions
export const requestLogin = payload => ({ type: types.REQUEST_LOGIN, payload })
export const successLogin = payload => ({ type: types.LOGIN_SUCCESS, payload })
export const failureLogin = payload => ({ type: types.LOGIN_FAILURE, payload })

// Signup Actions
export const requestSignup = payload => ({
  type: types.REQUEST_SIGNUP,
  payload,
})
export const successSignup = payload => ({
  type: types.SIGNUP_SUCCESS,
  payload,
})
export const failureSignup = payload => ({
  type: types.SIGNUP_FAILURE,
  payload,
})

export const saveConnectionRequest = payload => ({
  type: types.WS_CONNECTION_REQUEST,
  payload,
})
export const saveConnectionSuccess = payload => ({
  type: types.WS_CONNECTION_SUCCESS,
  payload,
})
export const errorWhileSavingConnection = payload => ({
  type: types.WS_CONNECTION_FAILURE,
  payload,
})

export const saveNotificationRequest = payload => ({
  type: types.SAVE_NOTIFICATION_REQUEST,
  payload,
})
export const saveNotificationSuccess = payload => ({
  type: types.SAVE_NOTIFICATION_SUCCESS,
  payload,
})
export const saveNotificationFailure = payload => ({
  type: types.SAVE_NOTIFICATION_FAILURE,
  payload,
})

/* export const removeNotificationRequest = payload => ({
  type: types.REMOVE_NOTIFICATION_REQUEST,
  payload,
})
export const removeNotificationSuccess = payload => ({
  type: types.REMOVE_NOTIFICATION_SUCCESS,
  payload,
})
export const removeNotificationFaiure = payload => ({
  type: types.REMOVE_NOTIFICATION_FAILURE,
  payload,
}) */

export const saveUnreadNotification = payload => ({
  type: types.HOMEPAGE_UNREAD_NOTIFICATION_REQUEST,
  payload,
})

export const saveNewNotificationId = payload => ({
  type: types.HOMEPAGE_NEW_NOTIFICATION_ID_REQUEST,
  payload,
})

// Logout Actions
export const requestLogout = () => ({ type: types.REQUEST_LOGOUT })

// emailExist Actions
export const emailExistRequest = payload => ({
  type: types.EMAIL_EXIST_REQUEST,
  payload,
})
export const emailExistSuccess = payload => ({
  type: types.EMAIL_EXIST_SUCCESS,
  payload,
})
export const emailExistFailure = payload => ({
  type: types.EMAIL_EXIST_FAILURE,
  payload,
})

// forgotPassword Actions
export const forgotPasswordRequest = payload => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  payload,
})
export const forgotPasswordSuccess = payload => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload,
})
export const forgotPasswordFailure = payload => ({
  type: types.FORGOT_PASSWORD_FAILURE,
  payload,
})

// clear homepage error
export const clearHomepageError = () => ({ type: types.CLEAR_HOMEPAGE_ERROR })

// Timer tracking
export const incrementCounter = () => ({ type: types.INCREMENT_TIMER })
export const stopCounter = () => ({ type: types.STOP_TIMER })
export const resetCounter = () => ({ type: types.RESET_TIMER })
export const pathChange = payload => ({ type: types.UPDATE_PATHNAME, payload })
export const updateProjectId = payload => ({
  type: types.CURRENT_PROJECT_ID,
  payload,
})
export const updateUploadDocumentModalParent = payload => ({
  type: types.UPLOAD_DOCUMENT_MODAL_PARENT,
  payload,
})
