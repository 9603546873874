import React, {useState} from 'react';
import Tooltip from 'react-bootstrap/Tooltip'
import './InfoBar.css';

export const InfoBar = ({ users, handleSingleUser }) => {
    const [ activeLink, setActiveLink] = useState(false)
    const d = (user) => {
        console.log(user);
        setActiveLink(user.userId)
        handleSingleUser(user)
    }
    return (
        <div className="infoBar">
            <div className="leftInnerContainer">
                <span className="all_users_text">All</span>
                {users.length ? users.map((u) => (<img key={u.userId}  className={
                    'user' +
                    (u.userId === activeLink ? " activeLink" : "")
                } onClick={() => d(u)} src={u.profile}  title={u.name}></img>)) : null}
            </div>
            <div className="rightInnerContainer">
            </div>
        </div>
    )
};
