import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { connect } from 'react-redux'

import * as workflowManagementActions from './../../../store/actions/workFlowManagementLandingPageActions'
// import Filter from '../../data-catalog/modals/filter/Filter'
import Filter from './../../workflow-management/modal/filter/Filter'
import Sort from '../../data-catalog/modals/sort/Sort'
import RelatedSearches from '../../data-catalog/related-searches/RelatedSearches'
import ShareIcon from './../../data-catalog/share-icon/ShareIcon'
import Dropdown from '../../data-catalog/dropdown/Dropdown'
import SearchIcon from './../../../assets/search_green.svg'
import PulseSVG from './../../../assets/pulse.svg'
import SourceSVG from './../../../assets/source.svg'
import StepBarSVG from './../../../assets/step_bar_green.svg'
import AllProjectsSVG from './../../../assets/project_green.svg'
import FolderOutlineGreen from './../../../assets/folder_outline_green.svg'
import FolderSolidBlue from './../../../assets/folder_solid_blue.svg'
import styles from './AllProjectsAndRecentFolders.module.css'
import { Steps, Divider } from 'antd'
import { formatDate } from '../../../shared/formatDate'
import moment from 'moment'
import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'
import { IconContext } from 'react-icons'
import filters from '../../../json-data/wf-filter.json'

const { Step } = Steps
const CHUNK_SIZE = 5
class AllProjectsAndRecentFolders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showFilterModal: false,
      showSortModal: false,
      filteredProjectsData: this.props.filteredDocuments
        ? this.props.filteredDocuments
        : this.props.allProjectsData,
      isAlphabetAscending: 1,
      isDateAscending: 1,
      showInChunks: CHUNK_SIZE,
      initialData: this.props.allProjectsData,
    }

    this.filterRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchTerms !== this.props.searchTerms) {
      this.getFilteredProjects()
    }
    // to handle case when all project api is called but not reflecting in list after project creation
    if (this.state.initialData.length !== this.props.allProjectsData.length) {
      this.setState({
        filteredProjectsData: this.props.allProjectsData,
        initialData: this.props.allProjectsData,
      })
    }
  }

  getFilteredProjects() {
    let subStr = this.props.searchTerms.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let filteredProjects = this.props.allProjectsData.filter(tag => {
      return tag.name.toLowerCase().search(subStr.toLowerCase()) !== -1
    })
    this.setState({ filteredProjectsData: filteredProjects })
  }

  toggleFilter = () => {
    const { showFilterModal, showSortModal } = this.state
    if (showSortModal) {
      this.setState({ showSortModal: false })
    }
    this.setState({
      showFilterModal: !showFilterModal,
    })
  }
  toggleSort = () => {
    const { showSortModal, showFilterModal } = this.state
    if (showFilterModal) {
      this.setState({ showFilterModal: false })
    }
    this.setState({
      showSortModal: !showSortModal,
    })
  }

  closeFilterAndSort = () => {
    const { showFilterModal, showSortModal } = this.state
    if (showFilterModal || showSortModal)
      this.setState({
        showFilterModal: false,
        showSortModal: false,
      })
  }

  goToProject = id => {
    this.props.history.push(
      {
        pathname: '/all-projects',
        search: '?source=workflow-management',
      },
      { id: id }
    )
  }

  sortByAlbhabet = () => {
    this.sortProjectByAlphabet()
  }

  sortProjectByAlphabet = () => {
    const filteredProjectsData = this.state.filteredProjectsData
    filteredProjectsData.sort((a, b) =>
      a['name'].toLowerCase() > b['name'].toLowerCase()
        ? this.state.isAlphabetAscending
        : this.state.isAlphabetAscending * -1
    )
    this.setState({
      filteredProjectsData,
      isAlphabetAscending: this.state.isAlphabetAscending * -1,
    })
  }
  sortByDate = () => {
    this.sortProjectByDate()
  }

  sortProjectByDate = () => {
    const { isDateAscending, filteredProjectsData } = this.state
    if (isDateAscending === 1) {
      filteredProjectsData.sort(function (a, b) {
        return new Date(b['createdAt']) - new Date(a['createdAt'])
      })
      this.setState({
        filteredProjectsData,
        isDateAscending: -1,
      })
    } else if (isDateAscending === -1) {
      filteredProjectsData.sort(function (a, b) {
        return new Date(a['createdAt']) - new Date(b['createdAt'])
      })
      this.setState({
        filteredProjectsData,
        isDateAscending: 1,
      })
    }
  }

  getCurrentValue = tasks => {
    // console.log(tasks)
    const date = new Date()
    let count = 0
    tasks.forEach(task => {
      if (new Date(task.endDate) < date) {
        count++
      }
    })
    return count
  }

  handleLoadMore = () => {
    if (this.state.filteredProjectsData.length >= this.state.showInChunks) {
      let showInChunks = this.state.showInChunks + CHUNK_SIZE
      this.setState({ showInChunks })
    }
  }

  applyFilters = filteredData => {
    // console.info('filteredData: ', filteredData)

    this.props.saveFilteredDocuments(filteredData)
    const allFilters = this.filterRef.current.getCheckboxListState()
    this.props.saveFilters(allFilters)

    let areFiltersEmpty = true

    for (const key in allFilters) {
      const value = allFilters[key]
      if (value.length) {
        areFiltersEmpty = false
        break
      }
    }

    if (filteredData.length === 0 && areFiltersEmpty) {
      this.setState(
        {
          filteredProjectsData: this.props.allProjectsData,
          showFilterModal: false,
        },
        () => {
          this.closeFilterAndSort()
        }
      )
    } else if (filteredData) {
      this.setState(
        {
          filteredProjectsData: filteredData,
          showFilterModal: false,
        },
        () => {
          this.closeFilterAndSort()
        }
      )
    }
  }
  closeFilterAndSort = () => {
    const { showFilterModal, showSortModal } = this.state
    if (showFilterModal || showSortModal)
      this.setState({
        showFilterModal: false,
        showSortModal: false,
      })
  }
  clearFilters = () => {
    this.setState(
      {
        filteredProjectsData: this.props.allProjectsData,
        showFilterModal: false,
      },
      () => {
        this.closeFilterAndSort()
        this.props.saveFilteredDocuments(null)
        this.props.saveFilters(null)
      }
    )
  }

  render() {
    const { searchResults, folders } = this.props
    const {
      showSortModal,
      showFilterModal,
      filteredProjectsData,
      showInChunks,
    } = this.state
    return (
      <div className={styles.wrapper}>
        <div className={styles.root}>
          {/* Left Part */}
          <div className={styles.left}>
            <div className={styles.heading}>
              {/* Heading */}
              <div className={styles.heading__root}>
                <div className={styles.heading__heading}>All Projects</div>
                <div className={styles.heading__icon_div}>
                  <img
                    className={styles.heading__icon}
                    src={AllProjectsSVG}
                    alt={'All Projects'}
                  />
                </div>
              </div>

              <OutsideClickHandler
                onOutsideClick={() => this.closeFilterAndSort()}
              >
                <div className={styles.dropdown_wrapper}>
                  <div className={styles.filter_wrapper}>
                    <div
                      className={styles.dropdown}
                      onClick={this.toggleFilter}
                    >
                      <Dropdown
                        backgroundColor={'#fafafa'}
                        value0={'Filter'}
                        height={'30px'}
                        fontSize={'18px'}
                        toggled={showFilterModal}
                      />
                    </div>
                    <div
                      style={
                        showFilterModal
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <Filter
                        filters={filters.filters}
                        documentsData={this.props.allProjectsData}
                        applyFilters={filteredData =>
                          this.applyFilters(filteredData)
                        }
                        clearFilters={this.clearFilters}
                        allFilters={this.props.allFilters}
                        ref={this.filterRef}
                      />
                    </div>
                  </div>
                  <div className={styles.sort_wrapper}>
                    <div className={styles.dropdown} onClick={this.toggleSort}>
                      <Dropdown
                        backgroundColor={'#fafafa'}
                        value0={'Sort'}
                        height={'30px'}
                        fontSize={'18px'}
                        toggled={showSortModal}
                      />
                    </div>
                    <div
                      style={
                        showSortModal
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <Sort
                        sortByAlbhabet={this.sortByAlbhabet}
                        sortByDate={this.sortByDate}
                      />
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
            {/* Displaying All Projects */}
            {/* <RelatedSearches documentsData={searchResults} /> */}

            <div className={styles.related_searches__root}>
              {filteredProjectsData.length === 0 ||
              filteredProjectsData === null ? (
                <div className={styles.related_searches__wrapper}>
                  No Projects Found!!
                </div>
              ) : (
                <div>
                  {filteredProjectsData.map(
                    (
                      {
                        type,
                        name,
                        description,
                        createdAt,
                        id,
                        results,
                        sources,
                        confidence,
                        tasks,
                        team,
                        endDate,
                      },
                      index
                    ) => {
                      const dateAndTime = formatDate(createdAt)
                      let newDate = new Date(endDate)
                      let dueDate = moment(newDate)
                        .fromNow()
                        .replace('in', '')
                        .toUpperCase()
                      if (showInChunks > index) {
                        return (
                          <div className={styles.related_searches__wrapper}>
                            <div className={styles.related_searches__container}>
                              <div
                                className={
                                  styles.related_searches__left_container
                                }
                              >
                                <div className={styles.body__root}>
                                  <div
                                    onClick={() => {
                                      this.goToProject(id)
                                    }}
                                    className={styles.body__heading}
                                  >
                                    {type}
                                  </div>
                                  <div className={styles.body__subheading}>
                                    {name}
                                  </div>
                                  <div className={styles.body__description}>
                                    {description}
                                  </div>
                                  <div className={styles.body__date}>
                                    {dateAndTime.date}.{dateAndTime.time}
                                  </div>
                                </div>
                              </div>

                              <div
                                className={
                                  styles.related_searches__right_container
                                }
                              >
                                <div
                                  className={
                                    styles.related_searches__share_link
                                  }
                                >
                                  <ShareIcon
                                    provideCtrl={ctrl =>
                                      (this.controlShareIcon = ctrl)
                                    }
                                  />
                                </div>

                                <div className={styles.graphic_container__root}>
                                  <div
                                    className={
                                      styles.graphic_container__confidence
                                    }
                                  >
                                    <div
                                      className={
                                        styles.confidence_value_container
                                      }
                                    >
                                      Status
                                      <div className={styles.confidence_value}>
                                        DUE IN {dueDate}
                                      </div>
                                    </div>

                                    <div
                                      className={`${styles.confidence_container} all_project_home_steps_small`}
                                    >
                                      {/*  <div
                                  className={styles.confidence}
                                  style={{ width: '80%' }}
                                ></div> */}
                                      {/* <img src={StepBarSVG} alt={'Step Bar Green'} /> */}
                                      <Steps
                                        progressDot
                                        current={this.getCurrentValue(tasks)}
                                        size="small"
                                      >
                                        <Step title="" description="" />
                                        <Step title="" description="" />
                                        <Step title="" description="" />
                                        <Step title="" description="" />
                                        <Step title="" description="" />
                                        <Step title="" description="" />
                                      </Steps>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      styles.graphic_container__results_and_sources
                                    }
                                  ></div>
                                  <div>
                                    {team.map(team => {
                                      return (
                                        <div className={styles.avatars}>
                                          <span className={styles.avatar}>
                                            <img src="https://www.w3schools.com/howto/img_avatar.png" />
                                          </span>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    }
                  )}
                </div>
              )}
            </div>
            {!(showInChunks >= filteredProjectsData.length) && (
              <div className={styles.page_end_text}>
                <span
                  onClick={() => {
                    this.handleLoadMore()
                  }}
                >
                  Load more
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: state.workFlowManagementLandingPage.filters,
    filteredDocuments: state.workFlowManagementLandingPage.filteredDocuments,
  }
}

const mapDispatchToProps = {
  saveFilters: workflowManagementActions.workflowManagementSaveFilters,
  saveFilteredDocuments:
    workflowManagementActions.workflowManagementSaveFilteredDocuments,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllProjectsAndRecentFolders)
