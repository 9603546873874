import * as types from '../types';

// ClusterData Actions

export const clusterDataRequest = (payload, payload2, payload3) => ({ type: types.CLUSTER_DATA_REQUEST, payload, payload2, payload3 });
export const clusterDataSuccess = (payload) => ({ type: types.CLUSTER_DATA_SUCCESS, payload });
export const clusterDataFailure = (payload) => ({ type: types.CLUSTER_DATA_FAILURE, payload });
export const setUploadedData = (payload) => ({ type: types.SET_UPLOADED_DATA, payload });

// HISTORICAL DATA
export const similarParaRequest = (payload) => ({ type: types.SIMILAR_PARA_REQUEST, payload })
export const similarParaSuccess = (payload)=> ({ type: types.SIMILAR_PARA_SUCCESS, payload }) 
export const similarParaFailure = (payload) => ({ type: types.SIMILAR_PARA_FAILURE, payload })
export const similarParaId = (payload) => ({type: types.SIMILAR_PARA_ID, payload})
export const similarParaInit = (payload) => ({type: types.SIMILAR_PARA_INIT})

// SIMILAR DOCS
export const similarDocsRequest = (payload) => ({type: types.SIMILAR_DOCS_REQUEST, payload})
export const similarDocsSuccess = (payload) => ({type: types.SIMILAR_DOCS_SUCCESS, payload})
export const similarDocsFailure = (payload) => ({type: types.SIMILAR_PARA_FAILURE, payload})

// Respone
export const prepareResponseRequest = (payload) => ({type: types.PREPARE_RESPONSE_REQUEST, payload})
export const prepareResponseSuccess = (payload) => ({type: types.PREPARE_RESPONSE_SUCCESS, payload})
export const prepareResponseFailure = (payload) => ({type: types.PREPARE_RESPONSE_FAILURE, payload})
export const clearResponse = () => ({type: types.CLEAR_RESPONSE})

export const revertActiveState = (payload) => ({type: types.REVERT_ACTIVE_STATE, payload})
export const clearSimilarPara = () => ({type: types.CLEAR_SIMILAR_PARA})
export const submitResponseRequest = (payload) => ({type: types.SUBMIT_PREPARE_RESPONSE_REQUEST, payload})
export const submitResponseSuccess = (payload) => ({type: types.SUBMIT_PREPARE_RESPONSE_SUCCESS, payload})
export const submitResponseFailure = (payload) => ({type: types.SUBMIT_PREPARE_RESPONSE_FAILURE, payload})