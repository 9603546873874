import React, { Component } from 'react'
import styles from './AdvanceSearch.module.css'
import commonStyles from '../../../shared/Common.module.css'
import CommonModal from '../../../shared/common-modal/CommonModal'
import Select from 'react-select'
import { getFullDate } from '../../../shared/helper'
import { connect } from 'react-redux'
import ErrorText from '../../../shared/error-text/ErrorText'
import { handleSuccess, handleError } from '../../../shared/HandleMessages'

class AdvanceSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchTerm: '',
      secectedDocumentType: '',
      selectedTags: '',
      clientType: '',
      searchFrom: 'uploadedDocs',
      startDate: '',
      endDate: '',
      move: false,
      errors: {
        searchTerm: '',
        secectedDocumentType: '',
        selectedTags: '',
        clientType: '',
        startDate: '',
        endDate: '',
      },
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.isAdvanceSearchFetching &&
      prevProps.isAdvanceSearchFetching &&
      this.props.advanceSearchdata?.data
    ) {
      handleSuccess(this.props.advanceSearchdata.message)
      const url = '/search-results'
      this.props.history.push(url)
      this.setState({ move: false })
    }
    if (
      !this.props.isAdvanceSearchFetching &&
      prevProps.isAdvanceSearchFetching &&
      this.props.error
    ) {
      handleError(this.props.error)
      this.setState({ move: false })
      return
    }
    if (
      this.props.isAdvanceSearchFetching &&
      !prevProps.isAdvanceSearchFetching
    ) {
      this.setState({ move: true })
    }
  }
  handleDocumentTypeChange = secectedDocumentType => {
    this.setState({ secectedDocumentType })
    let errors = this.state.errors
    errors.secectedDocumentType = ''
    this.setState({ errors })
  }
  handleTagsChange = selectedTags => {
    this.setState({ selectedTags })
    let errors = this.state.errors
    errors.selectedTags = ''
    this.setState({ errors })
  }
  handleUserInput = e => {
    const name = e.target.name
    const value = e.target.value
    let errors = this.state.errors
    if (value && value !== '') {
      errors[name] = ''
    }
    this.setState({ errors })
    this.setState({ [name]: value })
  }
  getErrors = () => {
    const { searchTerm } = this.state
    let errors = this.state.errors
    if (searchTerm === '') {
      errors.searchTerm = 'search term is Required!'
    }
    // if (!secectedDocumentType) {
    //     errors.secectedDocumentType = "Document type is Required!"
    // }
    // if (!selectedTags) {
    //     errors.selectedTags = "Tags is Required!"
    // }

    // if (startDate === '') {
    //     errors.startDate = "Start date is Required!"
    // }
    // if (endDate === '') {
    //     errors.endDate = "End date is Required!"
    // }
    // if (clientType === '') {
    //     errors.clientType = "Client type is Required!"
    // }
    let errorCount = 0
    for (const property in this.state.errors) {
      if (this.state.errors[property].length > 1) {
        errorCount++
      }
    }

    this.setState({ errors: errors })
    return errorCount > 0
  }

  handlerSearch = e => {
    const isFormInvalid = this.getErrors()
    if (!isFormInvalid) {
      const {
        searchTerm,
        secectedDocumentType,
        startDate,
        endDate,
        clientType,
        searchFrom,
        selectedTags,
      } = this.state
      let tags = []
      if (selectedTags && selectedTags.length > 0) {
        tags = this.state.selectedTags.map(tag => tag.value)
      }
      const searchParam = {
        search: searchTerm,
        documentType: secectedDocumentType.value,
        startDate: startDate,
        endDate: endDate,
        clientType: clientType,
        searchFrom: searchFrom,
        tags: tags.toString(),
      }
      // this.setState({move:true})
      this.props.handlerSearch(searchParam)
    }
  }
  getDocumentTypeOptions = () => {
    const documentTypeDropdown = this.props.dropdowns.filter(dropdown => {
      if (dropdown.type === 'DOCUMENT_TYPE') return dropdown.values
    })
    const documentTypeOptions = documentTypeDropdown[0].values.map(item => {
      return {
        value: item.key,
        label: item.value,
      }
    })
    return documentTypeOptions
  }
  getTagOptions = () => {
    const tagsDropdown = this.props.dropdowns.filter(dropdown => {
      if (dropdown.type === 'ISSUES') return dropdown.values
    })

    const tagOptions = tagsDropdown[0].values.map(item => {
      return {
        value: item.key,
        label: item.value,
      }
    })
    return tagOptions
  }

  render() {
    let documentTypeOptions = []
    let tagOptions = []
    const {
      searchTerm,
      secectedDocumentType,
      startDate,
      endDate,
      selectedTags,
      clientType,
      errors,
    } = this.state
    const today = getFullDate()
    if (this.props.dropdowns && this.props.dropdowns.length) {
      documentTypeOptions = this.getDocumentTypeOptions()
      tagOptions = this.getTagOptions()
    }
    return (
      <div>
        <CommonModal
          modalClass={'advance_search_modal'}
          closeHandler={this.props.closeHandler}
          header={'Advanced Search'}
        >
          <form>
            <div className="row">
              <div className="col-md-6">
                <p className={commonStyles.common_form_label}>SEARCH TERM</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="searchTerm"
                  value={searchTerm}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                {errors.searchTerm.length > 0 && (
                  <ErrorText text={errors.searchTerm} />
                )}
              </div>
              <div className="col md-6">
                <p className={commonStyles.common_form_label}>DOCUMENT TYPE</p>
                <Select
                  value={secectedDocumentType}
                  onChange={this.handleDocumentTypeChange}
                  options={documentTypeOptions}
                  isMulti={false}
                />
                {/* {errors.secectedDocumentType.length > 0 && <ErrorText text={errors.secectedDocumentType} />} */}
              </div>
            </div>
            <div className={`row ${styles.row_gap}`}>
              <div className="col md-3">
                <p className={commonStyles.common_form_label}>TAGS</p>
                <Select
                  value={selectedTags}
                  onChange={this.handleTagsChange}
                  options={tagOptions}
                  isMulti={true}
                />
                {/* {errors.selectedTags.length > 0 && <ErrorText text={errors.selectedTags} />} */}
              </div>
              <div className="col md-3">
                <p className={commonStyles.common_form_label}>START DATE</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="date"
                  name="startDate"
                  value={startDate}
                  max={today}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                {/* {errors.startDate.length > 0 && <ErrorText text={errors.startDate} />} */}
              </div>
              <div className="col md-3">
                <p className={commonStyles.common_form_label}>END DATE</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="date"
                  name="endDate"
                  value={endDate}
                  min={startDate}
                  max={today}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                {/* {errors.endDate.length > 0 && <ErrorText text={errors.endDate} />} */}
              </div>
              <div className="col md-3">
                <p className={commonStyles.common_form_label}>CLIENT NAME</p>
                <input
                  className={`form-control ${commonStyles.common_form_text_field}`}
                  type="text"
                  name="clientType"
                  value={clientType}
                  onChange={event => this.handleUserInput(event)}
                ></input>
                {/* {errors.clientType.length > 0 && <ErrorText text={errors.clientType} />} */}
              </div>
            </div>

            <div className={styles.bottom_wrapper}>
              <p className={commonStyles.common_form_label}>SEARCH FROM</p>
              <div className={styles.bottom_content}>
                <div className={styles.search_from_radios}>
                  <span>
                    {' '}
                    <input
                      type="radio"
                      value="uploadedDocs"
                      defaultChecked
                      id="uploadedDocs"
                      onChange={event => this.handleUserInput(event)}
                      name="searchFrom"
                    />{' '}
                    <label htmlFor="uploadedDocs">Uploaded Docs</label>
                  </span>
                  <span>
                    {' '}
                    <input
                      type="radio"
                      value="completeDatabase"
                      id="completeDatabase"
                      onChange={event => this.handleUserInput(event)}
                      name="searchFrom"
                    />{' '}
                    <label htmlFor="completeDatabase">Complete Database</label>
                  </span>
                </div>
                <div>
                  <button
                    type="button"
                    className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                    onClick={this.props.closeHandler}
                  >
                    CANCEL
                  </button>
                  <button
                    // disabled
                    type="button"
                    className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                    onClick={this.handlerSearch}
                    disabled={this.state.move}
                  >
                    {this.state.move && <i class="fas fa-spinner fa-spin"></i>}
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </form>
        </CommonModal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    dropdowns: state.dashboard.userDashboard?.data?.dropdown,
    advanceSearchdata: state.dashboard.advanceSearch,
    isAdvanceSearchFetching: state.dashboard.isAdvanceSearchFetching,
    error: state.dashboard.error,
  }
}

export default connect(mapStateToProps, null)(AdvanceSearch)
