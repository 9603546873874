import { all } from 'redux-saga/effects'
import homepageSaga from '../sagas/homepageSaga'
import teamManagementSaga from '../sagas/teamManagementSaga'
import accountSettingsSaga from '../sagas/accountSettingsSaga'
import uploadDocSaga from '../sagas/uploadDocSaga'
import uploadedDocSaga from '../sagas/uploadedDocSaga'
import clusteredResultSaga from '../sagas/clusteredResultSaga'
import dashboardSaga from '../sagas/dashboardSaga'
import allDocumentsSaga from '../sagas/allDocumentsSaga'
import notificationSaga from '../sagas/notificationSaga'
import allGraphsSaga from '../sagas/GraphsSaga'
import supportSaga from '../sagas/supportSaga'
import dataSourceAuthSaga from '../sagas/dataSourceAuthSaga'
import dataSourceInfoSaga from '../sagas/dataSourceInfoSaga'
import catalogLandingPageSaga from '../sagas/catalogLandingPageSaga'
import catalogSearchResultsSaga from '../sagas/catalogSearchResultsSaga'
import catalogAllSearchResultSaga from '../sagas/catalogAllSearchResultSaga'
import catalogSelectedFolderSaga from '../sagas/catalogSelectedFolderSaga'
import filesStatusSaga from '../sagas/filesStatusSaga'
import graphDataSaga from '../sagas/graphDataSaga'
import allFoldersTreeSaga from '../sagas/allFoldersTreeSaga'
import workFlowManagementLandingPageSaga from '../sagas/workFlowManagementLandingPageSaga'
import workflowManagementAllProjectsSaga from '../sagas/workflowManagementAllProjectsSaga'
import navbar from '../sagas/navbarSaga'
import documentAnalyticsHistorySaga from '../sagas/documentAnalyticsHistorySaga'
import fileClusterDataSaga from '../sagas/fileClusterDataSaga'
import dashboardV2Saga from '../sagas/dashboard-v2Saga'
import privateFolderSaga from './../sagas/privateFolderSaga'
import fileCommentSaga from '../sagas/fileCommentSaga'
import createNewDocumentSaga from '../sagas/createNewDocumentSaga'
import fileLinkSaga from '../sagas/fileLinkSaga'

export default function* rootSaga() {
  yield all([
    homepageSaga(),
    teamManagementSaga(),
    accountSettingsSaga(),
    uploadDocSaga(),
    uploadedDocSaga(),
    clusteredResultSaga(),
    dashboardSaga(),
    allDocumentsSaga(),
    notificationSaga(),
    allGraphsSaga(),
    supportSaga(),
    dataSourceAuthSaga(),
    dataSourceInfoSaga(),
    catalogLandingPageSaga(),
    catalogSearchResultsSaga(),
    catalogAllSearchResultSaga(),
    catalogSelectedFolderSaga(),
    filesStatusSaga(),
    graphDataSaga(),
    allFoldersTreeSaga(),
    workFlowManagementLandingPageSaga(),
    workflowManagementAllProjectsSaga(),
    documentAnalyticsHistorySaga(),
    fileClusterDataSaga(),
    navbar(),
    dashboardV2Saga(),
    privateFolderSaga(),
    fileCommentSaga(),
    createNewDocumentSaga(),
    fileLinkSaga(),
  ])
}
