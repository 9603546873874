import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as catalogLandingPage from '../../../store/actions/catalogLandingPageActions'
import * as catalogSearchResults from '../../../store/actions/catalogSearchResultsAction'

import MinusCircle from './../../../assets/minus-circle.svg'
import styles from './RadioGroupSources.module.css'

class RadioGroupSources extends Component {
  AGGREGATE_DATA = 'All Sources'
  PRECILY_INFORMATION_DATABASE = 'Precily Information Database'

  state = {
    selectedCheckboxes: this.props.catalogSelectedSources
      ? this.props.catalogSelectedSources
      : [this.AGGREGATE_DATA],
    searchTerm: this.props.searchTerm,
    selectedSources: [],
  }

  /* select only Aggregate Data, else other sources  */
  onValueChange = (e, value) => {
    // console.info('value', value)
    let selectedCheckboxes = this.state.selectedCheckboxes

    if (selectedCheckboxes.includes(value) && !e.target.checked) {
      selectedCheckboxes.splice(selectedCheckboxes.indexOf(value), 1)
    } else if (!selectedCheckboxes.includes(value) && e.target.checked) {
      if (value !== this.AGGREGATE_DATA) {
        if (selectedCheckboxes.includes(this.AGGREGATE_DATA))
          selectedCheckboxes.splice(
            selectedCheckboxes.indexOf(this.AGGREGATE_DATA),
            1
          )
      } else if (value === this.AGGREGATE_DATA) {
        selectedCheckboxes = []
      }
      selectedCheckboxes.push(value)
    }

    if (selectedCheckboxes.length === 0)
      selectedCheckboxes[0] = this.AGGREGATE_DATA

    this.setState(
      {
        selectedCheckboxes,
      },
      () => {
        /*
         * saving "catalogSourceId" of selected checkboxes
         */
        // console.info('selectedCheckboxes: ', this.state.selectedCheckboxes)
        this.props.saveSelectedSources(this.state.selectedCheckboxes)

        let source = []
        if (this.state.selectedCheckboxes.includes(this.AGGREGATE_DATA)) {
          source.push('')
        } else {
          this.state.selectedCheckboxes.forEach((itemName, index) => {
            this.props.data.forEach((item, dataIndex) => {
              if (dataIndex > 0) {
                if (itemName === this.PRECILY_INFORMATION_DATABASE) {
                  source.push('')
                } else if (
                  item.name === itemName &&
                  item.name !== this.PRECILY_INFORMATION_DATABASE
                ) {
                  source.push(item.catalogSourceId)
                }
              }
            })
          })
        }

        /*
         * updating selected source, making query, saving sources
         */
        this.setState(
          {
            selectedSources: source,
          },
          () => {
            this.props.catalogSearchResultRequest({
              filters: {},
              query: this.state.searchTerm,
              source: source,
            })
          }
        )
      }
    )
  }

  getSelectedSources = () => {
    return this.state.selectedSources
  }

  render() {
    const { data, searchTerm } = this.props
    // console.info('data: ', data)

    return (
      <>
        {data?.map((item, index) => (
          <div className={styles.radio_container} key={index}>
            <div className={styles.round}>
              <input
                type="checkbox"
                id={index}
                onChange={e => this.onValueChange(e, item.name)}
                name={index}
                value={item.name}
                checked={this.state.selectedCheckboxes.includes(item.name)}
              />
              <label for={index}></label>
            </div>
            <span className={styles.radio_title}>{item.name}</span>
            <img className={styles.minus_icon} src={MinusCircle} />
          </div>
        ))}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    catalogSelectedSources: state.catalogSearchResult.catalogSelectedSources,
  }
}
const mapDispatchToProps = {
  catalogSearchResultRequest:
    catalogSearchResults.catalogSearchResultDataRequest,
  saveSelectedSources: catalogSearchResults.catalogSelectedSourcesAction,
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(RadioGroupSources)
