import * as types from '../types';

const initialState = {
    isLoading: false,
    teamList: null,
    error: null,
    teamRole: null,
    addTeamMember: null,
    createTeam: null,
    deleteTeamMember: null,
    updateTeamMember: null,
    isTeamListLoading: false,
    isaddTeamMemberLoading: false,
    isCreateTeamLoading: false,
    isUpdateTeamMemberLoading: false,
    isDeleteTeamMemberLoading: false
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
    switch (action.type) {
        // Team list Actions
        case types.TEAM_LIST_REQUEST:
            return {
                ...state, isLoading: true, teamList: null, addTeamMember: null, isTeamListLoading: true
            };
        case types.TEAM_LIST_SUCCESS: {
            return {
                ...state, isLoading: false,
                teamList: action.payload.result,
                isTeamListLoading: false
            };
        }
        case types.TEAM_LIST_FAILURE: return {
            ...state, isLoading: false, isTeamListLoading: false, error: action.payload.result.message || defaultErrorMessage
        };

        // Add Team Member Actions
        case types.ADD_TEAM_MEMBER_REQUEST:
            return {
                ...state, isLoading: true, addTeamMember: null, isaddTeamMemberLoading: true
            };
        case types.ADD_TEAM_MEMBER_SUCCESS: {
            return {
                ...state, isLoading: false,
                addTeamMember: action.payload.result,
                isaddTeamMemberLoading: false
            };
        }
        case types.ADD_TEAM_MEMBER_FAILURE: return {
            ...state, isLoading: false, isaddTeamMemberLoading: false, error: action.payload.result.message || defaultErrorMessage
        };

        // Create Team Actions
        case types.CREATE_TEAM_REQUEST:
            return {
                ...state,
                createTeam: null,
                isLoading: true,
                isCreateTeamLoading: true,
            };
        case types.CREATE_TEAM_SUCCESS: {
            return {
                ...state,
                createTeam: action.payload.result,
                isLoading: false,
                isCreateTeamLoading: false
            };
        }
        case types.CREATE_TEAM_FAILURE: return {
            ...state,
            isLoading: false,
            isCreateTeamLoading: false,
            error: action.payload.result.message || defaultErrorMessage
        };

        // Delete Team Member Actions
        case types.DELETE_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                deleteTeamMember: null,
                isDeleteTeamMemberLoading: true,
                isLoading: true
            };
        case types.DELETE_TEAM_MEMBER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isDeleteTeamMemberLoading: false,
                deleteTeamMember: action.payload.result
            };
        }
        case types.DELETE_TEAM_MEMBER_FAILURE: return {
            ...state,
            isLoading: false,
            isDeleteTeamMemberLoading: false,
            error: action.payload.result.message || defaultErrorMessage
        };
        // Team role Actions
        case types.TEAM_ROLE_REQUEST:
            return {
                ...state, isLoading: true, teamRole: null
            };
        case types.TEAM_ROLE_SUCCESS: {
            return {
                ...state, isLoading: false,
                teamRole: action.payload.result
            };
        }
        case types.TEAM_ROLE_FAILURE: return {
            ...state, isLoading: false, error: action.payload.result.message || defaultErrorMessage
        };
        // update team member Actions
        case types.UPDATE_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                isLoading: true,
                isUpdateTeamMemberLoading: true,
                updateTeamMember: null
            };
        case types.UPDATE_TEAM_MEMBER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isUpdateTeamMemberLoading: false,
                updateTeamMember: action.payload.result
            };
        }
        case types.UPDATE_TEAM_MEMBER_FAILURE: return {
            ...state,
            isLoading: false,
            isUpdateTeamMemberLoading: false,
            error: action.payload.result.message || defaultErrorMessage
        };

        default: {
            return state;
        }
    }
}