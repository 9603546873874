import React from "react";
import { withRouter } from "react-router-dom";
import { Nav, Navbar, Form, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import SocketIOClient from "socket.io-client";
import styled from "styled-components";
import styles from "./NavigationBar.module.css";
import { markNotificationReadRequest } from "../../store/actions/notificationAction";
import { saveFileDataRequest } from "../../store/actions/allDocumentsActions";
import {
  requestLogout,
  saveConnectionRequest,
  saveNotificationRequest,
  removeNotificationSuccess,
} from "../../store/actions/homepageActions";
import { BarLoader } from "react-spinners";

const Styles = styled.div`
  .navbar {
    background-color: #ffffff;
    padding: 0.69rem 1rem;
  }
  a,
  .navbar-nav,
  .navbar-light .nav-link {
    // color: #9FFFCB;
    // &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1em;
    color: #ffffff;
    &:hover {
      color: white;
    }
    position: absolute;
    background-color: #144192;
    left: 0;
    top: 0;
    height: 100%;
    width: 65px;
    text-align: center;
    line-height: 3.4;
  }
  .form-center {
    position: absolute !important;
    // left: 6%;
    right: 67%;
    width: 24.5%;
  }
  .form-center input {
    border-radius: 8px;
    background-color: #f5f7fd;
  }
  //   input:focus{
  //     border-bottom-left-radius: 0px;
  //     border-bottom-right-radius: 0px;
  // }
  .form-control:focus {
    box-shadow: none;
  }
`;

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      togglePopup: false,
      showNotifications: false,
      searchBarOpen: false,
      loader: true,
      messages: [],
    };
  }

  componentDidMount() {
    if (this.props.user) {
      const io = SocketIOClient("https://d339ww5oq1w5bd.cloudfront.net/");
      io.emit(
        "client",
        JSON.stringify({ TYPE: "LOGIN", message: this.props.user.userId })
      );
      io.on("notification", (data) => {
        this.saveMessages(data);
      });
    }
  }


  saveMessages = (m) => {
    let msg = JSON.parse(m);
    let activeMessages = [...this.state.messages, msg];
    this.setState(
      {
        messages: activeMessages,
      },
      function () {
        this.props.saveNotificationRequest(this.state.messages);
      }
    );
  };

  onSearchBlur = (e) => {
    this.setState({ searchBarOpen: false });
  };

  handleProfileImageClick = () => {
    this.setState({ togglePopup: true });
  };

  handleNotifications = () => {
    this.setState({ showNotifications: true });
  };

  handleViewAllNotifications = () => {
    this.props.history.push("/notifications");
  };

  closeNotificationDialog = () => {
    this.setState({ showNotifications: !this.state.showNotifications });
  };

  onPopupClose = () => {
    this.setState({ togglePopup: false });
  };
  handleAccountSettingsClick = () => {
    this.props.history.push("/settings");
  };
  handleSignoutClick = () => {
    this.props.requestLogout();
    const io = SocketIOClient("https://d339ww5oq1w5bd.cloudfront.net/");
    io.emit(
      "client",
      JSON.stringify({ TYPE: "LOGOUT", message: this.props.user.userId })
    );
    this.props.history.push("/");
  };
  handleUserInput = (event) => {
    var searchItems = event.target.value;
    this.props.onHeaderSearchChange(searchItems);
  };

  handleRoute = (data) => {
    if(data.heading === "TEAM INVITE") {
      this.props.markNotificationReadRequest(data.messageId);
      this.props.removeNotificationSuccess(data.messageId);
      return;
    }else {
    const {
      docName,
      fileId,
      version,
      fileVersionId,
      docType,
      date,
      docStatus,
      dueDate,
      isClustered,
      minor,
      permission,
      docUploadedBy
    } = data;
    const paramsObj = {
      docName,
      fileId,
      version,
      fileVersionId,
      docType: docType.toString(),
      date,
      docStatus,
      docUploadedBy,
      dueDate,
      isClustered,
      minor,
      permission,
    };
    this.props.saveFileDataRequest(paramsObj);
    this.props.markNotificationReadRequest(data.messageId);
    this.props.removeNotificationSuccess(data.messageId);
    this.props.history.push("/all-documents")
  } 
  };

  render() {
    const { togglePopup, showNotifications } = this.state;
    const { message } = this.props;
    let name = "";
    let email = "";
    let userProfileData = localStorage.getItem("userProfileData");
    if (userProfileData) {
      name = JSON.parse(userProfileData).name;
      email = JSON.parse(userProfileData).email;
    }
    return (
      <Styles>
        <div className={styles.loader_wrapper}>
          <BarLoader
            width="auto"
            Loader="CircleLoader"
            color={"#3FA0FF"}
            loading={this.props.loading ? this.props.loading : false}
          />
        </div>
        <Navbar className="fixed-top" expand="lg">
          <Navbar.Brand href="/dashboard">P_AI</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Form className={`form-center ${styles.holder}`}>
         {!this.props.hideSearchBar &&  <div className={styles.searchBarContainer}>
              <FormControl
                type="text"
                placeholder={
                  this.props.placeHolder ? this.props.placeHolder : "Search"
                }
                className={styles.formControl}
                onFocus={() => this.setState({ searchBarOpen: true })}
                onBlur={() => setTimeout(this.onSearchBlur, 500)}
                onChange={(event) => this.handleUserInput(event)}
              />
              <div className={styles.searchIcon}>
                <i className="fas fa-search"></i>
              </div>
            </div>}
          </Form>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <i
                data-badge={message.length && message !== undefined ? "" : null}
                className={`fas fa-bell ${styles.badge1}`}
                onClick={this.handleNotifications}
              ></i>
              <div className={styles.vl}></div>
              <div className={styles.navbar_container}>
                <div className={styles.navbar_profile_section}>
                  <span className={styles.navbar_profile_name}>{name}</span>
                  <span className={styles.navbar_profile_email}>{email}</span>
                </div>
                <div onClick={() => this.handleProfileImageClick()}>
                  <img
                    src="https://www.w3schools.com/howto/img_avatar.png"
                    alt="Avatar"
                    className={styles.avatar}
                  ></img>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {showNotifications && (
          <div
            className={styles.popup_overlay}
            onClick={() => this.closeNotificationDialog()}
          >
            <div className={styles.popup_container_notification}>
              <div className={styles.popup_container_notification_heading}>
                <p>Notifications({message.length || 0})</p>
                <p
                  onClick={() => this.handleViewAllNotifications()}
                  className={styles.popup_container_notification_heading_sub}
                >
                  View all
                </p>
              </div>
              <hr />
              <div className={styles.popup_content_notification}>
                {message.length > 0 && message ? (
                  message.map((msg) => {
                    return (
                      <div key={msg.id} onClick={() => this.handleRoute(msg)}>
                        <div className={styles.notification}>
                          <p style={{ marginRight: "10px" }}>{msg.heading}</p>
                          <i data-badge="" className={`${styles.badge1}`} />
                        </div>
                        <div className={styles.notificationDetails}>
                          {msg.message}
                        </div>
                        <p className={styles.notificationDate}>
                          {new Date(`${msg.date}`).toLocaleString(undefined, {
                            timeZone: "Asia/Kolkata",
                          })}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p className={styles.notification}>No Notifications</p>
                )}
              </div>
            </div>
          </div>
        )}
        {togglePopup && (
          <div
            className={styles.popup_overlay}
            onClick={() => this.onPopupClose()}
          >
            <div className={styles.popup_container}>
              <div className={styles.popup_content}>
                <div onClick={() => this.handleAccountSettingsClick()}>
                  Account Settings
                </div>
                <div
                  onClick={() => this.handleSignoutClick()}
                  className={styles.signout}
                >
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        )}
      </Styles>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.homepage.userDetails,
    message: state.homepage.notifications,
    isLoading: state.notifications.isLoading,
    fileId: state.allDocuments.fileId,
    loadingRoute: state.allDocuments.loadingRoute,
  };
};
const mapDispatchToProps = {
  requestLogout,
  saveConnectionRequest,
  saveNotificationRequest,
  markNotificationReadRequest,
  // removeNotificationSuccess,
  saveFileDataRequest,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavigationBar));
