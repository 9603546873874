import React from "react";
import { connect } from "react-redux";
import * as notification from "../../store/actions/notificationAction";
import * as homepage from "../../store/actions/homepageActions";
import styles from "./Notifications.module.css";
import Sidebar from "../../shared/sidebar/Sidebar";
import NavigationBar from "../../shared/navigation-bar/NavigationBar";
import commonStyles from "../../shared/Common.module.css";
import "../../css/react-setup.css";
import NotificationList from "../../components/notifications/NotificationList";
import Loader from "../../shared/loader/Loader";
class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchItem: "",
      notifications: [],
      isDisabled: true,
      newNotification: null,
      isLoading: true,
    };
  }
  
  //  COPY
  componentDidMount() {
    this.props.fetchAllNotifications(this.props.homepage.userDetails.userId);
  }

  //  COPY
  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.props.notifications.messages?.length > 0) {
        this.setState({
          notifications: this.props.notifications.messages,
          isDisabled: this.props.notifications.new > 0 ? false : true,
          newNotification: this.props.notifications.new,
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: false });
      }
    }
    if (
      prevProps.markAllNotificationLoading &&
      !this.props.markAllNotificationLoading
    ) {
      this.setState({ isLoading: false });
      this.props.removeNotification();
      this.props.fetchAllNotifications(this.props.homepage.userDetails.userId);
    }
    if (
      (!prevProps.markAllNotificationLoading &&
        this.props.markAllNotificationLoading) ||
      (prevProps.markNotificationLoading && !this.props.markNotificationLoading)
    ) {
      this.setState({ isLoading: true });
    }
    // console.log(
    //   prevProps ,
    //   !this.props.markNotification
    // );
    if (
      prevProps.markNotificationLoading &&
      !this.props.markNotificationLoading
    ) {
      this.setState({ isLoading: false });
      this.props.removeNotification();
      this.props.fetchAllNotifications(this.props.homepage.userDetails.userId);
    }
  }

  onHandleSearchChange = (searchItem) => {
    this.setState({ searchItem });
  };

  //  COPY
  handleMarkAllRead = () => {
    const { notifications } = this.state;
    const ids = notifications.filter((msg) => {
      return msg.fresh === true;
    });
    this.props.markAllNotificationsRead(ids);
  };

  //  COPY
  handleAsRead = (id) => {
    this.props.markNotificationAsRead(id);
  };

  render() {
    const {
      notifications,
      isDisabled,
      newNotification,
      isLoading,
    } = this.state;
    return (
      <>
        <Sidebar></Sidebar>
        <NavigationBar
          onHeaderSearchChange={this.onHandleSearchChange}
        ></NavigationBar>
        <div>
          <div
            className={`${commonStyles.commom_wrapper} ${styles.notifications_container}`}
          >
            <div className={styles.dashboard_heading}>
              <div style={{ display: "inline-flex" }}>
                <div className={commonStyles.commom_title}>
                  <div>Notifications</div>
                  <div className={styles.subheading}>
                    You have{" "}
                    {newNotification ? newNotification + " new " : 0 + " "}
                    {newNotification === 1
                      ? "notification"
                      : "notifications"}{" "}
                    waiting for you
                  </div>
                </div>
                <button
                  type="button"
                  onClick={this.handleMarkAllRead}
                  className={`btn btn-primary-outline ${styles.markAllBtn}`}
                  disabled={isDisabled}
                >
                  MARK ALL AS READ
                </button>
              </div>
            </div>
          </div>
          <div className={`card ${commonStyles.card_layout} ${styles.card}`}>
            <div className={`card-body ${styles.card_body}`}>
              {isLoading ? (
                <Loader />
              ) : notifications?.length > 0 ? (
                <NotificationList
                  notifications={notifications}
                  handleAsRead={this.handleAsRead}
                />
              ) : (
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  No notification yet!
                </p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    homepage: state.homepage,
    notifications: state.notifications,
    isLoading: state.notifications.isLoading,
    markAllNotificationLoading: state.notifications.markAllNotificationLoading,
    markNotificationLoading: state.notifications.markNotificationLoading,
  };
};

const mapDispatchToProps = {
  fetchAllNotifications: notification.fetchAllNotificationsRequest,
  markAllNotificationsRead: notification.markAllNotificationsReadRequest,
  // removeNotification: homepage.removeNotificationRequest,
  markNotificationAsRead: notification.markNotificationReadRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
