import * as types from '../types';

// Update Profile Actions
export const graphDataRequest = (payload) => ({ type: types.FETCH_GRAPH_DATA_REQUEST, payload });
export const graphDataSuccess = (payload) => ({ type: types.FETCH_GRAPH_DATA_SUCCESS, payload });
export const graphDataFailure = (payload) => ({ type: types.FETCH_GRAPH_DATA_FAILURE, payload });

export const tagsGraphDataRequest = (payload) => ({ type: types.TAGS_GRAPH_DATA_REQUEST, payload });
export const tagsGraphDataSuccess = (payload) => ({ type: types.TAGS_GRAPH_DATA_SUCCESS, payload });
export const tagsGraphDataFailure = (payload) => ({ type: types.TAGS_GRAPH_DATA_FAILURE, payload });
