import * as types from '../types'
// Workflow management project Actions

export const workflowManagementProjectRequest = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_REQUEST,
  payload,
})
export const workflowManagementProjectSuccess = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_SUCCESS,
  payload,
})
export const workflowManagementProjectFailure = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_FAILURE,
  payload,
})

// Workflow management project graph Actions
export const workflowManagementProjectGraphRequest = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_GRAPH_REQUEST,
  payload,
})
export const workflowManagementProjectGraphSuccess = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_GRAPH_SUCCESS,
  payload,
})
export const workflowManagementProjectGraphFailure = payload => ({
  type: types.WORKFLOW_MANAGEMENT_PROJECT_GRAPH_FAILURE,
  payload,
})

// Workflow management Dropdown Actions
export const workflowManagementDropdownRequest = payload => ({
  type: types.WORKFLOW_MANAGEMENT_DROPDOWN_REQUEST,
  payload,
})
export const workflowManagementDropdownSuccess = payload => ({
  type: types.WORKFLOW_MANAGEMENT_DROPDOWN_SUCCESS,
  payload,
})
export const workflowManagementDropdownFailure = payload => ({
  type: types.WORKFLOW_MANAGEMENT_DROPDOWN_FAILURE,
  payload,
})

// Workflow management Create Project Actions
export const workflowManagementCreateProjectRequest = payload => ({
  type: types.WORKFLOW_MANAGEMENT_CREATE_PROJECT_REQUEST,
  payload,
})
export const workflowManagementCreateProjectSuccess = payload => ({
  type: types.WORKFLOW_MANAGEMENT_CREATE_PROJECT_SUCCESS,
  payload,
})
export const workflowManagementCreateProjectFailure = payload => ({
  type: types.WORKFLOW_MANAGEMENT_CREATE_PROJECT_FAILURE,
  payload,
})


//  saving data on page
export const workflowManagementSaveFilters = payload => ({
  type: types.WORKFLOW_MANAGEMENT_SAVE_FILTERS,
  payload,
})
export const workflowManagementSaveFilteredDocuments = payload => ({
  type: types.WORKFLOW_MANAGEMENT_FILTERED_DOCUMENTS,
  payload,
})
