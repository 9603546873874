import React, { Component } from 'react'
import { IconContext } from 'react-icons'
import { IoMdArrowDropdown } from 'react-icons/io'
import UploadSVG from './../../../../assets/cloud_upload_white.svg'
import PlusInCircleGreySVG from './../../../../assets/plus_in_circle_grey.svg'

import CommonModal from './../../../../shared/common-modal/CommonModal'
import Dropdown from '../../../data-catalog/dropdown/Dropdown'
import styles from './NewProjectModal.module.css'
import CommonDropdown from '../../../../shared/common-dropdown/CommonDropdown'
import ErrorText from '../../../../shared/error-text/ErrorText'
import Dropzone from 'react-dropzone'
import { handleSuccess, handleError } from '../../../../shared/HandleMessages'

export default class NewProjectModal extends Component {
  constructor(props) {
    super(props)
    this.formData = new FormData()
    this.state = {
      uploadedFilesName: [],
      projectTitle: '',
      clientName: '',
      accessibility: this.props.dropDownData?.access[0]?.key,
      address: '',
      projectType: this.props.dropDownData?.type[0]?.key,
      billType: this.props.dropDownData?.billType[0]?.key,
      billValue: 0,
      projectStartDate: '',
      projectEndDate: '',
      projectLead: this.props.dropDownData?.users[0]?.key,
      fileType: [],
      tasksInfoList: [
        {
          taskName: this.props.dropDownData?.tasks[0]?.key,
          taskStartDate: '',
          taskEndDate: '',
          newTask: '',
        },
      ],
      membersInfoList: [
        {
          teamMember: this.props.dropDownData?.users[0]?.key,
          role: this.props.dropDownData?.role[0]?.key,
        },
      ],

      showError: false,
      errors: {
        projectTitle: '',
        clientName: '',
        accessibility: '',
        selectedRole: '',
        address: '',
        projectType: '',
        billType: '',
        billValue: '',
        projectStartDate: '',
        projectEndDate: '',
        projectLead: '',
      },
    }
  }
  handleUserInput = e => {
    const name = e.target.name
    const value = e.target.value
    let errors = this.state.errors
    if (value && value !== '') {
      errors[name] = ''
    }
    this.setState({ errors })
    this.setState({ [name]: value })
  }
  getErrors = () => {
    const {
      projectTitle,
      clientName,
      accessibility,
      selectedRole,
      address,
      projectType,
      billType,
      billValue,
      projectStartDate,
      projectEndDate,
      projectLead,
    } = this.state
    let errors = this.state.errors
    if (projectTitle === '') {
      errors.projectTitle = 'Title is Required!'
    }
    if (clientName === '') {
      errors.clientName = 'Client Name is Required!'
    }
    if (accessibility === '') {
      errors.accessibility = 'Accessibility is Required!'
    }
    if (selectedRole === '') {
      errors.selectedRole = 'Role is Required!'
    }
    if (address === '') {
      errors.address = 'Project Info Required!'
    }
    if (projectType === '') {
      errors.projectType = 'Project Type is Required!'
    }
    if (billType === '') {
      errors.billType = 'Bill Type is Required!'
    }
    if (billValue === '') {
      errors.billValue = 'Bill Value is Required!'
    }
    if (projectStartDate === '') {
      errors.projectStartDate = 'Start Date is Required!'
    }
    if (projectEndDate === '') {
      errors.projectEndDate = 'End Date is Required!'
    }
    // if (projectLead === '') {
    //   errors.projectLead = 'Project Lead is Required!'
    // }

    let errorCount = 0
    for (const property in this.state.errors) {
      if (this.state.errors[property].length > 1) {
        errorCount++
      }
    }

    this.setState({ errors: errors })
    return errorCount > 0
  }
  createProject = () => {
    console.log('jjj', this.state)
    const {
      clientName,
      accessibility,
      address,
      projectType,
      billType,
      billValue,
      projectStartDate,
      projectEndDate,
      taskStartDate,
      taskEndDate,
      taskName,
      projectLead,
      fileType,
      projectTitle,
      tasksInfoList,
      membersInfoList,
      newTask,
      errors,
    } = this.state
    for (let i = 0; i < membersInfoList.length; i++) {
      for (let j = i + 1; j < membersInfoList.length; j++) {
        if (membersInfoList[i].teamMember === membersInfoList[j].teamMember) {
          handleError('You have selected duplicate team member')
          return
        }
      }
    }
    for (let i = 0; i < tasksInfoList.length; i++) {
      for (let j = i + 1; j < tasksInfoList.length; j++) {
        if (tasksInfoList[i].taskName === tasksInfoList[j].taskName) {
          handleError('You have selected duplicate task name')
          return
        }
      }
    }
    if (!this.state.uploadedFilesName || this.state.uploadedFilesName?.length<1) {
      handleError('Please Upload any Document')
      return
    }

    const isFormInvalid = this.getErrors()
    if (!isFormInvalid) {
      this.formData.append('createdBy', this.props.userId)
      this.formData.append('name', projectTitle)
      this.formData.append('clientName', clientName)
      this.formData.append('access', accessibility)
      this.formData.append('description', address)
      this.formData.append('type', projectType)

      const SelectedprojectLead = this.props.dropDownData?.users.filter(
        user => {
          return projectLead === user.key
        }
      )
      this.formData.append(
        'lead',
        JSON.stringify({ id: projectLead, name: SelectedprojectLead[0].value })
      )
      this.formData.append(
        'billingDetails',
        JSON.stringify({ type: billType, charge: billValue })
      )
      this.formData.append('startDate', projectStartDate)
      this.formData.append('endDate', projectEndDate)

      const taskInfoData = tasksInfoList.map(task => {
        return {
          name: task.taskName,
          startDate: task.taskStartDate,
          endDate: task.taskEndDate,
          modifiedName: task.newTask,
        }
      })

      const memberInfoData = membersInfoList.map(member => {
        const selectedMember = this.props.dropDownData?.users.filter(user => {
          return member.teamMember === user.key
        })

        return {
          id: selectedMember[0].key,
          name: selectedMember[0].value,
          permission: member.role,
        }
      })
      this.formData.append('team', JSON.stringify(memberInfoData))

      this.formData.append('tasks', JSON.stringify(taskInfoData))
      this.formData.append('fileType', JSON.stringify(fileType))

      this.props.createProject(this.formData)
    }
  }

  onFileFound = fileArray => {
    // let arr = this.state.uploadedFilesName
    // arr.push(fileArray)
    this.setState({ uploadedFilesName: fileArray })

    let fileType = []
    fileArray.forEach(element => {
      this.formData.append('files', element)
      fileType.push({ name: element.name, type: 'sub' })
    })
    this.setState({ fileType })
  }
  selectedDropdown = (name, value) => {
    this.setState({ [name]: value })
  }
  handleSwitchChange = name => {
    let fileType = this.state.fileType.map(file => {
      if (file?.name === name) {
        if (file.type === 'main') {
          file.type = 'sub'
        } else {
          file.type = 'main'
        }
      }
      return file
    })

    this.setState({ fileType })
  }

  handleTaskNameChange = idx => (evt, selectedValue) => {
    const tasksInfo = this.state.tasksInfoList.map((task, sidx) => {
      if (idx !== sidx) return task

      return { ...task, taskName: selectedValue }
    })

    this.setState({ tasksInfoList: tasksInfo })
  }

  handleTaskEndChange = (evt, idx) => {
    const newTasksInfoList = this.state.tasksInfoList.map((task, sidx) => {
      if (idx !== sidx) return task

      return { ...task, taskEndDate: evt.target.value }
    })

    this.setState({ tasksInfoList: newTasksInfoList })
  }

  handleTaskStartChange = (evt, idx) => {
    const newTasksInfoList = this.state.tasksInfoList.map((task, sidx) => {
      if (idx !== sidx) return task

      return { ...task, taskStartDate: evt.target.value }
    })

    this.setState({ tasksInfoList: newTasksInfoList })
  }

  handleNewTaskChange = (evt, idx) => {
    const newTasksInfoList = this.state.tasksInfoList.map((task, sidx) => {
      if (idx !== sidx) return task
      return { ...task, newTask: evt.target.value }
    })

    this.setState({ tasksInfoList: newTasksInfoList })
  }

  handleAddTeamMemberChange = idx => (evt, selectedValue) => {
    const newMembersInfoList = this.state.membersInfoList.map(
      (member, sidx) => {
        if (idx !== sidx) return member

        return { ...member, teamMember: selectedValue }
      }
    )

    this.setState({ membersInfoList: newMembersInfoList })
  }
  handleRoleChange = idx => (evt, selectedValue) => {
    const newMembersInfoList = this.state.membersInfoList.map(
      (member, sidx) => {
        if (idx !== sidx) return member

        return { ...member, role: selectedValue }
      }
    )

    this.setState({ membersInfoList: newMembersInfoList })
  }

  handleAddTask = () => {
    this.setState({
      tasksInfoList: this.state.tasksInfoList.concat([
        {
          taskName: this.props.dropDownData?.tasks[0].key,
          taskStartDate: '',
          taskStartDate: '',
          newTask: '',
        },
      ]),
    })
  }
  handleAddMember = () => {
    this.setState({
      membersInfoList: this.state.membersInfoList.concat([
        {
          teamMember: this.props.dropDownData?.users[0]?.key,
          role: this.props.dropDownData?.role[0]?.key,
        },
      ]),
    })
  }
  render() {
    console.log("lll", this.props.loader)
    const { newProjectModalHandler } = this.props
    const { uploadedFilesName } = this.state
    const {
      clientName,
      accessibility,
      address,
      projectType,
      billType,
      billValue,
      projectStartDate,
      projectEndDate,
      projectLead,
      taskName,
      taskStartDate,
      taskEndDate,
      newTask,
      role,
      projectTitle,
      errors,
    } = this.state
    return (
      <CommonModal
        modalClass={'new_project_common_modal'}
        closeHandler={newProjectModalHandler}
        backdropClassName={'new_project_modal_blue_bg'}
        header={
          <section className={styles.header}>
            <input
              name="projectTitle"
              value={projectTitle}
              onChange={event => this.handleUserInput(event)}
              type="text"
              placeholder="New Project Title"
            />
            {errors.projectTitle.length > 0 && (
              <ErrorText text={errors.projectTitle} />
            )}
          </section>
        }
      >
        <div className={styles.root}>
          <section className={styles.section_client_name}>
            <div className={styles.client_name}>
              <div className={styles.label}>CLIENT NAME</div>
              <input
                type="text"
                name="clientName"
                value={clientName}
                onChange={event => this.handleUserInput(event)}
                placeholder="XYZ Private Limited"
              />
              {errors.clientName.length > 0 && (
                <ErrorText text={errors.clientName} />
              )}
            </div>
            <div className={styles.accessibility}>
              <div className={styles.label}>ACCESSIBILITY</div>
              <CommonDropdown
                backgroundColor="#fafafa"
                value0="Choose Type"
                fontSize="18px"
                width="100%"
                height="51px"
                borderRadius="4px"
                iconSize="24px"
                padding="13px 24px"
                textColor="#000"
                name="accessibility"
                dropdownList={this.props.dropDownData?.access}
                selectedDropdown={this.selectedDropdown}
              />
              {errors.accessibility.length > 0 && (
                <ErrorText text={errors.accessibility} />
              )}
            </div>
          </section>

          <section className={styles.section_address}>
            <div className={styles.label}>PROJECT INFO</div>
            <input
              type="text"
              name="address"
              value={address}
              onChange={event => this.handleUserInput(event)}
              placeholder="Project Info"
            />
            {errors.address.length > 0 && <ErrorText text={errors.address} />}
          </section>

          <section className={styles.section_type_of_project}>
            <div className={styles.type_of_project}>
              <div className={styles.label}>TYPE OF PROJECT</div>
              <CommonDropdown
                backgroundColor="#fafafa"
                value0="Choose Type"
                fontSize="18px"
                width="100%"
                height="51px"
                borderRadius="4px"
                padding="10px 15px"
                iconSize="24px"
                textColor="#000"
                name="projectType"
                dropdownList={this.props.dropDownData?.type}
                selectedDropdown={this.selectedDropdown}
              />
              {errors.projectType.length > 0 && (
                <ErrorText text={errors.projectType} />
              )}
            </div>

            <div className={styles.bill_container}>
              <span className={styles.bill_type}>
                <div className={styles.label}>BILL TYPE</div>
                <CommonDropdown
                  backgroundColor="#fafafa"
                  value0="Choose Type"
                  fontSize="18px"
                  width="100%"
                  height="51px"
                  borderRadius="4px"
                  padding="10px 15px"
                  iconSize="24px"
                  textColor="#000"
                  name="billType"
                  dropdownList={this.props.dropDownData?.billType}
                  selectedDropdown={this.selectedDropdown}
                />
                {errors.billType.length > 0 && (
                  <ErrorText text={errors.billType} />
                )}
              </span>

              <span className={styles.bill}>
                <div className={styles.label}>BILL</div>
                <div>
                  <input
                    type="text"
                    name="billValue"
                    value={billValue}
                    onChange={event => this.handleUserInput(event)}
                    placeholder="00000"
                  />

                  <span className={styles.bill_field_text}>INR</span>
                </div>
                {/* {errors.billValue.length > 0 && <ErrorText text={errors.billValue} />}  */}
              </span>
            </div>
          </section>

          <section className={styles.section_date}>
            <div className={styles.start_date}>
              <div className={styles.label}>START DATE</div>
              <input
                type="date"
                name="projectStartDate"
                required
                value={projectStartDate}
                onChange={event => this.handleUserInput(event)}
              />
              {errors.projectStartDate.length > 0 && (
                <ErrorText text={errors.projectStartDate} />
              )}
            </div>
            <div className={styles.end_date}>
              <div className={styles.label}>END DATE</div>
              <input
                type="date"
                name="projectEndDate"
                required
                value={projectEndDate}
                onChange={event => this.handleUserInput(event)}
              />
              {errors.projectEndDate.length > 0 && (
                <ErrorText text={errors.projectEndDate} />
              )}
            </div>
          </section>

          <section className={styles.section_project_lead}>
            <div className={styles.label}>PROJECT LEAD</div>
            {/* <input
              type="text"
              name="projectLead"
              value={projectLead}
              onChange={event => this.handleUserInput(event)}
              placeholder="Project Lead name"
            /> */}

            <div className={styles.team_member}>
              {/* {idx === 0 && <div className={styles.label}>TEAM MEMBER</div>} */}
              <CommonDropdown
                backgroundColor="#fafafa"
                value0="Project Lead"
                fontSize="18px"
                width="100%"
                height="51px"
                borderRadius="4px"
                padding="10px 15px"
                iconSize="24px"
                textColor="#000"
                name="projectLead"
                dropdownList={this.props.dropDownData?.users}
                selectedDropdown={this.selectedDropdown}
              />
            </div>
            {errors.projectLead.length > 0 && (
              <ErrorText text={errors.projectLead} />
            )}
          </section>

          {this.state.membersInfoList.map((task, idx) => (
            <section className={styles.section_team_member}>
              <div className={styles.team_member}>
                {idx === 0 && <div className={styles.label}>TEAM MEMBER</div>}
                <div style={{ borderRight: '1px solid #eaeaea' }}>
                  <CommonDropdown
                    backgroundColor="#fafafa"
                    value0="Add Team Member"
                    fontSize="18px"
                    width="100%"
                    height="51px"
                    borderRadius="4px"
                    padding="10px 15px"
                    iconSize="24px"
                    textColor="#000"
                    name="teamMember"
                    dropdownList={this.props.dropDownData?.users}
                    selectedDropdown={this.handleAddTeamMemberChange(idx)}
                  />
                </div>
              </div>
              <div className={styles.role}>
                {idx === 0 && (
                  <div className={`${styles.label} ${styles.role_label}`}>
                    ROLE
                  </div>
                )}
                <CommonDropdown
                  backgroundColor="#fafafa"
                  value0="Assign Role"
                  fontSize="18px"
                  width="465px"
                  height="51px"
                  borderRadius="4px"
                  padding="10px 15px"
                  iconSize="24px"
                  textColor="#000"
                  name="role"
                  dropdownList={this.props.dropDownData?.role}
                  selectedDropdown={this.handleRoleChange(idx)}
                />
              </div>
            </section>
          ))}

          {this.props.dropDownData?.users.length - 1 >
            this.state.membersInfoList.length && (
            <div
              onClick={this.handleAddMember}
              className={styles.add_member_container}
            >
              <span>Add Member</span>
              <img src={PlusInCircleGreySVG} alt="PlusInCircleGreySVG" />
            </div>
          )}

          {this.state.tasksInfoList.map((task, idx) => (
            <section key={idx} className={styles.section_task}>
              <div className={styles.task}>
                {idx === 0 && <div className={`${styles.label}`}>TASK</div>}
                <div className={styles.task_input} style={{ borderRight: '1px solid #eaeaea' }}>
                  <input
                    type="date"
                    name="newTask"
                    value={task.newTask}
                    onChange={event => this.handleNewTaskChange(event, idx)}
                    type="text"
                  />
                </div>
              </div>

              <div className={styles.date}>
                <div className="start_date">
                  {idx === 0 && (
                    <div className={`${styles.label} ${styles.date_label}`}>
                      START DATE
                    </div>
                  )}

                  <input
                    type="date"
                    name="taskStartDate"
                    required
                    value={task.taskStartDate}
                    onChange={event => this.handleTaskStartChange(event, idx)}
                  />
                </div>
                <div className="end_date">
                  {idx === 0 && (
                    <div className={`${styles.label} ${styles.date_label}`}>
                      END DATE
                    </div>
                  )}

                  <input
                    type="date"
                    name="taskEndDate"
                    value={task.taskEndDate}
                    onChange={event => this.handleTaskEndChange(event, idx)}
                  />
                </div>
              </div>

              <div className={styles.task}>
                {idx === 0 && <div className={`${styles.label}`}>STATUS</div>}
                <div style={{ borderRight: '1px solid #eaeaea' }}>
                  <CommonDropdown
                    backgroundColor="#fafafa"
                    value0="Assign Role"
                    fontSize="18px"
                    width="255px"
                    height="51px"
                    borderRadius="4px"
                    padding="10px 15px"
                    iconSize="24px"
                    textColor="#000"
                    name="taskName"
                    dropdownList={this.props.dropDownData?.tasks}
                    // index={idx}
                    selectedDropdown={this.handleTaskNameChange(idx)}
                  />
                </div>
              </div>
            </section>
          ))}

          {this.props.dropDownData?.tasks.length >
            this.state.tasksInfoList.length && (
            <div
              onClick={this.handleAddTask}
              className={styles.add_task_section}
            >
              <span>Add A Task</span>
              <img src={PlusInCircleGreySVG} alt="PlusInCircleGreySVG" />
            </div>
          )}

          <div className={styles.project_modal_bottom_divider}></div>

          {uploadedFilesName &&
            uploadedFilesName.map(file => {
              return (
                <div className={styles.upload_files_list}>
                  <div className={styles.project_uploaded_files}>
                    {file.name}
                  </div>
                  <div className={styles.switch_container}>
                    <label className={styles.switch}>
                      <input
                        onChange={() => {
                          this.handleSwitchChange(file.name)
                        }}
                        type="checkbox"
                      />
                      <span
                        className={`${styles.slider} ${styles.round}`}
                      ></span>
                    </label>
                    <span>Main document</span>
                  </div>
                </div>
              )
            })}

          <div className={styles.project_modal_bottom_content}>
            <Dropzone onDrop={this.onFileFound}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} type="file" />

                  <section className={styles.section_btn}>
                    <div className={styles.upload_doc}>
                      <span>Upload Documents</span>
                      <img src={UploadSVG} alt="cloud Upload Icon" />
                    </div>
                  </section>
                </div>
              )}
            </Dropzone>

            <div onClick={this.createProject} className={styles.create_project}>
              <div> {this.props.loader && <i class="fas fa-spinner fa-spin"></i>}  CREATE PROJECT </div>
            </div>
          </div>

          {/* <section className={styles.section_btn}>
            <div className={styles.upload_doc}>
              <span>Upload Documents</span>
              <img src={UploadSVG} alt="cloud Upload Icon" />
            </div>
            <div onClick={this.createProject} className={styles.create_project}>CREATE PROJECT</div>
          </section> */}
        </div>
      </CommonModal>
    )
  }
}
