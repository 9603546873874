import React, { Component } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { RiPulseFill } from 'react-icons/ri';
import { IconContext } from 'react-icons';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

/* import NavigationBar from '../../shared/navigation-bar/NavigationBar'
import Sidebar from '../../shared/sidebar/Sidebar' */
import NavBar from './NavBar/NavBar';
import Sidebar from './Sidebar/Sidebar';
import RightBar from './RightBar/RightBar';
import Dropdown from './../../components/data-catalog/dropdown/Dropdown';
import SearchBar from '../../components/data-catalog/search-bar/SearchBar';
import AllProjectsAndRecentFolders from '../../components/workflow-management/AllProjectsAndRecentFolders/AllProjectsAndRecentFolders';
import PlusCircle from '../../../src/assets/plus_circle.svg';
import GreenFolder from '../../../src/assets/green_folder.svg';
import commonStyles from '../../shared/Common.module.css';
import {
  search_results as searchResults,
  folders,
} from './../../json-data/searchResults.json';
import dadas from './../../json-data/recentFolders.json';
import styles from './WorkflowManagement.module.css';
import NewProjectModal from '../../components/all-projects/modals/new-project/NewProjectModal';
import { connect } from 'react-redux';
import * as workFlowManagementLandingPage from '../../store/actions/workFlowManagementLandingPageActions';
import * as catalogLandingPageActions from '../../store/actions/catalogLandingPageActions';
import * as catalogSearchResults from '../../store/actions/catalogSearchResultsAction';
import { handleSuccess, handleError } from '../../shared/HandleMessages';

class WorkflowManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowManagementProjectData: null,
      showNewProjectModal: false,
      searchTerms: '',
    };
  }

  componentDidMount() {
    this.props.saveAdvancedSearchFilters(null);
    this.props.saveSearchTermRequest(null);
    this.props.saveSelectedSources(null);

    const paramsObj = {
      createdBy: this.props.userId,
    };
    this.props.workflowManagementProject(paramsObj);
    this.props.workflowManagementProjectGraph({
      graphType: 'status',
      createdBy: this.props.userId,
    });
    this.props.workflowManagementDropdown({ adminId: this.props.userId });
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isWorkflowManagementProjectDataLoading &&
      prevProps.isWorkflowManagementProjectDataLoading &&
      this.props.workflowManagementProjectData
    ) {
      // console.log(this.props.workflowManagementProjectData);
      let projects = this.props.workflowManagementProjectData;
      let count = 0;
      let dateFrom = moment().subtract(7, 'd').format('YYYY-MM-DD');
      projects.forEach((project) => {
        let createdDate = new Date(project.createdAt).getTime();
        let previousDate = new Date(dateFrom).getTime();
        if (createdDate >= previousDate) {
          count += 1;
        }
      });
      this.setState({
        workflowManagementProjectData: projects,
        recentProjectsCount: count,
      });
    }

    if (
      prevProps.isWorkflowManagementProjectGraphDataLoading &&
      !this.props.isWorkflowManagementProjectGraphDataLoading
    ) {
      this.setState({
        workflowManagementProjectGraphData: this.props
          .workflowManagementProjectGraphData,
      });
    }
    if (
      prevProps.isWorkflowManagementCreateProjectLoading &&
      !this.props.isWorkflowManagementCreateProjectLoading &&
      this.props.workflowManagementCreateProject
    ) {
      handleSuccess('Project has been created successfully!');
      this.setState({ showNewProjectModal: false });
      const paramsObj = {
        createdBy: this.props.userId,
      };
      this.props.workflowManagementProject(paramsObj);
      this.props.workflowManagementProjectGraph({
        graphType: 'status',
        createdBy: this.props.userId,
      });
    }
  }
  toggleNewProjectModal = (value) => {
    this.setState({
      showNewProjectModal: value,
    });
  };

  createProject = (paramsObj) => {
    this.props.workflowManagementCreateProjectRequest(paramsObj);
  };

  handleSearchChange = (searchTerms) => {
    this.setState({ searchTerms });
  };

  render() {
    const profileName = JSON.parse(localStorage.getItem('userProfileData'))
      ?.name;
    const {
      // workflowManagementProjectData,
      showNewProjectModal,
      workflowManagementProjectGraphData,
      searchTerms,
      recentProjectsCount,
    } = this.state;
    return (
      <>
        <NavBar />
        <Sidebar />
        <RightBar />
        <div
          className={`${commonStyles.common_workflow_management_wrapper} ${styles.root}`}
        >
          <div className={styles.upper_part}>
            <div className={styles.header}>
              <div className={styles.heading}>
                <div>Welcome Back</div>
                <div className={styles.username}>{profileName}</div>
              </div>

              <div className={styles.card_container}>
                <div className={styles.new_project_card}>
                  <div className={styles.folder_icon_container}>
                    <img src={GreenFolder} alt="Green Folder" />
                  </div>

                  <div className={styles.top_container_card_info}>
                    <div className={styles.top_container_title}>
                      Working on:
                    </div>

                    <div className={styles.top_container_count}>
                      {this.state.workflowManagementProjectData?.length !==
                      (null || undefined)
                        ? `${this.state.workflowManagementProjectData?.length}
                       Projects`
                        : '0 Projects'}
                    </div>
                    <div
                      className={styles.text_plus_icon_container}
                      onClick={() => this.toggleNewProjectModal(true)}
                    >
                      <div className={styles.top_container_action}>
                        Create New Project
                      </div>
                      <div className={styles.plus_icon}>
                        <img src={PlusCircle} alt="PlusCircle" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.search_bar_section}>
              <div className={styles.search_bar_container}>
                <SearchBar
                  iconSize={'21px'}
                  placeholder={'Search For Projects, Teams, or Clients'}
                  fontSize={'18px'}
                  handleSearchChange={(searchItems) => {
                    this.handleSearchChange(searchItems);
                  }}
                />
              </div>
              <div className={styles.project_info}>
                <span className={styles.circle_fill_icon}>
                  <IconContext.Provider
                    value={{ color: '#1BA94C', size: '12px' }}
                  >
                    <BsCircleFill />
                  </IconContext.Provider>
                </span>
                <span>
                  {recentProjectsCount} Projects have been recently updated
                </span>
              </div>
            </div>
          </div>

          <div className={styles.middle_part}>
            <div className={styles.middle_part_heading}>
              <span className={styles.overview_heading}>Overview</span>
              <IconContext.Provider
                value={{
                  color: '#1BA94C',
                  iconSize: 20,
                }}
              >
                <RiPulseFill />
              </IconContext.Provider>
            </div>
            <div className={`workflow_management_tabs ${styles.tabs}`}>
              <Tabs
                defaultActiveKey="home1"
                transition={false}
                // onSelect={k => this.handleActiveTab(k)}
              >
                <Tab
                  tabClassName={styles.tab_root}
                  eventKey="home1"
                  title="By Status"
                ></Tab>
                {/* <Tab
                  tabClassName={styles.tab_root}
                  eventKey="home2"
                  title="By Project"
                ></Tab> */}
                <Tab
                  tabClassName={styles.tab_root}
                  eventKey="home3"
                  title="By Billing"
                ></Tab>
                {/* <Tab
                  tabClassName={styles.tab_root}
                  eventKey="home4"
                  title="By Client"
                ></Tab> */}
              </Tabs>
            </div>
            <div className={styles.graph_container}>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  colors: [
                    '#0e5326',
                    '#178c40',
                    '#1eb854',
                    '#D0EDDA',
                    '#EAEAEA',
                  ].reverse(),
                  chart: {
                    type: 'column',
                    backgroundColor: '#fafafa',
                    height: 350,
                    style: {
                      fontFamily: 'Roboto',
                    },
                  },
                  title: {
                    text: ' ',
                  },
                  xAxis: {
                    categories:
                      workflowManagementProjectGraphData &&
                      workflowManagementProjectGraphData.categories,
                    title: {
                      text: 'PROJECT',
                      style: {
                        color: '#2c2c2c',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                      },
                    },
                    labels: {
                      style: {
                        fontSize: '12px',
                        color: '#9a9a9a',
                      },
                    },
                  },
                  yAxis: {
                    lineColor: '#eaeaea',
                    lineWidth: 1,
                    min: 0,
                    title: {
                      text: 'DAYS',
                      margin: 20,
                      style: {
                        color: '#2c2c2c',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                      },
                    },
                    gridLineWidth: 0,
                    stackLabels: {
                      enabled: false,
                      style: {
                        fontWeight: 'bold',
                        color: '#2c2c2c',
                        fontFamily: 'Roboto',
                      },
                    },
                    labels: {
                      style: {
                        fontSize: '12px',
                        color: '#9a9a9a',
                        fontFamily: 'Roboto',
                      },
                    },
                  },
                  legend: {
                    align: 'right',
                    x: 0,
                    verticalAlign: 'top',
                    y: -15,
                    floating: true,
                    backgroundColor:
                      Highcharts.defaultOptions.legend.backgroundColor ||
                      '#fafafa',
                    shadow: false,
                    itemStyle: {
                      fontSize: '12px',
                      fontFamily: 'Roboto, sans-serif',
                      color: '#9a9a9a',
                      fontWeight: '400',
                    },
                  },
                  tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat:
                      '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
                  },
                  plotOptions: {
                    column: {
                      stacking: 'normal',
                      dataLabels: {
                        enabled: false,
                      },
                      colors: [
                        '#1BA94C',
                        '#D0EDDA',
                        '#EAEAEA',
                        '#4CAF50',
                        '#8BC34A',
                        '#C8E6C9',
                      ],
                    },
                  },
                  series:
                    workflowManagementProjectGraphData &&
                    workflowManagementProjectGraphData.series.reverse(),
                }}
              />
            </div>
          </div>
          <div className={styles.lower_part}>
            {this.state.workflowManagementProjectData && (
              <AllProjectsAndRecentFolders
                folders={folders}
                searchResults={searchResults}
                history={this.props.history}
                allProjectsData={this.state.workflowManagementProjectData}
                searchTerms={searchTerms}
              />
            )}
          </div>
        </div>
        {showNewProjectModal && this.props.workflowManagementDropdownData && (
          <NewProjectModal
            newProjectModalHandler={() => this.toggleNewProjectModal(false)}
            dropDownData={this.props.workflowManagementDropdownData}
            userId={this.props.userId}
            createProject={this.createProject}
            loader={this.props.isWorkflowManagementCreateProjectLoading}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.homepage.userDetails.userId,
    workflowManagementProjectData:
      state.workFlowManagementLandingPage.workflowManagementProjectData,
    isWorkflowManagementProjectDataLoading:
      state.workFlowManagementLandingPage
        .isWorkflowManagementProjectDataLoading,
    isWorkflowManagementProjectGraphDataLoading:
      state.workFlowManagementLandingPage
        .isWorkflowManagementProjectGraphDataLoading,
    workflowManagementDropdownData:
      state.workFlowManagementLandingPage.workflowManagementDropdownData,
    workflowManagementProjectGraphData:
      state.workFlowManagementLandingPage.workflowManagementProjectGraphData,
    workflowManagementCreateProject:
      state.workFlowManagementLandingPage.workflowManagementCreateProject,
    isWorkflowManagementCreateProjectLoading:
      state.workFlowManagementLandingPage
        .isWorkflowManagementCreateProjectLoading,
  };
};

const mapDispatchToProps = {
  workflowManagementProject:
    workFlowManagementLandingPage.workflowManagementProjectRequest,
  workflowManagementProjectGraph:
    workFlowManagementLandingPage.workflowManagementProjectGraphRequest,
  workflowManagementDropdown:
    workFlowManagementLandingPage.workflowManagementDropdownRequest,
  workflowManagementCreateProjectRequest:
    workFlowManagementLandingPage.workflowManagementCreateProjectRequest,
  saveAdvancedSearchFilters:
    catalogLandingPageActions.catalogAdvancedSearchFilterAction,
  saveSearchTermRequest: catalogSearchResults.catalogSearchResultSearchTermAction,
  saveSelectedSources: catalogSearchResults.catalogSelectedSourcesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowManagement);
