import React from 'react';
import { v4 as uuid } from 'uuid';
import { BsPencil } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import moment from 'moment';

import styles from './CustomNode.module.css';

class CustomNode extends React.Component {
  state = {
    scopeID: 'scopeID',
    scopeName: 'International Taxation',
    levelOne: [
      {
        levelOneID: 'A',
        levelOneName: 'PE',
        levelTwo: [
          {
            levelTwoID: 'A1',
            levelTwoName: 'Service PE',
            keywords: 'Service PE, Physical Presence',
          },
          {
            levelTwoID: 'A2',
            levelTwoName: 'Service PE',
            keywords: 'Service PE, Physical Presence',
          },
          {
            levelTwoID: 'A3',
            levelTwoName: 'Service PE',
            keywords: 'Service PE, Physical Presence',
          },
          //   {
          //     levelTwoID: 'ID',
          //     levelTwoName: 'Supervisory PE',
          //     keywords: ['Supervisory pe', 'project pe'],
          //   },
        ],
        metadata: {
          editors: [
            {
              name: 'Neeraj Sewani',
              imageUrl: 'https://www.w3schools.com/howto/img_avatar.png',
            },
            {
              name: 'Neeraj Sewani',
              imageUrl: 'https://www.w3schools.com/howto/img_avatar.png',
            },
            {
              name: 'Neeraj Sewani',
              imageUrl: 'https://www.w3schools.com/howto/img_avatar.png',
            },
          ],
          lastEdit: Date.now(),
          status: 'Uploaded',
        },
      },
      {
        levelOneID: 'B',
        levelOneName: 'Business Profits',
        levelTwo: [
          {
            levelTwoID: 'B1',
            levelTwoName: 'Source Of Income',
            keywords: 'nexus, place of accruel',
          },
        ],
      },
    ],
    metadata: [],
    currentInputHovered: null,
    inputChanged: { id: null, value: null },
    keywordChanged: { id: null, value: null },
    showMainIssue: false,
    mainIssueSelectedId: null,
    subIssueSelectedId: null,
  };

  addMainNode = () => {
    const levelOne = this.state.levelOne;
    const newObj = {
      levelOneID: uuid(),
      levelOneName: '',
      levelTwo: [
        {
          levelTwoID: uuid(),
          levelTwoName: '',
          keywords: [],
        },
      ],
    };
    levelOne.push(newObj);
    this.setState({ levelOne }, () => {
      console.info('level one main node added: ', this.state.levelOne);
    });
    //  TODO: API call here to make a new node
  };

  addSubIssueNode = (levelOneId) => {
    console.info('addSubIssueNode: level one id: ', levelOneId);

    const levelOne = this.state.levelOne;
    const levelOneMatchedNodeIndex = levelOne.findIndex(
      (levelOneNode) => levelOneNode.levelOneID === levelOneId
    );
    const levelOneMatchedNode = levelOne[levelOneMatchedNodeIndex];

    const newObj = {
      levelTwoID: uuid(),
      levelTwoName: '',
      keywords: [],
    };

    levelOneMatchedNode.levelTwo.push(newObj);
    levelOne.splice(levelOneMatchedNodeIndex, 1, levelOneMatchedNode);
    this.setState({ levelOne }, () => {
      console.info(
        'level one after sub issue node added: ',
        this.state.levelOne
      );
    });
  };

  //   handleInputKeyPress = data => {
  //     console.info('data: ', data)
  //   }

  handleInputKeyPressLevelOne = (e, id) => {
    if (e.key === 'Enter') {
      const { levelOne, inputChanged } = this.state;
      const matchedNodeIndex = levelOne.findIndex(
        (element) => element.levelOneID === id
      );

      const matchedNode = levelOne[matchedNodeIndex];

      matchedNode.levelOneName = inputChanged.value;
      levelOne.splice(matchedNodeIndex, 1, matchedNode);
      this.setState({
        levelOne,
      });
    }
  };
  handleInputKeyPressLevelTwo = (e, levelOneId, levelTwoId) => {
    if (e.key === 'Enter') {
      const { levelOne, inputChanged } = this.state;
      const matchedNodeIndex = levelOne.findIndex(
        (element) => element.levelOneID === levelOneId
      );

      const matchedNode = levelOne[matchedNodeIndex];

      const matchedNodeLevelTwoIndex = matchedNode.levelTwo.findIndex(
        (element) => element.levelTwoID === levelTwoId
      );
      const matchedNodeLevelTwo =
        matchedNode.levelTwo[matchedNodeLevelTwoIndex];

      matchedNodeLevelTwo.levelTwoName = inputChanged.value;
      levelOne.splice(matchedNodeIndex, 1, matchedNode);
      this.setState({
        levelOne,
      });
    }
  };

  handleKeywordKeyPressLevelTwo = (e, levelOneId, levelTwoId) => {
    if (e.key === 'Enter') {
      const { levelOne, keywordChanged } = this.state;
      const matchedNodeIndex = levelOne.findIndex(
        (element) => element.levelOneID === levelOneId
      );
      const matchedNode = levelOne[matchedNodeIndex];

      const matchedNodeLevelTwoIndex = matchedNode.levelTwo.findIndex(
        (element) => element.levelTwoID === levelTwoId
      );
      const matchedNodeLevelTwo =
        matchedNode.levelTwo[matchedNodeLevelTwoIndex];

      matchedNodeLevelTwo.keywords = keywordChanged.value;
      levelOne.splice(matchedNodeIndex, 1, matchedNode);
      this.setState({
        levelOne,
      });
    }
  };

  handleInputHover = (id) => {
    this.setState({
      currentInputHovered: id,
    });
  };

  handleInputChange = (e, id) => {
    const value = e.target.value;

    this.setState({
      inputChanged: {
        id,
        value,
      },
    });
  };
  handleKeywordChange = (e, id) => {
    const value = e.target.value;

    this.setState({
      keywordChanged: {
        id,
        value,
      },
    });
  };

  toggleMainIssue = () => {
    const { showMainIssue } = this.state;
    this.setState({
      showMainIssue: !showMainIssue,
      mainIssueSelectedId: null,
    });
  };

  toggleSubIssue = (levelOneId) => {
    this.setState({
      mainIssueSelectedId: levelOneId,
    });
  };

  handleSubIssueSelection = (id) => {
    this.setState({
      subIssueSelectedId: id,
    });
  };

  render() {
    const {
      levelOne,
      scopeName,
      currentInputHovered,
      inputChanged,
      showMainIssue,
      mainIssueSelectedId,
      subIssueSelectedId,
      keywordChanged,
    } = this.state;

    // console.info('state: ', this.state.levelOne)

    return (
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.left_section}>
            <div className={styles.left_section_wrapper}>
              <div
                className={styles.main_issue_card}
                onClick={this.toggleMainIssue}
                style={{
                  borderLeft: '4px solid #1ba94c',
                }}
              >
                <div className={styles.input_container}>
                  <input
                    type="text"
                    value={scopeName}
                    onKeyPress={() => this.handleInputKeyPress()}
                  />
                  <IconContext.Provider
                    value={{
                      color: '#2c2c2c',
                      size: 15,
                    }}
                  >
                    <BsPencil />
                  </IconContext.Provider>
                </div>
              </div>

              {showMainIssue && <div className={styles.line}></div>}
            </div>
          </div>

          {showMainIssue && (
            <div className={styles.middle_section}>
              {levelOne.map((levelOneData, index) => {
                return (
                  <div
                    className={styles.middle_cards}
                    style={
                      index === levelOne.length - 1
                        ? { paddingBottom: '0px', marginBottom: '9px' }
                        : {}
                    }
                  >
                    {index === 0 && (
                      <div
                        className={styles.border_remover_container}
                        style={
                          mainIssueSelectedId === levelOneData.levelOneID
                            ? { height: '50px' }
                            : { height: '24px' }
                        }
                      ></div>
                    )}

                    {index === levelOne.length - 1 && (
                      <div
                        className={styles.border_remover_container_opposite}
                        style={
                          mainIssueSelectedId === levelOneData.levelOneID
                            ? { height: '50px' }
                            : { height: '24px' }
                        }
                      ></div>
                    )}
                    <div className={styles.main_issue_cards_container}>
                      <div
                        className={styles.middle_line}
                        style={
                          mainIssueSelectedId === levelOneData.levelOneID
                            ? index === 0 || index === levelOne.length - 1
                              ? {
                                  border: '1px solid #1ba94c',
                                  marginLeft: '-3px',
                                }
                              : { border: '1px solid #1ba94c' }
                            : index === 0 || index === levelOne.length - 1
                            ? { marginLeft: '-3px' }
                            : {}
                        }
                      ></div>

                      {/* Main Issue Card */}
                      <div
                        className={styles.main_issue_card}
                        onClick={() =>
                          this.toggleSubIssue(levelOneData.levelOneID)
                        }
                        onMouseEnter={() =>
                          this.handleInputHover(levelOneData.levelOneID)
                        }
                        onMouseLeave={() => this.handleInputHover(null)}
                        style={
                          mainIssueSelectedId === levelOneData.levelOneID
                            ? {
                                borderLeft: '4px solid #1ba94c',
                              }
                            : {}
                        }
                      >
                        <div className={styles.input_container}>
                          <input
                            type="text"
                            placeholder="Main Issue"
                            value={
                              inputChanged.id === levelOneData.levelOneID
                                ? inputChanged.value
                                : levelOneData.levelOneName
                            }
                            onChange={(e) =>
                              this.handleInputChange(e, levelOneData.levelOneID)
                            }
                            onKeyPress={(e) =>
                              this.handleInputKeyPressLevelOne(
                                e,
                                levelOneData.levelOneID
                              )
                            }
                            //   onClick={() =>
                            //     this.handleInputClick(levelOneData.levelOneID)
                            //   }
                            style={
                              levelOneData.levelOneID === currentInputHovered
                                ? {
                                    borderBottom: '1px solid #eaeaea',
                                  }
                                : {}
                            }
                          />
                          {currentInputHovered === levelOneData.levelOneID ? (
                            <IconContext.Provider
                              value={{
                                color: '#2c2c2c',
                                size: 15,
                              }}
                            >
                              <BsPencil />
                            </IconContext.Provider>
                          ) : null}
                        </div>

                        {levelOneData.metadata &&
                          mainIssueSelectedId === levelOneData.levelOneID && (
                            <div className={styles.metadataContainer}>
                              <div className={styles.avatars}>
                                {levelOneData.metadata.editors.map(
                                  (editor, index) => (
                                    <span className={styles.avatar}>
                                      <img
                                        src={editor.imageUrl}
                                        alt="Profile Icon"
                                      />
                                    </span>
                                  )
                                )}
                              </div>
                              <div className={styles.info_container}>
                                <div className={styles.date}>
                                  {moment(
                                    levelOneData.metadata.lastEdit
                                  ).format('DD/MM/YYYY')}
                                </div>
                                <div className={styles.status}>
                                  {levelOneData.metadata.status}
                                </div>
                              </div>
                            </div>
                          )}
                      </div>

                      {mainIssueSelectedId === levelOneData.levelOneID && (
                        <div
                          className={styles.middle_line1}
                          style={
                            mainIssueSelectedId === levelOneData.levelOneID
                              ? { border: '1px solid #1ba94c' }
                              : {}
                          }
                        ></div>
                      )}
                    </div>

                    {/* Sub-issues */}
                    <div className={styles.sub_issue_card_container}>
                      {mainIssueSelectedId === levelOneData.levelOneID && (
                        <div
                          className={styles.sub_issue_card_container_wrapper}
                          // onClick={e =>
                          //   this.handleSubIssueCardSelection(
                          //     levelTwoData.levelTwoID
                          //   )
                          // }
                        >
                          <div
                            className={
                              styles.sub_issue_card_container_wrapper_1
                            }
                          >
                            {levelOneData?.levelTwo?.map(
                              (levelTwoData, index) => (
                                <div
                                  className={styles.sub_issue_card}
                                  onMouseEnter={() =>
                                    this.handleInputHover(
                                      levelTwoData.levelTwoID
                                    )
                                  }
                                  onMouseLeave={() =>
                                    this.handleInputHover(null)
                                  }
                                  // onClick={e =>
                                  //   this.handleSubIssueSelection(
                                  //     levelTwoData.levelTwoID
                                  //   )
                                  // }
                                  // style={
                                  //   subIssueSelectedId === levelTwoData.levelTwoID
                                  //     ? {
                                  //         borderLeft: '4px solid #1ba94c',
                                  //       }
                                  //     : {}
                                  // }
                                >
                                  <div
                                    className={styles.subissue_input_container}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Sub Issue"
                                      value={
                                        inputChanged.id ===
                                        levelTwoData.levelTwoID
                                          ? inputChanged.value
                                          : levelTwoData.levelTwoName
                                      }
                                      style={
                                        currentInputHovered ===
                                        levelTwoData.levelTwoID
                                          ? {
                                              borderBottom: '1px solid #eaeaea',
                                            }
                                          : {}
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(
                                          e,
                                          levelTwoData.levelTwoID
                                        )
                                      }
                                      onKeyPress={(e) =>
                                        this.handleInputKeyPressLevelTwo(
                                          e,
                                          levelOneData.levelOneID,
                                          levelTwoData.levelTwoID
                                        )
                                      }
                                    />
                                    <textarea
                                      name="keywords-text-area"
                                      placeholder="Keywords"
                                      rows="3"
                                      cols="1"
                                      value={
                                        keywordChanged.id ===
                                        levelTwoData.levelTwoID
                                          ? keywordChanged.value
                                          : levelTwoData.keywords
                                      }
                                      style={
                                        currentInputHovered ===
                                        levelTwoData.levelTwoID
                                          ? {
                                              borderBottom: '1px solid #eaeaea',
                                            }
                                          : {}
                                      }
                                      onChange={(e) =>
                                        this.handleKeywordChange(
                                          e,
                                          levelTwoData.levelTwoID
                                        )
                                      }
                                      onKeyPress={(e) =>
                                        this.handleKeywordKeyPressLevelTwo(
                                          e,
                                          levelOneData.levelOneID,
                                          levelTwoData.levelTwoID
                                        )
                                      }
                                    />
                                  </div>

                                  <div className={styles.subissue_pencil_icon}>
                                    {currentInputHovered ===
                                    levelTwoData.levelTwoID ? (
                                      <IconContext.Provider
                                        value={{
                                          color: '#2c2c2c',
                                          size: 15,
                                        }}
                                      >
                                        <BsPencil />
                                      </IconContext.Provider>
                                    ) : null}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {mainIssueSelectedId === levelOneData.levelOneID && (
                        <div
                          className={styles.add_sub_issue_card}
                          onClick={() =>
                            this.addSubIssueNode(levelOneData.levelOneID)
                          }
                        >
                          Add +
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className={styles.add_middle_cards}>
                <div className={styles.main_issue_cards_container}>
                  <div
                    className={styles.middle_line}
                    style={{ border: '1px solid #eaeaea' }}
                  ></div>
                  <div
                    className={styles.add_main_issue_card}
                    onClick={this.addMainNode}
                  >
                    Add +
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomNode;
