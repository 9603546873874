import * as types from "../types";

const initialState = {
  isLoading: false,
  error: null,
  tickets: [],
  isTicketClosed: false,
  isTicketCreated: false,
  isTicketUpdated: false,
  message: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_ALL_TICKETS:
      return {
        ...state,
        isLoading: true,
        tickets: []
      };
    case types.ALL_TICKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        tickets: action.payload,
      };
    case types.ALL_TICKETS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.REQUEST_TICKET_CREATE:
      return {
        ...state,
        isTicketCreated: true,
      };
    case types.TICKET_CREATE_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        error:null,
        tickets: action.payload.result.data,
        message: action.payload.result.message
      };
    case types.TICKET_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case types.REQUEST_TICKET_UPDATE:
      return {
        ...state,
        isTicketUpdated: true,
      };
    case types.TICKET_UPDATE_SUCCESS:
      return {
        ...state,
        isTicketUpdated: false,
        error: null,
      };
    case types.TICKET_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.REQUEST_TICKET_DELETE:
      return {
        ...state,
        error: null,
        isTicketClosed: true,
      };
    case types.TICKET_DELETE_SUCCESS:
      return {
        ...state,
        error: null,
        isTicketClosed: false,
      };
    case types.TICKET_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state
  }
};
