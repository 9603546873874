import * as types from '../types';

const initialState = {
  catalogAllFoldersTreeData: null,
  catalogAllFoldersContentData: null,
  isCatalogAllFoldersTreeDataLoading:false,
  IsCatalogAllFoldersContentLoading:false,
  error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // Catalog All folders tree Data Actions
    case types.CATALOG_ALL_FOLDERS_TREE_DATA_REQUEST:
      return {
        ...state,
        catalogAllFoldersTreeData: null,
        isCatalogAllFoldersTreeDataLoading: true,
      };
    case types.CATALOG_ALL_FOLDERS_TREE_DATA_SUCCESS: {
      return {
        ...state,
        isCatalogAllFoldersTreeDataLoading: false,
        catalogAllFoldersTreeData: action.payload.result.data,
      };
    }
    case types.CATALOG_ALL_FOLDERS_TREE_DATA_FAILURE:
      return {
        ...state,
        isCatalogAllFoldersTreeDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };


      // Catalog all folders content Actions
    case types.CATALOG_ALL_FOLDERS_CONTENT_REQUEST:
        return {
          ...state,
          catalogAllFoldersContentData: null,
          IsCatalogAllFoldersContentLoading: true,
        };
      case types.CATALOG_ALL_FOLDERS_CONTENT_SUCCESS: {
        return {
          ...state,
          IsCatalogAllFoldersContentLoading: false,
          catalogAllFoldersContentData: action.payload.result.data,
        };
      }
      case types.CATALOG_ALL_FOLDERS_CONTENT_FAILURE:
        return {
          ...state,
          IsCatalogAllFoldersContentLoading: false,
          error: action.payload.result || defaultErrorMessage,
        };


    default: {
      return state;
    }
  }
};
