import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as teamManagement from '../actions/teamManagementActions';

function* teamListWorker(params) {
    try {
        const data = yield call(_api, URLS.requestTeamList, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(teamManagement.teamListSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(teamManagement.teamListFailure(e));
    }
}
function* addTeamMemberWorker(params) {
    try {
        const data = yield call(_api, URLS.requestAddTeamMember, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(teamManagement.addTeamMemberSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(teamManagement.addTeamMemberFailure(e));
    }
}
function* createTeamWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCreateTeam, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(teamManagement.createTeamSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(teamManagement.createTeamFailure(e));
    }
}

function* deleteTeamMemberWorker(params) {
    try {
        const data = yield call(_api, URLS.requestDeleteTeamMember, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(teamManagement.deleteTeamMemberSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(teamManagement.deleteTeamMemberFailure(e));
    }
}

function* teamRoleWorker(params) {
    try {
        const data = yield call(_api, URLS.requestDropdown, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(teamManagement.teamRoleSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(teamManagement.teamRoleFailure(e));
    }
}

function* updateTeamMemberWorker(params) {
    try {
        const data = yield call(_api, URLS.requestUpdateTeamMember, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(teamManagement.updateTeamMemberSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(teamManagement.updateTeamMemberFailure(e));
    }
}
function* teamListWatcher() {
    yield takeLatest(types.TEAM_LIST_REQUEST, teamListWorker);
}

function* addTeamMemberWatcher() {
    yield takeLatest(types.ADD_TEAM_MEMBER_REQUEST, addTeamMemberWorker);
}
function* createTeamWatcher() {
    yield takeLatest(types.CREATE_TEAM_REQUEST, createTeamWorker);
}
function* deleteTeamMemberWatcher() {
    yield takeLatest(types.DELETE_TEAM_MEMBER_REQUEST, deleteTeamMemberWorker);
}
function* teamRoleWatcher() {
    yield takeLatest(types.TEAM_ROLE_REQUEST, teamRoleWorker);
}
function* updateTeamMemberWatcher() {
    yield takeLatest(types.UPDATE_TEAM_MEMBER_REQUEST, updateTeamMemberWorker);
}
export default function* teamManagementSaga() {
    yield all([
        teamListWatcher(),
        addTeamMemberWatcher(),
        createTeamWatcher(),
        deleteTeamMemberWatcher(),
        teamRoleWatcher(),
        updateTeamMemberWatcher()

    ]);
}