import React, { Component } from "react";
import styles from "./ClusteredResultDocInfo.module.css";
import { connect } from 'react-redux';
// import commonStyles from '../../../shared/Common.module.css';
class ClusteredResultDocInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredTags: [],
            selectedTags: []
        }
    }
    componentDidMount(){
        this.setState({
            filteredTags: this.props.uniqueTags,
        })
    }
    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps.uniqueTags)!==JSON.stringify(this.props.uniqueTags))
        this.setState({
            filteredTags: this.props.uniqueTags,
        })
    }
    handleTagSearch = (event) => {
        let searchItems = event.target.value
        let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        let filteredTags = this.props.uniqueTags.filter(tag => {
            return tag.toLowerCase().search(
                subStr.toLowerCase()
            ) !== -1;
        });
        this.setState({
            filteredTags: filteredTags
        })
    }
    handleTagSelection = (tag) => {
        this.props.handleTagSelection(tag)
    }
    getSelectedTagStyle(tag) {
        if (this.props.selectedTags && this.props.selectedTags[tag]) {
            return ({ border: '2px solid #144192' })
        }
        else
            return null
    }
    render() {
        const { filteredTags } = this.state
        return (
            <div className={styles.doc_info_container}>
                <div className={styles.two_column_row}>
                    <div>
                        <div className={styles.doc_name}>
                            <span className={styles.heading}>NAME <br></br></span><span className={styles.heading_value}>{this.props.uploadedDoc.clusterData.data.fileVersion.orgFileName}</span>
                        </div>
                        <div className={styles.uploaded_by_row}>
        <span className={styles.heading}>  UPLOADED BY </span> <br></br><span className={styles.heading_value}>{this.props.uploadedDoc.clusterData.data.fileVersion.uploadedBy}</span>
                        </div>
                    </div>
                    <div className={styles.doc_status}>
        <span>{this.props.uploadedDoc.clusterData.data.fileVersion.fileStatus}</span>
                    </div>
                </div>
                <hr></hr>
                <div className={styles.two_column_row}>
                    <div className={styles.heading}>
                        Permissions
    </div>
                    <div className={styles.heading}>
                        {this.props.uploadedDoc.clusterData.data.fileVersion.permission}
    </div>
                </div>
                <hr></hr>
                <div className={styles.tags_row}>
                    <div className={styles.tags_heading}>
                        Tags
    </div>
                    <div className={styles.tag_search}>
                        <input type="text"
                            id="tagSearch"
                            name="tagSearch"
                            placeholder="Search Tags"
                            onChange={(event) => this.handleTagSearch(event)}></input>
                    </div>
                </div>
                <div className={styles.tag_container}>
                    {filteredTags.map((tag, i) => {
                        return (
                            <div style={this.getSelectedTagStyle(tag)} onClick={() => this.handleTagSelection(tag)} key={i} className={styles.tag}>{tag}</div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        // uploadDoc: state.uploadDoc
        uploadedDoc: state.uploadedDoc,
    };
}
export default connect(mapStateToProps, null)(ClusteredResultDocInfo);
