import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { IconContext } from 'react-icons'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import OutsideClickHandler from 'react-outside-click-handler'

import * as notification from '../../store/actions/notificationAction'
import * as homepage from '../../store/actions/homepageActions'

import NavBar from './../workflow-management/NavBar/NavBar'
import Sidebar from './../workflow-management/Sidebar/Sidebar'
import RightBar from './../workflow-management/RightBar/RightBar'
import Loader from '../../shared/loader/Loader'

import NotificationBtnSVG from './../../assets/mark_as_read_green.svg'
import NotificationBtnGreySVG from './../../assets/mark_as_read_grey.svg'
import FileBlackSVG from './../../assets/file_black.svg'
import ArrowUp from '../../../src/assets/arrow-up-grey.svg'

import { data } from './../../json-data/notification-v2.json'

import '../../css/react-setup.css'
import commonStyles from '../../shared/Common.module.css'
import styles from './Notification.module.css'
class Notification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchItem: '',
      notifications: [],
      isDisabled: true,
      notificationsRead: [],
      allNotificationsAreRead: false,
      openedOptionsMenu: null,
      newNotifications: 0,
    }
  }

  componentDidMount() {
    this.props.fetchAllNotifications(this.props.homepage.userDetails.userId)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.props.notifications.messages?.length > 0) {
        const allNotificationsAreRead = this.props.notifications.new === 0
        this.setState({
          notifications: this.props.notifications.messages,
          allNotificationsAreRead,
          newNotifications: this.props.notifications.new,
        })
      } else {
        // this.setState({ isLoading: false })
      }
    }
    if (prevProps.newNotificationId !== this.props.newNotificationId) {
      this.props.fetchAllNotifications(this.props.homepage.userDetails.userId)
    }
    if (
      prevProps.isNotificationRemoveLoading &&
      !this.props.isNotificationRemoveLoading &&
      this.props.notificationRemoveData
    ) {
      const removedMessageId = this.props.notificationRemoveData.messageId
      let { notifications } = this.state

      notifications = notifications.filter(
        notification => notification.messageId !== removedMessageId
      )
      this.setState(
        {
          notifications,
        },
        () => {
          this.handleOptionsMenu(null)
        }
      )
    }
    if (
      prevProps.markNotificationLoading &&
      !this.props.markNotificationLoading &&
      this.props.markNotificationReadData
    ) {
      const newNotifications = this.props.markNotificationReadData.new
      this.setState({
        newNotifications,
      })
    }
  }

  handleMarkAllRead = () => {
    const { notifications, notificationsRead } = this.state

    const ids = notifications
      .filter(msg => {
        return notificationsRead.includes(msg.messageId) === false
      })
      .map(notification => notification.messageId)

    this.setState(
      (prevState, prevProps) => ({
        notificationsRead: [...prevState.notificationsRead, ...ids],
        allNotificationsAreRead: true,
        newNotifications: 0,
      }),
      () => {
        this.props.markAllNotificationsRead(ids)
        this.props.saveUnreadNotification(false)
      }
    )
  }
  handleAsRead = id => {
    this.props.markNotificationAsRead(id)
  }
  handleBackNavigation = () => {
    this.props.history.goBack()
  }
  notificationRead = messageId => {
    const { notifications, notificationsRead } = this.state
    if (notificationsRead.includes(messageId)) return

    const notification = notifications.filter(
      notification => notification.messageId === messageId
    )
    if (notification[0].markAsRead) return

    notificationsRead.push(messageId)
    this.setState(
      {
        notificationsRead,
      },
      () => {
        this.handleAsRead(messageId)
      }
    )
  }

  notificationReadFromOptionsMenu = messageId => {
    const { notificationsRead } = this.state
    if (notificationsRead.includes(messageId)) return
    notificationsRead.push(messageId)

    this.setState(
      {
        notificationsRead,
        openedOptionsMenu: null,
      },
      () => {
        this.handleAsRead(messageId)
      }
    )
  }

  createMessage = msg => {
    return { __html: msg }
  }

  handleOptionsMenu = messageId => {
    this.setState({
      openedOptionsMenu: messageId,
    })
  }

  handleDeleteNotification = messageId => {
    this.props.removeNotificationRequest({
      messageId,
    })
  }

  render() {
    const {
      notifications,
      isDisabled,
      notificationsRead,
      allNotificationsAreRead,
      openedOptionsMenu,
      newNotifications,
    } = this.state

    const { isLoading } = this.props

    return (
      <>
        <NavBar />
        <Sidebar />
        <RightBar />
        <div
          className={`${commonStyles.common_workflow_management_wrapper} ${styles.root}`}
        >
          <div className={styles.relative}>
            <div
              className={styles.back_icon}
              onClick={this.handleBackNavigation}
            >
              <img src={ArrowUp} alt="Arrow Up" />
            </div>
          </div>

          <div className={styles.header}>
            <span>Notifications {`(${newNotifications})`}</span>
            <img
              className={styles.markAllAsReadImg}
              src={
                allNotificationsAreRead
                  ? NotificationBtnGreySVG
                  : NotificationBtnSVG
              }
              alt="Notification Button"
              onClick={this.handleMarkAllRead}
            />
          </div>

          <div className={styles.main_card}>
            {isLoading ? (
              <Loader />
            ) : notifications?.length > 0 ? (
              notifications.map(msg => {
                const duration = moment.duration(
                  moment(Date.now()).diff(moment(msg.date))
                )

                // console.info('duration: ', duration.asHours())
                // console.info('duration: ', duration.asDays())
                let timePeriod = Math.round(duration.asHours())
                if (timePeriod > 24) timePeriod = Math.round(duration.asDays())

                return (
                  <div
                    className={styles.notification_card}
                    key={msg.messageId}
                    style={
                      notificationsRead.includes(msg.messageId) ||
                      msg.markAsRead
                        ? { backgroundColor: '#fff' }
                        : { backgroundColor: '#fafafa' }
                    }
                  >
                    <img src={FileBlackSVG} alt="File Black Icon" />
                    <div
                      className={styles.content}
                      onClick={() => this.notificationRead(msg.messageId)}
                    >
                      <div className={styles.heading}>{msg.heading}</div>
                      <div
                        className={styles.subheading}
                        dangerouslySetInnerHTML={this.createMessage(
                          msg.message
                        )}
                      ></div>
                      <div className={styles.time_period}>{msg.date}</div>
                    </div>
                    <div className={styles.three_dots_icon}>
                      <div
                        onClick={() => this.handleOptionsMenu(msg.messageId)}
                      >
                        <IconContext.Provider
                          value={{
                            size: 21,
                            color: '#7a7a7a',
                          }}
                        >
                          <BiDotsVerticalRounded />
                        </IconContext.Provider>
                      </div>

                      {openedOptionsMenu === msg.messageId && (
                        <OutsideClickHandler
                          onOutsideClick={() => this.handleOptionsMenu(null)}
                        >
                          <div className={styles.options_panel}>
                            {!msg.markAsRead && !allNotificationsAreRead && (
                              <div
                                onClick={() =>
                                  this.notificationReadFromOptionsMenu(
                                    msg.messageId
                                  )
                                }
                              >
                                Mark as read
                              </div>
                            )}
                            <div
                              onClick={() =>
                                this.handleDeleteNotification(msg.messageId)
                              }
                            >
                              Delete
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}

                      {/*  <div className={styles.notification_tooltip}>
                    <div>Lorem ipsum 1</div>
                    <div>Lorem ipsum </div>
                  </div> */}
                    </div>
                  </div>
                )
              })
            ) : (
              <p style={{ textAlign: 'center', marginTop: '20px' }}>
                No Notification Yet!
              </p>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  // console.info('state: ', state)
  return {
    homepage: state.homepage,
    notifications: state.notifications,
    isLoading: state.notifications.isLoading,
    markAllNotificationLoading: state.notifications.markAllNotificationLoading,
    markNotificationLoading: state.notifications.markNotificationLoading,
    markNotificationReadData: state.notifications.markNotificationReadData,
    newNotificationId: state.homepage.newNotificationId,
    isNotificationRemoveLoading:
      state.notifications.isNotificationRemoveLoading,
    notificationRemoveData: state.notifications.notificationRemoveData,
  }
}

const mapDispatchToProps = {
  fetchAllNotifications: notification.fetchAllNotificationsRequest,
  markAllNotificationsRead: notification.markAllNotificationsReadRequest,
  removeNotificationRequest: notification.removeNotificationRequest,
  saveUnreadNotification: homepage.saveUnreadNotification,
  markNotificationAsRead: notification.markNotificationReadRequest,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
