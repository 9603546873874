import React from 'react'
import { connect } from 'react-redux'
import * as homepage from '../../store/actions/homepageActions'
// import styles from './Homepage.module.css';
import commonStyles from '../../shared/Common.module.css'
import HomeLeftSection from '../../components/homepage/home-left-section/HomeLeftSection'
import Login from '../../components/homepage/Login/Login'
import Signup from '../../components/homepage/signup/Signup'
import ForgotPassword from '../../components/homepage/forgot-password/ForgotPassword'
import styles from './Homepage.module.css'
class Homepage extends React.Component {
  componentDidMount() {
    // if (this.props.history.length !== 0) {
    //   console.info('component did mount')
    //   window.location.reload()
    // }
  }

  componentDidUpdate() {
    if (this.props.error) {
      this.props.triggerErrorToast(
        this.props.error,
        this.props.clearHomepageError
      )
    }
    if (this.props.successMessage) {
      this.props.triggerSuccessToast(
        this.props.successMessage,
        this.props.clearHomepageError
      )
    }
  }

  getRightSectionComponent = pathname => {
    switch (pathname) {
      case '/signup':
        return (
          <Signup
            history={this.props.history}
            onSignup={this.props.requestSignup}
            onEmailExist={this.props.requestEmailExist}
            isSignUpSuccess={this.props.isSignUpSuccess}
          />
        )
      case '/forgot-password':
        return (
          <ForgotPassword
            history={this.props.history}
            onforgotPassword={this.props.requestForgotPassword}
          ></ForgotPassword>
        )
      default:
        return (
          <Login
            history={this.props.history}
            onLogin={this.props.requestLogin}
          />
        )
    }
  }

  render() {
    // console.info('history: ', this.props.history)
    return (
      <div style={{display:'flex'}}>
        {/* <div className={`row ${commonStyles.no_gutter}`}> */}
          <div className={styles.homePageLeft}>
            <HomeLeftSection />
          </div>
          <div className={styles.homePageRight}>
            {this.getRightSectionComponent(this.props.location.pathname)}
          </div>
        {/* </div> */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    homepage: state.homepage,
    error: state.homepage.error,
    successMessage: state.homepage.successMessage,
    isSignUpSuccess: state.homepage.isSignUpSuccess,
  }
}

const mapDispatchToProps = {
  requestLogin: homepage.requestLogin,
  requestSignup: homepage.requestSignup,
  requestEmailExist: homepage.emailExistRequest,
  requestForgotPassword: homepage.forgotPasswordRequest,
  clearHomepageError: homepage.clearHomepageError,
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage)
