import * as types from '../types';

const initialState = {
  documentAnalyticsHistoryGetData: null,
  documentAnalyticsHistoryCreateData: null,
  isDocumentAnalyticsHistoryGetDataLoading: false,
  isDocumentAnalyticsHistoryCreateDataLoading: false,
  paraFeedbackAdd: null,
  isParaFeedbackAddLoading: false,
  error: null,
  allMainSubTags: null,
  isAllMainSubTgsLoading: false,
  similarParaData: null,
  isSimilarParaDataLoading: false,
  similarDocData: null,
  isSimilarDocDataLoading: false,
  updateFileStatus: null,
  isUpdatingFileStatus: false,
  isFileTrackLoading: false,
  fileTrackData: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // get document analytics history data
    case types.DOCUMENT_ANALYTICS_HISTORY_GET_REQUEST:
      return {
        ...state,
        documentAnalyticsHistoryGetData: null,
        isDocumentAnalyticsHistoryGetDataLoading: true,
      };
    case types.DOCUMENT_ANALYTICS_HISTORY_GET_SUCCESS: {
      return {
        ...state,
        isDocumentAnalyticsHistoryGetDataLoading: false,
        documentAnalyticsHistoryGetData: action.payload.result.data,
      };
    }
    case types.DOCUMENT_ANALYTICS_HISTORY_GET_FAILURE:
      return {
        ...state,
        isDocumentAnalyticsHistoryGetDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // create document analytics history data
    case types.DOCUMENT_ANALYTICS_HISTORY_CREATE_REQUEST:
      return {
        ...state,
        documentAnalyticsHistoryCreateData: null,
        isDocumentAnalyticsHistoryCreateDataLoading: true,
      };
    case types.DOCUMENT_ANALYTICS_HISTORY_CREATE_SUCCESS: {
      return {
        ...state,
        isDocumentAnalyticsHistoryCreateDataLoading: false,
        documentAnalyticsHistoryCreateData: action.payload.result.data,
      };
    }
    case types.DOCUMENT_ANALYTICS_HISTORY_CREATE_FAILURE:
      return {
        ...state,
        isDocumentAnalyticsHistoryCreateDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // para feedback add
    case types.PARA_FEEDBACK_ADD_REQUEST:
      return {
        ...state,
        paraFeedbackAdd: null,
        isParaFeedbackAddLoading: true,
      };
    case types.PARA_FEEDBACK_ADD_SUCCESS: {
      return {
        ...state,
        isParaFeedbackAddLoading: false,
        paraFeedbackAdd: action.payload.result.data,
      };
    }
    case types.PARA_FEEDBACK_ADD_FAILURE:
      return {
        ...state,
        isParaFeedbackAddLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // para feedback get all main and sub tags
    case types.ALL_MAIN_SUB_TAGS_REQUEST:
      return {
        ...state,
        allMainSubTags: null,
        isAllMainSubTgsLoading: true,
      };
    case types.ALL_MAIN_SUB_TAGS_SUCCESS: {
      return {
        ...state,
        isAllMainSubTgsLoading: false,
        allMainSubTags: action.payload.result.data,
      };
    }
    case types.ALL_MAIN_SUB_TAGS_FAILURE:
      return {
        ...state,
        isAllMainSubTgsLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // get all similar paras
    case types.SIMILAR_PARA_DATA_REQUEST:
      return {
        ...state,
        similarParaData: null,
        isSimilarParaDataLoading: true,
      };
    case types.SIMILAR_PARA_DATA_SUCCESS: {
      return {
        ...state,
        isSimilarParaDataLoading: false,
        similarParaData: action.payload.result.data,
      };
    }
    case types.SIMILAR_PARA_DATA_FAILURE:
      return {
        ...state,
        isSimilarParaDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // get all similar docs
    case types.SIMILAR_DOC_DATA_REQUEST:
      return {
        ...state,
        similarDocData: null,
        isSimilarDocDataLoading: true,
      };
    case types.SIMILAR_DOC_DATA_SUCCESS: {
      return {
        ...state,
        isSimilarDocDataLoading: false,
        similarDocData: action.payload.result.data,
      };
    }
    case types.SIMILAR_DOC_DATA_FAILURE:
      return {
        ...state,
        isSimilarDocDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // Update file status
    case types.UPDATE_FILE_STATUS_REQUEST:
      return {
        ...state,
        updateFileStatus: null,
        isUpdatingFileStatus: true,
      };
    case types.UPDATE_FILE_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdatingFileStatus: false,
        updateFileStatus: action.payload.result.data,
      };
    }
    case types.UPDATE_FILE_STATUS_FAILURE:
      return {
        ...state,
        isUpdatingFileStatus: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // file track
    case types.FILE_TRACK_REQUEST:
      return {
        ...state,
        fileTrackData: null,
        isFileTrackLoading: true,
      };
    case types.FILE_TRACK_SUCCESS: {
      return {
        ...state,
        isFileTrackLoading: false,
        fileTrackData: action.payload.result.data,
      };
    }
    case types.FILE_TRACK_FAILURE:
      return {
        ...state,
        isFileTrackLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    default: {
      return state;
    }
  }
};
