import React, { Component } from 'react'
import styles from './ChangePassword.module.css'
import commonStyles from '../../../shared/Common.module.css'
import ProgressBar from 'react-bootstrap/ProgressBar'
import ErrorText from '../../../shared/error-text/ErrorText'
import { handleError } from '../../../shared/HandleMessages'

//Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
const STRONG_PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
)
// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
const MEDIUM_PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/
)
// Minimum eight characters, at least one letter, one number and one special character:
const WEAK_PASSWORD_REGEX = new RegExp(
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,}$/
)

const ALPHA_NUMERIC_REGEX = new RegExp(/^[a-zA-Z0-9]{4,}$/i)
// new RegExp(/^[a-zA-Z0-9]{4,}+$/)

class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      reEnterPassword: '',
      passwordStrengthMeter: 0,
      showError: false,
      formsInvalid: true,
      errors: {
        newPassword: 'Enter new password',
        reEnterPassword: '',
        oldPassword: 'Enter old password',
      },
    }
  }

  handleUserInput = e => {
    const name = e.target.name
    const value = e.target.value
    let errors = this.state.errors
    let newPassword = this.state.newPassword
    let formsInvalid = false
    if (name === 'newPassword') {
      const passwordStrength = this.getPasswordStrength(value)
      this.setState({ passwordStrengthMeter: passwordStrength })
    }
    switch (name) {
      case 'oldPassword':
        errors.oldPassword =
          value.length < 8 ? 'Password must be 8 characters long!' : ''
        formsInvalid = errors.oldPassword.length > 0 ? true : false
        break
      case 'newPassword':
        errors.newPassword = STRONG_PASSWORD_REGEX.test(value)
          ? ''
          : 'Password is too weak!'
        formsInvalid = STRONG_PASSWORD_REGEX.test(value) ? false : true
        break
      case 'reEnterPassword':
        errors.reEnterPassword =
          newPassword === value ? '' : "Password don't match!"
        formsInvalid = newPassword === value ? false : true
        break
      default:
        break
    }
    this.setState({ errors, [name]: value })
    this.setState({ formsInvalid: formsInvalid })
  }

  getPasswordStrength(password) {
    let passwordStrength = 0

    if (STRONG_PASSWORD_REGEX.test(password)) {
      passwordStrength = 100
    } else if (MEDIUM_PASSWORD_REGEX.test(password)) {
      passwordStrength = 80
    } else if (WEAK_PASSWORD_REGEX.test(password)) {
      passwordStrength = 60
    } else if (ALPHA_NUMERIC_REGEX.test(password)) {
      passwordStrength = 40
    } else if (password.length >= 2) {
      passwordStrength = 20
    }
    return passwordStrength
  }

  handleChangePassword = () => {
    const {
      formsInvalid,
      oldPassword,
      newPassword,
      reEnterPassword,
      passwordStrengthMeter,
    } = this.state
    if (passwordStrengthMeter < 100) {
      handleError('password is weak')
      return
    }
    if (
      formsInvalid ||
      !oldPassword ||
      !newPassword ||
      reEnterPassword !== newPassword
    ) {
      if (reEnterPassword !== newPassword) {
        this.setState({ formsInvalid: true })
        handleError("password don't match")
      }
      this.setState({ showError: true })
    } else {
      this.setState({ showError: false })
      const paramsObj = {
        old_password: oldPassword,
        password: newPassword,
        confirm_password: newPassword,
      }
      this.props.onchangePassword(paramsObj)
    }
  }
  
  render() {
    const {
      oldPassword,
      newPassword,
      reEnterPassword,
      passwordStrengthMeter,
      showError,
      errors,
    } = this.state
    return (
      <div>
        <div className={styles.change_password_container}>
          <div className={styles.change_password_form_section}>
            <form>
              <p className={commonStyles.common_form_label}>
                ENTER OLD PASSWORD
              </p>
              <input
                className={`form-control ${commonStyles.common_form_text_field}`}
                type="password"
                name="oldPassword"
                value={oldPassword}
                onChange={this.handleUserInput}
              ></input>
              {showError && errors.oldPassword && (
                <ErrorText text={errors.oldPassword} />
              )}
              <p className={commonStyles.common_form_label}>
                ENTER NEW PASSWORD
              </p>
              <input
                className={`form-control ${commonStyles.common_form_text_field}`}
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={this.handleUserInput}
              ></input>
              {showError && errors.newPassword && (
                <ErrorText text={errors.newPassword} />
              )}
              <div className={styles.password_strength_container}>
                <span className={styles.password_strength_label}>
                  Password Strength:{' '}
                </span>

                <div className="password_strength_progress_container">
                  <ProgressBar now={passwordStrengthMeter} />
                </div>
              </div>

              <p className={commonStyles.common_form_label}>
                RE-ENTER PASSWORD
              </p>
              <input
                className={`form-control ${commonStyles.common_form_text_field}`}
                type="password"
                name="reEnterPassword"
                value={reEnterPassword}
                onChange={this.handleUserInput}
              ></input>
              {showError && errors.reEnterPassword && (
                <ErrorText text={errors.reEnterPassword} />
              )}
              <div className={styles.edit_profile_submit_container}>
                <button
                  onClick={this.handleChangePassword}
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
          <div className={styles.password_rules_container}>
            <div className={styles.password_rules_section}>
              <p>Password Rules</p>
              <div className={styles.password_rules}>
                <p>* Min 8 characters</p>
                <p>* Upper-case characters</p>
                <p>* Lower-case characters</p>
                <p>* Number 0-9</p>
                <p>* Special characters (Eg.,!%^&*)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ChangePassword
