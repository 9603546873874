import React from 'react';
import styles from './AddSourceModal.module.css';
import CommonModal from '../../../shared/common-modal/CommonModal';
import BlueDocImg from '../../../assets/blue_document.svg';
import GreenDocImg from '../../../assets/green_document.svg';
import commonStyles from '../../../shared/Common.module.css';
import * as filesStatus from '../../../store/actions/fileStatusAction';
import { connect } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';
import minimizeIcon from '../../../assets/minimize_icon.svg';
import { handleSuccess } from '../../../shared/HandleMessages';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class ProgressModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      readyFilesCount: 0,
      uploadingFileCount: 0,
      clusteringFileCount: 0,
      totalFileCount: 0,
      messageShown: false,
    };
  }

  componentDidMount() {
    setTimeout(this.requestFiles(), 10000);
    this.interval = setInterval(this.requestFiles, 20000);
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  requestFiles = async () => {
    await this.props.filesStatusRequest({
      userId: this.props.userId,
      trackId: this.props.trackId,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.filesStatus.isLoading &&
      !this.props.isLoading &&
      this.props.filesStatus.filesStatus
    ) {
      this.setState({ data: this.props.filesStatus.filesStatus.fileStatus });
      this.countFiles(this.props.filesStatus.filesStatus.fileStatus);
    }
    if (
      this.state.data.length > 0 &&
      this.state.readyFilesCount === this.state.data.length &&
      !this.state.messageShown
    ) {
      handleSuccess('All files has been clustered.');
      this.setState({ messageShown: true });
    }
  }

  countFiles = (data) => {
    let uploading = 0;
    let clustering = 0;
    let ready = 0;
    let total = 0;
    data.forEach((item) => {
      if (item.status === 'clustered') {
        ready += 1;
        total += 1;
      } else if (item.status === 'clustering') {
        clustering += 1;
        total += 1;
      } else {
        uploading += 1;
        total += 1;
      }
    });
    // console.log(uploading, clustering, ready);
    this.setState({
      uploadingFileCount: uploading,
      clusteringFileCount: clustering,
      readyFilesCount: ready,
      totalFileCount: total,
    });
  };

  render() {
    const { handleModalCancel } = this.props;
    const {
      data,
      readyFilesCount,
      totalFileCount,
      uploadingFileCount,
      clusteringFileCount,
    } = this.state;
    // console.log(data);
    return (
      <CommonModal
        modalClass={'source_file_progress_modal'}
        backdropClassName={'data_source_model_blue_bg'}
        // closeHandler={handleModalCancel}
        // header={' '}
      >
        <div className={styles.progress_modal_container}>
          <div className={styles.progress_modal_heading_container}>
            <h5 className={`${styles.progress_modal_heading}`}>
              Please wait while we get
              <br /> your files ready.
            </h5>
            {/* <p className={`${styles.progress_modal_time_info}`}>ETA 5 MINS</p> */}
            <img
              className={styles.progress_modal_minimize_icon}
              src={minimizeIcon}
              alt="minimize"
              onClick={handleModalCancel}
            />
          </div>
          <hr className={`${styles.horizontal_line}`} />

          {!data.length ? (
            <BeatLoader
              css={override}
              size={25}
              color={'#11256D'}
              loading={true}
            />
          ) : (
            <div className={styles.progress_modal_section_container}>
              <div className={styles.progress_modal_section_wrapper}>
                <div
                  className={styles.progress_modal_section_heading_container}
                >
                  <p className={styles.progress_modal_section_heading_left}>
                    Uploaded...
                  </p>
                  <p className={styles.progress_modal_section_heading_right}>
                    {uploadingFileCount ? uploadingFileCount : 0}/
                    {totalFileCount}
                  </p>
                </div>
                <hr />
                <div className={styles.file_list_container}>
                  {data.length > 0 &&
                    data.map((file, i) => {
                      return file.status === 'uploaded' ||
                        file.status === 'error' ? (
                        <div className={styles.file_info_wrapper} key={i}>
                          <div className={styles.progress_container}>
                            <div className={styles.progress_percent}></div>
                          </div>
                          <div className={styles.file_info_container}>
                            <img src={BlueDocImg} alt="file icon" />
                            <p className={styles.file_name}>
                              {file.fileName.replace('%', ' ')}
                            </p>
                          </div>
                          <div className={styles.progress_container}>
                            <div className={styles.progress_percent_grey}></div>
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              </div>

              <div className={styles.progress_modal_section_wrapper}>
                <div
                  className={styles.progress_modal_section_heading_container}
                >
                  <p className={styles.progress_modal_section_heading_left}>
                    Clustering...
                  </p>
                  <p className={styles.progress_modal_section_heading_right}>
                    {clusteringFileCount ? clusteringFileCount : 0}/
                    {totalFileCount}
                  </p>
                </div>
                <hr />
                {clusteringFileCount || readyFilesCount === totalFileCount ? (
                  <div className={styles.file_list_container}>
                    {data.length > 0 &&
                      data.map((file, i) => {
                        return file.status === 'clustering' ? (
                          <div className={styles.file_info_wrapper} key={i}>
                            <div className={styles.progress_container}>
                              <div
                                className={styles.progress_percent_green}
                              ></div>
                            </div>
                            <div className={styles.file_info_container}>
                              <img src={GreenDocImg} alt="file icon" />
                              <p className={styles.file_name}>
                                {file.fileName.replace('%', ' ')}
                              </p>
                            </div>
                            <div className={styles.progress_container}>
                              <div
                                className={styles.progress_percent_grey}
                              ></div>
                            </div>
                          </div>
                        ) : null;
                      })}
                  </div>
                ) : (
                  <BeatLoader
                    css={override}
                    size={25}
                    color={'#11256D'}
                    loading={true}
                  />
                )}
              </div>

              <div className={styles.progress_modal_section_wrapper}>
                <div
                  className={styles.progress_modal_section_heading_container}
                >
                  <p className={styles.progress_modal_section_heading_left}>
                    Ready For Search
                  </p>
                  <p className={styles.progress_modal_section_heading_right}>
                    {readyFilesCount ? readyFilesCount : 0}/{totalFileCount}
                  </p>
                </div>
                <hr />
                {readyFilesCount && data.length ? (
                  <div className={styles.file_list_container}>
                    {data.length > 0 &&
                      data.map((file, i) => {
                        return file.status === 'clustered' ? (
                          <div className={styles.file_info_wrapper} key={i}>
                            <div className={styles.file_info_container}>
                              <img src={GreenDocImg} alt="file icon" />
                              <p className={styles.file_name}>
                                {file.fileName.split('%').join(' ')}
                              </p>
                            </div>
                          </div>
                        ) : null;
                      })}
                  </div>
                ) : (
                  <BeatLoader
                    css={override}
                    size={25}
                    color={'#11256D'}
                    loading={true}
                  />
                )}
              </div>
            </div>
          )}

          <div className={`${styles.progress_modal_bottom_container}`}>
            <p className={`${styles.bottom_text}`}>
              {clusteringFileCount + uploadingFileCount} Files being processed
            </p>
            <button
              onClick={handleModalCancel}
              className={`${styles.progress_modal_cancel_all_btn} btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
            >
              DONE
            </button>
          </div>
        </div>
      </CommonModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.homepage.userDetails.userId,
    filesStatus: state.filesStatus,
    isLoading: state.dataSourceAuth.isLoading,
  };
};

const mapDispatchToProps = {
  filesStatusRequest: filesStatus.catalogFilesStatusRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressModal);
