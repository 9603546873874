import React from 'react';
import SplitArrowSVG from './../../../assets/split_arrow.svg';
import { Pane } from 'react-split-pane';
import styles from './DocumentAnalytics.module.css';
import { IconContext } from 'react-icons';
import { IoIosArrowDown } from 'react-icons/io';
import MenuSVG from './../../../assets/menu_grey.svg';
import RecentDocument from './recent-documents/RecentDocuments';
// import RelatedDocument from './related-documents/RelatedDocuments';
// import FileViewer from 'react-file-viewer';
import { Document, Page } from 'react-pdf';
import Loader from '../../../shared/loader/Loader';
import { fileData } from './../../../json-data/documentAnalytics.json';

class DragDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: 'related_document',
      similarDocs: fileData.result.data,
      selectedFile: 0,
      showDragBtn: false,
      showFileUrl: '',
      searchData: this.props.similarDocs,
    };
  }

  // componentDidMount() {
  //   console.log(this.props);
  //   let url = '';
  //   this.props.similarDocs.forEach((doc) => {
  //     if (doc.fileId === this.props.selectedSimilarDoc) {
  //       url = doc.filePath;
  //     }
  //   });
  //   this.setState({
  //     showFileUrl: url,
  //   });
  // }

  handleSelectChange = (event) => {
    this.setState({ selectedValues: event.target.value });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  renderPages = () => {
    return Array.from({ length: this.state.numPages }, (el, i) => {
      return <Page loading={''} pageNumber={i + 1} />;
    });
  };

  renderLoader = () => {
    return <Loader></Loader>;
  };

  handleSelectedFile = (fileId) => {
    let url = '';
    // console.log(fileId);
    this.props.similarDocs.forEach((doc) => {
      if (doc.fileId === fileId) {
        url = doc.filePath;
      }
    });
    this.setState({ selectedFile: fileId, showFileUrl: url });
  };

  showDragBtnHandler = () => {
    const { showDragBtn } = this.state;
    if (!showDragBtn)
      this.setState({
        showDragBtn: true,
      });
  };

  handleSearch = (event) => {
    // console.log(event.target.value);
    let inputValue = event.target.value;
    if (inputValue === '') {
      this.setState({ searchData: this.props.similarDocs });
    } else {
      const filterData = this.props.similarDocs.filter((obj) =>
        obj.fileName.toLowerCase().includes(inputValue.toLowerCase())
      );
      this.setState({
        searchData: filterData,
      });
    }
  };

  render() {
    const {
      selectedValues,
      similarDocs,
      showDragBtn,
      showFileUrl,
      searchData,
    } = this.state;
    const {
      selectedSimilarDocUrl,
      similarDocFileClusterData,
      similarDocClusterDataLoading,
      similarDocFileClusterSubTags,
      splitPane2Expanded,
      size,
      handleSimilarDocTagClick,
      similarDocsSelectedParaIndex,
      isSimilarDocsTagSelected,
    } = this.props;
    return (
      /*  <Pane size={`${size}%`}> */
      <section
        className={styles.related_documents_root}
        onClick={this.showDragBtnHandler}
      >
        {showDragBtn && (
          <span className={styles.split_bar_arrow}>
            <img src={SplitArrowSVG} alt="Split Arrow" />
          </span>
        )}

        <div className={styles.related_documents_wrapper}>
          <div className={styles.related_documents_header_wrapper}>
            <div
              className={styles.related_documents_header}
              onClick={this.props.handleExpandPane2}
            >
              <div
                className={styles.related_dropdown}
                value={selectedValues}
                // onChange={this.handleSelectChange}
              >
                RELATED DOCUMENT
              </div>
            </div>
            {splitPane2Expanded && (
              <span
                className={styles.plane2ResizeArrow}
                onClick={this.props.resetSplitPane2}
              >
                <IconContext.Provider
                  value={{
                    size: 21,
                    color: '#7A7A7A',
                  }}
                >
                  <IoIosArrowDown />
                </IconContext.Provider>
              </span>
            )}
          </div>

          {/*  {showRelatedDocumentSection && ( */}
          {similarDocs?.length > 0 ? (
            <div className={styles.related_documents_bottom}>
              <div className={styles.related_documents_bottom_left}>
                <div className={styles.search_container}>
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                  />
                  <img src={MenuSVG} alt="Menu Icon" />
                </div>

                <div className={styles.cards_container}>
                  {selectedValues === 'related_document' ? (
                    <RecentDocument
                      data={searchData}
                      selectedSimilarDoc={this.props.selectedSimilarDoc}
                      handleDocumentChange={this.props.handleDocumentChange}
                    />
                  ) : null}
                  {/* {selectedValues === 'related_paragraph' ? (
                    <RelatedDocument
                      data={similarDocs}
                      selectHandler={this.handleSelectedFile}
                    />
                  ) : null} */}
                </div>
              </div>

              <div className={styles.related_documents_bottom_middle}>
                {/* {!similarDocClusterDataLoading ? ( */}
                <div
                  className={
                    similarDocClusterDataLoading
                      ? styles.similar_docs_html_wrapper_loading
                      : styles.similar_docs_html_wrapper
                  }
                >
                  {/* {selectedValues === 'related_document' ? (
                    fileType === 'docx' ? (
                      <FileViewer
                        fileType={fileType}
                        onError={this.onError}
                        filePath={selectedSimilarDocUrl}
                      />
                    ) : (
                      <div className={styles.drag_document_document_container}>
                        {selectedSimilarDocUrl ? (
                          <Document
                            file={selectedSimilarDocUrl}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            loading={this.renderLoader}
                          >
                            {this.state.numPages && this.renderPages()}
                          </Document>
                        ) : (
                          <p>No related files found</p>
                        )}
                      </div>
                    )
                  ) : null} */}

                  {!similarDocClusterDataLoading ? (
                    similarDocFileClusterData &&
                    similarDocFileClusterData.length > 0 ? (
                      similarDocFileClusterData.map((data, i) => (
                        <div
                          className={`${
                            similarDocsSelectedParaIndex.includes(i)
                              ? styles.similar_doc_border_container
                              : similarDocsSelectedParaIndex?.length > 0
                              ? styles.similar_doc_default_container_fade
                              : styles.similar_doc_default_container
                          }`}
                          id={`analytics-similar-docs-scroll-to-${i}`}
                        >
                          {data.paraType === 'Table' ? (
                            <img
                              src={data.paraText}
                              alt="table"
                              className={styles.related_docs_center_table}
                            />
                          ) : (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.htmlText
                                  ? data.htmlText
                                  : data.paraText,
                              }}
                            />
                          )}
                        </div>
                      ))
                    ) : null
                  ) : (
                    <div>
                      <Loader />
                    </div>
                  )}
                </div>
                {/* ) : null} */}
              </div>

              <div className={styles.related_documents_bottom_right}>
                <div className={styles.search_bar_and_chips_drag}>
                  {/* <div className={styles.search_bar_background}>
                    <div className={styles.search_bar_wrapper}>
                      <SearchBar
                        iconColor={'#2c2c2c'}
                        iconSize={'18px'}
                        fontSize={'15px'}
                        placeholder={'Search Tags'}
                        // handleSearch={() => {}}
                        // handleSearchChange={searchItems => {
                        //   this.handleSearchChange(searchItems)
                        // }}
                      />
                    </div>
                  </div> */}
                  <div
                    className={`${styles.chips_container} ${styles.related_documents_chips}`}
                  >
                    {similarDocFileClusterSubTags &&
                      similarDocFileClusterSubTags.map((tag) => (
                        <span
                          className={`${
                            isSimilarDocsTagSelected.includes(tag)
                              ? styles.related_doc_chip_active
                              : styles.related_doc_chip
                          }`}
                          onClick={() => handleSimilarDocTagClick(tag)}
                        >
                          {tag}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      /*  </Pane> */
    );
  }
}

export default DragDocument;
