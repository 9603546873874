import React from 'react';
import ReactDOM from 'react-dom'
import styles from  './Portal.module.css';
import commonStyles from './Common.module.css'

function PortalDemo(props) {
    const {handlePrepareResponse, handleResponseChange, handleSubmitResponse, clearResponse, value} = props

    return ReactDOM.createPortal(
        <div className={ styles.wrapper}>
            
            


        
             <div
              className={`row ${styles.response_section}`}
              style={{ display: "flex" }}
            >
              <div className={styles.cross_btn}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={handlePrepareResponse}
                ></i>
              </div>

              <div
                className="col-md-8"
                style={{
                  display: "flex",
                  marginLeft: "42px",
                }}
              >
                <textarea
                  className={styles.response_body}
                  onChange={handleResponseChange}
                  value={value}
                ></textarea>
              </div>
              <div style={{textAlign:'center'}} className="col-md-3">
              <div
                style={{
                  marginBottom:"40px"
                }}
              >
                <button
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple} ${styles.response_save}`}
                  onClick={handleSubmitResponse}
                >
                  SUMBIT RESPONSE
                </button>
              </div>
              <div
                style={{
                }}
              >
                <button
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple} ${styles.response_clear}`}
                  onClick={clearResponse}
                >
                  CLEAR RESPONSE
                </button>
              </div>
            </div>
            </div>
          </div>
,
        document.getElementById('portal-root')
    )
}

export default PortalDemo