
import { toast } from 'react-toastify';

export const handleSuccess = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT
      });
};

export const handleError = (message) => {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT
      });
};