import * as types from '../types';

const initialState = {
  isLoading: false,
  fileClusterData: null,
  error: null,
  similarDocFileClusterData: null,
  isSimilarDocFileClusterLoading: false,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // Update Profile Actions
    case types.FILE_CLUSTER_DATA_REQUEST:
      return {
        ...state,
        filesStatus: null,
        isLoading: true,
      };
    case types.FILE_CLUSTER_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        filesStatus: action.payload.result.data,
      };
    }
    case types.FILE_CLUSTER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    // get similar doc cluster data
    case types.SIMILAR_DOC_CLUSTER_DATA_REQUEST:
      return {
        ...state,
        similarDocFileClusterData: null,
        isSimilarDocFileClusterLoading: true,
      };
    case types.SIMILAR_DOC_CLUSTER_DATA_SUCCESS: {
      return {
        ...state,
        isSimilarDocFileClusterLoading: false,
        similarDocFileClusterData: action.payload.result.data,
      };
    }
    case types.SIMILAR_DOC_CLUSTER_DATA_FAILURE:
      return {
        ...state,
        isSimilarDocFileClusterLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    default: {
      return state;
    }
  }
};
