import React, { Component } from 'react'

import styles from './FilterCheckboxList.module.css'

export default class FilterCheckboxList extends Component {
  state = {
    selectedUserIds: [],
    selectedUserDetails: [],
  }

  onValueChange = (e, item) => {
    // console.info('onValueChange item: ', item, 'checked: ', e.target.checked)
    const selectedUserIds = this.state.selectedUserIds
    const selectedUserDetails = this.state.selectedUserDetails

    if (selectedUserIds.includes(item.key) && !e.target.checked) {
      const indexOfMatchingKey = selectedUserIds.indexOf(item.key)
      selectedUserIds.splice(indexOfMatchingKey, 1)
      selectedUserDetails.splice(indexOfMatchingKey, 1)
    } else if (!selectedUserIds.includes(item.key) && e.target.checked) {
      selectedUserIds.push(item.key)
      selectedUserDetails.push(item)
    }
    this.setState({
      selectedUserIds,
    })
  }

  getState = () => {
    return this.state
  }

  render() {
    // console.info('FilterCheckboxList render state: ', this.state)
    const { data, showSelected, width = 18, height = 18 } = this.props
    console.info(data)

    let listData = []
    if (showSelected) {
      const keyValues = [...data]
      listData = keyValues.filter(item =>
        this.state.selectedUserIds.includes(item.key)
      )
    } else listData = data
    return (
      <>
        {listData?.map((item, index) => (
          <div className={styles.checkbox_container} key={index}>
            <div
              className={styles.round}
              style={{
                width,
                height,
              }}
            >
              <input
                type="checkbox"
                id={`${item.key}-${index}`}
                onChange={e => this.onValueChange(e, item)}
                name={`${item.key}-${index}`}
                value={item.value}
                checked={this.state.selectedUserIds?.includes(item.key)}
              />
              <label
                for={`${item.key}-${index}`}
                style={{
                  width,
                  height,
                }}
              ></label>
            </div>
            <span className={styles.checkbox_title}>{item.value}</span>
          </div>
        ))}
      </>
    )
  }
}
