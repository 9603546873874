import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { BsBellFill } from 'react-icons/bs'
// import { RiBarChartLine } from 'react-icons/ri'
// import { AiOutlineUser } from 'react-icons/ai'
import { FiFile } from 'react-icons/fi'
import { BsCircleFill } from 'react-icons/bs'
import { IconContext } from 'react-icons'
import OutsideClickHandler from 'react-outside-click-handler'
import { BarLoader } from 'react-spinners'
import BeatLoader from 'react-spinners/BeatLoader'
import { css } from '@emotion/core'

import ClockWhiteSVG from './../../../assets/clock_white.svg'
import ClockBlueSVG from './../../../assets/clock_blue.svg'
import ststsIconActive from './../../../assets/stats_icon_active.svg'
import statsIconInActive from './../../../assets/stats_icon_inactive.svg'
import notificationIconActive from './../../../assets/notification_icon_active.svg'
import UploadDocumentSVG from './../../../assets/upload_document.svg'

import Logout from './../../../assets/log-out.svg'
import Settings from './../../../assets/settings.svg'
// import { timeTracking } from './../../../json-data/allProjects.json'
import styles from './NavBar.module.css'

import AccountSettingsModal from '../../../components/workflow-management/modal/account-settings-v2/AccountSettingsModal'
import UploadDocumentModal from './../../dashboard-v2/modal/UploadDocumentModal'

import IdleTimer from 'react-idle-timer'

import * as navbarActions from '../../../store/actions/navbarActions'
import * as dashboardV2Page from '../../../store/actions/dashboard-v2Action'
import * as homepageActions from '../../../store/actions/homepageActions'

import {
  withLastLocation,
  WithLastLocationProps,
} from 'react-router-last-location'

import {
  stopCounter,
  incrementCounter,
  pathChange,
  resetCounter,
} from '../../../store/actions/homepageActions'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
// import TimeMe from 'timeme.js';
import io from 'socket.io-client'
import StatsGraph from '../../../shared/stats-graph/StatsGraph'

import {
  requestLogout,
  saveConnectionRequest,
  saveNotificationRequest,
  removeNotificationRequest,
  saveUnreadNotification,
  saveNewNotificationId,
} from '../../../store/actions/homepageActions'

import * as notification from '../../../store/actions/notificationAction'
// import { saveFileDataRequest } from '../../../store/actions/allDocumentsActions';

import { projectColors } from '../../../json-data/nav-bar.json'

const NOTIFICATION_SERVER_ENDPOINT ='https://notification-server-precily.azurewebsites.net';
//'http://localhost:7700/'
  /* `${process.env.REACT_APP_NOTIFICATION_SERVER}` */ 
const CHAT_AND_TRACKING_SERVER_ENDPOINT =
  'https://precilyteamchat.azurewebsites.net'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`
class NavBar extends Component {
  NAVBAR = 'NavBar'
  fileInputRef = React.createRef()

  constructor(props) {
    super(props)
    this.socketNotification = io(NOTIFICATION_SERVER_ENDPOINT)
    this.socketTimeTrackingAndChat = io(CHAT_AND_TRACKING_SERVER_ENDPOINT)

    this.state = {
      showTimeTrackingPanel: false,
      togglePopup: false,
      showAccountSettingModal: false,
      showStatsModal: false,
      running: false,
      currentTimeMs: 0,
      currentTimeSec: 0,
      currentTimeMin: 0,
      trackTime: 0,
      seconds: 0,
      start: 0,
      isOn: false,
      time: 0,
      projects: this.props.dashboardData?.data?.projects,
      messages: [],
      // socketNotification: null,
      newNotifications: false,
      quickSummary: null,
      blinkTimer: false,
      showUploadDocumentModal: false,
      fileUploadData: null,
      timeSpentData: [],
      uploadTriggerFromNavigation: false,
    }
  }

  componentDidMount() {
    let sessionId
    this.socketNotification.on('connect', () => {
      // console.info('connected: ', this.socketNotification.connected)
      // console.info('disconnected: ', this.socketNotification.disconnected)
      sessionId = this.socketNotification.id
      // console.info('sessionId: ', sessionId)

      this.socketNotification.emit(
        'client',
        JSON.stringify({
          TYPE: 'LOGIN',
          userId: this.props.homepage?.userDetails?.userId,
          sessionId,
        })
      )
    })

    this.socketNotification.on('notification', data => {
      // console.info('notification data: ', data)
      const dataJS = JSON.parse(data)
      this.props.saveNewNotificationId(dataJS.messageId)
      this.props.saveUnreadNotification(true)
    })

    // fetch all notifications
    this.props.fetchAllNotifications(this.props.homepage.userDetails.userId)
    let count = 0

    //  blink timer
    let path = window.location.pathname
    if (this.props.homepage.running && path !== '/all-projects') {
      this.interval = setInterval(() => {
        const { blinkTimer } = this.state
        this.setState({
          blinkTimer: !blinkTimer,
        })
      }, 500)
    }

    // socket = io.connect(ENDPOINT)
    this.props.pathChange(path)
    let isRoute = false
    if (this.props.homepage.running) {
      this.timer = setInterval(() => {
        this.props.incrementCounter()
      }, 1000)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDashboardDataLoading &&
      !this.props.isDashboardDataLoading
    ) {
      this.setState({
        projects: this.props.dashboardData?.data?.projects,
      })
    }
    if (
      prevProps.isQuickSummaryLoading &&
      !this.props.isQuickSummaryLoading &&
      this.props.quickSummary
    ) {
      let projects = this.props.dashboardData?.data?.projects
      let timeSpentData = this.props.quickSummary?.timeSpent
      let graphData = []
      if (timeSpentData && projects) {
        timeSpentData.forEach(data => {
          projects.forEach(project => {
            if (project.id === data.projectId) {
              graphData.push({
                id: project.id,
                key: project.name,
                value: Number(data.timeSpent / 1000 / 60 / 60),
              })
            }
          })
        })
        graphData.sort((a, b) => a.value - b.value)
      }
      this.setState({
        quickSummary: this.props.quickSummary,
        timeSpentData,
        timeSpentGraphData: graphData,
      })
    }

    if (
      prevProps.isLoading &&
      !this.props.isLoading &&
      this.props.notifications
    ) {
      let newNotifications = false
      this.props.notifications.messages.forEach(notification => {
        if (!notification.markAsRead) {
          newNotifications = true
        }
      })

      this.props.saveUnreadNotification(newNotifications)
    }
    if (
      prevProps.isDashboardFileUploading &&
      !this.props.isDashboardFileUploading &&
      this.props.documentUploadData &&
      this.props.uploadDocumentModalParent === this.NAVBAR
    ) {
      this.setState(
        {
          fileUploadData: this.props.documentUploadData?.data,
        },
        () => {
          this.uploadDocumentModalHandler(true)
        }
      )
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    clearInterval(this.interval)

    this.socketNotification.disconnect()
    this.socketNotification.removeListener('notification', () => {
      // console.info('Notification listener removed')
    })
  }

  saveMessages = m => {
    let msg = JSON.parse(m)
    let activeMessages = [...this.state.messages, msg]
    this.setState(
      {
        messages: activeMessages,
      },
      () => {
        this.props.saveNotificationRequest(this.state.messages)
      }
    )
  }

  toggleTimeTrackingPanel = () => {
    const { showTimeTrackingPanel } = this.state
    this.setState({
      showTimeTrackingPanel: !showTimeTrackingPanel,
    })
  }
  closeTimeTrackingPanel = () => {
    this.setState({
      showTimeTrackingPanel: false,
    })
  }
  openPopupPanel = () => {
    this.setState({
      togglePopup: true,
    })
  }
  closePopupPanel = () => {
    this.setState({
      togglePopup: false,
    })
  }

  start = e => {
    if (e.target.checked && window.location.pathname === '/all-projects') {
      this.socketTimeTrackingAndChat.emit('subscribe', {
        userId: this.props.homepage?.userDetails?.userId,
        startTime: Date.now(),
        date: new Date().toISOString(),
        projectId: this.props.homepage?.currentProjectId,
      })
      this.timer = setInterval(() => {
        this.props.incrementCounter()
      }, 1000)
    } else if (!e.target.checked) {
      this.props.stopCounter()
      clearInterval(this.timer)
      this.socketTimeTrackingAndChat.emit('unsubscribe', {
        userId: this.props.homepage?.userDetails?.userId,
        endTime: Date.now(),
        projectId: this.props.homepage?.currentProjectId,
      })
    }
  }

  handleStatsClick = () => {
    const { showStatsModal } = this.state
    this.setState({
      showStatsModal: !showStatsModal,
    })
    this.props.quickSummaryRequest()
    // if (this.props.quickSummary) {
    //   this.setState({ quickSummary: this.props.quickSummary })
    // } else {
    //   this.props.quickSummaryRequest()
    // }
  }

  hideStatsModal = () => {
    const { showStatsModal } = this.state
    this.setState({
      showStatsModal: false,
    })
  }
  handleSignoutClick = () => {
    //  TODO: Logout tracking api to be integrated by Abhinav
    this.socketNotification.emit(
      'client',
      JSON.stringify({
        TYPE: 'LOGOUT',
        message: this.props.homepage?.userDetails?.userId,
      })
    )

    this.props.resetCounter()
    this.props.history.push('/')
    localStorage.clear()
  }

  toggleAccountSettingModal = value => {
    this.setState({
      showAccountSettingModal: value,
      togglePopup: false,
    })
  }

  notificationScreenHandler = () => {
    // this.props.history.push('/notification-v2');
    this.props.history.push({
      pathname: '/notification-v2',
      search: '?source=dashboard',
    })
  }

  fileSelected = e => {
    // this.props.notifyDashboard();

    if (e.target.value) {
      console.info('fileSelected inside if')
      this.props.updateUploadDocumentModalParent(this.NAVBAR)

      const teamId = JSON.parse(localStorage.getItem('userProfileData'))
        .teams[0]?.teamId

      const formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('teamId', teamId)

      this.setState(
        {
          uploadTriggerFromNavigation: true,
        },
        () => {
          this.props.dashboardFileUpload(formData)
        }
      )

       console.info('documentUploadData: ', this.props.documentUploadData)
    }
  }

  handleInputClick = e => {
    this.fileInputRef.current.value = null
  }
  uploadDocumentModalHandler = value => {
    this.setState({
      showUploadDocumentModal: value,
    })
  }

  getProjectTimeSpent = projectId => {
    let timeSpentData = this.state.timeSpentData
    let total = 0
    timeSpentData.forEach(timeSpent => {
      if (timeSpent.projectId === projectId) {
        total += Number((timeSpent.timeSpent / 1000 / 60 / 60).toFixed(2))
      }
    })
    if (total <= 0) {
      return '00:00'
    }
    return total
  }

  render() {
    const { showTimeTracking, areNotificationsUnread } = this.props
    const {
      showTimeTrackingPanel,
      showStatsModal,
      togglePopup,
      showAccountSettingModal,
      projects,
      quickSummary,
      blinkTimer,
      showUploadDocumentModal,
      fileUploadData,
      timeSpentGraphData,
    } = this.state

    const isTimerRunning = this.props.homepage.running

    return (
      <div>
        <IdleTimer
          timeout={1000 * 60 * 30}
          onIdle={() => this.props.history.push('/')}
          debounce={250}
        />
        <div className={styles.loader_wrapper}>
          <BarLoader
            width="auto"
            Loader="CircleLoader"
            color={'#11256d'}
            loading={
              this.props.loading === (null || undefined)
                ? false
                : this.props.loading
            }
          />
        </div>
        <div className={styles.root}>
          <div className={styles.company_name}>Precily AI</div>

          <div className={styles.right_side}>
            <label className={styles.upload_document} for="my file 1">
              <img src={UploadDocumentSVG} alt="UploadDocumentSVG" />
              <input
                type="file"
                id="my file 1"
                name="my file 1"
                onChange={e => this.fileSelected(e)}
                onClick={this.handleInputClick}
                ref={this.fileInputRef}
              />
            </label>

            <section
              className={styles.time_tracking}
              style={
                isTimerRunning
                  ? blinkTimer
                    ? {
                        backgroundColor: '#f50072',
                        color: '#fff',
                      }
                    : {}
                  : {}
              }
            >
              <OutsideClickHandler onOutsideClick={this.closeTimeTrackingPanel}>
                <div
                  className={styles.time_tracking_wrapper}
                  onClick={this.toggleTimeTrackingPanel}
                >
                  <span>
                    {this.props.homepage.currentTimeHours}:
                    {this.props.homepage.currentTimeMin}:
                    {this.props.homepage.currentTimeSec}
                  </span>
                  <img
                    src={
                      isTimerRunning
                        ? blinkTimer
                          ? ClockWhiteSVG
                          : ClockBlueSVG
                        : ClockBlueSVG
                    }
                    alt="Clock White Icon"
                  />
                </div>

                {showTimeTrackingPanel && (
                  <div className={styles.time_tracking_panel}>
                    <div className={styles.upper_part}>
                      <span>Tracking in progress..</span>
                      <div className={styles.switch_container}>
                        <label className={styles.switch}>
                          <input
                            type="checkbox"
                            onChange={this.start}
                            checked={this.props.homepage.running}
                          />

                          <span
                            className={`${styles.slider} ${styles.round}`}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className={styles.lower_part}>
                      <div className={styles.lower_part_data_container}>
                        {projects?.map(({ name, id }, index) => (
                          <div className={styles.lower_part_data}>
                            <span className={styles.circle_icon}>
                              <IconContext.Provider
                                value={{
                                  size: 14,
                                  color:
                                    index + 1 > projectColors.length
                                      ? projectColors[
                                          index % projectColors.length
                                        ]
                                      : projectColors[index],
                                }}
                              >
                                <BsCircleFill />
                              </IconContext.Provider>
                            </span>
                            <span className={styles.filename}>{name}</span>
                            <span className={styles.time}>
                              {this.getProjectTimeSpent(id)} Hrs.
                            </span>
                            {/* <div className={styles.switch_container}>
                              <label className={styles.switch}>
                                <input type="checkbox" />
                                <span
                                  className={`${styles.slider} ${styles.round}`}
                                ></span>
                              </label>
                            </div> */}
                          </div>
                        ))}
                      </div>

                      <div className={styles.lower_part_progress}>
                        <div className={styles.progress_container}>
                          <span className={styles.file_icon}>
                            <IconContext.Provider
                              value={{ color: '#000', size: 15 }}
                            >
                              <FiFile />
                            </IconContext.Provider>
                          </span>
                          <span className={styles.file_progress_text}>
                            0 File being processed
                          </span>

                          <div className={styles.progress_percentage}>0%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </OutsideClickHandler>
            </section>

            {areNotificationsUnread ? (
              <div
                className={styles.bell_icon_container}
                onClick={this.notificationScreenHandler}
              >
                <div>
                  <img src={notificationIconActive} alt="notification" />
                </div>
              </div>
            ) : (
              <div
                className={styles.bell_icon_container}
                onClick={this.notificationScreenHandler}
              >
                <IconContext.Provider
                  value={{ color: '#eaeaea', size: '20px' }}
                >
                  <BsBellFill />
                </IconContext.Provider>
              </div>
            )}

            <div className={styles.stats_icon}>
              <div onClick={this.handleStatsClick}>
                {/* <IconContext.Provider
                    value={{ color: '#11256D', size: '20px' }}
                  >
                    <RiBarChartLine />
                  </IconContext.Provider> */}
                {!showStatsModal && (
                  <img src={statsIconInActive} alt="stats_icon_active"></img>
                )}
                {showStatsModal && (
                  <img src={ststsIconActive} alt="stats_icon_active"></img>
                )}
              </div>

              {showStatsModal && (
                <OutsideClickHandler onOutsideClick={this.hideStatsModal}>
                  <div className={styles.stats_popup_container}>
                    <div className={styles.stats_popup_content}>
                      <div className={styles.stats_header_section}>
                        <div className={styles.header_left_section}>
                          <div className={styles.header_text}>Summary</div>
                          <div
                            className={`workflow_management_stats_tabs ${styles.tabs}`}
                          >
                            <Tabs defaultActiveKey="home1" transition={false}>
                              <Tab
                                tabClassName={styles.tab_root}
                                eventKey="home1"
                                title="Download Summary"
                              ></Tab>

                              {/* <Tab
                                tabClassName={styles.tab_root}
                                eventKey="home3"
                                title="Some Link"
                              ></Tab> */}
                            </Tabs>
                          </div>
                        </div>

                        <div
                          className={styles.stats_popup_close_icon}
                          onClick={this.hideStatsModal}
                        >
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                      <div className={styles.stats_popup_bottom_divider}></div>

                      {quickSummary ? (
                        <div
                          className={styles.stats_graph_container}
                          style={{ display: 'flex' }}
                        >
                          <StatsGraph
                            data={timeSpentGraphData}
                            title="TIME SPENT"
                          ></StatsGraph>
                          <StatsGraph
                            data={quickSummary.workflow}
                            title="WORKFLOW"
                          ></StatsGraph>
                          <StatsGraph
                            data={quickSummary.issues}
                            title="ISSUES"
                          ></StatsGraph>
                        </div>
                      ) : (
                        <BeatLoader
                          css={override}
                          size={25}
                          color={'#11256D'}
                          loading={true}
                        />
                      )}
                    </div>
                  </div>
                </OutsideClickHandler>
              )}
            </div>

            <div
              className={styles.user_icon}
              onClick={() => this.openPopupPanel()}
            >
              {/* <IconContext.Provider value={{ color: '#c4c4c4', size: '20px' }}>
              <AiOutlineUser />
            </IconContext.Provider> */}
              <img src="https://www.w3schools.com/howto/img_avatar.png" />
            </div>
            <div
              onClick={() => this.openPopupPanel()}
              className={styles.name_container}
            >
              {this.props.homepage?.userDetails?.name}
            </div>
          </div>
        </div>

        {togglePopup && (
          <div className={styles.popup_overlay}>
            <OutsideClickHandler onOutsideClick={this.closePopupPanel}>
              <div className={styles.popup_container}>
                <div className={styles.popup_content}>
                  <div onClick={() => this.toggleAccountSettingModal(true)}>
                    <img src={Logout} alt="Logout"></img>{' '}
                    <span> Account Settings</span>
                  </div>
                  <div className={styles.settings_divider}></div>
                  <div
                    onClick={this.handleSignoutClick}
                    className={styles.signout}
                  >
                    <img src={Settings} alt="Settings"></img>{' '}
                    <span>Sign Out</span>
                  </div>
                </div>
              </div>
            </OutsideClickHandler>
          </div>
        )}

        {showAccountSettingModal && (
          <AccountSettingsModal
            accountSettingsModalHandler={() =>
              this.toggleAccountSettingModal(false)
            }
          />
        )}
        {showUploadDocumentModal && (
          <UploadDocumentModal
            uploadDocumentModalHandler={() => {
              this.uploadDocumentModalHandler(false)
            }}
            data={fileUploadData}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    homepage: state.homepage,
    quickSummary: state.navbar?.quickSummary,
    isQuickSummaryLoading: state.navbar?.isQuickSummaryLoading,
    dashboardData: state.dashboardV2Page.dashboardData,
    isDashboardDataLoading: state.dashboardV2Page.isLoading,
    isLoading: state.notifications.isLoading,
    notifications: state.notifications,
    areNotificationsUnread: state.homepage.areNotificationsUnread,
    isDashboardFileUploading: state.dashboardV2Page.isFileUploading,
    documentUploadData: state.dashboardV2Page.documentUploadData,
    uploadDocumentModalParent: state.homepage.uploadDocumentModalParent,
  }
}
const mapDispatchToProps = {
  incrementCounter,
  stopCounter,
  pathChange,
  resetCounter,
  quickSummaryRequest: navbarActions.quickSummaryRequest,
  requestLogout,
  saveConnectionRequest,
  saveNotificationRequest,
  // removeNotificationRequest,
  saveUnreadNotification,
  saveNewNotificationId,
  fetchAllNotifications: notification.fetchAllNotificationsRequest,
  dashboardFileUpload: dashboardV2Page.dashboardFileUploadRequest,
  updateUploadDocumentModalParent:
    homepageActions.updateUploadDocumentModalParent,
  quickSummaryRequest: navbarActions.quickSummaryRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLastLocation(NavBar)))
