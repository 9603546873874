import styles from './DocumentAnalytics.module.css';
import ReactQuill from 'react-quill';
import React from 'react';
const Quill = ReactQuill.Quill;

let Inline = Quill.import('blots/inline');

class Highlight extends Inline {
  static create(opts) {
    const { color, id } = opts;

    let node = super.create();

    node.style.backgroundColor = color;
    node.dataset.id = id;

    return node;
  }

  static formats(node) {
    if (!node.style.backgroundColor) return null;

    return {
      color: node.style.backgroundColor,
      id: node.dataset.id,
    };
  }
}

Highlight.blotName = 'highlight';
Highlight.className = 'review_highlighted_text';

Quill.register(Highlight);

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: this.props.data };
    this.reactQuillRef = null;
  }

  handleChange = (html) => {
    this.setState({ editorHtml: html });
  };

  componentDidMount() {
    let data = this.props.fileComments?.length ? this.props.fileComments : [];
    if (this.refs.editor) {
      const editor = this.refs.editor.getEditor();
      data.forEach((obj) => {
        editor.formatText(obj.startIndex, obj.endIndex, 'highlight', {
          color: '#d1eedb',
          id: `da_highlighted_text_${obj.commentCount}`,
        });

        let quill = document.getElementsByClassName('ql-editor')[0];
        // console.log("QUILL", quill);
        quill.parentNode.addEventListener('click', (e) => {
          if (
            e.target.getAttribute('data-id') ===
            `da_highlighted_text_${obj.commentCount}`
          ) {
            this.props.handleCommentFilter(e.target, obj.commentCount);
          }
        });

        // let span = document.querySelectorAll(
        //   `[data-id="da_highlighted_text_${i}"]`
        // );
        // console.log(span[0]);
      });
    }
  }

  handleChangeSelection = (selection) => {
    if (
      selection &&
      selection.index !== selection.length &&
      selection.length !== 0 &&
      !this.props.isCommenting &&
      this.props.selectedCommentCount === null
    ) {
      const editor = this.refs.editor.getEditor();
      this.props.handleSelectedText(selection.index, selection.length);
      editor.formatText(selection.index, selection.length, 'highlight', {
        color: '#d1eedb',
        id: 'new-comment',
      });
    }
  };

  render() {
    return (
      <div
        className={`${styles.review_model_editor_wrapper} review_model_editor_wrapper`}
      >
        <ReactQuill
          readOnly={true}
          ref="editor"
          theme={null}
          onChange={this.handleChange}
          onChangeSelection={this.handleChangeSelection}
          value={this.state.editorHtml}
        />
      </div>
    );
  }
}

export default Editor;
