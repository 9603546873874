import React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { IconContext } from 'react-icons'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import styles from './RecentFolders.module.css'
import grey_folder from '../../../../src/assets/grey_folder.svg'
import cloud_download from '../../../../src/assets/cloud_download.svg'
import share from '../../../../src/assets/share.svg'
import info from '../../../../src/assets/info.svg'
import selected_info from '../../../../src/assets/selected_info.svg'
import { formatDate } from '../../../shared/formatDate'

import infoAction from '../../../json-data/infoAction.json'
class RecentFolders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAction: false,
      documentTypeCount: [],
      modifiedDate: '',
    }
  }

  openActionPopup = (index, documentTypeCount, modifiedDate) => {
    this.setState({ selectedAction: index, documentTypeCount, modifiedDate })
  }
  closeActionPopup = () => {
    if ((this.state.selectedAction, this.state.selectedAction))
      this.setState({ selectedAction: null })
  }
  handleFolderClick = (fileName, referenceId, catalogSourceId) => {
    this.props.history.push({
      pathname: '/selected-folder',
      search: '?source=data-catalog'
    }, {
      fileName: fileName,
      referenceId: referenceId,
      catalogSourceId: catalogSourceId,
    })
  }

  render() {
    const modifiedDate = formatDate(this.state.modifiedDate)
    return (
      <React.Fragment>
        {this.props.recentFolders?.map((data, index) => {
          if (index > 3) return
          return (
            <div>
              <div className={styles.recent_folders_content}>
                <div className={styles.folder_and_title}>
                  <div className={styles.recent_folders_icon}>
                    <img src={grey_folder} alt="document"></img>
                  </div>
                  <span
                    className={styles.recent_folders_title}
                    onClick={() =>
                      this.handleFolderClick(
                        data.name,
                        data.referenceId,
                        data.catalogSourceId
                      )
                    }
                  >
                    {data.name}
                  </span>
                </div>

                <div className={styles.recent_folders_action_icons}>
                  <OutsideClickHandler
                    onOutsideClick={() => this.closeActionPopup()}
                  >
                    <div className={styles.icon_container}>
                      <img
                        className={styles.cloud_icon}
                        src={cloud_download}
                        alt="cloud_download"
                      ></img>
                    </div>
                    <div className={styles.icon_container}>
                      <img
                        className={styles.share_icon}
                        src={share}
                        alt="share"
                      ></img>
                    </div>

                    <div className={styles.icon_container}>
                      <div
                        className={styles.info_icon_wrapper}
                        style={
                          this.state.selectedAction === index + 1
                            ? {
                                backgroundColor: '#11256d',
                                padding: '15px',
                                borderRadius: '50%',
                              }
                            : {}
                        }
                        onClick={() =>
                          this.openActionPopup(
                            index + 1,
                            data.documentTypeCount,
                            data.modified
                          )
                        }
                      >
                        <IconContext.Provider
                          value={
                            this.state.selectedAction === index + 1
                              ? { size: 24, color: '#fff' }
                              : {
                                  size: 24,
                                  color: '#9a9a9a',
                                }
                          }
                        >
                          <AiOutlineInfoCircle />
                        </IconContext.Provider>
                      </div>

                      {this.state.selectedAction === index + 1 && (
                        <div className={styles.action_popup}>
                          {this.state.documentTypeCount.map((data, index) => {
                            return (
                              <div>
                                <div className={styles.action_popup_content}>
                                  <div className={styles.action_popup_text}>
                                    {data.name}
                                  </div>
                                  <div className={styles.action_popup_value}>
                                    {data.count}
                                  </div>
                                </div>
                              </div>
                            )
                          })}

                          <hr></hr>
                          <div className={styles.popup_bottom_content}>
                            <div className={styles.action_popup_text}>
                              Updated on
                            </div>
                            <div className={styles.action_popup_value}>
                              {modifiedDate.date} . {modifiedDate.time}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </OutsideClickHandler>
                </div>
              </div>
              {index < this.props.recentFolders.length - 1 && <hr></hr>}
            </div>
          )
        })}
      </React.Fragment>
    )
  }
}

export default RecentFolders
