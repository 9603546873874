import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as styles from './NotificationList.module.css'
// import * as notificationActions from "../../../store/actions/notificationAction";

class NotificationList extends Component {
  render() {
    const { notifications, handleAsRead } = this.props
    return (
      <div className={styles.notification}>
        {notifications.length &&
          notifications.map(msg => {
            return (
              <div className={styles.notification_card} key={msg.messageId}>
                <div className={styles.notification_heading}>
                  {msg.data.heading}
                </div>

                <div className={styles.notification_data}>
                  <div style={{ display: 'inline-flex' }}>
                    {msg.data.message}
                    <i
                      data-badge={msg.fresh ? '' : null}
                      className={`${styles.badge1}`}
                      style={{ marginLeft: '12px' }}
                    />
                  </div>
                  <p className={styles.notificationDate}>
                    {new Date(`${msg.created}`).toLocaleString(undefined, {
                      timeZone: 'Asia/Kolkata',
                    })}
                  </p>
                </div>
                {msg.fresh ? (
                  <button
                    className={`btn btn-primary-outline ${styles.markAsRead}`}
                    onClick={() => handleAsRead(msg.messageId)}
                  >
                    MARK AS READ
                  </button>
                ) : null}
              </div>
            )
          })}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDistapatchToProps = {}

export default connect(mapStateToProps, mapDistapatchToProps)(NotificationList)
