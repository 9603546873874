import React from 'react'
import styles from './SupportPage.module.css'
import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar'
import NavBar from '../../screens/workflow-management/NavBar/NavBar'
import RightBar from '../../screens/workflow-management/RightBar/RightBar'
import commonStyles from '../../shared/Common.module.css'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import plus_circle from '../../../src/assets/plus_circle.svg'
import CommonModal from '../../shared/common-modal/CommonModal'
import CommonDropdown from '../../shared/common-dropdown/CommonDropdown'
import UploadSVG from '../../../src/assets/cloud_upload_white.svg'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import { handleSuccess, handleError } from '../../shared/HandleMessages'
import DeleteTicket from '../../components/support/DeleteTicket'
import UpdateTicket from '../../components/support/update-ticket/UpdateTicket'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import * as supportActions from '../../store/actions/supportActions'
import * as catalogLandingPage from '../../store/actions/catalogLandingPageActions'
import * as catalogSearchResults from '../../store/actions/catalogSearchResultsAction'
import * as workflowManagementActions from '../../store/actions/workFlowManagementLandingPageActions'

import { connect } from 'react-redux'

const renderDashboardTooltip = (props, text) => {
  return (
    <Tooltip className="my-tooltip" id="renderDashboardTooltiptt" {...props}>
      {text}
    </Tooltip>
  )
}

const priorityDropdown = [
  {
    key: 1,
    value: 'Low',
  },
  {
    key: 2,
    value: 'Medium',
  },
  {
    key: 3,
    value: 'High',
  },
  {
    key: 4,
    value: 'Urgent',
  },
]
const priorityColorMap = {
  Low: '#1BA94C',
  Medium: '#FFA800',
  High: '#EB3E3E',
  Urgent: 'red',
}
const data = {
  data: [
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Pending',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Pending',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Closed',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Pending',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Closed',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Pending',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Closed',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Pending',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Closed',
      dueDate: 'December 28, 2020',
      priority: '',
    },
    {
      id: '426',
      subject: 'DARRELL STEWARD',
      status: 'Pending',
      dueDate: 'December 28, 2020',
      priority: '',
    },
  ],
}

class SupportPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCreateTicketModal: false,
      loading: false,
      tickets: [],
      loading: true,
      activeTab: 'all_tickets',
      filedata: '',
      fileName: '',
      subject: '',
      description: '',
      selectedPriority: null,
      selectedAction: null,
      isCloseTicketModalOpen: false,
      isUpdateTicketModalOpen: false,
      ticketToClose: '',
    }
  }

  componentDidMount() {
    this.props.saveAdvancedSearchFilters(null)
    this.props.saveSearchTermRequest(null)
    this.props.saveSelectedSources(null)
    this.props.saveFilters(null)
    this.props.saveFilteredDocuments(null)
    this.props.saveFiltersSearchResultsPage(null)
    this.props.saveFilteredDocumentsSearchResultsPage(null)

    this.props.listTicketsRequest(this.props.userDetails.email)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (this.props.listTickets?.tickets?.length > 0) {
        this.setState({
          tickets: this.props.listTickets.tickets[0].tickets,
          loading: false,
        })
      } else {
        this.setState({ loading: false })
      }
    }

    if (prevProps.isTicketCreated && !this.props.isTicketCreated) {
      handleSuccess('Ticket has been successfully created !')
      this.props.listTicketsRequest(this.props.userDetails.email)
      this.setState({ loading: true, showCreateTicketModal: false })
    }

    if (prevProps.isTicketClosed && !this.props.isTicketClosed) {
      // this.onUploadClose();
      this.props.listTicketsRequest(this.props.userDetails.email)
      handleSuccess('Ticket has been successfully deleted !')
      this.setState({ selectedAction: null })
    }

    if (prevProps.isTicketUpdated && !this.props.isTicketUpdated) {
      // this.onUploadClose();
      handleSuccess('Ticket has been successfully updated !')
      this.props.listTicketsRequest(this.props.userDetails.email)
      this.setState({ isUpdateTicketModalOpen: false, selectedAction: null })
    }
  }
  supportPageModalHandler = () => {
    this.setState({ showCreateTicketModal: false, subject:'',description:'',selectedPriority:null })
  }
  handleCreateTicket = () => {
    this.setState({ showCreateTicketModal: true })
  }

  navigateBack = () => {
    this.props.history.goBack()
  }
  selectedDropdown = (name, value) => {
    this.setState({ [name]: value })
  }

  handleActiveTab = tab => {
    this.setState({ activeTab: tab })
    if (tab === 'all_tickets') {
      this.setState({
        tickets: this.props.listTickets.tickets[0].tickets,
      })
    } else if (tab === 'open') {
      this.setState({
        tickets: this.props.listTickets.tickets[1].tickets,
      })
    } else if (tab === 'pending') {
      this.setState({
        tickets: this.props.listTickets.tickets[2].tickets,
      })
    } else if (tab === 'resolved') {
      this.setState({
        tickets: this.props.listTickets.tickets[3].tickets,
      })
    } else if (tab === 'closed') {
      this.setState({
        tickets: this.props.listTickets.tickets[4].tickets,
      })
    }
  }

  onfileFound = file => {
    file.forEach(file => {
      const fileType = file.path.split('.').pop()
      if (fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png') {
        toast.error('file format not supported', {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }
      this.setState(
        {
          fileName: file.name,
          uploadingInProcess: true,
        },
        () => {
          const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
            this.setState(
              {
                filedata: reader,
              },
              () => {
                this.onUpload(file)
              }
            )
          }
          reader.readAsDataURL(file)
        }
      )
    })
  }
  onUpload = async file => {
    // const fileType = file.path.split('.').pop()
    // if (fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'png') {
    //   toast.error('file format not supported', {
    //     position: toast.POSITION.TOP_RIGHT,
    //   })
    // }
  }

  createNewTicket = () => {
    // const isValid = this.getErrors();
    // if (!isValid) {
    const { subject, description, selectedPriority, filedata } = this.state
    const email = this.props.userDetails.email
    const paramObj = {
      subject: subject,
      description,
      status: 2,
      email,
      priority: parseInt(selectedPriority),
      filedata: filedata.result,
    }
    this.props.createTicket(paramObj)
    // }
  }

  handleUserInput = e => {
    const name = e.target.name
    const value = e.target.value
    // let errors = this.state.errors;
    // if (value && value !== "") {
    //   errors[name] = "";
    // }
    // this.setState({ errors });
    this.setState({ [name]: value })
  }

  openActionPopup = fileId => {
    this.setState({ selectedAction: fileId })
  }
  closeActionPopup = () => {
    if (this.state.selectedAction) {
      this.setState({ selectedAction: null })
    }
  }

  handleDelete = id => {
    this.setState({ isCloseTicketModalOpen: true, ticketToClose: id })
  }

  handleUpdateTicket = id => {
    const ticketToEdit = this.props.listTickets?.tickets[0].tickets.filter(
      ticket => {
        return ticket.id === id
      }
    )
    this.setState({
      isUpdateTicketModalOpen: true,
      ticketToUpdate: ticketToEdit,
    })
  }

  onCloseTicket = () => {
    const paramsObj = {
      id: this.state.ticketToClose,
    }
    this.props.closeTicketRequest(paramsObj)
    this.setState({ isCloseTicketModalOpen: false })
  }

  onCloseTicketClose = () => {
    this.setState({ isCloseTicketModalOpen: false })
  }
  onCloseUpdateTicket = () => {
    this.setState({ isUpdateTicketModalOpen: false })
  }

  render() {
    const {
      showCreateTicketModal,
      tickets,
      subject,
      selectedPriority,
      description,
      isCloseTicketModalOpen,
      memberToDelete,
      isUpdateTicketModalOpen,
    } = this.state
    return (
      <div>
        <NavBar />
        <Sidebar />
        <RightBar />

        <div
          className={`${commonStyles.common_workflow_management_wrapper} ${styles.root}`}
        >
          <div className={styles.support_page_header_section}>
            <i
              onClick={this.navigateBack}
              className={`fas fa-arrow-left ${styles.support_page_header_arrow_icon}`}
            ></i>
            <div className={styles.support_page_header_text}>Support</div>
            <div
              onClick={this.handleCreateTicket}
              className={styles.create_ticket_button}
            >
              <div className={styles.create_ticket_button_content}>
                <div>Create New Ticket</div>

                <div>
                  <img src={plus_circle} alt="plus_circle"></img>
                </div>
              </div>
            </div>
          </div>

          <div
            onClick={this.closeActionPopup}
            className={styles.support_page_container}
          >
            {this.props.listTickets?.tickets &&
              this.props.listTickets?.tickets.length > 0 && (
                <div className={`support_page_tabs ${styles.tabs}`}>
                  <Tabs
                    defaultActiveKey="all_tickets"
                    transition={false}
                    onSelect={k => this.handleActiveTab(k)}
                  >
                    <Tab
                      tabClassName={styles.tab_root}
                      eventKey="all_tickets"
                      title={`All Tickets ( ${this.props.listTickets?.tickets[0]?.tickets?.length} )`}
                    ></Tab>

                    <Tab
                      tabClassName={styles.tab_root}
                      eventKey="open"
                      title={`Open ( ${this.props.listTickets?.tickets[1]?.tickets?.length} )`}
                    ></Tab>
                    <Tab
                      tabClassName={styles.tab_root}
                      eventKey="pending"
                      title={`Pending ( ${this.props.listTickets?.tickets[2]?.tickets?.length} )`}
                    ></Tab>
                    <Tab
                      tabClassName={styles.tab_root}
                      eventKey="resolved"
                      title={`Resolved ( ${this.props.listTickets?.tickets[3]?.tickets?.length} )`}
                    ></Tab>
                    <Tab
                      tabClassName={styles.tab_root}
                      eventKey="closed"
                      title={`Closed ( ${this.props.listTickets?.tickets[4]?.tickets?.length} )`}
                    ></Tab>
                  </Tabs>
                </div>
              )}

            <table className={styles.support_page}>
              <thead>
                <tr className={styles.support_page}>
                  <th>
                    <span>TICKET ID</span>
                  </th>
                  <th>
                    <span>SUBJECT</span>
                  </th>
                  <th>
                    <span>STATUS</span>
                  </th>
                  <th>
                    <span>DUE DATE</span>
                  </th>
                  {/* <th>
                <span>PRIORITY</span>
              </th> */}
                </tr>
              </thead>
              <tbody>
                {tickets &&
                  tickets.length > 0 &&
                  tickets.map((data, index) => {
                    return (
                      <tr
                        style={{
                          opacity:
                            data.status.toLowerCase() === 'closed'
                              ? '0.25'
                              : '1',
                        }}
                        key={index}
                      >
                        <td>
                          <div className={styles.id_container}>
                            {/* <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={(e)=>{renderDashboardTooltip(e, 'priority')}}
          
        > */}
                            <div class="tooltipp">
                              <span
                                style={{
                                  backgroundColor:
                                    priorityColorMap[data.priority],
                                }}
                                className={styles.priority_badge}
                              ></span>
                              <span
                                style={{
                                  backgroundColor:
                                    priorityColorMap[data.priority],
                                }}
                                className={`tooltiptextt ${
                                  data.priority === 'High'
                                    ? 'highP'
                                    : data.priority === 'Medium'
                                    ? 'mediumP'
                                    : data.priority === 'Urgent'
                                    ? 'urgentP'
                                    : 'lowP'
                                }`}
                              >
                                {data.priority}
                              </span>
                            </div>
                            {/* <span style={{backgroundColor:priorityColorMap[data.priority]}} className={styles.priority_badge}></span> */}
                            {/* </OverlayTrigger> */}
                            <span className={styles.support_id}>{data.id}</span>
                          </div>
                        </td>

                        <td>
                          <span>{data.description_text}</span>
                        </td>
                        <td>
                          <span>{data.status} </span>
                        </td>
                        <td>
                          <span>{data.due_by} </span>
                        </td>
                        {/* <td>
                    <span><i class="fas fa-chevron-up"></i> </span>
                  </td> */}

                        <td style={{ position: 'relative', cursor: 'pointer' }}>
                          {data.status !== 'Closed' ? (
                            <i
                              onClick={() => this.openActionPopup(data.id)}
                              className="fas fa-ellipsis-v"
                            ></i>
                          ) : (
                            <i
                              onClick={() => this.openActionPopup(data.id)}
                              className="fas fa-ellipsis-v"
                              style={{ color: '#e8e8e8', cursor: 'default' }}
                            ></i>
                          )}

                          {this.state.selectedAction === data.id &&
                            data.status !== 'Closed' && (
                              <div className={styles.action_popup}>
                                <p
                                  onClick={() => {
                                    this.handleUpdateTicket(data.id)
                                  }}
                                  className={styles.actionText}
                                >
                                  Update
                                </p>
                                <p
                                  onClick={() => {
                                    this.handleDelete(data.id)
                                  }}
                                  className={styles.actionText}
                                >
                                  Close
                                </p>
                              </div>
                            )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {showCreateTicketModal && (
          <CommonModal
            modalClass={'support_ticket_common_modal'}
            closeHandler={this.supportPageModalHandler}
            backdropClassName={'new_project_modal_blue_bg'}
            header={
              <div className={styles.header}>
                <div>Create New Tickets</div>
              </div>
            }
          >
            <div>
              <section className={styles.section_client_name}>
                <div className={styles.client_name}>
                  <div className={styles.label}>ENTER SUBJECT</div>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    onChange={event => this.handleUserInput(event)}
                    placeholder="Subject"
                  />
                </div>
                <div className={styles.accessibility}>
                  <div className={styles.label}>CHOOSE PRIORITY</div>
                  <CommonDropdown
                    backgroundColor="#fafafa"
                    value0="Choose"
                    fontSize="18px"
                    width="100%"
                    height="51px"
                    borderRadius="4px"
                    iconSize="24px"
                    padding="13px 24px"
                    textColor="#000"
                    name="selectedPriority"
                    dropdownList={priorityDropdown}
                    selectedDropdown={this.selectedDropdown}
                  />
                </div>
              </section>

              <section className={styles.section_address}>
                <div className={styles.label}>DETAILS</div>
                {/* <input
              type="text"
              name="address"
            //   value={address}
              onChange={event => this.handleUserInput(event)}
              placeholder="Project Info"
            /> */}
                <textarea
                  name="description"
                  value={description}
                  onChange={event => this.handleUserInput(event)}
                  rows="3"
                ></textarea>
              </section>

              <label>{this.state.fileName}</label>
              <div className={styles.project_modal_bottom_content}>
                <Dropzone
                  onDrop={acceptedFiles => this.onfileFound(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />

                      <section className={styles.section_btn}>
                        <div className={styles.upload_doc}>
                          <span>Upload Documents</span>
                          <img src={UploadSVG} alt="cloud Upload Icon" />
                        </div>
                      </section>
                    </div>
                  )}
                </Dropzone>

                <div
                  onClick={this.createNewTicket}
                  className={styles.create_project}
                >
                  <div> CREATE TICKET </div>
                </div>
              </div>
            </div>
          </CommonModal>
        )}

        {isCloseTicketModalOpen && (
          <DeleteTicket
            memberToDelete={memberToDelete}
            closeHandler={this.onCloseTicketClose}
            onCloseTicket={this.onCloseTicket}
          ></DeleteTicket>
        )}
        {isUpdateTicketModalOpen && (
          <UpdateTicket
            ticket={this.state.ticketToUpdate}
            closeHandler={this.onCloseUpdateTicket}
            onCloseTicket={this.onCloseTicket}
          ></UpdateTicket>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.homepage.userDetails,
    listTickets: state.support.tickets,
    isLoading: state.support.isLoading,
    isTicketClosed: state.support.isTicketClosed,
    isTicketCreated: state.support.isTicketCreated,
    isTicketUpdated: state.support.isTicketUpdated,
    message: state.support.message,
  }
}
const mapDispatchToProps = {
  listTicketsRequest: supportActions.fetchAllTicketsRequest,
  closeTicketRequest: supportActions.deleteTicketRequest,
  createTicket: supportActions.createTicketRequest,
  saveAdvancedSearchFilters:
    catalogLandingPage.catalogAdvancedSearchFilterAction,
  saveSearchTermRequest: catalogSearchResults.catalogSearchResultSearchTermAction,
  saveSelectedSources: catalogSearchResults.catalogSelectedSourcesAction,
  saveFilters: workflowManagementActions.workflowManagementSaveFilters,
  saveFilteredDocuments:
    workflowManagementActions.workflowManagementSaveFilteredDocuments,
  saveFiltersSearchResultsPage:
    catalogSearchResults.catalogSearchResultFilterAction,
  saveFilteredDocumentsSearchResultsPage:
    catalogSearchResults.catalogSearchResultFilteredDocumentsAction,
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportPage)
