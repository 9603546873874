import React, { useState, useEffect } from 'react'
import { Route, Switch, BrowserRouter, useLocation } from 'react-router-dom'
import { AnimatedRoute, AnimatedSwitch } from 'react-router-transition'

import PrivateRoute from './PrivateRoutes'

import Homepage from '../screens/homepage/Hompage'
import AccountSettings from '../screens/account-settings/AccountSettings'
import Dashboard from '../screens/dashboard/Dashboard'
import Documents from '../screens/documents/Documents'
import Reports from '../screens/reports/Reports'
import Teams from '../screens/teams/Teams'
import Templates from '../screens/templates/Templates'
import UploadedDoc from '../screens/uploaded-doc/UploadedDoc'
import ClusteredResult from '../screens/clustered-result/ClusteredResult'
import AllDocuments from '../screens/all-documents/AllDocuments'
import SearchResults from '../screens/search-results/SearchResults'
import AdminView from '../screens/admin-view/AdminView'
import UsersDetails from '../screens/users-details/UsersDetails'
import InReviewFilesView from '../screens/in-review-file-view/InReviewFileView'
import Notifications from '../screens/notifications/Notifications'
import Graphs from '../screens/graphs/Graphs'
import Support from '../screens/support/Support'
import Help from '../screens/help/Help'
import Submit from '../screens/submit/Submit'
import ClusteredResultAfterResponsePrepared from '../screens/clustered-result-after-response-prepared/ClusteredResultAfterResponsePrepared'
import DataCatalog from '../screens/data-catalog/DataCatalog'
import SearchResultsPage from '../screens/search-results-page/SearchResultsPage'
import RelatedSearchesPage from './../components/data-catalog/related-searches-page/RelatedSearchesPage'
import DataCatalogAllFolders from '../screens/data-catalog-all-folders/DataCatalogAllFolders'
import AllFoldersTree from '../screens/all-folders-tree/AllFoldersTree'
import WorkflowManagement from '../screens/workflow-management/WorkflowManagement'
import AllProjects from '../screens/all-projects/AllProjects'
import DocumentAnalytics from './../components/workflow-management/DocumentAnalytics/DocumentAnalytics'
import Notification from '../screens/notification-v2/Notification'
import DashboardV2 from '../screens/dashboard-v2/Dashboard'
import SupportPage from '../screens/support-page/SupportPage'
import EmailModal from '../screens/dashboard-v2/modal/EmailModal'
import SignInPage from '../screens/sign-in-page-v2/SignInPage'
import MasterList from './../screens/master-list/MasterList'
import { LastLocationProvider } from 'react-router-last-location'
import CreateDocument from '../screens/create-document/CreateDocument'
import DocumentList from '../screens/documents-list/DocumentList'
import CustomNode from './../components/custom-node/CustomNode'

const AppRoute = ({
  triggerErrorToast,
  triggerSuccessToast,
  isCreateDocumentDisplayed,
}) => {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Switch>
          <Route
            exact
            path="/"
            component={props => (
              <Homepage
                triggerErrorToast={triggerErrorToast}
                triggerSuccessToast={triggerSuccessToast}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/signup"
            component={props => (
              <Homepage
                triggerErrorToast={triggerErrorToast}
                triggerSuccessToast={triggerSuccessToast}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/forgot-password"
            component={props => (
              <Homepage
                triggerErrorToast={triggerErrorToast}
                triggerSuccessToast={triggerSuccessToast}
                {...props}
              />
            )}
          />
          <PrivateRoute
            exact
            path="/settings"
            component={props => (
              <AccountSettings
                triggerErrorToast={triggerErrorToast}
                triggerSuccessToast={triggerSuccessToast}
                {...props}
              />
            )}
          />
          {/* <PrivateRoute
            exact
            path="/dashboard"
            // component={props => (
            //   <Dashboard triggerSuccessToast={triggerSuccessToast} {...props} />
            // )}
            component={DashboardV2}
          /> */}
          <PrivateRoute exact path="/documents" component={Documents} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute exact path="/teams" component={Teams} />
          <PrivateRoute exact path="/templates" component={Templates} />
          <PrivateRoute exact path="/Uploaded-doc" component={UploadedDoc} />
          <PrivateRoute
            exact
            path="/clustered-result"
            component={ClusteredResult}
          />
          <PrivateRoute exact path="/all-documents" component={AllDocuments} />
          <PrivateRoute
            exact
            path="/search-results"
            component={SearchResults}
          />
          <PrivateRoute exact path="/admin" component={AdminView} />
          <PrivateRoute exact path="/users" component={UsersDetails} />
          <PrivateRoute
            exact
            path="/inreview-files"
            component={InReviewFilesView}
          />
          <PrivateRoute exact path="/notifications" component={Notifications} />

          <PrivateRoute exact path="/graphs" component={Graphs} />
          {/* <PrivateRoute exact path="/support" component={Support} /> */}
          <PrivateRoute exact path="/help" component={Help} />
          <PrivateRoute exact path="/submit" component={Submit} />
          <PrivateRoute
            exact
            path="/test"
            component={ClusteredResultAfterResponsePrepared}
          />
          <PrivateRoute exact path="/data-catalog" component={DataCatalog} />
          <PrivateRoute
            exact
            path="/data-catalog-2"
            component={SearchResultsPage}
          />
          <PrivateRoute
            exact
            path="/related-searches-page"
            component={RelatedSearchesPage}
          />
          <PrivateRoute exact path="/master-list" component={MasterList} />
          <PrivateRoute
            exact
            path="/selected-folder"
            component={DataCatalogAllFolders}
          />
          <PrivateRoute
            exact
            path="/all-folders-tree"
            component={AllFoldersTree}
          />
          <PrivateRoute
            exact
            path="/workflow-management"
            component={WorkflowManagement}
          />
          <PrivateRoute exact path="/all-projects" component={AllProjects} />
          <PrivateRoute
            exact
            path="/document-analytics"
            component={DocumentAnalytics}
          />
          <PrivateRoute
            exact
            path="/notification-v2"
            component={Notification}
          />

          <PrivateRoute exact path="/support-page" component={SupportPage} />
          <PrivateRoute exact path="/sign-in-v2" component={SignInPage} />
          <PrivateRoute exact path="/document-list" component={DocumentList} />
          <PrivateRoute exact path="/custom-node" component={CustomNode} />

          {/* <PrivateRoute exact path="/dashboard" component={DashboardV2} />
          <PrivateRoute path="/create-document" component={CreateDocument} /> */}

        </Switch>
        <AnimatedSwitch
          atEnter={{ offset: -100 }}
          atLeave={{ offset: 100 }}
          atActive={{ offset: 0 }}
          className="switch-wrapper"
          mapStyles={styles => ({
            transform: `translateX(${styles.offset}%)`,
          })}
        >
          <PrivateRoute path="/create-document" component={CreateDocument} />
        </AnimatedSwitch>

        <AnimatedSwitch
          atEnter={isCreateDocumentDisplayed ? { offset: -100 } : { offset: 0 }}
          atLeave={{ offset: 0 }}
          atActive={{ offset: 0 }}
          className="switch-wrapper"
          mapStyles={styles => ({
            transform: `translateX(${styles.offset}%)`,
          })}
        >
          <PrivateRoute exact path="/dashboard" component={DashboardV2} />
        </AnimatedSwitch>
      </LastLocationProvider>
    </BrowserRouter>
  )
}

export default AppRoute
