import React, { Component } from "react";
import styles from "./AllDocumentsDocInfo.module.css";
import { connect } from 'react-redux';
import * as uploadedDoc from '../../../store/actions/uploadedDocActions';
import * as dashboard from '../../../store/actions/dashboardActions';
import { createParamsString } from '../../../shared/helper';
// import Loader from '../../../shared/loader/Loader'
class AllDocumentsDocInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClusteredDataRequested: false,
            uploadingInProcess: false
        }
    }
    componentDidUpdate(prevProps){
        if (this.props.uploadedDoc.clusterData  && prevProps.isUploadedDocLoading && !this.props.isUploadedDocLoading) {
            this.props.history.push('/clustered-result', {from:this.props.from})

        }

        if (this.props.dashboard.fileRead && !this.props.isDashboardLoading && this.state.uploadingInProcess && this.props.history) {
            const url = '/uploaded-doc' + createParamsString({origin: 'fileRead'});
            this.props.history.push(url, {from:this.props.from})
        }
    }
    handleOpenDocument = (docInfo) => {
        let selectedFileData = this.props.selectedFileData
        if(selectedFileData.isClustered){
        const paramsObj={
            fileId: selectedFileData.fileId,
            version:selectedFileData.version,
            fileVersionId:selectedFileData.fileVersionId,
            minor:selectedFileData.minor?.toString(),
            docType:selectedFileData.docType?.toString(),
        }
        this.props.clusterDataRequest(paramsObj, {fileId:selectedFileData.fileId}, {fileId:selectedFileData.fileId})
        this.setState({ isClusteredDataRequested: true })
    }
    else{
        const paramsObj={
            fileId: selectedFileData.fileId,
            version: selectedFileData.version
        }
        this.props.fileReadRequest(paramsObj)
        this.setState({ uploadingInProcess: true })
    }
    }
    render() {
        return (
            <div className='thin_custom_scrollbar'>
              {/* {(this.props.isDashboardLoading || this.props.isUploadedDocLoading) &&  <Loader></Loader> } */}
                <div className={styles.section_wrapper}>
                    <div className={styles.two_column_row}>
                        <div>
                            <div className={styles.doc_name}>
                                <span className={styles.heading}>NAME <br></br></span><span className={styles.heading_value}>{this.props.selectedFileData.docName}</span>
                            </div>
                            <div className={styles.uploaded_by_row}>
                                <span className={styles.heading}>  UPLOADED BY </span> <br></br><span className={styles.heading_value}>{this.props.selectedFileData.docUploadedBy}</span>
                            </div>
                        </div>
                        <div className={styles.doc_status}>
                            <span>{this.props.selectedFileData.docStatus}</span>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className={styles.section_wrapper}>
                    <div className={styles.two_column_row}>
                        <div className={styles.heading}>
                            Permissions
    </div>
                        <div className={styles.heading}>
                            {this.props.selectedFileData.permission}
    </div>
                    </div>
                </div>
                <hr></hr>
                <div className={styles.file_journey_container}>
                    <div className={styles.title_section}>
                        <span className={styles.file_journey_title}>File Journey</span>
                        <span onClick={() =>this.handleOpenDocument()} className={styles.open_doc_title}><u>Open Document</u></span>
                    </div>
                    {!this.props.isLoading && this.props.fileJourneyData.map(history => {
                        return (
                            <div key={history.fileId} className={styles.file_journey_section}>
                                <div className={styles.status_journey}>
                                    <div>
                                        {history.action}
                                    </div>
                        <div className={styles.date}>{this.props.selectedFileData.dueDate?this.props.selectedFileData.dueDate:'-'}</div>
                                </div>
                                <div className={styles.status_changed_by}>
                                    <div>
                                        {history.actionBy}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        fileJourneyData: state.allDocuments.fileJourneyData.data.journey,
        isLoading: state.allDocuments.isLoading,
        uploadDoc: state.uploadDoc,
        uploadedDoc: state.uploadedDoc,
        isUploadedDocLoading: state.uploadedDoc.isLoading,
        dashboard:state.dashboard,
        isDashboardLoading: state.dashboard.isFileReadLoading,
    };
}
const mapDispatchToProps = {
    clusterDataRequest: uploadedDoc.clusterDataRequest,
    fileReadRequest: dashboard.fileReadRequest,
    similarParaRequest: uploadedDoc.similarParaRequest,
    similarDocsRequest: uploadedDoc.similarDocsRequest,

}


export default connect(mapStateToProps, mapDispatchToProps)(AllDocumentsDocInfo);



