import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import zxcvbn from 'zxcvbn'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { connect } from 'react-redux'
import { IconContext } from 'react-icons'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

import { handleError, handleSuccess } from '../../../../shared/HandleMessages'
import ErrorText from '../../../../shared/error-text/ErrorText'
import * as accountSettings from './../../../../store/actions/accountSettingsActions'
import UserImageSVG from './../../../../assets/account_settings_user_image.svg'
import PencilSVG from './../../../../assets/pencil_black.svg'
import CommonModal from '../../../../shared/common-modal/CommonModal'
import commonStyles from '../../../../shared/Common.module.css'
import styles from './AccountSettingsModal.module.css'

//Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
const STRONG_PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
)
// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number:
const MEDIUM_PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/
)
// Minimum eight characters, at least one letter, one number and one special character:
const WEAK_PASSWORD_REGEX = new RegExp(
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,}$/
)

const ALPHA_NUMERIC_REGEX = new RegExp(/^[a-zA-Z0-9]{4,}$/i)
// new RegExp(/^[a-zA-Z0-9]{4,}+$/)

class AccountSettingsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.props.user.name,
      email: this.props.user.email,
      company: this.props.user.company,
      designation: this.props.user.designation,
      oldPassword: '',
      newPassword: '',
      reEnterPassword: '',
      passwordStrength: null,
      score: 'null',
      showPassword: false,
      showPasswordPanel: false,
      showInfoIconPanel: false,
      profileImage: 'https://www.w3schools.com/howto/img_avatar.png',
      selectImage: null,
      croppedImageUrl: null,
      base64Image: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 16 / 9,
      },
      errors: {
        name: '',
        designation: '',
        newPassword: 'Enter new password',
        reEnterPassword: '',
        oldPassword: 'Enter old password',
      },
      formsInvalid: true,
    }
  }

  passwordStrength = value => zxcvbn(value)

  togglePasswordShow = () => {
    const { showPassword } = this.state
    this.setState({
      showPassword: !showPassword,
    })
  }

  togglePasswordPanel = () => {
    const { showPasswordPanel } = this.state
    this.setState({
      showPasswordPanel: !showPasswordPanel,
    })
  }

  toggleInfoIconPanel = () => {
    const { showInfoIconPanel } = this.state
    this.setState({
      showInfoIconPanel: !showInfoIconPanel,
    })
  }
  closeInfoIconPanel = () => {
    this.setState({
      showInfoIconPanel: false,
    })
  }

  onSelectFile = event => {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0]) // read file as data url
      reader.onload = event => {
        this.setState({ showModal: true, selectImage: event.target.result })
      }
    }
  }

  onImageLoaded = image => {
    this.imageRef = image
  }

  handleModalClose = () => {
    this.setState({ showModal: false })
  }
  onCropComplete = crop => {
    this.makeClientCrop(crop)
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop })
  }

  makeClientCrop = async crop => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const base64Image = canvas.toDataURL('image/jpeg')
    this.setState({ base64Image })

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty')
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  handleRemoveImage = () => {
    this.setState({
      profileImage: 'https://www.w3schools.com/howto/img_avatar.png',
    })
    this.handleModal()
  }
  handleSaveImage = () => {
    this.setState({ profileImage: this.state.croppedImageUrl })
    this.handleModal()
  }

  handleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  getPasswordStrength(password) {
    let passwordStrength = 0

    if (STRONG_PASSWORD_REGEX.test(password)) {
      passwordStrength = 100
    } else if (MEDIUM_PASSWORD_REGEX.test(password)) {
      passwordStrength = 80
    } else if (WEAK_PASSWORD_REGEX.test(password)) {
      passwordStrength = 60
    } else if (ALPHA_NUMERIC_REGEX.test(password)) {
      passwordStrength = 40
    } else if (password.length >= 2) {
      passwordStrength = 20
    }
    return passwordStrength
  }

  handleUserInput = e => {
    const name = e.target.name
    const value = e.target.value

    let newPassword = this.state.newPassword

    // console.info('name: ', name, 'value:', value)

    let errors = this.state.errors
    let formsInvalid = false
    let passwordStrength = this.state.passwordStrength

    switch (name) {
      case 'name':
        errors.name =
          value.length < 5 ? 'Full Name must be 5 characters long!' : ''
        formsInvalid = errors.name.length > 0 ? true : false
        break
      case 'designation':
        errors.designation = value.length < 1 ? 'Designation is Required!' : ''
        formsInvalid = errors.designation.length > 0 ? true : false
        break
      case 'oldPassword':
        errors.oldPassword =
          value.length < 8 ? 'Password must be 8 characters long!' : ''
        formsInvalid = errors.oldPassword.length > 0 ? true : false
        break
      case 'newPassword':
        errors.newPassword = STRONG_PASSWORD_REGEX.test(value)
          ? ''
          : 'Password is too weak!'
        formsInvalid = STRONG_PASSWORD_REGEX.test(value) ? false : true
        passwordStrength = this.getPasswordStrength(value)
        break
      case 'reEnterPassword':
        errors.reEnterPassword =
          newPassword === value ? '' : "Password don't match!"
        formsInvalid = newPassword === value ? false : true
        break
      default:
        break
    }

    this.setState({ errors, [name]: value, formsInvalid, passwordStrength })
  }

  handleEditProfile = () => {
    const {
      formsInvalid,
      name,
      email,
      designation,
      profileImage,
      croppedImageUrl,
      base64Image,
      oldPassword,
      newPassword,
      reEnterPassword,
      passwordStrength,
    } = this.state
    // if (passwordStrength < 100) {
    //   handleError('password is weak')
    //   return
    // }

    if (
      formsInvalid ||
      !name ||
      !email ||
      !designation ||
      reEnterPassword !== newPassword
    ) {
      if (reEnterPassword !== newPassword) {
        this.setState({ formsInvalid: true })
        handleError("password don't match")
      }
      this.setState({ showError: true })
    } else {
    
      const paramsObj = {
        name: name,
        email: email,
        designation: designation,
        profile: base64Image,
        password: newPassword,
        old_password: oldPassword,
        confirm_password: reEnterPassword,
      }
     
      this.setState({ showError: false }, () => {
        // TODO: API CALL HERE
        handleSuccess('Changes Saved')
        setTimeout(() => {
          const updateProfileData = this.props.updateProfileRequest(paramsObj)
          
          this.props.accountSettingsModalHandler()
        }, 1000)
      })
    }

    /* For Password */
    /* if (passwordStrength < 100) {
      handleError('password is weak')
      return
    }
    if (
      formsInvalid ||
      !oldPassword ||
      !newPassword ||
      reEnterPassword !== newPassword
    ) {
      if (reEnterPassword !== newPassword) {
        this.setState({ formsInvalid: true })
        handleError("password don't match")
      }
      this.setState({ showError: true })
    } else {
      this.setState({ showError: false })
      const paramsObj = {
        old_password: oldPassword,
        password: newPassword,
        confirm_password: newPassword,
      }
      // this.props.onchangePassword(paramsObj)
    } */
  }
  /*  handleChangePassword = () => {
    const {
      formsInvalid,
      oldPassword,
      newPassword,
      reEnterPassword,
      passwordStrength,
    } = this.state
    if (passwordStrength < 100) {
      handleError('password is weak')
      return
    }
    if (
      formsInvalid ||
      !oldPassword ||
      !newPassword ||
      reEnterPassword !== newPassword
    ) {
      if (reEnterPassword !== newPassword) {
        this.setState({ formsInvalid: true })
        handleError("password don't match")
      }
      this.setState({ showError: true })
    } else {
      this.setState({ showError: false })
      const paramsObj = {
        old_password: oldPassword,
        password: newPassword,
        confirm_password: newPassword,
      }
      this.props.onchangePassword(paramsObj)
    }
  } */

  render() {
    const { accountSettingsModalHandler, user } = this.props
    const {
      score,
      showPassword,
      showPasswordPanel,
      showInfoIconPanel,
      profileImage,
      crop,
      selectImage,
      showModal,
      showError,
      errors,
      name,
      company,
      email,
      designation,
      oldPassword,
      newPassword,
      reEnterPassword,
      passwordStrength,
    } = this.state
    // console.info('user => ', user)

    return (
      <CommonModal
        modalClass={'account_settings_modal'}
        closeHandler={accountSettingsModalHandler}
        backdropClassName={'account_settings_modal_blue_bg'}
        header={
          <section className={styles.header}>
            <div>Account Settings</div>
          </section>
        }
      >
        <div className={styles.root}>
          <div className={styles.left_part}>
            <div className={styles.edit_photo}>
              <img src={profileImage} alt="User Image" />
              <div>
                {/*  <span>EDIT PHOTO</span>
                <img src={PencilSVG} alt="Pencil Icon" /> */}
                <input
                  className={styles.file_input}
                  id="fileInput"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={this.onSelectFile}
                />
                <div>
                  <label htmlFor="fileInput" className={styles.label_container}>
                    <div className={styles.picture_change_text}>Edit Photo</div>
                    <img src={PencilSVG} alt="Pencil Icon" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.right_part}>
            <div className={styles.label_and_input_container}>
              <div className={styles.label}>NAME</div>
              <div className={styles.input_container}>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={this.handleUserInput}
                />
                <img src={PencilSVG} alt="Pencil Icon" />
              </div>
              {showError && errors.name && <ErrorText text={errors.name} />}
            </div>
            <div className={styles.label_and_input_container}>
              <div className={styles.label}>EMAIL ADDRESS</div>
              <div className={styles.input_container}>
                <input type="text" value={email} disabled />
              </div>
            </div>
            <div className={styles.label_and_input_container}>
              <div className={styles.label}>COMPANY</div>
              <div className={styles.input_container}>
                <input type="text" value={company} disabled />
              </div>
            </div>
            <div className={styles.label_and_input_container}>
              <div className={styles.label}>DESIGNATION</div>
              <div className={styles.input_container}>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={this.handleUserInput}
                />
                <img src={PencilSVG} alt="Pencil Icon" />
              </div>
              {showError && errors.designation && (
                <ErrorText text={errors.designation} />
              )}
            </div>

            <div className={styles.change_password_header}>
              <span>CHANGE PASSWORD</span>
              <span
                className={styles.arrow_icon}
                onClick={this.togglePasswordPanel}
              >
                <IconContext.Provider
                  value={{
                    size: 21,
                    color: '#fff',
                  }}
                >
                  {showPasswordPanel ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </IconContext.Provider>
              </span>
            </div>

            {showPasswordPanel && (
              <div className={styles.password_container}>
                <div className={styles.label_and_input_container}>
                  <div className={styles.label}>Old Password</div>
                  <div className={styles.input_container}>
                    <input
                      type="text"
                      name="oldPassword"
                      value={oldPassword}
                      onChange={this.handleUserInput}
                    />
                  </div>
                  {showError && errors.oldPassword && (
                    <ErrorText text={errors.oldPassword} />
                  )}
                </div>

                <div
                  className={`${styles.label_and_input_container} ${styles.new_password}`}
                >
                  <div className={styles.new_password_label_container}>
                    <span className={styles.label}>New Password</span>
                    <div className={styles.info_icon_container}>
                      <div
                        className={styles.info_icon}
                        onClick={this.toggleInfoIconPanel}
                      >
                        <IconContext.Provider
                          value={{
                            size: 18,
                            color: '#7a7a7a',
                          }}
                        >
                          <BsFillInfoCircleFill />
                        </IconContext.Provider>
                      </div>
                      <OutsideClickHandler
                        onOutsideClick={this.closeInfoIconPanel}
                      >
                        {showInfoIconPanel && (
                          <div className={styles.info_icon_panel}>
                            <div className={styles.info_panel_heading}>
                              PASSWORD RULES
                            </div>
                            <div className={styles.info_panel_content}>
                              <div>
                                <span>.</span>
                                <span>Min 8 characters</span>
                              </div>
                              <div>
                                <span>.</span>
                                <span>Upper-case characters</span>
                              </div>
                              <div>
                                <span>.</span>
                                <span>Lower-case characters</span>
                              </div>
                              <div>
                                <span>.</span>
                                <span>Number 0-9</span>
                              </div>
                              <div>
                                <span>.</span>
                                <span>Special Characters</span>
                                <div>{'(E.g. ,!%^&*)'}</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </OutsideClickHandler>
                    </div>
                  </div>
                  <div className={styles.input_container}>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="newPassword"
                      value={newPassword}
                      onChange={this.handleUserInput}
                    />
                    <span
                      className={styles.eye_icon}
                      onClick={this.togglePasswordShow}
                    >
                      <IconContext.Provider
                        value={{
                          size: 24,
                          color: '#c4c4c4',
                        }}
                      >
                        {showPassword ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </IconContext.Provider>
                    </span>
                  </div>
                  {showError && errors.newPassword && (
                    <ErrorText text={errors.newPassword} />
                  )}
                  <div
                    className={styles.password_strength_bar}
                    data-score={`${passwordStrength}`}
                  ></div>
                </div>

                <div className={styles.label_and_input_container}>
                  <div className={styles.label}>Re-type New Password</div>
                  <div className={styles.input_container}>
                    <input
                      type="password"
                      name="reEnterPassword"
                      value={reEnterPassword}
                      onChange={this.handleUserInput}
                    />
                  </div>
                  {showError && errors.reEnterPassword && (
                    <ErrorText text={errors.reEnterPassword} />
                  )}
                </div>
              </div>
            )}

            <span
              className={styles.save_changes_btn}
              onClick={this.handleEditProfile}
            >
              SAVE CHANGES
            </span>
          </div>

          {/* For Image Crop */}
          {showModal && (
            <CommonModal
              modalClass={'team_common_modal'}
              closeHandler={this.handleModalClose}
              header="Change Picture"
            >
              <div className={`row ${commonStyles.no_gutter}`}>
                <div className={`col-md-12 ${styles.picture_selection}`}>
                  <ReactCrop
                    src={selectImage}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </div>
              </div>
              <div className={styles.buttons_wrapper}>
                <button
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                  onClick={this.handleModal}
                >
                  DISCARD
                </button>
                <button
                  className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
                  onClick={this.handleRemoveImage}
                >
                  <i className="fa fa-trash"></i> Remove Picture
                </button>
                <button
                  type="button"
                  className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
                  onClick={this.handleSaveImage}
                >
                  SAVE CHANGES
                </button>
              </div>
            </CommonModal>
          )}
        </div>
      </CommonModal>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.homepage.userDetails,
    successMessage: state.accountsSettings.successMessage,
    error: state.accountsSettings.error,
  }
}
const mapDispatchToProps = {
  updateProfileRequest: accountSettings.updateProfileRequest,
  changePasswordRequest: accountSettings.changePasswordRequest,
  clearaccountSettingsResponseMessages:
    accountSettings.clearaccountSettingsResponseMessages,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsModal)
