import React from "react";
import styles from "./AdminViewOptions.module.css";
import commonStyles from '../../shared/Common.module.css';


const AdminViewOptions = (props) => {
  const  handleTitleClick = (url) => {
    props.history.push(url)
    }
    return (
        <React.Fragment>
            <div className={styles.admin_view_options_card_container}>
                <div className={`card ${commonStyles.card_layout}`}>
                    <div className={`card-body ${styles.admin_view_options_card_body}`}>
                        {/* <div className={styles.doc_count}>{props.docCount.count}</div> */}
                        <div className={styles.title}>{props.data.title} <i className="fas fa-greater-than"></i> </div>
                        {props.data.titleDetails.map((data, i) => {
                            return (
                            <div onClick={() => {handleTitleClick(data.url)}} key={i} className={styles.title_details}> {data.title} </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default AdminViewOptions;