import React, { Component } from 'react'

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { IconContext } from 'react-icons'

// import PrecilyIconSVG from './../../assets/precily_icon_sign_in_page.svg'
import PrecilyIconSVG from './../../assets/PrecilyImg2.svg'

import styles from './SignInPage.module.css'

export default class SignInPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      email: null,
      password: null,
    }
  }

  togglePasswordShow = () => {
    const { showPassword } = this.state
    this.setState({
      showPassword: !showPassword,
    })
  }

  handleEmailChange = e => {
    this.setState({
      email: e.target.value,
    })
  }
  handlePasswordChange = e => {
    this.setState({
      password: e.target.value,
    })
  }

  render() {
    const { email, password, showPassword } = this.state

    return (
      <div className={styles.root}>

        <div className={styles.left_part}>
          <img src={PrecilyIconSVG} alt="Precily Icon" />
        </div>

        <div className={styles.right_part}>
          <div className={styles.sign_in_form}>
            <div className={styles.heading}>SIGN IN TO PRECILY AI</div>

            <div className={styles.input_container}>
              <div className={styles.label_container}>EMAIL ADDRESS</div>
              <div className={styles.input}>
                <input type="text" />
              </div>
            </div>

            <div className={styles.input_container}>
              <div className={styles.label_container}>
                <div>PASSWORD</div>
                <div className={styles.forgot_password}>Forgot password</div>
              </div>
              <div className={styles.input}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={this.handleUserInput}
                />
                <span
                  className={styles.eye_icon}
                  onClick={this.togglePasswordShow}
                >
                  <IconContext.Provider
                    value={{
                      size: 24,
                      color: '#c4c4c4',
                    }}
                  >
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </IconContext.Provider>
                </span>
              </div>
            </div>

            <div className={styles.captcha_container}></div>

            <button className={styles.sign_in_btn}>SIGN IN</button>
          </div>
        </div>
      </div>
    )
  }
}
