import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as graphData from '../actions/graphDataAction';

function* graphDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestGraphData,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(graphData.graphDataSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(graphData.graphDataFailure(e));
  }
}

function* graphDataWatcher() {
  yield takeLatest(types.FETCH_GRAPH_DATA_REQUEST, graphDataWorker);
}

function* tagsGraphDataWorker(params) {
  try {
    const data = yield call(_api, URLS.tagsGraphData, params.payload, 'POST');
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(graphData.tagsGraphDataSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(graphData.tagsGraphDataFailure(e));
  }
}

function* tagsGraphDataWatcher() {
  yield takeLatest(types.TAGS_GRAPH_DATA_REQUEST, tagsGraphDataWorker);
}

export default function* graphDataSaga() {
  yield all([graphDataWatcher(), tagsGraphDataWatcher()]);
}
