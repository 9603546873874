import React from 'react'
import { IconContext } from 'react-icons'
import { FiFlag, FiArrowUp } from 'react-icons/fi'
import { BsCircleFill } from 'react-icons/bs'
import { withRouter } from 'react-router-dom'

import styles from './AllDocumentsTableView.module.css'
import arrow_up from '../../../assets/arrow_up.svg'
import { formatDate } from '../../../shared/formatDate'
class AllDocumentsTableView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // documents:this.props.files,

      filteredDocumentsData: this.props.files,
      isAscending: 1,
      sortedData: this.props.files, //sortedData is for maintaining order when sort and filter applied simultaneously
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchItems !== this.props.searchItems) {
      this.getFilteredDocuments()
    }
  }

  sortFolders = () => {
    const filteredDocumentsData = this.state.filteredDocumentsData
    filteredDocumentsData.sort((a, b) =>
      a['name'].replace(/%20/g, '').toLowerCase() >
      b['name'].replace(/%20/g, '').toLowerCase()
        ? this.state.isAscending
        : this.state.isAscending * -1
    )
    this.setState({
      filteredDocumentsData,
      isAscending: this.state.isAscending * -1,
      sortedData: filteredDocumentsData,
    })
  }

  getFilteredDocuments = () => {
    let subStr = this.props.searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let filteredDocuments = this.state.sortedData.filter(tag => {
      return (
        tag.name
          .replace(/%20/g, '')
          .toLowerCase()
          .search(subStr.toLowerCase()) !== -1
      )
    })
    this.setState({ filteredDocumentsData: filteredDocuments })
  }

  documentHandler = (fileId, filePath) => {
    this.props.history.push({
      pathname: '/document-analytics',
      search: '?source=workflow-management'
    }, {
      fileId,
      filePath,
    })
  }
  render() {
    const { isAscending, filteredDocumentsData } = this.state
    // console.info('filteredDocumentsData', filteredDocumentsData)
    
    return (
      <>
        <section className={styles.projects_list}>
          <div className={styles.projects_list_header}>
            <span>
              <span>Name</span>
              <img
                className={`${styles.arrow_up} ${
                  isAscending == 1 ? '' : styles.arrow_down
                }`}
                src={arrow_up}
                alt="arrow_up"
                onClick={() => this.sortFolders()}
              ></img>
              {/* <IconContext.Provider
                  value={{
                    size: 21,
                    color: '#c4c4c4',
                  }}
                >
                  <FiArrowUp />
                </IconContext.Provider> */}
            </span>

            {/* <span>Status</span> */}

            <span>Modified On</span>
          </div>

          <div className={styles.project_list}>
            {filteredDocumentsData.map(
              ({ name, status, createdAt, id, path, type }, index) => {
                const timeAndDate = formatDate(createdAt)
                if (index > 4 || type.toLowerCase() !== 'sub') return
                return (
                  <div className={styles.list_container}>
                    <div
                      className={styles.name_and_icon_container}
                      onClick={() => this.documentHandler(id, path)}
                    >
                      <IconContext.Provider
                        value={{
                          size: 18,
                          color: '#F59F00',
                        }}
                      >
                        <FiFlag />
                      </IconContext.Provider>
                      <span className={styles.project_name}>{name}</span>
                    </div>

                    {/* <div className={styles.status_container}>
                      <IconContext.Provider
                        value={{
                          size: 18,
                          color: '#00CC66',
                        }}
                      >
                        <BsCircleFill />
                      </IconContext.Provider>
                      <span className={styles.status}>{status}</span>
                    </div> */}

                    <div className={styles.date_container}>
                      {timeAndDate.date}
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </section>
      </>
    )
  }
}

export default withRouter(AllDocumentsTableView)
