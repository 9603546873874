import React from 'react';
import styles from './DocumentAnalytics.module.css';
import OutsideClickHandler from 'react-outside-click-handler';
// import dotIcon from '../../../assets/green_dot.svg';
import greyPenIcon from '../../../assets/gray_pen.svg';
// import Highlighter from 'react-highlight-words';

class EditTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isEditing: false,
      subTagsSearch: '',
      searchedTags: [],
      selectedTagIndex: null,
      paraSeqId: null,
      newSubTag: '',
    };
  }

  // componentDidMount() {
  //   let comments = this.props.fileComments;
  //   comments.forEach((comment) => {
  //     let text = document.getElementsByClassName();
  //   });
  // }

  handleEditPen = (action, subTag) => {
    if (action === 'close') {
      this.setState({ isEdit: false });
    } else {
      this.setState({ isEdit: subTag });
    }
  };

  tagEditOpenHandler = (action, index) => {
    if (action === 'close') {
      this.setState({ isEditing: false, subTagsSearch: '', searchedTags: [] });
    } else if (action === 'updateIndex') {
      this.setState({ selectedTagIndex: index });
    } else {
      this.setState({
        isEditing: !this.state.isEditing,
        selectedTagIndex: index,
      });
    }
  };

  // getHighlightText = (paraData) => {
  //   let fileComments = this.props.fileComments;
  //   let arr = [];
  //   fileComments.forEach((comment) => {
  //     if (comment.paraSeqId === paraData.paraSeqId) {
  //       arr.push(comment.selectedText);
  //     }
  //   });
  //   return arr;
  // };

  findChunksAtBeginningOfWords = ({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords,
    textToHighlight,
  }) => {
    const chunks = [];
    const textLow = textToHighlight.toLowerCase();
    // Match at the beginning of each new word
    // New word start after whitespace or - (hyphen)
    const sep = /[-\s]+/;

    // Match at the beginning of each new word
    // New word start after whitespace or - (hyphen)
    const singleTextWords = textLow.split(sep);

    // It could be possible that there are multiple spaces between words
    // Hence we store the index (position) of each single word with textToHighlight
    let fromIndex = 0;
    const singleTextWordsWithPos = singleTextWords.map((s) => {
      const indexInWord = textLow.indexOf(s, fromIndex);
      fromIndex = indexInWord;
      return {
        word: s,
        index: indexInWord,
      };
    });

    // Add chunks for every searchWord
    searchWords.forEach((sw) => {
      const swLow = sw.toLowerCase();
      // Do it for every single text word
      singleTextWordsWithPos.forEach((s) => {
        if (s.word.startsWith(swLow)) {
          const start = s.index;
          const end = s.index + swLow.length;
          chunks.push({
            start,
            end,
          });
        }
      });

      // The complete word including whitespace should also be handled, e.g.
      // searchWord='Angela Mer' should be highlighted in 'Angela Merkel'
      if (textLow.startsWith(swLow)) {
        const start = 0;
        const end = swLow.length;
        chunks.push({
          start,
          end,
        });
      }
    });

    return chunks;
  };

  getSubTagsSearchResult = (event) => {
    let value = event.target.value;
    let arr = [];
    let data = this.props.allMainSubTags;
    data.forEach((obj) => {
      obj.subTags.forEach((subTag) => {
        if (subTag.name.toLowerCase().startsWith(value.toLowerCase())) {
          arr.push({ subTag: subTag.name, mainTag: obj.mainTag });
        }
      });
    });
    arr = arr.slice(0, 4);
    this.setState({ subTagsSearch: value, searchedTags: arr });
  };

  handleEditTagClick = (
    selectedSubTag,
    orgMainTag,
    orgSubTag,
    paraSeqId,
    paraId,
    fileId
  ) => {
    this.setState({
      paraSeqId: paraSeqId,
      newSubTag: selectedSubTag,
    });
    this.props.handleAddFeedback(
      fileId,
      paraId,
      paraSeqId,
      orgSubTag,
      selectedSubTag.subTag,
      selectedSubTag.mainTag
    );
  };

  getNewMainTag = (data, mainTag, subTag) => {
    // console.log(data, mainTag, subTag);
    let newMainTag = '';
    if (data.feedback && data.feedback.length > 0) {
      data.feedback.forEach((feedback) => {
        if (subTag === feedback.orgTag) {
          newMainTag = feedback.mainTag;
        }
      });
    }
    // Object.keys(data.subTags).forEach((tagIndex) => {
    // });
    return newMainTag ? newMainTag : mainTag;
  };

  isCustomTag = (data, mainTag, subTag) => {
    let isCustom = false;
    if (data.feedback && data.feedback.length > 0) {
      data.feedback.forEach((feedback) => {
        if (subTag === feedback.orgTag) {
          isCustom = true;
        }
      });
    }
    return isCustom;
  };

  getConfidenceClass = (confidence) => {
    if (confidence === 0) {
      return styles.tag_subtag_popup_heading_low;
    } else if (confidence === 1) {
      return styles.tag_subtag_popup_heading_medium;
    } else if (confidence === 2 || confidence === 3) {
      return styles.tag_subtag_popup_heading_high;
    } else if (confidence === 4) {
      return styles.tag_subtag_popup_heading_custom;
    }
    // if (confidence === 0) {
    //   return styles.tag_subtag_popup_heading_low;
    // } else if (confidence === 1) {
    //   return styles.tag_subtag_popup_heading_img_medium;
    // } else if (confidence === 2) {
    //   return styles.tag_subtag_popup_heading_img_high;
    // } else {
    //   return styles.tag_subtag_popup_heading_img_custom;
    // }
  };

  getConfidenceText = (confidence) => {
    if (confidence === 0) {
      return 'LOW CONFIDENCE';
    } else if (confidence === 1) {
      return 'MEDIUM CONFIDENCE';
    } else if (confidence === 2 || confidence === 3) {
      return 'HIGH CONFIDENCE';
    } else if (confidence === 4) {
      return 'CUSTOM';
    }
  };

  getNewSubTag = (data, subTag) => {
    // console.log(subTag);
    let newSubTag = '';
    if (data.feedback && data.feedback.length > 0) {
      data.feedback.forEach((feedback) => {
        if (subTag === feedback.orgTag) {
          newSubTag = feedback.customTag;
        }
      });
    }
    // Object.keys(data.subTags).forEach((tagIndex) => {
    // });
    return newSubTag ? newSubTag : subTag;
  };

  render() {
    const {
      selectedParaIndex,
      isTagSelected,
      handleSelectedText,
      isCommenting,
      fileType,
      isSubmittedForReview,
      isSimilarParaModalOpen,
    } = this.props;
    const {
      isEdit,
      isEditing,
      subTagsSearch,
      searchedTags,
      selectedTagIndex,
      paraSeqId,
      newSubTag,
    } = this.state;

    return (
      <div className={styles.edit_tag_main_container}>
        {this.props.data &&
          this.props.data.map((data, i) => (
            <div
              className={`${
                selectedParaIndex.includes(i) && !isCommenting
                  ? styles.tag_main_text_para_green_border
                  : styles.tag_main_text_para_default
              }`}
            >
              {data.paraType === 'Table' ? (
                <div id={`analytics-scroll-to-${data.paraSeqId}`}>
                  <img
                    src={data.htmlText}
                    alt=""
                    className={styles.para_text_img}
                  />
                </div>
              ) : (
                <div
                  onClick={() => {
                    if (isTagSelected.length > 0) {
                      return;
                    }
                    return this.props.handleParaClick(i);
                  }}
                  className={`${styles.tag_main_text_para} ${
                    !selectedParaIndex.includes(i) &&
                    selectedParaIndex.length > 0 &&
                    !isCommenting
                      ? styles.tag_main_text_para_fade
                      : null
                  }`}
                  onMouseUp={() => handleSelectedText(data.paraSeqId)}
                >
                  {/* <Highlighter
                      searchWords={this.getHighlightText(data)}
                      // findChunks={this.findChunksAtBeginningOfWords}
                      textToHighlight={
                        data.htmlText ? data.htmlText : data.paraText
                      }
                      autoEscape={true}
                      // highlightClassName={`selected_${data.paraSeqId}_${}`}
                    /> */}
                  {fileType === 'Notice' && isSubmittedForReview ? null : (
                    <div
                      id={`analytics-scroll-to-${i}`}
                      dangerouslySetInnerHTML={{
                        __html: data.htmlText ? data.htmlText : data.paraText,
                      }}
                    ></div>
                  )}
                </div>
              )}

              {selectedParaIndex.includes(i) &&
                !isTagSelected &&
                !isCommenting && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      if (!isSimilarParaModalOpen) {
                        // return;
                        this.tagEditOpenHandler('close');
                        this.handleEditPen('close');
                        this.props.closeActionPopup();
                      }
                    }}
                  >
                    <div className={styles.tag_action_popup}>
                      {Object.keys(data.subTags).map((tag, i) => (
                        <div className={styles.tag_action_popup_tag}>
                          {i === 0 ? (
                            <p
                              className={this.getConfidenceClass(
                                this.isCustomTag(
                                  data,
                                  data.mainTags[tag],
                                  data.subTags[tag]
                                )
                                  ? 4
                                  : data.confidence
                              )}
                            >
                              <div
                              // className={styles.tag_subtag_popup_heading_img}
                              ></div>
                              {this.getConfidenceText(
                                this.isCustomTag(
                                  data,
                                  data.mainTags[tag],
                                  data.subTags[tag]
                                )
                                  ? 4
                                  : data.confidence
                              )}
                            </p>
                          ) : (
                            <p
                              className={
                                styles.tag_subtag_popup_heading_suggested
                              }
                            >
                              <div
                                className={
                                  styles.tag_subtag_popup_heading_img_high
                                }
                              ></div>
                              SUGGESTED
                            </p>
                          )}
                          <div className={styles.tag_bottom_container}>
                            <p className={styles.tag_action_popup_text}>
                              {/* {selectedTagIndex === i &&
                              paraSeqId === data.paraSeqId
                                ? newSubTag.mainTag
                                : data.mainTags[tag]} */}
                              {this.getNewMainTag(
                                data,
                                data.mainTags[tag],
                                data.subTags[tag]
                              )}
                            </p>
                          </div>
                          <div className={styles.subtag_pen_container}>
                            <span
                              className={styles.tag_bottom_maintag}
                              onClick={() => {
                                this.tagEditOpenHandler('updateIndex', i);
                                this.handleEditPen('open', data.subTags[tag]);
                              }}
                            >
                              {/* {selectedTagIndex === i &&
                              paraSeqId === data.paraSeqId
                                ? newSubTag.subTag
                                : data.subTags[tag]} */}
                              {this.getNewSubTag(data, data.subTags[tag])}
                            </span>
                            {isEdit === data.subTags[tag] &&
                            selectedTagIndex === i ? (
                              <img
                                src={greyPenIcon}
                                alt="pen"
                                className={styles.grey_pen_icon_subtag}
                                onClick={() => this.tagEditOpenHandler('', i)}
                              />
                            ) : null}
                          </div>

                          {isEditing ? (
                            <OutsideClickHandler
                            // onOutsideClick={() => this.props.closeActionPopup()}
                            >
                              <div className={styles.update_tag_container}>
                                <input
                                  placeholder="Keyword |"
                                  className={styles.update_tag_container_input}
                                  onChange={this.getSubTagsSearchResult}
                                  value={subTagsSearch}
                                />
                                <div
                                  className={
                                    styles.update_tag_keyword_container
                                  }
                                >
                                  {searchedTags.map((subTag) => (
                                    <p
                                      className={
                                        styles.update_sub_tags_actual_name
                                      }
                                      onClick={() => {
                                        this.handleEditTagClick(
                                          subTag,
                                          data.mainTags[selectedTagIndex],
                                          data.subTags[selectedTagIndex],
                                          data.paraSeqId,
                                          data.paraId,
                                          data.fileId
                                        );
                                        this.tagEditOpenHandler('close');
                                        this.handleEditPen('close');
                                        this.props.closeActionPopup();
                                      }}
                                    >
                                      {subTag.subTag}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </OutsideClickHandler>
                          ) : null}
                        </div>
                      ))}
                      <div
                        className={styles.see_similar_paras_btn}
                        onClick={() =>
                          this.props.handleSimilarParaModal(
                            data.paraSeqId,
                            data.htmlText ? data.htmlText : data.paraText,
                            data.subTags
                          )
                        }
                      >
                        SEE SIMILAR PARAS
                      </div>
                    </div>
                  </OutsideClickHandler>
                )}
            </div>
          ))}
      </div>
    );
  }
}

export default EditTags;
