import React from 'react'
import styles from './AllFolderList.module.css'
import arrow_up from '../../../assets/arrow_up.svg'
import grey_document from '../../../assets/grey_document.svg'
import { handleLongFileName } from '../../../shared/helper'
import { formatDate } from '../../../shared/formatDate'
import { withRouter } from 'react-router-dom'

class AllFolderList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredFoldersData: this.props.foldersData,
      isAscending: 1,
      sortedData: this.props.foldersData, //sortedData is for maintaining order when sort and filter applied simultaneously
    }
  }

  componentDidMount() {
    this.setState({ filteredFoldersData: this.props.foldersData })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchItems !== this.props.searchItems) {
      this.getFilteredList()
    }
  }

  sortFolders = () => {
    const filteredFoldersData = this.state.filteredFoldersData
    filteredFoldersData.sort((a, b) =>
      a['fileName'].replace(/%20/g, '').toLowerCase() >
      b['fileName'].replace(/%20/g, '').toLowerCase()
        ? this.state.isAscending
        : this.state.isAscending * -1
    )
    this.setState({
      filteredFoldersData,
      isAscending: this.state.isAscending * -1,
      sortedData: filteredFoldersData,
    })
  }

  getFilteredList = () => {
    let subStr = this.props.searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    let filteredRecentDocuments = this.state.sortedData.filter(tag => {
      return (
        tag.fileName
          .replace(/%20/g, '')
          .toLowerCase()
          .search(subStr.toLowerCase()) !== -1
      )
    })
    this.setState({ filteredFoldersData: filteredRecentDocuments })
  }
  handleDocumentClick = data => {
    if (!data.isClustered) {
      this.props.history.push({
        pathname: '/create-document',
        search: '?source=document-list',
        state: {
          fileId: data.fileId,
        },
      })
    } else {
      this.props.history.push({
        pathname: '/document-analytics',
        search: '?source=data-catalog',
        state: { fileId: data.fileId, filePath: data.filePath },
      })
    }
  }
  render() {
    const { isAscending, filteredFoldersData } = this.state
    const { parent } = this.props

    return (
      <React.Fragment>
        <div>
          <table className={styles.data_catalog_all_folders_table}>
            <thead>
              <tr className={styles.all_folders_row_label}>
                <th>
                  <span>Name</span>
                  <img
                    className={`${styles.arrow_up} ${
                      isAscending == 1 ? '' : styles.arrow_down
                    }`}
                    src={arrow_up}
                    alt="arrow_up"
                    onClick={() => this.sortFolders()}
                  ></img>
                </th>
                {/* <th>
                  {' '}
                  <span>Confidence</span>
                </th> */}
                {parent === 'DocumentList' ? null : (
                  <th>
                    <span>File Type</span>
                  </th>
                )}
                <th>
                  <span>Modified On</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredFoldersData?.map((data, index) => {
                let docName = data.fileName.replace(/%20/g, '')
                let timeAndDate = formatDate(data.modified)
                let fileType
                if (parent !== 'DocumentList') {
                  fileType = data.fileType
                }

                let confidence = 98

                return (
                  <tr
                    className={styles.folder_details}
                    key={index}
                    onClick={() => this.handleDocumentClick(data)}
                  >
                    <td>
                      <span
                        className={`${styles.folder_name} ${styles.tooltip}`}
                      >
                        <img
                          className={styles.grey_document}
                          src={grey_document}
                          alt="grey_document"
                        ></img>
                        {handleLongFileName(docName, 50)}
                        {handleLongFileName(docName, 50) !== docName && (
                          <span className={styles.tooltiptext}>{docName}</span>
                        )}
                      </span>
                    </td>
                    {parent === 'DocumentList' ? null : (
                      <td>
                        <span className={styles.size}>{fileType} </span>
                      </td>
                    )}
                    <td>
                      <span className={styles.modified}>
                        {timeAndDate.date}
                      </span>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(AllFolderList)
