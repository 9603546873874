import * as types from '../types';

const initialState = {
  isLoading: false,
  dataSourceInfo: null,
  error: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // Update Profile Actions
    case types.FETCH_ALL_SOURCE_INFO_REQUEST:
      return {
        ...state,
        dataSourceInfo: null,
        isLoading: true,
      };
    case types.FETCH_ALL_SOURCE_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dataSourceInfo: action.payload.result.data,
      };
    }
    case types.FETCH_ALL_SOURCE_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    default: {
      return state;
    }
  }
};
