import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './AccountSettings.module.css'
import commonStyles from '../../shared/Common.module.css'
import '../../css/react-setup.css'
import EditProfile from '../../components/account-settings/edit-profile/EditProfile'
import ChangePassword from '../../components/account-settings/change-password/ChangePassword'
import { Tab, Tabs } from 'react-bootstrap'
import * as accountSettings from '../../store/actions/accountSettingsActions'
class AccountSettings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
    }
  }

  componentDidUpdate() {
    if (this.props.error) {
      this.props.triggerErrorToast(
        this.props.error,
        this.props.clearaccountSettingsResponseMessages
      )
    }
    if (this.props.successMessage) {
      this.props.triggerSuccessToast(
        this.props.successMessage,
        this.props.clearaccountSettingsResponseMessages
      )
    }
  }
  handleUserInput = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }
  handleSignOut = () => {
    this.props.history.push('/')
  }
  handleArrowClick() {
    this.props.history.push('/dashboard')
  }
  render() {
    return (
      <React.Fragment>
        <div className={styles.header_container}>
          <div
            onClick={() => {
              this.handleArrowClick()
            }}
            className={styles.strip_logo}
          >
            P_AI
          </div>
          <div className={styles.heading_action_container}>
            <button
              type="button"
              className={`btn btn-primary-outline ${commonStyles.home_top_action_btn} ${commonStyles.btn_ripple}`}
              onClick={this.handleSignOut}
            >
              SIGN OUT
            </button>
          </div>
        </div>
        <div className={styles.ac_wrapper}>
          <div className={commonStyles.commom_title}>
            <i
              onClick={() => {
                this.handleArrowClick()
              }}
              className="fas fa-arrow-left"
            ></i>
            Account Settings
          </div>
          <div className={`row ${styles.ac_card} ${commonStyles.no_gutter}`}>
            <div className="col-md-12" align="center">
              <div className={`card ${styles.ac_card}`}>
                <div className={`card-body ${styles.ac_card_body}`}>
                  <Tabs id="account-settings-tab">
                    <Tab eventKey="editProfile" title="Edit Profile">
                      <EditProfile
                        onUpdateProfile={this.props.updateProfileRequest}
                      ></EditProfile>
                    </Tab>
                    <Tab eventKey="changePassword" title="Change Password">
                      <ChangePassword
                        onchangePassword={this.props.changePasswordRequest}
                      ></ChangePassword>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    successMessage: state.accountsSettings.successMessage,
    error: state.accountsSettings.error,
  }
}
const mapDispatchToProps = {
  updateProfileRequest: accountSettings.updateProfileRequest,
  changePasswordRequest: accountSettings.changePasswordRequest,
  clearaccountSettingsResponseMessages:
    accountSettings.clearaccountSettingsResponseMessages,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings)
