import React, { Component } from "react";
import styles from "./DeleteMember.module.css";
import commonStyles from "../../shared/Common.module.css";
import CommonModal from "../../shared/common-modal/CommonModal";

class DeleteTicket extends Component {
  handleCloseBtn = () => {
    this.props.onCloseTicket();
  };

  render() {
    return (
      <div>
        <CommonModal
          modalClass={"team_common_modal"}
          closeHandler={this.props.closeHandler}
          // header={"Close Ticket"}

          modalClass={'delete_ticket_modal'}
          backdropClassName={'new_project_modal_blue_bg'}
          header={
              <div className={styles.header}>
              <div>Close Ticket</div>
              </div>
            }
        >
          <p>Are you sure you want to close this ticket?</p>
          <div className={styles.buttons_wrapper}>
            <button
              type="button"
              className={styles.create_project}
                style={{color:'#11256d', backgroundColor: '#ffffff'}}
              // className={`btn btn-primary-outline ${commonStyles.common_light_btn} ${commonStyles.btn_ripple} ${styles.btn_gap}`}
              onClick={this.props.closeHandler}
            >
              CANCEL
            </button>
            <button
              type="button"
              className={styles.create_project}
              // className={`btn btn-primary-outline ${commonStyles.common_submit_btn} ${commonStyles.btn_ripple}`}
              onClick={this.handleCloseBtn}
            >
              CLOSE
            </button>
          </div>
        </CommonModal>
      </div>
    );
  }
}

export default DeleteTicket;
