import React, { Component } from 'react'
import { FiSearch } from 'react-icons/fi'
import { IconContext } from 'react-icons'

import styles from './SearchBar.module.css'

export default class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchItems: this.props.searchKeyword ? this.props.searchKeyword : '',
    }
  }
  handleUserInput = event => {
    var searchItems = event.target.value
    this.setState({ searchItems })
    if (this.props.handleSearchChange)
      this.props.handleSearchChange(event.target.value)
  }
  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.props.handleSearchChange(this.state.searchItems)
    }
  }

  render() {
    const {
      iconColor,
      iconSize = '18px',
      fontSize,
      fontColor = '#000000',
      placeholder,
      leftMargin,
    } = this.props
    const { searchItems } = this.state

    return (
      <div
        className={styles.root}
        style={{
          marginLeft: leftMargin,
        }}
      >
        <input
          type="text"
          className={styles.search_box}
          style={{
            fontSize,
            color: `${fontColor}`,
          }}
          value={searchItems}
          placeholder={placeholder}
          onChange={event => this.handleUserInput(event)}
          onKeyPress={this.handleKeyPress}
        ></input>
        <div
          onClick={() => this.props.handleSearchChange(searchItems)}
          className={styles.search_icon}
        >
          <IconContext.Provider
            value={{ color: `${iconColor}`, size: `${iconSize}` }}
          >
            <div>
              <FiSearch />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    )
  }
}
