import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as allFoldersTree from '../actions/allFoldersTreeActions';

function* allFoldersTreeDataWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCatalogAllFoldersTree, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(allFoldersTree.catalogAllFoldersTreeDataSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(allFoldersTree.catalogAllFoldersTreeDataFailure(e));
    }
}


function* allFoldersTreeDataWatcher() {
    yield takeLatest(types.CATALOG_ALL_FOLDERS_TREE_DATA_REQUEST, allFoldersTreeDataWorker);
}






function* allFoldersContentDataWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCatalogAllFoldersContent, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(allFoldersTree.catalogAllFoldersContentSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(allFoldersTree.catalogAllFoldersContentFailure(e));
    }
}


function* allFoldersContentDataWatcher() {
    yield takeLatest(types.CATALOG_ALL_FOLDERS_CONTENT_REQUEST, allFoldersContentDataWorker);
}


export default function* catalogAllFoldersTreeSaga() {
    yield all([
        allFoldersTreeDataWatcher(),
        allFoldersContentDataWatcher()
    ]);
}