import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as types from '../types'
import _api from '../../services/api'
import URLS from '../../services/urls'
import * as catalogLandingPage from '../actions/catalogLandingPageActions'

function* catalogRecentFoldersWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestcatalogRecentFolders,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(catalogLandingPage.catalogRecentFoldersSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(catalogLandingPage.catalogRecentFoldersFailure(e))
  }
}

function* catalogRecentFoldersWatcher() {
  yield takeLatest(
    types.CATALOG_RECENT_FOLDERS_REQUEST,
    catalogRecentFoldersWorker
  )
}

function* catalogRecentDocumentsWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestCatalogRecentDocuments,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(catalogLandingPage.catalogRecentDocumentsSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(catalogLandingPage.catalogRecentDocumentsFailure(e))
  }
}

function* catalogRecentDocumentsWatcher() {
  yield takeLatest(
    types.CATALOG_RECENT_DOCUMENTS_REQUEST,
    catalogRecentDocumentsWorker
  )
}

function* catalogAllFoldersWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestCatalogAllFolders,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(catalogLandingPage.catalogAllFoldersSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(catalogLandingPage.catalogAllFoldersFailure(e))
  }
}

function* catalogAllFoldersWatcher() {
  yield takeLatest(types.CATALOG_ALL_FOLDERS_REQUEST, catalogAllFoldersWorker)
}

function* catalogAllSourceWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestCatalogAllSource,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(catalogLandingPage.catalogAllSourceSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(catalogLandingPage.catalogAllSourceFailure(e))
  }
}

function* catalogAllSourceWatcher() {
  yield takeLatest(types.CATALOG_ALL_SOURCE_REQUEST, catalogAllSourceWorker)
}

function* catalogAdvancedSearchWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.requestCatalogAdvancedSearch,
      params.payload,
      'POST'
    )
    if (data && data.status !== 200) {
      throw data
    }
    yield put(catalogLandingPage.catalogAdvancedSearchSuccess(data))
  } catch (e) {
    console.error('error  : ', e)
    yield put(catalogLandingPage.catalogAdvancedSearchFailure(e))
  }
}

function* catalogAdvancedSearchWatcher() {
  yield takeLatest(
    types.CATALOG_ADVANCED_SEARCH_REQUEST,
    catalogAdvancedSearchWorker
  )
}


function* catalogSearchTermWorker(params) {
    try {
        const data = yield call(_api, URLS.requestCatalogSearchTerm, params.payload, 'POST');
        if (data && data.status !== 200) {
            throw data;
        }
        yield put(catalogLandingPage.catalogSearchTermSuccess(data));
    } catch (e) {
        console.error('error  : ', e);
        yield put(catalogLandingPage.catalogSearchTermFailure(e));
    }
}

function* catalogSearchTermWatcher() {
    yield takeLatest(types.CATALOG_SEARCH_TERM_REQUEST, catalogSearchTermWorker);
}


export default function* catalogLandingPageSaga() {
    yield all([
        catalogRecentFoldersWatcher(),
        catalogRecentDocumentsWatcher(),
        catalogAllFoldersWatcher(),
        catalogAllSourceWatcher(),
        catalogSearchTermWatcher(),
        catalogAdvancedSearchWatcher(),
    ]);
}
