import React, { Component } from 'react'
import {
  IoIosArrowForward,
  IoMdArrowDropdown,
  IoMdArrowDropup,
  IoIosArrowDown,
} from 'react-icons/io'
import { IconContext } from 'react-icons'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsGantt from 'highcharts/modules/gantt'
import OutsideClickHandler from 'react-outside-click-handler'

import NavBar from './../workflow-management/NavBar/NavBar'
import Sidebar from './../workflow-management/Sidebar/Sidebar'
import RightBar from './../workflow-management/RightBar/RightBar'
import BluePlusSVG from './../../assets/blue_plus_white_background.svg'
import BluePlusSelectedSVG from './../../assets/blue_plus_selected.svg'
import ChatGreenSVG from './../../assets/chat_green.svg'
import ArrowRightSVG from './../../assets/arrow_right_black.svg'
import GreenDocument from '../../../src/assets/green_document.svg'
import ArrowUp from '../../../src/assets/arrow-up-grey.svg'
import commonStyles from '../../shared/Common.module.css'
import styles from './AllProjects.module.css'
import Dropdown from './../../components/data-catalog/dropdown/Dropdown'
import SearchBar from '../../components/data-catalog/search-bar/SearchBar'
import RecentDocument from './../../components/all-projects/recent-documents/RecentDocuments'
import AllDocumentsTableView from './../../components/all-projects/all-documents-table-view/AllDocumentsTableView'
import {updateProjectId} from "./../../store/actions/homepageActions"
import * as workflowManagementAllProjects from '../../store/actions/workflowManagementAllProjectsAction'
import { connect } from 'react-redux'
import { formatDate } from '../../shared/formatDate'
import { Chat } from './Chat/Chat'
import AddMember from '../../components/data-catalog/modals/add-member/AddMember'
import { Steps } from 'antd'
import * as d3 from 'd3'

const { Step } = Steps
highchartsGantt(Highcharts)
const TeamListArray = [
  { img: 'https://www.w3schools.com/howto/img_avatar.png', id: 1 },
  { img: 'https://www.w3schools.com/howto/img_avatar.png', id: 2 },
  { img: 'https://www.w3schools.com/howto/img_avatar.png', id: 3 },
  { img: 'https://www.w3schools.com/howto/img_avatar.png', id: 4 },
  { img: 'https://www.w3schools.com/howto/img_avatar.png', id: 5 },
  { img: 'https://www.w3schools.com/howto/img_avatar.png', id: 6 },
]
class AllProjects extends Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      showEditMemberPanel: false,
      showProjectDetailsPanel: false,
      showPlanningAvatars: true,
      showResearchAvatars: false,
      showImplementationAvatars: false,
      showDesignAvatars: false,
      toggledTask: 'planning',
      graphData: [],
      workflowManagementProjectData: [],
      serachTerms: '',
      showAddTeamMemberPopup: false,
      selectedMember: '',
      projectId: this.props.history.location?.state?.id,
    }
  }

  toggleEditMemberPanel = id => {
    this.setState({
      showEditMemberPanel: true,
      selectedMember: id,
    })
  }

  toggleProjectDetailsPanel = () => {
    const { showProjectDetailsPanel } = this.state
    this.setState({
      showProjectDetailsPanel: !showProjectDetailsPanel,
    })
  }

  handleBackNavigation = () => {
    this.props.history.goBack()
  }
  togglePlanningAvatars = () => {
    const { showPlanningAvatars } = this.state
    this.setState({
      showPlanningAvatars: !showPlanningAvatars,
    })
  }
  toggleResearchAvatars = () => {
    const { showResearchAvatars } = this.state
    this.setState({
      showResearchAvatars: !showResearchAvatars,
    })
  }
  toggleDesignAvatars = () => {
    const { showDesignAvatars } = this.state
    this.setState({
      showDesignAvatars: !showDesignAvatars,
    })
  }
  toggleTasks = task => {
    this.setState({
      toggledTask: task,
    })
  }

  componentDidMount() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    const { projectId } = this.state
    const paramsObj = {
      projectId,
    }
    this.props.workflowManagementProjectDetails(paramsObj)
    this.props.updateProjectId(projectId)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isWorkflowManagementProjectDataLoading &&
      !this.props.isWorkflowManagementProjectDataLoading &&
      this.props.ProjectDetailsData
    ) {
      let formattedGraphData = []
      let tasks = this.props.ProjectDetailsData?.tasks
      tasks &&
        tasks.map(task => {
          let startDate = new Date(task.startDate)
          let endDate = new Date(task.endDate)
          let today = new Date()
          let totalTime = endDate - startDate
          let progress = today - startDate
          let percent = (((progress / totalTime) * 100) / 100).toPrecision(2)
          formattedGraphData.push({
            // name: '',
            start: startDate.getTime(),
            end: endDate.getTime(),
            color: '#EAEAEA',
            completed: {
              amount: percent >= 1 ? 1 : parseFloat(percent),
              fill: '#1BA94C',
            },
          })
        })
      this.setState({
        workflowManagementProjectData: this.props.ProjectDetailsData,
        graphData: formattedGraphData,
      })
    }
    if (
      !prevState.showProjectDetailsPanel &&
      this.state.showProjectDetailsPanel
    ) {
      this.generateChart(this.props.ProjectDetailsData)
    }

    if (
      prevProps.isWorkflowManagementAddMemberDataLoading &&
      !this.props.isWorkflowManagementAddMemberDataLoading &&
      this.props.workflowManagementAddMemberData
    ) {
      const { projectId } = this.state
      const paramsObj = {
        projectId,
      }

      this.props.workflowManagementProjectDetails(paramsObj)
    }
    if (
      prevProps.isWorkflowManagementRemoveMemberDataLoading &&
      !this.props.isWorkflowManagementRemoveMemberDataLoading &&
      this.props.workflowManagementRemoveMemberData
    ) {
      const { projectId } = this.state
      const paramsObj = {
        projectId,
      }

      this.props.workflowManagementProjectDetails(paramsObj)
    }
  }
  handleSearchChange = searchItems => {
    this.setState({ searchTerms: searchItems })
  }

  addTeamMember = () => {
    const showAddTeamMemberPopup = this.state.showAddTeamMemberPopup
    this.setState({ showAddTeamMemberPopup: !showAddTeamMemberPopup })
  }
  hideAddTeamMemberPopup = () => {
    this.setState({ showAddTeamMemberPopup: false })
  }
  hideEditMemberPanel = () => {
    const { showEditMemberPanel } = this.state
    if (showEditMemberPanel) {
      this.setState({
        showEditMemberPanel: false,
        selectedMember: '',
      })
    }
  }

  countSteps = () => {
    let project = this.props.ProjectDetailsData
    let count = 0
    let tasks = project?.tasks
    tasks &&
      tasks.forEach(task => {
        // console.log(task);
        if (new Date(task.endDate) < new Date()) {
          count += 1
        }
      })
    return count
  }

  generateChart = detail => {
    if (this.state.showProjectDetailsPanel) {
      let startDate = new Date(detail.startDate)
      let endDate = new Date(detail.endDate)
      let today = new Date()
      let totalTime = endDate - startDate
      let progress = today - startDate
      // let percent = ((progress / totalTime) * 100).toPrecision(2);
      let totalHours = totalTime / 3600000
      let spentHours = progress / 3600000
      // console.log(percent);
      var data = [
        { key: 'total', value: totalHours },
        { key: 'spent', value: spentHours },
      ]

      var width = 450
      var height = 450
      var margin = 0

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      var radius = Math.min(width, height) / 2 - margin

      // append the svg object to the div called 'my_dataviz'
      var svg = d3
        .select(this.ref.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', 'translate(' + width / 2.4 + ',' + height / 2 + ')')

      let categories = []
      data.forEach(obj => {
        categories.push(obj.key)
      })

      // set the color scale
      var color = d3.scaleOrdinal().domain(categories).range(d3.schemeGreens[3])

      // Compute the position of each group on the pie:
      var pie = d3
        .pie()
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d.value
        })
      var data_ready = pie(data)

      // The arc generator
      var arc = d3
        .arc()
        .innerRadius(radius * 0.5) // This is the size of the donut hole
        .outerRadius(radius * 0.6)

      // Another arc that won't be drawn. Just for labels positioning
      d3.arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9)

      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      let arcPart = svg
        .selectAll('allSlices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', function (d) {
          return color(d.data.key)
        })
        .attr('stroke', 'white')
        .style('stroke-width', '2px')
        .style('opacity', 0.7)
        .style('cursor', 'pointer')

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '-1.3em')
        .text('Total')
        .style('font-size', '12px')
        .style('color', '#C4C4C4')

      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dy', '1em')
        .text(
          `${
            data[0].value % 1 === 0 ? data[0].value : data[0].value.toFixed(2)
          } Hrs.`
        )
        .style('font-size', '24px')
        .style('color', '#C4C4C4')

      arcPart
        .on('mouseover', (d, data) => {
          svg.selectAll('text').remove()
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1.3em')
            .text(`${data.data.key[0].toUpperCase()}${data.data.key.slice(1)}`)
            .style('font-size', '12px')
            .style('color', '#C4C4C4')

          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1em')
            .text(
              `${
                data.data.value % 1 === 0
                  ? data.data.value
                  : data.data.value.toFixed(2)
              } Hrs.`
            )
            .style('font-size', '24px')
            .style('color', '#C4C4C4')
        })
        .on('mouseout', d => {
          svg.selectAll('text').remove()
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1.3em')
            .text('Total')
            .style('font-size', '12px')
            .style('color', '#C4C4C4')
          svg
            .append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1em')
            .text(
              `${
                data[0].value % 1 === 0
                  ? data[0].value
                  : data[0].value.toFixed(2)
              } Hrs.`
            )
            .style('font-size', '24px')
            .style('color', '#C4C4C4')
        })
    }
  }

  getTaskName = taskName => {
    let project = this.props.ProjectDetailsData
    let name = ''
    project &&
      project.tasks.forEach(task => {
        if (task.name === taskName) {
          name = task.modifiedName
        }
      })
    return name
  }

  getTaskState = taskName => {
    let project = this.props.ProjectDetailsData
    let status = ''
    project &&
      project.tasks.forEach(task => {
        if (task.name === taskName) {
          if (new Date(task.endDate) <= new Date()) {
            status = 'COMPLETED'
          } else {
            status = 'IN PROGRESS'
          }
        }
      })
    return status
  }
  handleRemoveMember = memberId => {
    const params = {
      projectId: this.state.projectId,
      memberId,
    }
    this.props.requestRemoveMember(params)
    this.hideAddTeamMemberPopup()
  }

  render() {
    const {
      showEditMemberPanel,
      showProjectDetailsPanel,
      showPlanningAvatars,
      showResearchAvatars,
      graphData,
      showDesignAvatars,
      showImplementationAvatars,
      showAddTeamMemberPopup,
      selectedMember,
      toggledTask,
      projectId,
    } = this.state
    // const { ProjectDetailsData } = this.props;
    return (
      <>
        <NavBar />
        <Sidebar />
        <RightBar />
        <div
          className={`${commonStyles.common_workflow_management_wrapper} ${styles.root}`}
        >
          <div className={styles.relative}>
            <div
              className={styles.back_icon}
              onClick={this.handleBackNavigation}
            >
              <img src={ArrowUp} alt="Arrow Up" />
            </div>
          </div>

          <section className={styles.upper_part}>
            <div className={styles.header} onClick={this.handleBackNavigation}>
              <div>All Projects/</div>
              {this.props.ProjectDetailsData?.name && (
                <div className={styles.heading}>
                  {this.props.ProjectDetailsData?.name}
                </div>
              )}
            </div>

            <div className={styles.details_container}>
              <div className={styles.project_details_container}>
                <div className={styles.client_name_container}>
                  <div>CLIENT NAME</div>
                  <div className={styles.client_name}>
                    {this.props.ProjectDetailsData?.clientName}
                  </div>
                </div>
                <div className={styles.start_date_name_container}>
                  <div>START DATE</div>
                  <div className={styles.date}>
                    {formatDate(this.props.ProjectDetailsData?.startDate).date}
                  </div>
                </div>
                <div className={styles.end_date_name_container}>
                  <div>END DATE</div>
                  <div className={styles.date}>
                    {formatDate(this.props.ProjectDetailsData?.endDate).date}
                  </div>
                </div>
                <div className={styles.project_details_btn_container}>
                  <div
                    className={styles.project_details_btn}
                    onClick={this.toggleProjectDetailsPanel}
                  >
                    <div>PROJECT DETAILS</div>
                    <span>
                      <IconContext.Provider
                        value={{ color: '#7a7a7a', size: '18px' }}
                      >
                        {showProjectDetailsPanel ? (
                          <IoIosArrowDown />
                        ) : (
                          <IoIosArrowForward />
                        )}
                      </IconContext.Provider>
                    </span>
                  </div>
                </div>
              </div>

              {showProjectDetailsPanel && (
                <div className={styles.project_details_panel}>
                  <div className={styles.btn_container}>
                    <span className={styles.btn}>END PROJECT</span>
                    <span className={styles.btn}>SUSPEND</span>
                    <span className={styles.btn}>DELETE</span>
                  </div>

                  <div className={styles.project_details}>
                    <div className={styles.part_1}>
                      <div className={styles.project_details_text_wrapper}>
                        <div className={styles.project_details_text_1}>
                          DESCRIPTION
                        </div>
                        <div className={styles.project_details_text_2}>
                          {this.props.ProjectDetailsData?.description}
                        </div>
                      </div>
                      <div className={styles.project_details_text_wrapper}>
                        <div className={styles.project_details_text_1}>
                          ACCESSIBILITY
                        </div>
                        <div className={styles.project_details_text_2}>
                          {this.props.ProjectDetailsData?.access}
                        </div>
                      </div>
                      <div className={styles.project_details_text_wrapper}>
                        <div className={styles.project_details_text_1}>
                          PROJECT TYPE
                        </div>
                        <div className={styles.project_details_text_2}>
                          {this.props.ProjectDetailsData?.type}
                        </div>
                      </div>
                    </div>

                    <div className={styles.part_2}>
                      <div className={styles.project_details_text_wrapper}>
                        <div className={styles.project_details_text_1}>
                          PROJECT LEAD
                        </div>
                        <div className={styles.project_details_text_2}>
                          {this.props.ProjectDetailsData?.lead.name}
                        </div>
                      </div>
                      <div className={styles.project_details_text_wrapper}>
                        <div className={styles.project_details_text_1}>
                          TEAM
                        </div>
                        {this.props.ProjectDetailsData?.team?.map(team => {
                          return (
                            <div className={styles.project_details_text_2}>
                              {team.name}
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <div className={styles.part_3}>
                      <div className={styles.project_details_text_wrapper}>
                        <div className={styles.project_details_text_1}>
                          BILL TYPE
                        </div>
                        <div className={styles.project_details_text_2}>
                          {this.props.ProjectDetailsData?.billingDetails.type}
                        </div>
                      </div>
                      <div className={styles.project_details_text_wrapper}>
                        {/* <div className={styles.project_details_text_1}>
                          LAST BILLING
                        </div>
                        <div className={styles.project_details_text_2}>
                          24 NOV 2020
                        </div> */}
                      </div>
                      <div className={styles.generate_bill_btn}>
                        GENERATE SUMMARY
                      </div>
                    </div>

                    <div className={styles.part_4}>
                      <div ref={this.ref} />
                      {/* {this.generateChart()} */}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <section className={styles.teams_section}>
              <div
                className={`${styles.progress_container} project_detail_progress_steps`}
              >
                <span>PROGRESS</span>
                {this.props.ProjectDetailsData ? (
                  <span className={styles.progress_step}>
                    <Steps progressDot current={this.countSteps()} size="small">
                      <Step title="" description="" />
                      <Step title="" description="" />
                      <Step title="" description="" />
                      <Step title="" description="" />
                      <Step title="" description="" />
                      <Step title="" description="" />
                    </Steps>
                  </span>
                ) : null}
              </div>
              <div className={styles.team_wrapper}>
                <span className={styles.team_container}>
                  <span>TEAM</span>
                  <span className={styles.team_members}>
                    {this.props.ProjectDetailsData?.team &&
                      this.props.ProjectDetailsData?.team.map(data => {
                        // console.log("ddd", data)
                        return (
                          <span
                            className={styles.team_member_img_container}
                            onClick={() => {
                              this.toggleEditMemberPanel(data.id)
                            }}
                          >
                             {/* <span className={styles.tooltiptext}>{data.name}</span> */}
                            <img
                          
                              style={
                                selectedMember === data.id
                                  ? { border: '2px solid #11256D' }
                                  : {}
                              }
                              src="https://www.w3schools.com/howto/img_avatar.png"
                              alt="progress"
                            />

                            {showEditMemberPanel && selectedMember === data.id && (
                              <OutsideClickHandler
                                onOutsideClick={this.hideEditMemberPanel}
                              >
                                <span className={styles.edit_team_member}>
                                  <label className={styles.switch}>
                                    <input type="checkbox" />
                                    <span
                                      className={`${styles.slider} ${styles.round}`}
                                    ></span>
                                  </label>
                                  <span>Editor</span>
                                  <span
                                    className={styles.remove_btn}
                                    onClick={() =>
                                      this.handleRemoveMember(data.id)
                                    }
                                  >
                                    REMOVE
                                  </span>
                                </span>
                              </OutsideClickHandler>
                            )}
                  
                          </span>
                        )
                      })}
                   
                    <div className={styles.add_team_member_section}>
                      <span>
                        <img
                          onClick={this.addTeamMember}
                          src={
                            showAddTeamMemberPopup
                              ? BluePlusSelectedSVG
                              : BluePlusSVG
                          }
                          alt="BluePlusSVG"
                        />
                      </span>
                      {showAddTeamMemberPopup && (
                        <OutsideClickHandler
                          onOutsideClick={this.hideAddTeamMemberPopup}
                        >
                          <div className={styles.add_team_member_popup_section}>
                            <AddMember
                              projectId={projectId}
                              closeAddMemberPanel={this.hideAddTeamMemberPopup}
                            />
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>

                  </span>
                  <span className={styles.arrow_right_icon}>
                    <img src={ArrowRightSVG} alt="ArrowRightSVG" />
                  </span>
                </span>

                <span className={styles.team_heading}>
                  <span>TEAM CHAT</span>
                  <img src={ChatGreenSVG} alt="Green Chat Icon" />
                </span>
              </div>
              <div className={styles.gantt_chart_section}>
                <div className={styles.left}>
                  <div className={styles.task_container}>
                    <span>TASK</span>
                    {/* <Dropdown
                      backgroundColor={'#fff'}
                      value0={'2020'}
                      fontSize={'9px'}
                      width={'70px'}
                      height={'25px'}
                      borderRadius={'4px'}
                      padding={'6px 9px'}
                      iconSize={'24px'}
                    /> */}
                    {/* <div className={styles.related_documents_header}>
                      <select
                        className={styles.related_dropdown}
                        // value={selectedValues}
                        // onChange={this.handleSelectChange}
                      >
                        <option value="related_document">2020</option>
                        <option value="related_paragraph">2019</option>
                      </select>
                    </div> */}
                  </div>
                  <div className={styles.planning_container}>
                    <div className={styles.planning_text_container}>
                      <span className={styles.text_1}>
                        <div>Planning</div>
                        <div className={styles.text_2}>
                          {this.getTaskState('planning')}
                        </div>
                      </span>
                      <span
                        className={styles.up_arrow_icon}
                        onClick={() => this.toggleTasks('planning')}
                      >
                        <IconContext.Provider
                          value={{
                            size: '24px',
                            color: '#7a7a7a',
                          }}
                        >
                          {toggledTask === 'planning' ? (
                            <IoMdArrowDropup />
                          ) : (
                            <IoMdArrowDropdown />
                          )}
                        </IconContext.Provider>
                      </span>
                    </div>

                    {toggledTask === 'planning' && (
                      <div className={styles.avatars}>
                        {this.getTaskName('planning')}
                      </div>
                    )}
                  </div>
                  <div className={styles.planning_container}>
                    <div className={styles.planning_text_container}>
                      <span className={styles.text_1}>
                        <div>Research</div>
                        <div className={styles.text_2}>
                          {this.getTaskState('research')}
                        </div>
                      </span>
                      <span
                        className={styles.up_arrow_icon}
                        onClick={() => this.toggleTasks('research')}
                      >
                        <IconContext.Provider
                          value={{
                            size: '24px',
                            color: '#7a7a7a',
                          }}
                        >
                          {toggledTask === 'research' ? (
                            <IoMdArrowDropup />
                          ) : (
                            <IoMdArrowDropdown />
                          )}
                        </IconContext.Provider>
                      </span>
                    </div>

                    {toggledTask === 'research' && (
                      <div className={styles.avatars}>
                        {this.getTaskName('research')}
                      </div>
                    )}
                  </div>

                  <div className={styles.planning_container}>
                    <div className={styles.planning_text_container}>
                      <span className={styles.text_1}>
                        <div>Discussions</div>
                        <div className={styles.text_2}>
                          {this.getTaskState('discussions')}
                        </div>
                      </span>
                      <span
                        className={styles.up_arrow_icon}
                        onClick={() => this.toggleTasks('discussion')}
                      >
                        <IconContext.Provider
                          value={{
                            size: '24px',
                            color: '#7a7a7a',
                          }}
                        >
                          {toggledTask === 'discussion' ? (
                            <IoMdArrowDropup />
                          ) : (
                            <IoMdArrowDropdown />
                          )}
                        </IconContext.Provider>
                      </span>
                    </div>

                    {toggledTask === 'discussion' && (
                      <div className={styles.avatars}>
                        {this.getTaskName('discussions')}
                      </div>
                    )}
                  </div>

                  <div className={styles.planning_container}>
                    <div className={styles.planning_text_container}>
                      <span className={styles.text_1}>
                        <div>Drafting</div>
                        <div className={styles.text_2}>
                          {this.getTaskState('drafting')}
                        </div>
                      </span>
                      <span
                        className={styles.up_arrow_icon}
                        onClick={() => this.toggleTasks('drafting')}
                      >
                        <IconContext.Provider
                          value={{
                            size: '24px',
                            color: '#7a7a7a',
                          }}
                        >
                          {toggledTask === 'drafting' ? (
                            <IoMdArrowDropup />
                          ) : (
                            <IoMdArrowDropdown />
                          )}
                        </IconContext.Provider>
                      </span>
                    </div>

                    {toggledTask === 'drafting' && (
                      <div className={styles.avatars}>
                        {this.getTaskName('drafting')}
                      </div>
                    )}
                  </div>

                  <div className={styles.planning_container}>
                    <div className={styles.planning_text_container}>
                      <span className={styles.text_1}>
                        <div>Finalization</div>
                        <div className={styles.text_2}>
                          {this.getTaskState('finalization')}
                        </div>
                      </span>
                      <span
                        className={styles.up_arrow_icon}
                        onClick={() => this.toggleTasks('finalization')}
                      >
                        <IconContext.Provider
                          value={{
                            size: '24px',
                            color: '#7a7a7a',
                          }}
                        >
                          {toggledTask === 'finalization' ? (
                            <IoMdArrowDropup />
                          ) : (
                            <IoMdArrowDropdown />
                          )}
                        </IconContext.Provider>
                      </span>
                    </div>

                    {toggledTask === 'finalization' && (
                      <div className={styles.avatars}>
                        {this.getTaskName('finalization')}
                      </div>
                    )}
                  </div>

                  {/* Implementation section end */}
                </div>
                <div className={styles.chart}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'ganttChart'}
                    options={{
                      chart: {
                        height: 408,
                        backgroundColor: '#fafafa',
                        fontFamily: 'Roboto',
                      },

                      title: {
                        text: '',
                      },
                      xAxis: [
                        {
                          // tickInterval: 1000 * 60 * 60 * 24 * 7,
                          // startOfMonth: 0,
                          gridLineWidth: 2,
                          // gridLineColor: '#EAEAEA',
                        },
                        {
                          min: new Date(
                            this.props.ProjectDetailsData?.startDate
                          ).getTime(),
                          max: new Date(
                            this.props.ProjectDetailsData?.endDate
                          ).getTime(),
                        },
                      ],
                      yAxis: [
                        {
                          // gridLineColor: '#EAEAEA',
                        },
                      ],

                      series: [
                        {
                          name: this.props.ProjectDetailsData?.name,
                          dataLabels: {
                            style: {
                              textOutline: 'none',
                              fontSize: '10px',
                              fontWeight: 'normal',
                            },
                            color: '#fff',
                            align: 'left',
                            x: 10,
                          },
                          data: graphData,
                        },
                      ],
                    }}
                  />
                </div>
                <div className={styles.chat}>
                  <div className={styles.team_chat_icons}>
                    <Chat
                      userId={this.props.homepage?.userDetails?.userId}
                      userName={this.props.homepage?.userDetails?.name}
                      projectDetails={this.props.ProjectDetailsData}
                    />
                  </div>
                </div>
              </div>
            </section>
          </section>

          <section className={styles.recent_document}>
            <div className={styles.recent_documents_header_container}>
              <div className={styles.recent_documents_title}>
                Main Documents
              </div>
              <div className={styles.icon_container}>
                <img
                  className={styles.recent_document_icon}
                  src={GreenDocument}
                  alt="document"
                />
              </div>

              <div className={styles.search_bar_container}>
                <div className={styles.search_bar_background}>
                  <div className={styles.search_bar_wrapper}>
                    <SearchBar
                      iconColor={'#C4C4C4'}
                      iconSize="21px"
                      fontSize="18px"
                      placeholder="Search Within Project"
                      handleSearchChange={searchItems => {
                        this.handleSearchChange(searchItems)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.props.ProjectDetailsData?.files &&
              this.props.ProjectDetailsData?.files?.length > 0 && (
                <RecentDocument
                  projectData={this.props.ProjectDetailsData?.files}
                  history={this.props.history}
                />
              )}
          </section>

          {this.props.ProjectDetailsData?.files && (
            <AllDocumentsTableView
              searchItems={this.state.searchTerms}
              files={this.props.ProjectDetailsData?.files}
            ></AllDocumentsTableView>
          )}

          {/* <section className={styles.projects_list}>
            <div className={styles.projects_list_header}>
              <span>
                <span>Name</span>
                <IconContext.Provider
                  value={{
                    size: 21,
                    color: '#c4c4c4',
                  }}
                >
                  <FiArrowUp />
                </IconContext.Provider>
              </span>

              <span>Status</span>

              <span>Modified On</span>
            </div>

            <div className={styles.project_list}>
              {this.props.ProjectDetailsData?.files?.map(({ name, createdAt }, index) => {
                const dateAndTime = formatDate(createdAt)
                return (
                  <div className={styles.list_container}>
                    <div className={styles.name_and_icon_container}>
                      <IconContext.Provider
                        value={{
                          size: 18,
                          color: '#F59F00',
                        }}
                      >
                        <FiFlag />
                      </IconContext.Provider>
                      <span className={styles.project_name}>{name}</span>
                    </div>

                    <div className={styles.status_container}>
                      <IconContext.Provider
                        value={{
                          size: 18,
                          color: '#00CC66',
                        }}
                      >
                        <BsCircleFill />
                      </IconContext.Provider>
                      <span className={styles.status}>98%</span>
                    </div>

                    <div className={styles.date_container}>{dateAndTime.date}</div>
                  </div>
                );
              })}
            </div>
          </section> */}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    homepage: state.homepage,
    ProjectDetailsData:
      state.workflowManagementAllProjects.workflowManagementProjectDetailsData,
    isWorkflowManagementProjectDataLoading:
      state.workflowManagementAllProjects
        .isWorkflowManagementProjectDetailsDataLoading,
    workflowManagementAddMemberData:
      state.workflowManagementAllProjects.workflowManagementAddMemberData,
    isWorkflowManagementAddMemberDataLoading:
      state.workflowManagementAllProjects
        .isWorkflowManagementAddMemberDataLoading,
    workflowManagementRemoveMemberData:
      state.workflowManagementAllProjects.workflowManagementRemoveMemberData,
    isWorkflowManagementRemoveMemberDataLoading:
      state.workflowManagementAllProjects
        .isWorkflowManagementRemoveMemberDataLoading,
  }
}

const mapDispatchToProps = {
  workflowManagementProjectDetails:
    workflowManagementAllProjects.workflowManagementProjectDetailsRequest,
  requestRemoveMember:
    workflowManagementAllProjects.workflowManagementRemoveMemberRequest,
  updateProjectId: updateProjectId

}

export default connect(mapStateToProps, mapDispatchToProps)(AllProjects)
