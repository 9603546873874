import React, { Component } from "react";
import styles from "./AdminView.module.css";
import NavigationBar from '../../shared/navigation-bar/NavigationBar'
import commonStyles from '../../shared/Common.module.css';
import admin from "../../json-data/admin.json";
import AdminViewOptions from '../../components/admin-view-options/AdminViewOptions'

class AdminView extends Component {

  render() {
    return (
      <React.Fragment>
        <NavigationBar></NavigationBar>
        <div className={commonStyles.commom_wrapper}>
            <div className={commonStyles.commom_title}>IT Admin View</div>
            {/* <div className={commonStyles.commom_subtitle}>lorem ipsum dolor sit amet, consectetur adipiscing elit</div> */}
            <div className={styles.admin_view_options}>
              {admin.data.map(data => {
                return (
                  <AdminViewOptions history={this.props.history} key={data.title} data={data}></AdminViewOptions>
                )
              })
              }
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdminView;