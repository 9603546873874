import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as WorkflowAllProjectActions from './../../../../store/actions/workflowManagementAllProjectsAction';

import CheckboxList from '../../checkbox-list/CheckboxList';
import FilterCheckboxList from '../../filter-checkbox-list/FilterCheckboxList';
import SearchBar from '../../search-bar/SearchBar';
import { members_list } from './../../../../json-data/searchResults.json';
import CommonStyles from './../../../../shared/Common.module.css';
import styles from './AddMember.module.css';

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredMembersList: this.props.workflowManagementDropdownData?.users,
      showSelected: false,
      allUsers: this.props.workflowManagementDropdownData?.users,
      projectId: this.props.projectId,
    };

    this.filterCheckboxListRef = React.createRef();
  }

  handleSearchChange = (searchItems) => {
    this.setState({ searchItems });
    this.getFilteredMembersList(searchItems);
  };
  getFilteredMembersList = (searchItems) => {
    let subStr = searchItems.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let filteredMembersList = this.props.workflowManagementDropdownData?.users.filter(
      (member) => {
        return member.value.toLowerCase().search(subStr.toLowerCase()) !== -1;
      }
    );
    this.setState({ filteredMembersList });
  };

  toggleShowSelected = (e) => {
    this.setState({
      showSelected: e.target.checked,
    });
  };

  addMember = () => {
    const { selectedUserDetails } = this.getFilterCheckboxListState();

    let newMembers = [];
    _.forOwn(selectedUserDetails, ({ key, value }, _) => {
      newMembers.push({
        id: key,
        name: value,
        permission: '',
      });
    });

    const params = {
      projectId: this.state.projectId,
      newMembers,
    };
    this.props.requestAddMember(params);
    this.props.closeAddMemberPanel();
  };

  getFilterCheckboxListState = () => {
    return this.filterCheckboxListRef.current.getState();
  };

  render() {
    const {
      searchItems,
      filteredMembersList,
      showSelected,
      projectId,
    } = this.state;

    return (
      <div className={`${CommonStyles.card_layout} ${styles.root}`}>
        <div className={styles.search_bar_container}>
          <div className={styles.search_bar_background}>
            <div className={styles.search_bar_wrapper}>
              <SearchBar
                iconColor={'#2c2c2c'}
                iconSize={'16px'}
                fontSize={'12px'}
                placeholder={'Search'}
                handleSearch={() => {}}
                handleSearchChange={(searchItems) => {
                  this.handleSearchChange(searchItems);
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.members_list}>
          <FilterCheckboxList
            data={filteredMembersList}
            width={24}
            height={24}
            showSelected={showSelected}
            ref={this.filterCheckboxListRef}
          />
        </div>

        <div className={styles.button_container}>
          <div className={styles.toggle_btn}>
            <div className={styles.switch_container}>
              <label class={styles.switch}>
                <input type="checkbox" onChange={this.toggleShowSelected} />
                <span class={`${styles.slider} ${styles.round}`}></span>
              </label>
            </div>
            <div className={styles.bottom_text}>View Only</div>
          </div>
          <div className={styles.add_btn} onClick={this.addMember}>
            ADD
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workflowManagementDropdownData:
      state.workFlowManagementLandingPage.workflowManagementDropdownData,
  };
};
const mapDispatchToProps = {
  requestAddMember:
    WorkflowAllProjectActions.workflowManagementAddMemberRequest,
  requestRemoveMember:
    WorkflowAllProjectActions.workflowManagementRemoveMemberRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);
