import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as types from '../types';
import _api from '../../services/api';
import URLS from '../../services/urls';
import * as fileClusterData from '../actions/fileClusterDataAction';

function* fileClusterDataWorker(params) {
  try {
    console.log("Inside fileClusterDataWorker params:",params);
    const data = yield call(
      _api,
      URLS.documentAnalyticsClusterData,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(fileClusterData.fileClusterDataSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(fileClusterData.fileClusterDataFailure(e));
  }
}

function* fileClusterDataWatcher() {
  yield takeLatest(types.FILE_CLUSTER_DATA_REQUEST, fileClusterDataWorker);
}

// get similar doc cluster data
function* similarDocFileClusterDataWorker(params) {
  try {
    const data = yield call(
      _api,
      URLS.documentAnalyticsClusterData,
      params.payload,
      'POST'
    );
    if (data && data.status !== 200) {
      throw data;
    }
    yield put(fileClusterData.similarDocClusterDataSuccess(data));
  } catch (e) {
    console.error('error  : ', e);
    yield put(fileClusterData.similarDocClusterDataFailure(e));
  }
}

function* similarDocFileClusterDataWatcher() {
  yield takeLatest(
    types.SIMILAR_DOC_CLUSTER_DATA_REQUEST,
    similarDocFileClusterDataWorker
  );
}

export default function* fileClusterDataSaga() {
  yield all([fileClusterDataWatcher(), similarDocFileClusterDataWatcher()]);
}
