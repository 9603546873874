import * as types from '../types'

export const fileAddRequest = payload => ({
  type: types.PRIVATE_FOLDER_ADD_FILE_REQUEST,
  payload,
})
export const fileAddSuccess = payload => ({
  type: types.PRIVATE_FOLDER_ADD_FILE_SUCCESS,
  payload,
})
export const fileAddFailure = payload => ({
  type: types.PRIVATE_FOLDER_ADD_FILE_FAILURE,
  payload,
})

export const folderAddRequest = payload => ({
  type: types.PRIVATE_FOLDER_ADD_FOLDER_REQUEST,
  payload,
})
export const folderAddSuccess = payload => ({
  type: types.PRIVATE_FOLDER_ADD_FOLDER_SUCCESS,
  payload,
})
export const folderAddFailure = payload => ({
  type: types.PRIVATE_FOLDER_ADD_FOLDER_FAILURE,
  payload,
})

export const foldersListRequest = payload => ({
  type: types.PRIVATE_FOLDER_FOLDER_LIST_REQUEST,
  payload,
})
export const foldersListSuccess = payload => ({
  type: types.PRIVATE_FOLDER_FOLDER_LIST_SUCCESS,
  payload,
})
export const foldersListFailure = payload => ({
  type: types.PRIVATE_FOLDER_FOLDER_LIST_FAILURE,
  payload,
})
