import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import {InfoBar} from './Infobar/InfoBar';
import {Input} from './Input/Input';
import {Messages} from './Messages/Messages';
import './Chat.css';
// const ENDPOINT = 'http://localhost:5000'
 const ENDPOINT = 'https://precilyteamchat.azurewebsites.net';
 
let socket = io(ENDPOINT, {transports: ['websocket'], upgrade: false});
export const Chat = (props) => {
    const [userId, setName] = useState('');
    const [userName, setUserName] = useState('');
    const [adminUser, setAdminUser] = useState()
    const [roomId, setRoom] = useState('');
    const [users, setUsers] = useState('');
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [usr, setUsr] = useState('')
    const [isPrivate, setPrivate] = useState(false)
   

    useEffect(() => {
        const userId = props?.userId
        setUserName(props?.userName)
        const projectDetails = props?.projectDetails
        const id = projectDetails ? projectDetails.id : null
        const projectId = id

        setRoom(id)
        setName(userId)
        socket.emit('join', { userId, roomId: projectId }, (error) => {
            if(error) {
                console.log(error)
            }
        });
        socket.on('online', (data) => {
            setAdminUser(data.userId)
        });
    }, [ENDPOINT, window.location.pathname]);
    useEffect(() => {
        socket.on('message', message => {
            setMessages(msgs => [ ...msgs, message ]);
        });
        socket.on("roomData", ({ users, messages }) => {
            console.log("users: ",users)
            setUsers(users);
            setMessages(messages)
        });
    }, []);
    useEffect(() => {
        return () => {
            socket.emit("leave", {roomId, userId})
        }
    }, [roomId])
    const sendMessage = (event, privateMsg) => {
        event.preventDefault();
        if(isPrivate) {
            return socket.emit('sendPrivateMsg', {usr,message, adminUser}, () => setMessage(''))
        }
        if(message) {
            let time = new Date()
            let data = {message, roomId, time, initiateBy: userId}
            socket.emit('sendMessage', data, () => setMessage(''));
        }
    }
    const handleSingleUser = (user) => {
        setPrivate(!isPrivate)
        setUsr(user)
    }
    return (
            <div className="outerContainer">
                <div className="container">
                    <InfoBar room={roomId} users={users} handleSingleUser={handleSingleUser} adminUser={adminUser}/>
                    <Messages messages={messages} name={userName} admin={adminUser} isPrivate={isPrivate}/>
                    <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
                </div>
         </div>
    );
}