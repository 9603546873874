import * as types from '../types';

const initialState = {
  isLoading: false,
  createdComment: null,
  error: null,
  commentsData: null,
  isCommentsLoading: null,
  deletedComment: null,
  isDeleteCommentLoading: false,
  isUpdateCommentLoading: false,
  updatedComment: null,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // create comment Actions
    case types.CREATE_FILE_COMMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        createdComment: null,
      };
    case types.CREATE_FILE_COMMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        createdComment: action.payload.result,
      };
    }
    case types.CREATE_FILE_COMMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };

    // get comments Actions
    case types.GET_FILE_COMMENT_REQUEST:
      return {
        ...state,
        isCommentsLoading: true,
        commentsData: null,
      };
    case types.GET_FILE_COMMENT_SUCCESS: {
      return {
        ...state,
        isCommentsLoading: false,
        commentsData: action.payload.result,
      };
    }
    case types.GET_FILE_COMMENT_FAILURE:
      return {
        ...state,
        isCommentsLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };

    // delete comments Actions
    case types.DELETE_FILE_COMMENT_REQUEST:
      return {
        ...state,
        isDeleteCommentLoading: true,
        deletedComment: null,
      };
    case types.DELETE_FILE_COMMENT_SUCCESS: {
      return {
        ...state,
        isDeleteCommentLoading: false,
        deletedComment: action.payload.result,
      };
    }
    case types.DELETE_FILE_COMMENT_FAILURE:
      return {
        ...state,
        isDeleteCommentLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };
    
      // update comment Actions
    case types.UPDATE_FILE_COMMENT_REQUEST:
      return {
        ...state,
        isUpdateCommentLoading: true,
        updatedComment: null,
      };
    case types.UPDATE_FILE_COMMENT_SUCCESS: {
      return {
        ...state,
        isUpdateCommentLoading: false,
        updatedComment: action.payload.result,
      };
    }
    case types.UPDATE_FILE_COMMENT_FAILURE:
      return {
        ...state,
        isUpdateCommentLoading: false,
        error: action.payload.message || defaultErrorMessage,
      };
    default: {
      return state;
    }
  }
};
