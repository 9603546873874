import * as types from '../types'

// Dashboard-V2 Actions
export const dashboardDataRequest = payload => ({
  type: types.DASHBOARD_DATA_REQUEST,
  payload,
})
export const dashboardDataSuccess = payload => ({
  type: types.DASHBOARD_DATA_SUCCESS,
  payload,
})
export const dashboardDataFailure = payload => ({
  type: types.DASHBOARD_DATA_FAILURE,
  payload,
})

export const dashboardFileUploadRequest = payload => ({
  type: types.DASHBOARD_FILE_UPLOAD_REQUEST,
  payload,
})
export const dashboardFileUploadSuccess = payload => ({
  type: types.DASHBOARD_FILE_UPLOAD_SUCCESS,
  payload,
})
export const dashboardFileUploadFailure = payload => ({
  type: types.DASHBOARD_FILE_UPLOAD_FAILURE,
  payload,
})

// Upload Document Modal Actions
export const fileClusterRequest = payload => ({
  type: types.UPLOAD_DOCUMENT_FILE_CLUSTER_REQUEST,
  payload,
})
export const fileClusterSuccess = payload => ({
  type: types.UPLOAD_DOCUMENT_FILE_CLUSTER_SUCCESS,
  payload,
})
export const fileClusterFailure = payload => ({
  type: types.UPLOAD_DOCUMENT_FILE_CLUSTER_FAILURE,
  payload,
})

export const clusteredDataRequest = payload => ({
  type: types.UPLOAD_DOCUMENT_CLUSTERED_DATA_REQUEST,
  payload,
})
export const clusteredDataSuccess = payload => ({
  type: types.UPLOAD_DOCUMENT_CLUSTERED_DATA_SUCCESS,
  payload,
})
export const clusteredDataFailure = payload => ({
  type: types.UPLOAD_DOCUMENT_CLUSTERED_DATA_FAILURE,
  payload,
})
