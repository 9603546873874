import React, { Component } from 'react';
import { Steps, Divider } from 'antd';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import moment from 'moment';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';
import * as d3 from 'd3';
// import { interval } from 'd3'

import * as dashboardV2Page from '../../store/actions/dashboard-v2Action';
import * as catalogLandingPage from '../../store/actions/catalogLandingPageActions';
import * as catalogSearchResults from '../../store/actions/catalogSearchResultsAction';
import * as workFlowManagementLandingPage from './../../store/actions/workFlowManagementLandingPageActions';
import * as navbarActions from '../../store/actions/navbarActions';
import * as createNewDocumentActions from './../../store/actions/createNewDocumentActions';
import * as homepageActions from './../../store/actions/homepageActions';

import Sidebar from '../../screens/workflow-management/Sidebar/Sidebar';
import NavBar from '../../screens/workflow-management/NavBar/NavBar';
import RightBar from '../../screens/workflow-management/RightBar/RightBar';
import RecentDocuments from './recent-document/RecentDocuments';
import SearchBar from '../../components/data-catalog/search-bar/SearchBar';
import EmailModal from './modal/EmailModal';
import UploadDocumentModal from './modal/UploadDocumentModal';
import NewProjectModal from '../../components/all-projects/modals/new-project/NewProjectModal';

import ProjectSVG from './../../assets/project_green.svg';
import ClockSVG from './../../assets/clock_green.svg';
import SearchSVG from './../../assets/search_green.svg';
import MessagePinkSVG from './../../assets/message_pink.svg';
import IssueSvg from '../../assets/issues_dashboard_pin.svg';

import green_folder from '../../../src/assets/green_folder.svg';
import plus_circle from '../../../src/assets/plus_circle.svg';
import RecentSearchSVG from '../../../src/assets/recent_search.svg';
import search_grey from '../../../src/assets/search_grey.svg';
import FolderGreenSVG from '../../../src/assets/folder_outline_green.svg';
import FolderSolidBlue from './../../../src/assets/folder_solid_blue.svg';
import green_document from '../../../src/assets/green_document.svg';
import SourcesSVG from './../../../src/assets/sources_green.svg';
import SourcesGreySVG from './../../../src/assets/sources_grey.svg';
import CloudUploadSVG from './../../../src/assets/cloud_upload_white_dashboard.svg';
import DocumentGreenSVG from './../../../src/assets/document_green.svg';

import { documents } from './../../json-data/recentDocuments.json';
import { cardData } from './../../json-data/dashboard-v2.json';
import commonStyles from '../../shared/Common.module.css';
import styles from './Dashboard.module.css';

import { handleSuccess, handleError } from '../../shared/HandleMessages';
import { formatDate } from '../../shared/formatDate';

const { Step } = Steps;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

class Dashboard extends Component {
  PER_PAGE = 5;
  recentDocumentsData = [];
  DASHBOARD = 'Dashboard';

  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.secondGraphRef = React.createRef();

    this.state = {
      cardSelected: null,
      showEmailModal: false,
      showUploadDocumentModal: false,
      dashboardData: null,
      recentDocumentPaginationData: [],
      totalNumberOfPages: 1,
      fileUploadData: null,
      isFileUploading: false,
      quickSummary: null,
      showNewProjectModal: false,
      mostSearches: [],
      graphData: null,
      isSecondGraph: false,
      isGraphLoading: true,
      documentCount: 0,
      // uploadDocumentClicked: false,
      selectedProjectId: null,
      selectedProjectName: null,
    };
  }

  componentDidMount() {
    //  other component/pages dependency
    this.props.saveAdvancedSearchFilters(null);
    this.props.saveSearchTermRequest(null);
    this.props.saveSelectedSources(null);
    this.props.saveFiltersWorkflowPage(null);
    this.props.saveFilteredDocumentsWorkflowPage(null);
    this.props.saveFiltersSearchResultsPage(null);
    this.props.saveFilteredDocumentsSearchResultsPage(null);
    this.props.changePrivateDocumentsDisplayedRequest(false);

    this.props.dashboardDataRequest();
    this.props.fetchAllPrivateDocumentsRequest();
    this.props.workflowManagementDropdown({ adminId: this.props.userId });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isQuickSummaryLoading &&
      !this.props.isQuickSummaryLoading &&
      this.props.quickSummary
    ) {
      let timeSpentData = this.props.quickSummary?.timeSpent;
      let projects = this.props.dashboardData?.data?.projects;
      // let total = 0;
      let graphData = [];
      if (timeSpentData && projects) {
        timeSpentData.forEach((data) => {
          projects.forEach((project) => {
            if (project.id === data.projectId) {
              graphData.push({
                id: project.id,
                key: project.name,
                value: Number(data.timeSpent / (1000 * 60 * 60)),
              });
            }
          });
        });
        graphData.sort((a, b) => a.value - b.value);
        this.generateChart(
          graphData,
          this.state.selectedProjectId,
          this.state.selectedProjectName
        );
      }
      this.setState({
        quickSummary: this.props.quickSummary?.billing,
        secondGraphData: this.props.quickSummary?.issues,
        graphData,
        isGraphLoading: false,
      });
    }

    if (
      prevProps.isDashboardDataLoading &&
      !this.props.isDashboardDataLoading
    ) {
      this.recentDocumentsData = this.props.dashboardData?.data?.recentDocument;
      const totalNumberOfPages =
        this.recentDocumentsData?.length / this.PER_PAGE;

      const mostSearches = this.props.dashboardData?.data?.mostSearches;

      this.setState(
        {
          dashboardData: this.props.dashboardData,
          totalNumberOfPages,
          mostSearches,
        },
        () => {
          this.handlePaginationClick();
        }
      );
      this.props.quickSummaryRequest();
    }

    if (
      prevProps.isDashboardFileUploading &&
      !this.props.isDashboardFileUploading &&
      this.props.documentUploadData &&
      this.props.uploadDocumentModalParent === this.DASHBOARD
    ) {
      this.setState(
        {
          fileUploadData: this.props.documentUploadData?.data,
        },
        () => {
          this.uploadDocumentModalHandler(true);
        }
      );
    }

    if (
      prevProps.isWorkflowManagementCreateProjectLoading &&
      !this.props.isWorkflowManagementCreateProjectLoading &&
      this.props.workflowManagementCreateProject
    ) {
      // console.info('inside componentDidUpdate third if')

      handleSuccess('Project has been created successfully!');
      this.setState({ showNewProjectModal: false });
      this.props.dashboardDataRequest();
      // this.generateChart(this.props.quickSummary?.billing);
      this.props.workflowManagementDropdown({ adminId: this.props.userId });
      this.setState({ quickSummary: this.props.quickSummary?.billing });
    }
    if (
      prevProps.isFolderAddedLoading &&
      !this.props.isFolderAddedLoading &&
      this.props.folderAddedData
    ) {
      this.props.dashboardDataRequest();
    }
    if (
      prevProps.isFetchAllDocumentsLoading &&
      !this.props.isFetchAllDocumentsLoading &&
      this.props.fetchAllDocumentsData
    ) {
      // console.info(
      //   'this.props.fetchAllDocumentsData: ',
      //   this.props.fetchAllDocumentsData
      // )
      this.setState({
        documentCount: this.props.fetchAllDocumentsData.length,
      });
    }
  }

  // For ReactPaginate, calc data to be displayed
  handlePaginationClick = (data) => {
    const currentPage = data === undefined ? 0 : data.selected;
    const offset = this.PER_PAGE * currentPage;
    const recentDocumentPaginationData = this.recentDocumentsData.slice(
      offset,
      offset + this.PER_PAGE
    );
    this.setState({
      recentDocumentPaginationData,
    });
  };

  cardSelectedHandler = (e, index, id, name) => {
    const isProjectNameClicked = e.target.className
      .toString()
      .includes('project-name');
    if (isProjectNameClicked) {
      this.handleViewAll(id);
    } else {
      let graphValues = this.state.graphData;
      // let filteredGraphData = graphValues.filter((value) => value.id === id);
      if (this.state.cardSelected === index) {
        this.setState({
          cardSelected: null,
          selectedProjectId: null,
          selectedProjectName: null,
          isSecondGraph: false,
        });
        this.generateChart(graphValues, null, null);
      } else {
        this.setState(
          {
            cardSelected: index,
            selectedProjectId: id,
            selectedProjectName: name,
            isSecondGraph: false,
          },
          () => {
            this.generateChart(
              graphValues,
              this.state.selectedProjectId,
              this.state.selectedProjectName
            );
          }
        );
      }
    }
  };

  emailModalHandler = (value) => {
    this.setState({
      showEmailModal: value,
    });
  };

  uploadDocumentModalHandler = (value) => {
    this.setState({
      showUploadDocumentModal: value,
    });
  };

  countSteps = (tasks) => {
    let count = 0;
    tasks.forEach((task, index) => {
      // console.log(task);
      if (new Date(task.endDate) < new Date()) {
        count += 1;
      }
    });
    return count;
  };

  handleSearch = (searchItems) => {
    this.props.catalogSearchTerm({ searchTerm: searchItems });
    const params = {
      query: searchItems,
      source: '',
      filters: {},
    };
    this.props.catalogSearchResultRequest(params);
    this.props.history.push('/data-catalog-2', { searchItems: searchItems });
  };

  handleFrequentSearch = (searchTerm) => {
    this.props.catalogSearchTerm({ searchTerm });
    const params = {
      query: searchTerm,
      source: '',
      filters: {},
    };
    this.props.catalogSearchResultRequest(params);
    this.props.history.push('/data-catalog-2', { searchItems: searchTerm });
  };

  fileSelected = (e) => {
    console.info('fileSelected');

    if (e.target.value) {
      this.props.updateUploadDocumentModalParent(this.DASHBOARD);

      // const teamId = JSON.parse(localStorage.getItem('userProfileData'))
      //   .teams[0].teamId;

      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      //formData.append('teamId', teamId);

      this.setState(
        {
          isFileUploading: true,
          // uploadDocumentClicked: true,
        },
        () => {
          this.props.dashboardFileUpload(formData);
          // console.info('documentUploadData: ', this.props.documentUploadData)
          // this.uploadDocumentModalHandler(true)
          this.setState({
            isFileUploading: false,
          });
        }
      );
    }
  };

  generateChart = (data, selectedProjectId, selectedProjectName) => {
    if (!data || data.length < 1) return;

    // set the dimensions and margins of the graph
    var width = 430;
    var height = 430;
    var margin = 0;

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    var radius = Math.min(width, height) / 2 - margin;

    // append the svg object to the div called 'my_dataviz'
    d3.select(this.ref.current).selectAll('svg').remove();
    d3.select(this.secondGraphRef.current).selectAll('svg').remove();

    let svg = d3
      .select(this.ref.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2.4 + ',' + height / 2 + ')');

    let categories = [];
    let total = 0;
    data &&
      data.forEach((obj) => {
        categories.push(obj.key);
        total += obj.value;
      });

    let getProjectHours = (projectId) => {
      let val = 0;
      data.forEach((project) => {
        if (project.id === projectId) {
          val += project.value;
        }
      });
      return val % 1 === 0 ? val : val.toFixed(2);
    };

    // set the color scale
    var color = d3.scaleOrdinal().domain(categories).range(d3.schemeGreys[5]);

    // Compute the position of each group on the pie:
    var pie = d3
      .pie()
      .sort(null) // Do not sort group by size
      .value(function (d) {
        return d.value;
      });
    var data_ready = pie(data);

    // The arc generator
    var arc = d3
      .arc()
      .innerRadius(radius * 0.8) // This is the size of the donut hole
      .outerRadius(radius * 0.6);

    // Another arc that won't be drawn. Just for labels positioning
    d3.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('id', function (d) {
        return `arc_part_${d.data.id}`;
      })
      .attr('fill', function (d, i) {
        return selectedProjectId === d.data.id
          ? '#1BA94C'
          : color(d.data.value);
      })
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .style('opacity', 0.7)
      .style('cursor', 'pointer')
      .on('mouseover', (d, data) => {
        let arc = document.getElementById(`arc_part_${data.data.id}`);
        arc.style.fill = '#1BA94C';

        svg.selectAll('text').remove();
        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '-1.3em')
          .text(`${data.data.key[0].toUpperCase()}${data.data.key.slice(1)}`)
          .style('font-size', '12px')
          .style('color', '#C4C4C4');

        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '1em')
          .text(
            `${
              data.data.value % 1 === 0
                ? data.data.value
                : data.data.value.toFixed(2)
            } Hrs.`
          )
          .style('font-size', '24px')
          .style('color', '#C4C4C4');
      })
      .on('mouseout', (d, obj) => {
        let arc = document.getElementById(`arc_part_${obj.data.id}`);
        arc.style.fill = color(obj.data.value);

        svg.selectAll('text').remove();
        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '-1.3em')
          .text('Total')
          .style('font-size', '12px')
          .style('color', '#C4C4C4');
        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '1em')
          .text(`${total % 1 === 0 ? total : total.toFixed(2)} Hrs.`)
          .style('font-size', '24px')
          .style('color', '#C4C4C4');
      });

    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '-1.3em')
      .text(selectedProjectName ? selectedProjectName : 'Total')
      .style('font-size', '12px')
      .style('color', '#C4C4C4');

    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '1em')
      .text(
        `${
          selectedProjectId
            ? getProjectHours(selectedProjectId)
            : total % 1 === 0
            ? total
            : total.toFixed(2)
        } Hrs.`
      )
      .style('font-size', '24px')
      .style('color', '#C4C4C4');
  };

  formatIssueName = (name) => {
    let tempStr = '';
    if (name.length > 30) {
      tempStr = name.slice(0, 30);
      tempStr += ' ...';
      return tempStr;
    } else {
      return name;
    }
  };

  generateSecondChart = (data) => {
    let total = data.length;
    let totalCount = 0;

    var width = 430;
    var height = 430;
    var margin = 0;

    d3.select(this.ref.current).selectAll('svg').remove();
    d3.select(this.secondGraphRef.current).selectAll('svg').remove();
    var radius = Math.min(width, height) / 2 - margin;

    var svg = d3
      .select(this.ref.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + width / 2.4 + ',' + height / 2 + ')');

    let categories = [];
    data.forEach((obj) => {
      totalCount += obj.value;
      categories.push(obj.key);
    });

    // set the color scale
    let color = (i) => {
      let colorArr = [
        '#0588BB',
        '#9CCA61',
        '#1B2438',
        '#C3C2BD',
        '#474C5C',
        '#F74C51',
        '#9CC95F',
        '#474C5C',
        '#F3C25C',
        '#BD167A',
        '#01A2E1',
        '#B6EB72',
        '#3F527B',
        '#EDEBE3',
        '#002087',
        '#F00E15',
        '#B7E777',
        '#6275B4',
        '#E7BE6A',
        '#133752',
        '#16BCFC',
        '#C2FA7A',
        '#6783C0',
        '#C2BDA5',
        '#082CA0',
        '#BC0F14',
        '#92F908',
        '#99A9DD',
        '#9F8141',
        '#731275',
        '#86D3F1',
        '#CBEAA4',
        '#7E9EE4',
        '#FFEC90',
        '#0B38C8',
        '#8C0D11',
        '#72BE0B',
        '#6E88DD',
        '#B48E3F',
        '#93088D',
        '#429ABC',
        '#82DA12',
        '#80A7FF',
        '#BBAE70',
        '#0D3EDC',
        '#FF9093',
        '#547A21',
        '#192753',
        '#FADDA2',
        '#E739C0',
        '#37768E',
        '#689D23',
        '#A7BEF0',
        '#D6C679',
        '#2955E2',
        '#FCBEC0',
        '#93C253',
        '#0A1536',
        '#BFA56E',
        '#B871B1',
        '#309CC7',
        '#4F7918',
        '#142447',
        '#FFFFFF',
        '#4870F1',
        '#62090B',
        '#B7D194',
        '#7882A4',
        '#936609',
        '#C00781',
      ];
      if (i >= colorArr.length) {
        let num = Math.floor(Math.random() * colorArr.length);
        return colorArr[num];
      }
      return colorArr[i];
    };

    // Compute the position of each group on the pie:
    var pie = d3
      .pie()
      .sort(null) // Do not sort group by size
      .value(function (d) {
        return d.value;
      });
    var data_ready = pie(data);

    // The arc generator
    var arc = d3
      .arc()
      .innerRadius(radius * 0.8) // This is the size of the donut hole
      .outerRadius(radius * 0.6);

    // Another arc that won't be drawn. Just for labels positioning
    d3.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    let arcPart = svg
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d, i) {
        return color(i);
      })
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .style('opacity', 0.7)
      .style('cursor', 'pointer');

    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '-1.3em')
      .text('Total')
      .style('font-size', '14px')
      .style('color', '#C4C4C4');

    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '1em')
      .text(`${total % 1 === 0 ? total : total.toFixed(2)} Issues`)
      .style('font-size', '20px')
      .style('color', '#C4C4C4');

    arcPart
      .on('mouseover', (d, data) => {
        svg.selectAll('text').remove();
        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '-1.3em')
          .html(this.formatIssueName(data.data.key))
          .style('font-size', '14px')
          .style('color', '#C4C4C4');

        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '1em')
          .text(
            `${
              ((data.data.value / totalCount) * 100) % 1 === 0
                ? (data.data.value / totalCount) * 100
                : ((data.data.value / totalCount) * 100).toFixed(2)
            } %`
          )
          .style('font-size', '20px')
          .style('color', '#C4C4C4');
      })
      .on('mouseout', (d) => {
        svg.selectAll('text').remove();
        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '-1.3em')
          .text('Total')
          .style('font-size', '14px')
          .style('color', '#C4C4C4');
        svg
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dy', '1em')
          .text(`${total % 1 === 0 ? total : total.toFixed(2)} Issues`)
          .style('font-size', '24px')
          .style('color', '#C4C4C4');
      });
  };

  createProject = (paramsObj) => {
    this.props.workflowManagementCreateProjectRequest(paramsObj);
  };
  toggleNewProjectModal = (value) => {
    // this.setState({
    //   showNewProjectModal: value,
    // })
    this.props.history.push('/create-document');
  };
  handleViewAll = (id) => {
    this.props.history.push(
      {
        pathname: '/all-projects',
        search: '?source=dashboard',
      },
      { id: id }
    );
  };
  goToWorkflowPage = () => {
    this.props.history.push('/workflow-management');
  };
  goToDocumentList = () => {
    this.props.history.push('/document-list');
  };
  gotToRootFolderTreeView = () => {
    // TODO: get hasMore from backend
    const {
      name,
      catalogSourceId,
      referenceId,
    } = this.props.dashboardData.data?.rootFolder[0];

    // console.info(
    //   'this.props.dashboardData.data?.rootFolder',
    //   this.props.dashboardData.data?.rootFolder
    // )

    this.handleFolderClick(name, referenceId, catalogSourceId, true, true);
  };

  handleFolderClick = (
    fileName,
    referenceId,
    catalogSourceId,
    hasMore,
    isPrivate
  ) => {
    this.props.history.push(
      {
        pathname: '/selected-folder',
        search: '?source=data-catalog',
      },
      {
        fileName: fileName,
        referenceId: referenceId,
        catalogSourceId: catalogSourceId,
        hasMore: hasMore,
        isPrivate,
      }
    );
  };

  handleSecondGraphSwitch = (event) => {
    this.setState({ isSecondGraph: event.target.checked });
    if (event.target.checked) {
      this.generateSecondChart(this.state.secondGraphData);
    } else {
      this.generateChart(
        this.state.graphData,
        this.state.selectedProjectId,
        this.state.selectedProjectName
      );
    }
  };
  // handleFolderClick = (fileName, referenceId, catalogSourceId, hasMore) => {
  //   this.props.history.push('/selected-folder', {
  //     fileName: fileName,
  //     referenceId: referenceId,
  //     catalogSourceId: catalogSourceId,
  //     hasMore: hasMore,
  //   })
  // }

  /* from NavBar to disable showing of upload document here */
  notifyDashboard = () => {
    this.setState({
      uploadDocumentClicked: false,
    });
  };

  componentWillUnmount() {
    clearInterval(this.queryInterval);
  }
  render() {
    const {
      searchBarPlaceholder,
      cardSelected,
      showEmailModal,
      showUploadDocumentModal,
      dashboardData,
      recentDocumentPaginationData,
      totalNumberOfPages,
      fileUploadData,
      isFileUploading,
      showNewProjectModal,
      mostSearches,
      quickSummary,
      documentCount,
      isSecondGraph,
      isGraphLoading,
    } = this.state;

    // console.info('render: state: ', this.props.dashboardData?.recentSources)

    if (
      dashboardData !== null &&
      (dashboardData?.data !== undefined || dashboardData?.data !== null)
    )
      var {
        metaData,
        projects,
        recentDocuments,
        recentSearches,
        recentSources,
        folders,
      } = dashboardData?.data;
    return (
      <>
        <NavBar />
        <Sidebar />
        <RightBar />
        <div
          className={`${styles.wrapper} ${commonStyles.dashboard_v2_wrapper}`}
        >
          <div className={`${styles.root}`}>
            <div className={styles.top_container}>
              <div className={styles.top_container_cards_section}>
                <div className={styles.data_catalog_heading}>
                  <div className={styles.data_catalog_title}>MY DASHBOARD</div>
                  <div className="heading_container">
                    <div className={styles.data_catalog_subtitle}>
                      Search for any term,
                    </div>
                    <div className={styles.data_catalog_subtitle}>
                      phrase or query
                    </div>
                  </div>
                </div>

                <div className={styles.card_wrapper}>
                  <div className={styles.new_project_card}>
                    <div className={styles.folder_icon_container}>
                      <img src={MessagePinkSVG} alt="folder"></img>
                      <span className={styles.badge}></span>
                    </div>

                    <div className={styles.top_container_card_info}>
                      <div className={styles.top_container_title}>Emails:</div>

                      <div className={styles.top_container_count}>
                        {dashboardData === null ? 0 : metaData.emails} Pending
                      </div>
                      <div
                        className={styles.text_plus_icon_container}
                        onClick={() => this.emailModalHandler(true)}
                      >
                        <div className={styles.top_container_action}>
                          Link Emails
                        </div>
                        <div className={styles.plus_icon}>
                          <img src={plus_circle} alt="plus_circle" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.new_project_card}>
                    <div className={styles.connector_icon_container}>
                      <img
                        src={DocumentGreenSVG}
                        alt="Document Green Icon"
                      ></img>
                    </div>

                    <div className={styles.top_container_card_info}>
                      <div className={styles.top_container_title}>
                        Working on:
                      </div>

                      <div
                        className={styles.top_container_count}
                        onClick={this.goToDocumentList}
                      >
                        {`${documentCount} Documents`}
                      </div>
                      <div
                        className={styles.text_plus_icon_container}
                        onClick={() => this.toggleNewProjectModal(true)}
                      >
                        <div className={styles.top_container_action}>
                          Create New Document
                        </div>
                        <div className={styles.plus_icon}>
                          <img
                            src={plus_circle}
                            alt="plus_circle"
                            className={styles.add_source_modal_btn}
                            onClick={() => /* this.sourceModalHandler(true) */ {}}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.search_bar_container}>
              <div className={styles.search_bar_background}>
                <div className={styles.search_bar_wrapper}>
                  <SearchBar
                    iconColor={'#2c2c2c'}
                    iconSize={'21px'}
                    fontSize={'18px'}
                    parent={'Dashboard'}
                    handleSearch={(searchItems) =>
                      this.handleSearch(searchItems)
                    }
                    handleSearchChange={(searchItems) => {
                      // this.handleSearchChange(searchItems) {}
                    }}
                  />
                </div>
              </div>
              <div className={styles.recent_searches_wrapper}>
                {mostSearches.map(({ searchTerm }) => (
                  <span
                    className={styles.recent_search_results_container}
                    onClick={() => this.handleFrequentSearch(searchTerm)}
                  >
                    <img src={RecentSearchSVG} alt="Recent Search Icon" />
                    <span>{searchTerm}</span>
                  </span>
                ))}
              </div>
            </div>

            <section className={styles.middle_section}>
              <div className={styles.projects}>
                <div className={styles.heading}>
                  <span>Your Projects</span>
                  <img src={ProjectSVG} alt="Project Icon" />
                </div>

                <div className={styles.cards_wrapper}>
                  {this.props.isDashboardDataLoading ? (
                    <BeatLoader
                      css={override}
                      size={25}
                      color={'#11256D'}
                      loading={true}
                    />
                  ) : (
                    projects?.map(
                      (
                        {
                          id,
                          description,
                          clientName,
                          startDate,
                          endDate,
                          tasks,
                          team,
                          name,
                          type,
                          createdAt,
                        },
                        index
                      ) => (
                        <div
                          className={styles.card_container_wrapper}
                          style={
                            cardSelected === index
                              ? {
                                  boxShadow:
                                    '0px 0px 8px rgba(122, 122, 122, 0.25)',
                                  backgroundColor: '#1ba94c',
                                }
                              : {}
                          }
                          onClick={(e) =>
                            this.cardSelectedHandler(e, index, id, name)
                          }
                        >
                          <div
                            className={styles.cards_container}
                            style={
                              cardSelected === index
                                ? {
                                    marginLeft: '6px',
                                  }
                                : {}
                            }
                          >
                            <div className={styles.project_card}>
                              <div className={styles.left_part}>
                                <div
                                  className={`project-name ${styles.project_name}`}
                                >
                                  {name}
                                </div>
                                <div className={styles.company_name}>
                                  {type}
                                </div>
                                <div className={styles.avatars}>
                                  {team.map((member, index) => (
                                    <span className={styles.avatar}>
                                      <img src="https://www.w3schools.com/howto/img_avatar.png" />
                                    </span>
                                  ))}
                                </div>
                              </div>
                              <div className={styles.right_part}>
                                <div
                                  className={`dashboard_v2_steps ${styles.steps}`}
                                >
                                  <Steps
                                    progressDot
                                    current={this.countSteps(tasks)}
                                    size="small"
                                  >
                                    <Step title="" description="" />
                                    <Step title="" description="" />
                                    <Step title="" description="" />
                                    <Step title="" description="" />
                                    <Step title="" description="" />
                                    <Step title="" description="" />
                                  </Steps>
                                </div>
                                <div className={styles.date_container}>
                                  <div className={styles.due_date}>
                                    {`DUE IN  ${moment(endDate).diff(
                                      moment(startDate),
                                      'days'
                                    )}  DAYS`}
                                  </div>
                                  <div className={styles.last_update_date}>
                                    LAST UPDATE - {formatDate(createdAt).date}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>

              <div className={styles.time_spent}>
                <div className={styles.heading}>
                  <span>{isSecondGraph ? 'Issues' : 'Time Spent'}</span>
                  <img
                    src={isSecondGraph ? IssueSvg : ClockSVG}
                    alt="Clock Icon"
                  />
                </div>
                <div className={styles.radio_btn_container}>
                  {/* <p>{isSecondGraph ? 'ISSUES' : 'YOU'}</p> */}
                  <p>ISSUES</p>
                  <label class={styles.switch}>
                    <input
                      disabled={isGraphLoading}
                      type="checkbox"
                      checked={isSecondGraph}
                      onChange={(e) => this.handleSecondGraphSwitch(e)}
                    />
                    <span class={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>

                {/* Graph Here */}

                {isGraphLoading ? (
                  <BeatLoader
                    css={override}
                    size={25}
                    color={'#11256D'}
                    loading={true}
                  />
                ) : null}

                <div ref={this.ref} className={styles.d3_chart_container}></div>

                <div
                  ref={this.secondGraphRef}
                  className={styles.d3_chart_container}
                ></div>

                {/* <div className={styles.pie_chart_bottom_label}>
                  <p className={styles.total_time_text}>TOTAL TIME</p>
                  <p className={styles.time_taken_time_text}>
                    {quickSummary && quickSummary[0].value} Hrs. / WEEK
                  </p>
                </div> */}
              </div>

              <div className={styles.recent_searches_and_private_folders}>
                <div className={styles.recent_searches}>
                  <div className={styles.heading}>
                    <span>Recent Searches</span>
                    <img src={SearchSVG} alt="Search Icon" />
                  </div>
                  <div className={styles.recent_searches_container_wrapper}>
                    <div className={styles.recent_search_container}>
                      <div
                        className={styles.recent_search_content}
                        onClick={() =>
                          this.handleFrequentSearch(
                            recentSearches?.[0].searchTerm
                          )
                        }
                      >
                        <img
                          className={styles.recent_search_icon}
                          src={search_grey}
                          alt="search"
                        />
                        <span className={styles.recent_search_text}>
                          {recentSearches?.[0]?.searchTerm}
                        </span>
                      </div>
                    </div>
                    <div className={styles.recent_search_container}>
                      <div
                        className={styles.recent_search_content}
                        onClick={() =>
                          this.handleFrequentSearch(
                            recentSearches?.[1]?.searchTerm
                          )
                        }
                      >
                        <img
                          className={styles.recent_search_icon}
                          src={search_grey}
                          alt="search"
                        />
                        <span className={styles.recent_search_text}>
                          {recentSearches?.[1]?.searchTerm}
                        </span>
                      </div>
                    </div>
                    <div className={styles.recent_search_container}>
                      <div
                        className={styles.recent_search_content}
                        onClick={() =>
                          this.handleFrequentSearch(
                            recentSearches?.[2]?.searchTerm
                          )
                        }
                      >
                        <img
                          className={styles.recent_search_icon}
                          src={search_grey}
                          alt="search"
                        />
                        <span className={styles.recent_search_text}>
                          {recentSearches?.[2]?.searchTerm}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.private_folders}>
                  <div className={styles.heading}>
                    <span>Private Folders</span>
                    <img src={FolderGreenSVG} alt="Folder Icon" />
                    <span
                      className={styles.view_all}
                      onClick={this.gotToRootFolderTreeView}
                    >
                      View All
                    </span>
                  </div>

                  <div className={styles.related_folder__root}>
                    {folders?.map(
                      (
                        { name, referenceId, catalogSourceId, hasMore },
                        index
                      ) => {
                        if (index > 1) return;

                        return (
                          <div
                            onClick={() =>
                              this.handleFolderClick(
                                name,
                                referenceId,
                                catalogSourceId,
                                hasMore
                              )
                            }
                            className={styles.related_folder__folder}
                          >
                            <div className={styles.related_folder__icon}>
                              <img
                                src={FolderSolidBlue}
                                alt="Folder Solid Blue"
                              />
                            </div>
                            <div className={styles.related_folder__name}>
                              {name}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className={styles.recent_document_section}>
              <div className={styles.recent_documents_container}>
                <div className={styles.recent_documents_header_container}>
                  <div className={styles.recent_documents_title}>
                    Recent Documents
                  </div>
                  <div className={styles.icon_container}>
                    <img
                      className={styles.recent_document_icon}
                      src={green_document}
                      alt="document"
                    />
                  </div>
                  <div className={styles.upload_documents_btn}>
                    {isFileUploading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <label for="myfile">
                        <div>UPLOAD DOCUMENTS</div>
                        <img src={CloudUploadSVG} alt="Cloud Icon" />
                        <input
                          type="file"
                          id="myfile"
                          name="myfile"
                          onChange={this.fileSelected}
                        />
                      </label>
                    )}
                  </div>

                  {recentDocumentPaginationData &&
                    recentDocumentPaginationData.length > 0 && (
                      <div
                        className={`dashboard-v2-react-pagination ${styles.react_pagination}`}
                      >
                        <ReactPaginate
                          previousLabel={''}
                          nextLabel={''}
                          breakLabel={''}
                          breakClassName={'break-me'}
                          pageCount={totalNumberOfPages}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={totalNumberOfPages}
                          onPageChange={this.handlePaginationClick}
                          containerClassName={'pagination'}
                          subContainerClassName={'pages pagination'}
                          activeClassName={'active'}
                        />
                      </div>
                    )}
                </div>
                <div className={styles.recent_document_section}>
                  {this.props.isDashboardDataLoading ? (
                    <BeatLoader
                      css={override}
                      size={25}
                      color={'#11256D'}
                      loading={true}
                    />
                  ) : (
                    <RecentDocuments data={recentDocumentPaginationData} />
                  )}
                </div>
              </div>
            </section>

            <section className={styles.recently_added_sources}>
              <div className={styles.recently_added_sources_header}>
                <span>Recently Added Sources</span>
                <img src={SourcesSVG} alt="Sources Icon" />
              </div>

              <div className={styles.recently_added_sources_container}>
                {this.props.isDashboardDataLoading ? (
                  <BeatLoader
                    css={override}
                    size={25}
                    color={'#11256D'}
                    loading={true}
                  />
                ) : (
                  recentSources?.map(
                    (
                      {
                        folder,
                        catalogSourceId,
                        referenceId,
                        level,
                        hasMore,
                        isPrivate,
                      },
                      index
                    ) => {
                      // const hasMore = level > 0
                      return (
                        <div
                          className={styles.recently_added_sources_card}
                          onClick={() =>
                            this.handleFolderClick(
                              folder,
                              referenceId,
                              catalogSourceId,
                              hasMore,
                              isPrivate
                            )
                          }
                        >
                          <img src={SourcesGreySVG} alt="Sources Grey Icon" />
                          <span>{folder}</span>
                        </div>
                      );
                    }
                  )
                )}
              </div>
            </section>
          </div>
        </div>
        {showEmailModal && (
          <EmailModal emailModalHandler={() => this.emailModalHandler(false)} />
        )}
        {showUploadDocumentModal && (
          <UploadDocumentModal
            uploadDocumentModalHandler={() => {
              this.uploadDocumentModalHandler(false);
            }}
            data={fileUploadData}
          />
        )}

        {/* {showNewProjectModal && this.props.workflowManagementDropdownData && (
          <NewProjectModal
            newProjectModalHandler={() => this.toggleNewProjectModal(false)}
            dropDownData={this.props.workflowManagementDropdownData}
            userId={this.props.userId}
            createProject={this.createProject}
          />
        )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.info('state: ', state)
  return {
    userId: state.homepage.userDetails.userId,
    dashboardData: state.dashboardV2Page.dashboardData,
    isDashboardDataLoading: state.dashboardV2Page.isLoading,
    isDashboardFileUploading: state.dashboardV2Page.isFileUploading,
    documentUploadData: state.dashboardV2Page.documentUploadData,
    quickSummary: state.navbar?.quickSummary,
    isQuickSummaryLoading: state.navbar?.isQuickSummaryLoading,
    workflowManagementDropdownData:
      state.workFlowManagementLandingPage.workflowManagementDropdownData,
    workflowManagementCreateProject:
      state.workFlowManagementLandingPage.workflowManagementCreateProject,
    isWorkflowManagementCreateProjectLoading:
      state.workFlowManagementLandingPage
        .isWorkflowManagementCreateProjectLoading,
    isLoading: state.privateFolderComponent.isLoading,
    fileAddedData: state.privateFolderComponent.fileAddedData,
    isFolderAddedLoading: state.privateFolderComponent.isFolderAddedLoading,
    folderAddedData: state.privateFolderComponent.folderAddedData,
    isFetchAllDocumentsLoading:
      state.createPrivateDocument.isFetchAllDocumentsLoading,
    fetchAllDocumentsData: state.createPrivateDocument.fetchAllDocumentsData,
    uploadDocumentModalParent: state.homepage.uploadDocumentModalParent,
  };
};

const mapDispatchToProps = {
  dashboardDataRequest: dashboardV2Page.dashboardDataRequest,
  catalogSearchTerm: catalogLandingPage.catalogSearchTermRequest,
  catalogSearchResultRequest:
    catalogSearchResults.catalogSearchResultDataRequest,
  dashboardFileUpload: dashboardV2Page.dashboardFileUploadRequest,
  workflowManagementCreateProjectRequest:
    workFlowManagementLandingPage.workflowManagementCreateProjectRequest,
  workflowManagementDropdown:
    workFlowManagementLandingPage.workflowManagementDropdownRequest,
  saveAdvancedSearchFilters:
    catalogLandingPage.catalogAdvancedSearchFilterAction,
  saveSearchTermRequest:
    catalogSearchResults.catalogSearchResultSearchTermAction,
  saveSelectedSources: catalogSearchResults.catalogSelectedSourcesAction,
  saveFiltersWorkflowPage:
    workFlowManagementLandingPage.workflowManagementSaveFilters,
  saveFilteredDocumentsWorkflowPage:
    workFlowManagementLandingPage.workflowManagementSaveFilteredDocuments,
  saveFilteredDocumentsSearchResultsPage:
    catalogSearchResults.catalogSearchResultFilteredDocumentsAction,
  saveFiltersSearchResultsPage:
    catalogSearchResults.catalogSearchResultFilterAction,
  fetchAllPrivateDocumentsRequest:
    createNewDocumentActions.fetchAllPrivateDocumentsRequest,
  quickSummaryRequest: navbarActions.quickSummaryRequest,
  updateUploadDocumentModalParent:
    homepageActions.updateUploadDocumentModalParent,
  changePrivateDocumentsDisplayedRequest:
    createNewDocumentActions.changePrivateDocumentsDisplayed,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
