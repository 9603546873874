import * as types from '../types';

const initialState = {
  isLoading: false,
  graphData: null,
  error: null,
  tagsGraphData: null,
  isTagsGraphDataLoading: false,
};

const defaultErrorMessage = 'Something went wrong';

export default (state = initialState, action) => {
  switch (action.type) {
    // Update Profile Actions
    case types.FETCH_GRAPH_DATA_REQUEST:
      return {
        ...state,
        graphData: null,
        isLoading: true,
      };
    case types.FETCH_GRAPH_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        graphData: action.payload.result.data,
      };
    }
    case types.FETCH_GRAPH_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    case types.TAGS_GRAPH_DATA_REQUEST:
      return {
        ...state,
        tagsGraphData: null,
        isTagsGraphDataLoading: true,
      };
    case types.TAGS_GRAPH_DATA_SUCCESS: {
      return {
        ...state,
        isTagsGraphDataLoading: false,
        tagsGraphData: action.payload.result.data,
      };
    }
    case types.TAGS_GRAPH_DATA_FAILURE:
      return {
        ...state,
        isTagsGraphDataLoading: false,
        error: action.payload.result || defaultErrorMessage,
      };

    default: {
      return state;
    }
  }
};
